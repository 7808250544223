import React from 'react'
import { environment } from '../environments/environment'

const DisclaimerPixentro = () => {
  return (
    <div className='container disclaimer'>
      <h4>DISCLAIMER</h4>

      <p>
        TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW AND UNLESS OTHERWISE SPECIFIED IN WRITING BY US, (A) THE TOKENS ARE SOLD “AS IS” AND WITHOUT WARRANTIES OF ANY KIND, AND WE EXPRESSLY DISCLAIM ALL IMPLIED WARRANTIES WITH RESPECT TO THE TOKENS, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT, WHETHER ARISING BY LAW, COURSE OF DEALING, COURSE OF PERFORMANCE, USAGE OF TRADE OR OTHERWISE; (B) WE DO NOT WARRANT THAT THE TOKENS WILL BE RELIABLE, CURRENT, OR ERROR-FREE, WILL MEET YOUR REQUIREMENTS, OR THAT ERRORS IN THE TOKENS WILL BE CORRECTED; AND (C) WE CANNOT AND DO NOT WARRANT THAT THE TOKENS OR THE TOKEN DELIVERY MECHANISM WILL BE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.
      </p>

      <p>
        YOU ACKNOWLEDGE THAT YOU HAVE NOT RELIED ON ANY REPRESENTATIONS OR WARRANTIES MADE BY PROJECT OR ANYONE ELSE ON PROJECT’S BEHALF, INCLUDING, BUT NOT LIMITED TO, CONVERSATIONS OF ANY KIND, WHETHER BY ORAL OR ELECTRONIC COMMUNICATION.
      </p>

      <p>
        WE DO NOT AND WILL NOT MAKE AVAILABLE TO YOU ANY SOFTWARE OTHER THAN TOKENS IN YOUR DISTRIBUTION.
      </p>

      <p>
        YOU UNDERSTAND THAT TOKENS, BLOCKCHAIN TECHNOLOGY, THE ETHEREUM PROTOCOL, AND ERC-721 ARE NEW TECHNOLOGIES BEYOND OUR CONTROL, AND THAT ADVERSE CHANGES IN MARKET FORCES OR TECHNOLOGY WILL EXCUSE OUR PERFORMANCE UNDER THESE TERMS.
      </p>

      <p>
        TRANSACTIONS THAT USE BLOCKCHAIN TECHNOLOGY, SUCH AS TOKEN SALES, ARE SUBJECT TO THE RISK OF NUMEROUS POTENTIAL FAILURES, INCLUDING HIGH NETWORK VOLUME, COMPUTER FAILURES, BLOCKCHAIN FAILURES OF ANY KIND, USER FAILURES, TOKEN THEFT, AND NETWORK HACKING. WE ARE NOT RESPONSIBLE FOR ANY LOSS OF DATA, &lt;Website Name&gt; TOKENS, HARDWARE OR SOFTWARE RESULTING FROM ANY TYPE OF ERROR, THEFT OR HACKING.
      </p>

      <p>
        {environment.websiteName} is based on the Ethereum protocol. Therefore, any malfunction, unplanned function, or unexpected operation of the Ethereum protocol may cause the {environment.websiteName} Utility Token Network to malfunction or operate in a manner not expected.
      </p>

      <p>
        Some jurisdictions do not allow the exclusion of certain warranties or the waiver of implied conditions in contracts with consumers, so some or all of the exclusions of warranties and waivers in this section may not apply to you.
      </p>
    </div>
  )
}

export default DisclaimerPixentro