/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState } from "react";
import "./index.css";
import $ from "jquery";

import "react-modal-video/scss/modal-video.scss";
import "video.js/dist/video-js.min.css";
import "./index.css";

import poster1 from "./logo/poster1.svg";
import nftCover from './logo/nft-cover.png';
import subscribe from './logo/subscribe.svg'
import play from "./play.svg";

export const VideoPlayer = ({ playerId, coverId, coverImageLink, linkSrc, playButtonId, subsButtonId, height, coverImageHeight = "220px" }) => {

  const clickHandler = (e) => {
    e.preventDefault();
    $(`#${playerId}`).src += "?autoplay=1";
    $(`#${playerId}`).show();
    $(`#${coverId}`).hide();
    $(`#${subsButtonId}`).hide();
    $(`#${playButtonId}`).hide();
  };

  return (
    <div className="embed-responsive embed-responsive-16by9 wrapper video-player" style={{ margin: 0 }}>
      {/* Old Implementation */}
      {/* <img id={coverId} src={nftCover} alt="Video title" style={{height: '250px', width: 'auto', borderTopLeftRadius: '5px', borderTopRightRadius: '5px'}}/>
      {coverImageLink && coverImageLink.indexOf("https://") > -1 && (<img src={coverImageLink} style={{position: 'absolute', height: '80px', width: '150px', top: 0, left: 0}} className="card-img-top" />)} */}

      {/* New Implementation */}
      {coverImageLink?.indexOf("https://") > -1 ? (
        <img id={coverId} src={coverImageLink} alt="Video title" style={{ padding: '5px', height: coverImageHeight, width: '100%', borderTopLeftRadius: '16px', borderTopRightRadius: '16px' }} />) : (<img id={coverId} src={nftCover} alt="Video title" style={{ height: '250px', width: 'auto', borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }} />)
      }

      <iframe
        id={playerId}
        src={linkSrc}
        frameBorder="0"
        //allow="autoplay; encrypted-media"
        allowFullScreen
        height={height}
        width="100%"
        controlsList="nodownload"
      >
      </iframe>
      {/* <a id={subsButtonId} href={channelSrc} target="_blank" rel="noopener referer" className="subscribe-button">
        <img src={subscribe} style={{ width: "100px", height: "100px"}} alt="play" />
      </a> */}
      <button id={playButtonId} onClick={clickHandler} className="play-btn">
        <img src={play} style={{ width: "80px", height: "80px" }} alt="play" />
      </button>
    </div>
  );
};
