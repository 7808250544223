import {
  Button,
  Divider,
  Modal,
  Pagination,
  Popconfirm,
  Select,
  notification,
} from "antd";
import ProfilePic from "../../../../assets/images/home/profile-default.png";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { AdminService } from "../../../../components/services/AdminService/Admin.service";
import authContext from "../../../../components/Shared/Auth.service";
import { MetamaskService } from "../../../../components/Shared/Metamask.service";
import { MasterWhiteListingService } from "../../../../components/Shared/Blockchain/MasterWhitelisting/MasterWhiteListing.service";
const adminService = new AdminService();
const useSelectedWalletContext = () =>
  new MetamaskService().useSelectedWalletContext();

const nftApprovalStyleing = {
  cardContainer: {
    width: "100%",
    display: "flex",
  },

  cardImage: {
    width: "140px",
    height: "95px",
    borderRadius: "8px",
  },
  textDiv: {
    fontFamily: "Aileron",
    fontSize: "16px",
    flex: 4,
    marginLeft: "12px",
  },
  title: {
    fontWeight: "700",
    color: "#0F587A",
  },
  titleResponse: {
    fontWeight: "600",
    color: "#33363F",
  },
  sortValueInCard: {
    width: "140px",
    height: "50px",
    borderRadius: "40px",
    border: "2px solid #27AE60",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  sortValueInCardText: {
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: "500",
    color: "#27AE60",
    margin: "10px",
  },
  paginationContainer: {
    display: "flex",
    justifyContent: "end",
    padding: "30px",
  },
  paginationRender: {
    width: "180px",
    height: "41px",
    borderRadius: "8px",
    border: "2px solid #102253",
    display: "flex",
    gap: "10px",
    alignItems: "center",
    justifyContent: "center",
  },
  goInput: {
    border: "2px solid #102253",
    width: "71px",
    marginLeft: "8px",
  },
  goButton: {
    background: "#EB532D",
    marginLeft: "8px",
    borderRadius: "8px",
    width: "64px",
    height: "55px",
    color: "#FFFFFF",
  },
};
const masterWhiteListingService = new MasterWhiteListingService();

const NftApproval = ({ loading, setLoading, smartContractData }) => {
  const { userInfo } = useContext(authContext);
  const { networkId } = useSelectedWalletContext();
  const [approvedNft, setApprovedNft] = useState();
  const [pendingNftApproval, setPendingNftApproval] = useState();
  const [sortByVal, setSortByVal] = useState("Pending");
  const [filteredNft, setFilteredNft] = useState([]);
  const [goToPage, setGoToPage] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [nftRejectionMessage, setNftRejectionMessage] = useState("");
  const [isRejecting, setIsRejecting] = useState(false);
  const [nftDetails, setNftDetails] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 12;
  const { filteredApprovedEscrow, rejectedEscrow } = useMemo(() => {
    const filteredApprovedEscrow = approvedNft?.filter(
      (nft) => nft.adminVerificationStatus === "approved"
    )?.filter(item => item?.user) || [];
    const rejectedEscrow = approvedNft?.filter(
      (nft) => nft.adminVerificationStatus !== "approved"
    )?.filter(item => item?.user) || [];
    return { filteredApprovedEscrow, rejectedEscrow };
  }, [approvedNft]);

  const handleChange = (value) => {
    setSortByVal(value);
    setCurrentPage(1);
  };

  useEffect(() => {
    setFilteredNft(
      sortByVal === "Approved"
        ? filteredApprovedEscrow
        : sortByVal === "Rejected"
        ? rejectedEscrow
        : pendingNftApproval
    );
  }, [sortByVal, approvedNft]);

  const totalPages = Math.ceil(filteredNft?.length / pageSize);
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const currentPageData = filteredNft?.slice(startIndex, endIndex) || [];

  const onPageChange = (page) => {
    setCurrentPage(page);
    setGoToPage(page);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const [res, res2] = await Promise.all([
        adminService.getPendingNFT({ networkId }),
        adminService.getCompletedNFT({ networkId }),
      ]);
      if (res.data?.length > 0) {
        setPendingNftApproval(res.data.reverse()?.filter(item => item?.user));
      }else{
        setPendingNftApproval([]);
      }
      if (res2.data?.length > 0) {
        setApprovedNft(res2.data.reverse());
      }else{
        setApprovedNft([])
      }
    } catch (error) {
      console.log(error);
      notification.open({
        message: "Error",
        description: "something went wrong while fetching nft data",
        duration: 0,
      });
    }
    setLoading(false);
  };

  const handleGoToPage = () => {
    const pageNumber = parseInt(goToPage);
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const handleOpenModal = (user) => {
    setOpenModal(true);
    setNftDetails(user);
  };

  const handleClose = () => {
    setOpenModal(false);
    setIsRejecting(false);
  };

  const handleApprove = () => {
    setOpenModal(false);
    approveNFT(
      nftDetails.userId,
      nftDetails._id,
      "approved",
      "",
      nftDetails.userWalletAddress,
      nftDetails.assetDataURLIPFS
    );
  };

  const handleReject = () => {
    setOpenModal(false);
    approveNFT(
      nftDetails.userId,
      nftDetails._id,
      "rejected",
      nftRejectionMessage
    );
  };

  const approveNFT = async (userId, nftId, status, message, userWalletAddress, nftIPFSURL) => {
    setLoading(true);
    try{
      if(status === 'rejected') {
        let form = {
          userId, 
          nftId, 
          status, 
          message,
          NFTUserWalletAddress: userWalletAddress,
          approver: "MPTokenAdmin",
          MPTAdminAddress_Approver: userInfo?.walletAddress,
          networkId
        }
        
        let res = await adminService.approveUserNFT(form);
        if(res.data){
          setLoading(false);
          notification.open({
            message: 'Success rejecting NFTListed - MPTokenAdmin',
            duration: 0,
          });
          fetchData();
        } else {
          console.log("Error has occured while rejecting NFT");
        }
      } else {
        // approval flow
        const txnReceipt = await masterWhiteListingService.ApproveNftForMintingByTokenAdmin(
          String(userWalletAddress),
          String(nftIPFSURL),
          String(userInfo?.walletAddress),
          String(smartContractData?.masterWhiteListingContract)
        );
  
        console.log("Blockchain Transaction receipt: ", txnReceipt);
        if(txnReceipt) {
          let form = {
            userId, 
            nftId, 
            status, 
            message,
            NFTUserWalletAddress: userWalletAddress,
            approver: "MPTokenAdmin",
            MPTAdminAddress_Approver: userInfo?.walletAddress,
            transactionDetail: txnReceipt,
            networkId
          }
          
          let res = await adminService.approveUserNFT(form);
          if(res.data){
            setLoading(false);
            notification.open({
              message: 'Success approving NFTListed Content - MPTokenAdmin',
              duration: 0,
            });
            fetchData();
          } else {
            console.log("Error has occured while approving NFT");
          }
        }
      }
    } catch (error) {
    console.log(error);
    setLoading(false);
    notification.open({
      message: 'Error',
      description: error.message,
      duration: 0,
    });
  }
  }
  const reportUser = async () => {
    const userId = nftDetails.userId;
    const nftId = nftDetails._id;
    setLoading(true);
    try {
      let res = await adminService.blockUser({
        userId,
        nftId,
        networkId,
        message: "blocked",
      });
      if (res.data) {
        notification.open({
          message: "Success",
          duration: 0,
        });
        fetchData();
      }
    } catch (error) {
      console.log(error);
      notification.open({
        message: "Error",
        description: "something went wrong while blocking user",
        duration: 0,
      });
    }
  };

  return (
    !loading && (
      <>
        <p className="market-super-admin commission-configuration-text">
          NFT Approval
        </p>
        <div className="market-super-admin admin-invites card-container d-flex justify-content-center flex-column">
          <div
            className="market-super-admin card-inner-container"
            style={nftApprovalStyleing.cardContainer}
          >
            <div
              className="d-flex col-12"
              style={{
                justifyContent: "space-between",
                padding: "20px 20px 0",
              }}
            >
              <div className="d-flex justify-content-center align-items-center">
                {sortByVal === "Approved" && (
                  <img
                    className="market-place-admin status-icon"
                    src={"/img/admin-dashboard/approved.png"}
                    alt="nftArt"
                  />
                )}
                {sortByVal === "Rejected" && (
                  <img
                    className="market-place-admin status-icon"
                    src={"/img/admin-dashboard/rejected.png"}
                    alt="nftArt"
                  />
                )}
                {sortByVal === "Pending" && (
                  <img
                    className="market-place-admin status-icon"
                    src={"/img/admin-dashboard/pending.png"}
                    alt="nftArt"
                  />
                )}
                <p className="wallet-status-text">{sortByVal}</p>
              </div>
              <div className="d-flex">
                <div className="sort-by-text">
                <img
                  className="market-place-admin status-icon"
                  src={"/img/admin-dashboard/sort-icon.png"}
                  alt="nftArt"
                />
                Sort By:</div>
                <Select
                  className="sort-by-select"
                  defaultValue={"Approved"}
                  value={sortByVal}
                  onChange={handleChange}
                  options={[
                    {
                      value: "Pending",
                      label: "Pending",
                    },
                    {
                      value: "Approved",
                      label: "Approved",
                    },
                    {
                      value: "Rejected",
                      label: "Rejected",
                    },
                  ]}
                />
              </div>
            </div>
            <Divider />
            {currentPageData?.length > 0 ? (
              currentPageData.map((user) => (
                <div
                  className="market-super-admin escrow-card-component"
                  key={user?._id}
                >
                  <div style={{ display: "flex", marginTop: "10px" }}>
                    <div style={{ flex: "2" }}>
                      <img
                        className="wallet-kycDetails-selfie"
                        src={user?.user?.profilePicUrl || ProfilePic}
                        alt="Nft"
                        style={nftApprovalStyleing.cardImage}
                      />
                      <div style={nftApprovalStyleing.titleResponse}>
                        {" "}
                        {user?.userName}
                      </div>
                    </div>
                    <div style={nftApprovalStyleing.textDiv}>
                      <div style={nftApprovalStyleing.title}>Title</div>
                      <div style={nftApprovalStyleing.titleResponse}>
                        {user?.title}
                      </div>

                      <div style={nftApprovalStyleing.title}>Description</div>
                      <div style={nftApprovalStyleing.titleResponse}>
                        {user?.desc}
                      </div>
                    </div>
                  </div>

                  <Divider style={{ marginTop: "5px", marginBottom: "5px" }} />
                  <div style={{ display: "flex" }}>
                    <div style={{ flex: 1 }}>
                      <div style={{ fontFamily: "Aileron", fontSize: "14px" }}>
                        <div style={nftApprovalStyleing.title}>Category</div>
                        <div style={nftApprovalStyleing.titleResponse}>
                          {user?.category}
                        </div>
                        <div style={nftApprovalStyleing.title}>User Email</div>
                        <div style={nftApprovalStyleing.titleResponse}>
                          {user?.userEmail}
                        </div>
                      </div>
                      <div
                        style={{
                          fontFamily: "Aileron",
                          fontSize: "14px",
                          textAlign: "left",
                          marginTop: "10px",
                        }}
                      >
                        <div style={nftApprovalStyleing.title}>Royalty</div>
                        <div style={nftApprovalStyleing.titleResponse}>
                          {user?.royalty}
                        </div>
                      </div>
                    </div>
                    <div style={{ flex: 1 }}>
                      <div style={nftApprovalStyleing.textDiv}>
                        <div style={nftApprovalStyleing.title}>
                          Asset DataURL Firebase
                        </div>
                        <div style={nftApprovalStyleing.titleResponse}>
                          <a
                            href={`${user?.assetDataURLFirebase}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Click Here
                          </a>
                        </div>
                        <div style={nftApprovalStyleing.title}>
                          Copyright URL Firebase
                        </div>
                        <div style={nftApprovalStyleing.titleResponse}>
                          <a
                            href={`${user?.copyrightURLFirebase}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Click Here
                          </a>
                        </div>
                        <div style={nftApprovalStyleing.title}>
                          Original NFt Url
                        </div>
                        <div style={nftApprovalStyleing.titleResponse}>
                          <a
                            href={`${user?.file?.FirebaseURL}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Click Here
                          </a>
                        </div>
                        <div style={nftApprovalStyleing.title}>
                          Watermark NFT Url
                        </div>
                        <div style={nftApprovalStyleing.titleResponse}>
                          <a
                            href={`${user?.file?.watermarkedUrl}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Click Here
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <Divider style={{ marginTop: "5px", marginBottom: "5px" }} />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "10px",
                    }}
                  >
                    <div style={{ fontFamily: "Aileron", fontSize: "14px" }}>
                      <div style={nftApprovalStyleing.title}>Selling Price</div>
                      <div
                        style={{
                          fontWeight: "500",
                          color: "#F2994A",
                        }}
                      >
                        {user?.sellingPrice}
                      </div>
                    </div>
                    <div style={{ fontFamily: "Aileron", fontSize: "14px" }}>
                      <div style={nftApprovalStyleing.title}>Method</div>
                      <div
                        style={{
                          fontWeight: "500",
                          color: "#F2994A",
                        }}
                      >
                        {user?.method}
                      </div>
                    </div>
                    <div>
                      {/* {(sortByVal === "Approved" ||
                        sortByVal === "Rejected") && (
                        <div style={nftApprovalStyleing.sortValueInCard}>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <img
                              className="market-place-admin status-icon"
                              src={`/img/admin-dashboard/${sortByVal.toLowerCase()}.png`}
                              alt={sortByVal}
                            />
                            <p style={nftApprovalStyleing.sortValueInCardText}>
                              {sortByVal}
                            </p>
                          </div>
                        </div>
                      )}
                      {sortByVal === "Pending" && (
                        <button
                          style={{ display: "flex", alignItems: "center" }}
                          onClick={() => handleOpenModal(user)}
                          className="wallet-status-pending-chip"
                        >
                          <img
                            className="market-place-admin status-icon"
                            src={"/img/admin-dashboard/pending.png"}
                            alt="Pending"
                          />
                          <p style={nftApprovalStyleing.sortValueInCardText}>
                            Pending
                          </p>
                        </button>
                      )} */}
                      {
                        sortByVal !== "Pending" ?
                          user?.adminVerificationStatus ===
                            "approved"
                            ? <Button style={{fontWeight:"bolder"}} className="wallet-status-approved-chip"><img className="market-place-admin sidenav-icon" src={"/img/admin-dashboard/approved.png"} alt="nftArt" />
                              <p>Approved</p></Button>
                            : <Button style={{fontWeight:"bolder"}} className="wallet-status-rejected-chip"><img className="market-place-admin sidenav-icon" src={"/img/admin-dashboard/rejected.png"} alt="nftArt" />
                              <p>Rejected</p></Button>
                          : null
                      }

                      {sortByVal === "Pending" && (
                        <Button style={{fontWeight:"bolder"}} className="wallet-status-pending-chip" onClick={() => handleOpenModal(user)}><img className="market-place-admin sidenav-icon" src={"/img/admin-dashboard/pending.png"} alt="nftArt" />
                          <p>Pending</p></Button>
                      )}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="no-data-found-div">
                <p className="no-data-found-text">No Data Found</p>
              </div>
            )}

            <Modal
              title=""
              centered
              open={openModal}
              onOk={handleClose}
              onCancel={handleClose}
              // closable={false}
              className="wallet-modal"
              footer={[
                !isRejecting && (
                  <>
                    <Button
                      className="wallet-modal-approve-button"
                      onClick={handleApprove}
                      // disabled={isRejecting}
                    >
                      Approve
                    </Button>
                    ,
                    <Button
                      className="wallet-modal-reject-button"
                      type="primary"
                      onClick={() => setIsRejecting(true)}
                      // disabled={isRejecting}
                    >
                      Reject
                    </Button>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "20px",
                      }}
                    >
                      {/* <Button danger onClick={reportUser}>
                        Block User
                      </Button> */}
                      <Popconfirm
                        title={
                          "This will block user, his login and all of his NFTs will be blocked from site."
                        }
                        onConfirm={reportUser}
                        style={{ marginTop: "10px" }}
                      >
                        <Button
                          type="ghost"
                          style={{ fontSize: "10px" }}
                          danger
                        >
                          BLock User
                        </Button>
                      </Popconfirm>
                    </div>
                  </>
                ),
                <span key="textarea-container">
                  {isRejecting && (
                    <>
                      <textarea
                        required
                        className="wallet-modal-textarea"
                        value={nftRejectionMessage}
                        onChange={(e) => setNftRejectionMessage(e.target.value)}
                        placeholder="Type here to rejection note ..."
                        style={{
                          width: "100%",
                          height: "60px",
                          marginBottom: "10px",
                        }}
                      />
                      <Button
                        key="Reject"
                        className="wallet-modal-reject-button"
                        onClick={handleReject}
                      >
                        Reject
                      </Button>
                    </>
                  )}
                </span>,
              ]}
            >
              <span className="wallet-modal-heading-text">
                Are you need to approve this
              </span>
              <span className="wallet-modal-heading-sub-text">
                NFT Approvals?
              </span>
            </Modal>
          </div>
          {/* Pagination  */}
          {filteredNft?.length ? (
            <div style={nftApprovalStyleing.paginationContainer}>
              <Pagination
                current={currentPage}
                total={filteredNft?.length}
                pageSize={pageSize}
                onChange={onPageChange}
                showSizeChanger={false}
                showQuickJumper={false}
              />
              <input
                type="number"
                value={goToPage}
                onChange={(e) => setGoToPage(e.target.value)}
                min="1"
                max={totalPages}
                style={nftApprovalStyleing.goInput}
              />
              <button
                onClick={handleGoToPage}
                style={nftApprovalStyleing.goButton}
              >
                Go
              </button>
            </div>
          ) : null}
        </div>
      </>
    )
  );
};

export default NftApproval;