import React , {useContext} from 'react';
import HeaderNav from './HeaderNav';
import Sidenav from './SideNav';
import { Layout } from 'antd';
import authContext from '../Shared/Auth.service';
import './Nav.scss';

const { Content } = Layout;

// const authService = new AuthService();

// const useUserContext = () => authService.useUserContext();

export default ({children, isNotConnectedToMetamask}) => {

  const { userInfo, setUserInfo } = useContext(authContext);

  return (
    <Layout>
      {userInfo !== undefined && 
        <HeaderNav/>
      }

      <Layout style={{marginTop:'64px'}} className="full-layout-height">
        {userInfo && !isNotConnectedToMetamask && 
          <Sidenav/>
        }

        <Layout style={{ marginLeft: (userInfo && !isNotConnectedToMetamask)? 200 : 0}}>
          <Content>
            {children}
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
}