/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect, useRef, useMemo } from "react";
import { useHistory, Link } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  Typography,
  Form,
  Button,
  Spin,
  Input,
  Select,
  List,
  Progress,
  Popconfirm,
  notification,
  Table,
} from "antd";
import { createGlobalStyle } from "styled-components";
import EXIF from "exif-js";
import $ from "jquery";
import { environment } from "./../environments/environment";
import authContext from "./../../components/components/Shared/Auth.service";
import { SharedService } from "./../components/Shared/Shared.service";
import { NFTCreatorService } from "../components/services/NFTCreator/NFTCreator.service";
import { VideoPlayer } from "../components/mainSlider/VideoPlayer";
import ReCAPTCHA from "react-google-recaptcha";
import { NftFactoryService } from "../components/Shared/Blockchain/NftFactory/NftFactory.service";
import { MetamaskService } from "../components/Shared/Metamask.service";
import { AdminService } from "./../components/services/AdminService/Admin.service";
import axios from "axios";
import { dynamicAttributes } from "../../store/utils";
import { categories } from "../components/constants/filters";
import DateTime from "../components/DateTime";
import moment from "moment";
import "../pages/styles/nftCreate.scss";
import Price from "../assets/images/nft_create/Price.png";
import Bid from "../assets/images/nft_create/bid.png";
import Time from "../assets/images/nft_create/Time.png";
import  CollImg from "../assets/images/collections/coll-item-5.jpg"
const { Title } = Typography;
const { Option } = Select;

//const issuerSuperAdminService = new IssuerSuperAdminService();
const sharedService = new SharedService();
const nftService = new NFTCreatorService();
const NftFactory = new NftFactoryService();
const adminService = new AdminService();

const useSelectedWalletContext = () =>
  new MetamaskService().useSelectedWalletContext();

const APIURL = environment.APIURL;

const GlobalStyles = createGlobalStyle`
  .mainside{
    .connect-wal{
      display: none;
    }
    .logout{
      display: flex;
      align-items: center;
    }
  }
`;

const Createpage = () => {
  const { userInfo, setUserInfo } = useContext(authContext);
  const [files, setFiles] = useState([]);
  const [copyrightChecked, setCopyrightChecked] = useState(true);
  const [copyrightMessage, setCopyrightMessage] = useState("");
  const [watermarkchecked, setWatermarkchecked] = useState(true);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [nftDocument, setNftDocument] = useState([]);
  const [collectionDocument, setCollectionDocument] = useState([]);
  const [fileSizeError, setFileSizeError] = useState(false);
  const [fileTypeError, setFileTypeError] = useState(false);
  const [fileNameError, setFileNameError] = useState(false);
  const [imageDimensionError, setImageDimensionError] = useState(false);
  const [dateTimeError, setDateTimeError] = useState(false);
  const [noExifError, setNoExifError] = useState(false);
  const [fileCount, setFileCount] = useState(0);
  const [disbaleUpload, setDisableUpload] = useState(false);
  const [addProperty, setAddProperty] = useState([
    { propertyType: "", propertyName: "" },
  ]);
  const [NFTDownload, setNFTDownload] = useState("owner");
  const [watermarkType, setWatermarkType] = useState("");
  const [copyrightType, setCopyrightType] = useState("");
  const [copyrightOption, setCopyrightOption] = useState("Confidential");
  const [watermarkOption, setWatermarkOption] = useState("Confidential");
  const [watermarkInputMessage, setWatermarkInputMessage] = useState("");
  const [title, setTitle] = useState("");
  const [categoryName, setCategoryName] = useState("");
  let [fileType, setFileType] = useState({
    accept: ".png,.PNG,.jpg,.JPG,.jpeg,.JPEG,.gif,.GIF,.mp4,.MP4",
  });
  const [showFileType, setShowFileType] = useState("PNG, JPG, GIF, MP4");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState();
  const [usdPrice, setUsdPrice] = useState();
  const [todayEtheriumPriceInUSD, setTodayEtheriumPriceInUSD] = useState();
  const [royalty, setRoyalty] = useState(0);
  const [expired, setExpired] = useState(true);
  const [method, setMethod] = useState("Fixed Price");
  const [bid, setBid] = useState();
  const [usdBid, setUsdBid] = useState();
  const [startingDate, setStartingDate] = useState();
  const [expirationDate, setExpirationDate] = useState();
  const [saveProperty, setSaveProperty] = useState(false);
  const [filesObj, setFilesObj] = useState({
    uploading: false,
    uploadFilePercentage: 0,
    URL: null,
    name: null,
    type: null,
    lastModified: null,
    lastModifiedDate: null,
    size: null,
    exifData: null,
    iptcData: null,
    copyrightCheckedMessage: null,
    watermarkCheckedMessage: null,
  });
  const [collectionName, setCollectionName] = useState("");
  const [tokenSymbol, setTokenSymbol] = useState("");
  const [tokenDescription, setTokenDescription] = useState("");
  const [createCollectFilesObj, setCreateCollectionFilesObj] = useState({
    uploading: false,
    uploadFilePercentage: 0,
    URL: null,
    name: null,
    type: null,
  });
  const [collections, setCollections] = useState([]);
  const [createCollectionType, setCreateCollectionType] = useState({
    type: null,
    collectionName: null,
  });
  const [collectionSelected, setCollectionSelected] = useState({
    collectionName: "",
  });
  const [showCollection, setShowCollection] = useState(false);
  const { selectedWallet, networkId } = useSelectedWalletContext();

  const [waterMarkError, setWaterMarkError] = useState(false);
  const [nftCategories, setNftCategories] = useState([]);
  const [photoSize, setPhotoSize] = useState(15);
  const [videoSize, setVideoSize] = useState(25);
  const [collectionCreationFee, setCollectionCreationFee] = useState(null);
  const [smartContractData, setSmartContractData] = useState({});
  const [hours, setHours] = useState();
  const [minutes, setMinutes] = useState();
  const [offerValidityTime, setOfferValidityTime] = useState();
  const [offerValidityTimeArray, setOfferValidityTimeArray] = useState([]);
  const [fileUploading, setFileUploading] = useState(false);
  const imageDimensionSize = parseInt(environment.maxImageDimensionSize);
  const [image, setImage] = useState();
  const [external_link, setexternalLink] = useState();
  const [generalListingCommission, setGeneralListingCommission] = useState();
  const [generalSellingCommission, setGeneralSellingCommission] = useState();
  const [minPriceError, setMinPriceError] = useState(false);
  const fileInputRef = useRef(null);
  const rowStyle = { height: "36px" };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  useEffect(() => {
    async function getAllCollections() {
      const response = await nftService.getUserCollections(userInfo?._id, {
        networkId,
      });
      if (response.success) {
        setCollections(response.data);
      } else {
        console.error("An Error has occured!");
      }
    }

    if (!networkId) return;

    getAllCollections();
  }, [collectionName, networkId]);

  useEffect(() => {
    let tempArray = [];
    for (let i = 1; i <= 30; i++) {
      tempArray.push({ label: `${i} ${i < 1 ? "Day" : "Days"}`, value: i });
    }
    setOfferValidityTimeArray(tempArray);
  }, []);

  useEffect(async () => {
    setLoading(true);
    setTimeout(async () => {
      if (userInfo?._id) {
        if (!networkId) return;

        //setting ether price
        setTodayEtheriumPriceInUSD(await sharedService.getEtheriumPrice());

        //getting categories
        let res = await nftService.getAllCategories();
        if (res.data?.length > 0) {
          setNftCategories(res.data);
        } else {
          console.error("can not get categories");
          notification.open({
            message: "Error",
            description:
              "Error while tries to get categories, Please try after some time.",
            duration: 0,
          });
        }
        // getting max file size
        let res1 = await nftService.getFileSize();
        if (res1.data) {
          setPhotoSize(res1.data.photoSize);
          setVideoSize(res1.data.videoSize);
        } else {
          console.error("can not get file siz");
          notification.open({
            message: "Error",
            description:
              "Error while tries to get file size, Please try after some time.",
            duration: 0,
          });
        }

        if (userInfo?.status?.KYC && userInfo?.status?.KYC === "pending") {
          return history.push("/KYCUpload");
        }

        let res2 = await adminService.getMarketPlaceConfiguration(
          userInfo._id,
          { networkId }
        );
        if (userInfo?.socialMediaProfile?.goldenBadge?.[networkId]) {
          setCollectionCreationFee(res2?.data?.goldenBadgeCollectionFee);
        } else {
          setCollectionCreationFee(res2?.data?.collectionFee);
        }

        setGeneralListingCommission(parseFloat(res2?.data?.listingFees));
        //setting to 0 as it is in percentage
        //setGeneralSellingCommission(parseFloat(res2?.data?.sellingFees));
        setGeneralSellingCommission(parseFloat(0));

        // Set contractAddress Data
        let res3 = await adminService.getMarketPlaceDeployedSmartContract({
          networkId,
        });
        if (res3?.data) setSmartContractData(res3.data);
        setLoading(false);
      } else {
        return history.push("/account/login");
      }
    }, 400);
  }, [userInfo, networkId]);

  const propertyData =
    useMemo(() => {
      const data = addProperty
        .map((property, index) => {
          if (property.propertyName === "" || property.propertyType === "") {
            return null;
          }
          return {
            propertyName: property.propertyName,
            propertyType: property.propertyType,
          };
        })
        ?.filter((item) => item !== null);

      return data;
    }, [addProperty]) || [];

  const propertyColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <a>{text}</a>,
      title: "Property no",
      dataIndex: "index",
      key: "index",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Age",
      dataIndex: "age",
      key: "age",
      title: "Type",
      dataIndex: "propertyType",
      key: "propertyType",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      title: "Name",
      dataIndex: "propertyName",
      key: "propertyName",
    },
  ];

  const loadVideo = (file) =>
    new Promise((resolve, reject) => {
      try {
        let video = document.createElement("video");
        video.preload = "metadata";

        video.onloadedmetadata = function () {
          resolve(this);
        };

        video.onerror = function () {
          reject("Invalid video. Please select a video file.");
        };

        video.src = window.URL.createObjectURL(file);
      } catch (e) {
        reject(e);
      }
    });

  const uploadFile = async (e) => {
    if (!e.target.files) return;

    const file = e.target.files[0];

    if (!file) return;
    console.log("NFTDetails: ", file);

    let fileTypeArray = [
      "video/mp4",
      "video/MP4",
      "application/pdf",
      "image/png",
      "image/jpg",
      "image/jpeg",
      "image/gif",
      "image/svg",
    ];
    let anyError = false;

    for (let i = 0; i < fileTypeArray.length; i++) {
      if (fileTypeArray.includes(file.type)) {
        setFileTypeError(false);
      } else {
        setFileTypeError(true);
        anyError = true;
        return;
      }
    }

    setImageDimensionError(false);
    setNoExifError(false);
    setFileNameError(false);
    let fileWidthInPixel, fileHeightInPixel, videoDuration;

    if (!file.name || file.name.indexOf(" ") > -1)
      return setFileNameError(true);

    if (
      file.type === "video/mp4" ||
      file.type === "video/MP4" ||
      file.type === "application/pdf"
    ) {
      // video or pdf size
      if (file.size <= videoSize * 1024 * 1024) {
        //50MB for editing max size as per library
        setFileSizeError(false);
      } else {
        setFileSizeError(true);
        anyError = true;
        return;
      }

      const video = await loadVideo(file);
      videoDuration = video.duration;
      console.log("original video duration is: ", videoDuration);
    } else {
      // image size
      if (file.size <= photoSize * 1024 * 1024) {
        //10MB for editing max size as per library
        setFileSizeError(false);
      } else {
        setFileSizeError(true);
        anyError = true;
        return;
      }

      //checking image or gif resolution size in pixel
      let img = new Image();
      img.src = window.URL.createObjectURL(file);
      img.onload = () => {
        if (
          img &&
          (img.width > imageDimensionSize || img.height > imageDimensionSize)
        ) {
          setImageDimensionError(true);
          anyError = true;
          return;
        } else if (img) {
          fileWidthInPixel = img.width;
          fileHeightInPixel = img.height;
        }
      };
    }

    if (fileTypeError || fileSizeError) return;

    let fileSize = file.size;
    let lastModifiedDate = file.lastModifiedDate;
    let lastModified = file.lastModified;
    let exifData, iptcData;
    await EXIF.getData(file, function () {
      var make = EXIF.getTag(this, "Make");
      var model = EXIF.getTag(this, "Model");
      //console.log('make and model : ', make, model);
    });

    setTimeout(() => {
      if (!anyError) {
        const fileType = sharedService.getFileType(file.name);
        let firebaseUrl = "";
        setFilesObj({ ...filesObj, URL: null });
        exifData = file.exifdata;
        iptcData = file.iptcdata;
        console.log("exif", exifData, iptcData);

        // checking for exif data on image file(Not on gif)
        if (
          file.type === "image/png" ||
          file.type === "image/jpg" ||
          file.type === "image/jpeg" ||
          file.type === "image/svg"
        ) {
          const hasExifdata = exifData
            ? !!Object.keys(exifData)?.length
            : false;
          const hasIptcData = iptcData
            ? !!Object.keys(iptcData)?.length
            : false;

          if (!hasExifdata && !hasIptcData) return setNoExifError(true); // no original exif data
        }

        setFileUploading(true);

        try {
          /* upload to firebase starts here */
          nftService.uploadFile(
            file,
            (uploadFilePercent) => {
              setFilesObj({
                ...filesObj,
                uploading: true,
                uploadFilePercentage: 10,
              });
            },
            (err) => {
              console.error(err);
              setFilesObj({ ...filesObj, uploadling: false });
              setFileUploading(false);
            },
            async (response) => {
              if (response.success) {
                const firebaseUrl = response.data.url;
                /* upload to firebase ends here */
                try {
                  const { hash } = await sharedService.uploadDocumentToIPFS({
                    file,
                  });
                  const URL = `${environment.ipfsDomain}/ipfs/${hash}`;
                  console.log("ipfs URL is", URL);
                  setFilesObj({
                    ...filesObj,
                    uploading: false,
                    uploadFilePercentage: 100,
                    URL: URL,
                    name: file.name.replaceAll(" ", "-"),
                    type: fileType.toLowerCase(),
                    lastModified: lastModified,
                    lastModifiedDate: lastModifiedDate,
                    size: fileSize,
                    exifData: exifData,
                    iptcData: iptcData,
                    ...(fileWidthInPixel && { fileWidthInPixel }),
                    ...(fileHeightInPixel && { fileHeightInPixel }),
                    ...(videoDuration && { videoDuration }),
                  });
                  setNftDocument({
                    name: file.name.replaceAll(" ", "-"),
                    type: fileType.toLowerCase(),
                    lastModified: lastModified,
                    lastModifiedDate: lastModifiedDate,
                    size: fileSize,
                    exifData: exifData,
                    iptcData: iptcData,
                    ...(fileWidthInPixel && { fileWidthInPixel }),
                    ...(fileHeightInPixel && { fileHeightInPixel }),
                    ...(videoDuration && { videoDuration }),
                    FirebaseURL: firebaseUrl,
                    IPFSURL: URL,
                    createdOn: new Date().getTime(),
                  });
                } catch (err) {
                  console.error(err);
                  setFilesObj({ ...filesObj, uploadling: false });
                } finally {
                  setFileUploading(false);
                }
              }
            }
          );
        } catch (err) {
          console.error(err);
          setFilesObj({ ...filesObj, uploadling: false });
          setFileUploading(false);
        }
      }
    }, 200);
  };

  // Upload Collection image
  const uploadCollectionFile = async (e) => {
    if (!e.target.files) return;

    const file = e.target.files[0];

    if (!file) return;

    console.log("Newly created collection file: ", file);
    setImage(file.name);
    const fileType = sharedService.getFileType(file.name);
    let firebaseUrl = "";
    setCreateCollectionFilesObj({ ...createCollectFilesObj, URL: null });
    try {
      /* upload to firebase starts here */
      nftService.uploadFile(
        file,
        (uploadFilePercent) => {
          setCreateCollectionFilesObj({
            ...createCollectFilesObj,
            uploading: true,
            uploadFilePercentage: 10,
          });
        },
        (err) => {
          console.error(err);
          setCreateCollectionFilesObj({
            ...createCollectFilesObj,
            uploadling: false,
          });
        },
        async (response) => {
          if (response.success) {
            const firebaseUrl = response.data.url;
            /* upload to firebase ends here */
            try {
              // const { hash } = await sharedService.uploadDocumentToIPFS({ file });
              // const URL = `${environment.ipfsDomain}/ipfs/${hash}`;
              // console.log(URL)
              setCreateCollectionFilesObj({
                ...createCollectFilesObj,
                uploading: false,
                uploadFilePercentage: 100,
                URL: firebaseUrl,
                name: file.name.replaceAll(" ", "-"),
                type: fileType.toLowerCase(),
              });
              setCollectionDocument({
                name: file.name.replaceAll(" ", "-"),
                type: fileType.toLowerCase(),
                FirebaseURL: firebaseUrl,
                createdOn: new Date().getTime(),
              });
            } catch (err) {
              console.error(err);
              setCreateCollectionFilesObj({
                ...createCollectFilesObj,
                uploadling: false,
              });
            }
          }
        }
      );
    } catch (err) {
      console.error(err);
      setCreateCollectionFilesObj({
        ...createCollectFilesObj,
        uploadling: false,
      });
    }
  };

  //handle category change
  const selectCategory = (e) => {
    setCategoryName(e);
    if (e == "Photo" || e == "Avatar") {
      fileType = {
        ...dynamicAttributes(
          "accept",
          ".png,.PNG,.jpg,.JPG,.jpeg,.JPEG,.gif,.GIF,.svg,.SVG"
        ),
      };
      setShowFileType("PNG, JPG, GIF, SVG");
    } else if (e == "Sports") {
      fileType = {
        ...dynamicAttributes(
          "accept",
          ".png,.PNG,.jpg,.JPG,.jpeg,.JPEG,.gif,.GIF,.svg,.SVG,.mp4,.MP4"
        ),
      };
      setShowFileType("PNG, JPG, GIF, SVG, MP4");
    } else if (e == "Memes") {
      fileType = {
        ...dynamicAttributes(
          "accept",
          ".png,.PNG,.jpg,.JPG,.jpeg,.JPEG,.gif,.GIF"
        ),
      };
      setShowFileType("PNG, JPG, GIF");
    } else if (e == "Video") {
      fileType = { ...dynamicAttributes("accept", ".mp4,.MP4,") };
      setShowFileType("MP4");
    } else {
      fileType = {
        ...dynamicAttributes(
          "accept",
          ".png,.PNG,.jpg,.JPG,.jpeg,.JPEG,.gif,.GIF,.svg,.SVG,.mp4,.MP4"
        ),
      };
      setShowFileType("PNG, JPG, GIF, SVG, MP4");
    }
    setFileType(fileType);
  };

  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...addProperty];
    list[index][name] = value;
    setAddProperty(list);
  };

  // handle click event of the remove button
  const handleRemoveClick = (index) => {
    const list = [...addProperty];
    list.splice(index, 1);
    setAddProperty(list);
  };

  // handle click event on the add button
  const handleAddClick = () => {
    setAddProperty([...addProperty, { propertyType: "", propertyName: "" }]);
  };

  const saveNFT = async (_form) => {
    if (userInfo.walletAddress.toLowerCase() === selectedWallet.toLowerCase()) {
      //if not copyright or watermark message then reject it
      if (
        !copyrightMessage ||
        !watermarkInputMessage ||
        !title ||
        !description ||
        !categoryName ||
        !method ||
        !collectionName ||
        !nftDocument ||
        (method !== "Fixed Price" && !offerValidityTime)
      ) {
        alert("Please fill out all the available options!");
        return false;
      }

      setWaterMarkError(false);
      setDateTimeError(false);
      setMinPriceError(false);

      const form = { ..._form };
      form.tokenName = "CAR";
      form.createdBy = userInfo._id;
      form.userId = userInfo._id;
      //form.files = documentsToSubmit;
      form.title = title;
      form.desc = description;
      form.category = categoryName;
      form.method = method;
      form.royalty = royalty;
      form.status = [`createdBy ${userInfo._id}`];
      form.NFTDownload = NFTDownload;

      if (method === "Fixed Price") {
        // checking for minimum price of nft
        if (price <= generalListingCommission + generalSellingCommission) {
          setMinPriceError(true);
          return false;
        }

        form.price = price;
        form.sellingPrice = price;
      } else if (method === "Auction") {
        if (!hours?.length > 0 || !minutes?.length > 0) {
          setDateTimeError(true);
          $("#submit-modal-close").click();
          return;
        }

        // checking for minimum price of nft
        if (bid <= generalListingCommission + generalSellingCommission) {
          setMinPriceError(true);
          return false;
        }

        form.bid = bid;
        form.sellingPrice = bid;
        form.startingDate = startingDate;
        form.expirationDate = expirationDate;
        form.expirationHours = hours;
        form.expirationMinutes = minutes;
        form.expirationTimestamp = new Date(
          new Date(expirationDate).setHours(hours, minutes, 0)
        ).getTime();
      }

      if (method !== "Fixed price") {
        form.offerValidityTime = offerValidityTime;
      }

      form.oldBiddingDetails = [];
      form.biddingDetails = [];

      form.allSellingStatus = [
        {
          method,
          sellingPrice: method === "Fixed Price" ? price : bid,
          startingDate: method === "Auction" ? startingDate : null,
          expirationDate: method === "Auction" ? expirationDate : null,
        },
      ];

      if (createCollectionType.type === "NEW") {
        form.collection = {
          collectionType: createCollectionType.type,
          collectionSelected: {
            collectionName: collectionSelected.collectionName,
          },
          collectionName,
          tokenSymbol,
          tokenDescription,
          collectionDocument,
        };
      } else {
        setLoading(true);
        const response = await nftService.getCollectionDetailByName(
          `${createCollectionType.collectionName}`,
          { networkId }
        );
        if (response.success) {
          form.collection = {
            collectionId: response.data._id,
            collectionType: createCollectionType.type,
            collectionSelected: response.data.collectionSelected,
            collectionName: response.data.collectionName,
            tokenSymbol: response.data.tokenSymbol,
            tokenDescription: response.data.tokenDescription,
            collectionDocument: response.data.collectionDocument,
            NFTMintingContractAddress: response.data.NFTMintingContractAddress,
          };
        } else {
          console.log("An Error has occured!");
        }
      }

      form.property = addProperty;

      nftDocument.copyrightNotice = copyrightChecked;
      nftDocument.copyrightCheckedMessage = copyrightMessage;
      nftDocument.copyrightType = copyrightType;

      nftDocument.watermark = watermarkchecked;
      nftDocument.watermarkType = watermarkType;
      if (watermarkchecked) {
        if (watermarkType === "Add your own") {
          nftDocument.watermarkCheckedMessage = watermarkInputMessage;
        } else {
          nftDocument.watermarkCheckedMessage = watermarkInputMessage;
        }
        nftDocument.watermarkType = watermarkType;
      }

      if (nftDocument.watermark && !nftDocument.watermarkCheckedMessage) {
        setWaterMarkError(true);
        $("#submit-modal-close").click();
        return;
      }

      nftDocument.createdBy = userInfo._id;
      form.file = nftDocument;

      delete form.attachement;
      setLoading(true);
      if (createCollectionType.type === "NEW") {
        console.log("NFTDetails: ", form);
        let form1;
        try {
          if (collectionDocument.FirebaseURL !== "") {
            form1 = {
              userId: userInfo?._id,
              collectionName: collectionName,
              description: tokenDescription,
              image: collectionDocument.FirebaseURL,
              external_link: external_link,
            };
          } else {
            notification.open({
              message: "the collection doesen't have the right metadata image",
              duration: 0,
            });
            setLoading(false);
          }

          const collectionUri = await nftService.createCollectionURI(form1);
          if (collectionUri.success) {
            notification.open({
              message: "Successfully created a New Collection URI",
              duration: 0,
            });
          } else {
            setLoading(false);
            notification.open({
              message: "Error",
              description: collectionUri.error.message,
              duration: 0,
            });
          }
          setLoading(true);
          console.log(collectionUri === "" ? false : collectionUri);
          const txnReceipt = await NftFactory.createCollection(
            String(collectionName),
            String(tokenSymbol),
            String(collectionUri.data),
            String(selectedWallet),
            userInfo?.specificCollectionFee?.[networkId]?.length > 0 ||
              userInfo?.specificCollectionFee?.[networkId] === 0 ||
              isNaN(userInfo?.specificCollectionFee?.[networkId]) === false
              ? String(userInfo?.specificCollectionFee?.[networkId])
              : String(collectionCreationFee),
            String(smartContractData?.nftFactory)
          );

          console.log("Blockchain Transaction receipt: ", txnReceipt);
          if (
            txnReceipt?.hash?.length > 0 ||
            txnReceipt?.blockHash?.length > 0
          ) {
            let blockchainPending = false;
            let blockchainHash = txnReceipt.hash || txnReceipt.blockHash;
            if (txnReceipt.pending && txnReceipt.pending === true) {
              console.log("txn_hash :", txnReceipt.hash);
              blockchainPending = true;
              blockchainHash = txnReceipt.hash || txnReceipt.blockHash;
            }

            form.collection.NFTMintingContractAddress =
              txnReceipt?.events?.collectionCreated?.returnValues?.deployedAt;

            let formBlockchain = {
              userId: userInfo?._id,
              collectionName: collectionName,
              collectionCreatedBy: selectedWallet,
              transactionDetail: txnReceipt,
              networkId,
              collectionUri,
              collectionCreationFee:
                userInfo?.specificCollectionFee?.[networkId]?.length > 0 ||
                userInfo?.specificCollectionFee?.[networkId] === 0 ||
                isNaN(userInfo?.specificCollectionFee?.[networkId]) === false
                  ? String(userInfo?.specificCollectionFee?.[networkId])
                  : String(collectionCreationFee),
              blockchainPending,
              blockchainHash,
            };
            setLoading(true);
            const res = await nftService.createCollectionBlockchain(
              formBlockchain
            );

            if (res.data) {
              const response = await nftService.createNFT({
                ...form,
                blockchainTransctionId: res.data?.id,
                blockchainPending,
                blockchainHash,
                networkId,
              });
              if (response.success) {
                notification.open({
                  message: "Successfully created a new Nft",
                  duration: 0,
                });

                setLoading(false);
                setTimeout(
                  () =>
                    (window.location.href = `/item-detail/${response.data.nftId}`),
                  500
                );
              } else {
                if (
                  response.error.message
                    ?.toLowerCase()
                    ?.indexOf("network error") > -1
                ) {
                  notification.open({
                    message: "NFT Creation Will Take Some Time!",
                    description:
                      "Sorry! Your NFT creation is taking longer time to create nft with watermark of big file. Please check your profile page after 5 minutes or more. Once it gets created succesfully it will show be showing there. No need to create same or duplicate NFT. Meanwhile you can wait here or go to profile page!",
                    className: "nft-create-notification",
                    duration: 0,
                  });
                  setTimeout(
                    () => (window.location.href = "/nft"),
                    2 * 60 * 1000
                  );
                  $("#submit-modal-close").click();
                } else {
                  notification.open({
                    message: response.error.message,
                    duration: 0,
                  });
                  setLoading(false);
                }
              }
            } else {
              setLoading(false);
              console.log("An Error has occured!");
            }
          }
        } catch (error) {
          console.error(error);
          if (error.message?.toLowerCase()?.indexOf("network error") > -1) {
            notification.open({
              message: "NFT Creation Will Take Some Time!",
              description:
                "Sorry! Your NFT creation is taking longer time to create nft with watermark of big file. Please check your profile page after 5 minutes or more. Once it gets created succesfully it will show be showing there. No need to create same or duplicate NFT. Meanwhile you can wait here or go to profile page!",
              className: "nft-create-notification",
              duration: 0,
            });
            setTimeout(() => (window.location.href = "/nft"), 2 * 60 * 1000); //2 minutes

            $("#submit-modal-close").click();
          } else {
            notification.open({
              message: error.message,
              duration: 0,
            });
            setLoading(false);
          }
        }
      } else {
        // when we create a new nft on a existing-collection
        try {
          console.log("NFTDetails: ", form);
          const response = await nftService.createNFT({ ...form, networkId });
          if (response.success) {
            notification.open({
              message: "Successfully created a new Nft",
              duration: 0,
            });

            //setLoading(false);
            setTimeout(() => (window.location.href = "/nft"), 500);
          } else {
            setLoading(false);
            notification.open({
              message: "Error",
              description: response.error.message,
              duration: 0,
            });
          }
        } catch (error) {
          setLoading(false);
          notification.open({
            message: error.message,
            duration: 0,
          });
        }
      }
    } else {
      setLoading(false);
      notification.open({
        message: `This wallet address - ${selectedWallet} is not whitelisted by Admin`,
        duration: 0,
      });
    }
  };

  const handleCaptchaChange = (value) => {
    setExpired(false);
    // if value is null recaptcha expired
    if (value === null) setExpired(true);
  };

  const isCollectionExists = async () => {
    const collectionData = {
      collectionName: `${collectionName}`,
      tokenSymbol: `${tokenSymbol}`,
      networkId,
    };
    try {
      // this is only for checking if collection exist then reject it or just response back success with no db update
      const response = await nftService.checkCollection(collectionData);
      if (response.success) {
        notification.open({
          message: `${collectionName} successfully created`,
          duration: 0,
        });
        setShowCollection(true);
        setCollectionSelected({
          collectionName: collectionName.toLowerCase(),
        });
        setCollectionName(collectionName);
      } else {
        notification.open({
          message: response.error.message,
          duration: 0,
        });
        setCollectionName("");
        setTokenSymbol("");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleCollectionClick = (collectionName, tokenSymbol) => {
    setCreateCollectionType({
      type: "EXISTING",
      collectionName: collectionName,
    });
    setCollectionSelected({
      collectionName: collectionName,
    });
    setCollectionName(collectionName);
    setTokenSymbol(tokenSymbol);
  };

  const handleShow = () => {
    setMethod("Fixed Price");
    document.getElementById("tab_opt_1")?.classList.add("show");
    document.getElementById("tab_opt_1")?.classList.remove("hide");
    document.getElementById("tab_opt_2")?.classList.remove("show");
    document.getElementById("btn1")?.classList.add("active");
    document.getElementById("btn2")?.classList.remove("active");
    document.getElementById("btn3")?.classList.remove("active");
  };
  const handleShow1 = () => {
    setMethod("Auction");
    document.getElementById("tab_opt_1")?.classList.add("hide");
    document.getElementById("tab_opt_1")?.classList.remove("show");
    document.getElementById("tab_opt_2")?.classList.add("show");
    document.getElementById("btn1")?.classList.remove("active");
    document.getElementById("btn2")?.classList.add("active");
    document.getElementById("btn3")?.classList.remove("active");
  };
  const handleShow2 = () => {
    setMethod("Open for Bids");
    document.getElementById("tab_opt_1")?.classList.add("hide");
    document.getElementById("tab_opt_2")?.classList.add("hide");
    document.getElementById("tab_opt_1")?.classList.remove("show");
    document.getElementById("tab_opt_2")?.classList.remove("show");
    document.getElementById("btn1")?.classList.remove("active");
    document.getElementById("btn2")?.classList.remove("active");
    document.getElementById("btn3")?.classList.add("active");
  };

  // this is sample test api function to check the long time api response issue on server
  const getLongTimeApiResponse = async () => {
    try {
      const response = await nftService.getLongTimeApiResponse();
      if (response.success) {
        notification.open({
          message: `got akash`,
        });
      } else {
        notification.open({
          message: response.error,
          duration: 0,
        });
      }
    } catch (error) {
      console.error(error);
      if (error.message?.toLowerCase()?.indexOf("network error") > -1) {
        notification.open({
          message: "NFT Creation Will Take Some Time!",
          description:
            "Sorry! Your NFT creation is taking longer time to create nft with watermark of big file. Please check your profile page after 5 minutes. Once it gets created succesfully it will show be showing there. No need to create same or duplicate NFT. Meanwhile you can wait or refresh this page and can go to profile page!",
          className: "nft-create-notification",
          duration: 0,
        });
      } else {
        notification.open({
          message: error.message,
          duration: 0,
        });
      }
    }
  };

  return (
    <div>
      <GlobalStyles />
      <section className="jumbotron breadcumb no-bg nft-create">
        <div
          className="mainbreadcumb nft-create-page-banner"
          style={{ padding: "90px 0", marginTop: "100px" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h1 className="nft-create-title">Create a NFT</h1>
                <p className="nft-create-para">
                  Create NFTs and explore worldwide
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="nft-create container">
        {nftCategories?.length > 0 && (
          <div className="row">
            <div className="col-lg-8 col-md-8 offset-lg-1 mb-5 m-0">
              {/* <p className="nft-create nft-required-fields">
                Required fields{" "}
                <span className="nft-create required-icon">*</span>
              </p> */}
              <Form labelAlign="left" form={form}>
                <h5 className="nft-create nft-field-title">
                  Category Name
                  <span className="nft-create required-icon">*</span>
                </h5>
                <p className="nft-create nft-field-sub-title">
                  This is the category where your item will appear.
                </p>

                {/* <Button
                  onClick={getLongTimeApiResponse}
                  className="btn btn-primary"
                  title="getLongTimeApiResponse"
                >
                  getLongTimeApiResponse
                </Button> */}

                <Select
                  className="nft-create form-control-dropdown"
                  name="category"
                  id="category"
                  placeholder="Select an option from given categories"
                  value={categoryName}
                  onChange={(e) => selectCategory(e)}
                  allowClear
                >
                  {nftCategories?.length > 0 &&
                    nftCategories.map((ctg) => (
                      <Option value={`${ctg.name}`}>{ctg.name}</Option>
                    ))}
                </Select>

                <h5 className="nft-create nft-field-title">
                  Upload File<span className="nft-create required-icon">*</span>
                </h5>
                <p className="nft-create nft-field-sub-title">
                  File types supported: JPG, PNG, GIF, SVG, MP4, WEBM, MP3, WAV,
                  OGG, GLB, GLTF.{" "}
                </p>

                <div className="nft-create d-create-file d-flex flex-column">
                  <div className="d-flex">
                    <input
                      type="text"
                      value={nftDocument.name}
                      key={`${nftDocument.URL}`}
                      disabled
                      className="nft-create form-control"
                      placeholder="No file chooses"
                    />
                    <div className="browse">
                      <input
                        type="file"
                        onChange={(e) => {
                          uploadFile(e);
                        }}
                        disabled={loading || disbaleUpload}
                        required
                        accept={fileType?.accept}
                        id="get_file"
                        style={{ display: "none" }}
                        ref={fileInputRef}
                      />
                      <button
                        onClick={handleButtonClick}
                        disabled={loading || disbaleUpload}
                        className="nft-create chooseBtn"
                      >
                        Choose
                      </button>
                    </div>
                  </div>

                  <div className="nft-create error-div">
                    {fileSizeError && (
                      <p className="nft-create error-msg">
                        Max Size: Image – {photoSize} MB, Gif - {photoSize} MB,
                        Video - {videoSize} MB
                      </p>
                    )}

                    {fileTypeError && (
                      <p className="nft-create error-msg">Invalid File Type</p>
                    )}

                    {fileNameError && (
                      <p className="nft-create error-msg">
                        Invalid File Name. It should not contain spaces or any
                        special characters!
                      </p>
                    )}

                    {imageDimensionError && (
                      <p className="nft-create error-msg">
                        Uploaded Image or GIF file's Width or Height Dimension
                        Size should be less than {imageDimensionSize}px.
                      </p>
                    )}

                    {noExifError && (
                      <p className="nft-create error-msg">
                        Uploaded Image file do not have any Exif or Iptc data.
                        So please upload a original image with these metadata!
                      </p>
                    )}
                  </div>

                  {filesObj.uploadFilePercentage > 0 && (
                    <>
                      <Progress percent={filesObj.uploadFilePercentage} />
                      <br />
                    </>
                  )}
                  <br />
                </div>

                <h5 className="nft-create nft-field-title">
                  Title<span className="nft-create required-icon">*</span>
                </h5>
                <input
                  type="text"
                  name="title"
                  id="title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  className="nft-create form-control"
                  placeholder="Enter NFT title"
                  required
                />

                <div className="spacer-10"></div>

                <h5 className="nft-create nft-field-title">
                  Description<span className="nft-create required-icon">*</span>
                </h5>

                <textarea
                  data-autoresize
                  name="desc"
                  id="desc"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  className="nft-create form-control"
                  placeholder="Type your NFT description"
                  style={{ minHeight: "140px" }}
                ></textarea>

                <div className="spacer-10"></div>

                <h5 className="nft-create nft-field-title">
                  Pricing<span className="nft-create required-icon">*</span>
                </h5>
                <p className="nft-create nft-field-sub-title">
                  Select type for NFt marketplace
                </p>

                {/* <input type="number" name="price" id="price" min="0" value={price} onChange={(e) => setPrice(e.target.value)} className="form-control" placeholder="enter price for one item (ETH)" required /> */}
                <div className="nft-create field-set">
                  <div className="nft-create de_tab tab_methods">
                    <ul className="nft-create de_nav text-left mx-0">
                      <li
                        id="btn1"
                        className="nft-create active"
                        onClick={handleShow}
                      >
                        <span>
                          <img
                            src={Price}
                            alt="price"
                            className="nft-create pricing_img"
                          />
                          <p className="nft-create pricing_img_text">
                            Fixed Price
                          </p>
                        </span>
                      </li>
                      <li id="btn2" onClick={handleShow1}>
                        <span>
                          <img
                            src={Time}
                            alt="time"
                            className="nft-create pricing_img"
                          />
                          <p className="nft-create pricing_img_text">Auction</p>
                        </span>
                      </li>
                      <li id="btn3" onClick={handleShow2}>
                        <span>
                          <img
                            src={Bid}
                            alt="bid"
                            className="nft-create pricing_img"
                          />
                          <p className="nft-create pricing_img_text">Bid</p>
                        </span>
                      </li>
                    </ul>

                    <div className="de_tab_content pt-3">
                      <div id="tab_opt_1">
                        <div className="d-flex">
                          <div className="nft-create price-in-dollar">
                            <h5 className="nft-create nft-field-title">
                              Price (USD)
                              <span className="nft-create required-icon">
                                *
                              </span>
                            </h5>
                            <input
                              type="text"
                              name="item_price"
                              min="0"
                              max="70"
                              id="item_price"
                              className="nft-create form-control"
                              placeholder="Enter price for one item (USD)"
                              value={usdPrice}
                              onChange={(e) => {
                                setUsdPrice(e.target.value);
                                const convertedAmount = (
                                  parseFloat(e.target.value) /
                                  todayEtheriumPriceInUSD
                                ).toFixed(8);
                                setPrice(convertedAmount);
                              }}
                            />
                          </div>
                          {usdPrice && method === "Fixed Price" && (
                            <div className="nft-create price-in-eth">
                              <h5 className="nft-create nft-field-title">
                                Price (ETH)
                                <span className="nft-create required-icon">
                                  *
                                </span>
                              </h5>
                              <input
                                type="text"
                                name="item_price"
                                min="0"
                                max="70"
                                id="item_price"
                                className="nft-create form-control"
                                disabled
                                placeholder="Enter price for one item (ETH)"
                                value={price}
                                // onChange={(e) => {
                                //   setPrice(e.target.value);
                                // }}
                              />
                            </div>
                          )}
                        </div>
                      </div>

                      <div id="tab_opt_2" className="hide">
                        <div className="d-flex">
                          <div className="nft-create price-in-dollar">
                            <h5 className="nft-create nft-field-title">
                              Minimum BID (USD)
                              <span className="nft-create required-icon">
                                *
                              </span>
                            </h5>
                            <input
                              type="text"
                              name="item_price_bid"
                              id="item_price_bid"
                              className="nft-create form-control"
                              placeholder="Enter minimum bid (USD)"
                              value={usdBid}
                              onChange={(e) => {
                                setUsdBid(e.target.value);
                                const convertedAmount = (
                                  parseFloat(e.target.value) /
                                  todayEtheriumPriceInUSD
                                ).toFixed(8);
                                setBid(convertedAmount);
                              }}
                            />
                          </div>

                          {usdBid && method === "Auction" && (
                            <div className="nft-create price-in-eth">
                              <h5 className="nft-create nft-field-title">
                                Minimum BID (ETH)
                                <span className="nft-create required-icon">
                                  *
                                </span>
                              </h5>
                              <input
                                type="text"
                                name="item_price_bid"
                                id="item_price_bid"
                                className="nft-create form-control"
                                placeholder="Enter minimum bid (ETH)"
                                disabled
                                value={bid}
                                // onChange={(e) => {
                                //   setBid(e.target.value);
                                // }}
                              />
                            </div>
                          )}
                        </div>

                        <div className="spacer-20"></div>
                        <div className="row">
                          <div className="col-md-6">
                            <h5 className="nft-create nft-field-title">
                              Start Date
                              <span className="nft-create required-icon">
                                *
                              </span>
                            </h5>
                            <input
                              type="date"
                              name="bid_starting_date"
                              id="bid_starting_date"
                              className="nft-create form-control"
                              min={moment(new Date()).format("YYYY-MM-DD")}
                              value={startingDate}
                              onChange={(e) => {
                                setStartingDate(e.target.value);
                              }}
                            />
                          </div>
                          <div className="col-md-6">
                            <h5 className="nft-create nft-field-title">
                              Expiry Date
                              <span className="nft-create required-icon">
                                *
                              </span>
                            </h5>
                            <input
                              type="date"
                              name="bid_expiration_date"
                              id="bid_expiration_date"
                              className="nft-create form-control"
                              value={expirationDate}
                              onChange={(e) => {
                                setExpirationDate(e.target.value);
                              }}
                              min={moment(
                                new Date(startingDate?.replaceAll("-", "/"))
                              )
                                .add(1, "days")
                                .format("YYYY-MM-DD")}
                              disabled={!startingDate}
                            />
                          </div>

                          {method === "Auction" && dateTimeError && (
                            <p className="nft-create error-msg">
                              Please Input Auction Expiration Time in Hours and
                              Minutes!
                            </p>
                          )}

                          {method === "Auction" && (
                            <div
                              className="col-md-12"
                              style={{ marginBottom: "20px" }}
                            >
                              <h6 className="nft-create nft-field-title">
                                Auction Expiration Time* in 24 Hours Format(in
                                your Timezone)
                              </h6>
                              {expirationDate && (
                                <DateTime
                                  setHours={setHours}
                                  setMinutes={setMinutes}
                                  minutes={minutes}
                                  hours={hours}
                                  expirationDate={expirationDate}
                                />
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {(method === "Open for Bids" || method === "Auction") &&
                  offerValidityTimeArray?.length > 0 && (
                    <>
                      <h5 className="nft-create nft-field-title">
                        Pick a time
                        <span className="nft-create required-icon">*</span>
                      </h5>
                      <Select
                        placeholder="Select a number of days"
                        className="nft-create form-control-dropdown"
                        optionFilterProp="children"
                        onChange={(val) => setOfferValidityTime(val)}
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        defaultValue={offerValidityTime}
                        options={[...offerValidityTimeArray]}
                      />
                      <h6 className="nft-create nft-field-sub-title">
                        {" "}
                        Specify the Time in Days by which buyer has to do the
                        final NFT puchase after winning Bids or Auction*
                      </h6>
                    </>
                  )}

                <h5
                  className="nft-create nft-field-title"
                  style={{ marginTop: "15px" }}
                >
                  Royalties(%)
                  <span className="nft-create required-icon">*</span>
                </h5>
                <input
                  type="number"
                  max="70"
                  min="0"
                  name="royalty"
                  id="royalty"
                  value={royalty}
                  onChange={(e) => setRoyalty(e.target.value)}
                  className={
                    royalty > 70
                      ? "nft-create form-control invalid"
                      : "nft-create form-control"
                  }
                  placeholder="suggested: 0, 10%, 20%, 30%. Maximum is 70%"
                  required
                />
                {royalty > 70 && (
                  <p className="nft-create error-msg">
                    Royalty Must be less than 70%!!
                  </p>
                )}

                <div className="d-flex bd-highlight nft-create properties-div">
                  <div className="nft-create bd-highlight d-flex justify-content-center align-items-center">
                    <h5 style={{ marginBottom: "10px" }}>
                      <i className="fa fa-list-ul nft-create properties-icon"></i>
                    </h5>
                  </div>
                  <div className="flex-grow-1 bd-highlight">
                    <h5 className="nft-create nft-field-title">
                      Properties
                      <span className="nft-create required-icon">*</span>
                    </h5>
                  </div>
                  <div className="bd-highlight">
                    <button
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#propertyModal"
                      className="nft-create create-btn"
                    >
                      <i className="fa fa-plus  nft-create plus-icon"></i>{" "}
                      CREATE
                    </button>
                  </div>
                </div>

                <div className="properties">
                  {saveProperty && addProperty && (
                    <Table
                      className="nft-create"
                      columns={propertyColumns}
                      dataSource={propertyData.map((item, i) => ({
                        ...item,
                        key: i,
                      }))}
                      pagination={false}
                      rowStyle={() => rowStyle}
                    />
                  )}
                </div>

                <div className="collection-container">
                  <div className="d-flex bd-highlight mt-4">
                    <div className="bd-highlight">
                      <h5>
                        <i className="fa fa-list-ul nft-create properties-icon"></i>
                      </h5>
                    </div>
                    <div
                      className="flex-grow-1 bd-highlight"
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <h5 className="nft-create nft-field-title">
                        Collection
                        <span className="nft-create required-icon">*</span>
                      </h5>
                      <p className="nft-create collection-subtitle">
                        Must choose or create collection for NFT's
                      </p>
                    </div>
                    <div className="bd-highlight">
                      <button
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#collectionModal"
                        className="nft-create create-btn"
                        onClick={() => setCreateCollectionType({ type: "NEW" })}
                      >
                        <i className="fa fa-plus nft-create plus-icon"></i>{" "}
                        CREATE
                      </button>
                    </div>
                  </div>

                  <div className="get_collections">
                    <div className="nft-create collection_grid d-flex flex-row">
                        {createCollectionType.type === "NEW" &&
                          showCollection && (
                            <>
                              <button
                                className="nft-create collection_btn"
                                style={{ border: "1px solid #2e333c" }}
                              >
                                <div className="collection_image_container">
                                  <img
                                    src={collectionDocument.FirebaseURL}
                                    alt="Col"
                                    className="collection_image"
                                    loading="lazy"
                                  />
                                </div>
                                <div className="create_text_container">
                                  <span
                                    className="txt_create"
                                    style={{ wordWrap: "break-word" }}
                                  >
                                    {collectionName}
                                  </span>
                                  <span className="txt_ERC">{tokenSymbol}</span>
                                </div>
                              </button>
                            </>
                          )}

                        {collections &&
                          collections.map((collection) => (
                            <button
                              className={`${
                                collectionSelected.collectionName ===
                                collection.collectionName
                                  ? "nft-create collection_btn border_active"
                                  : "nft-create collection_btn"
                              }`}
                              key={collection._id}
                              onClick={() =>
                                handleCollectionClick(
                                  collection.collectionName,
                                  collection.tokenSymbol
                                )
                              }
                            >
                              <div className="collection_image_container">
                                <img
                                src={collection.collectionDocument.FirebaseURL}
                                  alt="Col"
                                  className="collection_image"
                                  loading="lazy"
                                />
                              </div>
                              <div className="create_text_container">
                                <span
                                  className="txt_create"
                                  style={{ wordWrap: "break-word" }}
                                >
                                  {collection.collectionName}
                                </span>
                                <span className="txt_ERC">
                                  {collection.tokenSymbol}
                                </span>
                              </div>
                            </button>
                          ))}
                      </div>
                      </div>
                    </div>
                <div className="nft-create nft-add">
                  <div className="nft-add-container">
                    <h5 className="nft-create nft-field-title">
                      Add Copyright Notice{" "}
                      <span className="nft-create required-icon">*</span>
                    </h5>
                    {copyrightChecked && (
                      <div style={{ marginBottom: "20px" }}>
                        <input
                          type="radio"
                          value="Add your own"
                          name="type"
                          onChange={(e) => setCopyrightType(e.target.value)}
                        />{" "}
                        Add your own
                        <input
                          type="radio"
                          value="Predefined"
                          name="type"
                          style={{ marginLeft: "20px" }}
                          onChange={(e) => setCopyrightType(e.target.value)}
                        />{" "}
                        Predefined
                      </div>
                    )}

                    {copyrightType === "Add your own" && (
                      <input
                        type="text"
                        name="copyrightmessage"
                        value={copyrightMessage}
                        onChange={(e) => setCopyrightMessage(e.target.value)}
                        id="copyrightmessage"
                        className="nft-create form-control"
                        placeholder="Add Copyright Text"
                      />
                    )}

                    {copyrightType === "Predefined" && (
                      <div
                        style={{
                          marginBottom: "10px",
                          marginTop: "10px",
                          marginLeft: "5px",
                        }}
                      >
                        <Select
                          defaultValue=""
                          className="nft-create form-control-dropdown"
                          onChange={(value) => setCopyrightMessage(value)}
                        >
                          <Option value="Confidential">Confidential</Option>
                          <Option value="Do Not Copy">Do Not Copy</Option>
                          <Option value="Draft">Draft</Option>
                          <Option value="Sample">Sample </Option>
                        </Select>
                      </div>
                    )}
                  </div>
                  <div className="nft-add-container">
                    <h5 className="nft-create nft-field-title">
                      Add WaterMark to File
                      <span className="nft-create required-icon">*</span>
                    </h5>

                    {watermarkchecked && (
                      <div style={{ marginBottom: "20px" }}>
                        <input
                          type="radio"
                          value="Add your own"
                          name="types"
                          onChange={(e) => setWatermarkType(e.target.value)}
                        />{" "}
                        Add your own
                        <input
                          type="radio"
                          value="Predefined"
                          name="types"
                          style={{ marginLeft: "20px" }}
                          onChange={(e) => setWatermarkType(e.target.value)}
                        />{" "}
                        Predefined
                      </div>
                    )}

                    {watermarkType === "Add your own" && (
                      <input
                        type="text"
                        name="watermarkmessage"
                        value={watermarkInputMessage}
                        onChange={(e) =>
                          setWatermarkInputMessage(e.target.value)
                        }
                        id="watermarkmessage"
                        className="nft-create form-control"
                        placeholder="Add WaterMark Text"
                      />
                    )}

                    {watermarkType === "Predefined" && (
                      <div
                        style={{
                          marginBottom: "10px",
                          marginTop: "10px",
                          marginLeft: "5px",
                        }}
                      >
                        <Select
                          placeholder="Select"
                          defaultValue=""
                          className="nft-create form-control-dropdown"
                          onChange={(value) => setWatermarkInputMessage(value)}
                        >
                          <Option value="Confidential">Confidential</Option>
                          <Option value="Do Not Copy">Do Not Copy</Option>
                          <Option value="Draft">Draft</Option>
                          <Option value="Sample">Sample </Option>
                        </Select>
                      </div>
                    )}
                  </div>
                </div>
                <div className="nft-create submit_btn_nft">
                  <Button
                    size="large"
                    type="primary"
                    htmlType="submit"
                    className="nft-create submit_btn"
                    loading={loading}
                    data-bs-toggle="modal"
                    data-bs-target="#submitModal"
                    disabled={
                      fileSizeError ||
                      fileTypeError ||
                      noExifError ||
                      fileNameError ||
                      (!title && !royalty) ||
                      royalty > 70 ||
                      (method === "Fixed Price" && (!price || !usdPrice)) ||
                      (method === "Open for Bids" && !offerValidityTime) ||
                      (method === "Auction" &&
                        (!bid ||
                          !usdBid ||
                          !startingDate ||
                          !expirationDate ||
                          !hours ||
                          !minutes ||
                          !offerValidityTime)) ||
                      !copyrightMessage ||
                      !watermarkInputMessage ||
                      !collectionName ||
                      fileUploading
                    }
                    onClick={() => {
                      setMinPriceError(false);
                    }}
                  >
                    SUBMIT
                  </Button>
                </div>
                <br />
                <br />
              </Form>
            </div>

            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
              <div className="nft-create nft-preview-card">
                <h5 className="nft-create nft-field-title">Preview item</h5>
                <div className="nft__item m-0">
                  <div className="nft__item_wrap">
                    <span>
                      {nftDocument.type &&
                        (nftDocument.type === "mp4" ||
                          nftDocument.type === "MP4") && (
                          <VideoPlayer
                            linkSrc={nftDocument.FirebaseURL}
                            playerId="player-1"
                            coverId="nft-cover"
                            playButtonId="play-1"
                            subsButtonId="subs-1"
                            height="200px"
                            autoplay="off"
                          />
                        )}
                      {nftDocument.type &&
                        (nftDocument.type === "pdf" ||
                          nftDocument.type === "PDF") && (
                          <embed
                            src={nftDocument.FirebaseURL}
                            type="application/pdf"
                            frameBorder="0"
                            scrolling="auto"
                            height="300px"
                            width="100%"
                          />
                        )}
                      {nftDocument.type &&
                        nftDocument.type !== "pdf" &&
                        nftDocument.type !== "PDF" &&
                        nftDocument.type !== "mp4" &&
                        nftDocument.type !== "MP4" && (
                          <img
                            src={nftDocument.FirebaseURL}
                            id="get_file_2"
                            className="lazy nft__item_preview"
                            alt={nftDocument.URL}
                            style={{ height: "250px" }}
                          />
                        )}
                      {!nftDocument.type && (
                        <img
                        src={CollImg}
                          id="get_file_2"
                          className="lazy nft__item_preview"
                          alt=""
                          height="250px"
                        />
                      )}
                    </span>
                  </div>
                  <div className="nft__item_info">
                    <span>
                      <h4 style={{ textAlign: "left" }}>
                        {nftDocument.type
                          ? nftDocument.name
                            ? nftDocument.name
                            : "Pinky Ocean"
                          : "Pinky Ocean"}
                      </h4>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            {/* Collection Modal */}
            <div
              className="modal fade"
              id="collectionModal"
              tabIndex="-1"
              aria-labelledby="collectionModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="collectionModalLabel">
                      Add Collection (ERC 721)
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <form>
                      <div className="d-create-file">
                        <p id="file_name">PNG, JPG, JPEG, GIF</p>
                        {collectionDocument && (
                          <p key={`${collectionDocument.URL}`}>
                            {collectionDocument.name}
                          </p>
                        )}
                        <div className="browse">
                          <input
                            required
                            type="button"
                            id="get_file"
                            className="btn-main"
                            value="Browse"
                          />
                          <input
                            required
                            id="attachment"
                            name="attachment"
                            type="file"
                            multiple
                            onChange={uploadCollectionFile}
                            accept=".png,.PNG,.jpg,.JPG,.jpeg,.JPEG,.jpeg,.JPEG,.gif,.GIF,.mp4,.MP4,.PDF,.pdf"
                          />
                        </div>
                        {createCollectFilesObj.uploadFilePercentage > 0 && (
                          <>
                            <Progress
                              percent={
                                createCollectFilesObj.uploadFilePercentage
                              }
                            />
                            <br />
                          </>
                        )}{" "}
                      </div>
                      <div className="form-group">
                        <label
                          for="displayName"
                          style={{ marginTop: "20px" }}
                          className="nft-create nft-field-title"
                        >
                          Collection Name*
                        </label>
                        <input
                          type="text"
                          required
                          className="nft-create form-control"
                          id="displayName"
                          value={collectionName}
                          onChange={(e) => setCollectionName(e.target.value)}
                          aria-describedby="displayNameHelp"
                          placeholder="Enter Collection Name"
                        />
                        <small
                          id="displayNameHelp"
                          className="form-text text-muted"
                        >
                          Token name cannot be changed in future.
                        </small>
                      </div>
                      <div className="form-group">
                        <label
                          for="tokenSymbol"
                          className="nft-create nft-field-title"
                        >
                          Symbol*
                        </label>
                        <input
                          type="text"
                          required
                          className="nft-create form-control"
                          id="tokenSymbol"
                          value={tokenSymbol}
                          onChange={(e) => setTokenSymbol(e.target.value)}
                          aria-describedby="tokenSymbolHelp"
                          placeholder="Enter Token Symbol Name"
                        />
                      </div>
                      <div className="form-group">
                        <label
                          for="tokenDescription"
                          className="nft-create nft-field-title"
                        >
                          Description
                        </label>
                        <input
                          type="text"
                          className="nft-create form-control"
                          id="tokenDescription"
                          value={tokenDescription}
                          onChange={(e) => setTokenDescription(e.target.value)}
                          aria-describedby="tokenDescription"
                          placeholder="Spread some words about your token collection"
                        />
                      </div>
                      <button
                        type="button"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        className="nft-create create-collection-btn"
                        disabled={!collectionName || !tokenSymbol}
                        onClick={isCollectionExists}
                      >
                        Create Collection
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            {/* Property Modal Box */}
            <div
              className="modal fade"
              id="propertyModal"
              tabIndex="-1"
              aria-labelledby="propertyModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5
                      className="nft-create modal-title"
                      id="propertyModalLabel"
                    >
                      Add Property
                    </h5>
                    {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                  </div>
                  <div className="modal-body">
                    {addProperty.map((x, i) => {
                      return (
                        <div className="col-md-12 property_form" key={i}>
                          <div className="col-md-4">
                            <input
                              placeholder="Property Type"
                              className="nft-create form-control"
                              name="propertyType"
                              value={x.propertyType}
                              onChange={(e) => handleInputChange(e, i)}
                            />
                          </div>
                          <div className="col-md-4 me-2 ms-2">
                            <input
                              className="nft-create form-control"
                              placeholder="Property Name"
                              name="propertyName"
                              value={x.propertyName}
                              onChange={(e) => handleInputChange(e, i)}
                            />
                          </div>
                          <div className="col-md-2 remove_btn">
                            {addProperty.length !== 1 && (
                              <button
                                className="me-2 btn btn-danger rounded"
                                onClick={() => handleRemoveClick(i)}
                              >
                                <i className="fa fa-minus"></i>
                              </button>
                            )}
                          </div>
                        </div>
                      );
                    })}
                    <button className="AddMore" onClick={handleAddClick}>
                      Add More
                    </button>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      className="nft-create save-changes-btn"
                      onClick={() => setSaveProperty(true)}
                    >
                      Save changes
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/* Submit Modal */}
            <Form onFinish={saveNFT}>
              <div
                className="modal fade"
                id="submitModal"
                tabIndex="-1"
                aria-labelledby="submitModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5
                        className="nft-create nft-field-done-title"
                        id="submitModalLabel"
                      >
                        Almost Done
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        id="submit-modal-close"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <h6
                        className="nft-create nft-field-done-title"
                        id="submitModalLabel"
                      >
                        Before this item gets created, please verify all of the
                        nft properties!
                      </h6>
                      {/* <div
                        style={{
                          margin: "10px",
                          display: "inline-block",
                          justifyContent: "center",
                        }}
                      >
                        <ReCAPTCHA
                          theme="light"
                          sitekey={environment.captchaKey}
                          onChange={handleCaptchaChange}
                        />
                      </div> */}
                      <br />
                      {categoryName === "Video" && (
                        <>
                          <p style={{ color: "red" }}>
                            *Saving the Big Video NFT can take upto 5-8 minutes
                            for big file! You need to wait or you can check the
                            video nft creation status after 5-8 minutes by
                            refreshing the profile page.
                          </p>
                          <p style={{ color: "red" }}>
                            *If This page does not automatically refresh after
                            5-8 minutes then you can manually refresh the page
                            and check the nft creation status from your profile
                            page!
                          </p>
                        </>
                      )}
                    </div>

                    {waterMarkError && (
                      <p style={{ margin: "20px 10px" }}>
                        Please add watermark message also
                      </p>
                    )}

                    {minPriceError && (
                      <h6 className="nft-create save-error-text">
                        You can not list your NFT with minimum price less than
                        or equals to{" "}
                        {generalListingCommission + generalSellingCommission}{" "}
                        ether
                      </h6>
                    )}

                    <div className="modal-footer">
                      <Form.Item>
                        <Button
                          size="large"
                          style={{ marginLeft: "300px" }}
                          type="primary"
                          htmlType="submit"
                          className="nft-create save-changes-btn"
                          loading={loading}
                          onClick={() => {
                            setMinPriceError(false);
                          }}
                        >
                          Save changes
                        </Button>
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>
            </Form>

            {/* Mint Modal */}
            <Form>
              <div
                className="modal fade"
                id="mintModal"
                tabIndex="-1"
                aria-labelledby="mintModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5
                        className="nft-create nft-field-sub-title2"
                        id="mintModalLabel"
                      >
                        Almost Done
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      {!loading && (
                        <>
                          <h6
                            className="nft-create nft-field-sub-title2"
                            id="submitModalLabel"
                          >
                            Before this item gets created, please verify all of
                            the nft properties!
                          </h6>
                          {/* <div
                          style={{
                            margin: "10px",
                            display: "inline-block",
                            justifyContent: "center",
                          }}
                        >
                          <ReCAPTCHA
                            theme="light"
                            sitekey={environment.captchaKey}
                            onChange={handleCaptchaChange}
                          />
                        </div> */}
                        </>
                      )}
                    </div>
                    {loading && (
                      <h6 className="nft-create nft-field-sub-title2">
                        NFT Saving is in progress.. Please wait!!
                      </h6>
                    )}
                    <div className="modal-footer">
                      <Form.Item>
                        <Button
                          size="large"
                          style={{ marginLeft: "300px" }}
                          type="primary"
                          htmlType="submit"
                          loading={loading}
                        >
                          Mint
                        </Button>
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        )}
      </section>
    </div>
  );
};

export default Createpage;
