import React from 'react'
import "./styles/AboutUs.scss";
import { environment } from '../../components/environments/environment';
import AboutUsQArt from '../dynamicContent/about.qart';
import AboutUsPixentro from '../dynamicContent/about.pixentro';

const AboutUs = () => {
  return (
    <>
      {environment.envName === 'qart' && <AboutUsQArt />}
      {environment.envName === 'pixentro' && <AboutUsPixentro />}
    </>
  )
}

export default AboutUs