import React, {useState, useEffect, useContext} from 'react'
import {
    Card,
    Typography,
    message,
  } from "antd";
import authContext from './../../components/components/Shared/Auth.service';

const { Text } = Typography;

const KYCSubmit = () => {
  const { userInfo, setUserInfo } = useContext(authContext);

  useEffect(()=>{
    if(userInfo?.status?.KYC === 'approved') {
      window.location.href = '/whitelistWallet'
    }
  },[])

  return (
    <Card style={{textAlign: 'center', padding: '50px 20px'}}>
        <Text style={{ margin: '30px 10px', fontSize: '18px'}}>
        KYC Successfully submitted!! Once your KYC is approved by NFT Marketplace admin you will receive a email and you will be able to continue.
        </Text>
    </Card>
  )
}

export default KYCSubmit