import React from "react";
/* import CarrerBackgroundImage from "../assets/images/career/career-bg.png"; */
import "./styles/Careers.scss";
import { environment } from "../environments/environment";

const Careers = () => {
  return (
    <>
      <img class="careerBanner card-img bannerHeight" alt="" />
      <div className="careerDescription">
        <p class="card-text display-7 career-page-desc ">
          Explore opportunities to contribute your skills and passion to{" "}
our NFT Marketplace, shaping the future of our creative
          community.
        </p>
        <div className="career-content-wrapper">
          <div className="left-column">
            <p className="textFontSize textPadding">
            Unlock exciting possibilities and chart a rewarding career journey with our NFT marketplace. As we continually expand our creative horizons, we invite you to explore opportunities to become an integral part of our dynamic team. Whether you're passionate about technology, design, community building, or customer support, Our NFT marketplace offers a diverse range of roles where your skills and enthusiasm can make a meaningful impact.
            </p>
            <p className="textFontSize">
            Join us in shaping the future of our innovative platform, where creativity meets cutting-edge technology, and contribute to a community that thrives on collaboration, innovation, and a shared love for digital art. Your career at our NFT marketplace is not just a job; it's a chance to be at the forefront of a creative revolution. Step into a world where your talents are valued, your ideas are heard, and your professional growth is nurtured. Explore the possibilities, and let your career journey with our NFT Marketplace unfold.
            </p>
          </div>
          <div className="right-column">
            <h5 className="textFontSize textPadding ">Send your Resume</h5>
            <p className="textFontSize">
            Innovative work, meaningful career paths. Hear more about how you will accomplish your career goals at our NFT marketplace
            </p>
            <img src={'/img/logo/Career_email_logo.png'} style={{marginTop:"55px"}} alt="email_logo"/>
            </div>
        </div>
      </div>
    </>
  );
};

export default Careers;
