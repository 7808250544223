import { environment } from '../../../../environments/environment';
import Web3 from 'web3';

export class NftFactoryService {
    private ethereum = window['ethereum'];
    private web3 = new Web3(this.ethereum);
    private contract = {
        ABI: require('./NftFactory.json')
    };
       
    async createCollection(name: string, symbol: string,collectionUri:string, NftUser: string, collectionCreationFee: string, NftFactoryContractAddr: string) { 
        console.log(`at create collection: name: ${name}, symbol: ${symbol}, collectionUri: ${collectionUri}, NftUser: ${NftUser}, collectionCreationFee: ${collectionCreationFee}, NftFactoryContractAddr: ${NftFactoryContractAddr}`)   
        const contractInstance = new this.web3.eth.Contract(this.contract.ABI, NftFactoryContractAddr);
        console.log(`contractInstance: `, contractInstance);
        
        // return contractInstance.methods.createCollection(name, symbol,collectionUri).send({ from: NftUser, value: this.web3.utils.toWei(collectionCreationFee, "ether") });
    
        const transactionParameters = {
            from: NftUser,
            value: this.web3.utils.toWei(collectionCreationFee, "ether")
        };

        const transactionObject = contractInstance.methods.createCollection(
            name, symbol, collectionUri
        );
        let receipts;
        let txn_hash;
 
 
        try{
        const transactionHash = await transactionObject.send(transactionParameters)
        .on('transactionHash', (hash: string) => {
            // Here you get the transaction hash immediately
            console.log('Transaction Hash:', hash);
            txn_hash = hash;
        })
        .on('confirmation', (confirmationNumber: number, receipt: any) => {
            // This is called when the transaction is confirmed
            console.log('Transaction Confirmed:', confirmationNumber);
            receipts = receipt;
        })
        .on('error', (error: any) => {
            // Handle errors
            console.error('Transaction Error:', error);
            receipts = false;
        });
        console.log("after confirmation: ", receipts);
        return receipts;
 
        }catch(e){
            return {hash: txn_hash, pending: true};
        }
 
    
    }
}