import React, {useEffect, useState, useContext} from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Spin, notification, Card, message, Form, Input, Button} from 'antd';
import { SharedService } from './../components/Shared/Shared.service';
import { NFTCreatorService } from "./../components/services/NFTCreator/NFTCreator.service";
import { AdminService } from './../components/services/AdminService/Admin.service';
import authContext from './../../components/components/Shared/Auth.service';
import { MasterWhiteListingService } from "../../components/components/Shared/Blockchain/MasterWhitelisting/MasterWhiteListing.service";
import greenTickMark from "../assets/images/home/greenTickMark.png"
import { MetamaskService } from "../components/Shared/Metamask.service";

const sharedService = new SharedService();
const nftCreatorService = new NFTCreatorService();
const masterWhiteListingService = new MasterWhiteListingService();
const adminService = new AdminService();
const useSelectedWalletContext = () =>
  new MetamaskService().useSelectedWalletContext();

const WhitelistWallet = () => {
    const { userInfo, setUserInfo } = useContext(authContext);
    const [loading, setLoading] = useState(true);
    const [emailState, setEmailState] = useState('not sent'); //not sent, submitted, verified, rejected
    const [whiteListingFeePaid, setWhiteListingFeePaid] = useState(false);
    const [whiteListingFee, setWhiteListingFee] = useState(null);
    const [smartContractData, setSmartContractData] = useState({});
    const [blockchainNormalLoading, setBlockchainNormalLoading] = useState(false);
    const [blockchainPendingTransactionLoading, setBlockchainPendingTransactionLoading] = useState(false);
    const [blockchainInputGiven, setBlockchainInputGiven] = useState(false);
    const [blockchainInputHash, setBlockchainInputHash] = useState('');
    const [blockchainPendingTransactionDetails, setBlockchainPendingTransactionDetails] = useState();

    const history = useHistory();
  const { selectedWallet, networkId} = useSelectedWalletContext();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(async()=>{
      
      setLoading(true);
      if(!networkId) return;

        setTimeout(async()=>{
            if(userInfo?._id) {
              try {
                // here need to write somne service to do transaction sercive fee and if it is xcoming as success from api or already succeed then call the bewlo one.

                let res1 = await adminService.getMarketPlaceConfiguration(userInfo?._id, {networkId});
                setWhiteListingFee(res1?.data?.walletWhitelistingFee);

                if(userInfo?.status?.whiteListingFeesPaid?.[networkId]) { 
                  setWhiteListingFeePaid(true);
                  let res = await nftCreatorService.getWalletWhitelistingRequest(userInfo?._id, {networkId});
                  setEmailState(res.data);
                  if(res.data === 'approved') return history.push("/profile");
                }

                // Set contractAddress Data
                let res2 = await adminService.getMarketPlaceDeployedSmartContract({networkId});
                if(res2?.data) setSmartContractData(res2.data);
                setLoading(false);
                // checking for blockchain pending transactions
                if(userInfo?._id && selectedWallet?.length > 0) {
                  let res3 = await nftCreatorService.checkPendingTransactionByType(sharedService.pendingTransactionConstants.WHITELISTING_WALLET, userInfo?._id, '', '');

                  if(res3?.data?.pendingTransaction) {
                    setLoading(true);
                    setBlockchainPendingTransactionLoading(true);
                    setBlockchainNormalLoading(false);
                    setBlockchainInputGiven(res3.data.inputGiven);
                    if(res3.data.inputGiven) {
                      setBlockchainInputHash(res3.data.blockchainHash);
                    }
                    setBlockchainPendingTransactionDetails(res3?.data);
                    setTimeout(()=> window.location.reload(), 60000); //reload after 1 minute
                  }
                }

              } catch (error) {
                setLoading(false);
                console.log(error);
                notification.open({
                  message: 'Error',
                  description: 'something went wrong while fetching whitelisting wallet data',
                  duration: 0,
                });
              }
            }
        }, 400)
      },[userInfo, networkId])
  
  const payWhiteListingFee = async () => {
    setLoading(true);
    setBlockchainNormalLoading(true);
    try {

      const txnReceipt = await masterWhiteListingService.collectWhiteListingFee(
        String(userInfo?.walletAddress),
        (userInfo?.specificWhiteListingFee?.[networkId]?.length > 0 ||
          userInfo?.specificWhiteListingFee?.[networkId] === 0 ||
          isNaN(userInfo?.specificWhiteListingFee?.[networkId]) === false)
          ? String(userInfo?.specificWhiteListingFee?.[networkId]) :
          String(whiteListingFee),
        String(smartContractData?.masterWhiteListingContract)
      );
  
      console.log("Blockchain Transaction receipt: ", txnReceipt);
      if(txnReceipt?.hash?.length > 0 || txnReceipt?.blockHash?.length > 0) {
        let blockchainPending = false;
        let blockchainHash = txnReceipt.hash || txnReceipt.blockHash;
        if(txnReceipt.pending && txnReceipt.pending === true){
          console.log("pending txn_hash :" ,txnReceipt.hash);
          blockchainPending = true;
          blockchainHash = txnReceipt.hash || txnReceipt.blockHash;
        }
        
        let form = {
          userId: userInfo?._id,
          NFTUserWalletAddress: userInfo?.walletAddress,
          transactionDetail: txnReceipt,
          networkId,
          whiteListingFee : 
            (userInfo?.specificWhiteListingFee?.[networkId]?.length > 0 ||
            userInfo?.specificWhiteListingFee?.[networkId] === 0 ||
            isNaN(userInfo?.specificWhiteListingFee?.[networkId]) === false)
            ? String(userInfo?.specificWhiteListingFee?.[networkId]) :
            String(whiteListingFee),
          blockchainPending,
          blockchainHash,
        }
  
        const res = await nftCreatorService.getWalletWhitelistingRequest(userInfo?._id, {networkId});
        const response = await adminService.collectWhiteListingFee(form);
          if (response.success) {
            setWhiteListingFeePaid(true);
            setEmailState(res.data);

            setLoading(false);
            if(!blockchainPending) {
              notification.open({
                message: `You have successfully paid the whiteListing Fee..`,
                duration: 0,
              });
            } else {
              notification.open({
                message: `Your transaction is pending due to low gas fees! please wait untill it gets mined or executed on blockchain`,
                duration: 0,
              });
            }
  
          } else {
            notification.open({
              message: 'Error',
              description: response.error.message,
              duration: 0,
            });
          }
          setTimeout(()=> window.location.reload(), 1000);
      }
    } catch (error) {
      setLoading(false);
      notification.open({
        message: error.message,
        duration: 0,
      })
    }

    setBlockchainNormalLoading(false);
  }

  const saveInputBlockchainHash = async () => {
    if(!blockchainInputHash || blockchainInputHash.length !== 66) {
      message.error("Please provide correct successfull blockchain hash or transactionId!");
      return false;
    }
    setLoading(true);

    try {
      const response = await nftCreatorService.addBlockchainHashToPendingTransaction({ inputBlockchainHash: blockchainInputHash, pendingTransactionId: blockchainPendingTransactionDetails?._id });
      if (response.success && response.data) {
        setBlockchainInputGiven(true);
        notification.open({
          message: response.data,
          duration: 0,
        });
        setTimeout(() => (window.location.reload()), 2000);
      } else {
        console.log('else block');
        message.error(response?.error?.code?.message);
        console.error(response?.error?.code);
      }
    } catch (err) {
      message.error('Error while submitting blockchain hash to system. Please try again later!');
      console.log('catch block');
      console.error(err);
    }
  };

  return (
    <>
    {loading && (<>
      <div className='loading-spinner' style={{textAlign: 'center'}}>
        <br/>
        <Spin style={{size:'large', textAlign: 'center'}}/>
        {blockchainNormalLoading && (<h6>Please wait, Blockchain transaction is processing</h6>)}
        {blockchainPendingTransactionLoading && !blockchainNormalLoading && (<>
          {!blockchainInputGiven && (
            <h6>We are processing your transaction, please wait for Blockchain to complete the transaction. Still if you want to speed up the transaction, go on Metamask select the pending transaction, click on Speedup button. Select the Site Suggested as Aggressive. Then click on Confirm Button</h6>)}

          {blockchainInputGiven && (
            <h6>We are processing your new transaction, please wait for sometime!</h6>)}

            {!blockchainInputGiven && (<>
              <br/>
              <Form
                name="blockchainInputForm"
                onFinish={saveInputBlockchainHash}
              >
                <Form.Item
                  label="Successfully Executed Blockchain Hash or TransactionId"
                  name="textInput"
                  rules={[{ required: true, message: 'Please input your TransactionId!' }]}
                  style={{display: 'flex', justifyContent: 'center'}}
                >
                  <Input
                    type="text"
                    name="blockchainInputHash"
                    value={blockchainInputHash}
                    onChange={(e) =>
                      setBlockchainInputHash(e.target.value)
                    }
                    style={{width: '400px'}}
                  />
                </Form.Item>

                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </>)}
          </>)}
      </div>
    </>)}

    {!loading && !blockchainPendingTransactionLoading && (<Card style={{margin: '20px', marginTop:'80px', textAlign: 'center'}}>
        {!whiteListingFeePaid && <h2>First You need to pay Wallet WhiteListing Fee using your metamask and the you can go to next steps!</h2>}
        {(emailState == 'submitted' || emailState == 'pending') && (
        <h5>Your wallet whitelisting request is submitted and pending with marketplace admins approvals. Once approved you can login.</h5>)}
        {emailState == 'rejected' && (
        <h5>Your wallet whitelisting request is rejected by marketplace admin! You can not Proceed Ahead!</h5>)}


        {
          whiteListingFeePaid ? (
            <div style={{ display: "flex", padding: "5px", alignItems: "center" }}>
              <img src={greenTickMark} alt="" height="15px" width="35px" />
              <div style={{ display: "flex", flexDirection: "column" }}>
                <p style={{ margin: "2px 2px 0px 25px" }}>{whiteListingFee == 0 ? 'You have successfully paid the Gas Fee.' : 'You have successfully paid the whiteListing Fee.'}</p>
              </div>
            </div>
          ) : (
            <ul className="de_nav">
              <li id='Mainbtn' className="active">
                <span onClick={payWhiteListingFee}>{whiteListingFee == 0 ? 'Pay Gas Fees' : 'Pay Whitelisting fees'}</span>
              </li>
            </ul>
          )
        }
    </Card>)}
    </>
  )
}

export default WhitelistWallet