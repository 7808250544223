import { environment } from '../../../../../../environments/environment';
import Web3 from 'web3';

export class NftTraderBeaconService {
    private ethereum = window['ethereum'];
    private web3 = new Web3(this.ethereum);
    private contract = {
        ABI: require('./NftTradingBeacon.json')
    };
       
    async upgradeNftTraderContract(newNftMintingContractAddress: string, PSAdmin: string, networkId: string) {    
        const contractInstance = new this.web3.eth.Contract(this.contract.ABI, environment.blockchains[networkId].NftTrader_Beacon_Address);
        return contractInstance.methods.upgrade(newNftMintingContractAddress).send({ from: PSAdmin });
    }
}