/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { Spin, notification } from 'antd';
import WhitelistWallets from './components/WhitelistWallets';
import GoldenBadgeApprovals from './components/GoldenBadgeApproval';
import KYCApprovals from './components/KYCApprovals';
import ChangePassword from './components/ChangePassword';
import EscrowApproval from './components/EscrowApproval';
import authContext from '../../../components/Shared/Auth.service';
import { AdminService } from '../../../components/services/AdminService/Admin.service';
import { MetamaskService } from '../../../components/Shared/Metamask.service';

const adminService = new AdminService();
const useSelectedWalletContext = () =>
  new MetamaskService().useSelectedWalletContext();

function MarketPlaceComplianceAdmin() {
  const [selectedTab, setSelectedTab] = useState("kycApproval");
  const [loading, setLoading] = useState(false);
  const { networkId } = useSelectedWalletContext();
  const [smartContractData, setSmartContractData] = useState({});
  const { userInfo } = useContext(authContext);

  useEffect(async () => {
    setLoading(true);

    if (!networkId) return;

    setTimeout(async () => {
      if (userInfo?._id && userInfo?.role === 'marketplace-compliance-admin') {
        try {

          // Set contractAddress Data
          let res5 = await adminService.getMarketPlaceDeployedSmartContract({ networkId });
          if (res5?.data) setSmartContractData(res5.data);

          setLoading(false);

        } catch (error) {
          console.log(error);
          notification.open({
            message: 'Error',
            description: 'something went wrong while fetching KYC Data',
            duration: 0,
          });
        }
      } else {
        //return history.push('/account/login');
      }
    }, 400)
  }, [userInfo, networkId])


  const handleMenuClick = (value) => {
    setSelectedTab(value)
  }

  const renderTabs = (val) => {
    switch (val) {
      case "whitelistWallet":
        return <WhitelistWallets smartContractData={smartContractData} setLoading={setLoading} loading={loading} />
      case "kycApproval":
        return <KYCApprovals setLoading={setLoading} loading={loading} />
      case "goldenBadgeApproval":
        return <GoldenBadgeApprovals smartContractData={smartContractData} setLoading={setLoading} loading={loading} />
      case "change-password":
        return <ChangePassword setLoading={setLoading} loading={loading} />
      case "escrowApproval":
        return <EscrowApproval setLoading={setLoading} loading={loading} />;
      default:
        return null;
    }
  }
  return (
    <>
      <div className="d-flex market-super-admin main-section col-lg-12">
        <div className="col-lg-2 market-super-admin col-4-section">
          <div className='d-flex flex-column'>
            <div className='market-super-admin super-admin-box'>
              <p className='market-super-admin super-admin-text'>Compliance Admin</p>
            </div>
            <div>
              <ul className="market-place-admin sidenav-ul">

                <li
                  id="kycApproval"
                  className={`market-place-admin sidenav-list ${selectedTab === "kycApproval" ? "active" : ""}`}
                  onClick={() => handleMenuClick("kycApproval")}
                >
                  <img className="market-place-admin sidenav-icon" src={"/img/admin-dashboard/kycApproval.png"} alt="nftArt" />
                  KYC Approval
                </li>

                <li
                  id="whitelistWallet"
                  className={`market-place-admin sidenav-list ${selectedTab === "whitelistWallet" ? "active" : ""}`}
                  onClick={() => handleMenuClick("whitelistWallet")}
                >
                  <img className="market-place-admin sidenav-icon" src={"/img/admin-dashboard/whitelistWallet.png"} alt="nftArt" />
                  Whitelist Wallet
                </li>

                <li
                  id="goldenBadgeApproval"
                  className={`market-place-admin sidenav-list ${selectedTab === "goldenBadgeApproval" ? "active" : ""}`}
                  onClick={() => handleMenuClick("goldenBadgeApproval")}
                >
                  <img className="market-place-admin sidenav-icon" src={"/img/admin-dashboard/goldenBadgeApproval.png"} alt="nftArt" />
                  Golden Badge Approval
                </li>
                <li
                  id="escrowApproval"
                  className={`market-place-admin sidenav-list ${selectedTab === "escrowApproval" ? "active" : ""}`}
                  onClick={() => handleMenuClick("escrowApproval")}
                >
                  <img className="market-place-admin sidenav-icon" src={"/img/admin-dashboard/escrowApproval.png"} alt="nftArt" />
                  Escrow Approval
                </li>
                <li
                  id="change-password"
                  className={`market-place-admin sidenav-list ${selectedTab === "change-password" ? "active" : ""}`}
                  onClick={() => handleMenuClick("change-password")}
                >
                  <img className="market-place-admin sidenav-icon" src={"/img/admin-dashboard/changePassword.png"} alt="nftArt" />
                  Change Password
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="col-lg-10 market-super-admin col-8-section">
          {renderTabs(selectedTab)}
          {loading && (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ margin: "100px 0" }}
            >
              <Spin size="large" />
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default MarketPlaceComplianceAdmin;
