/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from "react";
import "./styles.css";
import Dashboard from "./components/Dashboard";
import { Spin, notification } from "antd";
import { useHistory } from "react-router-dom";
import { MetamaskService } from "../../../components/Shared/Metamask.service";
import authContext from "../../../components/Shared/Auth.service";
import { SharedService } from "../../../components/Shared/Shared.service";
import { AdminService } from "../../../components/services/AdminService/Admin.service";
import CommissionConfiguration from "./components/CommissionConfiguration";
import NftConfiguration from "./components/NftConfiguration";
import CommissionConfigForGoldenBadge from "./components/CommissionConfigForGoldenBadge";
import AdminInvites from "./components/AdminInvites";
import WhitelistWallets from "./components/WhitelistWallets";
import UpdateFees from "./components/UpdateFees";
import EscrowApproval from "./components/EscrowApproval";
import AdminWalletUpdate from "./components/AdminWalletUpdate";
import DeploySmartContract from "./components/DeploySmartContract";
import BlockchainTransactions from "./components/BlockchainTransactions";
import NftUsers from "./components/NftUsers";
import ChangePassword from "./components/ChangePassword";
import './styles.css'
import "./tempStyles.css"
import AdminApprovals from './components/AdminApprovals';
import NftApproval from "./components/NftApproval";
import KYCApprovals from "./components/KYCApprovals";
import GoldenBadgeApprovals from "./components/GoldenBadgeApproval";

const useSelectedWalletContext = () =>
  new MetamaskService().useSelectedWalletContext();
const sharedService = new SharedService();
const adminService = new AdminService();

function MarketPlaceSuperAdmin() {
  const history = useHistory();
  const { networkId } = useSelectedWalletContext();

  const [selectedTab, setSelectedTab] = useState("dashboard");
  const { userInfo } = useContext(authContext);
  const [todayEtheriumPriceInUSD, setTodayEtheriumPriceInUSD] = useState();
  const [dashboardData, setDashboardData] = useState({});
  const [MSAProfileApproved, setMSAProfileApproved] = useState(false);
  const [smartContractData, setSmartContractData] = useState({});
  const [loading, setLoading] = useState(false);
  const [config, setConfig] = useState({})

  useEffect(async () => {
    setLoading(true);

    if (!networkId) return;

    setTimeout(async () => {
      if (userInfo?._id && userInfo?.role === "marketplace-super-admin") {
        try {
          // checking for profile approval status
          if (userInfo?.status?.profileVerified === 'approved' || userInfo?.status?.profileVerified === 'verified') {
            setMSAProfileApproved(true);
          }

          //setting ether price
          setTodayEtheriumPriceInUSD(await sharedService.getEtheriumPrice());

          //setDashboard Data
          let res = await adminService.getMarketplaceSuperAdminDashboard({ networkId });
          if (res?.data) setDashboardData(res.data);

          // Set contractAddress Data
          let res1 = await adminService.getMarketPlaceDeployedSmartContract({ networkId });
          if (res1?.data) setSmartContractData(res1.data);

          setLoading(false);
        } catch (error) {
          console.log(error);
          notification.open({
            message: "Error",
            description: "something went wrong while fetching data",
            duration: 0,
          });
        }
      } else {
        history.push("/account/login");
      }
    }, 400);
  }, [userInfo, networkId]);

  useEffect(async () => {
    loadConfiguration();
  }, [networkId]);

  async function loadConfiguration() {
    return new Promise(async (resolve, reject) => {
      setLoading(true);
      try {
        // config data
        let res = await adminService.getMarketPlaceConfiguration(userInfo?._id, { networkId });
        if (res.data) {
          if (res.data) {
            setConfig(res.data)
          }
          setLoading(false);
          resolve("true");
        }
      } catch (error) {
        console.log(error);
        notification.open({
          message: "Error",
          description: "something went wrong while fetching configuration data",
          duration: 0,
        });
        setLoading(false);
        reject("true");
      }
    });
  }

  const handleMenuClick = (value) => {
    setSelectedTab(value)
  }

  const renderTabs = (val) => {
    switch (val) {
      case "dashboard":
        window.scrollTo(0, 0);
        return (
          <Dashboard
            dashboardData={dashboardData}
            todayEtheriumPriceInUSD={todayEtheriumPriceInUSD}
            loading={loading}
          />
        );
      case "commissionConfiguration":
        window.scrollTo(0, 0);
        return (
          <CommissionConfiguration
            loading={loading}
            config={config}
            setLoading={setLoading}
            loadConfiguration={loadConfiguration}
            smartContractData={smartContractData}
            todayEtheriumPriceInUSD={todayEtheriumPriceInUSD}
          />
        );
      case "commissionConfigForGoldenBadge":
        window.scrollTo(0, 0);
        return (
          <CommissionConfigForGoldenBadge
            loading={loading}
            config={config}
            setLoading={setLoading}
            loadConfiguration={loadConfiguration}
            smartContractData={smartContractData}
            todayEtheriumPriceInUSD={todayEtheriumPriceInUSD}
          />
        );
      case "nftConfiguration":
        window.scrollTo(0, 0);
        return (
          <NftConfiguration
            loading={loading}
            config={config}
            setLoading={setLoading}
          />
        );
      case "adminInvites":
        window.scrollTo(0, 0);
        return <AdminInvites setLoading={setLoading} loading={loading} />;
      case "adminApprovals":
        window.scrollTo(0, 0);
        return (
          <AdminApprovals
            setLoading={setLoading}
            smartContractData={smartContractData}
            loading={loading}
          />
        );
      case "whitelistWallet":
        window.scrollTo(0, 0);
        return (
          <WhitelistWallets
            setLoading={setLoading}
            smartContractData={smartContractData}
            loading={loading}
          />
        );
      case "nftApproval":
        window.scrollTo(0, 0);
        return (
          <NftApproval
            setLoading={setLoading}
            smartContractData={smartContractData}
            loading={loading}
          />
        );
      case "escrowApproval":
        window.scrollTo(0, 0);
        return <EscrowApproval setLoading={setLoading} loading={loading} smartContractData={smartContractData} />;
      case "adminWalletUpdate":
        window.scrollTo(0, 0);
        return <AdminWalletUpdate setLoading={setLoading} loading={loading} smartContractData={smartContractData} />;
      case "deploySmartContract":
        window.scrollTo(0, 0);
        return (
          <DeploySmartContract setLoading={setLoading} loading={loading} smartContractData={smartContractData} setSmartContractData={setSmartContractData} MSAProfileApproved={MSAProfileApproved} />
        );
      case "blockchainTransactions":
        window.scrollTo(0, 0);
        return (
          <BlockchainTransactions setLoading={setLoading} loading={loading} smartContractData={smartContractData} />
        );
      case "updateFees":
        window.scrollTo(0, 0);
        return (
          <UpdateFees
            loading={loading}
            config={config}
            setLoading={setLoading}
            smartContractData={smartContractData}
            todayEtheriumPriceInUSD={todayEtheriumPriceInUSD}
          />
        );
      case "nftUsers":
        window.scrollTo(0, 0);
        return <NftUsers setLoading={setLoading} loading={loading} smartContractData={smartContractData} />;
      case "change-password":
        window.scrollTo(0, 0);
        return <ChangePassword setLoading={setLoading} loading={loading} smartContractData={smartContractData} />;
      case "kycApproval":
        window.scrollTo(0, 0);
        return <KYCApprovals setLoading={setLoading} loading={loading} />;
      case "goldenBadgeApproval":
        window.scrollTo(0, 0);
        return (
          <GoldenBadgeApprovals
            setLoading={setLoading}
            loading={loading}
            smartContractData={smartContractData}
          />
        );

      default:
        return null;
    }
  };
  return (
    <>
      <div className="d-flex market-super-admin main-section col-lg-12">
        <div className="col-lg-2 market-super-admin col-4-section">
          <div className='d-flex flex-column'>
            <div className='market-super-admin super-admin-box'>
              <p className='market-super-admin super-admin-text'>Super Admin</p>
            </div>
            <div>
              <ul className="market-place-admin sidenav-ul">
                <li
                  id="dashboard"
                  className={`market-place-admin sidenav-list ${selectedTab === "dashboard" ? "active" : ""}`}
                  onClick={() => handleMenuClick("dashboard")}
                >
                  <img className="market-place-admin sidenav-icon" src={"/img/admin-dashboard/dashboard.png"} alt="nftArt" />
                  Dashboard
                </li>
                <li
                  id="commissionConfiguration"
                  className={`market-place-admin sidenav-list ${selectedTab === "commissionConfiguration" ? "active" : ""}`}
                  onClick={() => handleMenuClick("commissionConfiguration")}
                >
                  <img className="market-place-admin sidenav-icon" src={"/img/admin-dashboard/commissionConfig.png"} alt="nftArt" />
                  Commission Configuration
                </li>
                <li
                  id="commissionConfigForGoldenBadge"
                  className={`market-place-admin sidenav-list ${selectedTab === "commissionConfigForGoldenBadge" ? "active" : ""}`}
                  onClick={() => handleMenuClick("commissionConfigForGoldenBadge")}
                >
                  <img className="market-place-admin sidenav-icon" src={"/img/admin-dashboard/goldenBadge.png"} alt="nftArt" />
                  Commission Configuration for Golden Badge
                </li>
                <li
                  id="nftConfiguration"
                  className={`market-place-admin sidenav-list ${selectedTab === "nftConfiguration" ? "active" : ""}`}
                  onClick={() => handleMenuClick("nftConfiguration")}
                >
                  <img className="market-place-admin sidenav-icon" src={"/img/admin-dashboard/nftConfig.png"} alt="nftArt" />
                  NFT Configuration
                </li>
                <li
                  id="adminInvites"
                  className={`market-place-admin sidenav-list ${selectedTab === "adminInvites" ? "active" : ""}`}
                  onClick={() => handleMenuClick("adminInvites")}
                >
                  <img className="market-place-admin sidenav-icon" src={"/img/admin-dashboard/adminInvites.png"} alt="nftArt" />
                  Admin Invites
                </li>
                <li
                  id="adminApprovals"
                  className={`market-place-admin sidenav-list ${selectedTab === "adminApprovals" ? "active" : ""}`}
                  onClick={() => handleMenuClick("adminApprovals")}
                >
                  <img className="market-place-admin sidenav-icon" src={"/img/admin-dashboard/adminApprovals.png"} alt="nftArt" />
                  Admin Approvals
                </li>
                <li
                  id="whitelistWallet"
                  className={`market-place-admin sidenav-list ${selectedTab === "whitelistWallet" ? "active" : ""}`}
                  onClick={() => handleMenuClick("whitelistWallet")}
                >
                  <img className="market-place-admin sidenav-icon" src={"/img/admin-dashboard/whitelistWallet.png"} alt="nftArt" />
                  Whitelist Wallet
                </li>
                <li
                  id="nftApproval"
                  className={`market-place-admin sidenav-list ${selectedTab === "nftApproval" ? "active" : ""}`}
                  onClick={() => handleMenuClick("nftApproval")}
                >
                  <img className="market-place-admin sidenav-icon" src={"/img/admin-dashboard/nftApproval.png"} alt="nftArt" />
                  NFT Approval
                </li>
                <li
                  id="kycApproval"
                  className={`market-place-admin sidenav-list ${selectedTab === "kycApproval" ? "active" : ""}`}
                  onClick={() => handleMenuClick("kycApproval")}
                >
                  <img className="market-place-admin sidenav-icon" src={"/img/admin-dashboard/kycApproval.png"} alt="nftArt" />
                  KYC Approval
                </li>
                <li
                  id="goldenBadgeApproval"
                  className={`market-place-admin sidenav-list ${selectedTab === "goldenBadgeApproval" ? "active" : ""}`}
                  onClick={() => handleMenuClick("goldenBadgeApproval")}
                >
                  <img className="market-place-admin sidenav-icon" src={"/img/admin-dashboard/goldenBadgeApproval.png"} alt="nftArt" />
                  Golden Badge Approval
                </li>
                <li
                  id="escrowApproval"
                  className={`market-place-admin sidenav-list ${selectedTab === "escrowApproval" ? "active" : ""}`}
                  onClick={() => handleMenuClick("escrowApproval")}
                >
                  <img className="market-place-admin sidenav-icon" src={"/img/admin-dashboard/escrowApproval.png"} alt="nftArt" />
                  Escrow Approval
                </li>
                <li
                  id="adminWalletUpdate"
                  className={`market-place-admin sidenav-list ${selectedTab === "adminWalletUpdate" ? "active" : ""}`}
                  onClick={() => handleMenuClick("adminWalletUpdate")}
                >
                  <img className="market-place-admin sidenav-icon" src={"/img/admin-dashboard/adminWallet.png"} alt="nftArt" />
                  Admin Wallet Update
                </li>
                <li
                  id="deploySmartContract"
                  className={`market-place-admin sidenav-list ${selectedTab === "deploySmartContract" ? "active" : ""}`}
                  onClick={() => handleMenuClick("deploySmartContract")}
                >
                  <img className="market-place-admin sidenav-icon" src={"/img/admin-dashboard/deploySmartContract.png"} alt="nftArt" />
                  Deploy Smart Contract
                </li>
                <li
                  id="blockchainTransactions"
                  className={`market-place-admin sidenav-list ${selectedTab === "blockchainTransactions" ? "active" : ""}`}
                  onClick={() => handleMenuClick("blockchainTransactions")}
                >
                  <img className="market-place-admin sidenav-icon" src={"/img/admin-dashboard/blockchain.png"} alt="nftArt" />
                  Blockchain Transactions
                </li>
                <li
                  id="updateFees"
                  className={`market-place-admin sidenav-list ${selectedTab === "updateFees" ? "active" : ""}`}
                  onClick={() => handleMenuClick("updateFees")}
                >
                  <img className="market-place-admin sidenav-icon" src={"/img/admin-dashboard/updateFees.png"} alt="nftArt" />
                  Update Fees
                </li>
                <li
                  id="nftUsers"
                  className={`market-place-admin sidenav-list ${selectedTab === "nftUsers" ? "active" : ""}`}
                  onClick={() => handleMenuClick("nftUsers")}
                >
                  <img className="market-place-admin sidenav-icon" src={"/img/admin-dashboard/nftUsers.png"} alt="nftArt" />
                  NFT Users
                </li>
                <li
                  id="change-password"
                  className={`market-place-admin sidenav-list ${selectedTab === "change-password" ? "active" : ""}`}
                  onClick={() => handleMenuClick("change-password")}
                >
                  <img className="market-place-admin sidenav-icon" src={"/img/admin-dashboard/changePassword.png"} alt="nftArt" />
                  Change Password
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="col-lg-10 market-super-admin col-8-section">
          {renderTabs(selectedTab)}
          {loading && (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ margin: "100px 0" }}
            >
              <Spin size="large" />
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default MarketPlaceSuperAdmin;
