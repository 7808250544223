import React, { useContext, useEffect, useState } from 'react';
import { Button, Form, notification, Segmented, Tabs, DatePicker, Select, Input, Card } from 'antd';
import authContext from '../../../../components/Shared/Auth.service';
import { MetamaskService } from '../../../../components/Shared/Metamask.service';
import { AdminService } from '../../../../components/services/AdminService/Admin.service';
import './UpdateFees.scss';
import { SharedService } from '../../../../components/Shared/Shared.service';
import { PlatformService } from '../../../../components/Shared/Blockchain/Platform/Platform.service';
import { AdminRolesService } from '../../../../components/Shared/Blockchain/AdminRoles/AdminRoles.service';
import { CommissionsService } from '../../../../components/Shared/Blockchain/commissions/commissions.service';
import { MasterWhiteListingService } from '../../../../components/Shared/Blockchain/MasterWhitelisting/MasterWhiteListing.service';
import { NFTCreatorService } from '../../../../components/services/NFTCreator/NFTCreator.service';
import { NftEscrowService } from '../../../../components/Shared/Blockchain/NftEscrow/NftEscrow.service';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const TabPane = Tabs.TabPane;
const { RangePicker } = DatePicker;
const { Option } = Select;
const { Search } = Input;
const platformService = new PlatformService();
const adminRolesService = new AdminRolesService();
const commissionsService = new CommissionsService();
const masterWhiteListingService = new MasterWhiteListingService();
const sharedService = new SharedService();
const adminService = new AdminService();
const nftService = new NFTCreatorService();
const nftEscrowService = new NftEscrowService();
const useSelectedWalletContext = () =>
  new MetamaskService().useSelectedWalletContext();

function UpdateFees(props) {
  const { loading, setLoading, config, todayEtheriumPriceInUSD, smartContractData } = props || {}
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const { userInfo } = useContext(authContext);
  const { selectedWallet, networkId, isMetamaskAuthenticated } = useSelectedWalletContext();
  const [alignValue, setAlignValue] = useState('center');
  const [tabMenu, setTabMenu] = useState(sessionStorage.getItem('menuName') || 'whiteListingMenu');
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [whiteListingFeesUpdatedUser, setWhiteListingFeesUpdatedUser] =
    useState([]);
  const [whiteListingFeesSearchedUser, setWhiteListingFeesSearchedUser] =
    useState([]);
  const [whiteListingFeesUpdatingUser, setWhiteListingFeesUpdatingUser] =
    useState();
  const [whiteListingFeesUserSearchText, setWhiteListingFeesUserSeachText] =
    useState("");
  const [whiteListingFeesInput, setWhiteListingFeesInput] = useState();
  const [showWhiteListingFeesInput, setShowWhiteListingFeesInput] =
    useState(false);

  const [collectionFeesUpdatedUser, setCollectionFeesUpdatedUser] = useState(
    []
  );
  const [collectionFeesSearchedUser, setCollectionFeesSearchedUser] = useState(
    []
  );
  const [collectionFeesUpdatingUser, setCollectionFeesUpdatingUser] =
    useState();
  const [collectionFeesUserSearchText, setCollectionFeesUserSeachText] =
    useState("");
  const [collectionFeesInput, setCollectionFeesInput] = useState();
  const [showCollectionFeesInput, setShowCollectionFeesInput] = useState(false);

  const [mintingFeesUpdatedUser, setMintingFeesUpdatedUser] = useState([]);
  const [mintingFeesSearchedUser, setMintingFeesSearchedUser] = useState([]);
  const [mintingFeesUpdatingUser, setMintingFeesUpdatingUser] = useState();
  const [mintingFeesUserSearchText, setMintingFeesUserSeachText] = useState("");
  const [mintingFeesInput, setMintingFeesInput] = useState();
  const [showMintingFeesInput, setShowMintingFeesInput] = useState(false);

  const [listingFeesUpdatedUser, setListingFeesUpdatedUser] = useState([]);
  const [listingFeesSearchedUser, setListingFeesSearchedUser] = useState([]);
  const [listingFeesUpdatingUser, setListingFeesUpdatingUser] = useState();
  const [listingFeesUserSearchText, setListingFeesUserSeachText] = useState("");
  const [listingFeesInput, setListingFeesInput] = useState();
  const [showListingFeesInput, setShowListingFeesInput] = useState(false);

  const [sellingFeesUpdatedUser, setSellingFeesUpdatedUser] = useState([]);
  const [sellingFeesSearchedUser, setSellingFeesSearchedUser] = useState([]);
  const [sellingFeesUpdatingUser, setSellingFeesUpdatingUser] = useState();
  const [sellingFeesUserSearchText, setSellingFeesUserSeachText] = useState("");
  const [sellingFeesInput, setSellingFeesInput] = useState();
  const [showSellingFeesInput, setShowSellingFeesInput] = useState(false);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    const loadInitialData = async () => {
      setLoading(true);
      try {
        const results = await Promise.allSettled([
          adminService.getUsersDetailsByWhiteListingFees({ networkId }),
          adminService.getUsersDetailsByCollectionFees({ networkId }),
          adminService.getUsersDetailsByMintingFees({ networkId }),
          adminService.getUsersDetailsByListingFees({ networkId }),
          adminService.getUsersDetailsBySellingFees({ networkId })
        ]);
      
        for (let index = 0; index < results.length; index++) {
          const result = results[index];
          if (result.status === 'fulfilled') {
            const data = result.value?.data;
            if (data?.length > 0) {
              switch (index) {
                case 0:
                  setWhiteListingFeesUpdatedUser(data);
                  break;
                case 1:
                  setCollectionFeesUpdatedUser(data);
                  break;
                case 2:
                  setMintingFeesUpdatedUser(data);
                  break;
                case 3:
                  setListingFeesUpdatedUser(data);
                  break;
                case 4:
                  setSellingFeesUpdatedUser(data);
                  break;
                default:
                  break;
              }
            }
          } else {
            console.error(`Promise ${index} rejected:`, result.reason);
          }
        }
      } catch (error) {
        console.log(error);
        notification.open({
          message: "Error",
          description:
            "something went wrong while fetching whitelisting, collection creation, minting, listing, selling fees updated users data!",
          duration: 0,
        });
      }
      setLoading(false);
    };

    if(userInfo?._id && networkId) {
      loadInitialData();
      if(reload === true) {
        //setReload(false);
        setLoading(false);
      }
      setTabMenu(sessionStorage.getItem('menuName') || "whiteListingMenu");
      setSelectedRowIndex(null);
    }
  }, [userInfo, networkId, reload])

  const onChangeTab = (key) => {
    //console.log(key);
    setTabMenu(key);
    sessionStorage.setItem('menuName', key);
    setSelectedRowIndex(null);
  };

  const getUsersDetailsByWhiteListingFeesSearch = async () => {
    if (
      !whiteListingFeesUserSearchText ||
      whiteListingFeesUserSearchText?.length < 3
    ) {
      return notification.open({
        description:
          "Please give some correct input text to search user to set white listing fees",
        duration: 0,
      });
    }
    // here we need to deploy the smartCOntract and get the contract address and pass it to below form object so that we can store the contract address. Nothing else is required for database side.
    try {
      setLoading(true);
      let res = await adminService.getUsersDetailsBySearch(
        whiteListingFeesUserSearchText
      );
      if (res.data?.length > 0) {
        setWhiteListingFeesSearchedUser([...res.data]);
      } else {
        setWhiteListingFeesSearchedUser([]);
      }
      setShowWhiteListingFeesInput(false);
    } catch (error) {
      console.log(error);
      notification.open({
        message: "Error",
        description:
          "something went wrong while searching user in getUsersDetailsByWhiteListingFeesSearch method",
        duration: 0,
      });
    }
    setLoading(false);
  };

  const getUsersDetailsByCollectionFeesSearch = async () => {
    if (
      !collectionFeesUserSearchText ||
      collectionFeesUserSearchText?.length < 3
    ) {
      return notification.open({
        description:
          "Please give some correct input text to search user to set collection fees",
        duration: 0,
      });
    }
    // here we need to deploy the smartCOntract and get the contract address and pass it to below form object so that we can store the contract address. Nothing else is required for database side.
    try {
      setLoading(true);
      let res = await adminService.getUsersDetailsBySearch(
        collectionFeesUserSearchText
      );
      if (res.data?.length > 0) {
        setCollectionFeesSearchedUser([...res.data]);
      } else {
        setCollectionFeesSearchedUser([]);
      }
      setShowCollectionFeesInput(false);
    } catch (error) {
      console.log(error);
      notification.open({
        message: "Error",
        description:
          "something went wrong while searching user in getUsersDetailsByCollectionFeesSearch method",
        duration: 0,
      });
    }
    setLoading(false);
  };

  const getUsersDetailsByMintingFeesSearch = async () => {
    if (!mintingFeesUserSearchText || mintingFeesUserSearchText?.length < 3) {
      return notification.open({
        description:
          "Please give some correct input text to search user to set minting fees",
        duration: 0,
      });
    }
    // here we need to deploy the smartCOntract and get the contract address and pass it to below form object so that we can store the contract address. Nothing else is required for database side.
    try {
      setLoading(true);
      let res = await adminService.getUsersDetailsBySearch(
        mintingFeesUserSearchText
      );
      if (res.data?.length > 0) {
        setMintingFeesSearchedUser([...res.data]);
      } else {
        setWhiteListingFeesSearchedUser([]);
      }
      setShowMintingFeesInput(false);
    } catch (error) {
      console.log(error);
      notification.open({
        message: "Error",
        description:
          "something went wrong while searching user in getUsersDetailsByWhiteMintingFeesSearch method",
        duration: 0,
      });
    }
    setLoading(false);
  };

  const getUsersDetailsByListingFeesSearch = async () => {
    if (!listingFeesUserSearchText || listingFeesUserSearchText?.length < 3) {
      return notification.open({
        description:
          "Please give some correct input text to search user to set listing fees",
        duration: 0,
      });
    }
    // here we need to deploy the smartCOntract and get the contract address and pass it to below form object so that we can store the contract address. Nothing else is required for database side.
    try {
      setLoading(true);
      let res = await adminService.getUsersDetailsBySearch(
        listingFeesUserSearchText
      );
      if (res.data?.length > 0) {
        setListingFeesSearchedUser([...res.data]);
      } else {
        setListingFeesSearchedUser([]);
      }

      setShowListingFeesInput(false);
    } catch (error) {
      console.log(error);
      notification.open({
        message: "Error",
        description:
          "something went wrong while searching user in getUsersDetailsByListingFeesSearch method",
        duration: 0,
      });
    }
    setLoading(false);
  };

  const getUsersDetailsBySellingFeesSearch = async () => {
    if (!sellingFeesUserSearchText || sellingFeesUserSearchText?.length < 3) {
      return notification.open({
        description:
          "Please give some correct input text to search user to set selling fees",
        duration: 0,
      });
    }
    // here we need to deploy the smartCOntract and get the contract address and pass it to below form object so that we can store the contract address. Nothing else is required for database side.
    try {
      setLoading(true);
      let res = await adminService.getUsersDetailsBySearch(
        sellingFeesUserSearchText
      );
      if (res.data?.length > 0) {
        setSellingFeesSearchedUser([...res.data]);
      } else {
        setSellingFeesSearchedUser([]);
      }
      setShowSellingFeesInput(false);
    } catch (error) {
      console.log(error);
      notification.open({
        message: "Error",
        description:
          "something went wrong while searching user in getUsersDetailsBySellingFeesSearch method",
        duration: 0,
      });
    }
    setLoading(false);
  };

  const updateUserWhiteListingFees = async () => {
    if (isNaN(whiteListingFeesInput)) {
      return notification.open({
        description: "Please set White Listing Fees Value for the user",
        duration: 0,
      });
    }
    // here we need to deploy the smartCOntract and get the contract address and pass it to below form object so that we can store the contract address. Nothing else is required for database side.
    try {
      setLoading(true);
      const txnReceipt = await commissionsService.setCustomWhiteListingFees(
        String(userInfo?.walletAddress),
        String(whiteListingFeesUpdatingUser?.walletAddress),
        String(whiteListingFeesInput),
        String(smartContractData?.commissionsContract)
      );

      console.log("Blockchain Transaction receipt: ", txnReceipt);
      if (txnReceipt) {
        let form = {
          email: whiteListingFeesUpdatingUser?.email,
          specificWhiteListingFee: whiteListingFeesInput,
          networkId,
          transactionDetail: {
            txnReceipt,
            specificUserWalletAddress:
              whiteListingFeesUpdatingUser?.walletAddress,
          },
        };
        let res = await adminService.updateUserWhiteListingFees(form);
        if (!res.success) {
          setLoading(false);
          return notification.open({
            message: res.error?.message || "Something went wrong",
            duration: 0,
          });
        }

        if (res.success && res.data) {
          notification.open({
            message: "Successfully Updated user whiteListing fees",
            duration: 0,
          });
          setShowWhiteListingFeesInput(false);
          setWhiteListingFeesUserSeachText("");
          setWhiteListingFeesSearchedUser([]);
          setWhiteListingFeesUpdatedUser([
            ...whiteListingFeesUpdatedUser.filter(
              (usr) => usr?.email !== whiteListingFeesUpdatingUser?.email
            ),
            { ...res.data },
          ]);
          setWhiteListingFeesUpdatingUser();
          setReload(true);
        }
      }
    } catch (error) {
      console.log(error);
      notification.open({
        message: "Error",
        description:
          "something went wrong while updating WhiteListing fees for a user",
        duration: 0,
      });
    }
    setLoading(false);
  };

  const updateUserCollectionFees = async () => {
    if (isNaN(collectionFeesInput)) {
      return notification.open({
        description: "Please set White Collection Fees Value for the user",
        duration: 0,
      });
    }
    // here we need to deploy the smartCOntract and get the contract address and pass it to below form object so that we can store the contract address. Nothing else is required for database side.
    try {
      setLoading(true);
      const txnReceipt =
        await commissionsService.setCustomCollectionCreationFees(
          String(userInfo?.walletAddress),
          String(collectionFeesUpdatingUser?.walletAddress),
          String(collectionFeesInput),
          String(smartContractData?.commissionsContract)
        );

      console.log("Blockchain Transaction receipt: ", txnReceipt);
      if (txnReceipt) {
        let form = {
          email: collectionFeesUpdatingUser?.email,
          specificCollectionFee: collectionFeesInput,
          networkId,
          transactionDetail: {
            txnReceipt,
            specificUserWalletAddress:
              collectionFeesUpdatingUser?.walletAddress,
          },
        };
        let res = await adminService.updateUserCollectionFees(form);
        if (!res.success) {
          setLoading(false);
          return notification.open({
            message: res.error?.message || "Something went wrong",
            duration: 0,
          });
        }

        if (res.success && res.data) {
          notification.open({
            message: "Successfully Updated user collection fees",
            duration: 0,
          });
          setShowCollectionFeesInput(false);
          setCollectionFeesUserSeachText("");
          setCollectionFeesSearchedUser([]);
          setCollectionFeesUpdatedUser([
            ...collectionFeesUpdatedUser.filter(
              (usr) => usr?.email !== collectionFeesUpdatingUser?.email
            ),
            { ...res.data },
          ]);
          setCollectionFeesUpdatingUser();
          setReload(true);
        }
      }
    } catch (error) {
      console.log(error);
      notification.open({
        message: "Error",
        description:
          "something went wrong while updating Collection fees for a user",
        duration: 0,
      });
    }
    setLoading(false);
  };

  const updateUserMintingFees = async () => {
    if (isNaN(mintingFeesInput)) {
      return notification.open({
        description: "Please set White Minting Fees Value for the user",
        duration: 0,
      });
    }
    // here we need to deploy the smartCOntract and get the contract address and pass it to below form object so that we can store the contract address. Nothing else is required for database side.
    try {
      setLoading(true);
      const txnReceipt = await commissionsService.setCustomMintingFees(
        String(userInfo?.walletAddress),
        String(mintingFeesUpdatingUser?.walletAddress),
        String(mintingFeesInput),
        String(smartContractData?.commissionsContract)
      );

      console.log("Blockchain Transaction receipt: ", txnReceipt);
      if (txnReceipt) {
        let form = {
          email: mintingFeesUpdatingUser?.email,
          specificMintingFee: mintingFeesInput,
          networkId,
          transactionDetail: {
            txnReceipt,
            specificUserWalletAddress: mintingFeesUpdatingUser?.walletAddress,
          },
        };
        let res = await adminService.updateUserMintingFees(form);
        if (!res.success) {
          setLoading(false);
          return notification.open({
            message: res.error?.message || "Something went wrong",
            duration: 0,
          });
        }

        if (res.success && res.data) {
          notification.open({
            message: "Successfully Updated user minting fees",
            duration: 0,
          });
          setShowMintingFeesInput(false);
          setMintingFeesUserSeachText("");
          setMintingFeesSearchedUser([]);
          setMintingFeesUpdatedUser([
            ...mintingFeesUpdatedUser.filter(
              (usr) => usr?.email !== mintingFeesUpdatingUser?.email
            ),
            { ...res.data },
          ]);
          setMintingFeesUpdatingUser();
          setReload(true);
        }
      }
    } catch (error) {
      console.log(error);
      notification.open({
        message: "Error",
        description:
          "something went wrong while updating Minting fees for a user",
        duration: 0,
      });
    }
    setLoading(false);
  };

  const updateUserListingFees = async () => {
    if (isNaN(listingFeesInput)) {
      return notification.open({
        description: "Please set Listing Fees Value for the user",
        duration: 0,
      });
    }
    // here we need to deploy the smartCOntract and get the contract address and pass it to below form object so that we can store the contract address. Nothing else is required for database side.
    try {
      setLoading(true);
      const txnReceipt = await commissionsService.setCustomListingFees(
        String(userInfo?.walletAddress),
        String(listingFeesUpdatingUser?.walletAddress),
        String(listingFeesInput),
        String(smartContractData?.commissionsContract)
      );

      console.log("Blockchain Transaction receipt: ", txnReceipt);
      if (txnReceipt) {
        let form = {
          email: listingFeesUpdatingUser?.email,
          specificListingFee: listingFeesInput,
          networkId,
          transactionDetail: {
            txnReceipt,
            specificUserWalletAddress: listingFeesUpdatingUser?.walletAddress,
          },
        };
        let res = await adminService.updateUserListingFees(form);
        if (!res.success) {
          setLoading(false);
          return notification.open({
            message: res.error?.message || "Something went wrong",
            duration: 0,
          });
        }

        if (res.success && res.data) {
          notification.open({
            message: "Successfully Updated user listing fees",
            duration: 0,
          });
          setShowListingFeesInput(false);
          setListingFeesUserSeachText("");
          setListingFeesSearchedUser([]);
          setListingFeesUpdatedUser([
            ...listingFeesUpdatedUser.filter(
              (usr) => usr?.email !== listingFeesUpdatingUser?.email
            ),
            { ...res.data },
          ]);
          setListingFeesUpdatingUser();
          setReload(true);
        }
      }
    } catch (error) {
      console.log(error);
      notification.open({
        message: "Error",
        description:
          "something went wrong while updating listing fees for a user",
        duration: 0,
      });
    }
    setLoading(false);
  };
  
  const updateUserSellingFees = async () => {
    if (isNaN(sellingFeesInput)) {
      return notification.open({
        description: "Please set Selling Fees Value for the user",
        duration: 0,
      });
    }

    try {
      setLoading(true);
      
      const txnReceipt = await commissionsService.setCustomSellingFees(
        String(userInfo?.walletAddress),
        String(sellingFeesUpdatingUser?.walletAddress),
        String(sellingFeesInput),
        String(smartContractData?.commissionsContract)
      );

      console.log("Blockchain Transaction receipt: ", txnReceipt);
      if (txnReceipt) {
        let form = {
          email: sellingFeesUpdatingUser?.email,
          specificSellingFee: sellingFeesInput,
          networkId,
          transactionDetail: {
            txnReceipt,
            specificUserWalletAddress: sellingFeesUpdatingUser?.walletAddress,
          },
        };
        let res = await adminService.updateUserSellingFees(form);
        if (!res.success) {
          setLoading(false);
          return notification.open({
            message: res.error?.message || "Something went wrong",
            duration: 0,
          });
        }

        if (res.success && res.data) {
          notification.open({
            message: "Successfully Updated user selling fees",
            duration: 0,
          });
          setShowSellingFeesInput(false);
          setSellingFeesUserSeachText("");
          setSellingFeesSearchedUser([]);
          setSellingFeesUpdatedUser([
            ...sellingFeesUpdatedUser.filter(
              (usr) => usr?.email !== sellingFeesUpdatingUser?.email
            ),
            { ...res.data },
          ]);
          setSellingFeesUpdatingUser();
          setReload(true);
        }
      }
    } catch (error) {
      console.log(error);
      notification.open({
        message: "Error",
        description:
          "something went wrong while updating selling fees for a user",
        duration: 0,
      });
    }
    setLoading(false);
  };

  const WhiteListingFees = () => (<>
    <div className="col-12 whitelisting-fees">
      {/* <h3>Update WhiteListing Fees for Users</h3> */}
      <Card>
        {/* searching user with searchText */}
        <Form
          form={form}
          onFinish={getUsersDetailsByWhiteListingFeesSearch}
          className='user-search-form'
        >
          <label for="searchText">
            Search user with First Name or Last Name or Email:
          </label>
          <Input
            required
            autoFocus="autoFocus"
            type="text"
            defaultValue={whiteListingFeesUserSearchText}
            value={whiteListingFeesUserSearchText}
            onChange={(e) => {setWhiteListingFeesUserSeachText(e.target.value)}}
            style={{
              padding: "2px 5px 2px 5px",
              marginLeft: "10px",
            }}
            id="searchText"
          />

          <br />
          {smartContractData.deployed === true && (<Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              className='form-submit-bottom'
            >
              Search
            </Button>
          </Form.Item>)}
        </Form>

        {/* dynamic searched users result table */}
        {whiteListingFeesSearchedUser?.length > 0 && (
          <>
            <br />
            <br />
            <h5>Search Result Users</h5>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">No</th>
                  <th scope="col">First Name</th>
                  <th scope="col">Last Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Country</th>
                  <th scope="col">Phone No</th>
                  <th scope="col">Select</th>
                </tr>
              </thead>
              <tbody>
                {whiteListingFeesSearchedUser?.length > 0 &&
                  whiteListingFeesSearchedUser.map(
                    (usr, index) => (
                      <tr className={`table-row ${index === selectedRowIndex ? 'selected' :''}`}>
                        <th scope="row">{index + 1}</th>
                        <td>{usr.firstName}</td>
                        <td>{usr.lastName}</td>
                        <td>{usr.email}</td>
                        <td>{usr.country}</td>
                        <td>{usr.phone}</td>
                        <td>
                          <Button
                            type="secondary"
                            onClick={() => {
                              console.log(usr);
                              setSelectedRowIndex(index);
                              if (
                                usr.specificWhiteListingFee?.[networkId]
                              ) {
                                setWhiteListingFeesInput(
                                  usr.specificWhiteListingFee?.[networkId]
                                );
                              } else {
                                setWhiteListingFeesInput(0);
                              }
                              setWhiteListingFeesUpdatingUser(
                                usr
                              );
                              setShowWhiteListingFeesInput(
                                true
                              );
                            }}
                          >
                            Select
                          </Button>
                        </td>
                      </tr>
                    )
                  )}
              </tbody>
            </table>
          </>
        )}

        {/* setting user specificWhiteListingFee */}
        {showWhiteListingFeesInput && (
          <div className='setting-user-view'>
            <h5>
              Update User with Specific WhiteListing Fees
            </h5>
            <Form
              form={form2}
              onFinish={updateUserWhiteListingFees}
              className='fees-input-form'
            >
              <Form.Item
                label="WhiteListing Fees in ETH"
                name="whiteListingFee"
                rules={[
                  {
                    required: false,
                    message:
                      "Please input WhiteListing Fees in ETH!",
                  },
                ]}
              >
                <input
                  type="number"
                  defaultValue={whiteListingFeesInput}
                  value={whiteListingFeesInput}
                  autoFocus="autoFocus"
                  onChange={(e) =>
                    setWhiteListingFeesInput(e.target.value)
                  }
                  style={{ padding: "2px 5px 2px 5px" }}
                />
                <span className='input-in-usd'>
                  USD:{" "}
                  {(
                    whiteListingFeesInput *
                    todayEtheriumPriceInUSD
                  ).toFixed(3)}
                </span>
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                >
                  Update WhiteListing Fees
                </Button>
              </Form.Item>
            </Form>
          </div>
        )}

        {/* already set uses listing fees */}
        {whiteListingFeesUpdatedUser?.length > 0 && (
          <>
            <br />
            <br />
            <br/>
            <br/>
            <h5>
              Already Updated User with Specific WhiteListing
              Fees
            </h5>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">No</th>
                  <th scope="col">First Name</th>
                  <th scope="col">Last Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Phone No</th>
                  <th scope="col">WhiteListing Fee</th>
                </tr>
              </thead>
              <tbody>
                {whiteListingFeesUpdatedUser.map(
                  (usr, index) => (
                    <tr>
                      <th scope="row">{index + 1}</th>
                      <td>{usr.firstName}</td>
                      <td>{usr.lastName}</td>
                      <td>{usr.email}</td>
                      <td>{usr.phone}</td>
                      <td>{usr.specificWhiteListingFee?.[networkId]}</td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </>
        )}
      </Card>
    </div>
  </>);

  const CollectionCreationFees = () => (<>
    <div className="col-12 collection-fees">
      {/* <h3>Update Collection Creation Fees for Users</h3> */}
      <Card>
        {/* searching user with searchText */}
        <Form
          form={form}
          onFinish={getUsersDetailsByCollectionFeesSearch}
          className='user-search-form'
        >
          <label for="searchText">
            Search user with First Name or Last Name or Email:
          </label>
          <Input
            required
            type="text"
            autoFocus="autoFocus"
            defaultValue={collectionFeesUserSearchText}
            value={collectionFeesUserSearchText}
            onChange={(e) =>
              setCollectionFeesUserSeachText(e.target.value)
            }
            style={{
              padding: "2px 5px 2px 5px",
              marginLeft: "10px",
            }}
            id="searchText"
          />

          <br />
          {smartContractData.deployed === true && (<Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              className='form-submit-bottom'
            >
              Search
            </Button>
          </Form.Item>)}
        </Form>

        {/* dynamic searched users result table */}
        {collectionFeesSearchedUser?.length > 0 && (
          <>
            <br />
            <br />
            <h5>Search Result Users</h5>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">No</th>
                  <th scope="col">First Name</th>
                  <th scope="col">Last Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Country</th>
                  <th scope="col">Phone No</th>
                  <th scope="col">Select</th>
                </tr>
              </thead>
              <tbody>
                {collectionFeesSearchedUser?.length > 0 &&
                  collectionFeesSearchedUser.map(
                    (usr, index) => (
                      <tr className={`table-row ${index === selectedRowIndex ? 'selected' :''}`}>
                        <th scope="row">{index + 1}</th>
                        <td>{usr.firstName}</td>
                        <td>{usr.lastName}</td>
                        <td>{usr.email}</td>
                        <td>{usr.country}</td>
                        <td>{usr.phone}</td>
                        <td>
                          <Button
                            type="secondary"
                            onClick={() => {
                              console.log(usr);
                              setSelectedRowIndex(index);
                              if (usr.specificCollectionFee?.[networkId]) {
                                setCollectionFeesInput(
                                  usr.specificCollectionFee?.[networkId]
                                );
                              } else {
                                setCollectionFeesInput(0);
                              }
                              setCollectionFeesUpdatingUser(
                                usr
                              );
                              setShowCollectionFeesInput(
                                true
                              );
                            }}
                          >
                            Select
                          </Button>
                        </td>
                      </tr>
                    )
                  )}
              </tbody>
            </table>
          </>
        )}

        {/* setting user specificCollectionFee */}
        {showCollectionFeesInput && (
          <div className='setting-user-view'>
            <h5>
              Update User with Specific Collection Creation
              Fees
            </h5>
            <Form
              {...formItemLayout}
              form={form2}
              onFinish={updateUserCollectionFees}
              className='fees-input-form'
            >
              <Form.Item
                label="Collection Fees in ETH"
                name="collectionFee"
                rules={[
                  {
                    required: false,
                    message:
                      "Please input Collection Fees in ETH!",
                  },
                ]}
              >
                <input
                  type="text"
                  defaultValue={collectionFeesInput}
                  value={collectionFeesInput}
                  autoFocus="autoFocus"
                  onChange={(e) =>
                    setCollectionFeesInput(e.target.value)
                  }
                  style={{ padding: "2px 5px 2px 5px" }}
                />
                <span className='input-in-usd'>
                  USD:{" "}
                  {(
                    collectionFeesInput *
                    todayEtheriumPriceInUSD
                  ).toFixed(3)}
                </span>
              </Form.Item>

              <Form.Item {...tailFormItemLayout}>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                >
                  Update Collection Fees
                </Button>
              </Form.Item>
            </Form>
          </div>
        )}

        {/* already set uses listing fees */}
        {collectionFeesUpdatedUser?.length > 0 && (
          <>
            <br />
            <br />
            <br/>
            <br/>
            <h5>
              Already Updated User with Specific Collection
              Fees
            </h5>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">No</th>
                  <th scope="col">First Name</th>
                  <th scope="col">Last Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Phone No</th>
                  <th scope="col">Collection Fee</th>
                </tr>
              </thead>
              <tbody>
                {collectionFeesUpdatedUser.map(
                  (usr, index) => (
                    <tr>
                      <th scope="row">{index + 1}</th>
                      <td>{usr.firstName}</td>
                      <td>{usr.lastName}</td>
                      <td>{usr.email}</td>
                      <td>{usr.phone}</td>
                      <td>{usr.specificCollectionFee?.[networkId]}</td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </>
        )}
      </Card>
    </div>
  </>)

  const MintingFees = () => (<>
    <div className="col-12 minting-fees">
      {/* <h3>Update Minting Fees for Users</h3> */}
      <Card>
        {/* searching user with searchText */}
        <Form
          form={form}
          onFinish={getUsersDetailsByMintingFeesSearch}
          className='user-search-form'
        >
          <label for="searchText">
            Search user with First Name or Last Name or Email:
          </label>
          <Input
            required
            autoFocus='autoFocus'
            type="text"
            defaultValue={mintingFeesUserSearchText}
            value={mintingFeesUserSearchText}
            onChange={(e) =>
              setMintingFeesUserSeachText(e.target.value)
            }
            style={{
              padding: "2px 5px 2px 5px",
              marginLeft: "10px",
            }}
            id="searchText"
          />

          <br />
          {smartContractData.deployed === true && (<Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              className='form-submit-bottom'
            >
              Search
            </Button>
          </Form.Item>)}
        </Form>

        {/* dynamic searched users result table */}
        {mintingFeesSearchedUser?.length > 0 && (
          <>
            <br />
            <br />
            <h5>Search Result Users</h5>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">No</th>
                  <th scope="col">First Name</th>
                  <th scope="col">Last Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Country</th>
                  <th scope="col">Phone No</th>
                  <th scope="col">Select</th>
                </tr>
              </thead>
              <tbody>
                {mintingFeesSearchedUser?.length > 0 &&
                  mintingFeesSearchedUser.map(
                    (usr, index) => (
                      <tr className={`table-row ${index === selectedRowIndex ? 'selected' :''}`}>
                        <th scope="row">{index + 1}</th>
                        <td>{usr.firstName}</td>
                        <td>{usr.lastName}</td>
                        <td>{usr.email}</td>
                        <td>{usr.country}</td>
                        <td>{usr.phone}</td>
                        <td>
                          <Button
                            type="secondary"
                            onClick={() => {
                              console.log(usr);
                              setSelectedRowIndex(index);
                              if (usr.specificMintingFee?.[networkId]) {
                                setMintingFeesInput(
                                  usr.specificMintingFee?.[networkId]
                                );
                              } else {
                                setMintingFeesInput(0);
                              }
                              setMintingFeesUpdatingUser(usr);
                              setShowMintingFeesInput(true);
                            }}
                          >
                            Select
                          </Button>
                        </td>
                      </tr>
                    )
                  )}
              </tbody>
            </table>
          </>
        )}

        {/* setting user specificMintingFee */}
        {showMintingFeesInput && (
          <div className='setting-user-view'>
            <br />
            <h5>Update User with Specific Minting Fees</h5>
            <Form
              {...formItemLayout}
              form={form2}
              onFinish={updateUserMintingFees}
              className='fees-input-form'
            >
              <Form.Item
                label="Minting Fees in ETH"
                name="mintingFee"
                rules={[
                  {
                    required: false,
                    message:
                      "Please input Minting Fees in ETH!",
                  },
                ]}
              >
                <input
                  type="text"
                  autoFocus='autoFocus'
                  defaultValue={mintingFeesInput}
                  value={mintingFeesInput}
                  onChange={(e) =>
                    setMintingFeesInput(e.target.value)
                  }
                  style={{ padding: "2px 5px 2px 5px" }}
                />
                <span className='input-in-usd'>
                  USD:{" "}
                  {(
                    mintingFeesInput * todayEtheriumPriceInUSD
                  ).toFixed(3)}
                </span>
              </Form.Item>

              <Form.Item {...tailFormItemLayout}>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                >
                  Update Minting Fees
                </Button>
              </Form.Item>
            </Form>
            </div>
        )}

        {/* already set uses listing fees */}
        {mintingFeesUpdatedUser?.length > 0 && (
          <>
            <br />
            <br />
            <br/>
            <br/>
            <h5>
              Already Updated User with Specific Minting Fees
            </h5>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">No</th>
                  <th scope="col">First Name</th>
                  <th scope="col">Last Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Phone No</th>
                  <th scope="col">Minting Fee</th>
                </tr>
              </thead>
              <tbody>
                {mintingFeesUpdatedUser.map((usr, index) => (
                  <tr>
                    <th scope="row">{index + 1}</th>
                    <td>{usr.firstName}</td>
                    <td>{usr.lastName}</td>
                    <td>{usr.email}</td>
                    <td>{usr.phone}</td>
                    <td>{usr.specificMintingFee?.[networkId]}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
      </Card>
    </div>
  </>)

  const ListingFees = () => (<>
    <div className="col-12 listing-fees">
      {/* <h3>Update Listing Fees for Users</h3> */}
      <Card >
        {/* searching user with searchText */}
        <Form
          {...formItemLayout}
          form={form}
          onFinish={getUsersDetailsByListingFeesSearch}
          className='user-search-form'
        >
          <label for="searchText">
            Search user with First Name or Last Name or Email:
          </label>
          <Input
            required
            autoFocus='autoFocus'
            type="text"
            defaultValue={listingFeesUserSearchText}
            value={listingFeesUserSearchText}
            onChange={(e) =>
              setListingFeesUserSeachText(e.target.value)
            }
            style={{
              padding: "2px 5px 2px 5px",
              marginLeft: "10px",
            }}
            id="searchText"
          />

          <br />
          {smartContractData.deployed === true && (<Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              className='form-submit-bottom'
            >
              Search
            </Button>
          </Form.Item>)}
        </Form>

        {/* dynamic searched users result table */}
        {listingFeesSearchedUser?.length > 0 && (
          <>
            <br />
            <br />
            <br/>
            <br/>
            <h5>Search Result Users</h5>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">No</th>
                  <th scope="col">First Name</th>
                  <th scope="col">Last Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Country</th>
                  <th scope="col">Phone No</th>
                  <th scope="col">Select</th>
                </tr>
              </thead>
              <tbody>
                {listingFeesSearchedUser?.length > 0 &&
                  listingFeesSearchedUser.map(
                    (usr, index) => (
                      <tr className={`table-row ${index === selectedRowIndex ? 'selected' :''}`}>
                        <th scope="row">{index + 1}</th>
                        <td>{usr.firstName}</td>
                        <td>{usr.lastName}</td>
                        <td>{usr.email}</td>
                        <td>{usr.country}</td>
                        <td>{usr.phone}</td>
                        <td>
                          <Button
                            type="secondary"
                            onClick={() => {
                              console.log(usr);
                              setSelectedRowIndex(index);
                              if (usr.specificListingFee?.[networkId]) {
                                setListingFeesInput(
                                  usr.specificListingFee?.[networkId]
                                );
                              } else {
                                setListingFeesInput(0);
                              }
                              setListingFeesUpdatingUser(usr);
                              setShowListingFeesInput(true);
                            }}
                          >
                            Select
                          </Button>
                        </td>
                      </tr>
                    )
                  )}
              </tbody>
            </table>
          </>
        )}

        {/* setting user specificListingFee */}
        {showListingFeesInput && (
          <div className='setting-user-view'>
            <br />
            <h5>Update User with Specific Listing Fees</h5>
            <Form
              {...formItemLayout}
              form={form2}
              onFinish={updateUserListingFees}
              className='fees-input-form'
            >
              <Form.Item
                label="Listing Fees in ETH"
                name="collectionFee"
                rules={[
                  {
                    required: false,
                    message:
                      "Please input Listing Fees in ETH!",
                  },
                ]}
              >
                <input
                  type="text"
                  defaultValue={listingFeesInput}
                  value={listingFeesInput}
                  autoFocus='autoFocus'
                  onChange={(e) =>
                    setListingFeesInput(e.target.value)
                  }
                  style={{ padding: "2px 5px 2px 5px" }}
                />
                <span className='input-in-usd'>
                  USD:{" "}
                  {(
                    listingFeesInput * todayEtheriumPriceInUSD
                  ).toFixed(3)}
                </span>
              </Form.Item>

              <Form.Item {...tailFormItemLayout}>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                >
                  Update Listing Fees
                </Button>
              </Form.Item>
            </Form>
          </div>
        )}

        {/* already set uses listing fees */}
        {listingFeesUpdatedUser?.length > 0 && (
          <>
            <br />
            <br />
            <br/>
            <br/>
            <h5>
              Already Updated User with Specific Listing Fees
            </h5>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">No</th>
                  <th scope="col">First Name</th>
                  <th scope="col">Last Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Phone No</th>
                  <th scope="col">Listing Fee</th>
                </tr>
              </thead>
              <tbody>
                {listingFeesUpdatedUser.map((usr, index) => (
                  <tr>
                    <th scope="row">{index + 1}</th>
                    <td>{usr.firstName}</td>
                    <td>{usr.lastName}</td>
                    <td>{usr.email}</td>
                    <td>{usr.phone}</td>
                    <td>{usr.specificListingFee?.[networkId]}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
      </Card>
    </div>
  </>)

  const SellingFees = () => (<>
    <div className="col-12 selling-fees">
      {/* <h3>Update Selling Fees for Users</h3> */}
      <Card>
        {/* searching user with searchText */}
        <Form
          form={form}
          onFinish={getUsersDetailsBySellingFeesSearch}
          className='user-search-form'
        >
          <label for="searchText">
            Search user with First Name or Last Name or Email:
          </label>
          <Input
            required
            autoFocus='autoFocus'
            type="text"
            defaultValue={sellingFeesUserSearchText}
            value={sellingFeesUserSearchText}
            onChange={(e) =>
              setSellingFeesUserSeachText(e.target.value)
            }
            style={{
              padding: "2px 5px 2px 5px",
              marginLeft: "10px",
            }}
            id="searchText"
          />

          <br />
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              className='form-submit-bottom'
            >
              Search
            </Button>
          </Form.Item>
        </Form>

        {/* dynamic searched users result table */}
        {sellingFeesSearchedUser?.length > 0 && (
          <>
            <br />
            <br />
            <h5>Search Result Users</h5>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">No</th>
                  <th scope="col">First Name</th>
                  <th scope="col">Last Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Country</th>
                  <th scope="col">Phone No</th>
                  <th scope="col">Select</th>
                </tr>
              </thead>
              <tbody>
                {sellingFeesSearchedUser?.length > 0 &&
                  sellingFeesSearchedUser.map(
                    (usr, index) => (
                      <tr className={`table-row ${index === selectedRowIndex ? 'selected' :''}`}>
                        <th scope="row">{index + 1}</th>
                        <td>{usr.firstName}</td>
                        <td>{usr.lastName}</td>
                        <td>{usr.email}</td>
                        <td>{usr.country}</td>
                        <td>{usr.phone}</td>
                        <td>
                          <Button
                            type="secondary"
                            onClick={() => {
                              console.log(usr);
                              setSelectedRowIndex(index);
                              if (usr.specificSellingFee?.[networkId]) {
                                setSellingFeesInput(
                                  usr.specificSellingFee?.[networkId]
                                );
                              } else {
                                setSellingFeesInput(0);
                              }
                              setSellingFeesUpdatingUser(usr);
                              setShowSellingFeesInput(true);
                            }}
                          >
                            Select
                          </Button>
                        </td>
                      </tr>
                    )
                  )}
              </tbody>
            </table>
          </>
        )}

        {/* setting user specificListingFee */}
        {showSellingFeesInput && (
          <div className='setting-user-view'>
            <br />
            <h5>Update User with Specific Selling Fees</h5>
            <Form
              form={form2}
              onFinish={updateUserSellingFees}
              className='fees-input-form'
            >
              <Form.Item
                label="Selling Commission in Percentage"
                name="sellingFees"
                rules={[
                  {
                    required: false,
                    message:
                      "Selling Commission in Percentage!",
                  },
                ]}
              >
                <input
                  type="number"
                  autoFocus="autoFocus"
                  defaultValue={sellingFeesInput}
                  value={sellingFeesInput}
                  onChange={(e) =>
                    setSellingFeesInput(e.target.value)
                  }
                  style={{ padding: "2px 5px 2px 5px" }}
                /><span className='percentage-type'>{"%"}</span>
              </Form.Item>

              <Form.Item>
              {smartContractData.deployed === true && (<Button
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                >
                  Update Selling Fees
                </Button>)}
              </Form.Item>
            </Form>
          </div>
        )}

        {/* already set uses listing fees */}
        {sellingFeesUpdatedUser?.length > 0 && (
          <>
            <br />
            <br />
            <br/>
            <br/>
            <h5>
              Already Updated User with Specific Selling Fees
            </h5>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">No</th>
                  <th scope="col">First Name</th>
                  <th scope="col">Last Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Phone No</th>
                  <th scope="col">Selling Fee</th>
                </tr>
              </thead>
              <tbody>
                {sellingFeesUpdatedUser.map((usr, index) => (
                  <tr>
                    <th scope="row">{index + 1}</th>
                    <td>{usr.firstName}</td>
                    <td>{usr.lastName}</td>
                    <td>{usr.email}</td>
                    <td>{usr.phone}</td>
                    <td>{usr.specificSellingFee?.[networkId]}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
      </Card>
    </div>
  </>)

  const items = [
    {
      key: 'whiteListingMenu',
      label: <span className='tab-header-text'>Update<br/> Whitelisting Fees</span>,
      children: <WhiteListingFees />,
    },
    {
      key: 'collectionCreationMenu',
      label: <span className='tab-header-text'>Update<br/> Collection Creation Fees</span>,
      children: <CollectionCreationFees/>,
    },
    {
      key: 'MintingMenu',
      label: <span className='tab-header-text'>Update<br/> Minting Fees</span>,
      children: <MintingFees />,
    },
    {
      key: 'ListingMenu',
      label: <span className='tab-header-text'>Update<br/> Listing Fees</span>,
      children: <ListingFees />,
    },
    {
      key: 'sellingMenu',
      label: <span className='tab-header-text'>Update<br/> Selling Fees</span>,
      children: <SellingFees />,
    },
  ];

  return (
    !loading && (<>
      <p className='market-super-admin commission-configuration-text'>Update Fees</p>
      <div className='market-super-admin card-container d-flex justify-content-center'>
        <div className='market-super-admin card-inner-container' style={{ width: '100%', padding: '20px' }}>
          <div className='update-fees-container'>
            <div className='header'>
              <Tabs
                defaultActiveKey={sessionStorage.getItem('menuName') || 'whiteListingMenu'}
                //activeKey={tabMenu}
                items={items}
                onChange={onChangeTab}
                indicator={{
                  size: (origin) => origin - 20,
                  align: alignValue,
                }}
              />
            </div>
            <div className='body'>
              {tabMenu === 'whiteListingMenu' && <WhiteListingFees />}
              {tabMenu === 'collectionCreationMenu' && <CollectionCreationFees />}
              {tabMenu === 'MintingMenu' && <MintingFees />}
              {tabMenu === 'ListingMenu' && <ListingFees />}
              {tabMenu === 'sellingMenu' && <SellingFees />}
            </div>
          </div>
        </div>
      </div>
    </>)
  )
}

export default UpdateFees
