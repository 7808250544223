import React, { useState } from 'react';
import { Collapse } from 'antd';
import './styles/CommunityStandards.scss';
import UpwardIcon from "./../assets/images/CommunityStandards/upwardIcon.png";
import DownwardIcon from "./../assets/images/CommunityStandards/downwardIcon.png";
import PrimaryIcon from "./../assets/images/CommunityStandards/communityStandardBanner.png";
import Iconone from "./../assets/images/CommunityStandards/Rectangle 27.png";
import IconTwo from "./../assets/images/CommunityStandards/icon_Two.png";
import icon_three from "./../assets/images/CommunityStandards/icon_three.png";
import { environment } from '../../components/environments/environment';

const { Panel } = Collapse;

const CommunityStandards = () => {
  const [activeKey, setActiveKey] = useState('');
  const { companyName } = environment || {};

  const cardLists = [
    {
      key: 1,
      title: 'Preventing Physical and Emotional Harm',
      img: Iconone,
      cardContent: <p className='community-standards card-content'>
        {companyName} is committed to preventing any content that may cause harm, be it physical or emotional. We strictly prohibit the posting of content that incites, encourages, or supports violence. Additionally, the platform has a zero-tolerance stance against the sharing of private, personally identifiable information (doxxing).
      </p>
    },
    {
      key: 2,
      title: 'A Hate-Free Zone',
      img: IconTwo,
      cardContent: <p className='community-standards card-content'>
        Our Inciting Hate policy aims to create a space free from the promotion of hate organizations. Content advocating violence, superiority, or harm towards any protected group is strictly prohibited. {companyName} also maintains a stance against content that includes hateful stereotypes or slurs.
      </p>
    },
    {
      key: 3,
      title: 'Combatting Sexual Exploitation',
      img: icon_three,
      cardContent: <p className='community-standards card-content'>
        {companyName} is dedicated to preventing the distribution of content created without explicit consent. This includes revenge porn, creepshots, upskirt shots, and any content involving the dissemination of sexualized material without the subject's consent.
      </p>
    },
    {
      key: 4,
      title: 'Nurturing Mental Health',
      img: IconTwo,
      cardContent: <p className='community-standards card-content'>
        While {companyName} encourages self-expression, we prioritize your safety. Content that glorifies, encourages, or supports suicide or self-injury is not allowed. We aim to maintain a supportive community for responsible self-expression. By embracing these guidelines, {companyName} endeavors to create a secure and respectful platform, allowing you to freely express yourself while ensuring the well-being of our community.
      </p>
    }
  ]

  const handlePanelChange = (key) => {
    setActiveKey(activeKey === key ? '' : key);
  };

  const handleCardChange = (e, key) => {
    e.stopPropagation();
    handlePanelChange(key);
  }

  const renderTitle = (cardKey, cardTitle, cardImg) => (
    <>
      <div className='community-standards collapse-div' onClick={(e) => handleCardChange(e, cardKey)}>
        <div>
          <img src={cardImg} alt='Icon' className='community-standards icon c_s_icon' style={{ height: activeKey === cardKey ? "180px" : "70px", width: activeKey === cardKey ? "180px" : "70px" }} />
        </div>
        <div>
          <p className='community-standards cardTitle' style={{ margin: "14px 0px 0px 20px" }}>{cardTitle}</p>
        </div>
        <div className='community-standards imageDiv'>
          {activeKey === cardKey ?
            <img src={UpwardIcon} alt='UpwardIcon' className='community-standards upward-icon' onClick={(e) => handleCardChange(e, cardKey)} />
            :
            <img src={DownwardIcon} alt='DownwardIcon' className='community-standards downward-icon' onClick={(e) => handleCardChange(e, cardKey)} />
          }
        </div>
      </div>
    </>
  );

  return (
    <>
      <div  style={{ backgroundImage: `url(${PrimaryIcon})` , height:"390px" , width:"1680px" ,backgroundSize:"cover" ,marginTop:"102px"}}>
        
      </div>
      <div style={{ background: "#FFFFFF" }}>
        <p className='community-standards primary-text'>At {companyName}, your safety and comfort are our top priorities. To ensure a positive and secure experience for every user, we've implemented user-centric policies focusing on your well-being:</p>
        <Collapse
          accordion
          activeKey={activeKey}
          onChange={handlePanelChange}
          className="community-standards custom-collapse"
          expandIconPosition="end"
        >
          {cardLists?.map((card) => {
            return (
              <Panel
                header={renderTitle(card.key, card.title, card.img)}
                key={card.key}
              >
                {activeKey === card.key && (
                  <div>

                    <p>

                      {card.cardContent}
                    </p>
                  </div>
                )}
              </Panel>
            );
          })}
        </Collapse>
      </div>
    </>
  );
};

export default CommunityStandards;
