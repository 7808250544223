import React from 'react'

const TermsOfUsePixentro = () => {
  return (
    <div className='container terms-of-use'>
      <h4>TERMS OF USE</h4>
      <p>
        These Terms of Use (the “Terms”) are a legal agreement between the marketplace (“we,” “our,” “us”) and you, the visitor, governing your access to and use of all content and functionalities available at the pixentro website and services provided via Site (collectively, the “Services”).
      </p>
      <p>
        THIS IS A LEGALLY BINDING CONTRACT. BY ACCESSING OR USING THE SERVICES OR BY INDICATING YOUR ACCEPTANCE OF THESE TERMS, YOU ACKNOWLEDGE AND REPRESENT THAT YOU HAVE READ THESE TERMS, UNDERSTAND THEM, AND AGREE TO BE BOUND BY THEM. YOU ALSO REPRESENT AND WARRANT THAT YOU ARE OF LEGAL AGE TO ENTER INTO THIS CONTRACT AND ARE NOT PROHIBITED BY LAW FROM ACCESSING OR USING THE SERVICES. YOU DO NOT HAVE TO ENTER INTO THESE TERMS, BUT IF YOU DO NOT, YOU WILL NOT BE PERMITTED TO, AND YOU MAY NOT, USE THE SERVICES.
      </p>
      <h4>1. You must be at least 18 years old to use the Services.</h4>
      <p>
        If you are under the age of majority in your state of residence, a minor, your parent or legal guardian must agree to these Terms on your behalf, and you may only access and use the Site and Services with permission from your parent or legal guardian. Further, you represent and warrant that you (i) are not located in a country that is subject to a U.S. Government embargo, or that has been designated by the U.S. Government as a terrorist-supporting country; and (ii) are not listed on any U.S. Government list of prohibited or restricted parties.
      </p>
      <h4>2. NFTs and Blockchain.</h4>
      <p>
        Subject to compliance with these Terms, the Services include allowing users to mint their digital assets into non-fungible tokens (the “NFTs”), upload content files and documents and establish declarations, all of which will be permanently published on decentralized servers and Ethereum blockchains and rendered in Ethereum blockchain registries. To use some of our Services, you must use a third-party wallet that allows you to engage in transactions on Ethereum blockchains. Your ownership of the NFT and documents you upload, and any declarations you choose to make through our Services will be permanently published on Ethereum blockchains and decentralized storage servers/systems and rendered on Ethereum blockchain registries, associated with your wallet. We do not provide or control any wallets. We do not have custody or control over the NFTs or Ethereum blockchains you are interacting with, and we do not execute or effectuate purchases, transfers, sales, or resales of NFTs. You are solely responsible for any transaction you conduct with the NFT and your wallets.
      </p>
      <h4>3. Verification.</h4>
      <p>
        Prior to using the Services, users may be required to verify their identity using one or more methods available on the Site. If you are unable or unwilling to verify your identity using one of the provided methods, we may refuse your use of some or all the Services, in our sole discretion.
      </p>
      <h4>4. Use of Rights Related Services Provisions. During your use of any Services, the following may apply:</h4>
      <p>4.1 Declarations Related to Ownership and Rights. 
    During your use of our Service, you may be asked to make confirmations of your legal ownership, property, or other rights, and your ability to grant rights related to assets, some of which will be recorded on the blockchain. You agreed to only make truthful confirmations and declarations and acknowledge that we in no way has any obligations to determine veracity; provided, that we may cancel any certifications or Services, without refund, if it determines that any such required declarations are untrue or have been removed. You authorize that we process permanent publication of your declarations, provided data and User Content onto IPFS, and other decentralized servers, blockchains and registries as part of the Services. </p>
    <h4>5.Royalty Fees.</h4>
    <p>During the minting process, you may be provided an opportunity to specify a resale royalty that you may be entitled to receive when an NFT you mint is resold after the initial sale subject to the operation of any third-party technology used in connection with such resale. You understand and agree that we have no responsibility for or involvement in whether a resale royalty is actually paid to you, and we shall not owe you anything if you do not receive any resale royalty. </p>
    <h4>6.You may be required to pay fees for use of the Services.</h4> 
    <p>Applicable fees will be set forth on the Site or as part of the Services. The Fees and payment methods available to pay the fees are subject to change at any time, for any reason, and in our sole and exclusive discretion. </p>
    <h4>7. Payment Terms. </h4> 
    <p>You may be required to have a valid whitelisted Metamask wallet and form of payment that are compatible with the Ethereum blockchain on which you intend to use our Services. You must have all necessary legal authorization to use that wallet and/or payment to complete the transaction. </p>
    <h4>8.No Refunds. </h4>
    <p>All transactions are irreversible, final and there are no refunds. Please carefully review your order prior to confirming the Services being ordered. </p>
    <h4>9.Acceptable Use of the Site and Services</h4>
    <p>You are responsible for your use of the Site and Services, and for any use of the Services made using your account or wallet. Our goal is to create a positive, useful, legal and safe user experience. To promote this goal, we prohibit certain kinds of conduct that may be harmful to other users or to us. When you use the Services, you may not: 
    •	violate any law or regulation; 
    •	violate, infringe, or misappropriate other people’s intellectual property, privacy, publicity, or other legal rights; 
    •	post or share anything that is illegal, abusive, harassing, harmful to reputation, pornographic, indecent, profane, obscene, hateful, racist, or otherwise objectionable; 
    •	send unsolicited or unauthorized advertising or commercial communications, such as spam; 
    •	engage in spidering or harvesting, or participate in the use of software, including spyware, designed to collect data from the Services; 
    •	transmit any viruses or other computer instructions or technological means whose purpose is to disrupt, damage, or interfere with the use of computers or related systems; 
    •	stalk, harass, or harm another individual; 
    •	impersonate any person or entity or perform any other similar fraudulent activity, such as phishing; 
    •	use any means to scrape or crawl any Web pages contained in the Site; 
    •	attempt to circumvent any technological measure implemented by us or any of our providers or any other third party (including another user) to protect the Services; 
    •	attempt to decipher, decompile, disassemble, or reverse engineer any of the software or other underlying code used to provide the Services; or 
    •	advocate, encourage, or assist any third party in doing any of the foregoing. </p>
    
    <h4>10.User Content.</h4>  
    <p>The Site and some of our Services allow you to upload, submit, store, send, or receive content and data (“User Content”). User Content includes, without limitation, any art, media or other creative works, including the written content such as license agreements you choose to upload. You retain ownership of any intellectual property rights that you hold in that User Content. 
    When you provide User Content to the Services, you give us permission under all of the rights in any copyright associated with the User Content to reproduce and use your User Content for all activities and purposes related to operating the Site and Services as they exist now and, in the future, including the right to display User Content in public registries. This license is for the limited purpose of operating, promoting, and improving the Site and Services, and to develop new Services.  
    You promise and legally represent that: 
    you own all rights to your User Content or, alternatively, that you have the right to give us the rights described above; and 
    your User Content does not infringe the intellectual property rights, privacy rights, publicity rights, or other legal rights of any third party. 
    We may refuse to accept or transmit User Content for any reason. We may remove from the Services or disable access to any User Content for any reason. </p>

    <h4>11. Ownership of the Services.</h4>  
    <p>Other than the User Content, we (or our licensors) own all right, title, and interest in and to the Site and Services, including all software, text, media, and other content available on the Site and Services. The Site and Services are all protected under U.S. and international laws. The look and feel of the Site and Services are copyright NFT MARKEPLACE. All rights reserved. You may not duplicate, copy, or reuse any portion of the Site, including any HTML/CSS, Javascript, or visual design elements or concepts without express written permission from us. </p>
    
    <h4>12. Privacy</h4>
    <p>Your privacy is very important to us. Our Privacy Policy explains how we collect, use, protect, and when we share personal information and other data with others. You are responsible for maintaining the confidentiality of your account information, including your private key. You are responsible for all activities that occur under your account, and you agree to notify us immediately of any unauthorized access or use of your account. We are not responsible or liable for any damage or loss related to any unauthorized access or use of your account. </p>
    
    
    <h4>13. Links</h4>
    <p>The Site and Services may contain links to other websites and online resources. A link to a third party’s website does not mean that we endorse it or that we are affiliated with it. We are not responsible or liable for any damage or loss related to the use of any third-party website. You should always read the terms and conditions and privacy policy of a third-party website before using it. </p>
    <h4>14. Changes to the Services</h4>
    <p>We enhance and update the Site and Services often. We may change or discontinue the Services at any time, with or without notice to you. </p>
    <h4>15. Termination</h4>
    <p>We reserve the right to not provide the Services to any person for any or no reason. We also reserve the right to terminate any user’s right to access the Services at any time, in our discretion. If you violate any of these Terms, your permission to use the Site and Services automatically terminates. </p>
    <h4>16. File Storage.</h4>
    <p>Licenses and declarations you submit to us when using our Services including without limitation the minting of an NFT may be stored on the InterPlanetary File System (“IPFS”) or other storage server or system that we may specify in the future (“File Storage”). You understand and agree that we do not control the IPFS, or any File Storage and we are not responsible for repairing, supporting, replacing, or maintaining the IPFS or File Storage, nor do we have the obligation to maintain any connection or link between an NFT. </p>

    <h4>17. Disclaimer and Limitations on Our Liability </h4>
    <p>YOU USE THE SITE AND SERVICES AT YOUR OWN RISK. THE SITE AND SERVICES ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, OUR COMPANY AND ITS OFFICERS, EMPLOYEES, DIRECTORS, SHAREHOLDERS, PARENTS, SUBSIDIARIES, AFFILIATES, AGENTS, AND LICENSORS (“AFFILIATES”) DISCLAIM ALL WARRANTIES, CONDITIONS, AND REPRESENTATIONS OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING THOSE RELATED TO MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT AND THOSE ARISING OUT OF COURSE OF DEALING OR USAGE OF TRADE. 
    IN PARTICULAR, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, OUR COMPANY AND ITS AFFILIATES MAKE NO REPRESENTATIONS OR WARRANTIES ABOUT THE ACCURACY OR COMPLETENESS OF CONTENT AVAILABLE ON OR THROUGH THE SERVICES, OR THE CONTENT OF ANY WEBSITES OR ONLINE SERVICES LINKED TO OR INTEGRATED WITH THE SERVICES. OUR COMPANY AND ITS AFFILIATES WILL HAVE NO LIABILITY FOR ANY: (A) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT; (b) PERSONAL INJURY OR PROPERTY DAMAGE RESULTING FROM YOUR ACCESS TO OR USE OF THE SERVICES; (c) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SERVERS OR OF ANY PERSONAL INFORMATION OR USER DATA; (d) ANY INTERRUPTION OF TRANSMISSION TO OR FROM THE SERVICES; (e) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED ON OR THROUGH THE SERVICES BY ANY THIRD PARTY; OR (f) ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED OR SHARED THROUGH THE SERVICES. WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, COMPANY MAKES NO WARRANTY THAT THE SITE AND SERVICES WILL MEET YOUR REQUIREMENTS OR BE AVAILABLE ON AN UNINTERRUPTED, SECURE, OR ERROR-FREE BASIS. FURTHER, COMPANY MAKES NO WARRANTY REGARDING THE QUALITY, ACCURACY, TIMELINESS, TRUTHFULNESS, COMPLETENESS OR RELIABILITY OF ANY INFORMATION OR CONTENT ON THE SERVICES. 
    YOU UNDERSTAND AND AGREE THAT, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ANY MATERIAL OR INFORMATION DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE SERVICES IS DONE AT YOUR OWN RISK AND THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE ARISING FROM DOING SO. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM US OR THROUGH THE SERVICES WILL CREATE ANY WARRANTY NOT EXPRESSLY MADE. 
    NFTS ARE INTANGIBLE DIGITAL ASSETS. THEY EXIST ONLY BY VIRTUE OF THE OWNERSHIP RECORD MAINTAINED IN THE APPLICABLE BLOCKCHAIN NETWORK. ANY TRANSACTION THAT MIGHT OCCUR IN ANY UNIQUE DIGITAL ASSET OCCURS ON THE DECENTRALIZED LEDGER WITHIN SUCH BLOCKCHAIN NETWORK, WHICH COMPANY DOES NOT CONTROL. YOU UNDERSTAND AND AGREE THAT, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, OUR COMPANY will not be responsible for any communication failures, disruptions, errors, distortions or delays you may experience when effecting NFT OR BLOCKCHAIN transactions, to the extent caused by or related to third-party technology, platforms, services or other functionality, including without limitation any wallets, smart contracts, blockchains or file storage systems. 
    TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL WE BE LIABLE TO YOU OR TO ANY THIRD PARTY FOR ANY INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES (INCLUDING FOR LOSS OF PROFITS, REVENUE, OR DATA) OR FOR THE COST OF OBTAINING SUBSTITUTE PRODUCTS ARISING OUT OF OR IN CONNECTION WITH THESE TERMS, HOWEVER CAUSED, WHETHER SUCH LIABILITY ARISES FROM ANY CLAIM BASED UPON CONTRACT, WARRANTY, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY OR OTHERWISE, AND WHETHER OR NOT WE’VE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. 
    TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, OUR TOTAL CUMULATIVE LIABILITY TO YOU OR ANY THIRD PARTY UNDER THESE TERMS, FROM ALL CAUSES OF ACTION AND ALL THEORIES OF LIABILITY, WILL BE LIMITED TO AND WILL NOT EXCEED THE AMOUNTS YOU HAVE ACTUALLY PAID US DURING THE TWELVE (12) MONTHS PRECEDING THE CLAIM GIVING RISE TO SUCH LIABILITY. 
    You understand and agree that we have set our fees and entered these Terms with you in reliance upon the limitations of liability set forth in these Terms, which allocate risk between us and form the basis of a bargain between the parties. </p>

    <h4>18. Indemnification </h4>
    <p>To the maximum extent permitted by applicable law, you agree to indemnify and hold harmless our company and its Affiliates from and against any and all claims, costs, proceedings, demands, losses, damages, and expenses (including, without limitation, reasonable attorney’s fees and legal costs) of any kind or nature, relating to, any actual or alleged breach of these Terms by you or anyone using your account or wallet. If we assume the defense of such a matter, you will reasonably cooperate with us in such defense. </p>
    <h4>19. Arbitration Agreement & Waiver of Certain Rights </h4>
    <p>Except as set forth below, you and we agree that we will resolve any disputes between us (including any disputes between you and a third-party agent of ours) through binding and final arbitration instead of through court proceedings. You and we hereby waive any right to a jury trial of any Claim (defined below). All controversies, claims, counterclaims, or other disputes between you and us or you and a third-party agent of ours (each a “Claim”) shall be submitted for binding arbitration in accordance with the Rules of the American Arbitration Association (“AAA Rules”). The arbitration will be heard and determined by a single arbitrator. The arbitrator’s decision in any such arbitration will be final and binding upon the parties and may be enforced in any court of competent jurisdiction. You and we agree that the arbitration proceedings will be kept confidential and that the existence of the proceeding and any element of it (including, without limitation, any pleadings, briefs or other documents submitted or exchanged and any testimony or other oral submissions and awards) will not be disclosed beyond the arbitration proceedings, except as may lawfully be required in judicial proceedings relating to the arbitration, by applicable disclosure rules and regulations of securities regulatory authorities or other governmental agencies, or as specifically permitted by state law. The Federal Arbitration Act and federal arbitration law apply to this agreement. However, the Arbitrator, and not any federal, state, or local court or agency, shall have the exclusive authority to resolve any dispute relating to the interpretation, applicability, enforceability, or formation of these Terms including, but not limited to, a claim that all or any part of these Terms is void or voidable. 
    If you demonstrate that the costs of arbitration will be prohibitive as compared to the costs of litigation, we will pay as much of the administrative costs and arbitrator’s fees required for the arbitration as the arbitrator deems necessary to prevent the cost of the arbitration from being prohibitive. In the final award, the arbitrator may apportion the costs of arbitration and the compensation of the arbitrator among the parties in such amounts as the arbitrator deems appropriate. 
    This arbitration agreement does not preclude either party from seeking action by federal, state, or local government agencies. You and we also have the right to bring qualifying claims in small claims court. In addition, you and we retain the right to apply to any court of competent jurisdiction for provisional relief, including pre-arbitral attachments or preliminary injunctions, and any such request shall not be deemed incompatible with these Terms, nor a waiver of the right to have disputes submitted to arbitration as provided in these Terms. 
    Neither you nor we may act as a class representative or private attorney general, nor participate as a member of a class of claimants, with respect to any Claim. Claims may not be arbitrated on a class or representative basis. The arbitrator can decide only your and/or our individual Claims. The arbitrator may not consolidate or join the claims of other persons or parties who may be similarly situated. The arbitrator may award in the arbitration the same damages or other relief available under applicable law, including injunctive and declaratory relief, as if the action were brought in court on an individual basis. Notwithstanding anything to the contrary in the foregoing or herein, the arbitrator may not issue a “public injunction” and any such “public injunction” may be awarded only by a federal or state court. If either party seeks a “public injunction,” all other claims and prayers for relief must be adjudicated in arbitration first and any prayer or claim for a “public injunction” in federal or state court stayed until the arbitration is completed, after which the federal or state court can adjudicate the party’s claim or prayer for “public injunctive relief.” In doing so, the federal or state court is bound under principles of claim or issue preclusion by the decision of the arbitrator. 
    If any provision of this Section is found to be invalid or unenforceable, then that specific provision shall be of no force and effect and shall be severed, but the remainder of this Section shall continue in full force and effect. No waiver of any provision of this Section of the Terms will be effective or enforceable unless recorded in a writing signed by the party waiving such a right or requirement. Such a waiver shall not waive or affect any other portion of these Terms. This Section of the Terms will survive the termination of your relationship with us. 
    THIS SECTION LIMITS CERTAIN RIGHTS, INCLUDING THE RIGHT TO MAINTAIN A COURT ACTION, THE RIGHT TO A JURY TRIAL, THE RIGHT TO PARTICIPATE IN ANY FORM OF CLASS OR REPRESENTATIVE CLAIM, THE RIGHT TO ENGAGE IN DISCOVERY EXCEPT AS PROVIDED IN AAA RULES, AND THE RIGHT TO CERTAIN REMEDIES AND FORMS OF RELIEF. OTHER RIGHTS THAT YOU OR WE WOULD HAVE IN COURT ALSO MAY NOT BE AVAILABLE IN ARBITRATION. </p>
    
    <h4>20. Arbitration Agreement & Waiver of Certain Rights </h4>
    <p>Except as set forth below, you and we agree that we will resolve any disputes between us (including any disputes between you and a third-party agent of ours) through binding and final arbitration instead of through court proceedings. You and we hereby waive any right to a jury trial of any Claim (defined below). All controversies, claims, counterclaims, or other disputes between you and us or you and a third-party agent of ours (each a “Claim”) shall be submitted for binding arbitration in accordance with the Rules of the American Arbitration Association (“AAA Rules”). The arbitration will be heard and determined by a single arbitrator. The arbitrator’s decision in any such arbitration will be final and binding upon the parties and may be enforced in any court of competent jurisdiction. You and we agree that the arbitration proceedings will be kept confidential and that the existence of the proceeding and any element of it (including, without limitation, any pleadings, briefs or other documents submitted or exchanged and any testimony or other oral submissions and awards) will not be disclosed beyond the arbitration proceedings, except as may lawfully be required in judicial proceedings relating to the arbitration, by applicable disclosure rules and regulations of securities regulatory authorities or other governmental agencies, or as specifically permitted by state law. The Federal Arbitration Act and federal arbitration law apply to this agreement. However, the Arbitrator, and not any federal, state, or local court or agency, shall have the exclusive authority to resolve any dispute relating to the interpretation, applicability, enforceability, or formation of these Terms including, but not limited to, a claim that all or any part of these Terms is void or voidable. 
    If you demonstrate that the costs of arbitration will be prohibitive as compared to the costs of litigation, we will pay as much of the administrative costs and arbitrator’s fees required for the arbitration as the arbitrator deems necessary to prevent the cost of the arbitration from being prohibitive. In the final award, the arbitrator may apportion the costs of arbitration and the compensation of the arbitrator among the parties in such amounts as the arbitrator deems appropriate. 
    This arbitration agreement does not preclude either party from seeking action by federal, state, or local government agencies. You and we also have the right to bring qualifying claims in small claims court. In addition, you and we retain the right to apply to any court of competent jurisdiction for provisional relief, including pre-arbitral attachments or preliminary injunctions, and any such request shall not be deemed incompatible with these Terms, nor a waiver of the right to have disputes submitted to arbitration as provided in these Terms. 
    Neither you nor we may act as a class representative or private attorney general, nor participate as a member of a class of claimants, with respect to any Claim. Claims may not be arbitrated on a class or representative basis. The arbitrator can decide only your and/or our individual Claims. The arbitrator may not consolidate or join the claims of other persons or parties who may be similarly situated. The arbitrator may award in the arbitration the same damages or other relief available under applicable law, including injunctive and declaratory relief, as if the action were brought in court on an individual basis. Notwithstanding anything to the contrary in the foregoing or herein, the arbitrator may not issue a “public injunction” and any such “public injunction” may be awarded only by a federal or state court. If either party seeks a “public injunction,” all other claims and prayers for relief must be adjudicated in arbitration first and any prayer or claim for a “public injunction” in federal or state court stayed until the arbitration is completed, after which the federal or state court can adjudicate the party’s claim or prayer for “public injunctive relief.” In doing so, the federal or state court is bound under principles of claim or issue preclusion by the decision of the arbitrator. 
    If any provision of this Section is found to be invalid or unenforceable, then that specific provision shall be of no force and effect and shall be severed, but the remainder of this Section shall continue in full force and effect. No waiver of any provision of this Section of the Terms will be effective or enforceable unless recorded in a writing signed by the party waiving such a right or requirement. Such a waiver shall not waive or affect any other portion of these Terms. This Section of the Terms will survive the termination of your relationship with us. 
    THIS SECTION LIMITS CERTAIN RIGHTS, INCLUDING THE RIGHT TO MAINTAIN A COURT ACTION, THE RIGHT TO A JURY TRIAL, THE RIGHT TO PARTICIPATE IN ANY FORM OF CLASS OR REPRESENTATIVE CLAIM, THE RIGHT TO ENGAGE IN DISCOVERY EXCEPT AS PROVIDED IN AAA RULES, AND THE RIGHT TO CERTAIN REMEDIES AND FORMS OF RELIEF. OTHER RIGHTS THAT YOU OR WE WOULD HAVE IN COURT ALSO MAY NOT BE AVAILABLE IN ARBITRATION. </p>
    
    <h4>21. Changes to these Terms </h4>
    <p>From time to time, we may change these Terms. If we change these Terms, we will give you notice by posting the revised Terms on the Site. Those changes will go into effect on the Revision Date shown in the revised Terms. By continuing to use the Services, you are agreeing to the revised Terms. You may be required to indicate acceptance of the changed Terms to continue use of the Services. </p>
    </div>
  )
}

export default TermsOfUsePixentro