import React, { useState, useEffect, useContext } from "react";
import CarouselNewRedux from "../components/CarouselNewRedux";
import { useHistory } from "react-router-dom";
import { Carousel, notification } from "antd";
import { createGlobalStyle } from "styled-components";
import { MetamaskService } from "../components/Shared/Metamask.service";

import { HomeService } from "./../components/services/home/home.service";
import { NFTCreatorService } from "../components/services/NFTCreator/NFTCreator.service";
import authContext from "./../components/Shared/Auth.service";
import "./homePage.scss";
import { environment } from "../environments/environment";
import CarouselNft101 from "../components/CarouselNft101";
import HomePageSoldNftsCarousel from "./homePage/homePageSoldNftsCarousel";
import HomePageLearns from "./homePage/homePageLearns";
import HomePageFeatures from "./homePage/homePageFeatures";
import HomePageSubscribeSection from "./homePage/homePageSubscribeSection";
import HomePageCategoriesSection from "./homePage/homePageCategoriesSection";
import HomePageCollections from "./homePage/homePageCollections";
import { SharedService } from "../components/Shared/Shared.service";
import CreateNftImg from "./../assets/images/CommunityStandards/createNftImg.png";

const homeService = new HomeService();
const nftService = new NFTCreatorService();
const sharedService = new SharedService();

const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar .search #quick_search{
    background: rgba(255, 255, 255, .1);
  }
  header#myHeader .logo .d-block{
    display: none !important;
  }
  header#myHeader .logo .d-none{
    display: none !important;
  }
  header#myHeader .logo .d-3{
    display: block !important;
  }
  footer.footer-light .subfooter span img.d-1{
    display: none !important;
  }
  footer.footer-light .subfooter span img.d-3{
    display: inline-block !important;
  }
  .de_countdown{
    right: 10px;
  }
  .author_list_pp{
    margin-left:0;
  }
  footer.footer-light .subfooter{
    border-top: 1px solid rgba(255,255,255,.1);
  }
`;

const useSelectedWalletContext = () =>
  new MetamaskService().useSelectedWalletContext();

const Home = () => {
  const { userInfo, setUserInfo } = useContext(authContext);
  const [loading, setLoading] = useState(false);
  const [nftCategories, setNftCategories] = useState([]);
  const [allCollections, setAllCollections] = useState([]);
  const [countdown, setCountdown] = useState({ days: 0, hours: 0, minutes: 0 });
  const { networkId } = useSelectedWalletContext();
  const [todayEtheriumPriceInUSD, setTodayEtheriumPriceInUSD] = useState();

  const history = useHistory();

  useEffect(() => {
    if (userInfo?.role === "marketplace-super-admin") {
      history.push("/marketplace-admin/super-admin");
    } else if (userInfo?.role === "marketplace-compliance-admin") {
      history.push("/marketplace-admin/compliance-admin");
    } else if (userInfo?.role === "marketplace-token-admin") {
      history.push("/marketplace-admin/token-admin");
    }
  }, [userInfo]);

  useEffect(() => {
    (async () => {
      setLoading(true);

      if (!networkId) return;

      if (!environment.beforeGoLive) {
        //setting ether price
        setTodayEtheriumPriceInUSD(await sharedService.getEtheriumPrice());
        // setCategories((await homeService.getCategories()).data);
        //getting categories
        let res = await nftService.getAllCategories();
        if (res.data?.length > 0) {
          setNftCategories(res.data);
        } else {
          console.error("can not get categories");
          notification.open({
            message: "Error",
            description:
              "Error while tries to get categories, Please try after some time.",
            duration: 0,
          });
        }

        let res1 = await nftService.getAllCollectionDetails({
          networkId,
          pageSize: 5,
        });
        if (res1.data) {
          if (res1.data?.length > 0) setAllCollections([...res1.data]);
        } else {
          console.error("can not get collections");
          notification.open({
            message: "Error",
            description:
              "Error while tries to get collections, Please try after some time.",
            duration: 0,
          });
        }
      }

      setLoading(false);
    })();
  }, [networkId]);

  useEffect(() => {
    if (environment.beforeGoLive) {
      const targetDate = new Date(`${environment.goLiveDate}`).getTime();

      const updateCountdown = () => {
        const now = new Date().getTime();
        const distance = targetDate - now;
        console.log(distance);
        if (distance < 0) {
          // Countdown has ended
          setCountdown({ days: 0, hours: 0, minutes: 0 });
        } else {
          // Calculate remaining time
          const days = Math.floor(distance / (1000 * 60 * 60 * 24));
          const hours = Math.floor(
            (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          );
          const minutes = Math.floor(
            (distance % (1000 * 60 * 60 * 24)) / (1000 * 60)
          );

          setCountdown({ days, hours, minutes });
        }
      };

      const timer = setInterval(updateCountdown, 60 * 1000);
      const timer2 = setTimeout(updateCountdown, 100);

      return () => {
        clearInterval(timer);
        clearInterval(timer2);
      };
    }
  }, [environment.beforeGoLive]);

  return (
    <div>
      <GlobalStyles />

      {/* This is Banner section */}
      <Carousel
        className="carousel-container carousel-homepage"
        autoplay={true}
      >
        <section className="banner home_bg_banner banner-1">
          <div className={`banner-background ${environment.envName}`}>
            <div className="container-fluid">
              <div className="d-flex align-items-d-flex align-content-between flex-wrap">
                <div className="col-6 position-relative banner-text">
                  <div className="banner-left horizontal-vertical-middle-align-adjust">
                    {/* <img className="home_nft_text" src="./img/home/Group3.png" /> */}
                    <div>
                      <h2 className="home-page gradient-text1">
                        Start Framing
                      </h2>
                      <h2
                        style={{
                          fontFamily: "Poppins sans-serif",
                          color: "white",
                          fontSize: "44px",
                          fontWeight: 400,
                          lineHeight: "30px",
                          letterSpacing: "0.05em",
                          textAlign: "left",
                          width: "300px",
                        }}
                      // className="home-page gradient-text2"
                      >
                        your own story
                      </h2>
                      <h2
                        style={{
                          fontFamily: "Poppins sans-serif",
                          fontSize: "44px",
                          color: "white",
                          fontWeight: 400,
                          lineHeight: "30px",
                          letterSpacing: "0.05em",
                          textAlign: "left",
                          width: "300px",
                        }}
                      // className="home-page gradient-text3"
                      >
                        with NFT art
                      </h2>
                      <p className="home-page text_trade">
                        Trade, create and explore most wanted digital assets.
                      </p>
                    </div>
                    <div className="d-flex position-relative">
                      <img
                        className="home-page line_img"
                        src="./img/home/line.png"
                      />
                      <button
                        type="button"
                        style={{ marginTop: "30px" }}
                        className="full_btn me-3"
                        onClick={() =>
                          (window.location.href = "/account/register/nft-user")
                        }
                      >
                        {environment.registerWithInvitation === true
                          ? "Join by Invitation"
                          : "GET STARTED"}
                      </button>
                      <button
                        type="button"
                        style={{
                          borderRadius: "50px",
                          fontSize: "16px",
                          cursor: "pointer",
                          fontWeight: 600,
                          color: "#FFFFFF",
                          minWidth: "160px",
                          padding: "7px",
                          marginTop: "26px",
                          backgroundColor: "#0C3F64",
                          border: "2px solid #FFFFFF",
                          width: "160px",
                          height: "45px",
                        }}
                        onClick={() => (window.location.href = "/all-nft")}
                      >
                        EXPLORE
                      </button>
                    </div>
                    {environment.beforeGoLive && (
                      <Timer
                        days={countdown.days}
                        hours={countdown.hours}
                        minutes={countdown.minutes}
                      />
                    )}
                  </div>
                </div>
                <div className="col-6 artImages banner-image">
                  <img
                    className="nftArt"
                    /*    src={environment.homePageBannerImageUrl} */
                    src="./img/home/NFT-Art.png"
                    alt="nftArt"
                    style={{
                      maxHeight: "100%",
                      marginTop: "10px",
                      maxWidth: "100%",
                      backgroundSize: "cover",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="banner home_bg_banner banner-2">
          <div className={`banner-background ${environment.envName}`}>
            <div className="container-fluid">
              <div className="d-flex align-items-d-flex align-content-between flex-wrap">
                <div className="col-6 position-relative banner-text">
                  <div className="banner-left horizontal-vertical-middle-align-adjust">
                    {/* <img className="home_nft_text" src="./img/home/Group3.png" /> */}
                    <h2 className="home-page gradient-text1">Explore NFTs</h2>
                    <p
                      style={{
                        fontFamily: "Poppins sans-serif",
                        fontSize: "20px",
                        fontWeight: 400,
                        lineHeight: "30px",
                        letterSpacing: "0.05em",
                        textAlign: "left",
                        width: "300px",
                      }}
                    >
                      from creators worldwide
                    </p>
                    <p style={{ color: "white", fontWeight: "300" }}>
                      Gain early access to the Metaverse!
                    </p>
                    <div className="d-flex position-relative">
                      {/* <img className="line_img" src="./img/home/line.png" />
                      <button
                        type="button"
                        className="full_btn me-3"
                        onClick={() =>
                          (window.location.href = "/account/register/nft-user")
                        }
                      >
                        {environment.registerWithInvitation === true
                          ? "Join by Invitation"
                          : "GET started"}
                      </button> */}
                      <button
                        type="button"
                        style={{
                          borderRadius: "50px",
                          fontSize: "16px",
                          cursor: "pointer",
                          fontWeight: 600,
                          color: "#100D07",
                          minWidth: "160px",
                          padding: "7px",
                          marginTop: "26px",
                          backgroundColor: "#FFDB6D",
                          width: "160px",
                          height: "45px",
                        }}
                        onClick={() => (window.location.href = "/all-nft")}
                      >
                        EXPLORE
                      </button>
                    </div>
                    {environment.beforeGoLive && (
                      <Timer
                        days={countdown.days}
                        hours={countdown.hours}
                        minutes={countdown.minutes}
                      />
                    )}
                  </div>
                </div>
                <div className="col-6 artImages banner-image">
                  <img
                    // className="nftArt"
                    // src={environment.homePageBannerImageUrl}
                    src="./img/home/NFTArt1.svg"
                    alt="nftArt"
                    style={{
                      maxHeight: "100%",
                      marginTop: "-30px",
                      maxWidth: "100%",
                      backgroundSize: "cover",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="banner home_bg_banner banner-3">
          <div className={`banner-background ${environment.envName}`}>
            <div className="container-fluid">
              <div className="d-flex align-items-d-flex align-content-between flex-wrap">
                <div className="col-6 position-relative banner-text">
                  <div className="banner-left horizontal-vertical-middle-align-adjust">
                    {/* <img className="home_nft_text" src="./img/home/Group3.png" /> */}
                    <h2 className="home-page gradient-text1" style={{fontSize: '40px'}}>Thrill of NFT marketplace</h2>
                    <p
                      style={{
                        fontFamily: "Poppins sans-serif",
                        fontSize: "20px",
                        fontWeight: 400,
                        lineHeight: "30px",
                        letterSpacing: "0.05em",
                        textAlign: "left",
                        width: "500px",
                      }}
                    >
                      KYC Made Simple: Enter the Exciting World of
                      NFTs without Breaking the Law <span style={{color: '#FBDA6E'}}>Get Free KYC</span>
                    </p>
                    <p style={{ color: "white", fontWeight: "300" }}>
                    Gain early access to the Metaverse!
                    </p>
                    <div className="d-flex position-relative">
                      <button
                        type="button"
                        style={{
                          borderRadius: "50px",
                          fontSize: "16px",
                          cursor: "pointer",
                          fontWeight: 600,
                          color: "#100D07",
                          minWidth: "160px",
                          padding: "7px",
                          marginTop: "26px",
                          backgroundColor: "#FFDB6D",
                          width: "160px",
                          height: "45px",
                        }}
                        onClick={() => (window.location.href = "/all-nft")}
                      >
                        EXPLORE
                      </button>
                    </div>
                    {environment.beforeGoLive && (
                      <Timer
                        days={countdown.days}
                        hours={countdown.hours}
                        minutes={countdown.minutes}
                      />
                    )}
                  </div>
                </div>
                <div className="col-6 artImages banner-image">
                  <img
                    // className="nftArt"
                    // src={environment.homePageBannerImageUrl}
                    src="./img/home/NFT-Art2.png"
                    alt="nftArt"
                    style={{
                      marginLeft: "20px",
                      marginTop: "-15px",
                      maxWidth: "550px",
                      maxHeight: "500px",
                      backgroundSize: "cover",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </Carousel>

      {/* nft create section */}
      {userInfo?._id &&
        userInfo?.role.indexOf("admin") < 0 &&
        !environment.beforeGoLive && (
          <div className="d-flex justify-content-center align-items-center">
            <div
              style={{
                cursor: "pointer",
                backgroundImage: `url(${CreateNftImg})`,
              }}
              className="home-page CreateNftImg d-flex flex-column justify-content-center"
            >
              <div className="create-nft-div">
                <p className="create-your-unique-text">Create your unique</p>
                <p className="NFT-collection-text">NFT collection</p>
                <p>
                  <span className="NFT-collection-in-text">in</span>
                  <span className="Market-place-text">Market place</span>
                </p>
                <button
                  className="Create-now-button"
                  onClick={() => history.push("/create")}
                >
                  Create now
                </button>
              </div>
            </div>
          </div>
        )}

      {/* Collections + nfts section */}
      <HomePageCollections
        allCollections={allCollections}
        todayEtheriumPriceInUSD={todayEtheriumPriceInUSD}
      />

      {/* This is collection section */}

      {/* {!environment.beforeGoLive && (
        <div className="container-fluid collection">
          <div className="col-12 text-center home-page collection-text-div">
            <h2 className="home-page collection-text">Collections</h2>
            <p className="home-page collection-sub-text">Most popular gaming digital nft market place</p>
            <div className="d-flex justify-content-center">
              <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="pills-home-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-home"
                  type="button"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected="true"
                >
                  Trending
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="pills-profile-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-profile"
                  type="button"
                  role="tab"
                  aria-controls="pills-profile"
                  aria-selected="false"
                >
                  Top
                </button>
              </li>
            </ul> 
            </div>
          </div>
          <div className="row">
            <div className="col-3 d-flex">
              <p className="home-page collection-p-text">Collections</p>
            </div>
            <div className="col-3 d-flex">
              <p className="home-page nft-collection-p-text">NFTs</p>
            </div>
            <div className="col-6 d-flex justify-content-end">
              <button
                type="button"
                className="btn_view_all float-end home-page view-all-btn"
                onClick={() => window.open("/collections", "_self")}
              >
                VIEW ALL
              </button>
            </div>
          </div>
          <hr />

         <div className="tab-content" id="pills-tabContent">
            <div
              className="tab-pane fade show active"
              id="pills-home"
              role="tabpanel"
              aria-labelledby="pills-home-tab"
              tabIndex="0"
            >
              <div className="row"> 
                 {allCollections?.length > 0 &&
                  allCollections.map((collection, index) => (
                    <div key={index}>
                      <div className="col-3" key={index}>
                        <div className="card border-0">
                          <div className="row g-0">
                            <div className="col-md-3 position-relative d-flex justify-content-end">
                              <img
                                src={`${collection?.collectionDocument?.FirebaseURL}`}
                                className="img-fluid float-end home-page collection-img"
                                alt=""
                                onClick={() =>
                                  window.open(
                                    `/collection/${collection?.collectionName}`,
                                    "_self"
                                  )
                                }
                              />
                              <img
                                src="./img/home/Frame1520.png"
                                className="home-page tick_mark"
                                alt=""
                              />
                            </div>
                            <div className="col-md-9 d-flex justify-content-start home-page collection-name-div align-items-center">
                              <div>
                                <p className="card-text text-dark home-page collection-name">
                                  {collection?.collectionName}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-9 home-page-collecion collection-nft-list" key={index}>
                        <Slider {...carouselCollectionNew}>
                          {collection?.nftDetails?.length > 0 &&
                            collection.nftDetails.map((nft, index) => (
                              <div
                                className="position-relative me-3 image-click"
                                key={index}
                                onClick={() =>
                                  window.open(
                                    `/item-buy/${nft.nftId}`,
                                    "_self"
                                  )
                                }
                                style={{ cursor: "pointer" }}
                              >
                                {nft.nftType === "mp4" ? (
                                  <>
                                    <img
                                      src={nft?.videoThumbnailUrl}
                                      alt="nft-img"
                                      className="home-page nft-image"
                                    />
                                  </>
                                ) : (
                                  <img
                                    src={nft?.nftIPFSURL}
                                    alt="nft-img"
                                    className="home-page nft-image"
                                  />
                                )}
                                {(nft.price || nft.bid) && (
                                  <p
                                    className="home-page crypto_text"
                                    style={{ cursor: "pointer" }}
                                  >
                                    {(nft.price || nft.bid) + "ETH"}
                                  </p>
                                )}
                              </div>
                            ))}
                        </Slider>
                      </div>
                    </div>
                  ))} 
              </div>
            </div>
          </div>
        </div>)} */}

      {/* NFT 101 section */}
      {/* <CarouselNft101 /> */}

      {/* This is category section */}
      {!environment.beforeGoLive && (
        <HomePageCategoriesSection nftCategories={nftCategories} />
      )}

      {/* This is new nft section */}
      {!environment.beforeGoLive && <CarouselNewRedux sell={true} />}

      {/* Sold Nfts section */}
      {/* <HomePageSoldNftsCarousel todayEtheriumPriceInUSD={todayEtheriumPriceInUSD} /> */}

      {/* Features section */}
      <HomePageFeatures />

      {/* Explore on NFT Marketplace section */}
      <HomePageLearns />

      {/* Subscribe Section */}
      {/* <HomePageSubscribeSection /> */}
    </div>
  );
};

const Timer = ({ days, hours, minutes }) => {
  return (
    <>
      {environment.registerWithInvitation === true ? (
        <>
          <div className="coming-soon">
            Join us by invitation for exclusive access and connections.
          </div>
          <div className="coming-soon">Launching Soon</div>
          <div className="coming-soon" style={{ color: "yellow" }}>
            Pixento runs on Polygon network. Use Metmask Polygon network Matic
            tokens
          </div>
        </>
      ) : (
        <>
          <div className="countdown-timer">
            <div className="countdown-timer-box">
              <p className="countdown-timer-label">Days</p>
              <p className="countdown-timer-value">{days}</p>
            </div>
            <div className="countdown-timer-box">
              <p className="countdown-timer-label">Hours</p>
              <p className="countdown-timer-value">{hours}</p>
            </div>
            <div className="countdown-timer-box">
              <p className="countdown-timer-label">Minutes</p>
              <p className="countdown-timer-value">{minutes}</p>
            </div>
          </div>
          <div className="coming-soon">Coming Soon</div>
        </>
      )}
    </>
  );
};

export default Home;
