import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { VideoPlayer } from "./mainSlider/VideoPlayer";
import authContext from "./Shared/Auth.service";
import ProfilePic from "../assets/images/home/profile-default.png";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const Outer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: 260px;
  overflow: hidden;
  border-radius: 8px;
`;

const NFTCard = ({ nft, buy, isCreator = false, todayEtheriumPriceInUSD, checkDetail, fromNft101 = false, nftCardBtnText = "" }) => {
  const { userInfo, setUserInfo } = useContext(authContext);
  const [buyScreen, setBuyScreen] = useState(buy || false);
  const history = useHistory();
  const [loaded, setLoaded] = useState(true);

  const bid = (nft) => {
    // making then buy now available for public
    history.push(`/item-buy/${nft._id}`, "_self");

    // if(userInfo?._id) {
    //   history.push(`/item-buy/${nft._id}`, "_self")
    // }else {
    //   history.push(`/account/login`, "_self")
    // }
  };

  if (!nft) return <></>;

  return (
    <>
      <div className="home-page-card card nft-card">
        <span>
          {nft.file?.type === "mp4" || nft.file?.type === "MP4" ? (
            <VideoPlayer
              height={210}
              linkSrc={
                nft.file?.watermarkedUrl
                  ? nft.file?.watermarkedUrl
                  : nft.file?.IPFSURL
              }
              //coverImageLink={nft.file?.videoWatermarkImageURL}
              coverImageLink={nft.file?.videoThumbnailUrl}
              playerId={`player-${nft._id}`}
              coverId={`nft-cover-${nft._id}`}
              playButtonId={`play-${nft._id}`}
              subsButtonId={`sub-${nft._id}`}
              autoplay="off"
            />
          ) : (
             <>
              {loaded && <div className="shimmer" />}

              <LazyLoadImage
                src={
                  nft.file?.watermarkedUrl
                    ? nft.file?.watermarkedUrl
                    : nft.file?.IPFSURL
                }
                className="home-page-card lazy nft__item_preview card-img-top"
                effect="blur"
                onClick={() => !fromNft101 ? history.push(`/item-detail/${nft._id}`, "_self") : history.push(`${nft.cardUrl}`)}
                style={{ height: loaded && "0px", width: loaded && "0px" }}
                onLoad={() => setLoaded(false)}
              />
            </>
          )}
        </span>
        <div
          className={`card-body nft_card ${buyScreen ? "nft-card-buy" : ""}`}
        >
          <h5
            className="home-page card-title"
            onClick={() => history.push(`/item-detail/${nft._id}`, "_self")}
          >
            {nft.title}
          </h5>

          <div className="home-page nft-method-card">
            <div className="home-page nft-method-card-child-div">
              <div className="home-page nft-method-card-inside-div">
                <div className="flex-grow-1">
                  {!fromNft101 && <p className="home-page price-text">Method:</p>}
                  <h4 className="home-page price-in-eth nft-method-text">{nft.method}</h4>
                </div>
                <div
                  className="d-flex grey home-page pricing-div"
                  onClick={() => !fromNft101 ? history.push(`/item-detail/${nft._id}`, "_self") : history.push(`${nft.cardUrl}`)}
                >
                  {nft.method === "Fixed Price" && (
                    <>
                      <div
                        className="flex-grow-1"
                        onClick={() =>
                          history.push(`/item-detail/${nft._id}`, "_self")
                        }
                      >
                        <p className="home-page price-text">Price:</p>
                        <h4 className="home-page price-in-eth">
                          {nft.price} ETH
                        </h4>
                      </div>
                    </>
                  )}
                  {nft.method === "Auction" && (
                    <>
                      <div
                        className="flex-grow-1"
                        onClick={() =>
                          history.push(`/item-detail/${nft._id}`, "_self")
                        }
                      >
                        <p className="home-page price-text">Min Price:</p>
                        <h4 className="home-page price-in-eth">{nft.bid} ETH</h4>
                      </div>
                    </>
                  )}

                  {nft.method === "Fixed Price" && (
                    <>
                      <div
                        onClick={() => history.push(`/item-detail/${nft._id}`, "_self")}
                      >
                        <div className="flex-grow-1">
                          <p className="home-page price-text">Price in USD:</p>
                          <h4 className="home-page price-in-eth">
                            {(nft.price * todayEtheriumPriceInUSD).toFixed(3) + " $"}
                          </h4>
                        </div>
                      </div>
                    </>
                  )}
                  {nft.method === "Auction" && (
                    <>
                      <div
                        onClick={() => history.push(`/item-detail/${nft._id}`, "_self")}
                      >
                        <div className="flex-grow-1">
                          <p className="home-page price-text">Min Price in USD:</p>
                          <h4 className="home-page price-in-eth">
                            {(nft.bid * todayEtheriumPriceInUSD).toFixed(3) + " $"}
                          </h4>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>

              {nft.userId !== userInfo?._id && nft.createdBy !== userInfo?._id &&
                buyScreen &&
                nft.method !== "Fixed Price" && (
                  <div className="col-12 text-center bid-button">
                    <a
                      type="button"
                      className="nft-dashboard btn_view_all"
                      onClick={() => bid(nft)}
                    >
                      Place a Bid
                    </a>
                  </div>
                )}
              {nft.userId !== userInfo?._id &&
                buyScreen &&
                nft.method === "Fixed Price" && (
                  <div className="col-12 text-center bid-button">
                    <a
                      type="button"
                      className="nft-dashboard btn_view_all"
                      onClick={() => bid(nft)}
                    >
                      Buy Now
                    </a>
                  </div>
                )}
              {nft.userId !== userInfo?._id && checkDetail && (
                <div className="col-12 text-center bid-button">
                  <a
                    type="button"
                    className="nft-dashboard btn_view_all "
                    onClick={() => bid(nft)}
                  >
                    Check Details
                  </a>
                </div>
              )}
              {
                nftCardBtnText && (
                  <div className="col-12 text-center bid-button">
                    <a
                      type="button"
                      className="nft-dashboard btn_view_all "
                      style={{color: nftCardBtnText==="Approved" ? '#198754': '#149ec3', border: nftCardBtnText==="Approved" ? '2px solid #198754' : '2px solid #149EC3'  }}
                      onClick={() => history.push(`/item-detail/${nft._id}`, "_self")}
                    >
                      {nftCardBtnText}
                    </a>
                  </div>
                )
              }
            </div>
            {
              isCreator &&
              <div className="d-flex grey home-page creator-div">
                <div className="home-page creator-div-image-div">
                <LazyLoadImage
                    alt="profile pic"
                    src={nft.user?.profilePicUrl
                      ? nft.user.profilePicUrl
                      : ProfilePic}
                    effect="blur"
                    className="nft-detail profile-picurl home-page creator-div-image"
                  />
                </div>
                <div className="d-flex flex-direction-column home-page creator-par-div-created-by">
                  <p className="home-page creator-div-created-by">Created By</p>
                  <p className="home-page creator-div-created-by-name"> {nft.user?.firstName +
                    " " +
                    nft.user?.lastName}</p>
                </div>
              </div>
            }

          </div>

        </div>
      </div>
    </>
  );
};

export default NFTCard;