import { SharedService } from '../../Shared/Shared.service';
import { APIResponse} from '../../Shared/interfaces';
import { environment } from '../../../environments/environment';
import axios from 'axios';


const sharedService = new SharedService();


export class AdminService {
  private APIURL = environment.APIURL;

  async updateKYCDetails(form: any) {
      return axios.post<any, APIResponse>(
      `${this.APIURL}/admin/updateKYCDetails`,form,
      { headers: await sharedService.getAuthHeader() }
      );
  }

  async getPendingKYC(userId: any) {
    return axios.get<any, APIResponse>(
    `${this.APIURL}/admin/getPendingKYC?userId=${userId}`,
    { headers: await sharedService.getAuthHeader() }
    );
  }

  async getMarketPlaceConfiguration(userId: any, params: { networkId: string }) {
    return axios.get<any, APIResponse>(
    `${this.APIURL}/admin/getMarketPlaceConfiguration?userId=${userId}`,
    { headers: await sharedService.getAuthHeader(), params }
    );
  }

  async getAdminInvitesDetails(userId: any) {
    return axios.get<any, APIResponse>(
    `${this.APIURL}/admin/getAdminInvitesDetails?userId=${userId}`,
    { headers: await sharedService.getAuthHeader() }
    );
  }

  async getMarketplaceAdminDetails(userId: any, params: { networkId: string }) {
    return axios.get<any, APIResponse>(
    `${this.APIURL}/admin/getMarketplaceAdminDetails?userId=${userId}`,
    { headers: await sharedService.getAuthHeader(), params }
    );
  }

  async getAllInvestorDetails() {
    return axios.get<any, APIResponse>(
    `${this.APIURL}/admin/getAllInvestorDetails`,
    { headers: await sharedService.getAuthHeader() }
    );
  }

  async approveMarketplaceAdminDetails(form: any) {
    return axios.post<any, APIResponse>(
    `${this.APIURL}/admin/approveMarketplaceAdminDetails`,form,
    { headers: await sharedService.getAuthHeader() }
    );
  }

  async addPlatformCommissions(form: any) {
    return axios.post<any, APIResponse>(
    `${this.APIURL}/admin/addPlatformCommissions`,form,
    { headers: await sharedService.getAuthHeader() }
    );
  }

  async approveMPSuperAdminWallet(form: any) {
    return axios.post<any, APIResponse>(
    `${this.APIURL}/admin/approveMPSuperAdminWallet`,form,
    { headers: await sharedService.getAuthHeader() }
    );
  }

  async collectWhiteListingFee(form: any) {
    return axios.post<any, APIResponse>(
    `${this.APIURL}/admin/collectWhiteListingFee`,form,
    { headers: await sharedService.getAuthHeader() }
    );
  }

  async sendComplianceAdminInvite(email: any) {
    return axios.get<any, APIResponse>(
    `${this.APIURL}/admin/sendComplianceAdminInvite?email=${encodeURIComponent(email)}`,
    { headers: await sharedService.getAuthHeader() }
    );
  }

  async sendTokenAdminInvite(email: any) {
    return axios.get<any, APIResponse>(
    `${this.APIURL}/admin/sendTokenAdminInvite?email=${encodeURIComponent(email)}`,
    { headers: await sharedService.getAuthHeader() }
    );
  }

  async changeComplianceAdminEmail(email: any) {
    return axios.get<any, APIResponse>(
    `${this.APIURL}/admin/changeComplianceAdminEmail?email=${encodeURIComponent(email)}`,
    { headers: await sharedService.getAuthHeader() }
    );
  }

  async changeTokenAdminEmail(email: any) {
    return axios.get<any, APIResponse>(
    `${this.APIURL}/admin/changeTokenAdminEmail?email=${encodeURIComponent(email)}`,
    { headers: await sharedService.getAuthHeader() }
    );
  }

  async setMarketPlaceConfiguration(form: any) {
    return axios.post<any, APIResponse>(
    `${this.APIURL}/admin/setMarketPlaceConfiguration`,form,
    { headers: await sharedService.getAuthHeader() }
    );
  }

  async sendSuperAdminVerificationEmail(userId: any) {
    return axios.get<any, APIResponse>(
    `${this.APIURL}/admin/sendSuperAdminVerificationEmail?userId=${userId}`,
    { headers: await sharedService.getAuthHeader() }
    );
  }

  async sendComplianceAdminVerificationEmail(userId: any) {
    return axios.get<any, APIResponse>(
    `${this.APIURL}/admin/sendComplianceAdminVerificationEmail?userId=${userId}`,
    { headers: await sharedService.getAuthHeader() }
    );
  }

  async sendTokenAdminVerificationEmail(userId: any) {
    return axios.get<any, APIResponse>(
    `${this.APIURL}/admin/sendTokenAdminVerificationEmail?userId=${userId}`,
    { headers: await sharedService.getAuthHeader() }
    );
  }

  async getCompletedKYC(userId: any) {
    return axios.get<any, APIResponse>(
    `${this.APIURL}/admin/getCompletedKYC?userId=${userId}`,
    { headers: await sharedService.getAuthHeader() }
    );
  }

  async getKYCById(kycId: any) {
    return axios.get<any, APIResponse>(
    `${this.APIURL}/admin/getKYCById?kycId=${kycId}`,
    { headers: await sharedService.getAuthHeader() }
    );
  }

  async getPendingWalletComplianceAdmin(params: {networkId: string}) {
    return axios.get<any, APIResponse>(
    `${this.APIURL}/admin/getPendingWalletComplianceAdmin`,
    { headers: await sharedService.getAuthHeader(), params }
    );
  }

  async getCompletedWalletComplianceAdmin(params: {networkId: string}) {
    return axios.get<any, APIResponse>(
    `${this.APIURL}/admin/getCompletedWalletComplianceAdmin`,
    { headers: await sharedService.getAuthHeader(), params }
    );
  }

  async getCompletedWalletSuperAdmin(params: {networkId: string}) {
    return axios.get<any, APIResponse>(
    `${this.APIURL}/admin/getCompletedWalletSuperAdmin`,
    { headers: await sharedService.getAuthHeader(), params }
    );
  }

  async getPendingWalletSuperAdmin(params: {networkId: string}) {
    return axios.get<any, APIResponse>(
    `${this.APIURL}/admin/getPendingWalletSuperAdmin`,
    { headers: await sharedService.getAuthHeader(), params }
    );
  }

  async approveNftUserWalletComplianceAdmin(form: any) {
    return axios.post<any, APIResponse>(
    `${this.APIURL}/admin/approveNftUserWalletComplianceAdmin`,form,
    { headers: await sharedService.getAuthHeader() }
    );
  }

  async approveNftUserWalletSuperAdmin(form: any) {
    return axios.post<any, APIResponse>(
    `${this.APIURL}/admin/approveNftUserWalletSuperAdmin`,form,
    { headers: await sharedService.getAuthHeader() }
    );
  }

  async approvedNftUserWallet(form: any) {
    return axios.post<any, APIResponse>(
    `${this.APIURL}/admin/approvedNftUserWallet`,form,
    { headers: await sharedService.getAuthHeader() }
    );
  }

  async getPendingGoldenBadge(params: {networkId: string}) {
    return axios.get<any, APIResponse>(
    `${this.APIURL}/admin/getPendingGoldenBadge`,
    { headers: await sharedService.getAuthHeader(), params}
    );
  }

  async getCompletedGoldenBadge(params: {networkId: string}) {
    return axios.get<any, APIResponse>(
    `${this.APIURL}/admin/getCompletedGoldenBadge`,
    { headers: await sharedService.getAuthHeader(), params }
    );
  }

  async approveGoldenBadge(form: any) {
    return axios.post<any, APIResponse>(
    `${this.APIURL}/admin/approveGoldenBadge`,form,
    { headers: await sharedService.getAuthHeader() }
    );
  }

  async getPendingNFT(params: {networkId: string}) {
    return axios.get<any, APIResponse>(
    `${this.APIURL}/admin/getPendingNFT`,
    { headers: await sharedService.getAuthHeader(), params }
    );
  }

  async getCompletedNFT(params: {networkId: string}) {
    return axios.get<any, APIResponse>(
    `${this.APIURL}/admin/getCompletedNFT`,
    { headers: await sharedService.getAuthHeader(), params }
    );
  }

  async getPendingEscrowByComplianceAdmin(params: {networkId: string}) {
    return axios.get<any, APIResponse>(
    `${this.APIURL}/admin/getPendingEscrowByComplianceAdmin`,
    { headers: await sharedService.getAuthHeader(), params }
    );
  }

  async getCompletedEscrowByComplianceAdmin(params: {networkId: string}) {
    return axios.get<any, APIResponse>(
    `${this.APIURL}/admin/getCompletedEscrowByComplianceAdmin`,
    { headers: await sharedService.getAuthHeader(), params }
    );
  }

  async getPendingEscrowByTokenAdmin(params: {networkId: string}) {
    return axios.get<any, APIResponse>(
    `${this.APIURL}/admin/getPendingEscrowByTokenAdmin`,
    { headers: await sharedService.getAuthHeader(), params }
    );
  }

  async getCompletedEscrowByTokenAdmin(params: {networkId: string}) {
    return axios.get<any, APIResponse>(
    `${this.APIURL}/admin/getCompletedEscrowByTokenAdmin`,
    { headers: await sharedService.getAuthHeader(), params }
    );
  }

  async getPendingEscrowBySuperAdmin(params: {networkId: string}) {
    return axios.get<any, APIResponse>(
    `${this.APIURL}/admin/getPendingEscrowBySuperAdmin`,
    { headers: await sharedService.getAuthHeader(), params }
    );
  }

  async getCompletedEscrowBySuperAdmin(params: {networkId: string}) {
    return axios.get<any, APIResponse>(
    `${this.APIURL}/admin/getCompletedEscrowBySuperAdmin`,
    { headers: await sharedService.getAuthHeader(), params }
    );
  }

  async getPlatformMarketplaceSuperAdminDetails() {
    return axios.get<any, APIResponse>(
    `${this.APIURL}/admin/getPlatformMarketplaceSuperAdminDetails`,
    { headers: await sharedService.getAuthHeader() }
    );
  }

  async updatePlatformCommission(form: any) {
    return axios.post<any, APIResponse>(
    `${this.APIURL}/admin/updatePlatformCommission`,form,
    { headers: await sharedService.getAuthHeader() }
    );
  }

  async approveUserNFT(form: any) {
    return axios.post<any, APIResponse>(
    `${this.APIURL}/admin/approveUserNFT`,form,
    { headers: await sharedService.getAuthHeader() }
    );
  }

  async blockUser(form: any) {
    return axios.post<any, APIResponse>(
    `${this.APIURL}/admin/blockUser`,form,
    { headers: await sharedService.getAuthHeader() }
    );
  }

  async getMarketplaceSuperAdminDashboard(params: { networkId: string }) {
    return axios.get<any, APIResponse>(
    `${this.APIURL}/admin/getMarketplaceSuperAdminDashboard`,
    { headers: await sharedService.getAuthHeader(), params }
    );
  }

  async getPlatformSuperAdminDashboard(params: { networkId: string }) {
    return axios.get<any, APIResponse>(
    `${this.APIURL}/admin/getPlatformSuperAdminDashboard`,
    { headers: await sharedService.getAuthHeader(), params }
    );
  }

  async getPlatformSuperAdminWallet() {
    return axios.get<any, APIResponse>(
    `${this.APIURL}/admin/getPlatformSuperAdminWallet`,
    { headers: await sharedService.getAuthHeader() }
    );
  }

  async setPlatformSuperAdminWallet(form: any) {
    return axios.post<any, APIResponse>(
    `${this.APIURL}/admin/setPlatformSuperAdminWallet`,form,
    { headers: await sharedService.getAuthHeader() }
    );
  }

  async getMarketplaceTokenAdminWallet() {
    return axios.get<any, APIResponse>(
    `${this.APIURL}/admin/getMarketplaceTokenAdminWallet`,
    { headers: await sharedService.getAuthHeader() }
    );
  }

  async setMarketplaceTokenAdminWallet(form: any) {
    return axios.post<any, APIResponse>(
    `${this.APIURL}/admin/setMarketplaceTokenAdminWallet`,form,
    { headers: await sharedService.getAuthHeader() }
    );
  }

  async getMarketplaceComplianceAdminWallet() {
    return axios.get<any, APIResponse>(
    `${this.APIURL}/admin/getMarketplaceComplianceAdminWallet`,
    { headers: await sharedService.getAuthHeader() }
    );
  }

  async setMarketplaceComplianceAdminWallet(form: any) {
    return axios.post<any, APIResponse>(
    `${this.APIURL}/admin/setMarketplaceComplianceAdminWallet`,form,
    { headers: await sharedService.getAuthHeader() }
    );
  }

  async getMarketPlaceDeployedSmartContract(params: { networkId: string }) {
    return axios.get<any, APIResponse>(
    `${this.APIURL}/admin/getMarketPlaceDeployedSmartContract`,
    { headers: await sharedService.getAuthHeader(), params }
    );
  }

  async setMarketPlaceDeployedSmartContract(form: any) {
    return axios.post<any, APIResponse>(
    `${this.APIURL}/admin/setMarketPlaceDeployedSmartContract`,form,
    { headers: await sharedService.getAuthHeader() }
    );
  }

  async getUsersDetailsBySearch(searchText: string) {
    return axios.get<any, APIResponse>(
    `${this.APIURL}/admin/getUsersDetailsBySearch?searchText=${searchText}`,
    { headers: await sharedService.getAuthHeader() }
    );
  }

  async getUsersDetailsByListingFees(params: {networkId: string}) {
    return axios.get<any, APIResponse>(
    `${this.APIURL}/admin/getUsersDetailsByListingFees`,
    { headers: await sharedService.getAuthHeader(), params }
    );
  }

  async updateUserListingFees(form: any) {
    return axios.post<any, APIResponse>(
    `${this.APIURL}/admin/updateUserListingFees`,form,
    { headers: await sharedService.getAuthHeader() }
    );
  }

  async getUsersDetailsBySellingFees(params: {networkId: string}) {
    return axios.get<any, APIResponse>(
    `${this.APIURL}/admin/getUsersDetailsBySellingFees`,
    { headers: await sharedService.getAuthHeader(), params }
    );
  }

  async updateUserSellingFees(form: any) {
    return axios.post<any, APIResponse>(
    `${this.APIURL}/admin/updateUserSellingFees`,form,
    { headers: await sharedService.getAuthHeader() }
    );
  }

  async getUsersDetailsByWhiteListingFees(params: {networkId: string}) {
    return axios.get<any, APIResponse>(
    `${this.APIURL}/admin/getUsersDetailsByWhiteListingFees`,
    { headers: await sharedService.getAuthHeader(), params }
    );
  }

  async updateUserWhiteListingFees(form: any) {
    return axios.post<any, APIResponse>(
    `${this.APIURL}/admin/updateUserWhiteListingFees`,form,
    { headers: await sharedService.getAuthHeader() }
    );
  }

  async getUsersDetailsByCollectionFees(params: { networkId: string }) {
    return axios.get<any, APIResponse>(
    `${this.APIURL}/admin/getUsersDetailsByCollectionFees`,
    { headers: await sharedService.getAuthHeader(), params }
    );
  }

  async updateUserCollectionFees(form: any) {
    return axios.post<any, APIResponse>(
    `${this.APIURL}/admin/updateUserCollectionFees`,form,
    { headers: await sharedService.getAuthHeader() }
    );
  }

  async getUsersDetailsByMintingFees(params: { networkId: string }) {
    return axios.get<any, APIResponse>(
    `${this.APIURL}/admin/getUsersDetailsByMintingFees`,
    { headers: await sharedService.getAuthHeader(), params }
    );
  }

  async updateUserMintingFees(form: any) {
    return axios.post<any, APIResponse>(
    `${this.APIURL}/admin/updateUserMintingFees`,form,
    { headers: await sharedService.getAuthHeader() }
    );
  }

  async getMintingBlockchainTransaction(params: { networkId: string }) {
    return axios.get<any, APIResponse>(
    `${this.APIURL}/admin/getMintingBlockchainTransaction`,
    { headers: await sharedService.getAuthHeader(), params }
    );
  }

  async updateMarketplaceContractAddress(form: any) {
    return axios.post<any, APIResponse>(
    `${this.APIURL}/admin/updateMarketplaceContractAddress`,form,
    { headers: await sharedService.getAuthHeader() }
    );
  }

  async getUsersDetailsById(userId: string) {
    return axios.get<any, APIResponse>(
    `${this.APIURL}/admin/getUsersDetailsById?userId=${userId}`,
    { headers: await sharedService.getAuthHeader() }
    );
  }

  async sendReminderKYCEmail(email: string) {
    return axios.get<any, APIResponse>(
    `${this.APIURL}/admin/sendReminderKYCEmail?email=${email}`,
    { headers: await sharedService.getAuthHeader() }
    );
  }

}