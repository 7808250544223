import Web3 from 'web3';
import { environment } from "../../../../environments/environment";

export class NftTrader {
    private ethereum = window['ethereum'];
    private web3 = new Web3(this.ethereum);
    private contract = {
        ABI: require('./NftTrader.json')
    };
    
    async ListNft(tradeContractAddress: string, nftAddress: string, tokenId: string, nftBuyPrice: string, NftUser: string, NFTListingFee: string) {    
        const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tradeContractAddress); 
        // return contractInstance.methods.listItem(nftAddress, tokenId, this.web3.utils.toWei(nftBuyPrice, "ether")).send({ from: NftUser, value: this.web3.utils.toWei(NFTListingFee, "ether") });

        const transactionParameters = {
            from: NftUser,
            value: this.web3.utils.toWei(NFTListingFee, "ether")
        };

        const transactionObject = contractInstance.methods.listItem(
            nftAddress, tokenId, this.web3.utils.toWei(nftBuyPrice, "ether")
        );
        let receipts;
        let hashs;

        try{
        const transactionHash = await transactionObject.send(transactionParameters)
        .on('transactionHash', (hash: string) => {
            // Here you get the transaction hash immediately
            console.log('Transaction Hash:', hash);
            hashs = hash;
        })
        .on('confirmation', (confirmationNumber: number, receipt: any) => {
            // This is called when the transaction is confirmed
            console.log('Transaction Confirmed:', confirmationNumber);
            receipts = receipt;
        })
        .on('error', (error: any) => {
            // Handle errors
            console.error('Transaction Error:', error);
            receipts = false;
        });
        console.log("after confirmation: ", receipts);

        return receipts;

        }catch(e){
            return {hash: hashs, pending: true};
        }

    }

    async BuyNftOnFixedPrice(tradeContractAddress: string, nftAddress: string, tokenId: string, nftBuyPrice: string, NftUser: string) {    
        const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tradeContractAddress); 
        // return contractInstance.methods.buyItem(nftAddress, tokenId).send({ from: NftUser, value: this.web3.utils.toWei(nftBuyPrice, "ether") });
        const transactionParameters = {
            from: NftUser,
            value: this.web3.utils.toWei(nftBuyPrice, "ether")
        };

        const transactionObject = contractInstance.methods.buyItem(
            nftAddress, tokenId
        );
        let receipts;
        let hashs;

        try{
        const transactionHash = await transactionObject.send(transactionParameters)
        .on('transactionHash', (hash: string) => {
            // Here you get the transaction hash immediately
            console.log('Transaction Hash:', hash);
            hashs = hash;
        })
        .on('confirmation', (confirmationNumber: number, receipt: any) => {
            // This is called when the transaction is confirmed
            console.log('Transaction Confirmed:', confirmationNumber);
            receipts = receipt;
        })
        .on('error', (error: any) => {
            // Handle errors
            console.error('Transaction Error:', error);
            receipts = false;
        });
        console.log("after confirmation: ", receipts);

        return receipts;

        }catch(e){
            return {hash: hashs, pending: true};
        }
    }
    
    // Auction Web3 integration
    async ListNftOnAuction(tradeContractAddress: string, nftAddress: string, tokenId: string, startingBid: string, endingTime: string, NftUser: string, NFTListingFee: string, BidPercentage: string) {
        console.log('ListNftOnAuction:', tradeContractAddress, nftAddress, tokenId, startingBid, endingTime, NftUser, NFTListingFee, BidPercentage)
        const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tradeContractAddress); 
        // return contractInstance.methods.ListNftOnAuction(nftAddress, tokenId, this.web3.utils.toWei(startingBid, "ether"), endingTime, BidPercentage).send({ from: NftUser, value: this.web3.utils.toWei(NFTListingFee, "ether") });
        const transactionParameters = {
            from: NftUser,
            value: this.web3.utils.toWei(NFTListingFee, "ether")
        };

        const transactionObject = contractInstance.methods.ListNftOnAuction(
            nftAddress, tokenId, this.web3.utils.toWei(startingBid, "ether"), endingTime, BidPercentage
        );
        let receipts;
        let hashs;

        try{
        const transactionHash = await transactionObject.send(transactionParameters)
        .on('transactionHash', (hash: string) => {
            // Here you get the transaction hash immediately
            console.log('Transaction Hash:', hash);
            hashs = hash;
        })
        .on('confirmation', (confirmationNumber: number, receipt: any) => {
            // This is called when the transaction is confirmed
            console.log('Transaction Confirmed:', confirmationNumber);
            receipts = receipt;
        })
        .on('error', (error: any) => {
            // Handle errors
            console.error('Transaction Error:', error);
            receipts = false;
        });
        console.log("after confirmation: ", receipts);

        return receipts;

        }catch(e){
            return {hash: hashs, pending: true};
        }
    }

    async ListAgainOnAuction(tradeContractAddress: string, nftAddress: string, tokenId: string, startingBid: string, endingTime: string, NftUser: string, BidPercentage: string) {
        console.log(`ListAgainOnAuction: tradeContractAddress: ${tradeContractAddress}, nftAddress: ${nftAddress}, tokenId: ${tokenId}, startingBid: ${startingBid}, endingTime: ${endingTime}, NftUser: ${NftUser}, BidPercentage: ${BidPercentage}`)
        const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tradeContractAddress); 
        console.log('contractInstance: ', contractInstance);
        // return contractInstance.methods.ListAgainOnAuction(nftAddress, tokenId, this.web3.utils.toWei(startingBid, "ether"), endingTime, BidPercentage).send({ from: NftUser });
        const transactionParameters = {
            from: NftUser
        };

        const transactionObject = contractInstance.methods.ListAgainOnAuction(
            nftAddress, tokenId, this.web3.utils.toWei(startingBid, "ether"), endingTime, BidPercentage
        );
        let receipts;
        let hashs;

        try{
        const transactionHash = await transactionObject.send(transactionParameters)
        .on('transactionHash', (hash: string) => {
            // Here you get the transaction hash immediately
            console.log('Transaction Hash:', hash);
            hashs = hash;
        })
        .on('confirmation', (confirmationNumber: number, receipt: any) => {
            // This is called when the transaction is confirmed
            console.log('Transaction Confirmed:', confirmationNumber);
            receipts = receipt;
        })
        .on('error', (error: any) => {
            // Handle errors
            console.error('Transaction Error:', error);
            receipts = false;
        });
        console.log("after confirmation: ", receipts);

        return receipts;

        }catch(e){
            return {hash: hashs, pending: true};
        }
    }

    async bidOnAuction(tradeContractAddress: string, nftAddress: string, tokenId: string, bidPrice: string, NftUser: string) {  
        const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tradeContractAddress);
        // this calculation is required because blockchain doesnot support decimal points
        const amount = (Number(this.web3.utils.toWei(bidPrice, "ether")) * environment.bidPercentage) / 100;
        const Amount = Math.floor(amount);
        // return contractInstance.methods.bidOnAuction(nftAddress, tokenId, this.web3.utils.toWei(bidPrice, "ether")).send({ from: NftUser, value: String(Amount)});
        const transactionParameters = {
            from: NftUser,
            value: String(Amount)
        };

        const transactionObject = contractInstance.methods.bidOnAuction(
            nftAddress, tokenId, this.web3.utils.toWei(bidPrice, "ether")
        );
        let receipts;
        let hashs;

        try{
        const transactionHash = await transactionObject.send(transactionParameters)
        .on('transactionHash', (hash: string) => {
            // Here you get the transaction hash immediately
            console.log('Transaction Hash:', hash);
            hashs = hash;
        })
        .on('confirmation', (confirmationNumber: number, receipt: any) => {
            // This is called when the transaction is confirmed
            console.log('Transaction Confirmed:', confirmationNumber);
            receipts = receipt;
        })
        .on('error', (error: any) => {
            // Handle errors
            console.error('Transaction Error:', error);
            receipts = false;
        });
        console.log("after confirmation: ", receipts);

        return receipts;

        }catch(e){
            return {hash: hashs, pending: true};
        }
    }

    async endAuction(tradeContractAddress: string, nftAddress: string, NftUser: string, highestBiddingPrice: string, tokenId: string) {
        const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tradeContractAddress); 
        // this calculation is required because blockchain doesnot support decimal points
        const amount = (Number(this.web3.utils.toWei(highestBiddingPrice, "ether")) * environment.bidPercentage) / 100;
        const Amount = Math.floor(amount)
        const finalAmount = Number(this.web3.utils.toWei(highestBiddingPrice, "ether")) - Amount;
        const FinalAmount = Math.floor(finalAmount)
        // return contractInstance.methods.endAuction(nftAddress, tokenId).send({ from: NftUser, value: String(FinalAmount) });
        const transactionParameters = {
            from: NftUser,
            value: String(FinalAmount)
        };

        const transactionObject = contractInstance.methods.endAuction(
            nftAddress, tokenId
        );
        let receipts;
        let hashs;

        try{
        const transactionHash = await transactionObject.send(transactionParameters)
        .on('transactionHash', (hash: string) => {
            // Here you get the transaction hash immediately
            console.log('Transaction Hash:', hash);
            hashs = hash;
        })
        .on('confirmation', (confirmationNumber: number, receipt: any) => {
            // This is called when the transaction is confirmed
            console.log('Transaction Confirmed:', confirmationNumber);
            receipts = receipt;
        })
        .on('error', (error: any) => {
            // Handle errors
            console.error('Transaction Error:', error);
            receipts = false;
        });
        console.log("after confirmation: ", receipts);

        return receipts;

        }catch(e){
            return {hash: hashs, pending: true};
        }
    }
    
    // OpenForBids Web3 integration
    async ListNftOnOpenForBids(tradeContractAddress: string, nftAddress: string, tokenId: string, NftUser: string, NFTListingFee: string, BidPercentage: string) {
        const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tradeContractAddress); 
        // return contractInstance.methods.ListNftOnOpenForBids(nftAddress, tokenId, BidPercentage).send({ from: NftUser, value: this.web3.utils.toWei(NFTListingFee, "ether") });
        const transactionParameters = {
            from: NftUser,
            value: this.web3.utils.toWei(NFTListingFee, "ether")
        };

        const transactionObject = contractInstance.methods.ListNftOnOpenForBids(
            nftAddress, tokenId, BidPercentage
        );
        let receipts;
        let hashs;

        try{
        const transactionHash = await transactionObject.send(transactionParameters)
        .on('transactionHash', (hash: string) => {
            // Here you get the transaction hash immediately
            console.log('Transaction Hash:', hash);
            hashs = hash;
        })
        .on('confirmation', (confirmationNumber: number, receipt: any) => {
            // This is called when the transaction is confirmed
            console.log('Transaction Confirmed:', confirmationNumber);
            receipts = receipt;
        })
        .on('error', (error: any) => {
            // Handle errors
            console.error('Transaction Error:', error);
            receipts = false;
        });
        console.log("after confirmation: ", receipts);

        return receipts;

        }catch(e){
            return {hash: hashs, pending: true};
        }
    }

    async bidOnOpenForBids(tradeContractAddress: string, nftAddress: string, tokenId: string, bidPrice: string, NftUser: string) {   
        const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tradeContractAddress); 
        // this calculation is required because blockchain doesnot support decimal points
        const amount = (Number(this.web3.utils.toWei(bidPrice, "ether")) * environment.bidPercentage) / 100;
        const Amount = Math.floor(amount)
        // return contractInstance.methods.bidOnOpenForBids(nftAddress, tokenId, this.web3.utils.toWei(bidPrice, "ether")).send({ from: NftUser, value: String(Amount)});
        const transactionParameters = {
            from: NftUser,
            value: String(Amount)
        };

        const transactionObject = contractInstance.methods.bidOnOpenForBids(
            nftAddress, tokenId, this.web3.utils.toWei(bidPrice, "ether")
        );
        let receipts;
        let hashs;

        try{
        const transactionHash = await transactionObject.send(transactionParameters)
        .on('transactionHash', (hash: string) => {
            // Here you get the transaction hash immediately
            console.log('Transaction Hash:', hash);
            hashs = hash;
        })
        .on('confirmation', (confirmationNumber: number, receipt: any) => {
            // This is called when the transaction is confirmed
            console.log('Transaction Confirmed:', confirmationNumber);
            receipts = receipt;
        })
        .on('error', (error: any) => {
            // Handle errors
            console.error('Transaction Error:', error);
            receipts = false;
        });
        console.log("after confirmation: ", receipts);

        return receipts;

        }catch(e){
            return {hash: hashs, pending: true};
        }
    }

    async sellToBidder(tradeContractAddress: string, nftAddress: string, tokenId: string, bidderAddress: string, biddingPrice: string) {
        console.log(`sellToBidder=> tradeContractAddress: ${tradeContractAddress}, nftAddress: ${nftAddress}, tokenId:${tokenId}, bidderAddress: ${bidderAddress}, biddingPrice: ${biddingPrice}`);
        const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tradeContractAddress); 
        // this calculation is required because blockchain doesnot support decimal points
        const amount = (Number(this.web3.utils.toWei(biddingPrice, "ether")) * environment.bidPercentage) / 100;
        const Amount = Math.floor(amount);
        const finalAmount = Number(this.web3.utils.toWei(biddingPrice, "ether")) - Amount;
        const FinalAmount = Math.floor(finalAmount);
        // return contractInstance.methods.sellToBidder(nftAddress, bidderAddress, tokenId).send({ from: bidderAddress, value: String(FinalAmount)});
        const transactionParameters = {
            from: bidderAddress,
            value: String(FinalAmount)
        };

        const transactionObject = contractInstance.methods.sellToBidder(
            nftAddress, bidderAddress, tokenId
        );
        let receipts;
        let hashs;

        try{
        const transactionHash = await transactionObject.send(transactionParameters)
        .on('transactionHash', (hash: string) => {
            // Here you get the transaction hash immediately
            console.log('Transaction Hash:', hash);
            hashs = hash;
        })
        .on('confirmation', (confirmationNumber: number, receipt: any) => {
            // This is called when the transaction is confirmed
            console.log('Transaction Confirmed:', confirmationNumber);
            receipts = receipt;
        })
        .on('error', (error: any) => {
            // Handle errors
            console.error('Transaction Error:', error);
            receipts = false;
        });
        console.log("after confirmation: ", receipts);

        return receipts;

        }catch(e){
            return {hash: hashs, pending: true};
        }
    }
    
    //ReListing :- Approve an NFT by Buyer To List it again in the marketplace
    async ReListNft(tradeContractAddress: string, nftAddress: string, tokenId: string, nftBuyPrice: string, NftUser: string, NFTListingFee: string) {    
        const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tradeContractAddress); 
        // return contractInstance.methods.relistItem(nftAddress, tokenId, this.web3.utils.toWei(nftBuyPrice, "ether")).send({ from: NftUser, value: this.web3.utils.toWei(NFTListingFee, "ether") });
        const transactionParameters = {
            from: NftUser,
            value: this.web3.utils.toWei(NFTListingFee, "ether")
        };

        const transactionObject = contractInstance.methods.relistItem(
        nftAddress, tokenId, this.web3.utils.toWei(nftBuyPrice, "ether")
        );
        let receipts;
        let hashs;

        try{
        const transactionHash = await transactionObject.send(transactionParameters)
        .on('transactionHash', (hash: string) => {
            // Here you get the transaction hash immediately
            console.log('Transaction Hash:', hash);
            hashs = hash;
        })
        .on('confirmation', (confirmationNumber: number, receipt: any) => {
            // This is called when the transaction is confirmed
            console.log('Transaction Confirmed:', confirmationNumber);
            receipts = receipt;
        })
        .on('error', (error: any) => {
            // Handle errors
            console.error('Transaction Error:', error);
            receipts = false;
        });
        console.log("after confirmation: ", receipts);

        return receipts;

        }catch(e){
            return {hash: hashs, pending: true};
        }
    }

    //ReListing :- Approve an NFT by Buyer To List it again for Auction in the marketplace
    async ReListNftOnAuction(tradeContractAddress: string, nftAddress: string, tokenId: string, startingBid: string, endingTime: string, NftUser: string, NFTListingFee: string, BidPercentage: string) {
        const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tradeContractAddress); 
        // return contractInstance.methods.ReListNftOnAuction(nftAddress, tokenId, this.web3.utils.toWei(startingBid, "ether"), endingTime,BidPercentage).send({ from: NftUser, value: this.web3.utils.toWei(NFTListingFee, "ether") });
        const transactionParameters = {
            from: NftUser,
            value: this.web3.utils.toWei(NFTListingFee, "ether")
        };

        const transactionObject = contractInstance.methods.ReListNftOnAuction(
            nftAddress, tokenId, this.web3.utils.toWei(startingBid, "ether"), endingTime,BidPercentage
        );
        let receipts;
        let hashs;

        try{
        const transactionHash = await transactionObject.send(transactionParameters)
        .on('transactionHash', (hash: string) => {
            // Here you get the transaction hash immediately
            console.log('Transaction Hash:', hash);
            hashs = hash;
        })
        .on('confirmation', (confirmationNumber: number, receipt: any) => {
            // This is called when the transaction is confirmed
            console.log('Transaction Confirmed:', confirmationNumber);
            receipts = receipt;
        })
        .on('error', (error: any) => {
            // Handle errors
            console.error('Transaction Error:', error);
            receipts = false;
        });
        console.log("after confirmation: ", receipts);

        return receipts;

        }catch(e){
            return {hash: hashs, pending: true};
        }
    }

    //ReListing :- Approve an NFT by Buyer To List it again for OpeForBids in the marketplace
    async ReListNftOnOpenForBids(tradeContractAddress: string, nftAddress: string, tokenId: string, NftUser: string, NFTListingFee: string, BidPercentage:string) {
        const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tradeContractAddress); 
        // return contractInstance.methods.ReListNftOnOpenForBids(nftAddress, tokenId, BidPercentage).send({ from: NftUser, value: this.web3.utils.toWei(NFTListingFee, "ether") });
        const transactionParameters = {
            from: NftUser,
            value: this.web3.utils.toWei(NFTListingFee, "ether")
        };

        const transactionObject = contractInstance.methods.ReListNftOnOpenForBids(
            nftAddress, tokenId, BidPercentage
        );
        let receipts;
        let hashs;

        try{
        const transactionHash = await transactionObject.send(transactionParameters)
        .on('transactionHash', (hash: string) => {
            // Here you get the transaction hash immediately
            console.log('Transaction Hash:', hash);
            hashs = hash;
        })
        .on('confirmation', (confirmationNumber: number, receipt: any) => {
            // This is called when the transaction is confirmed
            console.log('Transaction Confirmed:', confirmationNumber);
            receipts = receipt;
        })
        .on('error', (error: any) => {
            // Handle errors
            console.error('Transaction Error:', error);
            receipts = false;
        });
        console.log("after confirmation: ", receipts);

        return receipts;

        }catch(e){
            return {hash: hashs, pending: true};
        }
    }

    // getting Escrow address from the blockchain
    async escrowContractAddress(NftMintingContract: string, nftTokenId:string, NftUser: string, tradeContractAddress: string) {
        const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tradeContractAddress); 
        return contractInstance.methods.nftEscrowContracts(NftMintingContract, nftTokenId).call({ from: NftUser });
    }    
}