/* eslint-disable react-hooks/rules-of-hooks */
import { ConsoleSqlOutlined } from '@ant-design/icons';
import React, { useState, useEffect, useContext } from 'react'
import Web3 from 'web3';
// import { User } from './interfaces';
// import { AuthService } from './Auth.service';
import { SharedService } from './Shared.service';




// const authService = new AuthService();
// const useUserContext = () => new AuthService().useUserContext();


export class MetamaskService {
  // const [web3, setWeb3] = useState<any>();
  // const [ethereum, setEthereum] = useState<any>();
  // const [isMetamaskAuthenticated, setIsMetamaskAuthenticated] = useState<boolean>();

  static web3: Web3;
  static ethereum;
  public static isMetamaskAuthenticated: boolean;

  public get isMetamaskInstalled() {
    // return typeof window['ethereum'] !== 'undefined';
    const { ethereum } = window as any;
    return Boolean(ethereum && ethereum.isMetaMask);
  }

  async selectChain(chainId: string) {
    const sharedService = new SharedService();

    try {
      await window['ethereum'].request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: Web3.utils.toHex(chainId) }],
      });

      window.location.reload();

    } catch (switchError) {
      // This error code indicates that the chain has not been added to MetaMask. wallet_addEthereumChain adds a new chain in MetaMask.
      if (switchError.code === 4902) {
        try {
          await window['ethereum'].request({
            method: 'wallet_addEthereumChain',
            params: [sharedService.ethereumNetworkDetails[chainId]],
          });

          window.location.reload();

        } catch (addError) {
          console.error('selectChain wallet_addEthereumChain:', JSON.stringify(addError));
        }

      } else {
        console.error('selectChain wallet_switchEthereumChain:', JSON.stringify(switchError));
      }
    }
  }

  useSelectedWallet(userId: string) {
    const [selectedWallet, setSelectedWallet] = useState<string>();
    const [networkId, setNetworkId] = useState<string>();
    const [isMetamaskAuthenticated, setIsMetamaskAuthenticated] = useState<boolean>();
    const [walletBalance, setWalletBalance] = useState<string>();
    
    
    useEffect(() => {
      (async () => {
        try {
          if(!userId) return;

          if (!this.isMetamaskInstalled)  return setIsMetamaskAuthenticated(false);

          if(MetamaskService.isMetamaskAuthenticated) return; //METAMASK ALREADY INITIALIZED

          MetamaskService.ethereum = window['ethereum'];
          
          MetamaskService.web3 = new Web3(MetamaskService.ethereum);
         
           await MetamaskService.ethereum.request({ method: 'eth_requestAccounts' });
          const wallets = await MetamaskService.ethereum.request({ method: 'eth_accounts' });

          MetamaskService.isMetamaskAuthenticated = true;
          
          setSelectedWallet(wallets[0]);
          
          MetamaskService.ethereum.on('accountsChanged', (accounts: string[]) => setSelectedWallet(accounts[0]));

          if(MetamaskService.ethereum.hasOwnProperty("autoRefreshOnNetworkChange")) MetamaskService.ethereum.autoRefreshOnNetworkChange = false; //COMMENT IN FUTURE

          setNetworkId(MetamaskService.ethereum.networkVersion);

          MetamaskService.ethereum.on('networkChanged', (networkId: string) => setNetworkId(networkId));

          // Get wallet balance
          if(MetamaskService.web3.eth) {
            //web3.eth.getBalance(walletAddress).then((data)=>console.log(data));
            MetamaskService.web3.eth.getBalance(wallets[0], (err, balance) => { 
              if(balance?.toString()?.length > 0) {
                setWalletBalance(MetamaskService.web3.utils.fromWei(String(balance), "ether"));
              }
            });
          }

        } catch (err) {
          MetamaskService.isMetamaskAuthenticated = false;

          console.error(err);
          if (err.message === 'User rejected provider access' || err.message === 'User denied account authorization') {
            console.log("The user didn't want to sign in!");
          } else {
            // This shouldn't happen, so you might want to log this...
            console.log('There was an issue signing you in.');
          }
        }

        setIsMetamaskAuthenticated(MetamaskService.isMetamaskAuthenticated);

        const sharedService = new SharedService();

        if(!this.isMetamaskInstalled || !MetamaskService.isMetamaskAuthenticated) setNetworkId(sharedService.allowedNetworks[0])

      })();
    }, [userId]);


    useEffect(() => {
      (async() => {
        setTimeout(()=> {
          const sharedService = new SharedService();
    
          if(!this.isMetamaskInstalled) setNetworkId(sharedService.allowedNetworks[0])
        }, 300)
  
      })();
    }, [isMetamaskAuthenticated]);

  
  
    return [selectedWallet, networkId, isMetamaskAuthenticated, setSelectedWallet as any, setNetworkId, setIsMetamaskAuthenticated, walletBalance];
  }


  static SelectedWalletContext = React.createContext
  <{
    selectedWallet: string | undefined, 
    setSelectedWallet: (value: string) => void,
    networkId: string | undefined,
    setNetworkId?: (value: string) => void,
    isMetamaskAuthenticated?: boolean | undefined,
  }>
  ({
    selectedWallet: undefined, 
    setSelectedWallet: () => {},
    networkId: undefined,
    isMetamaskAuthenticated: undefined,
    setNetworkId: () => {},
  });

  
  useSelectedWalletContext() {
    return useContext(MetamaskService.SelectedWalletContext);
  }

}