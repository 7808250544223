
import React, { memo, useState, useEffect } from "react";
import { message } from "antd";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { carouselNew } from "../../components/constants";
import { SharedService } from "../../components/Shared/Shared.service";
import { NFTCreatorService } from "../../components/services/NFTCreator/NFTCreator.service";
import { useHistory } from "react-router-dom";
import Spinner from "../../components/spinner/Spinner";
import NFTCard from "../../components/NFTCardComponent";
import { MetamaskService } from "../../components/Shared/Metamask.service";
import Clock from "../../components/Clock";

const sharedService = new SharedService();
const nftService = new NFTCreatorService();


const useSelectedWalletContext = () =>
  new MetamaskService().useSelectedWalletContext();

const HomePageSoldNftCarousel = ({ todayEtheriumPriceInUSD }) => {


  const { networkId } = useSelectedWalletContext();

  const [loading, setLoading] = useState(false);
  const [soldNFT, setSoldNFT] = useState([]);
  const history = useHistory();

  // useEffect(() => {
  //     dispatch(fetchNftsBreakdown());
  // }, [dispatch]);

  useEffect(() => {
    (async () => {
      if (!networkId) return;

      setLoading(true);
      try {

        let res1 = await nftService.getSoldNFT({ networkId });
        if (res1.data?.length > 0) {
          let soldnft = res1.data.filter((nft) => {
            //below is disabled tp show all available nft
            //if(nft.expirationDate) return new Date(nft.expirationDate) <= new Date()
            //else return true;
            return true;
          });

          setSoldNFT(soldnft);
        }

        setLoading(false);
      } catch (e) {
        message.error("Something went wrong, try again.");
        setLoading(false);
      }
    })();
  }, [networkId]);

  return (
    <>
      {loading && <Spinner />}
      {!loading && (
        <>
          <section className="home-page browse-by-category third_section">
            <div className="home-page browse-by-category container">
              <div className="row">
                <div className="col-lg-12 text-center mt-4">
                  <h2 className="home-page browse-by-category-text">Recent Sold NFT</h2>
                  <p className="home-page browse-by-category-sub-text">The NFTs provided cover digital art, collectibles, GameFi, the metaverse, and more.</p>
                </div>
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-12 view-all-nft-div">
                      <button
                        type="button"
                        className="btn_view_all float-end home-page view-all-nft-btn"
                        onClick={() => history.push('/sold-nft')}
                      >
                        VIEW ALL
                      </button>
                    </div>
                    <div className="col-12">
                      <div className="home-page slider-container">
                        <Slider {...carouselNew}>
                          {soldNFT.length > 0 &&
                            soldNFT.map((nft, index) => (
                              <div className="itm col-xl-3 col-lg-4 col-md-6 col-sm-12" key={index}>
                                <div className="d-item">
                                  <div
                                    className={`nft__item nft-item-buy`}
                                    style={{
                                      margin: "3px",
                                      padding: "3px",
                                      border: "none",
                                      backgroundColor: "transparent",
                                    }}
                                  >
                                    {nft.expirationDate &&
                                      nft.expirationTimestamp && (
                                        <div
                                          className="de_countdown"
                                          style={{ color: "white" }}
                                        >
                                          <Clock
                                            deadline={nft.expirationTimestamp}
                                          />
                                        </div>
                                      )}
                                    {/* <div className="author_list_pp">
                                  <span>
                                    <img
                                      className="lazy"
                                      src={
                                        nft.profilePicUrl
                                          ? nft.profilePicUrl
                                          : ProfilePic
                                      }
                                      alt=""
                                    />
                                    <i className="fa fa-check"></i>
                                  </span>
                                </div> */}
                                    <NFTCard
                                      nft={nft}
                                      todayEtheriumPriceInUSD={
                                        todayEtheriumPriceInUSD
                                      }
                                      buy={true}
                                    />
                                  </div>
                                </div>
                              </div>
                            ))}
                        </Slider>
                      </div>
                    </div>

                  </div>
                </div>
                <br />


              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default memo(HomePageSoldNftCarousel);
