import { Button, Card, Form, notification } from 'antd';
import React, { useContext, useEffect } from 'react'
import { AdminService } from '../../../../components/services/AdminService/Admin.service';
import authContext from '../../../../components/Shared/Auth.service';
import { MetamaskService } from '../../../../components/Shared/Metamask.service';
import { PlatformService } from '../../../../components/Shared/Blockchain/Platform/Platform.service';
import { environment } from '../../../../environments/environment';

const adminService = new AdminService();
const useSelectedWalletContext = () =>
  new MetamaskService().useSelectedWalletContext();
const platformService = new PlatformService();

function DeploySmartContract(props) {
  const { setLoading, smartContractData, loading, setSmartContractData, MSAProfileApproved } = props || {};
  const { userInfo } = useContext(authContext);
  const { networkId } = useSelectedWalletContext();
  const [form] = Form.useForm();

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };
  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 16,
        offset: 8,
      },
    },
  };

  useEffect(() => {
    fetchData(userInfo, networkId)
  }, [userInfo, networkId])

  const fetchData = async () => {
    // getting smartcontract deployed
    setLoading(true);
    try {
      let res = await adminService.getMarketPlaceDeployedSmartContract({ networkId });
      if (res?.data) setSmartContractData(res.data);
    } catch (error) {
      console.log(error);
      notification.open({
        message: "Error",
        description: "something went wrong while fetching getMarketPlaceDeployedSmartContract data",
        duration: 0,
      });
    }
    setLoading(false);
  };

  const updateMarketPlaceAdminSmartContractData = async () => {
    setLoading(true);
    try {
      const txnReceipt = await platformService.createNewMarketPlace(
        String(userInfo?.walletAddress),
        String(userInfo?.marketplaceName),
        environment.blockchains[networkId].Platform_Beacon_Proxy_Address
        // platfromSuperAdminMarketPlaceConfig?.Platform_contract_Address
      );

      console.log("Blockchain Transaction receipt: ", txnReceipt);
      if (txnReceipt) {
        let form = {
          MarketPlaceName: userInfo?.marketplaceName,
          MPsuperAdminId: userInfo?._id,
          deployed: true,
          adminRolesContract:
            txnReceipt?.events?.marketPlaceCreated?.returnValues
              ?.adminRolesContract,
          commissionsContract:
            txnReceipt?.events?.marketPlaceCreated?.returnValues
              ?.commissionContract,
          masterWhiteListingContract:
            txnReceipt?.events?.marketPlaceCreated?.returnValues
              ?.whiteListingContract,
          nftFactory:
            txnReceipt?.events?.marketPlaceCreated?.returnValues
              ?.nftFactoryContract,
          transactionDetail: txnReceipt,
          networkId
        };
        let res = await adminService.setMarketPlaceDeployedSmartContract(form);
        if (res.data) {
          notification.open({
            message: "Successfully deployed the MarketPlace SmartContract",
            duration: 0,
          });
          setSmartContractData(form);
          setLoading(false);
        }
      }
    } catch (error) {
      setLoading(false);
      notification.open({
        message: "Error",
        description: error.message,
        duration: 0,
      });
    }
  };

  return (
    !loading && <>
      <p className='market-super-admin commission-configuration-text'>Deploy Smart Contract</p>
      <div className='market-super-admin admin-invites card-container d-flex justify-content-center flex-column'>
        <div className='market-super-admin card-inner-container justify-content-center align-items-center' style={{ width: '100%', padding: '50px', height: 'calc(100vh - 250px)' }}>
          {MSAProfileApproved ? (
            smartContractData.deployed !== true ? (
              <Form
                {...formItemLayout}
                form={form}
                onFinish={updateMarketPlaceAdminSmartContractData}
              >
                <Form.Item {...tailFormItemLayout}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                  >
                    Deploy Smart Contract
                  </Button>
                </Form.Item>
              </Form>
            ) : (
              <h4 style={{ textAlign: "center" }} className='deploy-smart-contract'>
                You have deployed the Smart Contract
                Successfully!
              </h4>
            )) : (
            <div>
              <h4 style={{ textAlign: "center" }}>
                Your Profile is not verified yet by Platform Super Admin
              </h4>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default DeploySmartContract
