import { Button, Form, Input, notification } from "antd"
import { useContext, useEffect, useState } from "react";
import '../pages/styles/profileDetails.scss';
import { GlobalOutlined, LinkedinFilled, TwitterOutlined } from "@ant-design/icons";
import authContext from "../components/Shared/Auth.service";
import Spinner from "../components/spinner/Spinner";
import { MetamaskService } from "../components/Shared/Metamask.service";
import { NFTCreatorService } from "../components/services/NFTCreator/NFTCreator.service";
import GoldenBadge from "./../assets/images/profile/Golden Badge.png";
import Verified from "./../assets/images/profile/verified.png";
import { SharedService } from "../components/Shared/Shared.service";
import ProfilePic from "./../assets/images/home/profile-default.png";
import Edit from "./../assets/images/profile/edit.png";
import Banner1 from "./../assets/images/profile/banner1.png";
import Banner2 from "./../assets/images/profile/banner2.png";
import Banner3 from "./../assets/images/profile/banner3.png";
import Banner4 from "./../assets/images/profile/banner4.png";
import Copy from "./../assets/images/profile/copy.png";
import BannerChangeIcon from "./../assets/images/profile/banner_change.png";
import "../pages/Profile/index.css";
import NFTCard from "../components/NFTCardComponent";
import { useHistory } from "react-router-dom";

const useSelectedWalletContext = () =>
  new MetamaskService().useSelectedWalletContext();
const nftService = new NFTCreatorService();
const sharedService = new SharedService();

export const ProfileDetails = () => {
  const { userInfo } = useContext(authContext);
  const [form] = Form.useForm();
  const { networkId } = useSelectedWalletContext();
  const history = useHistory();

  const images = [Banner1, Banner2, Banner3, Banner4];

  const [selectedImage, setSelectedImage] = useState(images[0]);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isBioEdit, setIsBioEdit] = useState(false);
  const [isLinkEdit, setIsLinkEdit] = useState(false);
  const [submittable, setSubmittable] = useState(false);
  const [linkedinGoldenBadge, setLinkedinGoldenBadge] = useState(20);
  const [twitterGoldenBadge, setTwitterGoldenBadge] = useState(20);

  const socialMediaProfileValues = Form.useWatch('socialMediaProfile', form);

  const isProfileEditionDisabled = Object
    .values(userInfo?.socialMediaProfile?.verificationStatus || {})
    .some(status => status === "pending" || status === "approved");


  useEffect(() => {
    // Check form validity after a certain action or event
    const checkFormValidity = async () => {
      try {
        // Validate all fields
        await form.validateFields();
        setSubmittable(true);
        // Proceed with further logic for a valid form
      } catch (errorInfo) {
        setSubmittable(false);
        // Handle the validation error information if needed
      }
    };

    // Example: Check form validity on component mount
    checkFormValidity();

    // Clean up if needed
    return () => {
      // Perform cleanup here
    };
  }, [form, socialMediaProfileValues]);

  useEffect(() => {
    // Set the initial values for the form fields
    if (userInfo && networkId) {
      setLoading(false);
      form.setFieldsValue(userInfo);
      form.setFieldsValue({ name: `${userInfo.firstName}  ${userInfo.lastName}` });
    }
  }, [form, userInfo, networkId]);

  useEffect(async () => {
    setLoading(true);
    if (!networkId) return;

    setTimeout(async () => {
      if (userInfo?._id) {
        let res = await nftService.getMinimumNoOfFollowersForGoldenBadge(userInfo?._id);
        if (res.data) {
          setLinkedinGoldenBadge(res.data.linkedinGoldenBadge);
          setTwitterGoldenBadge(res.data.twitterGoldenBadge);
        }
        setTimeout(() => setLoading(false), 400);
      } else {
        return history.push("/account/login");
      }
    }, 400);
  }, [userInfo, networkId]);

  const uploadFile = async (e) => {
    if (!e.target.files) return;

    const file = e.target.files[0];
    if (!file) return;

    if (file.size > 15 * 1024 * 1024) {
      //15MB for editing max size as per library
      return false;
    }

    setLoading(true);
    try {
      // uploading to firebase
      nftService.uploadFile(
        file,
        (uploadFilePercent) => { },
        (err) => {
          console.error(err);
          notification.open({
            message: "Error",
            description: "something went wrong while updating profile image",
            duration: 0,
          });
        },
        async (response) => {
          if (response.success) {
            const URL = response.data.url;
            await sharedService.updateProfilePic({
              userId: userInfo?._id,
              profilePicUrl: URL,
            });
            userInfo.profilePic = URL;
            notification.open({
              message: "Successfully uploaded the profile pic",
              duration: 0,
            });
            setLoading(false);
            setTimeout(() => window.location.reload(), 1000);
          }
        }
      );
    } catch (err) {
      console.error(err);
      notification.open({
        message: "Error",
        description: "something went wrong while updating profile image",
        duration: 3,
      });
    }
  };

  const submitSocialMedia = async (values) => {
    const { linkedinUrl, twitterUrl, link, bio } = values?.socialMediaProfile || {};
    let obj = {
      [`socialMediaProfile.goldenBadge.${networkId}`]: false,
      [`socialMediaProfile.verificationStatus.${networkId}`]: "pending",
      'socialMediaProfile.userId': userInfo?._id,
    };

    if (!isProfileEditionDisabled) {
      obj = {
        ...obj,
        'socialMediaProfile.linkedinUrl': linkedinUrl,
        'socialMediaProfile.twitterUrl': twitterUrl,
        'socialMediaProfile.link': link || null,
        'socialMediaProfile.bio': bio || null,
      }
    }
    console.info(obj);
    setLoading(true);
    const response = await nftService.updateSocialMedia(obj);
    if (response.success) {
      notification.open({
        message: "Success",
        duration: 0,
      });
      setTimeout(() => window.location.reload(), 1000);
    } else {
      notification.open({
        message: "Error",
        description: response.error.message,
        duration: 0,
      });
    }
    setLoading(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const isApproved = () => {
    return userInfo?.socialMediaProfile?.goldenBadge?.[networkId] === true &&
      userInfo?.socialMediaProfile.verificationStatus?.[networkId] === "approved"
  }

  const isRejected = () => {
    return userInfo?.socialMediaProfile?.verificationStatus?.[networkId] === "rejected"
  }

  const isPending = () => {
    return userInfo?.socialMediaProfile?.verificationStatus?.[networkId] === "pending"
  }

  const handleImageClick = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleImageSelect = (image) => {
    setSelectedImage(image);
    setDropdownVisible(false);
  };

  const handleCopyClick = () => {
    try {
      navigator.clipboard.writeText(userInfo?.walletAddress);
      notification.open({
        message: "Wallet Address Copied Successfully",
        duration: 3,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleBioEdit = () => {
    setIsBioEdit(true);
  }

  const handleLinkEdit = () => {
    setIsLinkEdit(true);
  }

  const handleBioLinkSave = async (changingData = "bio") => {
    const { socialMediaProfile = {} } = userInfo || {}
    const { linkedinUrl, twitterUrl, goldenBadge, verificationStatus, bio, link } = socialMediaProfile || {};
    let obj = {
      'socialMediaProfile.linkedinUrl': linkedinUrl,
      'socialMediaProfile.twitterUrl': twitterUrl,
      'socialMediaProfile.bio': bio,
      'socialMediaProfile.link': link,
      [`socialMediaProfile.goldenBadge.${networkId}`]: goldenBadge[networkId],
      [`socialMediaProfile.verificationStatus.${networkId}`]: verificationStatus[networkId],
      'socialMediaProfile.userId': userInfo?._id,
    }
    let payload = {};
    if (changingData === "bio") {
      payload = { ...obj, 'socialMediaProfile.bio': socialMediaProfileValues?.bio || null, }
    } else {
      payload = { ...obj, 'socialMediaProfile.link': socialMediaProfileValues?.link || null, }
    }
    setLoading(true);
    const response = await nftService.addDataToUserProfile(userInfo?._id, payload);
    if (response.success) {
      notification.open({
        message: "Success",
        duration: 0,
      });
      setTimeout(() => window.location.reload(), 1000);
    } else {
      notification.open({
        message: "Error",
        description: response.error.message,
        duration: 0,
      });
    }
    setLoading(false);
  }

  return <>
    {loading && <Spinner />}
    <div className="collection-detail-container">
      <div className="row">
        <div className="full-column">

          <div className="collection-banner">
            <img
              src={selectedImage}
              alt="banner"
              className="NftBanner"
              loading="lazy"
              style={{ opacity: 1 }}
            />
          </div>

          {
            isApproved() && <div className="profile-form badge-overlay">
              <img
                src={GoldenBadge}
                alt="golden-badge"
                className="golden-badge-profile"
                loading="lazy"
              />
            </div>
          }

          <div className="profile-form banner-overlay">
            <img
              src={BannerChangeIcon}
              alt="banner"
              className="bannerChangePic"
              loading="lazy"
              onClick={handleImageClick}
            />
          </div>

          <section className="profile-form profile-image col-12">
          <div class="profile-overlay-url profile-pic-url">

              <img
                src={userInfo
                  ? userInfo.profilePicUrl
                    ? userInfo.profilePicUrl
                    : ProfilePic
                  : ProfilePic}
                alt="profile"
                className="profilePicture"
                loading="lazy"
              />

              <div className="browse">
                <button required="" type="button" id="get_file" class="btn-main">
                  <img src={Edit} alt='edit' className='profile-form edit-icon' />
                </button>

                <input
                  required
                  id="attachment"
                  name="attachment"
                  type="file"
                  onChange={uploadFile}
                  accept=".png,.PNG,.jpg,.JPG,.jpeg,.JPEG,.jpeg,.JPEG"
                />

              </div>

            </div>
          </section>

          <div>

            {
              dropdownVisible && (
                <div className="profile-form dropdown">
                  {images.map((image, index) => (
                    <img src={image} className="profile-form dropdown-image" style={{
                      padding: '8px',
                      cursor: 'pointer',
                      borderBottom: index !== images.length - 1 ? '1px solid #ddd' : 'none',
                    }}
                      onClick={() => handleImageSelect(image)} alt={`banner-${index}`} key={index} />
                  ))}
                </div>
              )
            }
          </div>

          <div className="bellow-banner-column">
            <div className="collection-detail">
              <div className="header_desc">
                <div className="collection_desc">
                  <div className="collection_title">

                    <span>
                      Profile details
                    </span>

                  </div>
                  <div>

                    <Form form={form} validateTrigger={['onChange', 'onBlur']} layout="vertical" onFinish={submitSocialMedia} onFinishFailed={onFinishFailed} className="profile-form form">

                      <Form.Item
                        className="profile-form formItem"
                        name="name"
                        label="Profile Name"

                      >
                        <Input className="profile-form formInput" disabled />
                      </Form.Item>

                      <Form.Item
                        className="profile-form formItem"
                        name="gender"
                        label="Gender"

                      >
                        <Input className="profile-form formInput" disabled />
                      </Form.Item>

                      <div className="d-flex w-100 align-items-center">
                        <div className="d-flex flex-column" style={{ width: '60%' }}>
                          <Form.Item
                            className="profile-form formItem"
                            label="Bio"
                            style={{ width: '100%' }}
                            name={['socialMediaProfile', 'bio']}
                            rules={[{ max: 500, message: 'Bio must be up to 500 characters' }]}
                          >
                            <Input.TextArea rows={6} style={{ resize: 'none' }} disabled={isProfileEditionDisabled && !isBioEdit} resize="none" className="profile-form formInput" placeholder="Leave your message here (optional)" />
                          </Form.Item>

                          <p className="profile-form char-limit">Character Limit: 500max</p>
                        </div>

                        {((isPending() || isApproved() || isRejected()) && !isBioEdit) && (
                          <Button type="primary" className="profile-form editBtn" onClick={handleBioEdit}>
                            Edit
                          </Button>
                        )}

                        {((isPending() || isApproved() || isRejected()) && isBioEdit) && (
                          <Button type="primary" className="profile-form saveBtn" onClick={() => handleBioLinkSave('bio')}>
                            Save
                          </Button>
                        )}

                      </div>

                      <Form.Item
                        className="profile-form formItem"
                        name="email"
                        label="Email Address"

                      >
                        <Input className="profile-form formInput" placeholder="Enter username" disabled />
                      </Form.Item>

                      <p className="profile-form social-connections">Social Connections</p>
                      <p className="profile-form social-connection-subtext">Help collectors verify your account by connecting social accounts</p>

                      <Form.Item
                        className="profile-form formItem"
                        name={['socialMediaProfile', 'twitterUrl']}
                        rules={[
                          { required: true, message: 'Please enter your Twitter profile URL.' },
                          {
                            type: 'url',
                            message: 'Please enter a valid URL for your Twitter profile.',
                          },
                        ]}
                        validateTrigger="onBlur"
                        label=""
                      >
                        <Input className="profile-form formInput" disabled={isProfileEditionDisabled} placeholder="Enter Twitter profile url link" prefix={<TwitterOutlined className="profile-form website-icon" />} />
                      </Form.Item>

                      <Form.Item
                        className="profile-form formItem"
                        name={['socialMediaProfile', 'linkedinUrl']}
                        rules={[
                          { required: true, message: 'Please enter your LinkedIn profile URL.' },
                          {
                            type: 'url',
                            message: 'Please enter a valid URL for your LinkedIn profile.',
                          },
                        ]}
                        validateTrigger="onBlur"
                        label=""
                      >
                        <Input className="profile-form formInput" disabled={isProfileEditionDisabled} placeholder="Enter LinkedIn profile url link" prefix={<LinkedinFilled className="profile-form website-icon" />} />
                      </Form.Item>

                      <div className="d-flex align-items-center">

                        <Form.Item
                          className="profile-form formItem"
                          label="Link"
                          style={{ width: '60%' }}
                          rules={[
                            { required: false },
                            {
                              type: 'url',
                              message: 'Please enter a valid URL',
                            },
                          ]}
                          validateTrigger="onBlur"
                          name={['socialMediaProfile', 'link']}
                        >
                          <Input className="profile-form formInput" disabled={isProfileEditionDisabled && !isLinkEdit} placeholder="yoursite.com" prefix={<GlobalOutlined className="profile-form website-icon" />} />
                        </Form.Item>

                        {((isPending() || isApproved() || isRejected()) && !isLinkEdit) && (
                          <Button type="primary" className="profile-form editBtn" onClick={handleLinkEdit}>
                            Edit
                          </Button>
                        )}

                        {((isPending() || isApproved() || isRejected()) && isLinkEdit) && (
                          <Button type="primary" className="profile-form saveBtn" onClick={() => handleBioLinkSave('link')} style={{ marginTop: '20px' }}>
                            Save
                          </Button>
                        )}

                      </div>

                      <Form.Item
                        className="profile-form formItem"
                        name="walletAddress"
                        label="Wallet Address"
                      >
                        <Input className="profile-form formInput" disabled suffix={<img src={Copy} alt="copy" onClick={handleCopyClick} className="profile-form copy-icon" />} />
                      </Form.Item>


                      <div className="profile-form golden-badge-div">
                        <p className="profile-form golden-badge">Golden Badge</p>
                        <p className="profile-form golden-badge-subtext">Below eligible points you have to finish to gain golden badge</p>

                        <ul className="profile-form golden-badge-list">
                          <li>Upload the correct profile photo {userInfo?.profilePicUrl && <img src={Verified} alt='Verified' className='profile-form verified-icon' />}</li>
                          <li>
                            You should have minimum of {twitterGoldenBadge} no of followers in Twitter profile. {isApproved() && <img src={Verified} alt='Verified' className='profile-form verified-icon' />}
                          </li>
                          <li>
                            You should have minimum of {linkedinGoldenBadge} no of followers in LinkedIn profile. {isApproved() && <img src={Verified} alt='Verified' className='profile-form verified-icon' />}
                          </li>
                        </ul>

                        {(userInfo?.socialMediaProfile?.verificationStatus?.[networkId] !==
                          "pending" && userInfo?.socialMediaProfile?.verificationStatus?.[networkId] !==
                          "approved") && (
                            <p className="profile-form status">
                              Status: Not yet completed the badge minimum requirement
                            </p>
                          )}

                        {isPending() && (
                          <p className="profile-form status">
                            Status: Pending for approval from Marketplace  Admin
                          </p>
                        )}

                        {isApproved() &&
                          (
                            <p className="profile-form status">
                              Status: <span className="profile-form golden-badge-earned">Congratulations! You have earned the “GOLDEN BADGE”</span>
                              <img src={GoldenBadge} alt='Golden Badge' className='profile-form goldenBadge-icon' />
                            </p>
                          )}

                        {isRejected() &&
                          (
                            <p className="profile-form status">
                              Status: <span className="profile-form golden-badge-rejected">Sorry! Admin has rejected your profile for “GOLDEN BADGE”</span>
                            </p>
                          )}

                      </div>

                      <div className="d-flex">
                        <Form.Item>
                          {
                            socialMediaProfileValues?.linkedinUrl && socialMediaProfileValues?.twitterUrl && userInfo?.profilePicUrl
                            && userInfo?.socialMediaProfile?.verificationStatus?.[networkId] !== "pending" && userInfo?.socialMediaProfile?.verificationStatus?.[networkId] !== "approved" && (
                              <Button type="primary" htmlType="submit" className="profile-form submitBtn" disabled={!submittable}>
                                Submit
                              </Button>
                            )
                          }
                          {
                            ((!socialMediaProfileValues?.linkedinUrl || !socialMediaProfileValues?.twitterUrl || !userInfo?.profilePicUrl)
                              && (<Button type="primary" htmlType="submit" className="profile-form submitBtn" disabled>
                                Submit
                              </Button>
                              ))
                          }
                          {isPending() && (
                            <Button type="primary" htmlType="submit" className="profile-form submittedBtn" disabled>
                              SUBMITTED
                            </Button>
                          )}

                        </Form.Item>


                      </div>
                    </Form>

                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>

    </div>
  </>
}