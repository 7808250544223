import React, { useEffect, useState, useContext } from "react";
import { NFTCreatorService } from "./../components/services/NFTCreator/NFTCreator.service";
import authContext from "./../components/Shared/Auth.service";
import { VideoPlayer } from "./../components/mainSlider/VideoPlayer";
import { SharedService } from "./../components/Shared/Shared.service";
import styled from "styled-components";
import { useHistory, useParams } from "react-router-dom";
import NFTCard from "../components/NFTCardComponent";
import CollectionBanner from "./../assets/images/collections/Banner1.png";
import ProfilePicture from "../../components/assets/images/collections/collectionDetail/Rectangle 7.png";
import { MetamaskService } from "../components/Shared/Metamask.service";
import { NavLink, Link } from "react-router-dom";
import "./styles/CollectionDetail.scss";
import Spinner from "../components/spinner/Spinner";

const nftService = new NFTCreatorService();
const sharedService = new SharedService();

const useSelectedWalletContext = () =>
  new MetamaskService().useSelectedWalletContext();

const Collections = () => {
  // const [nfts, setNfts] = useState([]);
  const [collection, setCollection] = useState({});
  const [tempCollections, setTempCollections] = useState({});
  const [todayEtheriumPriceInUSD, setTodayEtheriumPriceInUSD] = useState();
  const [loading, setLoading] = useState(true);
  const { networkId } = useSelectedWalletContext();
  const [searchValue, setSearchValue] = useState("");
  let { collectionName } = useParams();

  useEffect(() => {
    async function getAllCollections() {
      setLoading(true);
      //setting ether price
      setTodayEtheriumPriceInUSD(await sharedService.getEtheriumPrice());

      const response = await nftService.getCollectionDetailByName(
        collectionName,
        { networkId }
      );
      if (response.success) {
        setCollection(response?.data);
        setTempCollections(response?.data);
        setLoading(false);
      } else {
        console.log("An Error has occured!");
        setCollection({});
        setTempCollections({});
        setLoading(false);
      }
    }

    if (!networkId) return;

    getAllCollections();
  }, [collectionName, networkId]);

  const handleOnChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleSearch();
    }
  };

  const handleSearch = () => {
    if (searchValue) {
      const filteredCollections = tempCollections?.nftDetails?.filter((item) =>
        item?.title?.toLowerCase()?.includes(searchValue?.toLowerCase())
      );
      const updatedData = {
        ...tempCollections,
        nftDetails: filteredCollections,
      };
      setCollection(updatedData);
    } else {
      setCollection(tempCollections);
    }
  };

  return (
    // <div className="collection_container">
    //   <div className="collection_header">
    //     <div className="banner_image">
    //       <img
    //         // src="https://lh3.googleusercontent.com/i5dYZRkVCUK97bfprQ3WXyrT9BnLSZtVKGJlKQ919uaUB0sxbngVCioaiyu9r6snqfi2aaTyIvv6DHm4m2R3y7hMajbsv14pSZK8mhs=s2500"
    //         src={CollectionBanner}
    //         alt="banner"
    //         className="NftBanner"
    //         loading="lazy"
    //         style={{ opacity: 1 }}
    //       />
    //     </div>

    //     <div className="collection_image_container">
    //       <div style={{ marginTop: "-86px", marginBottom: "15px" }}>
    //         <div className="collection_image">
    //           <img
    //             src={collection?.collectionDocument?.FirebaseURL}
    //             alt="NFT_collection_Image"
    //             loading="lazy"
    //             style={{ opacity: 1 }}
    //           />
    //         </div>
    //       </div>
    //     </div>
    //   </div>

    //   <div className="header_desc">
    //     <div className="collection_desc">
    //       <div className="collection_title">
    //         <span>{collection?.collectionName}</span>
    //       </div>

    //       <div className="collection_created_by">
    //         <span style={{ fontSize: "15px", fontWeight: "bolder" }}>
    //           Created by:{" "}
    //         </span>
    //         <span style={{ color: "black", fontSize: "15px" }}>
    //           {collection?.collectionCreatedBy}
    //         </span>
    //       </div>

    //       <div className="desc_body">
    //         <span>{collection?.tokenDescription}</span>
    //       </div>
    //       {/* <div className="button_container">
    //                     <div className="place_bid">
    //                         <div className="btn_text">
    //                             <span>Place a bid for any NFT from this collection</span>
    //                         </div>
    //                         <button className='bid_btn'>Place a bid</button>
    //                     </div>
    //                 </div> */}
    //     </div>

    //     {/* This is commented for now and need to integrate with real blohchain data in future */}
    //     {/* <div className="collection_pricing">
    //             <div className="pricing_body">
    //                 <div className="items">
    //                     <span>Floor: </span>
    //                     <span style={{ color: "black" }}>75.369 ETH</span>
    //                 </div>
    //                 <div className="items">
    //                     <span>Volume: </span>
    //                     <span style={{ color: "black" }}>800.3K ETH</span>
    //                 </div>
    //                 <div className="items">
    //                     <span>Items: </span>
    //                     <span style={{ color: "black" }}>10K</span>
    //                 </div>
    //                 <div className="items">
    //                     <span>Owners: </span>
    //                     <span style={{ color: "black" }}>6.6k</span>
    //                 </div>

    //                 <div className="space_line"></div>

    //                 <div className="items">
    //                     <span>Blockchain: </span>
    //                     <span style={{ color: "black" }}>Ethereum</span>
    //                 </div>
    //                 <div className="items">
    //                     <span>Address: </span>
    //                     <span style={{ color: "black" }}>0xb...f13d</span>
    //                 </div>
    //             </div>
    //         </div> */}
    //   </div>

    //   <div className="collection_body">
    //     <div className="row col-12">
    //       {collection.nftDetails &&
    //         collection.nftDetails.map((nft, index) => (
    //           <div
    //             className="itm col-xl-3 col-lg-4 col-md-6 col-sm-12"
    //             index={index + 1}
    //             key={nft.nftId}
    //           >
    //             <div className="d-item">
    //               <div className="nft__item">
    //                 <NFTCard
    //                   nft={nft}
    //                   todayEtheriumPriceInUSD={todayEtheriumPriceInUSD}
    //                 />
    //               </div>
    //             </div>
    //           </div>
    //         ))}
    //     </div>
    //   </div>
    // </div>
    <>
      {loading && <Spinner />}
      {!loading && (
        <div className="collection-detail-container">
          <div className="row">
            <div className="full-column">
              <div className="collection-banner">
                <img
                  // src="https://lh3.googleusercontent.com/i5dYZRkVCUK97bfprQ3WXyrT9BnLSZtVKGJlKQ919uaUB0sxbngVCioaiyu9r6snqfi2aaTyIvv6DHm4m2R3y7hMajbsv14pSZK8mhs=s2500"
                  src={CollectionBanner}
                  alt="banner"
                  className="NftBanner"
                  loading="lazy"
                  style={{ opacity: 1 }}
                />
              </div>
              <div class="profile-overlay">
                <img
                  src={collection?.collectionDocument?.FirebaseURL}
                  alt="profile"
                  className="profilePicture"
                  loading="lazy"
                />
              </div>
              <div className="bellow-banner-column">
                <div className="collection-detail">
                  {/* <h1>collection detail</h1> */}

                  <div className="header_desc">
                    <div className="collection_desc">
                      <div className="collection_title">
                        <span>
                          <span className="collection_name">
                            Collection Name:
                          </span>
                          &nbsp;
                          {collection?.collectionName
                            ? collection.collectionName
                                .charAt(0)
                                .toUpperCase() +
                              collection.collectionName.slice(1)
                            : ""}
                        </span>
                        {/* <span>
                      Collection Name:&nbsp;{collection?.collectionName}
                    </span> */}
                      </div>
                      <div className="collection-flex">
                        <div className="collection_created_by">
                          Created by&nbsp;
                          <div className="collectio-createdBy">
                            <span>
                              {collection?.collectionCreatedBy
                                ? `${collection.collectionCreatedBy.substring(
                                    0,
                                    4
                                  )}...${collection.collectionCreatedBy.substring(
                                    collection.collectionCreatedBy.length - 4
                                  )}`
                                : ""}
                            </span>
                          </div>
                        </div>
                        <div
                          className="vertical-line"
                          style={{
                            borderLeft: " 2px solid rgba(0, 0, 0, 0.1)",
                            height: "70px",
                          }}
                        ></div>
                        <div className="collection_created_on">
                          Created on&nbsp;
                          <div className="collectio-createdOn">
                            <span>
                              {new Date(
                                collection?.collectionDocument?.createdOn
                              ).toLocaleString("en-US", {
                                month: "short",
                                day: "numeric",
                              })}
                            </span>
                          </div>
                          {/* <span>{collection?.collectionDocument?.createdOn}</span> */}
                        </div>
                        <div
                          className="vertical-line"
                          style={{
                            borderLeft: " 2px solid rgba(0, 0, 0, 0.1)",
                            height: "70px",
                          }}
                        ></div>
                        <div className="collection_creator">
                          Creator&nbsp;
                          <div className="collectio-creator">
                            <span>
                              {collection?.userDetails?.firstName}
                              &nbsp;
                              {collection?.userDetails?.lastName}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="collection-box">
                        <div className="collection_token_name">
                          Token Name:&nbsp;
                          <span> {collection?.tokenSymbol}</span>
                        </div>

                        <div className="collection_url">
                          <span>
                            <span className="collection-url">
                              Collection URL:
                            </span>{" "}
                            &nbsp;
                            <Link
                              className="collection_url_link"
                              to={collection?.NFTMintingContractAddress}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <span>
                                {collection?.NFTMintingContractAddress}
                              </span>
                            </Link>
                          </span>
                        </div>
                      </div>
                      {/*  <div className="desc_body">
                        <span>{collection?.tokenDescription}</span>
                      </div> */}
                      {/* <div className="button_container">
                    <div className="place_bid">
                      <div className="btn_text">
                        <span>
                          Place a bid for any NFT from this collection
                        </span>
                      </div>
                      <button className="bid_btn">Place a bid</button>
                    </div>
                  </div> */}
                    </div>
                  </div>
                </div>

                {/* SEARCH BAR */}
                {/*  <div class="search-container">
                  <div class="search_icon">
                    <i class="fa fa-search"></i>
                  </div>
                  <input
                    type="text"
                    class="search_input"
                    value={searchValue}
                    placeholder="Search by name"
                    onKeyDown={handleKeyDown}
                    onChange={(e) => handleOnChange(e)}
                  />
                  <button class="search_button" onClick={handleSearch}>
                    Search
                  </button>
                </div> */}

                <div className="nft-container">
                  <h1
                    className="nft-container-title"
                    style={{
                      textAlign: "left",
                      fontFamily: "poppins",
                      fontSize: "32px",
                      fontWeight: "700",
                      color: "rgba(5, 11, 55, 1)",
                    }}
                  >
                    NFTS
                  </h1>{" "}
                  <div className="collection_body d-flex flex-wrap">
                    {collection?.nftDetails?.length ? (
                      collection.nftDetails.map((nft, index) => (
                        <div
                          index={index + 1}
                          key={nft.nftId}
                          style={{ padding: "20px" }}
                          className="itm col-xl-3 col-lg-4 col-md-6 col-sm-12"
                        >
                          <NFTCard
                            isCreator={true}
                            nft={nft}
                            buy={true}
                            todayEtheriumPriceInUSD={todayEtheriumPriceInUSD}
                          />
                        </div>
                      ))
                    ) : (
                      <h3 className="collectionDetails-data">No Data Found.</h3>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Collections;
