import React, { useEffect, useState } from "react";
import { MetamaskService } from "../../../../components/Shared/Metamask.service";
import { SharedService } from "../../../../components/Shared/Shared.service";
import { Pagination, notification } from "antd";
import { AdminService } from "../../../../components/services/AdminService/Admin.service";
const useSelectedWalletContext = () =>
  new MetamaskService().useSelectedWalletContext();
const sharedService = new SharedService();
const adminService = new AdminService();

const blockchainTransactionsStyleing = {
  container: {
    backgroundColor: "#FFFFFF",
    borderRadius: "14px",
    boxShadow: "0px 4px 4px 0px #00000040",
    margin: "20px",
    paddingTop: "40px",
  },
  tableHead: { backgroundColor: "#0F587A", color: "#FFFFFF" },
  tableRowOdd: { backgroundColor: "#FFF8E2" },
  paginationContainer: {
    display: "flex",
    justifyContent: "end",
    padding: "30px",
  },
  paginationRender: {
    width: "180px",
    height: "41px",
    borderRadius: "8px",
    border: "2px solid #102253",
    display: "flex",
    gap: "10px",
    alignItems: "center",
    justifyContent: "center",
  },
  goInput: {
    border: "2px solid #102253",
    width: "71px",
    marginLeft: "8px",
  },
  goButton: {
    background: "#EB532D",
    marginLeft: "8px",
    borderRadius: "8px",
    width: "64px",
    height: "55px",
    color: "#FFFFFF",
  },
};

const BlockchainTransactions = ({ loading, setLoading }) => {
  const { networkId } = useSelectedWalletContext();
  const [mintingBlockchainTransaction, setMintingBlockchainTransaction] =
    useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [goToPage, setGoToPage] = useState(1);
  const pageSize = 10;

  const fetchData = async () => {
    // getting blockchain transctions
    setLoading(true);
    try {
      let res = await adminService.getMintingBlockchainTransaction({
        networkId,
      });
      if (res?.data?.length > 0){ 
        setMintingBlockchainTransaction(res.data.reverse());
      } else{
        setMintingBlockchainTransaction([]);
      }
    } catch (error) {
      console.log(error);
      notification.open({
        message: "Error",
        description:
          "something went wrong while fetching NFT Minting blockchain transaction data",
        duration: 0,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const totalPages = Math.ceil(mintingBlockchainTransaction.length / pageSize);
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const currentPageData = mintingBlockchainTransaction.slice(
    startIndex,
    endIndex
  );

  const handleGoToPage = () => {
    const pageNumber = parseInt(goToPage);
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const onPageChange = (page) => {
    setCurrentPage(page);
    setGoToPage(page);
  };

  return (
    !loading && (
      <>
        <p className="market-super-admin commission-configuration-text">
          NFT Minting Blockchain Transactions
        </p>
        <div
          style={blockchainTransactionsStyleing.container}
          className="admin-invites"
        >
          {mintingBlockchainTransaction?.length > 0 ? (
            <div style={{ overflowX: "auto" }}>
              <table className="table table-striped">
                <thead style={blockchainTransactionsStyleing.tableHead}>
                  <tr>
                    <th style={{paddingLeft:"30px"}} scope="col">No</th>
                    <th scope="col">NFT Title</th>
                    <th scope="col">NFT Collection Name</th>
                    <th scope="col">Blockchain URL</th>
                  </tr>
                </thead>

                <tbody>
                  {currentPageData.map((blockTxn, index) => (
                    <tr
                      style={
                        index % 2 === 0
                          ? blockchainTransactionsStyleing.tableRowOdd
                          : {}
                      }
                      key={blockTxn._id}
                    >
                      <th style={{paddingLeft:"40px"}} scope="row">{startIndex + index + 1}</th>
                      <td>{blockTxn?.nftTitle}</td>
                      <td>{blockTxn?.nftCollectionName}</td>
                      <td>
                        <a
                          href={`${sharedService.etherscanURL[networkId]}/tx/${blockTxn?.transactionDetails?.transactionHash}`}
                          target="_blank"
                          rel="noreferrer"
                          style={{ color: "black" }}
                        >
                          Link
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="no-data-found-div">
              <p className="no-data-found-text">No Data Found</p>
            </div>
          )}
          {/* Pagination  */}
          <div style={blockchainTransactionsStyleing.paginationContainer}>
            {mintingBlockchainTransaction?.length ? (
              <Pagination
                current={currentPage}
                total={mintingBlockchainTransaction?.length}
                pageSize={pageSize}
                onChange={onPageChange}
                showSizeChanger={false}
                showQuickJumper={false}
              />
            ) : null}
            <input
              type="number"
              value={goToPage}
              onChange={(e) => setGoToPage(e.target.value)}
              min="1"
              max={totalPages}
              style={blockchainTransactionsStyleing.goInput}
            />
            <button
              onClick={handleGoToPage}
              style={blockchainTransactionsStyleing.goButton}
            >
              Go
            </button>
          </div>
        </div>
      </>
    )
  );
};

export default BlockchainTransactions;
