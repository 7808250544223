import { Button, Divider, Modal, Pagination, Select, Table, notification } from 'antd';
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { AdminService } from '../../../../components/services/AdminService/Admin.service';
import { MasterWhiteListingService } from '../../../../components/Shared/Blockchain/MasterWhitelisting/MasterWhiteListing.service';
import authContext from '../../../../components/Shared/Auth.service';
import { MetamaskService } from '../../../../components/Shared/Metamask.service';

const adminService = new AdminService();
const masterWhiteListingService = new MasterWhiteListingService();
const useSelectedWalletContext = () =>
  new MetamaskService().useSelectedWalletContext();

function GoldenBadgeApprovals(props) {
  const { setLoading, smartContractData, loading } = props || {};
  const { userInfo } = useContext(authContext);
  const { networkId } = useSelectedWalletContext();

  const [pendingGoldenBadge, setPendingGoldenBadge] = useState();
  const [approvedGoldenBadge, setApprovedGoldenBadge] = useState();

  const [sortByVal, setSortByVal] = useState("Approved");
  const [goldenBadgeRejectionMessage, setGoldenBadgeRejectionMessage] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [walletDetails, setWalletDetails] = useState({});
  const rejectedGoldenBadges = useMemo(() => {
    return approvedGoldenBadge?.filter((user) => user.socialMediaProfile?.verificationStatus?.[networkId] === "rejected")
  }, [approvedGoldenBadge, networkId]) || [];
  const filteredApprovedGoldenBadges = useMemo(() => {
    return approvedGoldenBadge?.filter((user) => user.socialMediaProfile?.verificationStatus?.[networkId] === "approved")
  }, [approvedGoldenBadge, networkId]) || [];
  const filteredWallets = sortByVal === "Approved" ? filteredApprovedGoldenBadges : sortByVal === "Pending" ? pendingGoldenBadge : rejectedGoldenBadges;
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;
  const [goToPage, setGoToPage] = useState(1);
  const totalPages = Math.ceil(filteredWallets?.length / pageSize);
  const [isReject, setIsReject] = useState(false);

  const onPageChange = (page) => {
    setCurrentPage(page);
    setGoToPage(page);
  };

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const currentPageData = filteredWallets.slice(startIndex, endIndex);

  useEffect(() => {
    fetchData(userInfo, networkId)
  }, [userInfo, networkId])

  const columns = [
    {
      title: 'Profile',
      dataIndex: 'kycDetails',
      key: 'kycDetails',
      render: (_, { kycDetails }) => (
        <div style={{
          position: 'relative'
        }}>
          <img
          className="market-place-admin table-selfie-img"
          src={kycDetails?.selfie}
          alt="photo"
        />
        <img style={{
          position: 'absolute',
          width: '29px',
          height: '37px',
          top:"11px",
          left:"31px"
        }} src="/img/home/golden-badge.svg" alt="Golden Badge" />
        </div>
      ),
    },
    {
      title: 'FirstName',
      dataIndex: 'firstName',
      key: 'firstName',
      render: (text) => <p className='market-place-admin table-text'>{text}</p>,
    },
    {
      title: 'LastName',
      dataIndex: 'lastName',
      key: 'lastName',
      render: (text) => <p className='market-place-admin table-text'>{text}</p>,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (text) => <p className='market-place-admin table-text'>{text}</p>,
    },
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
      render: (text) => <p className='market-place-admin table-text'>{text}</p>,
    },
    {
      title: 'Profile Picture Url',
      dataIndex: 'profilePicUrl',
      key: 'profilePicUrl',
      render: (profilePicUrl) => <a href={profilePicUrl} target="_blank" rel="noreferrer" className='market-place-admin table-link'>{"Profile Picture Link"}</a>,
    },
    {
      title: 'LinkedinUrl',
      dataIndex: 'socialMediaProfile',
      key: 'socialMediaProfile',
      render: (_, { socialMediaProfile }) => (
        <a href={socialMediaProfile?.linkedinUrl} target="_blank" rel="noreferrer" className='market-place-admin table-link'>
          {"LinkedIn"}
        </a>
      ),
    },
    {
      title: 'TwitterUrl',
      dataIndex: 'socialMediaProfile',
      key: 'socialMediaProfile',
      render: (_, { socialMediaProfile }) => (
        <a href={socialMediaProfile?.twitterUrl} target="_blank" rel="noreferrer" className='market-place-admin table-link'>
          {"Twitter Link"}
        </a>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'socialMediaProfile',
      key: 'socialMediaProfile',
      render: (_, { socialMediaProfile, walletAddress, _id }) => {
        return <>
          {
            socialMediaProfile?.verificationStatus?.[networkId] === "approved" && <Button style={{fontWeight:"bolder"}} className="wallet-status-approved-chip table-chip"><img className="market-place-admin sidenav-icon" src={"/img/admin-dashboard/approved.png"} alt="nftArt" />
              <p>Approved</p></Button>
          }
          {
            socialMediaProfile?.verificationStatus?.[networkId] === "rejected" && <Button style={{fontWeight:"bolder"}} className="wallet-status-rejected-chip table-chip"><img className="market-place-admin sidenav-icon" src={"/img/admin-dashboard/rejected.png"} alt="nftArt" />
              <p>Rejected</p></Button>
          }
          {
            sortByVal === "Pending" && <Button style={{fontWeight:"bolder"}} className="wallet-status-pending-chip table-chip" onClick={() => handleOpenModal({ walletAddress, _id })}><img className="market-place-admin sidenav-icon" src={"/img/admin-dashboard/pending.png"} alt="nftArt" />
              <p>Pending</p></Button>
          }
        </>
      },
    },
  ];

  const handleOpenModal = (data) => {
    setOpenModal(true);
    setWalletDetails(data)
  }

  const fetchData = async () => {
    //get golden badge data
    setLoading(true);
    try {
      let res3 = await adminService.getPendingGoldenBadge({ networkId });
      if (res3.data?.length > 0) {
        setPendingGoldenBadge(res3.data.reverse());
      } else {
        setPendingGoldenBadge([])
      }

      let res4 = await adminService.getCompletedGoldenBadge({ networkId });
      if (res4.data?.length > 0) {
        setApprovedGoldenBadge(res4.data.reverse());
      } else {
        setApprovedGoldenBadge([])
      }
    } catch (error) {
      console.log(error);
      notification.open({
        message: 'Error',
        description: 'something went wrong while fetching Golden Badge Approval Data',
        duration: 0,
      });
    }
    setLoading(false);
  };

  const approveGoldenBadge = async (userId, status, message, userWalletAddress) => {
    setLoading(true);
    try {
      if (status === 'rejected') {
        let form = {
          userId,
          status: 'rejected',
          message,
          approver: "MPCAdmin",
          MPComplianceAdminApproverWallet: userInfo?.walletAddress,
          networkId
        }
        let res = await adminService.approveGoldenBadge(form);
        if (res.data) {
          notification.open({
            message: 'Success Rejected NFTCreator as GoldenBadge Holder',
            duration: 0,
          });
          setTimeout(() => fetchData(), 2000);
        }
      } else {
        const txnReceipt = await masterWhiteListingService.PromoteGoldenBadgeUser(
          String(userWalletAddress),
          String(userInfo?.walletAddress),
          String(smartContractData?.masterWhiteListingContract)
        );

        console.log("Blockchain Transaction receipt: ", txnReceipt);
        if (txnReceipt) {
          let form = {
            userId,
            status,
            message,
            approver: "MPCAdmin",
            MPComplianceAdminApproverWallet: userInfo?.walletAddress,
            transactionDetail: txnReceipt,
            networkId
          }
          let res = await adminService.approveGoldenBadge(form);
          if (res.data) {
            notification.open({
              message: 'Success Approved NFTCreator as GoldenBadge Holder',
              duration: 0,
            });
            fetchData();
          }
        }
      }

    } catch (error) {
      setLoading(false)
      console.log(error);
      notification.open({
        message: error.message,
        duration: 0,
      });
    }
  }

  const handleChange = (value) => {
    setSortByVal(value);
    setCurrentPage(1)
  }

  const handleReject = () => {
    setOpenModal(false);
    approveGoldenBadge(
      walletDetails._id,
      "rejected",
      goldenBadgeRejectionMessage
    )
  }

  const handleApprove = () => {
    setOpenModal(false);
    approveGoldenBadge(
      walletDetails._id,
      "approved",
      "",
      walletDetails?.walletAddress
    )
  }

  const handleGoToPage = () => {
    const pageNumber = parseInt(goToPage);
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  return (
    !loading && <>
      <p className='market-super-admin commission-configuration-text'>Golden Badge Approval</p>
      <div className='market-super-admin admin-invites golden-badge-approval card-container d-flex justify-content-center flex-column'>
        <div className='market-super-admin card-inner-container' style={{ width: '100%' }}>
          <div className='d-flex col-12' style={{ padding: "30px 30px 0 30px" }}>
            <div className='d-flex justify-content-center align-items-center'>
              {sortByVal === "Approved" && <img className="market-place-admin status-icon" src={"/img/admin-dashboard/approved.png"} alt="nftArt" />}
              {sortByVal === "Rejected" && <img className="market-place-admin status-icon" src={"/img/admin-dashboard/rejected.png"} alt="nftArt" />}
              {sortByVal === "Pending" && <img className="market-place-admin status-icon" src={"/img/admin-dashboard/pending.png"} alt="nftArt" />}
              <p className='wallet-status-text'>{sortByVal}</p>
            </div>
            <div className='d-flex' style={{ marginLeft: 'auto' }}>
              <div className='sort-by-text'>
              <img
                  className="market-place-admin status-icon"
                  src={"/img/admin-dashboard/sort-icon.png"}
                  alt="nftArt"
                />
              Sort By:</div>
              <Select
                className='sort-by-select'
                defaultValue={sortByVal}
                onChange={handleChange}
                options={[
                  {
                    value: 'Pending',
                    label: 'Pending',
                  },
                  {
                    value: 'Approved',
                    label: 'Approved',
                  },
                  {
                    value: 'Rejected',
                    label: 'Rejected',
                  },
                ]}
              />
            </div>
          </div>
          <Divider className='golden-badge-approval-divider' />
          {currentPageData?.length > 0 ?
            <Table columns={columns} dataSource={currentPageData} pagination={false} className="golden-badge-approval" />
            : <div className='no-data-found-div'>
              <p className='no-data-found-text'>No Data Found</p>
            </div>
          }

          <Modal
            title=""
            centered
            open={openModal}
            onOk={() => {
              setOpenModal(false);
              setIsReject(false);
            }}
            onCancel={() => {
              setOpenModal(false);
              setIsReject(false);
            }}
            maskClosable={true}
            className='wallet-modal'
            footer={[
              !isReject && (
                <div style={{ marginBottom: '20pxs' }}>
                  <Button className='wallet-modal-approve-button' onClick={handleApprove}>
                    Approve
                  </Button>
                  <Button className='wallet-modal-reject-button' type="primary" onClick={() => {
                    setIsReject(true)
                  }}>
                    Reject
                  </Button>
                </div>),
              isReject && (
                <>
                  <textarea
                    required
                    className='wallet-modal-textarea'
                    value={goldenBadgeRejectionMessage}
                    onChange={(e) =>
                      setGoldenBadgeRejectionMessage(
                        e.target.value
                      )
                    }
                    placeholder="Type here to rejection note ..."
                    style={{ width: "100%", height: "60px" }}
                  />
                  <Button className='wallet-modal-reject-button' type="primary" onClick={handleReject} style={{ marginTop: '20px' }}>
                    Reject
                  </Button>
                </>
              )
            ]}
          >
            <span className='wallet-modal-heading-text'>Are you need to approve this</span>
            <span className='wallet-modal-heading-sub-text'>Golden</span>
            <p className='wallet-modal-heading-sub-text'>Badge user ?</p>
          </Modal>

        </div>

        {
          filteredWallets?.length ? <div className='paginationContainer'>
            <Pagination
              current={currentPage}
              total={filteredWallets?.length}
              pageSize={pageSize}
              onChange={onPageChange}
            />
            <input
              type="number"
              value={goToPage}
              onChange={(e) => setGoToPage(e.target.value)}
              min="1"
              max={totalPages}
              className='goInput'
            />
            <button
              onClick={handleGoToPage}
              className='goButton'
            >
              Go
            </button>
          </div>
            : null
        }

      </div>
    </>
  )
}

export default GoldenBadgeApprovals
