import React, {useState} from 'react';
import { AccountService } from '../Account.service';
import { Row, Col, Card, Typography, Form, Input, Button, Result } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
const { Title } = Typography;


const accountService = new AccountService();


export default () => {
  const [submitting, setSubmitting] = useState(false);
  const [email, setEmail] = useState<string | null>(null);
  const [reqErr, setReqErr] = useState<string>();

  const sendEmailVerificationCode = async(req: any) => {
    setSubmitting(true);

    const response = await accountService.resendAndUploadEmailVerificationCode(req as {email: string});

    if (response.success) {
      setEmail(req.email);
      setReqErr(undefined);
    } else {
      setReqErr(response.error.message);
    }

    setSubmitting(false);
  };


  return (
    <Row justify="center" style={{marginTop:"60px"}}>
      <Col span={16} style={{textAlign:'center'}}>
        <Card>
          {!email && 
            <div>
              <Title level={4}>Enter your email and we will send an email verification link to you or Please click on the link which you have recieved in your registered email</Title>

              <Form size='large' onFinish={sendEmailVerificationCode}>

                <Form.Item
                  // label="Email"
                  name="email"
                  rules={[{ required: true, message: 'Please input your email!' }]}
                  validateStatus={reqErr && 'error'}
                  help={reqErr}
                  style={{width: '40%', margin: 'auto', marginBottom: '10px'}}>
                  <Input prefix={<MailOutlined/>} placeholder='Email'/>
                </Form.Item>

                <Form.Item>
                  <Button type='primary' htmlType='submit' loading={submitting}>
                    Send link
                  </Button>
                  <br/>
                  <a className='login-form-forgot' href="/account/login">
                    Forgot your password?
                  </a>
                  <br />
                  <>Or </><Link to='/account/login'>Login</Link>
                </Form.Item>
              </Form>
            </div>
          }
          {email && 
            <div>
              <h2 style={{textAlign:'center'}}>Check your Email</h2>
              <Result
                title={`We sent a verification email to ${email}.`}
                subTitle="Click the link to inside to started!"
                extra={
                  <Button key="console" onClick={()=>setEmail(null)}>
                    Email didn't arrive?
                  </Button>
                }
              />
            </div>
          }
        </Card>
      </Col>
    </Row>
  );
}