import { SharedService } from '../../Shared/Shared.service';
import { APIResponse} from '../../Shared/interfaces';
import { environment } from '../../../environments/environment';
import axios from 'axios';


const sharedService = new SharedService();


export class PaymentService {
  private APIURL = environment.APIURL;

  async payUsingCoinbase(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/payment/payUsingCoinbase`, 
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async getCoinbasePaymentDetails(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/payment/getCoinbasePaymentDetails`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async completePayment(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/payment/completePayment`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }

}