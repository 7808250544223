import Web3 from 'web3';

export class PlatformService {
    private ethereum = window['ethereum'];
    private web3 = new Web3(this.ethereum);
    private contract = {
        ABI: require('./Platform.json')
    };

    // SettingUp the commissions for market-place from platform
    async setCommission_P(
        PSAdmin: Number,
        whitelistingCommission: Number,
        collectionCreationCommission: Number,
        NFTMintingCommission: Number,
        NFTListingCommission: Number,
        NFTSellingCommission:Number,
        PlatformContractAddress: string
    ) {    
        const contractInstance = new this.web3.eth.Contract(this.contract.ABI, PlatformContractAddress);
        
        return contractInstance.methods.setCommission_P(
            whitelistingCommission,
            collectionCreationCommission,
            NFTMintingCommission,
            NFTListingCommission,
            NFTSellingCommission
        ).send({ from: PSAdmin });
    }
       
    async addSuperAdmin(MSAdmin: string, PSAdmin: string, MPName: string, PlatformContractAddress: string) {    
        const contractInstance = new this.web3.eth.Contract(this.contract.ABI, PlatformContractAddress);
        
        return contractInstance.methods.addSuperAdmin(MSAdmin, MPName).send({ from: PSAdmin });
    }
    
    async createNewMarketPlace(MSAdmin: string, MPName: string, PlatformContractAddress: string) {    
        const contractInstance = new this.web3.eth.Contract(this.contract.ABI, PlatformContractAddress);
        
        return contractInstance.methods.createNewMarketPlace(MPName).send({ from: MSAdmin });
    }

    async updatePSAdmin(MSAdmin: string, newAddress: string, PlatformContractAddress: string) {    
        const contractInstance = new this.web3.eth.Contract(this.contract.ABI, PlatformContractAddress);
        
        return contractInstance.methods.updatePSAdmin(newAddress).send({ from: MSAdmin });
    }
}