import { environment } from '../../../../environments/environment';
import Web3 from 'web3';

export class CommissionsService {
    private ethereum = window['ethereum'];
    private web3 = new Web3(this.ethereum);
    private contract = {
        ABI: require('./commissions.json')
    };
    
    // SettingUp the commissions and Fees for NormalUser
    async setCommissionAndFees_NU(
        MSAdmin: string,
        whiteListingFee: string,
        collectionCreationFee: string,
        NFTMintingFee: string,
        NFTListingFee: string,
        MPCommission: Number,
        royaltyCommission: Number,
        NFTSellingFee: string,
        CommissionsContractAddr: string
    ) {    
        const contractInstance = new this.web3.eth.Contract(this.contract.ABI, CommissionsContractAddr);
        
        return contractInstance.methods.setCommissionAndFees_NU(
            this.web3.utils.toWei(whiteListingFee, "ether"),
            this.web3.utils.toWei(collectionCreationFee, "ether"),
            this.web3.utils.toWei(NFTMintingFee, "ether"),
            this.web3.utils.toWei(NFTListingFee, "ether"),
            MPCommission,
            royaltyCommission,
            NFTSellingFee
        ).send({ from: MSAdmin });
    }
    
    // SettingUp the commissions and Fees for GoldenBadgeUser
    async setCommissionAndFees_GB(
        MSAdmin: string,
        collectionCreationFee: string,
        NFTMintingFee: string,
        NFTListingFee: string,
        MPCommission: Number,
        royaltyCommission: Number,
        NFTSellingCommission: string,
        CommissionsContractAddr: string
    ) {      
        const contractInstance = new this.web3.eth.Contract(this.contract.ABI, CommissionsContractAddr);
        
        return contractInstance.methods.setCommissionAndFees_GB(
            this.web3.utils.toWei(collectionCreationFee, "ether"),
            this.web3.utils.toWei(NFTMintingFee, "ether"),
            this.web3.utils.toWei(NFTListingFee, "ether"),
            MPCommission,
            royaltyCommission,
            NFTSellingCommission
        ).send({ from: MSAdmin });
    }

    async setCustomListingFees(
        MSAdmin: string,
        NftUser: string,
        NFTListingFee: string,
        CommissionsContractAddr: string
    ) {      
        console.log("Listing Fee: " + this.web3.utils.toWei(NFTListingFee, "ether"));
        const contractInstance = new this.web3.eth.Contract(this.contract.ABI, CommissionsContractAddr);
        
        return contractInstance.methods.setCustomListingFees(
            NftUser,
            this.web3.utils.toWei(NFTListingFee, "ether"),
        ).send({ from: MSAdmin });
    }

    async setCustomSellingFees(
        MSAdmin: string,
        NftUser: string,
        NFTSellingFee: string,
        CommissionsContractAddr: string
    ) {      
        console.log("Listing Fee: " + this.web3.utils.toWei(NFTSellingFee, "ether"));
        const contractInstance = new this.web3.eth.Contract(this.contract.ABI, CommissionsContractAddr);
        
        return contractInstance.methods.setCustomSellingFees(
            NftUser,
            NFTSellingFee,
        ).send({ from: MSAdmin });
    }

    async setCustomMintingFees(
        MSAdmin: string,
        NftUser: string,
        NFTMintingFee: string,
        CommissionsContractAddr: string
    ) {      
        console.log("Minting Fee: " + this.web3.utils.toWei(NFTMintingFee, "ether"));
        const contractInstance = new this.web3.eth.Contract(this.contract.ABI, CommissionsContractAddr);
        
        return contractInstance.methods.setCustomMintingFees(
            NftUser,
            this.web3.utils.toWei(NFTMintingFee, "ether"),
        ).send({ from: MSAdmin });
    }

    async setCustomCollectionCreationFees(
        MSAdmin: string,
        NftUser: string,
        CollectionCreationFee: string,
        CommissionsContractAddr: string
    ) {      
        console.log("CollectionCreation Fee: " + this.web3.utils.toWei(CollectionCreationFee, "ether"));
        const contractInstance = new this.web3.eth.Contract(this.contract.ABI, CommissionsContractAddr);
        
        return contractInstance.methods.setcustomCollectionCreationFee(
            NftUser,
            this.web3.utils.toWei(CollectionCreationFee, "ether"),
        ).send({ from: MSAdmin });
    }

    async setCustomWhiteListingFees(
        MSAdmin: string,
        NftUser: string,
        WhiteListingFee: string,
        CommissionsContractAddr: string
    ) {     
        console.log("WhiteListing Fee: " + this.web3.utils.toWei(WhiteListingFee, "ether"));
        const contractInstance = new this.web3.eth.Contract(this.contract.ABI, CommissionsContractAddr);
        
        return contractInstance.methods.setCustomWhiteListingFee(
            NftUser,
            this.web3.utils.toWei(WhiteListingFee, "ether"),
        ).send({ from: MSAdmin });
    }
}
