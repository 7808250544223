/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Divider, Pagination, Select, notification } from 'antd';
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { AdminService } from '../../../../components/services/AdminService/Admin.service';
import authContext from '../../../../components/Shared/Auth.service';
import { MetamaskService } from '../../../../components/Shared/Metamask.service';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

const adminService = new AdminService();
const useSelectedWalletContext = () =>
  new MetamaskService().useSelectedWalletContext();

function KYCApprovals(props) {
  const { setLoading, loading } = props || {};
  const { userInfo } = useContext(authContext);
  const { networkId } = useSelectedWalletContext();

  const [pendingKYC, setPendingKYC] = useState([]);
  const [completedKYC, setCompletedKYC] = useState([]);
  const [sortByVal, setSortByVal] = useState("Pending");
  const rejectedKYC = useMemo(() => {
    return completedKYC?.filter((kyc) => !kyc.approved)
  }, [completedKYC]) || [];
  const filteredCompletedKYC = useMemo(() => {
    return completedKYC?.filter((kyc) => kyc.approved)
  }, [completedKYC]) || [];
  const filteredKYCs = sortByVal === "Approved" ? filteredCompletedKYC : sortByVal === "Pending" ? pendingKYC : rejectedKYC;
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 12;
  const history = useHistory();
  const [goToPage, setGoToPage] = useState(1);
  const totalPages = Math.ceil(filteredKYCs?.length / pageSize);

  const onPageChange = (page) => {
    setCurrentPage(page);
    setGoToPage(page);
  };

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const currentPageData = filteredKYCs.slice(startIndex, endIndex);

  useEffect(async()=>{
    setLoading(true);

    if(!networkId) return;

    setTimeout(async()=>{
        if(userInfo?._id && userInfo?.role === 'marketplace-compliance-admin') {
          try {
            let res = await adminService.getPendingKYC(userInfo?._id);
            setPendingKYC(res.data.reverse());


            res = await adminService.getCompletedKYC(userInfo?._id);
            setCompletedKYC(res.data.reverse());

            setLoading(false);
          } catch (error) {
            console.log(error);
            notification.open({
              message: 'Error',
              description: 'something went wrong while fetching KYC Data',
              duration: 0,
            });
          }
        }else{
            //return history.push('/account/login');
        }
    }, 400)
  },[userInfo, networkId])


  const handleChange = (value) => {
    setSortByVal(value);
    setCurrentPage(1)
  }

  const handleGoToPage = () => {
    const pageNumber = parseInt(goToPage);
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  return (
    !loading && <>
      <p className='market-super-admin commission-configuration-text'>KYC Approval</p>
      <div className='market-super-admin admin-invites card-container d-flex justify-content-center flex-column'>
        <div className='market-super-admin card-inner-container' style={{ width: '100%', padding: '20px' }}>
          <div className='d-flex col-12'>
            <div className='d-flex justify-content-center align-items-center'>
              {sortByVal === "Approved" && <img className="market-place-admin status-icon" src={"/img/admin-dashboard/approved.png"} alt="nftArt" />}
              {sortByVal === "Rejected" && <img className="market-place-admin status-icon" src={"/img/admin-dashboard/rejected.png"} alt="nftArt" />}
              {sortByVal === "Pending" && <img className="market-place-admin status-icon" src={"/img/admin-dashboard/pending.png"} alt="nftArt" />}
              <p className='wallet-status-text'>{sortByVal}</p>
            </div>
            <div className='d-flex' style={{ marginLeft: 'auto' }}>
              <div className='sort-by-text'>
              <img
                  className="market-place-admin status-icon"
                  src={"/img/admin-dashboard/sort-icon.png"}
                  alt="nftArt"
                />
              Sort By:</div>
              <Select
                className='sort-by-select'
                defaultValue={sortByVal}
                onChange={handleChange}
                options={[
                  {
                    value: 'Pending',
                    label: 'Pending',
                  },
                  {
                    value: 'Approved',
                    label: 'Approved',
                  },
                  {
                    value: 'Rejected',
                    label: 'Rejected',
                  },
                ]}
              />
            </div>
          </div>
          <Divider />
          <div className='d-flex flex-wrap justify-content-evenly'>
            {currentPageData?.length > 0 ?
              currentPageData.map((kyc) => (
                <div className='market-super-admin wallet-card-component'>
                  <div className='d-flex flex-column'>
                    {/* <p className='wallet-nft-user-text'>nft-user</p> */}
                    <img
                      className="wallet-kycDetails-selfie"
                      src={kyc?.selfieURL}
                      alt=""
                    />
                    <div className="act_list_text">
                      <h4 className="wallet-fullname-text">
                        {kyc?.firstName + " " + kyc?.lastName}
                      </h4>
                      <span className="wallet-email-text">
                        {kyc?.email}
                      </span>
                      <span className="wallet-contactNumber-text">
                        {moment(kyc.dateOfSubmission).format(
                          "dddd, MMMM YYYY"
                        )}
                      </span>

                      {
                        sortByVal !== "Pending" ?
                          kyc.approved === true
                            ? <Button style={{fontWeight:"bolder"}}  className="wallet-status-approved-chip"><img className="market-place-admin sidenav-icon" src={"/img/admin-dashboard/approved.png"} alt="nftArt" />
                              <p>Approved</p></Button>
                            : <Button style={{fontWeight:"bolder"}}  className="wallet-status-rejected-chip"><img className="market-place-admin sidenav-icon" src={"/img/admin-dashboard/rejected.png"} alt="nftArt" />
                              <p>Rejected</p></Button>
                          : null
                      }

                      {
                        sortByVal === "Pending" && <Button style={{fontWeight:"bolder"}}  className="wallet-status-pending-chip"><img className="market-place-admin sidenav-icon" src={"/img/admin-dashboard/pending.png"} alt="nftArt" />
                          <p>Pending</p></Button>
                      }

                      <p className='kyc-approvals view-more-text' onClick={() => {
                        history.push(
                          `/marketplace-admin/kyc/${kyc._id}`
                        );
                      }}>View More</p>

                    </div>
                  </div>
                </div>
              ))
              : <div className='no-data-found-div'>
                <p className='no-data-found-text'>No Data Found</p>
              </div>
            }
          </div>
        </div>

        {
          filteredKYCs?.length ? <div className='paginationContainer'>
            <Pagination
              current={currentPage}
              total={filteredKYCs?.length}
              pageSize={pageSize}
              onChange={onPageChange}
            />
            <input
              type="number"
              value={goToPage}
              onChange={(e) => setGoToPage(e.target.value)}
              min="1"
              max={totalPages}
              className='goInput'
            />
            <button
              onClick={handleGoToPage}
              className='goButton'
            >
              Go
            </button>
          </div>
            : null
        }

      </div>
    </>
  )
}

export default KYCApprovals
