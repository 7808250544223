import React, { useContext, useState, useEffect } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import styled from "styled-components";
import { Spin, notification, Input, Button, Popconfirm } from "antd";
import Clock from "../components/Clock";
import { createGlobalStyle } from "styled-components";
import authContext from "../components/Shared/Auth.service";
import { NFTCreatorService } from "../components/services/NFTCreator/NFTCreator.service";
import { VideoPlayer } from "../components/mainSlider/VideoPlayer";
import ProfilePic from "./../assets/images/home/profile-default.png";
import { SharedService } from "../components/Shared/Shared.service";
import "./styles/styles.scss";
import NFTCard from "../components/NFTCardComponent";
import { MetamaskService } from "../components/Shared/Metamask.service";
import './styles/allNFT.scss';

const nftService = new NFTCreatorService();
const sharedService = new SharedService();

const useSelectedWalletContext = () =>
  new MetamaskService().useSelectedWalletContext();

const SoldNFTPage = function () {
  const { userInfo, setUserInfo } = useContext(authContext);

  const [soldNFT, setSoldNFT] = useState(null);
  const [loading, setLoading] = useState(true);
  const [todayEtheriumPriceInUSD, setTodayEtheriumPriceInUSD] = useState();
  const { networkId } = useSelectedWalletContext();

  const history = useHistory();

  useEffect(() => {
    if(!networkId) return;
    setTimeout(async () => {
      setLoading(true);
      let res = await nftService.getSoldNFT({networkId});
      if (res.data) setSoldNFT(res.data);

      setLoading(false);
      //setting ether price
      setTodayEtheriumPriceInUSD(await sharedService.getEtheriumPrice());
    }, 300);
  }, [userInfo, networkId]);

  return (
    <>
      {loading && (
        <div
          className="center"
          style={{ textAlign: "center", margin: "100px 0" }}
        >
          <Spin size="large" />
        </div>
      )}
      {!loading && (
        <div className="row">
          <div className="banner-big" style={{ backgroundImage: "url('/img/banner/all-nft-page-banner.png')" }}>
            <p className="banner-heading">NFT Arts</p>
            <p className="banner-text">Experience the future of ownership and explore the limitless possibilities of blockchain-based digital art with NFT marketplace.</p>
          </div>

          <div className="row col-12 start-section">
            <div className="col-sm-12 col-md-4 tabs-section">
              <div className="col-3 single-tab" onClick={()=> history.push('/all-nft')}>All NFTs</div>
              <div className="col-3 single-tab" onClick={()=> history.push('/new-nft')}>New NFTs</div>
              {/* <div className="col-3 single-tab selected" onClick={()=> history.push('/sold-nft')}>Sold NFTs</div> */}
            </div>

            <div className="col-12 col-sm-12 col-md-8 search-container"></div>
          </div>

          <div className="row col-12 nft-grid-container">
            {soldNFT?.map((nft, index) => (
              <div className="itm col-sm-12 col-md-4 col-lg-3 col-xl-3" key={index}>
                <div className="d-item">
                  <div
                    className={`nft__item nft-item-buy`}
                    style={{
                      margin: "3px",
                      padding: "3px",
                      border: "none",
                      backgroundColor: "transparent",
                    }}
                  >
                    <div className="author_list_pp">
                      <span>
                        {/* // onClick={()=> window.open("/home1", "_self")                                    */}
                        <img
                          className="lazy"
                          src={
                            nft.profilePicUrl
                              ? nft.profilePicUrl
                              : ProfilePic
                          }
                          alt=""
                        />
                        <i className="fa fa-check"></i>
                      </span>
                    </div>
                    <NFTCard
                      nft={nft}
                      todayEtheriumPriceInUSD={
                        todayEtheriumPriceInUSD
                      }
                      buy={false}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {!loading &&
        (!soldNFT || soldNFT?.length < 1) && (
          <div className="row">
            <h5 style={{ textAlign: "center" }}>
              No NFT found on this page.
            </h5>
          </div>
        )}
    </>
  );
};
export default SoldNFTPage;
