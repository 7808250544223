import React from "react";
import "./learn.scss";
import { environment } from "../../../components/environments/environment";

const Learn1 = () => {
  return (
    <div className="container learn-container learn1">
      <h3 class="blog-heading">
        Step to step guide to registering on our NFT marketplace
      </h3>
      <img
        className="blog-image horizontal-middle-align"
        src={`/img/learns/learnPageIcon1.png`}
        alt="learn-img"
        /* style={{ width: "600px", height: "550px" }}  */
      />
      <p>
        Let's take a closer look at the beautiful NFT world. These are the one
        of a kind nonfungible tokens that have been registered on ablockchain
        platform and whose cryptographic hash tag indicates their authenticity
        and ownership. In a marketplace for NFTs, you can create, mint,
        catalogue, market and buy NFTs.
      </p>
      <h4>
        To successfully install Metamask on Chrome, you need to follow a few
        simple steps.{" "}
      </h4>
      <p>
        Metamask is an essential component for registration, KYC whitelisting,
        and other activities on the NFT platform. To download it, click "Add to
        Chrome" and then "Add Extension." Open Metamask and agree to the terms
        of use. Create a new wallet by entering a new password and confirming
        it. Verify the secret recovery phrase and store it in a safe place.
        Complete the installation process by clicking "Got it!" and "Next" until
        the "Done" screen.
      </p>
      <h4>How to get Registered</h4>
      <ul>
        <li>
          {environment.envName === "qart" && (
            <strong>Access to the NFT platform: </strong>
          )}
          {environment.envName === "pixentro" && (
            <strong>Access to the NFT trading platform: </strong>
          )}
        </li>
      </ul>
      <p>
        Just visit the provided link and connect your MetaMask wallet to start
        interacting with the platform. Once you are on the website, find and
        click the "Register" button to begin the registration process.
      </p>
      <ul>
        <li>
          <strong>Fill up the Registration form</strong>
        </li>
      </ul>
      <p>
        Fill in all your details such as first name, last name, email address,
        password, confirm password, country and phone number in the registration
        form provided. Make sure that all required fields are completed
        accurately before hitting "Register" to submit your information.
      </p>
      <ul>
        <li>
          <strong>Confirm your email address </strong>
        </li>
      </ul>
      <p>
        Once your details have been successfully submitted, you will receive a
        confirmation link in your email, click to confirm your email address and
        register.
      </p>
      <ul>
        <li>
          <strong>Login to the NFT Marketplace</strong>
        </li>
      </ul>
      <p>
        Now that you have verified your email address successfully, log in using
        your username and password created during registration. Congratulations!
        You're now registered on the NFT platform. Get ready to explore the
        marketplace filled with unique NFTs while engaging with like-minded
        individuals in this exciting community!
      </p>
      <h4>Once the registration is done Mandatory KYC is Needed</h4>
      <ul>
        <li>
          To access the KYC upload page, the creator must complete the KYC
          upload process, which can be accessed via email or on the website. For
          better user experience, it is recommended to use a mobile phone. On
          the mobile app, simply click on the "Start" button to initiate the KYC
          process
        </li>
        <li>
          Upon accessing the KYC upload page, necessary personal details must be
          entered. These include first name, middle name, last name; street
          address 1 and 2; city; postal code; state and country for both present
          and permanent addresses; as well as contact number and email address.
        </li>
        <li>
          Once all personal details have been entered correctly, proceed by
          clicking on the "Next" button to move onto selecting appropriate
          identification options such as Passport or Driving License.
        </li>
        <li>
          After selecting your preferred ID verification method and submitting
          accordingly, you must log in to your marketplace account and sign
          their User Agreement by clicking 'I Agree' after clicking on "Start".
          Once completed successfully an email will be sent confirming
          successful signing of User Agreement.
        </li>
        <li>
          Upon completion of this step a message stating that "KYC Successfully
          submitted!! Once your KYC is approved by NFT Marketplace admin, you
          will receive an email and you will be able to continue" is displayed.
        </li>
        <li>
          The submitted KYC details are then reviewed by a Marketplace Super
          Admin. If all information has been provided correctly then approval
          shall be given for continuation of activities. Following this
          confirmation an email shall confirm successful update of KYC has been
          sent to creator's email address.
        </li>
        <li>
          By following these steps creators can complete their NFT marketplace's
          KYC process with ease while awaiting approval from marketplace admin
          without any hassle or issues arising during this time period.
        </li>
      </ul>
    </div>
  );
};

export default Learn1;
