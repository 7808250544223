import React, { useState } from 'react';
import { Radio, Tabs } from 'antd';
import HelpCenterImg from "./../assets/images/help/help_page_banner.png";
import ActiveIcon from "../../../src/assets/help_page_icon.png"
import { environment } from '../../components/environments/environment';
import './styles/helpCenter.scss';

const { TabPane } = Tabs;

const TabContent = ({ label, content }) => (
  <div className="help_center_card card">
    <div className="card-body bgBox">
      <h3 className='label_head' style={{fontFamily:'poppins',fontWeight:"700", fontSize:"24px" , color:"#050B37"}}>{label}</h3>
      <div className="ulBox">
        <ul style={{ padding: '0 20px' }}>
          {content.map((item, index) => (
            <li key={index} className="listText" style={{ listStyleType: "disc", marginBottom: "10px",fontFamily:'poppins', fontWeight:"400", fontSize:"16px" }}>
              {item}
            </li>
          ))}
        </ul>
      </div>
    </div>
  </div>
);

const HelpCenter = () => {
  const { companyName } = environment || {};
  const [mode, setMode] = useState('left');
  const [activeKey, setActiveKey] = useState("0");

  const handleModeChange = (e) => {
    setMode(e.target.value);
  };

  const handleTabChange = (key) => {
    setActiveKey(key);
  };

  const items = [
    {
      label: "How to Get Registered?",
      key: "0",
      content: [
        `Non-fungible tokens (NFTs) are unique digital assets representing ownership of various forms of digital media on the blockchain. Unlike cryptocurrencies, each NFT is distinct and cannot be exchanged for a Access ${environment.companyName}'s NFT platform by visiting the provided link and connect your MetaMask wallet.`,
        "Click 'Register' on the website, complete the registration form accurately, and hit 'Register.'",
        "Confirm your email by clicking the link sent to your email.",
        `Log in using your registered credentials. Congratulations, you're now part of the  ${environment.companyName} NFT community!`
      ],
    },
    {
      label: "Mandatory KYC Process?",
      key: "1",
      content: [
        "Complete the KYC upload process via email or the website, preferably on a mobile phone for a better experience.",
        "Enter personal details, select an ID verification method, and sign the User Agreement on the mobile app.",
        "Await email confirmation of successful KYC submission.",
        ` ${environment.companyName}'s Super Admin reviews the KYC details, approves them, and sends an email confirming the update.`
      ],
    },
    {
      label: `How to do Whitelisting of the Wallet on ${environment.companyName}?`,
      key: "2",
      content: [
        "After KYC approval, proceed with wallet whitelisting.",
        "Log in and pay the Wallet Whitelisting Fee at the respective gas price.",
        "Confirm the transaction in MetaMask.",
        "Receive a message stating your whitelisting request is pending admin approval.",
        `Upon approval, a confirmation email is sent, and you gain access to specific ${environment.companyName} platform privileges.`
      ],
    },
    {
      label: `How to Create an NFT on ${environment.companyName}?`,
      key: "3",
      content: [
        "After registration, KYC, and whitelisting, creators can start NFT creation.",
        "Choose 'Create' and input details for Fixed Price, Auction, or Open for Bids.",
        "Specify relevant details for each selling method.",
        "For a new NFT collection, click 'Create (ERC 721),' upload a thumbnail image, provide details, and save.",
        "Add a copyright notice, watermark, and pay collection fees.",
        "Place the submission for admin verification and await email confirmation of successful verification."
      ],
    },
    {
      label: `How to Mint Your NFT on ${environment.companyName}?`,
      key: "4",
      content: [
        "After completing previous steps, creators can mint their NFT.",
        "Click 'Mint' and sign the NFT Buy and Sell Agreement.",
        "Receive and sign the Agreement confirmation email.",
        `Upon successful completion, your NFT is officially minted and ready for sale on ${environment.companyName}.`
      ],
    },
    {
      label: "Participating in a Timed Auction for NFTs?",
      key: "5",
      content: [
        "For buyers using Fixed Price:",
        "Register, complete KYC, and whitelist your wallet.",
        `Browse available NFTs on ${environment.companyName} and click 'Buy Now' on your chosen NFT.`,
        "Review details, sign the agreement, and receive an email confirmation.",
        `After successful payment, receive a confirmation email from ${environment.companyName}.`
      ],
    },
    {
      label: `How a Buyer Purchases an NFT using Fixed Price on ${environment.companyName}?`,
      key: "7",
      content: [
        "For buyers using Fixed Price:",
        "Register, complete KYC, and whitelist your wallet.",
        `Browse available NFTs on ${environment.companyName} and click 'Buy Now' on your chosen NFT.`,
        "Review details, sign the agreement, and receive an email confirmation.",
        `After successful payment, receive a confirmation email from ${environment.companyName}.`
      ],
    },
    {
      label: `Placing NFT for Sale on ${environment.companyName}?`,
      key: "8",
      content: [
        "After minting, put your NFT up for sale.",
        `Click 'Place for Sell' to initiate the sale process on ${environment.companyName}.`
      ],
    },
    {
      label: "How to Purchase an NFT through Place a Bids?",
      key: "9",
      content: [
        "For buyers using Place a Bids:",
        "Register, complete KYC, and whitelist your wallet.",
        "Browse NFTs, click 'Buy Now,' and place a bid.",
        "Sign the agreement, make payment, and receive a confirmation email."
      ],
    },
  ];

  const getTabContent = (key) => {
    const item = items.find(item => item.key === key);
    return item ? item : { label: "", content: [] };
  };

  const renderTabLabel = (item) => (
    <span>
        {activeKey === item.key && <img src={ActiveIcon} alt="Active Icon" style={{ position:"relative", left:"540px" }} />}
      {item.label}
    
    </span>
  );
  

  return (
    <div className="help-new-tab-page" style={{background:"white"}}>
      <div className="d-flex justify-content-center" style={{ marginBottom: '20px' }}>
        <img src={HelpCenterImg} alt="HelpCenter" className="HelpCenter image" />
      </div>
      <Radio.Group
        onChange={handleModeChange}
        value={mode}
        style={{ marginBottom: 8 }}
      >
      </Radio.Group>
      <div>
        <h2 className='help-center_topic'>Topics</h2>
        <Tabs
          defaultActiveKey="0"
          tabPosition={mode}
          onChange={handleTabChange}
        >
          {items.map(item => (
            <TabPane tab={renderTabLabel(item)} key={item.key} style={{fontSize:"20px",fontFamily:'poppins', fontWeight:"500"}}>
            <TabContent label={getTabContent(activeKey).label} content={getTabContent(activeKey).content} />
          </TabPane>
          
          ))}
        </Tabs>
      </div>
    </div>
  );
};

export default HelpCenter;
