import React from 'react';
import { useHistory } from "react-router-dom";
import { CCarousel, CCarouselItem, CImage } from '@coreui/react';
import Banner from "../../../components/assets/images/faq/blog_banner.png";
import './blogs.scss';



const Blogs = () => {
    const history = useHistory();

    const carousal_head = {
        background: "orange",
        width: "80px",
        fontSize: "larger",
        borderRadius: "25px",
        padding: "12px 12px 12px 19px",
        color: "white",
        fontFamily: "Poppins",
    };
    const carousal_para1 = {
        color: "#050D39",
        fontSize: "32px",
        fontWeight: "600",
        lineHeight: "40px"
    };
    const carousal_para2 = {
        marginTop: "200px",
        color: "rgba(115, 115, 115, 1)"
    };

    const blog_card_h1 = {
        left: "15px",
        position: "relative",
        top: "-20px",
        background: "orange",
        width: "60px",
        fontSize: "larger",
        borderRadius: "33px",
        padding: "10px",
        color: "white",
        fontFamily: "'Poppins'"
    };

    const blog_date = {
        color: "rgba(115, 115, 115, 1)"
    }


    return (
        <div className='col-12 blogs-container'>
            <div className='col-12'>
                <img src={Banner} alt='Letters' style={{ height: "210px", width: "100%", marginTop: "27px" }} />
            </div>
            <CCarousel controls indicators>
                <CCarouselItem>
                    <CImage className="d-block w-100" src="path_to_slide1_image" alt="" />
                    <div className="d-none d-md-block carosal"   >

                        <div className='carousal_container' onClick={() => history.push(`/blogs/blog1`)} >

                            <div className='carousal_container_left '>
                                <h5 style={carousal_head}>Blog</h5>
                                <p style={carousal_para1}>NFT revolution-The Nft's - Multifaceted World of Digital Assets</p>
                                <p style={carousal_para2}>Mon 22 Apr 2022</p>
                            </div>
                            <div className='carousal_container_right'>
                                <img src={`/img/blogs/blog1.png`} alt='carosl_image' className='carousal_container_right_img' />
                            </div>


                        </div>
                    </div>
                </CCarouselItem>
                <CCarouselItem>
                    <CImage className="d-block w-100" src="path_to_slide1_image" alt="" />
                    <div className="d-none d-md-block carosal"   >

                        <div className='carousal_container' onClick={() => history.push(`/blogs/blog2`)}>

                            <div className='carousal_container_left '>
                                <h5 style={carousal_head}>Blog</h5>
                                <p style={carousal_para1}>Bringing Changing 2023 - How is NFT technology bringing a change in 2023?</p>
                                <p style={carousal_para2}>Mon 22 Apr 2022</p>
                            </div>
                            <div className='carousal_container_right'>
                                <img src={`/img/blogs/blog2.png`} alt='carosl_image' className='carousal_container_right_img' />
                            </div>


                        </div>
                    </div>
                </CCarouselItem>
                <CCarouselItem>
                    <CImage className="d-block w-100" src="path_to_slide1_image" alt="" />
                    <div className="d-none d-md-block carosal"   >

                        <div className='carousal_container' onClick={() => history.push(`/blogs/blog3`)}>

                            <div className='carousal_container_left ' >
                                <h5 style={carousal_head}>Blog</h5>
                                <p style={carousal_para1}>NFT Wallets - Storing and Managing Non-Fungible Tokens</p>
                                <p style={carousal_para2}>Mon 22 Apr 2022</p>
                            </div>
                            <div className='carousal_container_right'>
                                <img src={`/img/blogs/blog3.png`} className='carousal_container_right_img' alt='carosl_image' />
                            </div>


                        </div>
                    </div>
                </CCarouselItem>
                <CCarouselItem>
                    <CImage className="d-block w-100" src="path_to_slide1_image" alt="" />
                    <div className="d-none d-md-block carosal"   >

                        <div className='carousal_container' onClick={() => history.push(`/blogs/blog4`)}>

                            <div className='carousal_container_left '>
                                <h5 style={carousal_head}>Blog</h5>
                                <p style={carousal_para1}>Exploring NFT Gaming - A World of Opportunities and Innovations in the Gaming Industry</p>
                                <p style={carousal_para2}>Mon 22 Apr 2022</p>
                            </div>
                            <div className='carousal_container_right'>
                                <img src={`/img/blogs/blog4.png`} alt='carosl_image' className='carousal_container_right_img' />
                            </div>


                        </div>
                    </div>
                </CCarouselItem>

            </CCarousel>

            <div className='row col-12 mt-5 mb-5' style={{ marginLeft:"25px" }}>
                <div className="col-md-4 col-lg-3">
                    <div className="card blog_page_hover"
                        onClick={() => history.push(`/blogs/blog1`)}
                        style={{ cursor: "pointer", width: "300px" }}
                    >
                        <img
                            src={`/img/blogs/blogIcon4.png`}
                            className="card-img-top"
                            alt="category-img"
                            style={{ height: "157px", width: "300px" }}
                        />
                        <h1 className='blog_card' style={blog_card_h1}>Blog</h1>
                        <div className="card-body blogs-title">
                            <h5 className="card-title ">NFT revolution</h5>
                            <p className='card-paragraph'>The Nft's: Multifaceted World of Digital Assets</p>
                            <p style={blog_date}>Mon 22 Apr 2022</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 col-lg-3 mb-4">
                <div className="card blog_page_hover"
                        onClick={() => history.push(`/blogs/blog2`)}
                        style={{ cursor: "pointer", width: "300px" }}
                    >
                        <img
                            src={`/img/blogs/blogIcon3.png`}
                            className="card-img-top"
                            alt="category-img"
                            style={{ height: "157px", width: "300px" }}
                        />
                        <h1 className='blog_card' style={blog_card_h1}>Blog</h1>
                        <div className="card-body blogs-title">
                            <h5 className="card-title ">Bringing Change 2023: </h5>
                            <p className='card-paragraph'>How is NFT technology bringing a change in 2023?</p>
                            <p style={blog_date}>Mon 22 Apr 2022</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 col-lg-3 mb-4">
                <div className="card blog_page_hover"
                        onClick={() => history.push(`/blogs/blog3`)}
                        style={{ cursor: "pointer", width: "300px" }}
                    >
                        <img
                            src={`/img/blogs/blogIcon2.png`}
                            className="card-img-top"
                            alt="category-img"
                            style={{ height: "157px", width: "300px" }}
                        />
                        <h1 className='blog_card' style={blog_card_h1}>Blog</h1>
                        <div className="card-body blogs-title">
                            <h5 className="card-title ">NFT Wallets: </h5>
                            <p className='card-paragraph'>Storing and Managing Non-Fungible Tokens</p>
                            <p style={blog_date}>Mon 22 Apr 2022</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 col-lg-3 mb-4">
                <div className="card blog_page_hover"
                        onClick={() => history.push(`/blogs/blog4`)}
                        style={{ cursor: "pointer", width: "300px" }}
                    >
                        <img
                            src={`/img/blogs/blogIcon1.png`}
                            className="card-img-top"
                            alt="category-img"
                            style={{ height: "157px", width: "300px" }}
                        />
                        <h1 className='blog_card' style={blog_card_h1}>Blog</h1>
                        <div className="card-body blogs-title">
                            <h5 className="card-title ">Exploring NFT Gaming:</h5>
                            <p className='card-paragraph'> A World of Opportunities and Innovations in the Gaming Industry</p>
                            <p style={{...blog_date ,marginTop:"-22px"}}>Mon 22 Apr 2022</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Blogs;
