import React from 'react'

const CompanyContentQArt = () => {
  return (
    <div className='container company-content'>
        <h4>Mission</h4>
        <p>
            Our mission is to make the world a showcase for change through art and artistic expression used to capitalize social economics projects and programs. 
        </p>
        <h4>A Q-Vision</h4>
        <p>
            Our vision is to serve a bridge between sustainable development, change entire neighborhoods, and bring beauty back to live among us without discrimination. 
        </p>
        <h4>Our Social Economic Strategy</h4>
        <p>
            All the money that we make through the sale of our NFTs is to be invested in projects, or programs, of high social importance and impact such as sustainable infrastructure, social housing, and drinking water under public private partnership with local governments and municipalities. 
            <br/>
            We employ a unique social capitalism strategy to generate market rate earnings to our socially responsible investors and contributors. We work with financial planning consultants and expert asset managers to realize future earnings used for the delivery of our social economic projects and realize earnings to our investors. Our approach is to use all forms of capitalism to fill in the gap with social projects, helping the governments and the world. 
        </p>
        <h4>Our Social Investment Strategy</h4>
        <p>
            All the funds we raise for our projects are deposited into the QGF Charitable Trust in South Dakota which in turn is managed by our various asset management teams. Though we look forward to raising capital using crypto, our cash investments are placed in US Treasury securities mostly, and equities. Our asset management teams employ prudent and suitable strategies which allows us to generate outstanding market rate earnings. We can put you in contact with our asset management team so that they can themselves explain their strategies. 
        </p>

        <h4>Our projects: </h4>

        <ul class="list-style">
            <li>
                <h4>Sustainable infrastructure</h4>
                <p>Under public private partnerships, we invest in sustainable infrastructure which includes free Wi-Fi, off grid energy generation, black water and waste renewal technologies which allows us to work with public works and government planning boards to bring progress to our communities in real time, in time record.</p>

                <p>We have already identified amazing private sector companies which revolutionary technologies in these areas which we further combine with our choice of EPCM – Engineering, Procurement, Construction and Management company for the construction, installation, and management of the selected projects.</p>
            </li>

            <li>
                <h4>Neighborhood Renewal</h4>
                <p>Our vision is to be able to beautify and change poor neighborhoods infrastructure and housing using our Community & Neighborhood Renewal where we work with the local municipalities and the community to strategically buy their units outright at market rates, allow them to come bank to the same community once the new buildings are erected, and participate in the Q-Housing and Q-SME Programmed Savings plans which allows the civil society to save for the down payment of their new home, or start a new business.</p>

                <p>As part of our program, a socially responsible real estate investor may consider investing in a unit to better the lives of a family from a poor neighborhood in the world yet realize real earnings while feeling good helping a single mom, abandoned elderly, or a struggling family who needs an opportunity to live a better life.</p>
            </li>

            <li>
                <h4>Social Housing</h4>
                <p>We believe that each person alive should have suitable living conditions. We welcome and work with all global pro housing agencies to combine resources and co invest in social housing. Under our social housing program, we invite all socially responsible investors to consider investing in a unit, in any selected poor neighborhood in the world, where a local family will reside under terms and conditions where the family will cover a minimum of the cost.</p>

                <p>As part of our social economic programs, it is mandatory for our beneficiaries to obtain a free training from our Integral Technical Training Center which we also invest and build in the communities to train the citizens a trade, like the BRAC system in Bangladesh, which is an amazing initiative.</p>

                <p>We offer fractional unit investment as well as whole unit investment in exchange for a stead return generated from our asset management team’s work, not from the rental units. All the beneficiaries’ families will create their profile from which the investor will get to read their story and choose which family to help. For us is a real win-win since we guarantee you will put a beautiful smile in a lot of families faces.</p>
                <p>Also, it is important to highlight that we only invest in sustainable and progressive communities. We do not want to cookie cut and just replicate, we will stick to building sustainable communities in mixed use, mixed income structures. We have studied all the bad things generated by building bad neighborhoods, barrios, and favelas, they turn into slums. We invest in the people, then in the community, and then in housing, socially.</p>
            </li>

            <li>
                <h4>Affordable Housing</h4>
                <p>Each country needs a middle class who can afford and pay for the goods and services offered. Nonetheless, housing has been on the rise for years, followed by inflation and cost of living while minimum wage is stagnant and consumer credit being utilized to fill the necessity gap.</p>

                <p>Few governments have a solid affordable housing program with enough of resources to meet the increasing housing demand. We realize that the governments need help and again, under public private partnerships with the local housing authorities we are looking to combine their programs with ours in partnership with USAID and the European Union.</p>

                <p>We invest in Mixed Use, mixed income communities where there are several units side aside for affordable housing clients (90-10; 80-20; 70-30; 60-40). The units are purchased under strict terms and conditions.</p>
            </li>

            <li>
                <h4>Sustainable infrastructure</h4>
                <p>Under public private partnerships, we invest in sustainable infrastructure which includes free Wi-Fi, off grid energy generation, black water and waste renewal technologies which allows us to work with public works and government planning boards to bring progress to our communities in real time, in time record.</p>

                <p>We have already identified amazing private sector companies which revolutionary technologies in these areas which we further combine with our choice of EPCM – Engineering, Procurement, Construction and Management company for the construction, installation, and management of the selected projects.</p>
            </li>

            <li>
                <h4>Assisted Living</h4>
                <p>We feel for the families who have a loved one in need of permanent assistance. We want to dare to extend a helping hand to these families for the benefit of a loved one in need. It could happen to any of us. </p>

                <p>The QGF in partnership with USAID, the European union, and the local governments to provide a living subsidy assistance of equal share where each entity, the responsible next in keen (Father, mother, brother, a close friend, or any citizen willing to come in and help). Our investor’s money will be managed and invested by our selected asset managers and the benefit realized will be used to support the construction and development of projects and programs implementation.</p>
            </li>

            <li>
                <h4>Senior Housing</h4>
                <p>In the United States the current senior housing shortage keeps growing exponentially and the governments need help with establishing new programs and building more communities and we want to do just that, build more communities, under a mixed strategy, bringing together locals and foreigners into an exotic destination, in a friendly community, managed by an international experienced firm.</p>

                <p>Querubin Global foundation, Inc. has structured suitable programs so that the elder’s loved ones can come together and cover the monthly costs and expenses of an elderly person, a father, a mother, or both, who need living assistance in a state of the are development, managed by QGF and an experienced property manager. QGF promotes local development employing its social programs to first train and develop the local micro entrepreneurs. After the training, the micro entrepreneur is considered for a micro loan through our cooperative system alliance in each country.</p>

                <p>Our social and responsible investor’s money is invested in construction and infrastructure.</p>
            </li>

            <li>
                <h4>Sovereign Investment Crypto Funds</h4>
                <p>Our organization is ready, willing, and able to establish a public private partnership with governments, and municipalities to run and operate a Sovereign Investment Crypto Fund (s) for social economic investment strategies in a different country.</p>
            </li>

            <li>
                <h4>Agri communities</h4>
                <p>Food scarcity and shortage in the world in the raise. We are investing in Agri Community to promote food and animal farming, under mixed income, and employment opportunities for the local community trained by the Integral Technical Training Center and certified by Q-Agri, fare trade, and certified organic.</p>

                <p>The Q-Agri communities are beautiful housing complexes where we replace the usual golf course with food farming cared by local families. Each owner will receive a free portion of food produced in the complex for personal consumption. The surplus production will be either sold at premium in the marketplace or supply chain. </p>
            </li>

            <li>
                <h4>Ecotourism</h4>
                <p>The world needs reforestation, and we have found a way to do it while having fun it! We invest in eco-tourism facilities managed by Q-Eco in partnership with the local communities, trained and developed by our Integral Technical Training. As part of the activities, the visiting guests will have the opportunity to farm a tree!</p>
            </li>

            <li>
                <h4>Processing and packaging plants</h4>
                <p>We invest in processing and packaging plants and regional recollection centers, in partnership with local farmers and operated by the local community.</p>
            </li>

            <li>
                <h4>Fish Farms</h4>
                <p>We invest in fish farms to be operated by experienced fishermen of the community. We have partners and experts from Canada and Iceland as partners to train and develop the local community. Just as any other micro and small business, all fishermen business will benefit from our Q-programs including credit to promote the local economies.</p>
            </li>

            <li>
                <h4>Comprehensive Integral Technical Training</h4>
                <p>This is out training facilities which is no more than a live training facility in a real shopping center with all sorts of stores, hotels, restaurants, wine connoisseur, workshops, nursing, mechanical, robotics, computer, e-commerce, or any other suitable technical and essential training that enhances people’s abilities so that they can better their lives.</p>

                <p>The training will be provided by donated time by retired and active professionals volunteers. QGF will provide temporary housing.</p>
            </li>

            <li>
                <h4>Museum</h4>
                <p>We invest in the construction and development of museums of at. QGF has founded Q-Art to provide support to the local LIVING artist so that they can benefit in life of their great work. We are establishing public private partnerships with the ministries of culture and Beaty Arts to run and operate our museums.</p>
            </li>

            <li>
                <h4>Community Art Shops</h4>
                <p>We are brining Art to the communities, we are building art work shops in local communities to teach and art exhibits of local talents.</p>
            </li>

            <li>
                <h4>Libraries or Reading Rooms</h4>
                <p>We are investing in the construction of reading rooms or libraries in the communities. It is amazing to know that most cities around the world, in developing countries, do not have libraries or reading rooms. Owning a book in some of these countries is a luxury and owning a computer is a dream we can make come through.</p>
            </li>

            <li>
                <h4>Kids Parks</h4>
                <p>It is impressive to notice that in most countries there are no good parks for kids with the quality we have in our parks in the United States and Europe. Most municipalities lack the funding and resources to invest in parks for kids. We want to change that! We are investing in Q-Parks! We are establishing PPP with local municipalities to build and operate parks.</p>
            </li>

            <li>
                <h4>Hospitals</h4>
                <p>We invest in Geriatric, Kids and General Hospitals, again, with US and European quality standards under public private partnerships with the ministry of public health and with a private partnership with the nurse and doctor’s union but brining experts from all over the world to train and develop the locals.</p>
            </li>

            <li>
                <h4>Community Medical Centers</h4>
                <p>We believe that each community in the world must have a non-trauma community medical center opened 24 hours a day to attend the community in case of emergency or preventive care.</p>
            </li>

            <li>
                <h4>Laboratories</h4>
            </li>
            
        </ul>
      </div>
  )
}

export default CompanyContentQArt