import React, { useEffect, useState } from "react";
import {
  DatePicker,
  Input,
  Pagination,
  Select,
  notification,
} from "antd";
import moment from "moment";
import { AdminService } from "../../../../components/services/AdminService/Admin.service";
import { BellOutlined, SearchOutlined } from "@ant-design/icons";
const adminService = new AdminService();
const { RangePicker } = DatePicker;

const nftUsersStyleing = {
  container: {
    backgroundColor: "#FFFFFF",
    borderRadius: "14px",
    boxShadow: "0px 4px 4px 0px #00000040",
    margin: "20px",
    paddingTop: "40px",
  },
  tableHead: { backgroundColor: "#0F587A", color: "#FFFFFF" },
  tableRowOdd: { backgroundColor: "#FFF8E2" },
  emailIcon: {
    backgroundColor: "#1BB9CE",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "35px",
    height: "30px",
    borderRadius: "8px",
    color: "white",
    marginLeft: "8px",
  },
  paginationContainer: {
    display: "flex",
    justifyContent: "end",
    padding: "30px",
  },
  paginationRender: {
    width: "180px",
    height: "41px",
    borderRadius: "8px",
    border: "2px solid #102253",
    display: "flex",
    gap: "10px",
    alignItems: "center",
    justifyContent: "center",
  },
  goInput: {
    border: "2px solid #102253",
    width: "71px",
    marginLeft: "8px",
  },
  goButton: {
    background: "#EB532D",
    marginLeft: "8px",
    borderRadius: "8px",
    width: "64px",
    height: "55px",
  },
  searchButton: {
    width: "60px",
    height: "55px",
    background: "#1BB9CE",
    marginLeft: "8px",
    borderRadius: "8px",
  },
};
const NftUsers = ({ loading, setLoading }) => {
  const [nftUsersData, setNftUsersData] = useState([]);
  const [kycStatusFilter, setKycStatusFilter] = useState("all");
  const [dateRangeFilter, setDateRangeFilter] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [inputSearchTerm, setInputSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [goToPage, setGoToPage] = useState(1);
  const pageSize = 10;

  const fetchData = async () => {
    // getting nft users list
    setLoading(true);
    try {
      let res = await adminService.getAllInvestorDetails();
      if (res?.data?.length > 0) {
        setNftUsersData(res.data.reverse());
      } else{
        setNftUsersData([])
      }
    } catch (error) {
      console.log(error);
      notification.open({
        message: "Error",
        description: "something went wrong while fetchData",
        duration: 0,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setCurrentPage(1);
    setGoToPage(1);
  }, [kycStatusFilter, dateRangeFilter, searchTerm]);

  const sendRemiderKYCEmail = async (email) => {
    setLoading(true);
    try {
      let res = await adminService.sendReminderKYCEmail(email);
      if (res.success) {
        notification.open({
          message: "Successfully Send Reminder Email",
          duration: 0,
        });
      }
    } catch (error) {
      console.log(error);
      notification.open({
        message: "Error",
        description: "something went wrong while sending reminder email",
        duration: 0,
      });
    }
    setLoading(false);
  };

  const filteredData = nftUsersData.filter((user) => {
    if (kycStatusFilter !== "all" && user.status.KYC !== kycStatusFilter) {
      return false;
    }

    // Apply date range filter
    if (dateRangeFilter) {
      const registrationDate = moment(user.creationTS);
      if (
        registrationDate.isBefore(dateRangeFilter[0].startOf("day")) ||
        registrationDate.isAfter(dateRangeFilter[1].endOf("day"))
      ) {
        return false;
      }
    }

    // Apply search term filter
    const searchTermLowerCase = searchTerm.toLowerCase();
    if (
      !user.firstName.toLowerCase().includes(searchTermLowerCase) &&
      !user.lastName.toLowerCase().includes(searchTermLowerCase) &&
      !user.email.toLowerCase().includes(searchTermLowerCase)
    ) {
      return false;
    }

    return true;
  });

  const totalPages = Math.ceil(nftUsersData.length / pageSize);
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const currentPageData = filteredData.slice(startIndex, endIndex);

  const handleGoToPage = () => {
    const pageNumber = parseInt(goToPage);
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const onPageChange = (page) => {
    setCurrentPage(page);
    setGoToPage(page);
  };

  const handleSearchInputChange = (value) => {
    setInputSearchTerm(value);
  };

  const handleSearch = () => {
    setSearchTerm(inputSearchTerm);
    setCurrentPage(1);
  };

  return (
    !loading && (
      <>
        <p className="market-super-admin commission-configuration-text">
          NFT Users
        </p>
        <div style={nftUsersStyleing.container} className="admin-invites">
          <div
            style={{ display: "flex", justifyContent: "end", alignItems:"center", margin: "8px 8px 10px 20px" }}
          >
          <div style={{display:"flex" }}>
            <Input
              placeholder="Search by First Name, Last Name, or Email"
              onChange={(e) => handleSearchInputChange(e.target.value)}
              onPressEnter={handleSearch}
            />
            <button
              type="primary"
              onClick={() => handleSearch(searchTerm)}
              style={nftUsersStyleing.searchButton}
            >
              <SearchOutlined style={{ color: "white", fontSize: "20px" }} />
            </button>
          </div>
            <div style={{
                borderRadius: "4px",
                border: "1px solid #BCBCBC",
                marginLeft: "9px",
                // height: "55px",
              }} >
            <RangePicker
              onChange={(dates) => setDateRangeFilter(dates)}
              className="inputCustom"
            />
            </div>
            <div className="d-flex" style={{ marginLeft: "8px" }}>
              <div className="sort-by-text">
                <img
                  className="market-place-admin status-icon"
                  src={"/img/admin-dashboard/sort-icon.png"}
                  alt="nftArt"
                />
                Sort By :
              </div>
              <Select
                className="sort-by-select"
                defaultValue="All Kyc"
                onChange={(value) => setKycStatusFilter(value)}
                options={[
                  {
                    value: "all",
                    label: "All KYC",
                  },
                  {
                    value: "approved",
                    label: "Approved",
                  },
                  {
                    value: "pending",
                    label: "Pending",
                  },
                  {
                    value: "rejected",
                    label: "Rejected",
                  },
                ]}
              />
            </div>
          </div>
          {filteredData?.length > 0 ? (
            <>
            <div style={{ overflowX: "auto" }}>
              <table className="table table-striped">
                <thead style={nftUsersStyleing.tableHead}>
                  <tr>
                    <th style={{paddingLeft:"30px"}} scope="col">No</th>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Registration Date</th>
                    <th scope="col">Phone</th>
                    <th scope="col">KYC</th>
                    <th scope="col">Wallet Whitelisted</th>
                    <th scope="col">Total NFT</th>
                  </tr>
                </thead>
                <tbody>
                  {currentPageData.map((nftData, index) => (
                    <tr
                      style={
                        index % 2 === 0 ? nftUsersStyleing.tableRowOdd : {}
                      }
                      key={nftData._id}
                    >
                      <th style={{paddingLeft:"40px"}} scope="row">{startIndex + index + 1}</th>
                      <td>
                        {nftData?.firstName} &nbsp;
                        {nftData?.lastName}
                      </td>
                      <td>{nftData?.email}</td>
                      <td>
                        {moment(nftData?.creationTS).format("DD/MM/YYYY")}
                      </td>
                      <td>{nftData?.phone}</td>
                      <td style={{ display: "flex" }}>
                        {nftData?.status?.KYC}
                        {nftData?.status?.KYC === "pending" && (
                          <div style={nftUsersStyleing.emailIcon}>
                            <BellOutlined
                              onClick={() =>
                                sendRemiderKYCEmail(nftData?.email)
                              }
                            />
                          </div>
                        )}
                      </td>
                      <td>
                        {Object.values(nftData?.status?.walletWhitelisted).some(
                          (value) => value === "approved"
                        )
                          ? "Approved"
                          : "Rejected"}
                      </td>
                      <td>{nftData?.nftListedCount}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          
          {/* Pagination  */}
          <div style={nftUsersStyleing.paginationContainer}>
              <Pagination
                current={currentPage}
                total={filteredData?.length}
                pageSize={pageSize}
                onChange={onPageChange}
                showSizeChanger={false}
                showQuickJumper={false}
              />
            <input
              type="number"
              value={goToPage}
              onChange={(e) => setGoToPage(e.target.value)}
              min="1"
              max={totalPages}
              style={nftUsersStyleing.goInput}
            />
            <button onClick={handleGoToPage} style={nftUsersStyleing.goButton}>
              Go
            </button>
          </div>
          </>
        ) : (
            <div className="no-data-found-div">
              <p className="no-data-found-text">No Data Found</p>
            </div>
          )}
        </div>
      </>
    )
  );
};

export default NftUsers;
