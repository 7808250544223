import React from "react";
import "../pages/styles/Features.scss";
//import backgroundBanner from "../../assets/feature-banner.png";
import copyrightNotic from "../../assets/copyrightNotic.png";
import watermark from "../../assets/watermark.png";
import artwork from "../../assets/artwork.png";
import protocol from "../../assets/protocol.png";
import admin from "../../assets/admin.png";
import whitelisting from "../../assets/whitelisting.png";
import esign from "../../assets/esign.png";
import retrival from "../../assets/retrieval.png";
import badge from "../../assets/badge.png";
import backgroundImg from "../../assets/backgroundImg.png";

const Features = () => {
  return (
    <>
      <div class="features">
        <img class="features__banner" />
        <div className="features__body">
          <div className="features__body__card">
            <div className="features__body__card__front">
              <span className="features__body__card__front__left">
                <img
                  className="features__body__card__front__left__img1"
                  src={backgroundImg}
                ></img>
                <img
                  className="features__body__card__front__left__img"
                  src={copyrightNotic}
                ></img>
              </span>
              <span className="features__body__card__front__right">
                <span className="features__body__card__front__right__title">
                  Copyright Notice:
                </span>
                <span className="features__body__card__front__right__description">
                  Safeguarding intellectual property, the copyright notice
                  feature ensures that artists' creations are protected,
                  asserting ownership rights within the NFT marketplace.
                </span>
              </span>
            </div>
            {/* <div
              className="features__body__card__back"
              style={{ backgroundColor: "rgb(5 255 254)" }}
            >
              <span>01</span>
            </div> */}
          </div>
          <div className="features__body__card">
            <div className="features__body__card__front">
              <span className="features__body__card__front__left">
                <img
                  className="features__body__card__front__left__img1"
                  src={backgroundImg}
                ></img>
                <img
                  className="features__body__card__front__left__img"
                  src={watermark}
                ></img>
              </span>
              <span className="features__body__card__front__right">
                <span className="features__body__card__front__right__title">
                  Watermark Notice:
                </span>
                <span className="features__body__card__front__right__description">
                  Preserving the uniqueness of each NFT, the watermark notice
                  feature adds a distinctive mark, both as a testament to
                  authenticity and a visual identifier of the artist's work.
                </span>
              </span>
            </div>
            {/* <div
              className="features__body__card__back"
              style={{ backgroundColor: "rgb(33 217 241)" }}
            >
              <span>02</span>
            </div> */}
          </div>
          <div className="features__body__card">
            <div className="features__body__card__front">
              <span className="features__body__card__front__left">
                <img
                  className="features__body__card__front__left__img1"
                  src={backgroundImg}
                ></img>
                <img
                  className="features__body__card__front__left__img"
                  src={artwork}
                ></img>
              </span>
              <span className="features__body__card__front__right">
                <span className="features__body__card__front__right__title">
                  Exclusively Authentic Artwork:
                </span>
                <span className="features__body__card__front__right__description">
                  Upholding the integrity of the marketplace exclusively accepts
                  original artworks, fostering a space dedicated to genuine and
                  unique creations.
                </span>
              </span>
            </div>
            {/* <div
              className="features__body__card__back"
              style={{ backgroundColor: "rgb(20 180 202)" }}
            >
              <span>03</span>
            </div> */}
          </div>
          <div className="features__body__card">
            <div className="features__body__card__front">
              <span className="features__body__card__front__left">
                <img
                  className="features__body__card__front__left__img1"
                  src={backgroundImg}
                ></img>
                <img
                  className="features__body__card__front__left__img"
                  src={protocol}
                ></img>
              </span>
              <span className="features__body__card__front__right">
                <span className="features__body__card__front__right__title">
                  Identity Verification Protocol:
                </span>
                <span className="features__body__card__front__right__description">
                  Ensure a secure and trustworthy environment by requiring
                  artists to undergo a thorough KYC process, safeguarding the
                  authenticity of creators on our NFT platform.
                </span>
              </span>
            </div>
            {/*  <div
              className="features__body__card__back"
              style={{ backgroundColor: "rgb(10 135 168)" }}
            >
              <span>04</span>
            </div> */}
          </div>
          <div className="features__body__card">
            <div className="features__body__card__front">
              <span className="features__body__card__front__left">
                <img
                  className="features__body__card__front__left__img1"
                  src={backgroundImg}
                ></img>
                <img
                  className="features__body__card__front__left__img"
                  src={admin}
                ></img>
              </span>
              <span className="features__body__card__front__right">
                <span className="features__body__card__front__right__title">
                  Admin Approval:
                </span>
                <span className="features__body__card__front__right__description">
                  The admin approval feature ensures that only high-quality and
                  authentic content finds its place in the marketplace.
                </span>
              </span>
            </div>
            {/*  <div
              className="features__body__card__back"
              style={{ backgroundColor: "rgb(87 84 215)" }}
            >
              <span>05</span>
            </div> */}
          </div>
          <div className="features__body__card">
            <div className="features__body__card__front">
              <span className="features__body__card__front__left">
                <img
                  className="features__body__card__front__left__img1"
                  src={backgroundImg}
                ></img>
                <img
                  className="features__body__card__front__left__img"
                  src={whitelisting}
                ></img>
              </span>
              <span className="features__body__card__front__right">
                <span className="features__body__card__front__right__title">
                  Whitelisting:
                </span>
                <span className="features__body__card__front__right__description">
                  Through admin-approved whitelisting, artists gain exclusive
                  access, streamlining transactions and elevating the level of
                  trust within the platform.
                </span>
              </span>
            </div>
            {/* <div
              className="features__body__card__back"
              style={{ backgroundColor: "rgb(122 88 194)" }}
            >
              <span>06</span>
            </div> */}
          </div>
          <div className="features__body__card">
            <div className="features__body__card__front">
              <span className="features__body__card__front__left">
                <img
                  className="features__body__card__front__left__img1"
                  src={backgroundImg}
                ></img>
                <img
                  className="features__body__card__front__left__img"
                  src={esign}
                ></img>
              </span>
              <span className="features__body__card__front__right">
                <span className="features__body__card__front__right__title">
                  Digitally E-Sign:
                </span>
                <span className="features__body__card__front__right__description">
                  Offering a digital seal of authenticity, the E-Signature
                  feature allows artists to personally endorse their NFTs,
                  adding an extra layer of verification to their digital
                  creations.
                </span>
              </span>
            </div>
            {/* <div
              className="features__body__card__back"
              style={{ backgroundColor: "rgb(158 83 217)" }}
            >
              <span>07</span>
            </div> */}
          </div>
          <div className="features__body__card">
            <div className="features__body__card__front">
              <span className="features__body__card__front__left">
                <img
                  className="features__body__card__front__left__img1"
                  src={backgroundImg}
                ></img>
                <img
                  className="features__body__card__front__left__img"
                  src={retrival}
                ></img>
              </span>
              <span className="features__body__card__front__right">
                <span className="features__body__card__front__right__title">
                  Scheduled NFT Retrieval:
                </span>
                <span className="features__body__card__front__right__description">
                  Streamlining the transaction process, the pick-up time feature
                  establishes a timeline for the transfer of digital assets,
                  enhancing efficiency and user experience.
                </span>
              </span>
            </div>
            {/* <div
              className="features__body__card__back"
              style={{ backgroundColor: "rgb(74 56 186)" }}
            >
              <span>08</span>
            </div> */}
          </div>
          <div className="features__body__card">
            <div className="features__body__card__front">
              <span className="features__body__card__front__left">
                <img
                  className="features__body__card__front__left__img1"
                  src={backgroundImg}
                ></img>
                <img
                  className="features__body__card__front__left__img"
                  src={badge}
                ></img>
              </span>
              <span className="features__body__card__front__right">
                <span className="features__body__card__front__right__title">
                  Golden Badge User:
                </span>
                <span className="features__body__card__front__right__description">
                  Recognizing exceptional contributors, the Golden Badge is
                  bestowed upon distinguished users, signifying a premium status
                  and enhancing their profile within the NFT community.
                </span>
              </span>
            </div>
            {/* <div
              className="features__body__card__back"
              style={{ backgroundColor: "rgb(88 95 157)" }}
            >
              <span>09</span>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Features;
