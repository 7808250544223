import React, { useEffect, useState, useContext } from "react";
import { useHistory, Link } from "react-router-dom";
import { createGlobalStyle } from "styled-components";
import { Form, Input, Button, Card, notification, message, Spin } from "antd";
import { NFTCreatorService } from "../components/services/NFTCreator/NFTCreator.service";
import { AccountService } from "../components/Account/Account.service";
import { MetamaskService } from "./../components/Shared/Metamask.service";
import authContext from "./../components/Shared/Auth.service";

const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.white {
    background: #fff;
  }
  .mainside{
    .connect-wal{
      display: none;
    }
    .logout{
      display: flex;
      align-items: center;
    }
  }
  @media only screen and (max-width: 1199px) {
    .navbar{
      background: #403f83;
    }
    .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
      background: #fff;
    }
    .item-dropdown .dropdown a{
      color: #111 !important;
    }
  }
`;

const nftService = new NFTCreatorService();

const useSelectedWalletContext = () =>
  new MetamaskService().useSelectedWalletContext();

const accountService = new AccountService();

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const ChangePassword = ({ authorId = null }) => {
  const { userInfo, setUserInfo } = useContext(authContext);

  const { selectedWallet: walletAddress } = useSelectedWalletContext();

  const [oldPassword, setOldPassword] = useState([]);
  const [newPassword, setNewPassword] = useState(false);
  const [newConfirmPassword, setNewConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [correctWalletSelected, setCorrectWalletSelected] = useState(false);
  const [samePasswordError, setSamePasswordError] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    setLoading(true);
    setTimeout(async () => {
      if (!userInfo) window.location.href = "/account/login";
      else setLoading(false);
    }, 500);
  }, [userInfo]);

  useEffect(() => {
    if (userInfo)
      setCorrectWalletSelected(
        userInfo.walletAddress?.toLowerCase() === walletAddress?.toLowerCase()
      );
  }, [userInfo, walletAddress]);

  const changePassword = async (req) => {
    setConfirmPasswordError(false);
    setSamePasswordError(false);
    if (newPassword !== newConfirmPassword) {
      return setConfirmPasswordError(true);
    }
    if (oldPassword === newPassword) {
      return setSamePasswordError(true);
    }

    setLoading(true);
    let data = {
      email: userInfo?.email,
      oldPassword: oldPassword,
      newPassword: newPassword,
    };
    const response = await accountService.changePassword(data);

    if (response.success) {
      notification.open({
        message:
          "You have successfully updated new password for NFT Marketplace",
        duration: 0,
      });

      if(userInfo?.role === 'marketplace-super-admin') {
        setTimeout(() => (window.location.href = "/marketplace-admin/super-admin"), 2000);
      }else if(userInfo?.role === 'marketplace-compliance-admin') {
        setTimeout(() => (window.location.href = "/marketplace-admin/compliance-admin"), 2000);
      }else if(userInfo?.role === 'marketplace-token-admin') {
        setTimeout(() => (window.location.href = "/marketplace-admin/token-admin"), 2000);
      } else {
        setTimeout(() => (window.location.href = "/profile"), 2000);
      }

    } else {
      notification.open({
        message: response.error.message,
        duration: 0,
      });
    }

    setLoading(false);
  };

  return (
    <div>
      <GlobalStyles />
      {loading ? (
        <div
        className='loading-spinner' 
        >
          <Spin size="large" />
        </div>
      ) : (
        <>
          {correctWalletSelected ? (
            <div
              className="change-password"
              style={{
                margin: "30px 10px",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Card>
                <h2 style={{ textAlign: "center", fontSize: '36px', fontFamily: 'Poppins' }}>Change Password</h2>
                <Form
                  {...formItemLayout}
                  form={form}
                  onFinish={changePassword}
                  style={{ margin: "2% 10% 0 10%", width: "60%" }}
                >
                  <Form.Item
                    label="Current Password"
                    name="current Password"
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: "",
                      },
                      {
                        validator: (rule, value) => {
                          const strongRegex =
                            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$\-_[\](){}<>|°¬+=~".,;:'#!%*?&])[A-Za-z\d@$\-_[\](){}<>|°¬+=~".,;:'#!%*?&]{8,}$/;
                          if (strongRegex.test(value)) return Promise.resolve();
                          return Promise.reject(
                            "Please enter the valid password"
                          );
                        },
                      },
                    ]}
                  >
                    <Input.Password
                      value={oldPassword}
                      onChange={(e) => setOldPassword(e.target.value)}
                    />
                  </Form.Item>

                  <Form.Item
                    label="New Password"
                    name="newPassword"
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: "Please input your new password!",
                      },
                      {
                        validator: (rule, value) => {
                          const strongRegex =
                            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$\-_[\](){}<>|°¬+=~".,;:'#!%*?&])[A-Za-z\d@$\-_[\](){}<>|°¬+=~".,;:'#!%*?&]{8,}$/;
                          if (strongRegex.test(value)) return Promise.resolve();
                          return Promise.reject(
                            "The new password must contains at least 1 number, 1 lowercase, 1 uppercase and 1 special character and 8 characters long"
                          );
                        },
                      },
                    ]}
                  >
                    <Input.Password
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Confirm New Password"
                    name="confirmNewPassword"
                    dependencies={["newPassword"]}
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: "Please input your confirm password!",
                      },
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (!value || getFieldValue("newPassword") === value)
                            return Promise.resolve();
                          return Promise.reject(
                            "The two passwords that you entered do not match!"
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password
                      value={newConfirmPassword}
                      onChange={(e) => setNewConfirmPassword(e.target.value)}
                    />
                  </Form.Item>

                  <br />
                  {confirmPasswordError && (
                    <p style={{ color: "orangered", fontSize: "16px" }}>
                      Your new password and confirm password does not match
                    </p>
                  )}

                  {samePasswordError && (
                    <p style={{ color: "orangered", fontSize: "16px" }}>
                      Your current password and new password can not be same!
                    </p>
                  )}

                  <Form.Item {...tailFormItemLayout}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={loading}
                      className="change-password update-password-button"
                      disabled={
                        !oldPassword || !newPassword || !newConfirmPassword
                      }
                    >
                      Update Password
                    </Button>
                  </Form.Item>
                </Form>
              </Card>
            </div>
          ) : (
            <h6 style={{ textAlign: "center", padding: "120px 10px" }}>
              {" "}
              Please select correct User Wallet
            </h6>
          )}
        </>
      )}
    </div>
  );
};

export default ChangePassword;
