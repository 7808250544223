import React, { useContext, useState, useEffect } from "react";
import { useHistory, useParams, Link } from 'react-router-dom';
import styled from "styled-components";
import { Spin, notification, Input, Button, Popconfirm} from 'antd';
import Clock from "../components/Clock";
import { createGlobalStyle } from 'styled-components';
import authContext from '../components/Shared/Auth.service';
import { NFTCreatorService } from "../components/services/NFTCreator/NFTCreator.service";
import { SharedService } from './../components/Shared/Shared.service';
import { VideoPlayer } from "./../components/mainSlider/VideoPlayer";
import ProfilePic from './../assets/images/home/profile-default.png';
import './styles/styles.scss'
import { HomeService } from './../components/services/home/home.service';
import NFTCard from "../components/NFTCardComponent";
import { MetamaskService } from "../components/Shared/Metamask.service";

const nftService = new NFTCreatorService();
const homeService = new HomeService();
const sharedService = new SharedService();

const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.white {
    background: #FAF6F1;
    border-bottom: solid 1px #ccc !important;
  }
`;

const Outer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: 260px;
  overflow: hidden;
  border-radius: 8px;
`;

const useSelectedWalletContext = () =>
  new MetamaskService().useSelectedWalletContext();


const AllCategorisedNFTs= function() {
    const { userInfo, setUserInfo } = useContext(authContext);
    

    const [AllCategoryDetails, setAllCategoryDetails] = useState([]);
    const [AllCategoryNames, setAllCategoryNames] = useState([]);
    const [nftsMappedData, setNftsMappedData] = useState(new Map());
    const [todayEtheriumPriceInUSD, setTodayEtheriumPriceInUSD] = useState();
    const [loading, setLoading] = useState(true);
    const { networkId } = useSelectedWalletContext();

    const history = useHistory();

    useEffect(() => {

        if(!networkId) return;
        setTimeout(async ()=>{
                setLoading(true);
                //setting ether price
                setTodayEtheriumPriceInUSD(await sharedService.getEtheriumPrice());

                let newCategorisedData = ((await homeService.getNewCategories()).data);
                for (let categoryData of newCategorisedData){
                    AllCategoryNames.push(categoryData.name);
                    let nftFound = (await nftService.getCategorisedNFT(categoryData.name, {networkId})).data;
                    nftsMappedData.set(categoryData.name, nftFound.categorised);
                    AllCategoryDetails.push(nftFound.categorised)
                }
                setLoading(false);
        }, 300)
      }, [userInfo, networkId]);
    


    return (
<div>            
    <GlobalStyles/>
        {loading ? (<div className="center" style={{textAlign: 'center', margin: '100px 0'}}>
                <Spin size="large"/>
            </div>):
         (<div>
    


         { <div className="row">
                   
                        <h3 className="title test" style={{'margin-top': '100px'}}>{`Photo NFTs`}</h3>
                        <div className="row col-12">                         
                                        {(nftsMappedData.get('Photo')) && (nftsMappedData.get('Photo')).map( (nft, index) => (
                                        <div className='itm col-lg-4 col-md-6 col-sm-12' index={index + 1} key={index}>
                                            <div className="d-item">
                                            <div className="nft__item">
                                                <NFTCard nft={nft} todayEtheriumPriceInUSD={todayEtheriumPriceInUSD} />
                                            </div>
                                            </div>
                                        </div>
                                    ))}
                           
 
                        </div>
                        <h3 className="title test" style={{'margin-top': '100px'}}>{`Music NFTs`}</h3>
                        <div className="row col-12">                         
                                        {(nftsMappedData.get('Music')) && (nftsMappedData.get('Music')).map( (nft, index) => (
                                        <div className='itm col-lg-4 col-md-6 col-sm-12' index={index + 1} key={index}>
                                            <div className="d-item">
                                            <div className="nft__item">
                                                <NFTCard nft={nft} todayEtheriumPriceInUSD={todayEtheriumPriceInUSD} /> 
                                            </div>
                                            </div>
                                        </div>
                                    ))}
                           
 
                        </div>
                        <h3 className="title test" style={{'margin-top': '100px'}}>{`Avatar NFTs`}</h3>
                        <div className="row col-12">                         
                                        {(nftsMappedData.get('Avatar')) && (nftsMappedData.get('Avatar')).map( (nft, index) => (
                                        <div className='itm col-lg-4 col-md-6 col-sm-12' index={index + 1} key={index}>
                                            <div className="d-item">
                                            <div className="nft__item">
                                                <NFTCard nft={nft} todayEtheriumPriceInUSD={todayEtheriumPriceInUSD} />
                                            </div>
                                            </div>
                                        </div>
                                    ))}
                           
 
                        </div>
                        <h3 className="title test" style={{'margin-top': '100px'}}>{`Sports NFTs`}</h3>
                        <div className="row col-12">                         
                                        {(nftsMappedData.get('Sports')) && (nftsMappedData.get('Sports')).map( (nft, index) => (
                                        <div className='itm col-lg-4 col-md-6 col-sm-12' index={index + 1} key={index}>
                                            <div className="d-item">
                                            <div className="nft__item">
                                                <NFTCard nft={nft} todayEtheriumPriceInUSD={todayEtheriumPriceInUSD} />
                                            </div>
                                            </div>
                                        </div>
                                    ))}
                           
 
                        </div>
                        <h3 className="title test" style={{'margin-top': '100px'}}>{`Memes NFTs`}</h3>
                        <div className="row col-12">                         
                                        {(nftsMappedData.get('Memes')) && (nftsMappedData.get('Memes')).map( (nft, index) => (
                                        <div className='itm col-lg-4 col-md-6 col-sm-12' index={index + 1} key={index}>
                                            <div className="d-item">
                                            <div className="nft__item">
                                                <NFTCard nft={nft} todayEtheriumPriceInUSD={todayEtheriumPriceInUSD} />
                                            </div>
                                            </div>
                                        </div>
                                    ))}
                           
 
                        </div>
                        <h3 className="title test" style={{'margin-top': '100px'}}>{`Utility NFTs`}</h3>

                        <div className="row col-12">                         
                                        {(nftsMappedData.get('Utility')) && (nftsMappedData.get('Utility')).map( (nft, index) => (
                                        <div className='itm col-lg-4 col-md-6 col-sm-12' index={index + 1} key={index}>
                                            <div className="d-item">
                                            <div className="nft__item">
                                                <NFTCard nft={nft} todayEtheriumPriceInUSD={todayEtheriumPriceInUSD} />
                                            </div>
                                            </div>
                                        </div>
                                    ))}
                           
 
                        </div>






                   
                   
                   
                    </div>
                    }




          </div>)}
          </div>
);
}
export default AllCategorisedNFTs;