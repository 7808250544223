/* eslint-disable import/no-anonymous-default-export */
import React from "react";
// import { lazy } from '@loadable/component';
import { Switch, Route, useRouteMatch } from "react-router-dom";
import Blog1 from "./Blog1";
import Blog2 from "./Blog2";
import Blog3 from "./Blog3";
import Blog4 from "./Blog4";
import Blogs from "./Blogs";
export default () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/blog1`}>
        <Blog1 />
      </Route>
      <Route path={`${path}/blog2`}>
        <Blog2 />
      </Route>
      <Route path={`${path}/blog3`}>
        <Blog3 />
      </Route>
      <Route path={`${path}/blog4`}>
        <Blog4 />
      </Route>
      <Route path={`${path}`}>
        <Blogs />
      </Route>
    </Switch>
  );
};
