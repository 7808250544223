import React, { useEffect, useState, useContext } from "react";
import { useHistory, Link } from "react-router-dom";
import queryString from "query-string";
import { createGlobalStyle } from "styled-components";
import { Form, Input, Button, Card, notification, message, Spin } from "antd";
import { NFTCreatorService } from "../components/services/NFTCreator/NFTCreator.service";
import { AccountService } from "../components/Account/Account.service";
import authContext from "./../components/Shared/Auth.service";

const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.white {
    background: #fff;
  }
  .mainside{
    .connect-wal{
      display: none;
    }
    .logout{
      display: flex;
      align-items: center;
    }
  }
  @media only screen and (max-width: 1199px) {
    .navbar{
      background: #403f83;
    }
    .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
      background: #fff;
    }
    .item-dropdown .dropdown a{
      color: #111 !important;
    }
  }
`;

const nftService = new NFTCreatorService();
const accountService = new AccountService();

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const SetForgetPassword = (req) => {
  const { userInfo, setUserInfo } = useContext(authContext);
  const [newPassword, setNewPassword] = useState(false);
  const [newConfirmPassword, setNewConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [email, setEmail] = useState(false);
  const [form] = Form.useForm();

  useEffect(async () => {
    let params = queryString.parse(window.location.search);
    console.log(params);
    let email = params?.email;
    let secretCode = params?.secretCode;
    if (!email || !secretCode) {
      return notification.open({
        message:
          "Email or Code not found. You need to open a right link which you got from your email",
        duration: 0,
      });
    }

    setLoading(true);
    setEmail(email);
    const response = await accountService.checkEmailVerificationCodeForPassword(
      email,
      secretCode
    );
    if (response.data) {
      setShowForm(true);
    } else {
      notification.open({
        message: response.error.message,
        duration: 0,
      });
      setShowForm(false);
    }
    setLoading(false);
  }, []);

  const UpdateNewPassword = async (req) => {
    setConfirmPasswordError(false);
    if (newPassword !== newConfirmPassword) {
      return setConfirmPasswordError(true);
    }

    setLoading(true);
    let data = {
      email: email,
      newPassword: newPassword,
    };
    const response = await accountService.setNewPassword(data);

    if (response.success) {
      notification.open({
        message:
          "You have successfully updated new password for NFT Marketplace. Now You can login with new password",
        duration: 0,
      });
      setShowForm(false);
      setTimeout(() => (window.location.href = "/account/login"), 3000);
    } else {
      notification.open({
        message: response.error.message,
        duration: 0,
      });
    }

    setLoading(false);
  };

  return (
    <div>
      <h3 style={{ textAlign: "center", marginTop: "30px" }}>
        Forgot Password
      </h3>
      <GlobalStyles />
      {loading ? (
        <div
          className="center"
          style={{ textAlign: "center", margin: "100px 0" }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <>
          {showForm && (
            <div
              className="change-password"
              style={{
                margin: "30px 10px",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Card>
                <h6 style={{ textAlign: "center" }}>Change Password</h6>
                <Form
                  {...formItemLayout}
                  form={form}
                  onFinish={UpdateNewPassword}
                  style={{ margin: "2% 10% 0 10%", width: "60%" }}
                >
                  <Form.Item
                    label="New Password"
                    name="newPassword"
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: "Please input your new password!",
                      },
                      {
                        validator: (rule, value) => {
                          const strongRegex =
                            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$\-_[\](){}<>|°¬+=~".,;:'#!%*?&])[A-Za-z\d@$\-_[\](){}<>|°¬+=~".,;:'#!%*?&]{8,}$/;
                          if (strongRegex.test(value)) return Promise.resolve();
                          return Promise.reject(
                            "The new password must contains at least 1 number, 1 lowercase, 1 uppercase and 1 special character and 8 characters long"
                          );
                        },
                      },
                    ]}
                  >
                    <Input.Password
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Confirm New Password"
                    name="confirmNewPassword"
                    dependencies={["newPassword"]}
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: "Please input your confirm password!",
                      },
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (!value || getFieldValue("newPassword") === value)
                            return Promise.resolve();
                          return Promise.reject(
                            "The two passwords that you entered do not match!"
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password
                      value={newConfirmPassword}
                      onChange={(e) => setNewConfirmPassword(e.target.value)}
                    />
                  </Form.Item>

                  <br />
                  {confirmPasswordError && (
                    <p style={{ color: "orangered", fontSize: "16px" }}>
                      Your new password and confirm password does not match
                    </p>
                  )}

                  <Form.Item {...tailFormItemLayout}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={loading}
                      disabled={!newPassword || !newConfirmPassword}
                    >
                      Change Password
                    </Button>
                  </Form.Item>
                </Form>
              </Card>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default SetForgetPassword;
