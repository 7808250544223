import React from 'react'
import "../pages/styles/AboutUs.scss";
import AboutLogo from '../assets/images/aboutUs/about.png';
import InvetmentLogo from '../assets/images/aboutUs/investment.png';
import Banner from '../assets/images/aboutUs/abouUsBanner.jpg';

const AboutUsQArt = () => {
  return (
    <>
      <div class="card bg-dark text-white">
        <img src={Banner} class="card-img bannerHeight" alt="About Us Banner" />
        <div class="card-img-overlay d-flex align-items-end justify-content-center">
          <p class="text-white text-center mb-0 heading">About Us</p>
        </div>
      </div>
      <div className="bgColor">
        <div className="container-fluid px-5">
          <div className="d-flex justify-content-center">
            <p className='text-center text-white marginRemove subHeading'>Discover the heart and soul of Q-Art. Join a community driven by a passion for digital art and a shared commitment to artistic expression.</p>
          </div>
          <div className='mt-7 firstMt7'>
            <div className="row row-cols-1 row-cols-md-3">
              <div className='col'></div>
              <div className="col">
                <div className="h-100 d-flex justify-content-center align-items-center">
                  <img src={AboutLogo} alt="Q-Art Logo" className="card-img-top pixentImage" />
                </div>
              </div>
              <div className='col'></div>
            </div>
            <div className="card qartCardBgColor">
              <p className="card-text p-1 displayQart">
                Q-Art is a division of Querubin Global Foundation, Inc. Q-art was founded with the purpose of promoting modern artistic work from living, established artists with a career.
              </p>
              <p className="card-text p-1 displayQart">
                All our artists provide us with their piece of art which we replicate in NFT as part of our fund-raising strategy to accommodate our investors to fund our QGF social economic programs and social projects.
              </p>
              <p className="card-text p-1 displayQart">
                We are using art to fill the world with beauty! We use our proceeds to makes social economic investments in under cared areas of the economy such as art itself and filling the gap of needs within others such as health and education.
              </p>
              <p className="card-text p-1 displayQart">
                We work with financial planners and consultants to structure, capitalize and fund our projects combined with estate planning financial planning strategies available to our investors and delivered by our licensed planners’ experts and consultants.
              </p>
            </div>
          </div>
          {/* <div className='mt-7'>
            <div className="row row-cols-1 row-cols-md-3 g-4">
              <div className='col'></div>
              <div className="col">
                <div className="h-100 d-flex justify-content-center align-items-center">
                  <img src={PixentroWorksLogo} alt="Pixentro Works Logo" className="card-img-top pixentImage" />
                </div>
              </div>
              <div className='col'></div>
            </div>
            </div> */}
          <div className='mt-7'>
            <div className="row row-cols-1 row-cols-md-3">
              <div className='col'></div>
              <div className="col">
                <div className="d-flex justify-content-center align-items-center">
                  <img src={InvetmentLogo} alt="Mission Logo" className="card-img-top pixentImage" />
                </div>
              </div>
              <div className='col'></div>
            </div>
            <div className="card qartCardBgColor">
              <p className="card-text displayQart">
                All our NFT investors&rsquo; money is deposited in a Trust Account managed by our selected asset management team who guarantee our promised return. Then, we use this our trust as a payment guarantee to our selected engineering, procurement, construction, and management company (EPCM) in charged of delivering our project. Our selected EPCM must provide a completion bond which guarantees our positions under a turnkey contract.
              </p>
              <p className="card-text displayQ">Q-Artists &ndash; Artists profiles </p>
              <ul className='about ul'>
                <li  >Franklin Arturo Rodriguez</li>
                <li  >Hector Ledesma</li>
              </ul>
              <p className="card-text displayQ">Q-Projects</p>
              <ul className='about ul'>
                <li >Museums </li>
                <li >Art schools </li>
                <li >Community art shops</li>
                <li >Community renewal </li>
                <li >Q-Parks</li>
                <li >Q-social housing</li>
                <li >Q-Eco </li>
                <li >Q-Edu</li>
                <li >Q-Libraries </li>
                <li >Eternal Youth (Elderly Homes)</li>
                <li >Q-Dog pounds </li>
                <li >Q-Community Medical Center</li>
              </ul>
              <p className="card-text displayQ">Contact us.</p>
              <p className="card-text displayQ">Technical questions:</p>
              <p className="card-text displayQ">For general inquiries:</p>
              <a href={`mailto:Info-Qart@querubin-globalfoundation.org`} target="_blank" className="about link" rel="noreferrer">Info-Qart@querubin-globalfoundation.org</a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AboutUsQArt;