import { environment } from '../../../../../../environments/environment';
import Web3 from 'web3';

export class WhiteListingBeaconService {
    private ethereum = window['ethereum'];
    private web3 = new Web3(this.ethereum);
    private contract = {
        ABI: require('./WhiteListingBeacon.json')
    };
    
    async upgradeWhiteListingContract(newWhiteListingContractAddress: string, PSAdmin: string, networkId: string) {  
        const contractInstance = new this.web3.eth.Contract(this.contract.ABI, environment.blockchains[networkId].WhiteListing_Beacon_Address);       
        return contractInstance.methods.upgrade(newWhiteListingContractAddress).send({ from: PSAdmin });
    }
}