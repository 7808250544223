import { environment } from '../../../../environments/environment';
import Web3 from 'web3';

export class NftEscrowService {
    private ethereum = window['ethereum'];
    private web3 = new Web3(this.ethereum);
    private contract = {
        ABI: require('./NftEscrow.json')
    };
    
    async deposit(user: string, amount: string, buyer: string, NftUser: string, NftEscrowContractAddr: string) {    
        const contractInstance = new this.web3.eth.Contract(this.contract.ABI, NftEscrowContractAddr);
        
        return contractInstance.methods.deposit(user, amount, buyer).send({ from: NftUser, value: this.web3.utils.toWei(amount, "ether") });
    }

    async ApproveNftEscrowPayBySuperAdmin(MSAdmin: string, NftEscrowContractAddr: string) {    
        const contractInstance = new this.web3.eth.Contract(this.contract.ABI, NftEscrowContractAddr);
        
        return contractInstance.methods.approveAndPay().send({ from: MSAdmin });
    }

    async ApproveNftEscrowPayByTokenAdmin( MTAdmin: string, NftEscrowContractAddr: string) {    
        const contractInstance = new this.web3.eth.Contract(this.contract.ABI, NftEscrowContractAddr);
        
        return contractInstance.methods.approveAndPay().send({ from: MTAdmin });
    }

    async ApproveNftEscrowByComplianceAdmin( MCAdmin: string, NftEscrowContractAddr: string) {    
        const contractInstance = new this.web3.eth.Contract(this.contract.ABI, NftEscrowContractAddr);
        
        return contractInstance.methods.approveAndPay().send({ from: MCAdmin });
    }
    async ClaimEscrowAmount( User: string, NftEscrowContractAddr: string) {    
        const contractInstance = new this.web3.eth.Contract(this.contract.ABI, NftEscrowContractAddr);
        
        return contractInstance.methods.approveAndPay().send({ from: User });
    }
}