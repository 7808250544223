import React from "react";

import { useParams } from "react-router-dom";
import IssuerSuperAdmin from "./IssuerSuperAdmin";
import TeamMember from "./TeamMember";
import IssuerCounsel from "./IssuerCounsel";
import IssuerTokenAdmin from "./IssuerTokenAdmin";

import IssuerRegistration from "./IssuerRegistration";
import InvestorRegistration from "./InvestorRegistration";
import IssuerCompanyOfficer from "./IssuerCompanyOfficer";
import MarketPlaceSuperAdmin from "./MarketPlaceSuperAdmin";
import NFTCreator from "./NFTCreator";
import NFTModerator from "./NFTModerator";
import MarketPlaceComplianceAdmin from "./MarketPlaceComplianceAdmin";
import MarketPlaceTokenAdmin from "./MarketPlaceTokenAdmin";

export default () => {
  const { role } = useParams<{ role: string }>(); //ROLE URL. INCLUDES A DASH ("-") AS SEPARATOR
  const { email } = useParams<{ email: string }>();

  return (
    <>
      {email && <IssuerRegistration />}
      {/* {role === "issuer" && <IssuerSuperAdmin />} */}
      {/* {role === "team-member" && <TeamMember />} */}
      {/* {(role === 'issuer-token-admin') && <IssuerTokenAdmin/>}
      {(role === 'issuer-company-officer') && <IssuerCompanyOfficer/>}
      {(role === 'issuer-counsel') && <IssuerCounsel/>} */}
      {/* {role === "investor" && <InvestorRegistration />} */}
      {role === "nft-user" && <NFTCreator/> }
      {role === "nft-marketplace-admin" && <NFTModerator/> }
      {role === "marketplace-super-admin" && <MarketPlaceSuperAdmin />}
      {role === "marketplace-compliance-admin" && <MarketPlaceComplianceAdmin />}
      {role === "marketplace-token-admin" && <MarketPlaceTokenAdmin />}
    </>
  );
};
