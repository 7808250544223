import React, { memo, useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Card, Typography, message } from "antd";
import Slider from "react-slick";
import styled from "styled-components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Clock from "./Clock";
import { carouselNew } from "./constants";
import * as selectors from "../../store/selectors";
import { fetchNftsBreakdown } from "../../store/actions/thunks";
import { SharedService } from "./../components/Shared/Shared.service";
import { NFTCreatorService } from "./../components/services/NFTCreator/NFTCreator.service";
import authContext from "./../components/Shared/Auth.service";
import { useHistory } from "react-router-dom";
import api from "../../core/api";
import Spinner from "./spinner/Spinner";
import { VideoPlayer } from "./mainSlider/VideoPlayer";
import ProfilePic from "./../assets/images/home/profile-default.png";
import NFTCard from "./NFTCardComponent";
import { environment } from "../environments/environment";
import { MetamaskService } from "../components/Shared/Metamask.service";
import './../pages/homePage.scss';

const sharedService = new SharedService();
const nftService = new NFTCreatorService();

const Outer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: 260px;
  overflow: hidden;
  border-radius: 8px;
`;

const useSelectedWalletContext = () =>
  new MetamaskService().useSelectedWalletContext();

const CarouselNewRedux = ({ sell }) => {
  // const dispatch = useDispatch();
  // const nftsState = useSelector(selectors.nftBreakdownState);
  // const nfts = nftsState.data ? nftsState.data : [];

  const { userInfo, setUserInfo } = useContext(authContext);
  const { networkId } = useSelectedWalletContext();

  const [loading, setLoading] = useState(false);
  const [availableSellingNFT, setAvailableSellingNFT] = useState([]);
  const [todayEtheriumPriceInUSD, setTodayEtheriumPriceInUSD] = useState();
  const history = useHistory();

  // useEffect(() => {
  //     dispatch(fetchNftsBreakdown());
  // }, [dispatch]);

  useEffect(() => {
    (async () => {
      if (!networkId) return;

      setLoading(true);
      try {
        //setting ether price
        setTodayEtheriumPriceInUSD(await sharedService.getEtheriumPrice());

        let res = await nftService.getAvailableSellingNFT({ networkId });
        if (!res || !res.success || !res.data) {
          message.error("Something went wrong, try again.");
          setLoading(false);
          return;
        }
        if (res.data?.length > 0) {
          let availablenft = res.data.filter((nft) => {
            // expirationTimestamp is a timestamp and will automatically adjust as per client timezone
            if (nft.expirationDate && nft.expirationTimestamp)
              return new Date(nft.expirationTimestamp) >= new Date();
            else return true;
          });
          // below is disbaled for time being
          // filter based on my userid and my nft
          //if(userInfo)
          //  availablenft = availablenft.filter((nft)=>nft.userId!==userInfo._id);
          setAvailableSellingNFT(availablenft);
        }
        setLoading(false);


        setLoading(false);
      } catch (e) {
        message.error("Something went wrong, try again.");
        setLoading(false);
      }
    })();
  }, [networkId]);

  return (
    <>
      {loading && <Spinner />}
      {!loading && (
        <>
          <section className="home-page browse-by-category third_section" style={{background:" #F1F7F9"}}>
            <div className="home-page browse-by-category container">
              <div className="row">
                <div className="col-lg-12 text-center mt-4">
                  <h2 className="home-page browse-by-category-text">New NFTs</h2>
                  <p className="home-page browse-by-category-sub-text">The NFTs provided cover digital art, collectibles, GameFi, the metaverse, and more.</p>
                </div>
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-12 view-all-nft-div">
                      <button
                        type="button"
                        className="btn_view_all float-end home-page view-all-nft-btn"
                        onClick={() => history.push('/new-nft')}
                      >
                        VIEW ALL
                      </button>
                    </div>
                    <div className="col-12">
                      <div className="home-page slider-container">
                        <Slider {...carouselNew}>
                          {availableSellingNFT.length > 0 &&
                            availableSellingNFT.map((nft, index) => (
                              <div className="itm col-xl-3 col-lg-4 col-md-6 col-sm-12" key={index}>
                                <div className="d-item">
                                  <div
                                    className={`nft__item nft-item-buy`}
                                    style={{
                                      margin: "3px",
                                      padding: "3px",
                                      border: "none",
                                      backgroundColor: "transparent",
                                    }}
                                  >
                                    {nft.expirationDate &&
                                      nft.expirationTimestamp && (
                                        <div
                                          className="de_countdown"
                                          style={{ color: "white" }}
                                        >
                                          <Clock
                                            deadline={nft.expirationTimestamp}
                                          />
                                        </div>
                                      )}
                                    {/* <div className="author_list_pp">
                                  <span>
                                    <img
                                      className="lazy"
                                      src={
                                        nft.profilePicUrl
                                          ? nft.profilePicUrl
                                          : ProfilePic
                                      }
                                      alt=""
                                    />
                                    <i className="fa fa-check"></i>
                                  </span>
                                </div> */}
                                    <NFTCard
                                      nft={nft}
                                      todayEtheriumPriceInUSD={
                                        todayEtheriumPriceInUSD
                                      }
                                      buy={true}
                                    />
                                  </div>
                                </div>
                              </div>
                            ))}
                        </Slider>
                      </div>
                    </div>

                  </div>
                </div>
                <br />

              </div>
            </div>
          </section>

          {/*\ <div className="container-fluid">
              <div className="row">
                <div className="col-12 d-flex align-items-center">
                  <h2
                    className="style-2 yellow"
                    style={{
                      width: "100%",
                      textAlign: "center",
                      marginBottom: "20px",
                      marginTop: "50px",
                    }}
                  >
                    Sold NFTs
                  </h2>
                </div>
                <div className="col-12">
                  <div className="nft">
                    <Slider {...carouselNew}>
                      {soldNFT.length > 0 &&
                        soldNFT.map((nft, index) => (
                          <div className="col-4">
                            <div className="itm" key={index}>
                              <div className="d-item">
                                <div
                                  className="nft__item"
                                  style={{
                                    margin: "3px",
                                    padding: "3px",
                                    border: "none",
                                    backgroundColor: "transparent",
                                  }}
                                >
                                  <div className="author_list_pp">
                                    <span>
                                      <img
                                        className="lazy"
                                        src={
                                          nft.profilePicUrl
                                            ? nft.profilePicUrl
                                            : ProfilePic
                                        }
                                        alt=""
                                      />
                                      <i className="fa fa-check"></i>
                                    </span>
                                  </div>
                                  <NFTCard
                                    nft={nft}
                                    todayEtheriumPriceInUSD={
                                      todayEtheriumPriceInUSD
                                    }
                                    buy={false}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                    </Slider>
                  </div>
                </div>
                <div className="col-12 view-all">
                  <button
                    type="button"
                    className="btn_view_all float-end"
                    onClick={() => history.push('/sold-nft')}
                  >
                    VIEW ALL
                  </button>
                </div>
              </div>
            </div> */}


          {/* <section className='container'>
          <div className='row'>
            <div className='col-lg-12 text-center'>
                <h2 className='style-2'>New Items</h2>
                <div className="small-border"></div>
            </div>
          </div>
          <div className='nft'>
            <Slider {...carouselNew}>
              {availableSellingNFT.length > 0 && availableSellingNFT.map((nft, index) => (
                <div className='itm' key={index}>
                  <div className="d-item">
                    <div className="nft__item" style={{ margin: '3px', padding: '3px'}}>
                        {nft.expirationDate && nft.expirationTimestamp && (<div className="de_countdown">
                            <Clock deadline={nft.expirationTimestamp} />
                        </div>)}
                      <div className="author_list_pp">
                          <span>
                          {/* // onClick={()=> window.open("/home1", "_self")                                    
                            <img className="lazy" src={nft.profilePicUrl ? nft.profilePicUrl : ProfilePic} alt=""/>
                              <i className="fa fa-check"></i>
                          </span>
                      </div>
                      <NFTCard nft={nft} todayEtheriumPriceInUSD={todayEtheriumPriceInUSD} buy={true}/>
                  </div>
                </div>
              </div>
              ))}
            </Slider>
          </div>
        </section>

        <section className='container'>
          <div className='row'>
            <div className='col-lg-12 text-center'>
                <h2 className='style-2'>Other</h2>
                <div className="small-border"></div>
            </div>
          </div>
          <div className='nft'>
            <Slider {...carouselNew}>
              {otherNFT.length > 0 && otherNFT.map( (nft, index) => (
                <div className='itm' index={index + 1} key={index}>
                  <div className="d-item">
                    <div className="nft__item" onClick={()=> history.push(`/item-detail/${nft._id}`, "_self")}>
                          {/* <div className="de_countdown">
                              <Clock deadline={nft.expirationDate} />
                          </div> 
                        <div className="author_list_pp">
                            <span>
                            {/* // onClick={()=> window.open("/home1", "_self")                                    
                              <img className="lazy" src={nft.profilePicUrl ? nft.profilePicUrl : ProfilePic} alt=""/>
                                <i className="fa fa-check"></i>
                            </span>
                        </div>
                        <NFTCard nft={nft} todayEtheriumPriceInUSD={todayEtheriumPriceInUSD} buy={false}/>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </section> */}
        </>
      )}
    </>
  );
};

export default memo(CarouselNewRedux);
