import { environment } from '../../../../../../environments/environment';
import Web3 from 'web3';

export class NftFactoryBeaconService {
    private ethereum = window['ethereum'];
    private web3 = new Web3(this.ethereum);
    private contract = {
        ABI: require('./NftFactoryBeacon.json')
    };
       
    async upgradeNftFactoryContract(newNftFactoryContractAddress: string, PSAdmin: string, networkId: string) {    
        const contractInstance = new this.web3.eth.Contract(this.contract.ABI, environment.blockchains[networkId].NftFactory_Beacon_Address);
        return contractInstance.methods.upgrade(newNftFactoryContractAddress).send({ from: PSAdmin });
    }
}