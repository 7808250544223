import React from 'react'
import DashboardBanner from "../../../../assets/images/admin-dashboard/dashboardBanner.png";

function Dashboard(props) {
  const { dashboardData, todayEtheriumPriceInUSD, loading } = props || {};

  return (
    !loading && <>
      <div style={{ cursor: 'pointer', backgroundImage: `url(${DashboardBanner})` }} className="market-super-admin DashboardBanner d-flex flex-column justify-content-center">
        <p className='MarketPlace-Super-Admin-text'>MarketPlace Super Admin</p>
        <p className='Dashboard-text'>Dashboard</p>
      </div>

      <div className='market-super-admin card-container'>
        <div className='market-super-admin card-inner-container'>
          {dashboardData &&
            Object.keys(dashboardData).map((key, index) => (
              <div key={index} className='market-super-admin card-component'>
                <p className='market-super-admin card-component-main-text' style={{textAlign:"center"}}>{key}</p>
                {key === "RevenueFromWhiteListingFees" ||
                  key === "RevenueFromCollectionFees" ||
                  key === "RevenueFromMintingFees" ||
                  key === "RevenueFromListingFees" ||
                  key === "RevenueFromSellingCommission" ||
                  key === "TotalRevenueFromCommission" ? (
                  <p className='market-super-admin card-component-sub-text' style={{textAlign:"center"}}>{`${dashboardData[key].toFixed(
                    6
                  )} ETH -> ${(
                    dashboardData[key] *
                    todayEtheriumPriceInUSD
                  ).toFixed(3)} USD`}</p>
                ) : (
                  <p className='market-super-admin card-component-sub-text'>{dashboardData[key]}</p>
                )}
              </div>
            ))}
        </div>
      </div>
    </>
  )
}

export default Dashboard
