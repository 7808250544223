import React from 'react'
import { environment } from '../environments/environment'
import DisclaimerQArt from '../dynamicContent/Disclaimer.qart'
import DisclaimerPixentro from '../dynamicContent/Disclaimer.pixentro'

const Disclaimer = () => {
  return (
    <>
    {environment.envName === 'qart' && <DisclaimerQArt />}
    {environment.envName === 'pixentro' && <DisclaimerPixentro />}
  </>
  )
}

export default Disclaimer