import React from 'react'
// import { lazy } from '@loadable/component';
import { Switch, Route, useRouteMatch } from "react-router-dom";

//import Login from './Login';
import Register from './Register';
import VerifyEmail from './VerifyEmail';
import ResendEmailVerifcationCode from './ResendEmailVerifcationCode';
import DeviceRegistration from './DeviceRegistration'
import TOTP from './TOTP';
import TwoFA from './2FA';
import { ConsoleSqlOutlined } from '@ant-design/icons';
import Login from './Login';
import ForgotPasswordPage from '../../pages/ForgotPasswordPage';
import ChangePassword from '../../pages/ChangePassword';
import SetForgetPassword from '../../pages/SetForgetPassword';
// const Register = lazy(() => import('./Register'));




const AccountRoutes = () => {
  const { path } = useRouteMatch();
  
  return (
    <Switch>
      {/* <Route path={`${path}/register/user/:email`}>
        <Register/>
      </Route> */}
      <Route path={`${path}/register/:role`}>
        <Register />
      </Route>
      <Route path={`${path}/verify-email/:code`}>
        <VerifyEmail/>
      </Route>
      <Route path={`${path}/resend-email-verification-code`}>
        <ResendEmailVerifcationCode/>
      </Route>
      <Route path={`${path}/login`}>
        <Login />
      </Route>     
      <Route path={`${path}/TOTP`}>
        <TOTP/>
      </Route>
      <Route path={`${path}/2FA`}>
        <TwoFA/>
      </Route>
      <Route path={`${path}/device-registration`}>
        <DeviceRegistration/>
      </Route>
      <Route path={`${path}/forgot-password`}>
          <ForgotPasswordPage />
      </Route>
      <Route path={`${path}/set-forgot-password`}>
          <SetForgetPassword />
      </Route>
    </Switch>
  )
}

export default AccountRoutes;
  
