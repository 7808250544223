import React, { useState, useEffect, useContext, createContext } from 'react'
import { User } from './interfaces';
// import * as firebase from 'firebase/app';
import axios from 'axios';
import { APIResponse } from '../Shared/interfaces';
import { environment } from './../../environments/environment';
import { SharedService } from '../Shared/Shared.service';
// import { useEventCallback } from 'rxjs-hooks';
// import { Observable } from 'rxjs';

const sharedService = new SharedService();

export const UseUserInfo = ()=> {
  const [userInfo, setUserInfo] = useState<User | undefined | null>();
  const APIURL = environment.APIURL;

  const getUserbyToken= async()=> {
    return axios.post<any, APIResponse>(`${APIURL}/users/getUser`, {}, {headers: await sharedService.getAuthHeader()});
  }

    useEffect(() => {
      (async () => {
        const isValidToken = sharedService.isValidToken;

        if(!isValidToken) {
          setUserInfo(null);
          sharedService.deleteToken();
          return;
        }
          
        const response = await getUserbyToken();

        if(!response.success) {
          setUserInfo(null);
          sharedService.deleteToken();
          return;
        }
        
        setUserInfo(response.data);
        
      })();    
    }, []);

    return [userInfo, setUserInfo];
}

const authContext = createContext
  <{
    userInfo: User | undefined | null, 
    setUserInfo: React.Dispatch<React.SetStateAction<User | null | undefined>>
  }>({
  userInfo: undefined, 
  setUserInfo: () => {}
});

export default authContext;

export class AuthService {

}