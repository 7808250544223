import React from "react";
import "./learn.scss";

const Learn7 = () => {
  return (
    <div className="container learn-container">
      <h3 class="blog-heading">How to take part in timed auction for NFT’s</h3>
      <img
        className="blog-image horizontal-middle-align"
        src={`/img/learns/learnPageIcon7.png`}
        alt="learn-img"
        /*  style={{ width: "600px", height: "550px" }} */
      />
      <h4>Participating in a Timed Auction for NFTs</h4>
      <h6>
        To take part in a Timed Auction for NFTs, adhere to these instructions:
      </h6>
      <ul>
        <li>
          Explore NFTs: Visit the Market or Explore section and peruse the
          available NFTs.
        </li>
        <li>
          Choose an NFT: Select a specific NFT that catches your eye, and click
          on it to examine its particulars.
        </li>
        <li>
          Make a bid: On the NFT detail page, click on the "Make a bid" button.
          The page will display the time left until the auction ends. By
          clicking on "Click here to Place a bid," you can enter your bidding
          amount in ETH. Finalize your bid by clicking on the appropriate
          button.
        </li>
        <li>
          Agree to the terms: As a buyer, you must sign the NFT Buy and Sell
          Agreement, providing all the necessary details and covering the gas
          cost for the transaction. Once you have signed the agreement, the NFT
          creator receives an email notification confirming your agreement and
          the bidding price for the specific NFT. The creator can view the
          bidding details along with the buyer's information on the NFT detail
          page.
        </li>
        <li>
          Multiple bids: Other buyers can also place bids on the same NFT with
          different bidding prices. If you wish to modify your bidding price,
          you can do so by signing the agreement again and paying the gas price
          accordingly. The NFT you have bid on will be listed under the "NFT
          Bided" section on your profile.
        </li>
        <li>
          By following these instructions, you can participate in a Timed
          Auction for NFTs and have the possibility to acquire the desired NFT
          based on your bidding.
        </li>
      </ul>

      <p>
        Once the bidding period concludes, the creator is sent an email
        verifying the triumphant fulfillment of the NFT Auction. The purchaser
        is also notified via email of their winning bids and the requirement to
        finalize the transaction within a designated time frame. The purchaser
        can complete the acquisition by paying the gas fee and accessing the NFT
        from their "NFT Purchased" category on their Profile Page.
      </p>
    </div>
  );
};

export default Learn7;
