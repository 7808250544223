import { Button, Divider, Modal, Pagination, Select, notification } from "antd";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { AdminService } from "../../../../components/services/AdminService/Admin.service";
import authContext from "../../../../components/Shared/Auth.service";
import { MetamaskService } from "../../../../components/Shared/Metamask.service";
import { NFTCreatorService } from "../../../../components/services/NFTCreator/NFTCreator.service";
import { NftEscrowService } from "../../../../components/Shared/Blockchain/NftEscrow/NftEscrow.service";
const adminService = new AdminService();
const nftService = new NFTCreatorService();
const useSelectedWalletContext = () =>
  new MetamaskService().useSelectedWalletContext();

const escrowApprovalStyleing = {
  cardContainer: {
    width: "100%",
    display: "flex",
  },

  cardImage: {
    width: "140px",
    height: "95px",
    borderRadius: "8px",
  },
  textDiv: {
    fontFamily: "Aileron",
    fontSize: "16px",
    flex: 4,
    marginLeft: "12px",
  },
  title: {
    fontWeight: "700",
    color: "#0F587A",
  },
  titleResponse: {
    fontWeight: "600",
    color: "#33363F",
  },
  sortValueInCard: {
    width: "140px",
    height: "50px",
    borderRadius: "40px",
    border: "2px solid #27AE60",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  sortValueInCardText: {
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: "500",
    color: "#27AE60",
    margin: "10px"
  },
  paginationContainer: {
    display: "flex",
    justifyContent: "end",
    padding: "30px",
  },
  paginationRender: {
    width: "180px",
    height: "41px",
    borderRadius: "8px",
    border: "2px solid #102253",
    display: "flex",
    gap: "10px",
    alignItems: "center",
    justifyContent: "center",
  },
  goInput: {
    border: "2px solid #102253",
    width: "71px",
    marginLeft: "8px",
  },
  goButton: {
    background: "#EB532D",
    marginLeft: "8px",
    borderRadius: "8px",
    width: "64px",
    height: "55px",
    color: "#FFFFFF",
  },
};

const nftEscrowService = new NftEscrowService();

const EscrowApproval = ({ loading, setLoading }) => {
  const { userInfo } = useContext(authContext);
  const { networkId } = useSelectedWalletContext();
  const [approvedEscrow, setApprovedEscrow] = useState();
  const [pendingEscrowApproval, setPendingEscrowApproval] = useState();
  const [sortByVal, setSortByVal] = useState("Pending");
  const [filteredEscrow, setFilteredEscrow] = useState([]);
  const [goToPage, setGoToPage] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [escrowDetails, setEscrowDetails] = useState({});
  const [escrowRejectionMessage, setEscrowRejectionMessage] = useState("");
  const [isRejecting, setIsRejecting] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 12;

  const { filteredApprovedEscrow, rejectedEscrow } = useMemo(() => {
    const filteredApprovedEscrow = approvedEscrow?.filter(
      (escrow) => escrow.adminVerificationStatus === "approved"
    );
    const rejectedEscrow = approvedEscrow?.filter(
      (escrow) => escrow.adminVerificationStatus !== "approved"
    );
    return { filteredApprovedEscrow, rejectedEscrow };
  }, [approvedEscrow]);

  const handleChange = (value) => {
    setSortByVal(value);
    setCurrentPage(1);
  };

  useEffect(() => {
    setFilteredEscrow(
      sortByVal === "Approved"
        ? filteredApprovedEscrow
        : sortByVal === "Rejected"
          ? rejectedEscrow
          : pendingEscrowApproval
    );
  }, [sortByVal, approvedEscrow]);

  const totalPages = Math.ceil(filteredEscrow?.length / pageSize);
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const currentPageData = filteredEscrow?.slice(startIndex, endIndex);

  const onPageChange = (page) => {
    setCurrentPage(page);
    setGoToPage(page);
  };



  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const [res9, res10] = await Promise.all([
        adminService.getPendingEscrowByComplianceAdmin({ networkId }),
        adminService.getCompletedEscrowByComplianceAdmin({ networkId }),
      ]);
      if (res9.data?.length > 0) {
        setPendingEscrowApproval(res9.data.reverse());
      } else {
        setPendingEscrowApproval([])
      }
      if (res10.data?.length > 0) {
        setApprovedEscrow(res10.data.reverse());
      } else {
        setApprovedEscrow([])
      }
    } catch (error) {
      console.log(error);
      notification.open({
        message: "Error",
        description: "something went wrong while fetching escrow data",
        duration: 0,
      });
    }
    setLoading(false);
  };


  const handleGoToPage = () => {
    const pageNumber = parseInt(goToPage);
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const handleOpenModal = (user) => {
    setOpenModal(true);
    setEscrowDetails(user);
  };

  const handleClose = () => {
    setOpenModal(false)
    setIsRejecting(false)
  }

  const handleApprove = () => {
    setOpenModal(false);
    approveEscrowAccount(
      escrowDetails._id,
      escrowDetails.escrowContractAddress
    );
  };

  const handleReject = () => {
    setOpenModal(false);
  approveEscrowAccount(
    escrowDetails._id,
    escrowDetails.escrowContractAddress,
    "rejected",
    escrowRejectionMessage
  );
  };

  const approveEscrowAccount = async (nftId, escrowContractAddress) => {
    setLoading(true);
    try {

      // here do the blockchain approval of escrowContractAddress for nftId for MCA Approval
      const txnReceipt = await nftEscrowService.ApproveNftEscrowByComplianceAdmin(
        String(userInfo?.walletAddress),
        String(escrowContractAddress)
      );

      console.log("Blockchain Transaction receipt: ", txnReceipt);
      if (txnReceipt) {
        let form = {
          nftId,
          transactionDetails: txnReceipt,
          networkId,
          escrowContractStatus: {
            complianceAdminApproved: true,
            tokenAdminApproved: false,
            superAdminApproved: false
          }
        }
        let res = await nftService.approveEscrowByComplianceAdmin(form);
        if (res.data) {
          notification.open({
            message: 'Success Approved Escrow Acccount Approval',
            duration: 0,
          });
          fetchData();
        }
      }
    } catch (error) {
      setLoading(false)
      console.log(error);
      notification.open({
        message: error.message,
        duration: 0,
      });
    }
  }

  return (
    !loading && (
      <>
        <p className="market-super-admin commission-configuration-text">
          Escrow Approval
        </p>
        <div className="market-super-admin admin-invites card-container d-flex justify-content-center flex-column">
          <div
            className="market-super-admin card-inner-container"
            style={escrowApprovalStyleing.cardContainer}
          >
            <div
              className="d-flex col-12"
              style={{ justifyContent: "space-between", padding: "20px 20px 0px" }}
            >
              <div className="d-flex justify-content-center align-items-center">
                {sortByVal === "Approved" && (
                  <img
                    className="market-place-admin status-icon"
                    src={"/img/admin-dashboard/approved.png"}
                    alt="nftArt"
                  />
                )}
                {sortByVal === "Rejected" && (
                  <img
                    className="market-place-admin status-icon"
                    src={"/img/admin-dashboard/rejected.png"}
                    alt="nftArt"
                  />
                )}
                {sortByVal === "Pending" && (
                  <img
                    className="market-place-admin status-icon"
                    src={"/img/admin-dashboard/pending.png"}
                    alt="nftArt"
                  />
                )}
                <p className="wallet-status-text">{sortByVal}</p>
              </div>
              <div className="d-flex">
                <div className="sort-by-text">
                <img
                  className="market-place-admin status-icon"
                  src={"/img/admin-dashboard/sort-icon.png"}
                  alt="nftArt"
                />
                Sort By:</div>
                <Select
                  className="sort-by-select"
                  defaultValue={"Approved"}
                  value={sortByVal}
                  onChange={handleChange}
                  options={[
                    {
                      value: "Pending",
                      label: "Pending",
                    },
                    {
                      value: "Approved",
                      label: "Approved",
                    },
                    {
                      value: "Rejected",
                      label: "Rejected",
                    },
                  ]}
                />
              </div>
            </div>
            <Divider />
            {currentPageData?.length > 0 ? (
              currentPageData.map((user) => (
                <div
                  className="market-super-admin escrow-card-component"
                  key={user?._id}
                >
                  <div style={{ display: "flex", marginTop: "10px" }}>
                    <div style={{ flex: "2" }}>
                      <img
                        className="wallet-kycDetails-selfie"
                        src={user?.user?.profilePicUrl}
                        alt="Nft"
                        style={escrowApprovalStyleing.cardImage}
                      />
                    </div>
                    <div style={escrowApprovalStyleing.textDiv}>
                      <div style={escrowApprovalStyleing.title}>Title</div>
                      <div style={escrowApprovalStyleing.titleResponse}>
                        {user?.title}
                      </div>

                      <div style={escrowApprovalStyleing.title}>
                        Description
                      </div>
                      <div style={escrowApprovalStyleing.titleResponse}>
                        {user.desc}
                      </div>
                    </div>
                  </div>
                  <Divider style={{ marginTop: "5px", marginBottom: "5px" }} />
                  <div
                    style={{
                      display: "flex",
                      marginTop: "10px",
                      textAlign: "justify",
                    }}
                  >
                    <div style={{ fontFamily: "Aileron", fontSize: "14px" }}>
                      <div style={escrowApprovalStyleing.title}>Category</div>
                      <div style={escrowApprovalStyleing.titleResponse}>
                        {user?.category}
                      </div>
                      <div style={escrowApprovalStyleing.title}>User Email</div>
                      <div style={escrowApprovalStyleing.titleResponse}>
                        {user?.userEmail}
                      </div>
                    </div>
                    <div style={escrowApprovalStyleing.textDiv}>
                      <div style={escrowApprovalStyleing.title}>
                        Asset DataURL Firebase
                      </div>
                      <div style={escrowApprovalStyleing.titleResponse}>
                        <a
                          href={`${user?.assetDataURLFirebase}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Click Here
                        </a>
                      </div>
                      <div style={escrowApprovalStyleing.title}>
                        Copyright URL Firebase
                      </div>
                      <div style={escrowApprovalStyleing.titleResponse}>
                        <a
                          href={`${user?.copyrightURLFirebase}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Click Here
                        </a>
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      fontFamily: "Aileron",
                      fontSize: "14px",
                      textAlign: "left",
                      marginTop: "10px",
                    }}
                  >
                    <div style={escrowApprovalStyleing.title}>Royalty</div>
                    <div style={escrowApprovalStyleing.titleResponse}>
                      {user?.royalty}
                    </div>
                  </div>
                  <Divider style={{ marginTop: "5px", marginBottom: "5px" }} />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "10px",
                    }}
                  >
                    <div style={{ fontFamily: "Aileron", fontSize: "14px" }}>
                      <div style={escrowApprovalStyleing.title}>
                        Selling Price
                      </div>
                      <div
                        style={{
                          fontWeight: "500",
                          color: "#F2994A",
                        }}
                      >
                        {user?.sellingPrice}
                      </div>
                    </div>
                    <div style={{ fontFamily: "Aileron", fontSize: "14px" }}>
                      <div style={escrowApprovalStyleing.title}>Method</div>
                      <div
                        style={{
                          fontWeight: "500",
                          color: "#F2994A",
                        }}
                      >
                        {user?.method}
                      </div>
                    </div>
                    <div>
                      {/* {(sortByVal === "Approved" ||
                        sortByVal === "Rejected") && (
                          <div style={escrowApprovalStyleing.sortValueInCard}>
                            <div style={{ display: "flex", alignItems: "center" }} >
                              <img
                                className="market-place-admin status-icon"
                                src={`/img/admin-dashboard/${sortByVal.toLowerCase()}.png`}
                                alt={sortByVal}
                              />
                              <p style={escrowApprovalStyleing.sortValueInCardText} >
                                {sortByVal}
                              </p>
                            </div>
                          </div>
                        )} */}

                      {
                        sortByVal !== "Pending" ?
                          user?.adminVerificationStatus ===
                            "approved"
                            ? <Button style={{fontWeight:"bolder"}}  className="wallet-status-approved-chip"><img className="market-place-admin sidenav-icon" src={"/img/admin-dashboard/approved.png"} alt="nftArt" />
                              <p>Approved</p></Button>
                            : <Button style={{fontWeight:"bolder"}}  className="wallet-status-rejected-chip"><img className="market-place-admin sidenav-icon" src={"/img/admin-dashboard/rejected.png"} alt="nftArt" />
                              <p>Rejected</p></Button>
                          : null
                      }

                      {sortByVal === "Pending" && (
                        <Button style={{fontWeight:"bolder"}}  className="wallet-status-pending-chip" onClick={() => handleOpenModal(user)}><img className="market-place-admin sidenav-icon" src={"/img/admin-dashboard/pending.png"} alt="nftArt" />
                          <p>Pending</p></Button>
                      )}

                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="no-data-found-div">
                <p className="no-data-found-text">No Data Found</p>
              </div>
            )}

            <Modal
              title=""
              centered
              open={openModal}
              onOk={handleClose}
              onCancel={handleClose}
              // closable={false}
              className="wallet-modal"
              footer={[
                !isRejecting && (
                  <>
                <Button
                  className="wallet-modal-approve-button"
                  onClick={handleApprove}
                // disabled={isRejecting}
                >
                  Approve
                </Button>,
                 {/* <Button
                   className="wallet-modal-reject-button"
                   type="primary"
                   onClick={() => setOpenModal(false)}
                  disabled={isRejecting}
                 >
                   Cancel
                 </Button>, */}
                <Button
                  className="wallet-modal-reject-button"
                  type="primary"
                  onClick={() => setIsRejecting(true)}
                  disabled={isRejecting}
                >
                  Reject
                </Button>,
                 </>
                ),
                 <span key="textarea-container">
                    {isRejecting && (
                      <>
                        <textarea
                          required
                          className="wallet-modal-textarea"
                          value={escrowRejectionMessage}
                          onChange={(e) => setEscrowRejectionMessage(e.target.value)}
                          placeholder="Type here to rejection note ..."
                          style={{
                            width: "100%",
                            height: "60px",
                            marginBottom: "10px",
                          }}
                        />
                        <Button
                          key="Reject"
                          className="wallet-modal-reject-button"
                          onClick={handleReject}
                        >
                          Reject
                        </Button>
                      </>
                    )}
                  </span>,
              ]}
            >
              <span className="wallet-modal-heading-text">
                Are you need to approve this
              </span>
              <span className="wallet-modal-heading-sub-text">Pending
                Escrow Approvals ?</span>
            </Modal>
          </div>
          {/* Pagination  */}
          {filteredEscrow?.length ? (
            <div style={escrowApprovalStyleing.paginationContainer}>
              <Pagination
                current={currentPage}
                total={filteredEscrow?.length}
                pageSize={pageSize}
                onChange={onPageChange}
                showSizeChanger={false}
                showQuickJumper={false}
              />
              <input
                type="number"
                value={goToPage}
                onChange={(e) => setGoToPage(e.target.value)}
                min="1"
                max={totalPages}
                style={escrowApprovalStyleing.goInput}
              />
              <button
                onClick={handleGoToPage}
                style={escrowApprovalStyleing.goButton}
              >
                Go
              </button>
            </div>
          ) : null}
        </div>
      </>
    )
  );
};

export default EscrowApproval;
