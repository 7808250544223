// Faq.js
import React, { useState } from 'react';
import { Collapse } from 'antd';
import './styles/faqStyles.scss';

import PlusIcon from "./../assets/images/faq/plus_icon.png";
import MinusIcon from "./../assets/images/faq/Minus_icon.png";
import Banner from "./../assets/images/faq/faq_banner.png";
import { environment } from '../../components/environments/environment';

const { Panel } = Collapse;

const Faq = () => {
  const [activeKey, setActiveKey] = useState('');
  const { companyName } = environment || {};
  const def = {
    color: '#0d0c22',
    fontWeight: 'bold',
    display: 'block'
  };

  const cardLists = [
    {
      key: 1,
      title: 'What are NFTs?',
      cardContent: <p>
        Non-fungible tokens (NFTs) are unique digital assets representing ownership of various forms of digital media on the blockchain. Unlike cryptocurrencies, each NFT is distinct and cannot be exchanged for another. Artists and creators tokenize their work as NFTs, providing a secure and transparent way to buy, sell, and own digital content.
      </p>
    },
    {
      key: 2,
      title: 'Key Characteristics of NFTs',
      cardContent: <ul>
        <li><span style={def}>Uniqueness:</span> Each NFT is inherently unique, even if they share visual elements.</li>
        <li><span style={def}>Interoperability:</span> NFTs can be used across different platforms and environments.</li>
        <li><span style={def}>Transparency:</span> NFTs are linked to a distributed ledger, ensuring clear ownership and transaction history.</li>
        <li><span style={def}>Indivisibility:</span> NFTs cannot be divided; they exist as whole tokens.</li>
      </ul>
    },
    {
      key: 3,
      title: 'Exploring NFT Use Cases',
      cardContent: <>
        <p>NFTs have diverse applications, including:</p>
        <ul>
          <li><span style={def}>Digital: </span>Art: NFTs revolutionize the art world, allowing digital artists to tokenize and sell their work.</li>
          <li><span style={def}>Media:</span> Any digital media, from audio to video files, can be transformed into NFTs.</li>
          <li><span style={def}>Music:</span> Musicians benefit from NFTs by retaining proceeds from sales without intermediaries.</li>
          <li><span style={def}>Gaming:</span> NFTs enhance gaming experiences, offering unique in-game items and collectibles.</li>
          <li><span style={def}>Memes:</span> NFTs bring value to internet culture, allowing creators to tokenize and share digital memes.</li>
        </ul>
      </>
    },
    {
      key: 4,
      title: 'What is Blockchain technology',
      cardContent: <p>
        Blockchain is a transformative technology, is reshaping industries and revolutionizing the way we interact with digital assets. At the core of {companyName} lies the dynamic potential of blockchain, offering a decentralized and secure environment for the creation, trade, and ownership of Non-Fungible Tokens (NFTs). Let's embark on a journey to understand the intricacies of blockchain and its seamless integration into {companyName}.
      </p>
    },
    {
      key: 5,
      title: 'Key Components of Blockchain',
      cardContent: <ul>
        <li><span style={def}>Decentralization:</span> Blockchain operates on a decentralized network of computers, ensuring that no single entity holds control. This decentralization enhances security, transparency, and trust within the {companyName} ecosystem.</li>
        <li><span style={def}>Immutability:</span> Once information is recorded on the blockchain, it becomes nearly impossible to alter. Each block contains a unique cryptographic hash, linking it securely to the previous block, creating a chain resistant to tampering.</li>
        <li><span style={def}>Smart Contracts:</span> Smart contracts, self-executing agreements with coded terms, are a hallmark of blockchain technology. {companyName} leverages smart contracts to automate and enforce the execution of NFT transactions, ensuring efficiency and reliability.</li>
        <li><span style={def}>Transparency:</span> Blockchain's transparent nature allows participants in {companyName} to verify ownership, authenticity, and transaction history, fostering trust among users.</li>
      </ul>
    },
    {
      key: 6,
      title: `${companyName} and Blockchain Integration`,
      cardContent: <>
        <p>{companyName} stands as a beacon of innovation, harnessing the power of blockchain to redefine the digital art and collectibles landscape. Let's explore how {companyName} seamlessly integrates blockchain features into its platform:</p>
        <h4>1. Decentralized Creation and Trading:</h4>
        <p>{companyName} empowers artists and creators by providing a decentralized space for the creation and trading of NFTs. Artists can tokenize their unique creations without intermediaries, ensuring a direct connection with collectors.</p>
        <h4>2. Immutable Digital Ownership:</h4>
        <p>Blockchain's immutability guarantees the security and authenticity of digital ownership on {companyName}. Each NFT is securely recorded on the blockchain, creating a tamper-resistant record of ownership.</p>
        <h4>3. Smart Contracts for Royalties:</h4>
        <p>{companyName} utilizes smart contracts to automate royalty payments. Artists receive fair compensation automatically whenever their NFTs are resold, providing a transparent and equitable revenue-sharing model.</p>
        <h4>4. Transparency in Transactions:</h4>
        <p>Every transaction within {companyName} is recorded on the blockchain, allowing users to trace the history of each NFT. This transparency builds confidence and authenticity in the {companyName} platform.</p>
        <h4>5. Community Engagement through Governance:</h4>
        <p>{companyName} embraces decentralized governance, allowing the community to actively participate in decision-making processes. Through blockchain-based voting mechanisms, users shape the future direction of the platform.</p>
      </>
    },
    {
      key: 7,
      title: `Advantages of Blockchain in ${companyName}`,
      cardContent: <>
        <p>
          <b style={{ display: 'block', color: '#0d0c22' }}>Security and Trust:</b>
          Blockchain's cryptographic principles ensure the security and integrity of {companyName}'s NFTs, fostering trust among creators and collectors. Secure transactions on the blockchain create a reliable and tamper-resistant environment.
          Fair Compensation for Creators:
          Smart contracts on {companyName} guarantee fair compensation for artists, as royalties are automatically distributed based on predefined terms. The elimination of intermediaries ensures that creators receive the value they deserve.
        </p>
        <p>
          <b style={{ display: 'block', color: '#0d0c22' }}>Global Accessibility:</b>
          {companyName}, powered by blockchain, ensures global accessibility to the NFT marketplace. Artists and collectors from around the world can engage seamlessly.
          Immutability of Digital Assets:
          The immutability of blockchain records safeguards digital assets on {companyName}, ensuring the integrity and authenticity of each NFT.
        </p>
        <p>
          <b style={{ display: 'block', color: '#0d0c22' }}>Community-Driven Innovation:</b>
          Decentralized governance on {companyName} fosters a sense of community ownership, allowing users to actively contribute to the growth and development of the platform.
        </p>
        <p>
          <b style={{ display: 'block', color: '#0d0c22' }}>Future Perspectives:</b>
          {companyName}, with its commitment to innovation and user empowerment, is poised for an exciting future. As blockchain technology continues to evolve, {companyName} remains at the forefront, exploring new avenues to enhance the user experience, expand creative possibilities, and strengthen the NFT ecosystem.
          In conclusion, the integration of blockchain into {companyName} signifies a paradigm shift in the digital art and collectibles space. Through decentralization, transparency, and smart contracts, {companyName} stands as a beacon of progress, ushering in a new era where creators and collectors alike thrive in a secure and vibrant marketplace.
        </p>
      </>
    },
    {
      key: 8,
      title: 'What is Whitelisting Wallets?',
      cardContent: <p>Whitelisting is a process that allows specific Ethereum wallet addresses to gain access to exclusive features or events on {companyName}. It's a way to control participation and reward loyal users.</p>
    },
    {
      key: 9,
      title: 'Understanding Whitelisting',
      cardContent: <p>
        Think of whitelisting as your golden ticket to exclusive NFT events on {companyName}. It's like having a VIP pass to the most exciting digital art and collectibles experiences. This process involves pre-approving certain wallet addresses, creating a special list, or "whitelist," of participants who get the green light for major happenings.
        Being on the whitelist means you're part of an exclusive club of NFT enthusiasts who {companyName} trusts and values. You'll receive clear communication, after providing all the details you need to make the most of the upcoming NFT extravaganza.
      </p>
    },
    {
      key: 10,
      title: 'Building Trust and Security',
      cardContent: <p>
        {companyName} believes in trust and transparency. Whitelisting is not just a process; it's a promise to the {companyName} community that their NFT experiences will be secure, fair, and full of exciting opportunities. It's like having a personal guide on your NFT adventure, ensuring you're on the right path.
        Are you ready to be on the whitelist? Stay tuned for the next thrilling NFT event on {companyName}!
      </p>
    }
  ];

  const handlePanelChange = (key) => {
    setActiveKey(activeKey === key ? '' : key);
  };

  const handleCardChange = (e, key) => {
    e.stopPropagation();
    handlePanelChange(key);
  };

  const renderTitle = (cardKey, cardTitle) => (
    <>
      {
        activeKey === cardKey.toString() ? <img src={MinusIcon} alt='MinusIcon' className='minus-icon' onClick={(e) => handleCardChange(e, cardKey.toString())} />
          : <img src={PlusIcon} alt='PlusIcon' className='plus-icon' onClick={(e) => handleCardChange(e, cardKey.toString())} />
      }
      <div className='faq_container collapse-div' onClick={(e) => handleCardChange(e, cardKey.toString())}>
       
        <p className='cardTitle'>{cardTitle}</p>
        
      </div>
    </>
  );

  return (
    <>
      <div style={{ background: "#FFFFFF" }} className='faq_container'>
        <div className='card'>
          <img src={Banner} alt='Letters' className='learn-image' />
        </div>
        <Collapse
          accordion
          className='faqsection'
          activeKey={activeKey}
          onChange={handlePanelChange}
          style={{ background: "#white" }}
        >
          {
            cardLists.map((card) => (
              <Panel
                header={renderTitle(card.key, card.title)}
                key={card.key.toString()}
              >
                {activeKey === card.key.toString() &&
                  (
                    <div className='card-content'>
                      {card.cardContent}
                    </div>
                  )
                }
              </Panel>
            ))
          }
        </Collapse>
      </div>
    </>
  );
};

export default Faq;
