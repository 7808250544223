import { SharedService } from '../../Shared/Shared.service';
import { APIResponse} from '../../Shared/interfaces';
import { environment } from '../../../environments/environment';
import axios from 'axios';


const sharedService = new SharedService();


export class NFTCreatorService {
  private APIURL = environment.APIURL;

  convertToServerTimeZoneAndGetTimestamp(timestamp){
    //EST
    let offset = -5.0; //hour
    let clientDate = new Date(timestamp);

    let utc = clientDate.getTime() + (clientDate.getTimezoneOffset() * 60000);
    let serverDate = new Date(utc + (3600000*offset));

    return serverDate.getTime();
  }
  
  public uploadFile(file: Blob, progressCb?, errorCb?, completeCb?) {
    const req = new FormData();
    req.append('doc', file);

    sharedService.uploadDocument(`${this.APIURL}/nftCreator/uploadDocument`, req, progressCb, errorCb, completeCb)
  }

  async createNFT(form: any) {
      return axios.post<any, APIResponse>(
      `${this.APIURL}/nftCreator/createNFT`, form,
      { timeout: 10 * 60 * 1000, // Timeout of 10 minutes,
        headers: await sharedService.getAuthHeader() 
      });
  }

  async getLongTimeApiResponse() {
    return axios.get<any, APIResponse>(
      `${this.APIURL}/shared/getLongTimeApiResponse`,
      { headers: await sharedService.getAuthHeader() }
      );
  }

  async getUserNFT(userId: string, params: {networkId: string}) {
    return axios.get<any, APIResponse>(
    `${this.APIURL}/nftCreator/getUserNFT?userId=${userId}`,
    { headers: await sharedService.getAuthHeader(), params }
    );
  }

  async getNFTById(nftId: string, userId: string = "") {
    return axios.get<any, APIResponse>(
    `${this.APIURL}/nftCreator/getNFTById?nftId=${nftId}&userId=${userId}`,
    { headers: await sharedService.getAuthHeader() }
    );
  }

  async getNFTAuthorizeWalletAddress(userId: string, params: {networkId: string}) {
    return axios.get<any, APIResponse>(
    `${this.APIURL}/nftCreator/getNFTAuthorizeWalletAddress?userId=${userId}`,
    { headers: await sharedService.getAuthHeader(), params }
    );
  }

  async getAllCollections(params: {networkId: string}) {
    return axios.get<any, APIResponse>(
    `${this.APIURL}/nftCreator/getAllCollections`,
    { headers: await sharedService.getAuthHeader(), params }
    );
  }

  async getAllCollectionDetails(params: {networkId: string, pageSize: number}) {
    return axios.get<any, APIResponse>(
    `${this.APIURL}/nftCreator/getAllCollectionDetails`,
    { headers: await sharedService.getAuthHeader(), params }
    );
  }

  async getUserCollections(userId, params: {networkId: string}) {
    return axios.get<any, APIResponse>(
   `${this.APIURL}/nftCreator/getUserCollections?userId=${userId}`,
    { headers: await sharedService.getAuthHeader(), params }
    );
  }

  async getAllNfts(params: {networkId: string}) {
    return axios.get<any, APIResponse>(
    `${this.APIURL}/nftCreator/getAllNfts`,
    { headers: await sharedService.getAuthHeader(), params }
    );
  }

  async getCollectionDetailByName(collectionName: string, params: {networkId: string}) {
    return axios.get<any, APIResponse>(
    `${this.APIURL}/nftCreator/getCollectionDetailByName?collectionName=${collectionName}`,
    { headers: await sharedService.getAuthHeader(), params }
    );
  }

  async checkIfCollectionAlreadyExistsByName(collectionName: string, params: {networkId: string}) {
    return axios.get<any, APIResponse>(
    `${this.APIURL}/nftCreator/checkIfCollectionAlreadyExistsByName?collectionName=${collectionName}`,
    { headers: await sharedService.getAuthHeader(), params }
    );
  }

  async getNFTFirebaseURLbyOwner(userId: string, params: {networkId: string, nftId: string}) {
    return axios.get<any, APIResponse>(
    `${this.APIURL}/nftCreator/getNFTFirebaseURLbyOwner?userId=${userId}`,
    { headers: await sharedService.getAuthHeader(), params }
    );
  }

  async checkCollection(form: any) {
      return axios.post<any, APIResponse>(
      `${this.APIURL}/nftCreator/checkCollection`,form,
      { headers: await sharedService.getAuthHeader() }
      );
  }

  async sellNFT(form: any) {
    return axios.post<any, APIResponse>(
    `${this.APIURL}/nftCreator/sellNFT`,form,
    { headers: await sharedService.getAuthHeader() }
    );
  }

  async getMinimumNoOfFollowersForGoldenBadge(userId: string) {
    return axios.get<any, APIResponse>(
    `${this.APIURL}/nftCreator/getMinimumNoOfFollowersForGoldenBadge?userId=${userId}`,
    { headers: await sharedService.getAuthHeader() }
    );
  }

  async updateSocialMedia(form: any) {
    return axios.post<any, APIResponse>(
    `${this.APIURL}/nftCreator/updateSocialMedia`,form,
    { headers: await sharedService.getAuthHeader() }
    );
  }

  async addDataToUserProfile(userId: string, form: any) {
    return axios.post<any, APIResponse>(
    `${this.APIURL}/users/addDataToUserProfileByUserId?userId=${userId}`,form,
    { headers: await sharedService.getAuthHeader() }
    );
  }

  //update nft price by nft Id
  async editNftDetails(nftId: any, form: any) {
    return axios.post<any, APIResponse>(
    `${this.APIURL}/nftCreator/editNftDetails?nftId=${nftId}`,form,
    { headers: await sharedService.getAuthHeader() }
    );
  }

  async mintNFT(form: any) {
    return axios.post<any, APIResponse>(
    `${this.APIURL}/nftCreator/mintNFT`,form,
    { headers: await sharedService.getAuthHeader() }
    );
  }

  async createCollectionBlockchain(body: any) {
    return axios.post<any, APIResponse>(
    `${this.APIURL}/nftCreator/createCollectionBlockchain`,body,
    { headers: await sharedService.getAuthHeader() }
    );
  }


  async getAvailableNFT(params: {networkId: string}) {
    return axios.get<any, APIResponse>(
    `${this.APIURL}/nftCreator/getAvailableNFT`,
    { headers: await sharedService.getAuthHeader(), params }
    );
  }

  async getSoldNFT(params: {networkId: string}) {
    return axios.get<any, APIResponse>(
    `${this.APIURL}/nftCreator/getSoldNFT`,
    { headers: await sharedService.getAuthHeader(), params }
    );
  }

  async getAllNFT(params: {networkId: string}) {
    return axios.get<any, APIResponse>(
    `${this.APIURL}/nftCreator/getAllNFT`,
    { headers: await sharedService.getAuthHeader(), params }
    );
  }

  async getAvailableSellingNFT(params: {networkId: string}) {
    return axios.get<any, APIResponse>(
    `${this.APIURL}/nftCreator/getAvailableSellingNFT`,
    { headers: await sharedService.getAuthHeader(), params }
    );
  }

  async getCategorisedNFT(categoryName, params: {networkId: string}) {
    return axios.get<any, APIResponse>(
    `${this.APIURL}/nftCreator/getCategorisedNFT?categoryName=${categoryName}`,
    { headers: await sharedService.getAuthHeader(), params }
    );
  }

  async removeCopyrightNotice(nftId: any) {
    return axios.post<any, APIResponse>(
    `${this.APIURL}/nftCreator/removeCopyrightNotice?nftId=${nftId}`,
    { headers: await sharedService.getAuthHeader() }
    );
  }

  async removeWatermark(nftId: any) {
    return axios.post<any, APIResponse>(
    `${this.APIURL}/nftCreator/removeWaterMark?nftId=${nftId}`,
    { headers: await sharedService.getAuthHeader() }
    );
  }

  async reportCollectible(data: any) {
    return axios.post<any, APIResponse>(
    `${this.APIURL}/nftCreator/reportCollectible`,
      data,
    { headers: await sharedService.getAuthHeader() }
    );
  }

  async getWalletWhitelistingRequest(userId, params: {networkId: string}) {
    return axios.get<any, APIResponse>(
    `${this.APIURL}/nftCreator/getWalletWhitelistingRequest?userId=${userId}`,
    { headers: await sharedService.getAuthHeader(), params }
    );
  }

  async reviewNFTByAdmin(nftId) {
    return axios.get<any, APIResponse>(
    `${this.APIURL}/nftCreator/reviewNFTByAdmin?nftId=${nftId}`,
    { headers: await sharedService.getAuthHeader() }
    );
  }

  async getAllCategories() {
    return axios.get<any, APIResponse>(
    `${this.APIURL}/nftCreator/getAllCategories`,
    { headers: await sharedService.getAuthHeader() }
    );
  }

  async getFileSize() {
    return axios.get<any, APIResponse>(
    `${this.APIURL}/nftCreator/getFileSize`,
    { headers: await sharedService.getAuthHeader() }
    );
  }

  async buyNFT(form: any) {
    return axios.post<any, APIResponse>(
    `${this.APIURL}/nftCreator/buyNFT`,
      form,
    { headers: await sharedService.getAuthHeader() }
    );
  }

  async getUserSomeDetailsByUsersIds(userIds: string) {
    return axios.get<any, APIResponse>(
    `${this.APIURL}/nftCreator/getUserSomeDetailsByUsersIds?userIds=${userIds}`,
    { headers: await sharedService.getAuthHeader() }
    );
  }

  async saveBidForNft(form: any) {
    return axios.post<any, APIResponse>(
    `${this.APIURL}/nftCreator/saveBidForNft`,
      form,
    { headers: await sharedService.getAuthHeader() }
    );
  }

  async getUserAgreementSignURL(userId: any) {
    return axios.get<any, APIResponse>(
      `${this.APIURL}/nftCreator/getUserAgreementSignURL?userId=${userId}`,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async setUserAgreementSigned(form: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/nftCreator/setUserAgreementSigned`,
      form,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async getSellBuyNFTAgreementURL(userId: any) {
    return axios.get<any, APIResponse>(
      `${this.APIURL}/nftCreator/getSellBuyNFTAgreementURL?userId=${userId}`,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async setSellBuyNFTAgreement(form: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/nftCreator/setSellBuyNFTAgreement`,
      form,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async getUserAllAgreements(userId: any) {
    return axios.get<any, APIResponse>(
      `${this.APIURL}/nftCreator/getUserAllAgreements?userId=${userId}`,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async requestForSellToHighestBiddedUser(form: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/nftCreator/requestForSellToHighestBiddedUser`,
      form,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async requestForSellToAnyBiddedUser(form: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/nftCreator/requestForSellToAnyBiddedUser`,
      form,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async updateExpirationDate(form: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/nftCreator/updateExpirationDate`,
      form,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async relistNFT(form: any) {
    return axios.put<any, APIResponse>(
    `${this.APIURL}/nftCreator/relistNFT`, form,
    { headers: await sharedService.getAuthHeader() });
  }

  async claimAmountInEscrow(form: any) {
    return axios.post<any, APIResponse>(
    `${this.APIURL}/nftCreator/claimAmountInEscrow`, form,
    { headers: await sharedService.getAuthHeader() });
  }

  async getNFTEscrowStatus(nftId: any, networkId: any) {
    return axios.get<any, APIResponse>(
      `${this.APIURL}/nftCreator/getNFTEscrowStatus?nftId=${nftId}&networkId=${networkId}`,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async approveEscrowByComplianceAdmin(form: any) {
    return axios.post<any, APIResponse>(
    `${this.APIURL}/nftCreator/approveEscrowByComplianceAdmin`, form,
    { headers: await sharedService.getAuthHeader() });
  }

  async approveEscrowByTokenAdmin(form: any) {
    return axios.post<any, APIResponse>(
    `${this.APIURL}/nftCreator/approveEscrowByTokenAdmin`, form,
    { headers: await sharedService.getAuthHeader() });
  }

  async approveEscrowBySuperAdmin(form: any) {
    return axios.post<any, APIResponse>(
    `${this.APIURL}/nftCreator/approveEscrowBySuperAdmin`, form,
    { headers: await sharedService.getAuthHeader() });
  }

  // collection uri updation in api for ipfs upload
  async createCollectionURI(form: any) {
    return axios.post<any, APIResponse>(
    `${this.APIURL}/nftCreator/createCollectionURI`, form,
    { timeout: 10 * 60 * 1000, // Timeout of 10 minutes,
      headers: await sharedService.getAuthHeader() 
    });
  }

  // checking for any blockchain transaction pending
  async checkPendingTransactionByType(type: string, userId: any, collectionId: any, nftId: any) {
    return axios.get<any, APIResponse>(
    `${this.APIURL}/nftCreator/checkPendingTransactionByType?type=${type}&userId=${userId}&collectionId=${collectionId}&nftId=${nftId}`,
    { headers: await sharedService.getAuthHeader() }
    );
  }

  async addBlockchainHashToPendingTransaction(form: any) {
    return axios.post<any, APIResponse>(
    `${this.APIURL}/nftCreator/addBlockchainHashToPendingTransaction`, form,
    { 
      headers: await sharedService.getAuthHeader() 
    });
  }
}