import React from 'react'
// import { lazy } from '@loadable/component';
import { Switch, Route, useRouteMatch } from "react-router-dom";
import CreateAdminRole from './CreateAdminRoles'
import ManageAllAdminRoles  from './ManageAllAdminRoles'
import AddServiceProviders from './AddServiceProviders'
import ViewAllIssuers from './ViewAllIssuers';
import ManageAPIKey from './ManageAPIKey';
import KYCDetailsPage from '../../pages/KYCDetailsPage';
import VerifySuperAdminProfile from '../../pages/admin/verifySuperAdminProfile';
import VerifyComplianceAdminProfile from '../../pages/admin/VerifyComplianceAdminProfile';
import VerifyTokenAdminProfile from '../../pages/admin/VerifyTokenAdminProfile';
import PlatformSuperAdminView from '../../pages/admin/PlatformSuperAdminView';
import MarketPlaceSuperAdmin from '../../pages/admin/MarketplaceSuperAdmin/index';
import TokenAdmin from '../../pages/admin/TokenAdmin/index';
import MarketPlaceComplianceAdmin from '../../pages/admin/MarketPlaceComplianceAdmin.js';

export default () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/kyc/:kycId`}>
        <KYCDetailsPage />
      </Route>
      <Route path={`${path}/verify-super-admin-profile`}>
        <VerifySuperAdminProfile />
      </Route>
      <Route path={`${path}/verify-compliance-admin-profile`}>
        <VerifyComplianceAdminProfile />
      </Route>
      <Route path={`${path}/verify-token-admin-profile`}>
        <VerifyTokenAdminProfile />
      </Route>
      <Route path={`${path}/platform-super-admin-view`}>
        <PlatformSuperAdminView />
      </Route>
      <Route path={`${path}/super-admin`}>
        <MarketPlaceSuperAdmin />
      </Route>
      <Route path={`${path}/compliance-admin`}>
        <MarketPlaceComplianceAdmin />
      </Route>
      <Route path={`${path}/token-admin`}>
        <TokenAdmin />
      </Route>
      <Route path={`${path}/create-admin-role`}>
        <CreateAdminRole/>
      </Route>
      <Route path={`${path}/manage-all-admin-roles`}>
        <ManageAllAdminRoles/>
      </Route>
      <Route path={`${path}/add-service-providers`}>
        <AddServiceProviders/>
      </Route>
      <Route path={`${path}/view-all-issuers`}>
        <ViewAllIssuers/>
      </Route>
      <Route path={`${path}/manage-api-key`}>
        <ManageAPIKey/>
      </Route>
    </Switch>
  )
}