import React from 'react';
import { useHistory } from "react-router-dom";
import './learn.scss';
import Letters from "../../assets/images/faq/learn_banner.png";

const Learns = () => {
    const history = useHistory();

    const cardStyle = {
        cursor: "pointer",
        width: "385px",
        height: "143px",
        display: "flex",
        flexDirection: "row",
        
       // margin: "12px",
        padding: "12px",
        // Adjust the font size value as needed
    };


    return (
        <div className='col-12 learns-container'>
            <div className="d-flex justify-content-center">
                <img src={Letters} alt="Letters" className="learn-image" style={{ height: "100%", width: "100%" }} />
            </div>
            <div className='row col-12 pt-5' style={{ margin: "0px 0px 0px 130px" ,width:"1200px" }}>
                    <div className="col-md-6 col-lg-4">
                        <div className="learns card nft-card" onClick={() => history.push(`/learns/learn1`)} style={cardStyle}>
                            <img src={`/img/learns/learnIcon1.png`} className="lazy nft__item_preview home-page card-img-top" alt="category-img" />
                            <div className="learns card-body nft_card">
                                <h5 className="home-page nft-method">Step-by-step guide to registering on our NFT marketplace</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 mb-4">
                        <div className="learns card nft-card" onClick={() => history.push(`/learns/learn2`)} style={cardStyle}>
                            <img src={`/img/learns/learnIcon2.png`} className="lazy nft__item_preview home-page card-img-top" alt="category-img" />
                            <div className="learns card-body nft_card">
                                <h5 className="home-page nft-method">How to do Whitelisting of the Wallet?</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 mb-4">
                        <div className="learns card nft-card" onClick={() => history.push(`/learns/learn3`)} style={cardStyle}>
                            <img src={`/img/learns/learnIcon3.png`} className="lazy nft__item_preview home-page card-img-top" alt="category-img" />
                            <div className="learns card-body nft_card">
                                <h5 className="home-page nft-method">How to create an NFT?</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 mb-4">
                        <div className="learns card nft-card" onClick={() => history.push(`/learns/learn4`)} style={cardStyle}>
                            <img src={`/img/learns/learnIcon4.png`} className="lazy nft__item_preview home-page card-img-top" alt="category-img" />
                            <div className="learns card-body nft_card">
                                <h5 className="home-page nft-method">How to Mint your NFT?</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 mb-4">
                        <div className="learns card nft-card" onClick={() => history.push(`/learns/learn5`)} style={cardStyle}>
                            <img src={`/img/learns/learnIcon5.png`} className="lazy nft__item_preview home-page card-img-top" alt="category-img" />
                            <div className="learns card-body nft_card" >
                                <h5 className="home-page nft-method">How does a Buyer purchase an NFT using the Fixed Price method Purchase of NFT by Buyer using Fixed Price?</h5>
                        </div>
                    </div>
                </div>

                <div className="col-md-6 col-lg-4 mb-4">
                    <div className="learns card nft-card" onClick={() => history.push(`/learns/learn6`)} style={cardStyle}>
                        <img src={`/img/learns/learnIcon6.png`} className="lazy nft__item_preview home-page card-img-top" alt="category-img" />
                        <div className="learns card-body nft_card">
                            <h5 className="home-page nft-method">How to purchase an NFT through Place a Bids?</h5>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-4 mb-4">
                    <div className="learns card nft-card" onClick={() => history.push(`/learns/learn7`)} style={cardStyle}>
                        <img src={`/img/learns/learnIcon7.png`} className="lazy nft__item_preview home-page card-img-top" alt="category-img" />
                        <div className="learns card-body nft_card">
                            <h5 className="home-page nft-method">How to take part in timed auction for NFT’s?</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Learns;
