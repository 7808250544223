/* eslint-disable import/no-anonymous-default-export */
import React from "react";
// import { lazy } from '@loadable/component';
import { Switch, Route, useRouteMatch } from "react-router-dom";
import Learn1 from "./Learn1";
import Learns from "./Learns";
import Learn2 from "./Learn2";
import Learn3 from "./Learn3";
import Learn4 from "./Learn4";
import Learn5 from "./Learn5";
import Learn6 from "./Learn6";
import Learn7 from "./Learn7";

export default () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/learn1`}>
        <Learn1 />
      </Route>
      <Route path={`${path}/learn2`}>
        <Learn2 />
      </Route>
      <Route path={`${path}/learn3`}>
        <Learn3 />
      </Route>
      <Route path={`${path}/learn4`}>
        <Learn4 />
      </Route>
      <Route path={`${path}/learn5`}>
        <Learn5 />
      </Route>
      <Route path={`${path}/learn6`}>
        <Learn6 />
      </Route>
      <Route path={`${path}/learn7`}>
        <Learn7 />
      </Route>
      <Route path={`${path}`}>
        <Learns />
      </Route>
    </Switch>
  );
};
