import React from "react";
import "./learn.scss";

const Learn6 = () => {
  return (
    <div className="container learn-container">
      <h3 class="blog-heading">How to purchase an NFT through Place a Bids</h3>
      <img
        className="blog-image horizontal-middle-align"
        src={`/img/learns/learnPageIcon6.png`}
        alt="learn-img"
        /* style={{ width: "600px", height: "550px" }} */
      />
      <h4>Purchasing an NFT with Place a Bids:</h4>
      <h6>
        If you're interested in purchasing an NFT through the bidding process,
        there are a few steps to follow.
      </h6>
      <ul>
        <li>
          First, you'll need to register on the platform and complete the KYC
          process, which includes wallet address whitelisting. Once that's done,
          head over to the "Market" or "Explore" section to browse available
          NFTs. When you find one you like, click on the "Buy Now" option.
        </li>
        <li>
          A new page will open with detailed information about the NFT, such as
          its name, collection name and price.
        </li>
        <li>
          If you're ready to bid on it, simply click on the "Click here to Place
          a Bid" button and enter your bid amount in ETH.
        </li>
        <li>
          You'll receive an email asking you to sign the NFT Buy and Sell
          Agreement by filling in your name, address and contact number.
        </li>
        <li>
          Once that's done and your bid is accepted by the seller, it's time to
          make payment for your new purchase using your MetaMask wallet.
        </li>
        <li>
          After completing this step successfully, an email titled "Successfully
          Purchased NFT" will be delivered to your account.
        </li>
        <li>
          Throughout this process, you can keep track of all bidding details by
          navigating back over to the market page where all relevant information
          about bidders (names, prices, dates) is displayed clearly for ease of
          reference.
        </li>
      </ul>
    </div>
  );
};

export default Learn6;
