import React, {useState, useEffect} from 'react'
import { environment } from '../environments/environment';
import CompanyContentQArt from '../dynamicContent/Content.qart';

const CompanyContent = () => {
  return (
    <>
      {environment.envName === 'qart' && <CompanyContentQArt />}
    </>
  )
}

export default CompanyContent