import React, { useState, useEffect, useContext } from "react";
import { Card, Typography, message } from "antd";
import CardHOC from "./../components/CardHOC";
import { SharedService } from "./../components/Shared/Shared.service";
import { NFTCreatorService } from "./../components/services/NFTCreator/NFTCreator.service";
import authContext from "./../components/Shared/Auth.service";
import { useHistory } from "react-router-dom";
import "./styles/KYCUpload.scss";
import { NoStyleItemContext } from "antd/lib/form/context";

const { Text } = Typography;
const sharedService = new SharedService();
const nftService = new NFTCreatorService();

const KYCUpload = () => {
  const { userInfo, setUserInfo } = useContext(authContext);
  const [uniqueURL, setUniqueURL] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  //console.log(userInfo);

  useEffect(() => {
    if (userInfo) {
      (async () => {
        setLoading(true);
        try {
          // let res = await sharedService.generateLinkForKYC({ userId: userInfo?._id });
          // if (res.data.length > 0) {
          //   history.push("/profile");
          // }
          let res = await sharedService.generateLinkForKYC({
            companyId: userInfo.company.id,
            companyName: userInfo.company.name,
            role: userInfo.role,
            email: userInfo.email,
            userId: userInfo._id,
          });

          if (!res || !res.success || !res.data) {
            message.error("Something went wrong, try again.");
            setLoading(false);
            return;
          }
          setUniqueURL(res.data);
        } catch (e) {
          message.error("Something went wrong, try again.");
        }
        setLoading(false);
      })();
    }
  }, [history, userInfo]);

  return (
    <>
      <Card loading={loading}>
        <Text>
          we have sent you a link on your registered email. Please open the link
          in your smart phone to complete KYC. If you haven't recieved the link
          you can type in the below given URL directly in your browser to
          complete KYC.
        </Text>
        <br />
        <br />
        <a href={uniqueURL} rel="noopener noreferrer" target="_blank">
          {uniqueURL}
        </a>
        <br />
        <br />
        <Text>
          <b>
            Once you complete KYC please refresh the page to continue your
            investement process.
          </b>
        </Text>
      </Card>
    </>
  );
};

export default () => <CardHOC component={<KYCUpload />} heading="KYC Upload" />;
