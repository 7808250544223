import { SharedService } from '../../Shared/Shared.service';
import { APIResponse} from '../../Shared/interfaces';
import { environment } from '../../../environments/environment';
import axios from 'axios';


const sharedService = new SharedService();


export class HomeService {
  private APIURL = environment.APIURL;
  
  public uploadFile(file: Blob, progressCb?, errorCb?, completeCb?) {
    const req = new FormData();
    req.append('doc', file);

    sharedService.uploadDocument(`${this.APIURL}/teamMember/uploadDocument`, req, progressCb, errorCb, completeCb)
  }

  async getCategories(){
    return axios.get<any, APIResponse>(`${this.APIURL}/home/getCategories`, {headers: await sharedService.getAuthHeader()});    
  }

  async getNewCategories(){
    return axios.get<any, APIResponse>(`${this.APIURL}/home/getNewCategories`, {headers: await sharedService.getAuthHeader()});    
  }


}