import React from "react";
import './learn.scss';

const Learn4 = () => {
  return (
    <div className="container learn-container">
      <h3 class="blog-heading">How to Mint your NFT</h3>
      <img className="blog-image horizontal-middle-align" src={`/img/learns/learnPageIcon4.png`} alt="learn-img" 
       /* style={{ width: "600px", height: "550px" }} *//>
      <p>
        After completing the Registration , KYC process, and Whitelisting
        process the Creator can mint the NFT, by following these simple steps:
      </p>
      <ul>
        <li>
          Firstly, click on the "Mint" button - it should be easily visible and
          accessible.
        </li>
        <li>
          Next up, the NFT Creator will be prompted to sign the NFT Buy and Sell
          Agreement - this is a crucial step that can't be skipped.
        </li>
        <li>
          Shortly after, an email will be sent to the NFT Creator, requesting
          them to sign the Agreement as well.
        </li>
        <li>
          Once they've signed it, they'll receive a confirmation mail titled
          "Document Completed" in their inbox.
        </li>
        <li>
          By following these steps diligently and completing each one
          successfully, the NFT Creator can finally mint their very own NFT.
        </li>
      </ul>
      <h4>Placing NFT for Sale</h4>
      <p>
        After successfully minting the NFT, it's time to put it up for sale. To
        do so, simply click on the "Place for Sell" button.
      </p>
      <p>
        This will allow the NFT Creator to kickstart the process of making the
        NFT available for purchase on the marketplace.
      </p>
    </div>
  );
};

export default Learn4;
