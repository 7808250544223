import React, { useContext, useEffect, useState } from 'react'
import { AdminService } from '../../../../components/services/AdminService/Admin.service';
import authContext from '../../../../components/Shared/Auth.service';
import { MetamaskService } from '../../../../components/Shared/Metamask.service';
import { Button, Divider, Select, Table, notification } from 'antd';
import { AdminRolesService } from '../../../../components/Shared/Blockchain/AdminRoles/AdminRoles.service';

const adminService = new AdminService();
const useSelectedWalletContext = () =>
  new MetamaskService().useSelectedWalletContext();
const adminRolesService = new AdminRolesService();

function AdminApprovals(props) {
  const { setLoading, smartContractData, loading } = props || {}
  const { userInfo } = useContext(authContext);
  const { networkId } = useSelectedWalletContext();
  const [sortByVal, setSortByVal] = useState("Pending");
  const [marketplaceComplianceAdmin, setMarketplaceComplianceAdmin] = useState(false);
  const [marketplaceTokenAdmin, setMarketplaceTokenAdmin] = useState();
  const [marketplaceComplianceAdminApproved, setMarketplaceComplianceAdminApproved] = useState();
  const [marketplaceTokenAdminApproved, setMarketplaceTokenAdminApproved] = useState();
  const [showPending, setShowPending] = useState(false);
  const [showVerified, setShowVerified] = useState(false);

  // Filtered data for approved marketplaceComplianceAdmin
  const approvedComplianceAdmin = marketplaceComplianceAdminApproved?.status?.blockchainVerified?.[networkId] === "approved" ?
    marketplaceComplianceAdminApproved : null;

  // Filtered data for approved marketplaceTokenAdmin
  const approvedTokenAdmin = marketplaceTokenAdminApproved?.status?.blockchainVerified?.[networkId] === "approved" ?
    marketplaceTokenAdminApproved : null;


  // Filtered data for approved marketplaceComplianceAdmin
  const rejectedComplianceAdmin = marketplaceComplianceAdminApproved?.status?.blockchainVerified?.[networkId] === "rejected" ?
    marketplaceComplianceAdminApproved : null;

  // Filtered data for approved marketplaceTokenAdmin
  const rejectedTokenAdmin = marketplaceTokenAdminApproved?.status?.blockchainVerified?.[networkId] === "rejected" ?
    marketplaceTokenAdminApproved : null;

  useEffect(() => {
    fetchData();
  }, [networkId, userInfo])

  const handleChange = (value) => {
    setSortByVal(value);
  }

  const fetchData = async () => {
    setLoading(true);
    try {
      // admin approval
      let res3 = await adminService.getMarketplaceAdminDetails(userInfo?._id, { networkId });
      if (res3.data) {
        if (res3.data.marketplaceComplianceAdmin)
          setMarketplaceComplianceAdmin(res3.data.marketplaceComplianceAdmin);
        if (res3.data.marketplaceTokenAdmin)
          setMarketplaceTokenAdmin(res3.data.marketplaceTokenAdmin);
        if (
          res3.data.marketplaceComplianceAdmin ||
          res3.data.marketplaceTokenAdmin
        )
          setShowPending(true);

        if (res3.data.marketplaceComplianceAdminVerified)
          setMarketplaceComplianceAdminApproved(
            res3.data.marketplaceComplianceAdminVerified
          );
        if (res3.data.marketplaceTokenAdminVerified)
          setMarketplaceTokenAdminApproved(
            res3.data.marketplaceTokenAdminVerified
          );
        if (
          res3.data.marketplaceComplianceAdminVerified ||
          res3.data.marketplaceTokenAdminVerified
        )
          setShowVerified(true);
      }
    } catch (error) {
      console.log(error);
      notification.open({
        message: "Error",
        description: "something went wrong while fetching admin profile data",
        duration: 0,
      });
    }
    setLoading(false);
  }

  // Approve profile is for Approving admin profile, here we need to whitelist compliance (or) token admin wallet address
  const approveProfile = async (email, role, status) => {
    if (!smartContractData?.adminRolesContract) {
      notification.open({
        message: 'Error',
        description: 'Admin Roles contract address not found',
        duration: 0,
      });
      return;
    }

    setLoading(true);

    try {
      if (role === "marketplace-compliance-admin" && status === "approved") {
        try {
          const txnReceipt = await adminRolesService.addingComplianceAdmin(
            String(marketplaceComplianceAdmin?.walletAddress),
            String(userInfo?.walletAddress),
            String(smartContractData?.adminRolesContract)
          );

          console.log("Blockchain Transaction receipt: ", txnReceipt);
          if (txnReceipt) {
            let form = {
              email,
              role,
              status,
              MPsuperAdmin: userInfo?.walletAddress,
              MPCAdmin: marketplaceComplianceAdmin?.walletAddress,
              transactionDetail: txnReceipt,
              networkId,
            };

            let res = await adminService.approveMarketplaceAdminDetails(form);
            if (res.data) {
              setLoading(false);
              notification.open({
                message: `Successfully whitelisted/Added MarketplaceCompliance-Admin: ${marketplaceComplianceAdmin?.walletAddress}`,
                duration: 0,
              });
              fetchData();
            }
          }
        } catch (error) {
          setLoading(false);
          notification.open({
            message: error.message,
            duration: 0,
          });
        }
      } else if (role === "marketplace-token-admin" && status === "approved") {
        try {
          const txnReceipt = await adminRolesService.addingTokenAdmin(
            String(marketplaceTokenAdmin?.walletAddress),
            String(userInfo?.walletAddress),
            String(smartContractData?.adminRolesContract)
          );
          console.log("Blockchain Transaction receipt: ", txnReceipt);

          if (txnReceipt) {
            let form = {
              email,
              role,
              status,
              approving: "MPTokenAdmin",
              MPsuperAdmin: userInfo?.walletAddress,
              MPTAdmin: marketplaceTokenAdmin?.walletAddress,
              transactionDetail: txnReceipt,
              networkId,
            };

            let res = await adminService.approveMarketplaceAdminDetails(form);
            if (res.data) {
              setLoading(false);
              notification.open({
                message: `Successfully whitelisted/Added MarketplaceToken-Admin: ${marketplaceTokenAdmin?.walletAddress}`,
                duration: 0,
              });
              fetchData();
            }
          }
        } catch (error) {
          setLoading(false);
          notification.open({
            message: error.message,
            duration: 0,
          });
        }
      } else if (
        role === "marketplace-compliance-admin" ||
        ("marketplace-token-admin" && status === "rejected")
      ) {
        try {
          let form = {
            email,
            role,
            status,
            networkId
          };

          let res = await adminService.approveMarketplaceAdminDetails(form);
          if (res.data) {
            setLoading(false);
            notification.open({
              message: `Successfully rejected MarketplaceCompliance-Admin: ${marketplaceComplianceAdmin?.walletAddress}`,
              duration: 0,
            });
            fetchData();
          }
        } catch (error) {
          setLoading(false);
          notification.open({
            message: error.message,
            duration: 0,
          });
        }
      }
    } catch (error) {
      console.log(error);
      notification.open({
        message: "Error",
        description: "something went wrong while approving profile",
        duration: 0,
      });
    }
  };


  return (
    !loading && <>
      <p className='market-super-admin commission-configuration-text'>NFT Admin Approval</p>
      <div className='market-super-admin admin-invites golden-badge-approval card-container d-flex justify-content-center flex-column'>
        <div style={{ width: '100%' }}>
          <div className='d-flex col-12' style={{ padding: "20px 20px 10px" }}>
            <div className='d-flex justify-content-center align-items-center'>
              {sortByVal === "Approved" && <img className="market-place-admin status-icon" src={"/img/admin-dashboard/approved.png"} alt="nftArt" />}
              {sortByVal === "Rejected" && <img className="market-place-admin status-icon" src={"/img/admin-dashboard/rejected.png"} alt="nftArt" />}
              {sortByVal === "Pending" && <img className="market-place-admin status-icon" src={"/img/admin-dashboard/pending.png"} alt="nftArt" />}
              <p className='wallet-status-text'>{sortByVal}</p>
            </div>
            <div className='d-flex' style={{ marginLeft: 'auto' }}>
              <div className='sort-by-text'>
              <img
                  className="market-place-admin status-icon"
                  src={"/img/admin-dashboard/sort-icon.png"}
                  alt="nftArt"
                />
              Sort By:</div>
              <Select
                className='sort-by-select'
                defaultValue={sortByVal}
                onChange={handleChange}
                options={[
                  {
                    value: 'Pending',
                    label: 'Pending',
                  },
                  {
                    value: 'Approved',
                    label: 'Approved',
                  },
                  {
                    value: 'Rejected',
                    label: 'Rejected',
                  },
                ]}
              />
            </div>
          </div>
          <Divider className='golden-badge-approval-divider' />

          {sortByVal === "Pending" && (
            marketplaceComplianceAdmin || marketplaceTokenAdmin ?
              <Table
                pagination={false}
                dataSource={[
                  marketplaceComplianceAdmin?.email && {
                    key: '1',
                    admin: {
                      profile: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQESgEgkgFa6JBZxS_lIO7glUy3SWRmbfO4K7sTq6_1NA&s"
                    },
                    firstName: marketplaceComplianceAdmin.firstName,
                    lastName: marketplaceComplianceAdmin.lastName,
                    email: marketplaceComplianceAdmin.email,
                    role: marketplaceComplianceAdmin.role,
                  },
                  marketplaceTokenAdmin?.email && {
                    key: '2',
                    admin: {
                      profile: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQESgEgkgFa6JBZxS_lIO7glUy3SWRmbfO4K7sTq6_1NA&s"
                    },
                    firstName: marketplaceTokenAdmin.firstName,
                    lastName: marketplaceTokenAdmin.lastName,
                    email: marketplaceTokenAdmin.email,
                    role: marketplaceTokenAdmin.role,
                  }
                ].filter(Boolean)}
              >

                {/* <Table.Column
                  title="Profile"
                  dataIndex="profile"
                  key="profile"
                  render={(_, { admin }) => (
                    <img
                      className="market-place-admin table-selfie-img"
                      src={admin?.profile}
                      alt=""
                    />
                  )}
                /> */}
                <Table.Column title="First Name" dataIndex="firstName" key="firstName" />
                <Table.Column title="Last Name" dataIndex="lastName" key="lastName" />
                <Table.Column title="Email" dataIndex="email" key="email" />
                <Table.Column title="Role" dataIndex="role" key="role" />
                <Table.Column
                  title="Action"
                  key="action"
                  render={(text, record) => (
                    <span>
                      <Button style={{fontWeight:"bolder"}} className='wallet-modal-approve-button admin-approval-btn' onClick={() => approveProfile(record.email, record.role, "approved")}>Approve</Button>
                      <Button style={{fontWeight:"bolder"}} className='wallet-modal-reject-button admin-approval-btn' type="primary" onClick={() => approveProfile(record.email, record.role, "rejected")}>Reject</Button>
                    </span>
                  )}
                />
              </Table>
              : <div className='no-data-found-div'>
                <p className='no-data-found-text'>No Data Found</p>
              </div>
          )}

          {sortByVal === "Approved" && (
            approvedComplianceAdmin || approvedTokenAdmin ? <Table
              pagination={false}
              dataSource={[
                approvedComplianceAdmin?.email && {
                  key: '1',
                  admin: {
                    profile: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQESgEgkgFa6JBZxS_lIO7glUy3SWRmbfO4K7sTq6_1NA&s"
                  },
                  firstName: approvedComplianceAdmin.firstName,
                  lastName: approvedComplianceAdmin.lastName,
                  email: approvedComplianceAdmin.email,
                  role: approvedComplianceAdmin.role,
                  status: approvedComplianceAdmin.status?.blockchainVerified?.[networkId],
                },
                marketplaceTokenAdminApproved?.email && {
                  key: '2',
                  admin: {
                    profile: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQESgEgkgFa6JBZxS_lIO7glUy3SWRmbfO4K7sTq6_1NA&s"
                  },
                  firstName: approvedTokenAdmin.firstName,
                  lastName: approvedTokenAdmin.lastName,
                  email: approvedTokenAdmin.email,
                  role: approvedTokenAdmin.role,
                  status: approvedTokenAdmin.status?.blockchainVerified?.[networkId],
                }
              ].filter(Boolean)}
            >
              {/* <Table.Column
                title="Profile"
                dataIndex="profile"
                key="profile"
                render={(_, { admin }) => (
                  <img
                    className="market-place-admin table-selfie-img"
                    src={admin?.profile}
                    alt=""
                  />
                )}
              /> */}
              <Table.Column title="First Name" dataIndex="firstName" key="firstName" />
              <Table.Column title="First Name" dataIndex="firstName" key="firstName" />
              <Table.Column title="Last Name" dataIndex="lastName" key="lastName" />
              <Table.Column title="Email" dataIndex="email" key="email" />
              <Table.Column title="Role" dataIndex="role" key="role" />
              <Table.Column title="Status" dataIndex="status" key="status" render={(_) => (
                <>
                  {
                    marketplaceTokenAdminApproved.status?.blockchainVerified?.[networkId] === "approved" && <Button style={{fontWeight:"bolder"}} className="wallet-status-approved-chip table-chip"><img className="market-place-admin sidenav-icon" src={"/img/admin-dashboard/approved.png"} alt="nftArt" />
                      <p>Approved</p></Button>
                  }
                </>
              )} />
            </Table>
              : <div className='no-data-found-div'>
                <p className='no-data-found-text'>No Data Found</p>
              </div>
          )}

          {sortByVal === "Rejected" && (
            rejectedComplianceAdmin || rejectedTokenAdmin ? <Table
              pagination={false}
              dataSource={[
                rejectedComplianceAdmin?.email && {
                  key: '1',
                  admin: {
                    profile: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQESgEgkgFa6JBZxS_lIO7glUy3SWRmbfO4K7sTq6_1NA&s"
                  },
                  firstName: rejectedComplianceAdmin.firstName,
                  lastName: rejectedComplianceAdmin.lastName,
                  email: rejectedComplianceAdmin.email,
                  role: rejectedComplianceAdmin.role,
                  status: rejectedComplianceAdmin.status?.blockchainVerified?.[networkId],
                },
                rejectedTokenAdmin?.email && {
                  key: '2',
                  admin: {
                    profile: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQESgEgkgFa6JBZxS_lIO7glUy3SWRmbfO4K7sTq6_1NA&s"
                  },
                  firstName: rejectedTokenAdmin.firstName,
                  lastName: rejectedTokenAdmin.lastName,
                  email: rejectedTokenAdmin.email,
                  role: rejectedTokenAdmin.role,
                  status: rejectedTokenAdmin.status?.blockchainVerified?.[networkId],
                }
              ].filter(Boolean)}
            >
              {/* <Table.Column
                title="Profile"
                dataIndex="profile"
                key="profile"
                render={(_, { admin }) => (
                  <img
                    className="market-place-admin table-selfie-img"
                    src={admin?.profile}
                    alt=""
                  />
                )}
              /> */}
              <Table.Column title="First Name" dataIndex="firstName" key="firstName" />
              <Table.Column title="First Name" dataIndex="firstName" key="firstName" />
              <Table.Column title="Last Name" dataIndex="lastName" key="lastName" />
              <Table.Column title="Email" dataIndex="email" key="email" />
              <Table.Column title="Role" dataIndex="role" key="role" />
              <Table.Column title="Status" dataIndex="status" key="status" render={(_) => (
                <>
                  {
                    marketplaceTokenAdminApproved.status?.blockchainVerified?.[networkId] === "rejected" && <Button className="wallet-status-rejected-chip table-chip"><img className="market-place-admin sidenav-icon" src={"/img/admin-dashboard/rejected.png"} alt="nftArt" />
                      <p>Rejected</p></Button>
                  }
                </>
              )} />
            </Table>
              : <div className='no-data-found-div'>
                <p className='no-data-found-text'>No Data Found</p>
              </div>
          )}
        </div>

      </div>
    </>
  )
}

export default AdminApprovals
