import React from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const HomePageFeatures = () => {

  return (
    <section className="home-page browse-by-category third_section">
      <div className="home-page browse-by-category container">
        <div className="row">
          <div className="col-lg-12 text-center">
            <h2 className="home-page browse-by-category-text">Features</h2>
            <p className="home-page browse-by-category-sub-text">Explore NFTs from creators worldwide</p>
          </div>
          <div className="d-flex justify-content-center align-items-center home-page features-parent-div">
            <div className="d-flex col-lg-4 col-md-6 col-sm-12 home-page-feature-sub-parent-div align-items-center">
              <div className="home-page features-img-div">
                <LazyLoadImage className="home-page features-img-icon" src={"/img/profile/nftCreationAndMinting.png"} alt="nftArt" effect="blur" />
              </div>
              <div className="home-page features-img-text-div">
                <p className="home-page features-img-text">NFT Creation & Minting</p>
                <p className="home-page features-img-sub-text">
                Transform your creations into unique digital assets with our easy minting.
                </p>
              </div>
            </div>
            <div className="d-flex col-lg-4 col-md-6 col-sm-12 home-page-feature-sub-parent-div align-items-center">
              <div className="home-page features-img-div">
                <LazyLoadImage className="home-page zero-commission-img-icon" src={"/img/profile/buyAndSell.png"} alt="nftArt" effect="blur" />
              </div>
              <div className="home-page features-img-text-div">
                <p className="home-page features-img-text">Buy & Sell</p>
                <p className="home-page features-img-sub-text">Experience Seamless Buying
                  and Selling of NFTs in Our User-
                  Friendly Marketplace!</p>
              </div>
            </div>
            <div className="d-flex col-lg-4 col-md-6 col-sm-12 home-page-feature-sub-parent-div align-items-center">
              <div className="home-page features-img-div">
                <LazyLoadImage className="home-page atom-img-icon" src={"/img/profile/royalties.png"} alt="nftArt" effect="blur" />
              </div>
              <div className="home-page features-img-text-div">
                <p className="home-page features-img-text">Royalties</p>
                <p className="home-page features-img-sub-text">Earn Lifelong Royalties on Every Secondary Sale of Your NFTs!</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};


export default HomePageFeatures;