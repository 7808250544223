import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Button,
  Card,
  DatePicker,
  Select,
  Result,
  Col,
  Row,
  message
} from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { SharedService } from '../../../Shared/Shared.service';
import { Country } from '../../../Shared/interfaces';
import { AccountService } from '../../Account.service';
import BigNumber from 'bignumber.js';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import ConnectToMetamask from '../../../ConnectToMetamask'
import { MetamaskService } from "../../../Shared/Metamask.service";
// import PhoneInput from 'react-phone-number-input'
// import { isValidPhoneNumber } from 'react-phone-number-input'
const { Option } = Select;


const sharedService = new SharedService();
const accountService=new AccountService()


const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 }
  }
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0
    },
    sm: {
      span: 16,
      offset: 8
    }
  }
};

const underlyingAssetOptions = sharedService.underlyingAssetOptions;

const useSelectedWalletContext = () =>
  new MetamaskService().useSelectedWalletContext();

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const [submitting, setSubmitting] = useState(false);
  const { selectedWallet, networkId, isMetamaskAuthenticated } = useSelectedWalletContext();

  const [countries, setCountries] = useState<Country[]>();

  const [issuerEmail, setIssuerEmail] = useState<string>();
  const [form]=Form.useForm();
  const history = useHistory();

  const isNotConnectedToMetamask = !isMetamaskAuthenticated;

  useEffect(() => {
    (async () => {
      const response = await sharedService.getCountries();
      setCountries(response.data);
    })();
  }, []);


  const register = async (req) => {
    //req.tentativeDate = (req.tentativeDate.toDate() as Date).getTime();
    //req.amountToRaise = (req.amountToRaise as string).replace(/,/g,'');
    setSubmitting(true);

    const response = await accountService.registerNFTModerator(req);

    if (response.success) {
      setIssuerEmail(req.email);

    } else {
      message.error(response.error.message);
    }

    setSubmitting(false);
  }

  useEffect(()=>{
      form.setFieldsValue({walletAddress: selectedWallet});
  },[selectedWallet]);
 /* const fetchCountry=(_value)=>{
    let cunt=[]
    for(let i=0;i<tempCountries?.length;i++)
    {
      if(tempCountriesp[i].toString().startsWith(_value))cunt.push(tempCountries[i])
    }
  };*/
  return (
    <>
      <div hidden={!isNotConnectedToMetamask}>
        <ConnectToMetamask />
      </div>

      <div hidden={!!isNotConnectedToMetamask}>
      <Row justify="center" style={{margin:"60px 0"}}>
        <Col span={16} style={{textAlign:'center'}}>

          {!issuerEmail &&

            <Card>
              <h2 style={{textAlign:'center'}}>Register as NFT Marketplace Admin</h2>
              <Form
                {...formItemLayout}
                form={form}
                onFinish={register}
                style={{ margin: '2% 10% 0 10%', width: '60%' }}>

                <Form.Item 
                  label='First Name'
                  name="firstName"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your first name!',
                      whitespace: true
                    }
                  ]}>
                  <Input />
                </Form.Item>

                <Form.Item 
                  label='Last Name'
                  name="lastName"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your last name!',
                      whitespace: true
                    }
                  ]}>
                  <Input />
                </Form.Item>

                <Form.Item
                  label='E-mail'
                  name="email"
                  rules={[
                    {
                      type: 'email',
                      message: 'The input is not valid E-mail!'
                    },
                    {
                      required: true,
                      message: 'Please input your E-mail!'
                    }
                  ]}>
                  <Input />
                </Form.Item>

                <Form.Item 
                  label='Password'
                  name="password"
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: 'Please input your password!'
                    },
                    {
                      validator: (rule, value) => {
                        const strongRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$\-_[\](){}<>|°¬+=~".,;:'#!%*?&])[A-Za-z\d@$\-_[\](){}<>|°¬+=~".,;:'#!%*?&]{8,}$/;
                        if(strongRegex.test(value)) return Promise.resolve();
                        return Promise.reject('The password must contains at least 1 number, 1 lowercase, 1 uppercase and 1 special character and 8 characters long');
                      }
                    }
                  ]}>
                  <Input.Password />
                </Form.Item>

                <Form.Item 
                  label='Confirm Password'
                  name="confirm"
                  dependencies={['password']}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: 'Please confirm your password!'
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value || getFieldValue('password') === value) return Promise.resolve();
                        return Promise.reject('The two passwords that you entered do not match!');
                      },
                    }),
                  ]}>
                  <Input.Password />
                </Form.Item>

                <Form.Item 
                  label='Wallet Address'
                  name="walletAddress"
                  rules={[
                    {
                      required: true,
                      message: 'Please connect to metamask',
                      whitespace: true
                    }
                  ]}>
                  <Input style={{color:'green'}} disabled/>
                </Form.Item>

                <Form.Item 
                  label='Name of Company'
                  name="companyName"
                  rules={[
                    {
                      required: true,
                      message: 'Enter Company name here',
                      whitespace: true
                    }
                  ]}>
                  <Input />
                </Form.Item>

                <Form.Item 
                  label='Company Country'
                  name="companyCountry"
                  validateStatus={countries? '' : 'validating'}
                  hasFeedback
                  help= {countries? '' : "Loading Countries..."}
                  rules={[
                    {
                      required: true,
                      message: 'Select Country'
                    }
                  ]}>
                  <Select showSearch placeholder="Select Country" >
                    {countries?.sort(function (a, b){return b.name['en'].localeCompare(a.name['en']);}).map(country => 
                      <Option key={country._id} value={country.name['en']}>{country.name['en']}</Option>
                    )}
                  </Select>
                </Form.Item>

                <Form.Item
                  shouldUpdate={(prevValues, currentValues) => prevValues.companyCountry !== currentValues.companyCountry}
                  noStyle
                  >
                  {({ getFieldValue }) => {
                      return (
                        <Form.Item 
                          label='Phone number'
                          name="phone"
                          rules={[
                            {
                              required: true,
                              message: 'Enter Phone number here',
                              whitespace: true
                            },
                            {
                              validator: (rule, value) => {
                                const strongRegex = /^[+]{0,1}[0-9\s-]{0,4}[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]{3,10}$/;
                                if(strongRegex.test(value)) return Promise.resolve();
                                return Promise.reject('Invalid phone number');
                              }
                            }
                          ]}>
                            <PhoneInput/>
                        </Form.Item>
                      )
                  }}
                  </Form.Item>
                  {/* {value}
                  {isValidPhoneNumber(value)? 'VALID' : 'INVALID'} */}


                {/* <Form.Item 
                  label='Token Phase'
                  name="tokenPhase"
                  rules={[
                    {
                      required: true,
                      message: 'Please input token phase!',
                      whitespace: true
                    },
                  ]}>
                  <Select showSearch placeholder="Select Phase">
                    <Option key={'initial'} value={'Initial'}>{'Initial'}</Option>
                    <Option key={'pre-sale'} value={'Pre-sale'}>{'Pre-sales'}</Option>
                  </Select>
                </Form.Item>
        
                <Form.Item 
                  label='Amount to raise'
                  name="amountToRaise"
                  normalize = {(value: string, prevValue: any, allValues) => {
                    const _value = value.replace(/,/g,'');
                    
                    if(new BigNumber(_value).isGreaterThan(0)) return new BigNumber(_value).toFormat(0);
                    if(!_value) return '';
                    return prevValue || '';
                  }}
                  rules={[
                    {
                      required: true,
                      message: 'Please input amount to raise!'
                    },
                  ]}>
                  <Input
                    addonAfter='USD'
                  />
                </Form.Item>
        
                <Form.Item 
                  label='Underlying Asset'
                  name="underlyingAsset"
                  rules={[
                    {
                      required: true,
                      message: 'Please input underlying asset!',
                      whitespace: true
                    }
                  ]}>
                  <Select>
                    {underlyingAssetOptions.map(option => <Option key={option.value} value={option.value}>{option.name}</Option>)}
                  </Select>
                </Form.Item>
        
                <Form.Item 
                  label='Tentative Date'
                  name="tentativeDate"
                  rules={[
                    {
                      required: true,
                      message: 'Please input tentative date!'
                    }
                  ]}>
                  <DatePicker format="MMMM DD, YYYY" />
                </Form.Item> */}

                <Form.Item {...tailFormItemLayout}>
                  <Button type='primary' htmlType='submit' loading={submitting}>Register</Button>
                  <br />
                  <>Or </><Link to='/account/login'>login now!</Link>
                </Form.Item>

              </Form>
            </Card>

          }

          {issuerEmail && 
            <Card>
              <h2 style={{textAlign:'center'}}>Check your Email</h2>
              <Result
                title={`We sent a verification email to ${issuerEmail}.`}
                subTitle="Click on the link to get started!"
                extra={
                  <Button key="console" onClick={() => history.push('/account/resend-email-verification-code')}>
                    Email didn't arrive?
                  </Button>
                }/>
            </Card>
          }
        </Col>
      </Row>
      </div>
  </>
  );
}