/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useHistory, Link, useLocation, useParams} from 'react-router-dom';
import { Row, Col, Card, Form, Input, Button, message, Result, Select} from 'antd';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import ConnectToMetamask from '../../../ConnectToMetamask'
import { MetamaskService } from "../../../Shared/Metamask.service";
import { AccountService } from '../../Account.service';
import { SharedService } from '../../../Shared/Shared.service';

const useSelectedWalletContext = () =>
  new MetamaskService().useSelectedWalletContext();

const { Option } = Select;
const accountService = new AccountService();


const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 }
  }
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0
    },
    sm: {
      span: 16,
      offset: 8
    }
  }
};
const sharedService = new SharedService();



// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const history = useHistory();
  const location = useLocation();
  const [form] = Form.useForm();
  const [secretPhrase,setSecretPhrase]=useState("");
  const [submitting, setSubmitting] = useState(false);
  const { selectedWallet, networkId, isMetamaskAuthenticated } = useSelectedWalletContext();

  const [screenState, setScreenState] = useState<'register' | 'success'>('register');
  const [gotInvite, setGotInvite] = useState(false);

  const isNotConnectedToMetamask = !isMetamaskAuthenticated;

  const urlParams = new URLSearchParams(window.location.search);
  const email = urlParams.get('email')?.replaceAll(" ", "+");

  useEffect(()=>{
    (async ()=>{

      const response = await accountService.getSecretCode();
      console.log(response);
      if(response.success && response.data){
        setSecretPhrase(response.data.phrase);
      } else {
        message.error("Secret code is not setup by the owner.You can not register now.");
      }
    })();
  },[])

  useEffect(()=>{
    form.setFieldsValue({walletAddress: selectedWallet});
},[selectedWallet]);

useEffect(()=>{
  if(email) setGotInvite(true);
  else setGotInvite(false);
},[email])

  const register = async (formValue) => {
    if(email){
      formValue.email = email;
      console.log(formValue);
      //formValue.email+="@CryptoAssetRating.com";
      setSubmitting(true);
      const response = await accountService.registerMarketplaceComplianceTokenAdmin(formValue);

      if (response.success) {
        setScreenState('success');
      } else {
        message.error(response.error.message);
      }

      setSubmitting(false);
    }
  }


  return (
    <>
      <div hidden={!isNotConnectedToMetamask}>
        <ConnectToMetamask />
      </div>
      {!gotInvite && (<Card style={{margin: '20px', textAlign: 'center'}}>
        <h3>You should be getting a invite from marketplace super admin and then you can register.</h3>
      </Card>)}
      {gotInvite && (
      <Row justify="center" style={{ margin: "60px 0" }}>
        <Col span={22} style={{ textAlign: 'center' }}>
          {screenState === 'register' &&
            <Card>
              <h2 style={{ textAlign: 'center' }}>Sign up as Marketplace Token Admin!</h2>
              <Form form={form}
                {...formItemLayout}
                onFinish={register}
                style={{ margin: '2% 5% 0 5%', width: '80%' }}>
                <Form.Item
                  label='First name'
                  name="firstName"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your First Name!',
                      whitespace: true
                    }
                  ]}>
                  <Input />
                </Form.Item>

                <Form.Item
                  label='Last name'
                  name="lastName"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your Last Name!',
                      whitespace: true
                    }
                  ]}>
                  <Input />
                </Form.Item>

                {/* <Form.Item
                  label='Email username'
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: 'required'
                    },
                    {
                      validator: (rule, value) => {
                        if (!value.includes("@"))return Promise.resolve();
                        else return Promise.reject("@ is not allowed in email username");
                      }
                    }
                  ]}>
                  <Input  addonAfter="@CryptoAssetRating.com" />
                </Form.Item> */}

                <Form.Item
                  label='Email'
                  name="email"
                  rules={[
                    {
                      required: false,
                      message: 'required'
                    }
                  ]}>
                  <Input type="email" disabled defaultValue={email} value={email}/>
                </Form.Item>

                <Form.Item
                  label='Password'
                  name="password"
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: 'Please input your password!'
                    },
                    {
                      validator: (rule, value) => {
                        const strongRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$\-_[\](){}<>|°¬+=~".,;:'#!%*?&])[A-Za-z\d@$\-_[\](){}<>|°¬+=~".,;:'#!%*?&]{8,}$/;
                        if (strongRegex.test(value)) return Promise.resolve();
                        return Promise.reject('The password must contains at least 1 number, 1 lowercase, 1 uppercase and 1 special character and 8 characters long');
                      }
                    }
                  ]}>
                  <Input.Password />
                </Form.Item>

                <Form.Item
                  label='Confirm Password'
                  name="confirm"
                  dependencies={['password']}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: 'Please confirm your password!'
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value || getFieldValue('password') === value) return Promise.resolve();
                        return Promise.reject('The two passwords that you entered do not match!');
                      },
                    }),
                  ]}>
                  <Input.Password />
                </Form.Item>

                <Form.Item
                shouldUpdate={(prevValues, currentValues) => prevValues.companyCountry !== currentValues.companyCountry}
                noStyle
                >
                {({ getFieldValue }) => {
                    return (
                      <Form.Item 
                        label='Phone number'
                        name="phone"
                        rules={[
                          {
                            required: true,
                            message: 'Enter Phone number here',
                            whitespace: true
                          },
                          {
                            validator: (rule, value) => {
                              const strongRegex = /^[+]{0,1}[0-9\s-]{0,4}[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]{3,10}$/;
                              if(strongRegex.test(value)) return Promise.resolve();
                              return Promise.reject('Invalid phone number');
                            }
                          }
                        ]}>
                          <PhoneInput country={"us"}/>
                      </Form.Item>
                    )
                }}
                </Form.Item>

                <Form.Item
                  label='Gender'
                  name="gender"
                  rules={[
                    {
                      required: true,
                      message: 'Please select your gender!'
                    }
                  ]}
                >
                  <Select defaultValue="">
                    <Option value="" disabled>Select</Option>
                    <Option value="male">Male</Option>
                    <Option value="female">Female</Option>
                    <Option value="unknown">Unknown</Option>
                  </Select>
                </Form.Item>

                <Form.Item 
                label='Wallet Address'
                name="walletAddress"
                rules={[
                  {
                    required: true,
                    message: 'Please connect to metamask',
                    whitespace: true
                  }
                ]}>
                <Input style={{color:'green'}} disabled/>
              </Form.Item>

                <Form.Item
                  label='Role'
                  name="role"
                  rules={[
                    {
                      required: true,
                      message: 'Please select a role!'
                    }
                  ]}>
                  <Select>
                    {/* <Option key={1} value='platform issuer admin'>Platform issuer admin</Option>
                    <Option key={2} value='marketplace-super-admin'>Platform super admin</Option>
                    <Option key={3} value='platform compliance admin'>Platform compliance admin</Option> */}
                    <Option key={1} value='marketplace-token-admin' selected>MarketPlace Token Admin</Option>
                  </Select>
                </Form.Item>

                <Form.Item {...tailFormItemLayout}>
                  <Button type='primary' htmlType='submit' loading={submitting}>Register</Button>
                  <br />
                  <>Or </><Link to='/account/login'>login now!</Link>
                </Form.Item>

              </Form>
            </Card>

          }

          {screenState === 'success' &&
            <Card>
              <h2 style={{ textAlign: 'center' }}>Account created</h2>
              <Result
                title={`Your account has been created successfully. Pledase verify your email!!`}
                extra={
                  <Button key="console" onClick={() => history.push('/account/login')}>
                    Click here to login
                </Button>
                } />
            </Card>
          }

        </Col>
      </Row>)}
    </>
  );
}