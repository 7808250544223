/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from "react";
import { Spin, notification } from "antd";
import { useHistory } from "react-router-dom";
import { MetamaskService } from "../../../components/Shared/Metamask.service";
import authContext from "../../../components/Shared/Auth.service";
import { SharedService } from "../../../components/Shared/Shared.service";
import { AdminService } from "../../../components/services/AdminService/Admin.service";
import NftApproval from "./components/NftApproval";
import EscrowApproval from "./components/EscrowApproval";
import ChangePassword from "./components/ChangePassword";
// import './styles.css'

const useSelectedWalletContext = () =>
  new MetamaskService().useSelectedWalletContext();
const sharedService = new SharedService();
const adminService = new AdminService();

function TokenAdmin() {
  const history = useHistory();
  const { networkId } = useSelectedWalletContext();

  const [selectedTab, setSelectedTab] = useState("nftApproval");
  const { userInfo } = useContext(authContext);
  const [todayEtheriumPriceInUSD, setTodayEtheriumPriceInUSD] = useState();
  const [dashboardData, setDashboardData] = useState({});
  const [MSAProfileApproved, setMSAProfileApproved] = useState(false);
  const [smartContractData, setSmartContractData] = useState({});
  const [loading, setLoading] = useState(false);
  const [config, setConfig] = useState({})

  useEffect(async () => {
    setLoading(true);

    if (!networkId) return;

    setTimeout(async () => {
      if (userInfo?._id && userInfo?.role === "marketplace-token-admin") {
        try {
          // checking for profile approval status
          if (userInfo?.status?.profileVerified === 'approved' || userInfo?.status?.profileVerified === 'verified') {
            setMSAProfileApproved(true);
          }

          //setting ether price
          setTodayEtheriumPriceInUSD(await sharedService.getEtheriumPrice());

          //setDashboard Data
          let res = await adminService.getMarketplaceSuperAdminDashboard({ networkId });
          if (res?.data) setDashboardData(res.data);

          // Set contractAddress Data
          let res1 = await adminService.getMarketPlaceDeployedSmartContract({ networkId });
          if (res1?.data) setSmartContractData(res1.data);

          setLoading(false);
        } catch (error) {
          console.log(error);
          notification.open({
            message: "Error",
            description: "something went wrong while fetching data",
            duration: 0,
          });
        }
      } else {
        history.push("/account/login");
      }
    }, 400);
  }, [userInfo, networkId]);

  useEffect(async () => {
    loadConfiguration();
  }, [networkId]);

  async function loadConfiguration() {
    return new Promise(async (resolve, reject) => {
      setLoading(true);
      try {
        // config data
        let res = await adminService.getMarketPlaceConfiguration(userInfo?._id, { networkId });
        if (res.data) {
          //if (res.data.commission) setCommission(parseInt(res.data.commission));
          // if (res.data.listingFees) setListingFees(res.data.listingFees);
          // if (res.data.MintingFees) setMintingFees(res.data.MintingFees);
          // if (res.data.royalty) setRoyalty(parseInt(res.data.royalty));
          // if (res.data.sellingFees) setSellingFees(res.data.sellingFees);
          if (res.data) {
            setConfig(res.data)
          }

          // if (res.data.goldenBadgeCommission)
          //   setGoldenBadgeCommission(parseInt(res.data.goldenBadgeCommission));
          // if (res.data.goldenBadgeListingFees)
          //   setGoldenBadgeListingFees(res.data.goldenBadgeListingFees);
          // if (res.data.goldenBadgeMintingFees)
          //   setGoldenBadgeMintingFees(res.data.goldenBadgeMintingFees);
          // if (res.data.goldenBadgeRoyalty)
          //   setGoldenBadgeRoyalty(parseInt(res.data.goldenBadgeRoyalty));
          // if (res.data.goldenBadgeSellingFee)
          //   setGoldenBadgeMintingFees(res.data.goldenBadgeMintingFees);

          // if (res.data.walletWhitelistingFee)
          //   setWalletWhitelistingFee(res.data.walletWhitelistingFee);
          // if (res.data.collectionFee) setCollectionFee(res.data.collectionFee);
          // if (res.data.goldenBadgeCollectionFee)
          //   setGoldenBadgeCollectionFee(res.data.goldenBadgeCollectionFee);

          // if (res.data.goldenBadgeSellingFees)
          //   setGoldenBadgeSellingFees(res.data.goldenBadgeSellingFees);

          // if (res.data.photoSize) setPhotoSize(res.data.photoSize);
          // if (res.data.videoSize) setVideoSize(res.data.videoSize);
          // if (res.data.linkedinGoldenBadge)
          //   setLinkedinGoldenBadge(res.data.linkedinGoldenBadge);
          // if (res.data.twitterGoldenBadge)
          //   setLinkedinGoldenBadge(res.data.twitterGoldenBadge);

          // setConfigurationLoaded(true);
          // disabled the below beacuse to wait for all other calls to finish on refresh
          //setLoading(false);
          resolve("true");
        }
      } catch (error) {
        console.log(error);
        notification.open({
          message: "Error",
          description: "something went wrong while fetching configuration data",
          duration: 0,
        });
        setLoading(false);
        reject("true");
      }
    });
  }

  const handleMenuClick = (value) => {
    setSelectedTab(value)
  }

  const renderTabs = (val) => {
    switch (val) {
      case "nftApproval":
        return (
          <NftApproval
            setLoading={setLoading}
            smartContractData={smartContractData}
            loading={loading}
          />
        );
      case "escrowApproval":
        return <EscrowApproval setLoading={setLoading} loading={loading} />;
     case "change-password":
        return <ChangePassword setLoading={setLoading} loading={loading} />;
      default:
        return null;
    }
  };
  return (
    <>
      <div className="d-flex market-super-admin main-section col-lg-12">
        <div className="col-lg-2 market-super-admin col-4-section">
          <div className='d-flex flex-column'>
            <div className='market-super-admin super-admin-box'>
              <p className='market-super-admin super-admin-text'>Token Admin</p>
            </div>
            <div>
              <ul className="market-place-admin sidenav-ul">
                <li
                  id="nftApproval"
                  className={`market-place-admin sidenav-list ${selectedTab === "nftApproval" ? "active" : ""}`}
                  onClick={() => handleMenuClick("nftApproval")}
                >
                  <img className="market-place-admin sidenav-icon" src={"/img/admin-dashboard/nftApproval.png"} alt="nftArt" />
                  NFT Approval
                </li>
                <li
                  id="escrowApproval"
                  className={`market-place-admin sidenav-list ${selectedTab === "escrowApproval" ? "active" : ""}`}
                  onClick={() => handleMenuClick("escrowApproval")}
                >
                  <img className="market-place-admin sidenav-icon" src={"/img/admin-dashboard/escrowApproval.png"} alt="nftArt" />
                  Escrow Approval
                </li>
                <li
                  id="change-password"
                  className={`market-place-admin sidenav-list ${selectedTab === "change-password" ? "active" : ""}`}
                  onClick={() => handleMenuClick("change-password")}
                >
                  <img className="market-place-admin sidenav-icon" src={"/img/admin-dashboard/changePassword.png"} alt="nftArt" />
                  Change Password
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="col-lg-10 market-super-admin col-8-section">
          {renderTabs(selectedTab)}
          {loading && (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ margin: "100px 0" }}
            >
              <Spin size="large" />
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default TokenAdmin;
