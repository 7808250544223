import React from "react";
import "../pages/styles/AboutUs.scss";
import "../pages/styles/AboutPixentro.scss";
import PixentroImage1 from "../assets/images/logo/Pixentro1.png";
import PixentroImage2 from "../assets/images/logo/Pixentro2.png";
import PixentroImage3 from "../assets/images/logo/Pixentro3.png";
import StartFramingImg from "../assets/images/logo/startFramingImg.png";
import Banner from "../assets/images/banner/AboutUsBanner.png";

const AboutUsPixentro = () => {
  return (
    <>
      {/*  BANNER SECTION */}
      <div class="card bg-dark text-white aboutBanner">
        <img
          src={Banner}
          class="card-img  bannerHeight"
          alt="About Us Banner"
        />
      </div>

      {/*   MAIN SECTION */}
      <div className="bgColor aboutUsContainer">
        <div className="container-fluid px-5">
          <div className="d-flex justify-content-center">
            <p className="text-center marginRemove subHeading">
              <span className="subHeadingPara">
                Discover the heart and soul of Our NFT Marketplace.
              </span>
              {/* <br></br> */}
              <p className="subHeadingPara1">
                Join a community driven by a passion for digital art and a
                shared commitment to artistic expression.
              </p>
            </p>
          </div>

          {/*  ABOUT CARD CONTAINER */}
          <div className="mt-7 firstMt7  aboutCardContainer1 d-flex ">
            <div className="card cardBgColor aboutSection">
              <div className="aboutContent">
                <h3 className="aboutHeading">Why Us ?</h3>
                <p className="aboutHeadingPara">
                  Welcome to our revolutionary NFT Marketplace,
                </p>
                <p className="card-text p-3 displayP aboutPara w-50% order-0">
                  A dynamic platform that beckons artists from the digital
                  realm, the entertainment industry, and beyond! Here, we
                  celebrate the unique brilliance of every artist, be it from
                  the realms of TV, sports, Bollywood, Hollywood, or any other
                  influential background. Join us in creating an eccentric haven
                  where artists from diverse backgrounds unite. Embrace this era
                  of NFTs as a celebration of creativity, appreciation, and
                  ownership. We extend a warm invitation to every digital
                  artist, entertainment maestro, media virtuoso, and luminary
                  from Hollywood to Bollywood.Let's embark on a journey where
                  your art truly shines, resonates, and stands out in a crowd of
                  unique creators. Join us, and let's make history in the realm
                  of NFTs! Because here, Every Artist is Special.
                </p>
              </div>
            </div>
            <div className="col">
              <div className="PixentroImage order-1">
                <img
                  src={PixentroImage1}
                  alt="PixentroImage1"
                  className="card-img-top pixentImage pixentroImg1"
                />
              </div>
            </div>
          </div>
          <div className=" aboutCardContainer2 d-flex ">
            <div className="col">
              <div className="PixentroImage order-0">
                <img
                  src={PixentroImage2}
                  alt="PixentroImage2"
                  className="card-img-top pixentImage pixentroImg2"
                />
              </div>
            </div>
            <div className="card cardBgColor aboutSection order-1">
              <div className="aboutContent ">
                <h3 className="aboutHeading">How Our NFT Platform Works ?</h3>
                <p className="aboutHeadingPara">
                  The journey begins with a seamless onboarding experience.
                </p>
                <p className="card-text p-3 displayP aboutPara w-50% ">
                  NFT Creator undergo a KYC process through our streamlined
                  Marketplace Approval system, followed by the secure
                  Whitelisting of their wallet address upon admin approval. Your
                  path to the world of NFTs is paved with trust and
                  transparency. Now, artists hold the power to shape their own
                  destiny on our platform. Create bespoke collections that
                  reflect your artistic essence, mint NFTs that embody your
                  vision, and list them for sale to a global audience. As
                  creators, you're entitled to the royalties your talent
                  deserves. Our system ensures that you reap the benefits of
                  your hard work and creativity with every transaction. It's
                  your time to shine, both artistically and financially. We
                  understand the importance of preserving your artistic legacy.
                  Each NFT carries your copyright notice, and a watermark serves
                  as a testament to your individuality. In our marketplace,
                  every artist is not just special; they're royalty in their own
                  right. The ultimate marketplace that brings together artists
                  from the vibrant realms of digital art, entertainment, sports,
                  Hollywood, and Bollywood. Our platform is a canvas for
                  creators to showcase their unique art forms through the magic
                  of NFTs.
                </p>
              </div>
            </div>
          </div>
          <div className=" firstMt7  aboutCardContainer3 d-flex ">
            {/* <div className="row row-cols-1 row-cols-md-3">
              <div className="col"></div> */}

            {/*  <div className="col"></div>
            </div> */}
            <div className="card cardBgColor aboutSection">
              <div className="aboutContent">
                <h3 className="aboutHeading">Mission</h3>
                <p className="aboutHeadingPara">
                  Welcome to our revolutionary NFT Marketplace,
                </p>
                <p className="card-text p-3 displayP aboutPara w-50% order-0">
                  At Our NFT marketplace, we're passionate about promoting the
                  distinct features of NFTs, providing a space where artists
                  from all communities can unite to celebrate and appreciate
                  each other's art. Our mission is to facilitate a seamless
                  fusion of creativity, community, and cutting-edge blockchain
                  technology. We understand the importance of security,
                  efficiency, and reliability in the NFT space. That's why Our
                  NFT marketplace leverages blockchain technology to safeguard
                  the uniqueness of every creator. Your art is not just
                  showcased; it's immortalized on the blockchain, ensuring
                  authenticity and value.Our NFT marketplace is dedicated to
                  making the NFT journey effortless for both creators and
                  buyers. We believe in simplifying the promotion, sale, and
                  purchase of NFTs, providing a user-friendly experience that
                  lets the art speak for itself.
                </p>
              </div>
            </div>
            <div className="col">
              <div className="PixentroImage order-1">
                <img
                  src={PixentroImage3}
                  alt="PixentroImage3"
                  className="card-img-top pixentImage pixentroImg3"
                />
              </div>
            </div>
          </div>

          <div className=" aboutPageImg">
            <img src={StartFramingImg} alt=""></img>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUsPixentro;
