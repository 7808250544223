/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect, useRef } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import {
  Spin,
  notification,
  Input,
  Button,
  Popconfirm,
  Steps,
  message,
  Form,
  Card,
  Select,
  Divider,
  Table,
  Tooltip,
} from "antd";
import { createGlobalStyle } from "styled-components";
import styled from "styled-components";

import { MetamaskService } from "./../components/Shared/Metamask.service";
import authContext from "../components/Shared/Auth.service";
import { NFTCreatorService } from "../components/services/NFTCreator/NFTCreator.service";
import { SharedService } from "./../components/Shared/Shared.service";
import { VideoPlayer } from "./../components/mainSlider/VideoPlayer";
import ProfilePic from "./../assets/images/home/profile-default.png";
import "./styles/styles.scss";
import "./styles/nftDetails.scss";
import ItemBuy from "./ItemBuy";
import greenTickMark from "./../assets/images/home/greenTickMark.png";
import { NftMintingService } from "../components/Shared/Blockchain/NftMinting/NftMinting.service";
import { NftTrader } from "../components/Shared/Blockchain/NftTrader/NftTrader.service";
import { AdminService } from "./../components/services/AdminService/Admin.service";
import { NftEscrowService } from "./../components/Shared/Blockchain/NftEscrow/NftEscrow.service";
import eversign from "../components/Shared/Eversign/Eversign";
import moment from "moment";
import { environment } from "../environments/environment";
import DateTime from "../components/DateTime";
import Price from '../assets/images/nft_create/Price.png';
import Bid from '../assets/images/nft_create/bid.png';
import Time from '../assets/images/nft_create/Time.png';

const nftService = new NFTCreatorService();
const nftMintingService = new NftMintingService();
const adminService = new AdminService();
const nftTrader = new NftTrader();
const nftEscrowService = new NftEscrowService();
const useSelectedWalletContext = () =>
  new MetamaskService().useSelectedWalletContext();

const sharedService = new SharedService();

const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.white {
    background: #FAF6F1;
    border-bottom: solid 1px #ccc !important;
  }
`;

const Outer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: 260px;
  overflow: hidden;
  border-radius: 8px;
`;

const ItemDetail = function () {
  const { userInfo, setUserInfo } = useContext(authContext);
  const [userOriginalWalletAddress, setUserOriginalWalletAddress] =
    useState("");
  const [NFTDetails, setNFTDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [copyrightChecked, setCopyrightChecked] = useState(false);
  const [watermarkChecked, setWatermarkChecked] = useState(false);
  const [stepNo, setStepNo] = useState(1);
  const [inputError, setInputError] = useState(false);
  const [inputError2, setInputError2] = useState(false);
  const [reportText, setReportText] = useState("");
  const [isMinted, setIsMinted] = useState(null);
  const [showBiddingDetails, setShowBididngDetails] = useState(false);
  const { selectedWallet, networkId } = useSelectedWalletContext();

  const [todayEtheriumPriceInUSD, setTodayEtheriumPriceInUSD] = useState();
  const { nftId } = useParams();
  const history = useHistory();
  const [nftMintingPrice, setNFTMintingPrice] = useState(null);
  const [nftListingPrice, setNftListingPrice] = useState(null);
  const [readyForSell, setReadyForSell] = useState(false);
  const [biddingPrice, setBiddingPrice] = useState();
  const [maxBiddingAmount, setMaxBiddingAmount] = useState();
  const [maxBiddedUserId, setMaxBiddedUserId] = useState();
  const [biddingDetails, setBiddingDetails] = useState([]);
  const [allBiddedAmount, setAllBiddedAmount] = useState([]);
  const [openEversign, setOpenEversign] = useState(false);
  const [signURL, setSignURL] = useState(null);
  const [biddingDone, setBiddingDone] = useState(false);
  const [expirationDate, setExpirationDate] = useState();
  const [expirationTimeStamp, setExpirationTimeStamp] = useState();
  const [form] = Form.useForm();
  const [hours, setHours] = useState();
  const [minutes, setMinutes] = useState();
  const [originalNFTDownloaded, setOriginalNFTDownloaded] = useState(false);
  const [showResellingScreen, setShowResellingScreen] = useState(false);
  const [price, setPrice] = useState();
  const [method, setMethod] = useState("Fixed Price");
  const [bid, setBid] = useState();
  const [startingDate, setStartingDate] = useState();
  const [formExpirationDate, setFormExpirationDate] = useState();
  const [offerValidityTimeArray, setOfferValidityTimeArray] = useState([]);
  const [offerValidityTime, setOfferValidityTime] = useState();
  const [dateTimeError, setDateTimeError] = useState(false);
  const [claimExpirationDate, setClaimExpirationDate] = useState();
  const [currentOwnerDetails, setCurrentOwnerDetails] = useState();
  const [generalListingCommission, setGeneralListingCommission] = useState();
  const [generalSellingCommission, setGeneralSellingCommission] = useState();
  const [minPriceError, setMinPriceError] = useState(false);
  const [auctionExpired, setAuctionExpired] = useState(true);
  const [someUserDetails, setSomeUsersDetails] = useState([]);
  const [blockchainNormalLoading, setBlockchainNormalLoading] = useState(false);
  const [blockchainPendingTransactionLoading, setBlockchainPendingTransactionLoading] = useState(false);
  const [blockchainInputGiven, setBlockchainInputGiven] = useState(false);
  const [blockchainInputHash, setBlockchainInputHash] = useState('');
  const [blockchainPendingTransactionDetails, setBlockchainPendingTransactionDetails] = useState();
  const [usdBid, setUsdBid] = useState();
  const [usdPrice, setUsdPrice] = useState();

  //using useRef to get the current updated value in setTimeout
  const userInfoRef = useRef(userInfo);
  userInfoRef.current = userInfo;

  const rowStyle = { height: '36px' };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  useEffect(async () => {
    if (!networkId) return;

    setLoading(true);
    //setting ether price
    setTodayEtheriumPriceInUSD(await sharedService.getEtheriumPrice());
    //console.log('userInfo', userInfo);
    let data;
    if (userInfo?._id) data = (await nftService.getNFTById(nftId, userInfo?._id)).data;
    else data = (await nftService.getNFTById(nftId)).data;

    if (data) {
      setNFTDetails(data);
      setIsMinted(data?.minted);
      setUserOriginalWalletAddress(data.user?.walletAddress);
      if (data.biddingDone === true) setBiddingDone(true);
      if (data.expirationDate && !data.enableChangeAuctionDate) {
        setExpirationDate(data.expirationDate);
        setExpirationTimeStamp(data.expirationTimestamp);
      }

      if (data.previousOwnerIds?.length > 0 && data.sellingStatus === "sold" && data.resold === true) {
        setStepNo(8);
      } else if (data.previousOwnerIds?.length > 0 && data.sellingStatus === "selling") {
        setStepNo(7);
        setShowBididngDetails(true);
      } else if (
        data.adminVerification === true &&
        data.minted === true &&
        data.sellingStatus === "sold"
      ) {
        setStepNo(6);
      } else if (
        data.adminVerification === true &&
        data.minted === true &&
        data.sellingStatus === "selling"
      ) {
        setStepNo(5);
        setShowBididngDetails(true);
      } else if (data.adminVerification === true && data.minted === true) {
        setStepNo(4);
      } else if (
        data.adminVerification === true &&
        data.adminVerificationStatus === "approved"
      ) {
        setStepNo(3);
      } else if (data.adminVerification === "pending") {
        setStepNo(2);
      }

      // ready for sell
      if (data.minted === true && data.sellingStatus === "readyForSell")
        setReadyForSell(true);

      // check if aution date & time expired and still not placed for sell then disable place for sell button
      setAuctionExpired(false);
      if (data.expirationTimestamp) {
        if (new Date().getTime() > new Date(data.expirationTimestamp)?.getTime()) {
          setAuctionExpired(true);
        }
      }

      // get downloadable nft right wallet address
      let data1 = await nftService.getNFTAuthorizeWalletAddress(data?.userId, { networkId });
      setUserOriginalWalletAddress(data1.data);

      // setting all bidding details
      if (data.biddingDetails?.length > 0) {
        let biddingDetails = data.biddingDetails;
        let newBiddingDetails = [];
        let allBiddedAmountVar = [];

        // calling api to get user name and profile pic
        let userIds = [...biddingDetails.map((bid) => bid.userId)]?.join(",");
        let userDetailsArray = (
          await nftService.getUserSomeDetailsByUsersIds(userIds)
        ).data;
        setSomeUsersDetails(userDetailsArray);
        if (userDetailsArray?.length > 0) {
          biddingDetails.forEach((bid) => {
            allBiddedAmountVar.push(bid?.price);
            let userMicroDetails = userDetailsArray.filter(
              (usr) => usr._id === bid.userId
            );
            if (userMicroDetails?.length > 0)
              newBiddingDetails.push({ ...bid, ...userMicroDetails[0] });
          });
          setBiddingDetails(newBiddingDetails);
          setAllBiddedAmount(allBiddedAmountVar);
        }
        let maxBidAmount = Math.max(...biddingDetails.map((bid) => bid.price));
        setMaxBiddingAmount(maxBidAmount);

        // for same max price the preference should be to the first bidded user only
        let tempBidPrice = 0;
        biddingDetails.forEach((bidDetails) => {
          if (maxBidAmount === bidDetails?.price && bidDetails?.price !== tempBidPrice) {
            tempBidPrice = bidDetails?.price;
            setMaxBiddedUserId(bidDetails?.userId);
          }
        });
      }

      if (data.soldDate)
        setClaimExpirationDate(new Date(data.soldDate + environment.claimTimeInEscrow));

      const response = await adminService.getUsersDetailsById(data.userId);
      if (response.success && response.data) {
        setCurrentOwnerDetails(response.data);
      }
    }

    if (userInfo?._id) {
      let res2 = await adminService.getMarketPlaceConfiguration(userInfo?._id, { networkId });
      if (userInfo?.socialMediaProfile?.goldenBadge?.[networkId]) {
        setNFTMintingPrice(res2?.data?.goldenBadgeMintingFees);
      } else {
        setNFTMintingPrice(res2?.data?.MintingFees);
      }
      if (userInfo?.socialMediaProfile?.goldenBadge?.[networkId]) {
        setNftListingPrice(res2?.data?.goldenBadgeListingFees);
      } else {
        setNftListingPrice(res2?.data?.listingFees);
      }

      setGeneralListingCommission(parseFloat(res2?.data?.listingFees));
      //setting to 0 as it is in percentage
      //setGeneralSellingCommission(parseFloat(res2?.data?.sellingFees));
      setGeneralSellingCommission(parseFloat(0));

      // checking for blockchain pending transactions
      if (userInfo?._id && selectedWallet?.length > 0) {
        const [res3, res4, res5] = await Promise.all([
          nftService.checkPendingTransactionByType(sharedService.pendingTransactionConstants.COLLECTION_CREATION, userInfo?._id, data?.collection?.collectionId, nftId),
          nftService.checkPendingTransactionByType(sharedService.pendingTransactionConstants.MINTING_NFT, userInfo?._id, data?.collection?.collectionId, nftId),
          nftService.checkPendingTransactionByType(sharedService.pendingTransactionConstants.LISTING_NFT, userInfo?._id, data?.collection?.collectionId, nftId)
        ]);

        setLoading(false);

        if (res3?.data?.pendingTransaction) {
          setBlockchainPendingTransactionLoading(true);
          setBlockchainInputGiven(res3.data.inputGiven);
          if (res3.data.inputGiven) {
            setBlockchainInputHash(res3.data.blockchainHash);
          }
          setBlockchainPendingTransactionDetails(res3?.data);

          setLoading(true);
          setTimeout(() => window.location.reload(), 60000); //reload after 1 minute
        } else if (res4?.data?.pendingTransaction) {
          setBlockchainPendingTransactionLoading(true);
          setBlockchainInputGiven(res4.data.inputGiven);
          if (res4.data.inputGiven) {
            setBlockchainInputHash(res4.data.blockchainHash);
          }
          setBlockchainPendingTransactionDetails(res4?.data);

          setLoading(true);
          setTimeout(() => window.location.reload(), 60000); //reload after 1 minute
        } else if (res5?.data?.pendingTransaction) {
          setBlockchainPendingTransactionLoading(true);
          setBlockchainInputGiven(res5.data.inputGiven);
          if (res5.data.inputGiven) {
            setBlockchainInputHash(res5.data.blockchainHash);
          }
          setBlockchainPendingTransactionDetails(res5?.data);

          setLoading(true);
          setTimeout(() => window.location.reload(), 60000); //reload after 1 minute
        }
      }
    }


  }, [nftId, userInfo, networkId, selectedWallet]);

  useEffect(async () => {
    // making this page available for public
    // setTimeout(()=> {
    //     if(!userInfoRef?.current?._id) history.push('/account/login');
    // }, 1000);
  }, [userInfo]);

  useEffect(() => {
    let tempArray = [];
    for (let i = 1; i <= 30; i++) {
      tempArray.push({ label: `${i} ${i < 1 ? "Day" : "Days"}`, value: i });
    }
    setOfferValidityTimeArray(tempArray);
  }, []);

  const disableRightClick = (e) => {
    if (userInfo?._id !== NFTDetails?.userId) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    async function removeWatermark() {
      if (watermarkChecked) {
        const response = await nftService.removeWatermark(NFTDetails?._id);
        if (response.success) {
          notification.open({
            message: "Successfully deleted the watermark",
            duration: 0,
          });
        } else {
          notification.open({
            message: "Error",
            description: response.error.message,
            duration: 0,
          });
        }
      }
    }

    removeWatermark();
  }, [NFTDetails?._id, watermarkChecked]);

  const reportCollectible = async () => {
    if (!reportText) return alert("Please fill up the report text");
    setLoading(true);
    let data = {
      reportingUserName: userInfo.firstName + " " + userInfo.lastName,
      reportingUserId: userInfo?._id,
      reportingUserEmail: userInfo.email,
      nftUserId: NFTDetails.userId,
      nftCreatorUserId: NFTDetails?.createdBy,
      nftId: NFTDetails._id,
      nftName: NFTDetails.title,
      reportReason: reportText,
      creatoionTime: new Date(),
    };

    const response = await nftService.reportCollectible(data);
    if (response.success) {
      notification.open({
        message: "Successfully reported the user about this nft",
        duration: 0,
      });
    } else {
      notification.open({
        message: "Error",
        description: response.error.message,
        duration: 0,
      });
    }
    setLoading(false);
  };

  const reviewNFT = async (nftId) => {
    setLoading(true);
    try {
      let res = await nftService.reviewNFTByAdmin(nftId);
      if (res.data) {
        notification.open({
          message: "Successfully placed nft for verification",
          duration: 0,
        });
        setTimeout(() => window.location.reload(), 2000);
      } else {
        notification.open({
          message: "Error",
          description: "something went wrong!",
          duration: 0,
        });
      }
    } catch (error) {
      console.error(error);
      notification.open({
        message: "Error",
        description: error.message,
        duration: 0,
      });
    }
    setLoading(false);
  };

  const mintNFTDB = async (mintTxnDetails) => {
    setLoading(true);
    setBlockchainNormalLoading(true);
    setInputError(false);

    if (NFTDetails.method == "Fixed Price") {
      if (!NFTDetails.sellingPrice) {
        setInputError(true);
        return;
      }
    } else if (NFTDetails.method == "Auction") {
      if (
        !NFTDetails.bid ||
        !NFTDetails.startingDate ||
        !NFTDetails.expirationDate
      ) {
        setInputError(true);
        return;
      }
    }

    // not requirede as already saved the data at the time of creation or editing
    // if(!NFTDetails.allSellingStatus || NFTDetails.allSellingStatus.length < 1){
    //   NFTDetails.allSellingStatuss = [{
    //     method: NFTDetails.method,
    //     sellingPrice: NFTDetails.price,
    //     bid: NFTDetails.bid,
    //     startingDate: NFTDetails.startingDate,
    //     expirationDate: NFTDetails.expirationDate
    //   }];
    // }else if(NFTDetails.sellingPrice !== NFTDetails.allSellingStatus[NFTDetails.allSellingStatus?.length - 1].sellingPrice){ // this case is just not to push duplicate element
    //   NFTDetails.allSellingStatus = NFTDetails.allSellingStatus.push({
    //     method: NFTDetails.method,
    //     sellingPrice: NFTDetails.price,
    //     bid: NFTDetails.bid,
    //     startingDate: NFTDetails.startingDate,
    //     expirationDate: NFTDetails.expirationDate
    //   })
    // }

    NFTDetails.sellingStatus = "readyForSell";

    NFTDetails.minted = true;
    NFTDetails.nftDrafted = false;
    NFTDetails.blockchainPending = mintTxnDetails?.blockchainPending;
    NFTDetails.blockchainHash = mintTxnDetails?.blockchainHash;
    NFTDetails.mintTxnDetails = { ...mintTxnDetails };
    NFTDetails.status = [...NFTDetails.status, `mintedBy ${NFTDetails.userId}`];
    NFTDetails.userId = userInfo?._id;

    try {
      let res = await nftService.mintNFT({ ...NFTDetails, networkId });
      notification.open({
        message: `Successfully minted a new nft`,
        duration: 0,
      });
      setLoading(false);
      setTimeout(() => window.location.reload(), 1000);
    } catch (error) {
      console.error(error);
      notification.open({
        message: "Error",
        description: "something went wrong while minting nft",
        duration: 0,
      });
      setLoading(false);
    }
    setBlockchainNormalLoading(false);
  };

  const mintNFTBlockchain = async () => {
    if (
      userInfo?.walletAddress?.toLowerCase() === selectedWallet?.toLowerCase()
    ) {
      setLoading(true);
      setBlockchainNormalLoading(true);
      try {
        const txnReceipt = await nftMintingService.mintNft(
          String(NFTDetails?.collection?.NFTMintingContractAddress),
          String(NFTDetails?.assetDataURLIPFS),
          parseInt(NFTDetails?.royalty),
          String(selectedWallet),
          userInfo?.specificMintingFee?.[networkId]?.length > 0 ||
            userInfo?.specificMintingFee?.[networkId] === 0 ||
            isNaN(userInfo?.specificMintingFee?.[networkId]) === false
            ? String(userInfo?.specificMintingFee?.[networkId])
            : String(nftMintingPrice)
        );

        console.log("Blockchain Transaction receipt: ", txnReceipt);
        if (txnReceipt?.hash?.length > 0 || txnReceipt?.blockHash?.length > 0) {
          let blockchainPending = false;
          let blockchainHash = txnReceipt.hash || txnReceipt.blockHash;
          if (txnReceipt.pending && txnReceipt.pending === true) {
            console.log("txn_hash :", txnReceipt.hash);
            blockchainPending = true;
            blockchainHash = txnReceipt.hash || txnReceipt.blockHash;
          }

          let mintTxnDetails = {
            nftId,
            transactionDetails: txnReceipt,
            nftMintingPrice:
              userInfo?.specificMintingFee?.[networkId]?.length > 0 ||
                userInfo?.specificMintingFee?.[networkId] === 0 ||
                isNaN(userInfo?.specificMintingFee?.[networkId]) === false
                ? String(userInfo?.specificMintingFee?.[networkId])
                : String(nftMintingPrice),
            mintedByWalletAddress: selectedWallet,
            nftTitle: NFTDetails?.title,
            nftCollectionName: NFTDetails?.collection?.collectionName,
            nftTradeContractAddress:
              txnReceipt?.events?.Minted?.returnValues?.TradeContractAddr,
            tokenId: txnReceipt?.events?.Minted?.returnValues?._NftId,
            blockchainPending,
            blockchainHash
          };

          //then after minting calling placesell method to list it for sell
          return mintNFTDB({ ...mintTxnDetails });
        } else {
          notification.open({
            message: "Error",
            description:
              "Something went wrong, while initiating blockchain transaction",
            duration: 0,
          });
          return false;
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
        notification.open({
          message: "Error",
          description: error.message,
          duration: 0,
        });
      }
    } else {
      setLoading(false);
      notification.open({
        message: `This wallet address - ${selectedWallet} is not whitelisted by Admin. May be you are using a different wallet.`,
        duration: 0,
      });
    }
    setBlockchainNormalLoading(false);
  };

  const sellNFT = async () => {
    let listTxnDetails;
    let txnReceipt;
    let escrowAddress;
    let blockchainPending = false;
    let blockchainHash = '';
    setInputError(false);

    if (NFTDetails.method === "Fixed Price") {
      if (!NFTDetails.sellingPrice) {
        setInputError(true);
        return;
      }
    } else if (NFTDetails.method === "Auction") {
      if (
        !NFTDetails.bid ||
        !NFTDetails.startingDate ||
        !NFTDetails.expirationDate
      ) {
        setInputError(true);
        return;
      }
    }
    setLoading(true);
    setBlockchainNormalLoading(true);

    // here we need to call and integrate blockchain like top function and then just set the mintTxnDetails object which is response of blockchain code
    try {
      if (NFTDetails.method === "Fixed Price") {
        txnReceipt = await nftTrader.ListNft(
          String(NFTDetails?.nftTradeContractAddress),
          String(NFTDetails?.collection?.NFTMintingContractAddress),
          String(NFTDetails?.tokenId),
          String(NFTDetails.sellingPrice),
          String(selectedWallet),
          userInfo?.specificListingFee?.[networkId]?.length > 0 ||
            userInfo?.specificListingFee?.[networkId] === 0 ||
            isNaN(userInfo?.specificListingFee?.[networkId]) === false
            ? String(userInfo?.specificListingFee?.[networkId])
            : String(nftListingPrice)
        );
      } else if (NFTDetails.method === "Auction") {
        let expiresOn = new Date(expirationTimeStamp)?.getTime();
        let currentTimeStamp = new Date()?.getTime();
        console.log('expiresOn:' + expiresOn + ', currentTimeStamp:' + currentTimeStamp)
        let diff = parseInt(
          (parseInt(expiresOn) - parseInt(currentTimeStamp)) / 1000
        );

        txnReceipt = await nftTrader.ListNftOnAuction(
          String(NFTDetails?.nftTradeContractAddress),
          String(NFTDetails?.collection?.NFTMintingContractAddress),
          String(NFTDetails?.tokenId),
          String(NFTDetails?.sellingPrice),
          String(diff),
          String(selectedWallet),
          userInfo?.specificListingFee?.[networkId]?.length > 0 ||
            userInfo?.specificListingFee?.[networkId] === 0 ||
            isNaN(userInfo?.specificListingFee?.[networkId]) === false
            ? String(userInfo?.specificListingFee?.[networkId])
            : String(nftListingPrice),
          String(environment.bidPercentage)
        );
      } else if (NFTDetails.method === "Open for Bids") {
        txnReceipt = await nftTrader.ListNftOnOpenForBids(
          String(NFTDetails?.nftTradeContractAddress),
          String(NFTDetails?.collection?.NFTMintingContractAddress),
          String(NFTDetails?.tokenId),
          String(selectedWallet),
          userInfo?.specificListingFee?.[networkId]?.length > 0 ||
            userInfo?.specificListingFee?.[networkId] === 0 ||
            isNaN(userInfo?.specificListingFee?.[networkId]) === false
            ? String(userInfo?.specificListingFee?.[networkId])
            : String(nftListingPrice),
          String(environment.bidPercentage)
        );
      }

      console.log("Blockchain Transaction receipt: ", txnReceipt);

      if (txnReceipt?.hash?.length > 0 || txnReceipt?.blockHash?.length > 0) {
        if (txnReceipt.pending && txnReceipt.pending === true) {
          console.log("txn_hash :", txnReceipt.hash);
          blockchainPending = true;
          blockchainHash = txnReceipt.hash || txnReceipt.blockHash;
        }

        try {
          escrowAddress = await nftTrader.escrowContractAddress(
            String(NFTDetails?.collection?.NFTMintingContractAddress),
            String(NFTDetails?.tokenId),
            String(selectedWallet),
            String(NFTDetails?.nftTradeContractAddress),
          )
          console.log("Escrow Contract Address:", escrowAddress);
        } catch (error) {
          console.log(error);
          setLoading(false);
          notification.open({
            message: "Error",
            description: error.message,
            duration: 0,
          });
        }
        let ListingTxnDetails = {
          nftId,
          transactionDetails: txnReceipt,
          nftListingPrice,
          ListedByWalletAddress: selectedWallet,
          nftListedOn: NFTDetails.method,
          nftTitle: NFTDetails?.title,
          nftCollectionName: NFTDetails?.collection?.collectionName,
          nftTradeContractAddress: txnReceipt?.events?.Minted?.returnValues?.TradeContractAddr,
          tokenId: txnReceipt?.events?.Minted?.returnValues?._NftId,
          // change here the right escrow address
          escrowContractAddress: escrowAddress,
        };

        listTxnDetails = { ...ListingTxnDetails };
        console.log(listTxnDetails);
      } else {
        notification.open({
          message: "Error",
          description:
            "Something went wrong, while initiating blockchain transaction",
          duration: 0,
        });
        return false;
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      setBlockchainNormalLoading(false);
      notification.open({
        message: "Error",
        description: error.message,
        duration: 0,
      });
    }

    // below code is for database calling only
    NFTDetails.sellingStatus = "selling";
    NFTDetails.listTxnDetails = { ...listTxnDetails };
    NFTDetails.status = [
      ...NFTDetails.status,
      `placeForSellBy ${NFTDetails.userId}`,
    ];
    NFTDetails.nftId = NFTDetails._id;

    NFTDetails.escrowContractAddress = escrowAddress;
    NFTDetails.escrowContractCleared = false;
    NFTDetails.blockchainPending = blockchainPending;
    NFTDetails.blockchainHash = blockchainHash;
    NFTDetails.userId = userInfo?._id;
    console.log(NFTDetails);
    try {
      let res = await nftService.sellNFT({ ...NFTDetails, networkId });
      notification.open({
        message: `This NFT has been Successfully Placed for Sell`,
        duration: 0,
      });
      setLoading(false);
      setTimeout(() => window.location.reload(), 1000);
    } catch (error) {
      console.error(error);
      notification.open({
        message: "Error",
        description: "something went wrong while placing for sell nft",
        duration: 0,
      });
    }

    setBlockchainNormalLoading(false);
  };

  const downloadNFT = async (url, fileName) => {
    notification.open({
      message: "Download Started",
      duration: 0,
    });

    fetch(url, {
      method: "GET",
      headers: {
        // 'Content-Type': 'application/pdf',
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });
  };

  const getOriginalNFTURL = async () => {
    setLoading(true);
    try {
      let res = await nftService.getNFTFirebaseURLbyOwner(userInfo?._id, { networkId, nftId });

      if (res?.data?.file?.FirebaseURL?.length > 0) {
        setOriginalNFTDownloaded(true);
        window.open(res.data.file.FirebaseURL, "_blank").focus();
      }
    } catch (error) {
      console.error(error);
      notification.open({
        message: "Error",
        description:
          "something went wrong while downloding NFT Original Image URL! Please try again later!",
        duration: 0,
      });
    } finally {
      setLoading(false);
    }
  };

  const signSellBuyAgreement = async () => {
    setLoading(true);
    try {
      const response = await nftService.getSellBuyNFTAgreementURL(
        userInfo?._id
      );
      if (response.data) {
        setSignURL(response.data);
        setOpenEversign(true);
      } else {
        message.error(
          "Something went wrong while fetching nft buy sell agreemnt url, please try again."
        );
      }
    } catch (error) {
      console.error(error);
      message.error(
        "Something went wrong while fetching nft buy sell agreemnt url, please try again."
      );
    }
    setLoading(false);
  };

  const signNFTAgreementSigned = async () => {
    setLoading(true);
    try {
      const response = await nftService.setSellBuyNFTAgreement({
        agreementType: "sell",
        userId: userInfo?._id,
        nftId: NFTDetails?._id,
        nftMethod: NFTDetails.method,
        agreementURL: signURL,
        creationTS: new Date().getTime(),
      });
      if (response.success && response.data) {
        setOpenEversign(false);
        notification.open({
          message: "Successfully signed NFT Sell Agreement",
          duration: 0,
        });
        return mintNFTBlockchain();
      }
    } catch (error) {
      console.error(error);
      message.error(
        "Something went wrong while submitting user agreement, please try again."
      );
      setLoading(false);
      return false;
    }
  };

  const sellNFTToHighestBiddedUser = async () => {
    setLoading(true);
    try {
      const response = await nftService.requestForSellToHighestBiddedUser({
        nftId: NFTDetails?._id,
        maxBiddedUserId,
        status: [
          ...NFTDetails.status,
          `requestForSellBy ${userInfo?._id} to ${maxBiddedUserId}`,
        ],
      });
      if (response.success && response.data) {
        notification.open({
          message: "Successfully requested for sell of NFT",
          duration: 0,
        });
        setTimeout(() => window.location.reload(), 1000);
      }
    } catch (error) {
      console.error(error);
      message.error(
        "Something went wrong while requesting for sell of NFT, please try again."
      );
      setLoading(false);
      return false;
    }
  };

  const sellNFTToAnyBiddedUser = async (anyBiddedUserId) => {
    setLoading(true);
    try {
      const response = await nftService.requestForSellToAnyBiddedUser({
        nftId: NFTDetails?._id,
        anyBiddedUserId,
        status: [
          ...NFTDetails.status,
          `requestForSellBy ${userInfo?._id} to ${anyBiddedUserId}`,
        ],
      });
      if (response.success && response.data) {
        notification.open({
          message: "Successfully requested for sell of NFT",
          duration: 0,
        });
        setTimeout(() => window.location.reload(), 1000);
      }
    } catch (error) {
      console.error(error);
      message.error(
        "Something went wrong while requesting for sell of NFT, please try again."
      );
      setLoading(false);
      return false;
    }
  };

  const updateExpirationDate = async () => {
    setInputError2(false);
    if (
      expirationDate === NFTDetails.expirationDate ||
      !(new Date(expirationDate?.toString()) > new Date()) ||
      !hours ||
      !minutes
    ) {
      return setInputError2(true);
    }

    setLoading(true);
    setBlockchainNormalLoading(true);
    try {
      let expirationTimestamp = new Date(
        new Date(expirationDate).setHours(hours, minutes, 0)
      ).getTime();
      console.log(`expiration date and time is: expirationDate:${expirationDate}, hours:${hours}, minutes:${minutes}`);
      let expiresOn = new Date(expirationTimestamp)?.getTime();
      let currentTimeStamp = new Date()?.getTime();
      console.log(`expiresOn: ${expiresOn}, currentTimeStamp: ${currentTimeStamp}`);
      let diff = parseInt(
        (parseInt(expiresOn) - parseInt(currentTimeStamp)) / 1000
      );

      const txnReceipt = await nftTrader.ListAgainOnAuction(
        String(NFTDetails?.nftTradeContractAddress),
        String(NFTDetails?.collection?.NFTMintingContractAddress),
        String(NFTDetails?.tokenId),
        String(NFTDetails?.sellingPrice),
        String(diff),
        String(selectedWallet),
        String(environment.bidPercentage)
      );

      console.log("Blockchain Transaction receipt: ", txnReceipt);
      if (txnReceipt?.hash?.length > 0 || txnReceipt?.blockHash?.length > 0) {
        let blockchainPending = false;
        let blockchainHash = txnReceipt.hash || txnReceipt.blockHash;
        if (txnReceipt.pending && txnReceipt.pending === true) {
          console.log("txn_hash :", txnReceipt.hash);
          blockchainPending = true;
          blockchainHash = txnReceipt.hash || txnReceipt.blockHash;
        }

        const response = await nftService.updateExpirationDate({
          nftId: NFTDetails?._id,
          expirationDate: expirationDate,
          expirationHours: hours,
          expirationMinutes: minutes,
          expirationTimestamp,
          expirationDateUpdated: true,
          enableChangeAuctionDate: false,
          networkId,
          blockchainPending,
          blockchainHash,
          userId: userInfo?._id,
          transactionDetails: {
            txnReceipt,
            ListedByWalletAddress: selectedWallet,
            nftListedOn: NFTDetails.method,
          },
        });

        if (response.success && response.data) {
          notification.open({
            message: "Successfully updated expiration date for this NFT",
            duration: 0,
          });
          setTimeout(() => window.location.reload(), 1500);
        }
      }
    } catch (error) {
      console.error(error);
      message.error(
        "Something went wrong while requesting updateExpirationDate, please try again."
      );
      setLoading(false);
      return false;
    }

    setBlockchainNormalLoading(false);
  };

  const handleShow = () => {
    setMethod("Fixed Price");
    document.getElementById("tab_opt_1")?.classList.add("show");
    document.getElementById("tab_opt_1")?.classList.remove("hide");
    document.getElementById("tab_opt_2")?.classList.remove("show");
    document.getElementById("btn1")?.classList.add("active");
    document.getElementById("btn2")?.classList.remove("active");
    document.getElementById("btn3")?.classList.remove("active");
  };
  const handleShow1 = () => {
    setMethod("Auction");
    document.getElementById("tab_opt_1")?.classList.add("hide");
    document.getElementById("tab_opt_1")?.classList.remove("show");
    document.getElementById("tab_opt_2")?.classList.add("show");
    document.getElementById("btn1")?.classList.remove("active");
    document.getElementById("btn2")?.classList.add("active");
    document.getElementById("btn3")?.classList.remove("active");
  };
  const handleShow2 = () => {
    setMethod("Open for Bids");
    document.getElementById("tab_opt_1")?.classList.add("hide");
    document.getElementById("tab_opt_2")?.classList.add("hide");
    document.getElementById("tab_opt_1")?.classList.remove("show");
    document.getElementById("tab_opt_2")?.classList.remove("show");
    document.getElementById("btn1")?.classList.remove("active");
    document.getElementById("btn2")?.classList.remove("active");
    document.getElementById("btn3")?.classList.add("active");
  };

  const resellNFT = async (_form) => {
    if (userInfo.walletAddress.toLowerCase() === selectedWallet.toLowerCase()) {
      //if not copyright or watermark message then reject it
      if (!method || (method !== "Fixed Price" && !offerValidityTime)
      ) {
        alert("Please fill out all the available options!");
        return false;
      }

      setMinPriceError(false);
      setDateTimeError(false);

      const form = { ..._form };
      form.userId = userInfo._id;
      form.nftId = NFTDetails.nftId || NFTDetails._id;
      //form.files = documentsToSubmit;
      form.method = method;
      form.status = [...NFTDetails.status, `resellBy ${userInfo._id}`];

      console.log('generalListingCommission: ' + generalListingCommission, ', generalListingCommission: ' + generalSellingCommission);

      if (method === "Fixed Price") {
        // checking for minimum price of nft
        if (price <= (generalListingCommission + generalSellingCommission)) {
          setMinPriceError(true);
          return false;
        }

        form.price = price;
        form.sellingPrice = price;
      } else if (method === "Auction") {
        if (!hours?.length > 0 || !minutes?.length > 0) {
          setDateTimeError(true);
          return;
        }

        // checking for minimum price of nft
        if (bid <= (generalListingCommission + generalSellingCommission)) {
          setMinPriceError(true);
          return false;
        }

        form.bid = bid;
        form.sellingPrice = bid;
        form.startingDate = startingDate;
        form.expirationDate = formExpirationDate;
        form.expirationHours = hours;
        form.expirationMinutes = minutes;
        form.expirationTimestamp = new Date(
          new Date(formExpirationDate).setHours(hours, minutes, 0)
        ).getTime();
      }

      setMinPriceError(false);

      if (method !== "Fixed price") {
        form.offerValidityTime = offerValidityTime;
      }
      form.biddingDetails = [];

      form.allSellingStatus = [...NFTDetails.allSellingStatus,
      {
        method,
        sellingPrice: method === "Fixed Price" ? price : bid,
        startingDate: method === "Auction" ? startingDate : null,
        expirationDate: method === "Auction" ? expirationDate : null,
      },
      ];
      form.networkId = networkId;
      form.sellingStatus = 'selling';
      form.sold = false;
      form.escrowContractCleared = false;
      form.escrowContractStatus = {
        complianceAdminApproved: false,
        tokenAdminApproved: false,
        superAdminApproved: false
      }
      form.biddingDone = false;
      form.bidWonUserId = "";

      setLoading(true);
      setBlockchainNormalLoading(true);
      let txnReceipt;
      let blockchainPending = false;
      let blockchainHash = txnReceipt?.hash || txnReceipt?.blockHash;
      // here call the blockchain to do the relisting
      try {
        if (method === "Fixed Price") {
          txnReceipt = await nftTrader.ReListNft(
            String(NFTDetails?.nftTradeContractAddress),
            String(NFTDetails?.collection?.NFTMintingContractAddress),
            String(NFTDetails?.tokenId),
            String(price),
            String(selectedWallet),
            userInfo?.specificListingFee?.[networkId]?.length > 0 ||
              userInfo?.specificListingFee?.[networkId] === 0 ||
              isNaN(userInfo?.specificListingFee?.[networkId]) === false
              ? String(userInfo?.specificListingFee?.[networkId])
              : String(nftListingPrice)
          );
        } else if (method === "Auction") {
          let expiresOn = new Date(form.expirationTimestamp)?.getTime();
          let currentTimeStamp = new Date()?.getTime();
          let diff = parseInt(
            (parseInt(expiresOn) - parseInt(currentTimeStamp)) / 1000
          );

          txnReceipt = await nftTrader.ReListNftOnAuction(
            String(NFTDetails?.nftTradeContractAddress),
            String(NFTDetails?.collection?.NFTMintingContractAddress),
            String(NFTDetails?.tokenId),
            String(bid),
            String(diff),
            String(selectedWallet),
            userInfo?.specificListingFee?.[networkId]?.length > 0 ||
              userInfo?.specificListingFee?.[networkId] === 0 ||
              isNaN(userInfo?.specificListingFee?.[networkId]) === false
              ? String(userInfo?.specificListingFee?.[networkId])
              : String(nftListingPrice),
            String(environment.bidPercentage)
          );
        } else if (method === "Open for Bids") {
          txnReceipt = await nftTrader.ReListNftOnOpenForBids(
            String(NFTDetails?.nftTradeContractAddress),
            String(NFTDetails?.collection?.NFTMintingContractAddress),
            String(NFTDetails?.tokenId),
            String(selectedWallet),
            userInfo?.specificListingFee?.[networkId]?.length > 0 ||
              userInfo?.specificListingFee?.[networkId] === 0 ||
              isNaN(userInfo?.specificListingFee?.[networkId]) === false
              ? String(userInfo?.specificListingFee?.[networkId])
              : String(nftListingPrice),
            String(environment.bidPercentage)
          );
        }
        console.log("Blockchain Transaction receipt: ", txnReceipt);

      } catch (error) {
        console.log(error);
        setLoading(false);
        setBlockchainNormalLoading(false);
        notification.open({
          message: "Error",
          description: error.message,
          duration: 0,
        });
      }

      try {
        console.log("NFTDetails: ", form);
        if (!txnReceipt) return false;
        if (txnReceipt?.hash?.length > 0 || txnReceipt?.blockHash?.length > 0) {
          if (txnReceipt.pending && txnReceipt.pending === true) {
            console.log("txn_hash :", txnReceipt.hash);
            blockchainPending = true;
            blockchainHash = txnReceipt.hash || txnReceipt.blockHash;
          }

          const response = await nftService.relistNFT({ ...form, blockchainPending, blockchainHash, userId: userInfo?._id });
          if (response.success) {
            notification.open({
              message: "Successfully relisted the existing NFT!",
              duration: 0,
            });

            //setLoading(false);
            setTimeout(() => (window.location.reload()), 500);
          } else {
            setLoading(false);
            setBlockchainNormalLoading(false);
            notification.open({
              message: "Error",
              description: response.error.message,
              duration: 0,
            });
          }
        }
      } catch (error) {
        setLoading(false);
        setBlockchainNormalLoading(false);
        notification.open({
          message: error.message,
          duration: 0,
        });
      }
    }
    setBlockchainNormalLoading(false);
  };

  const claimEscrowAmountAfterCertainPeriod = async () => {
    setLoading(true);
    setBlockchainNormalLoading(true);
    let transactionDetails;
    // here call the blockchain to do the release amount fromm escrow
    try {
      const escrowContractAddress = await nftTrader.escrowContractAddress(
        String(NFTDetails?.collection?.NFTMintingContractAddress),
        String(NFTDetails?.tokenId),
        String(selectedWallet),
        String(NFTDetails?.nftTradeContractAddress),
      )
      console.log("Escrow Contract Address", escrowContractAddress);
      transactionDetails = await nftEscrowService.ClaimEscrowAmount(
        String(selectedWallet),
        String(escrowContractAddress)
      )
      console.log("blockchain transaction receipt", transactionDetails);
    } catch (error) {
      console.log(error);
      setLoading(false);
      setBlockchainNormalLoading(false);
      notification.open({
        message: "Error",
        description: error.message,
        duration: 0,
      });
      return false;
    }

    try {
      let form = {};
      form.nftId = NFTDetails.nftId;
      form.transactionDetails = transactionDetails;
      form.escrowContractCleared = true;
      form.escrowContractStatus = {
        complianceAdminApproved: true,
        tokenAdminApproved: true,
        superAdminApproved: true
      }
      form.networkId = networkId;

      const response = await nftService.claimAmountInEscrow({ ...form });
      if (response.success) {
        notification.open({
          message: "Successfully claimEscrowAmountAfterCertainPeriod the existing NFT!",
          duration: 0,
        });

        //setLoading(false);
        setBlockchainNormalLoading(false);
        setTimeout(() => (window.location.reload()), 500);
      } else {
        setLoading(false);
        setBlockchainNormalLoading(false);
        notification.open({
          message: "Error",
          description: response.error.message,
          duration: 0,
        });
      }
    } catch (error) {
      setLoading(false);
      setBlockchainNormalLoading(false);
      notification.open({
        message: error.message,
        duration: 0,
      });
    }
  };

  const getUserFullNameByUserId = (userId) => {
    const userDetails = someUserDetails.find(usr => usr._id === userId);
    if (userDetails?._id) {
      return userDetails.firstName + " " + userDetails.lastName;
    } else {
      return "";
    }
  }

  const saveInputBlockchainHash = async () => {
    if (!blockchainInputHash || blockchainInputHash.length !== 66) {
      message.error("Please provide correct successfull blockchain hash or transactionId!");
      return false;
    }
    setLoading(true);

    try {
      const response = await nftService.addBlockchainHashToPendingTransaction({ inputBlockchainHash: blockchainInputHash, pendingTransactionId: blockchainPendingTransactionDetails?._id });
      if (response.success && response.data) {
        setBlockchainInputGiven(true);
        notification.open({
          message: response.data,
          duration: 0,
        });
        setTimeout(() => (window.location.reload()), 2000);
      } else {
        console.log('else block');
        message.error(response?.error?.code?.message);
        console.error(response?.error?.code);
      }
    } catch (err) {
      message.error('Error while submitting blockchain hash to system. Please try again later!');
      console.log('catch block');
      console.error(err);
    }
  };
  const propertyColumns = [
    {
      title: 'Property no',
      dataIndex: 'index',
      key: 'index',
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Type',
      dataIndex: 'propertyType',
      key: 'propertyType',
    },
    {
      title: 'Name',
      dataIndex: 'propertyName',
      key: 'propertyName',
    }
  ];

  const propertyData = NFTDetails?.property || [];

  const biddingDetailsColumns = [
    {
      title: 'User name',
      dataIndex: 'userName',
      key: 'userName',
      render: (text, record) => `${record.firstName} ${record.lastName}`,
    },
    {
      title: "Bidded",
      dataIndex: "price",
      key: "price",
      render: (text, record) => `${record.price} ETH`,
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (text) => moment(text).format("MMMM DD, YYYY hh:mm:ss A"),
    },
  ];

  const transformedData = biddingDetails.map((item) => ({
    ...item,
    userName: `${item.firstName} ${item.lastName}`,
  }));

  const biddingDetailsSource = transformedData || [];

  const placeABid = (nft) => {
    history.push(`/item-buy/${nft._id}`, "_self");
  };

  return (
    <div>
      <GlobalStyles />
      {loading && (<>
        <div className='loading-spinner-blockchain' style={{ textAlign: 'center' }}>
          <br />
          <Spin style={{ size: 'large', textAlign: 'center' }} />
          {blockchainNormalLoading && (<h6>Please wait, Blockchain transaction is processing</h6>)}
          {blockchainPendingTransactionLoading && !blockchainNormalLoading && (<>
            {!blockchainInputGiven && (
              <h6>We are processing your transaction, please wait for Blockchain to complete the transaction. Still if you want to speed up the transaction, go on Metamask select the pending transaction, click on Speedup button. Select the Site Suggested as Aggressive. Then click on Confirm Button</h6>)}

            {blockchainInputGiven && (
              <h6>We are processing your new transaction, please wait for sometime!</h6>)}

            {!blockchainInputGiven && (<>
              <br />
              <Form
                name="blockchainInputForm"
                onFinish={saveInputBlockchainHash}
              >
                <Form.Item
                  label="Successfully Executed Blockchain Hash or TransactionId"
                  name="textInput"
                  rules={[{ required: true, message: 'Please input your TransactionId!' }]}
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <Input
                    type="text"
                    name="blockchainInputHash"
                    value={blockchainInputHash}
                    onChange={(e) =>
                      setBlockchainInputHash(e.target.value)
                    }
                    style={{ width: '400px' }}
                  />
                </Form.Item>

                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </>)}
          </>)}
        </div>
      </>)}

      {!loading && NFTDetails && !openEversign && !showResellingScreen && (
        <>
          <section className="container" onContextMenu={disableRightClick}>
            {/* Only show the below steps for nft creator and owner */}
            {userInfo?._id &&
              (userInfo?._id === NFTDetails?.createdBy ||
                userInfo?._id === NFTDetails?.owner?._id) && (
                  <Steps
                  style={{ width: "100%" }}
                  size="medium"
                  current={stepNo}
                  items={[
                      { title: <Tooltip title="Draft">Draft</Tooltip> },
                      { title: <Tooltip title="Request Approval">Request Approval</Tooltip> },
                      { title: <Tooltip title="Approved">Approved</Tooltip> },
                      { title: <Tooltip title="Mint">Mint</Tooltip> },
                      { title: <Tooltip title="Place For Sell">Place For Sell</Tooltip> },
                      { title: <Tooltip title="Sold">Sold</Tooltip> },
                      { title: <Tooltip title="Reselling">Reselling</Tooltip> },
                      { title: <Tooltip title="Resold">Resold</Tooltip> },
                  ]}
              />
              )}
            <div className="row pt-md-4">
              <div className="col-md-6 text-center">
                <div className="nft__item_wrap nft-detail img-parent-div">
                  <Outer>
                    <span className="img-detail-wrapper">
                      {NFTDetails.file.type === "mp4" ||
                        NFTDetails.file.type === "MP4" ? (
                        <VideoPlayer
                          linkSrc={NFTDetails.file.IPFSURL}
                          playerId="player-1"
                          coverId="nft-cover"
                          //coverImageLink={NFTDetails.file?.videoWatermarkImageURL}
                          coverImageLink={NFTDetails.file?.videoThumbnailUrl}
                          playButtonId="play-1"
                          subsButtonId="subs-1"
                          height="350px"
                          width="100%"
                          coverImageHeight="350px"
                          autoplay="off"
                        />
                      ) : (
                        <img
                          src={NFTDetails.file.IPFSURL}
                          className="img-fluid img-rounded mb-sm-30"
                          style={{ width: "100%" }}
                          alt=""
                        />
                      )}
                    </span>
                  </Outer>
                </div>

                {/* table 1 */}
                <div className="d-flex flex-column">
                  {!NFTDetails?.property.some(
                    (property) =>
                      property.propertyName === "" ||
                      property.propertyType === ""
                  ) &&
                    <p className="nft-detail properties-text">{'Properties'}</p>
                  }

                  {
                    NFTDetails?.property && NFTDetails?.property?.length ? <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 table-border">
                      <Table className="nft-detail" columns={propertyColumns} dataSource={propertyData} pagination={false} bordered rowStyle={() => rowStyle} />
                    </div> : null
                  }

                </div>

                {/* table 2 */}
                {showBiddingDetails &&
                  NFTDetails.sellingStatus === "selling" &&
                  userInfo?.walletAddress?.toLowerCase() ===
                  selectedWallet?.toLowerCase() &&
                  NFTDetails.method !== "Fixed Price" && <div className="d-flex flex-column">

                    {
                      biddingDetails?.length > 0 &&
                      <p className="nft-detail usersbids-text">{'List of users bids'}</p>
                    }
                    <p className="nft-detail bidded-amount"><span>{maxBiddingAmount ? 'Maximum Bidded amount by user :' : ''}</span>
                      {maxBiddingAmount && <span className="nft-detail ETH-val">{maxBiddingAmount} ETH</span>}
                      {
                        !biddingDetails?.length && <>
                          <p className="nft-detail BiddingDetails">
                            <span>BiddingDetails:</span></p>
                          <button className="nft-detail bit-chip">{'No bid till now'}</button>
                        </>
                      }
                    </p>

                    {
                      biddingDetails?.length > 0 &&
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 table-border">
                        <Table className="nft-detail" columns={biddingDetailsColumns} dataSource={biddingDetailsSource} pagination={false} bordered rowStyle={() => rowStyle} />
                      </div>
                    }
                  </div>
                }

                {
                  NFTDetails.minted === true &&
                  NFTDetails.sellingStatus === "selling" &&
                  NFTDetails.method === "Auction" &&
                  userInfo?._id && (
                    <p className="nft-detail note-text">{'Note:'}
                      <span className="nft-detail note-sub-text">{'If No bid found up to the last auction date then you will be given an option to change the end auction date again!'}</span>
                    </p>
                  )
                }


                {/* download buttons */}
                {/* watermark origin nft download */}
                {NFTDetails?.userId === userInfo?._id && NFTDetails?.minted === true && (
                  <div
                    className="text-center"
                    style={{
                      marginTop: "20px",
                      display: "inline-block",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <button
                      type="button"
                      className="btn-main download-nft-button"
                      style={{ padding: "3px 10px" }}
                      onClick={() =>
                        downloadNFT(
                          NFTDetails.file.IPFSURL,
                          "watermark-" + NFTDetails.file.name
                        )
                      }
                    >
                      <span style={{ fontSize: "12px" }}>
                        Download Watermark NFT
                      </span>
                    </button>
                  </div>
                )}
                <br />
                {/* origin nft download */}
                {NFTDetails?.userId === userInfo?._id &&
                  selectedWallet?.toLowerCase() ===
                    userOriginalWalletAddress?.toLowerCase() && NFTDetails?.minted === true && (
                    <div
                      className="text-center"
                      style={{
                        marginTop: "20px",
                        display: "inline-block",
                        justifyContent: "center",
                        marginBottom: "10px",
                      }}
                    >
                      <button
                        type="button"
                        className="btn-main download-nft-button download-original-nft-button"
                        style={{
                          padding: "3px 10px",
                          backgroundColor: "dimgray",
                        }}
                        onClick={getOriginalNFTURL}
                        disabled={originalNFTDownloaded}
                      >
                        Download Original NFT
                        {/* <a
                          href={NFTDetails.file.FirebaseURL}
                          download
                          target="_blank"
                          style={{ fontSize: "12px" }}
                        >
                          Download Original NFT
                        </a> */}
                      </button>
                    </div>
                  )}

                <br /><br />
                {/* Resell NFT Button */}
                {NFTDetails?.userId === userInfo?._id &&
                  selectedWallet?.toLowerCase() === userOriginalWalletAddress?.toLowerCase() &&
                  NFTDetails.previousOwnerIds?.length > 0 && NFTDetails.sellingStatus === "sold" &&
                  environment.resell === true && NFTDetails.escrowContractCleared === true && (
                    <div
                      className="text-center"
                      style={{
                        marginTop: "20px",
                        display: "inline-block",
                        justifyContent: "center",
                        marginBottom: "10px",
                      }}
                    >
                      <button
                        type="button"
                        className="btn-main download-nft-button download-original-nft-button"
                        style={{
                          padding: "3px 30px",
                          backgroundColor: "royalblue",
                        }}
                        onClick={() => setShowResellingScreen(true)}
                      >
                        Resell NFT
                      </button>
                    </div>
                  )}

                <br />
                {/* Claim Escrow Amount Button in case of either you are the creator or the previous owner or seller*/}
                {(NFTDetails?.createdBy === userInfo?._id || NFTDetails?.previousOwnerId === userInfo?._id) &&
                  NFTDetails.previousOwnerIds?.length > 0 && NFTDetails.sellingStatus === "sold" &&
                  environment.resell === true && NFTDetails.escrowContractCleared === false &&
                  claimExpirationDate && new Date() > claimExpirationDate && (
                    <div
                      className="text-center"
                      style={{
                        marginTop: "20px",
                        display: "inline-block",
                        justifyContent: "center",
                        marginBottom: "10px",
                      }}
                    >
                      <button
                        type="button"
                        className="btn-main download-nft-button download-original-nft-button"
                        style={{
                          padding: "3px 30px",
                          backgroundColor: "royalblue",
                        }}
                        onClick={claimEscrowAmountAfterCertainPeriod}
                      >
                        Claim Holding Amount in Escrow
                      </button>
                    </div>
                  )}

                <br />
                {NFTDetails.minted === true && userInfo?._id && (
                  <div
                    className="report"
                    style={{ position: "absolute", bottom: 0, right: "10px" }}
                  >
                    <Popconfirm
                      title={
                        <>
                          <p>This NFT will be reported</p>
                          <input
                            type="text"
                            placeholder=""
                            value={reportText}
                            onChange={(e) => setReportText(e.target.value)}
                          />
                        </>
                      }
                      onConfirm={reportCollectible}
                      style={{ marginTop: "10px" }}
                      className="report-popup"
                    >
                      {/* <Button type="primary" style={{fontSize: '10px'}} danger>Report</Button> */}
                      <p style={{ fontSize: "12px" }}>
                        Report Incorrect Information
                      </p>
                    </Popconfirm>
                  </div>
                )}
              </div>
              <div className="col-md-6">
                <div className="nft-detail item_info">
                  {/* Auctions ends in 
                        <div className="de_countdown">
                            <Clock deadline="December, 30, 2021" />
                        </div> */}
                  <h2 className="nft-detail item_title">Title: {NFTDetails?.title}</h2>
                  <p className="nft-detail item_creator">Creator</p>
                  <div className="nft-detail item_creator_div">
                    <p className="nft-detail item_creator_name"> {NFTDetails.user?.firstName +
                      " " +
                      NFTDetails.user?.lastName}</p>
                    <img alt="profile pic" className="nft-detail profile-picurl" src={NFTDetails.user?.profilePicUrl
                      ? NFTDetails.user.profilePicUrl
                      : ProfilePic}></img>
                  </div>
                  <p className="nft-detail details-desc">Description: {NFTDetails?.desc}</p>
                  {/* <p className="nft-detail collection-name" onClick={() =>
                    history.push(
                      `/collection/${NFTDetails?.collection?.collectionName}`,
                      "_self"
                    )
                  }>{'Collection name'}</p> */}

                  {/* Card 1 */}
                  <div className="nft-detail details-card1">
                    <div className="nft-detail d-flex details-card-pDiv" >


                      {NFTDetails.method === "Fixed Price" && (
                        <div className="nft-detail col-xl-6 col-lg-6 col-md-6 col-sm-12 current-price-div">
                          <p className="nft-detail current-price">{'Current price'}</p>
                          <p className="nft-detail current-price-val">{NFTDetails?.sellingPrice} ETH</p>
                          <div className="nft-detail price-div">
                            <div>
                              <p className="nft-detail current-price">{'Price in USD'}</p>
                              <p className="nft-detail price-val">      {(
                                NFTDetails.sellingPrice * todayEtheriumPriceInUSD
                              ).toFixed(3) + " $"}</p>
                            </div>
                            <div style={{ marginLeft: '20px' }}>
                              <p className="nft-detail current-price">{'Method'}</p>
                              <p className="nft-detail price-val">{NFTDetails?.method}</p>
                            </div>
                          </div>
                        </div>
                      )}
                      {
                        NFTDetails.method === "Auction" && stepNo !== 6 && (
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 nft-detail current-price-div">
                            <p className="nft-detail current-price">{'Min price'}</p>
                            <p className="nft-detail current-price-val">{NFTDetails?.sellingPrice} ETH</p>
                            <div className="price-div">
                              <div>
                                <p className="nft-detail current-price">{'Min Price in USD'}</p>
                                <p className="nft-detail price-val">      {(
                                  NFTDetails.sellingPrice * todayEtheriumPriceInUSD
                                ).toFixed(3) + " $"}</p>
                              </div>
                              <div style={{ marginLeft: '20px' }}>
                                <p className="nft-detail current-price">{'Method'}</p>
                                <p className="nft-detail price-val">{NFTDetails?.method}</p>
                              </div>
                            </div>
                          </div>
                        )
                      }
                      {NFTDetails.method === "Auction" && stepNo === 6 && (
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 nft-detail current-price-div">
                          <p className="nft-detail current-price">{'Sold Price'}</p>
                          <p className="nft-detail current-price-val">{maxBiddingAmount} ETH</p>
                          <div className="nft-detail price-div">
                            <div>
                              <p className="nft-detail current-price">{'Sold Price in USD'}</p>
                              <p className="nft-detail price-val">     {(maxBiddingAmount * todayEtheriumPriceInUSD).toFixed(
                                3
                              ) + " $"}</p>
                            </div>
                            <div style={{ marginLeft: '20px' }}>
                              <p className="nft-detail current-price">{'Method'}</p>
                              <p className="nft-detail price-val">{NFTDetails?.method}</p>
                            </div>
                          </div>
                        </div>
                      )}
                      {NFTDetails.method === "Open for Bids" && stepNo === 6 && (
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 nft-detail current-price-div">
                          <p className="nft-detail current-price">{'Sold Price'}</p>
                          <p className="nft-detail current-price-val">{NFTDetails.sellingPrice} ETH</p>
                          <div className="nft-detail price-div">
                            <div>
                              <p className="nft-detail current-price">{'Sold Price in USD'}</p>
                              <p className="nft-detail price-val">{(
                                NFTDetails.sellingPrice * todayEtheriumPriceInUSD
                              ).toFixed(3) + " $"}</p>
                            </div>
                            <div style={{ marginLeft: '20px' }}>
                              <p className="nft-detail current-price">{'Method'}</p>
                              <p className="nft-detail price-val">{NFTDetails?.method}</p>
                            </div>
                          </div>
                        </div>
                      )}
                      {NFTDetails.method === "Open for Bids" && stepNo !== 6 && (
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 current-price-div">
                          <div>
                            <p className="nft-detail current-price">{'Method'}</p>
                            <p className="nft-detail price-val">{NFTDetails?.method}</p>
                          </div>
                        </div>
                      )}

                      {/* PLACE A BID button */}

                      {
                        NFTDetails.userId !== userInfo?._id && NFTDetails.createdBy !== userInfo?._id &&
                        NFTDetails.method !== "Fixed Price" && NFTDetails.sellingStatus === "selling" && !biddingDone && <div className="bid-btn-div col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex justify-content-end" onClick={() => placeABid(NFTDetails)}>
                          <button class="bid-btn" >PLACE A BID</button>
                        </div>
                      }

                    </div>

                    {NFTDetails.method !== "Fixed Price" && <Divider className="nft-detail divider" />}

                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12" style={{ padding: '20px' }}>
                      {
                        NFTDetails.method === "Auction" && stepNo !== 6 && (
                          <>
                            <p className="nft-detail expirationDate">{'Expiration Date & Time: '}</p>
                            <p className="nft-detail expirationTime"> {moment(
                              new Date(NFTDetails.expirationTimestamp)
                            ).format(environment.displayTimeFormat)}</p>
                          </>
                        )
                      }
                      {(NFTDetails.method === "Auction" ||
                        NFTDetails.method === "Open for Bids") && (
                          <>
                            <p className="nft-detail offerValidityTime">
                              Time in Days by which buyer has to do the final NFT
                              purchase after winning Bids or Auction:{" "}
                              <b>{NFTDetails.offerValidityTime || 3}</b> days
                            </p>
                          </>
                        )}
                    </div>
                  </div>


                  {/* Card 2 */}
                  <div className="d-flex nft-detail details-card2">

                    <div className="details-card1 col-xl-9 col-lg-9 col-md-12 col-sm-12 royalityParentDiv">
                      {NFTDetails?.userId === NFTDetails?.createdBy && (
                        <div className="nft-detail royalityDiv">
                          <p className="nft-detail current-price">{'Royality'}</p>
                          <p className="nft-detail expirationTime">  {`${NFTDetails.royalty}`.includes("%")
                            ? `${NFTDetails.royalty}`
                            : `${NFTDetails.royalty}%`}</p>
                        </div>
                      )}
                      <div className="nft-detail d-flex message-div" >
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                          <p className="nft-detail current-price">{'Copyright message'}</p>
                          <p className="nft-detail expirationTime">{NFTDetails?.file?.copyrightCheckedMessage}</p>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                          <p className="nft-detail current-price">{'Watermark message'}</p>
                          <p className="nft-detail expirationTime"> {NFTDetails?.file?.watermarkCheckedMessage}</p>
                        </div>
                      </div>
                    </div>

                    <div className="nft-detail details-card1 col-xl-3 col-lg-3 col-md-12 col-sm-12 profileDiv">
                      <p className="nft-detail category-text">{'Category'}</p>
                      <img
                        src={`../img/home/${NFTDetails.category}-Category.png`}
                        className="nft-detail photo-avatar"
                        alt="category-img"
                      />
                      <p className="nft-detail photo-text">{NFTDetails.category}</p>
                    </div>
                  </div>

                  <h5 className="nft-detail collection-text">Collection</h5>
                  <div>
                    <button
                      className="collection_btn mb-2 mt-2"
                      onClick={() =>
                        history.push(
                          `/collection/${NFTDetails?.collection?.collectionSelected?.collectionName}`,
                          "_self"
                        )
                      }
                      style={{ width: "190px", height: '140px' }}
                    >
                      <div className="collection_image_container">
                        <img
                          src={
                            NFTDetails?.collection?.collectionDocument
                              ?.FirebaseURL
                          }
                          alt="Col"
                          className="collection_image"
                          loading="lazy"
                        />
                      </div>
                      <div className="create_text_container">
                      <Tooltip title={NFTDetails?.collection?.collectionName}>
                        <span
                          className="nft-detail txt-collectionName"
                          style={{ wordWrap: "break-word" }}
                        >
                          {NFTDetails?.collection?.collectionName}{" "}
                        </span>
                        </Tooltip>
                        <span className="txt_ERC">
                          {NFTDetails?.collection?.tokenSymbol}
                        </span>
                      </div>
                    </button>
                  </div>

                  {/* {NFTDetails?.file?.watermark && !watermarkChecked && (userInfo?._id === NFTDetails?.userId) && (NFTDetails.adminVerification != 'pending' && NFTDetails.adminVerification != true) && (
                            <h5 className="mt-3">
                                <input
                                    type="checkbox"
                                    checked={watermarkChecked}
                                    onChange={() => setWatermarkChecked(!watermarkChecked)}
                                    id="copyright"
                                    name="copyright"
                                    style={{ marginRight: '10px' }}
                                />
                                Remove watermark
                            </h5>
                        )} */}

                  {(NFTDetails?.userId === userInfo?._id ||
                    NFTDetails?.creatorId === userInfo?._id) &&
                    NFTDetails?.minted === true &&
                    userInfo?._id && (
                      <p class="nft-detail owner-text">
                        NFT URL:{" "}
                        <a
                          href={`${NFTDetails?.file?.IPFSURL}`}
                          target="_blank"
                        >
                          LINK
                        </a>
                      </p>
                    )}

                  {(NFTDetails?.userId === userInfo?._id ||
                    NFTDetails?.creatorId === userInfo?._id) &&
                    NFTDetails?.minted === true &&
                    userInfo?._id && (
                      <p class="nft-detail owner-text">
                        NFT Blockchain Token URL:{" "}
                        <a
                          href={`${sharedService.etherscanURL[networkId] +
                            "/address/" +
                            NFTDetails?.collection?.NFTMintingContractAddress
                            }`}
                          target="_blank"
                        >
                          Token Link
                        </a>
                      </p>
                    )}


                  <h5 className="nft-detail owner-text" style={{ marginTop: '16px !important' }}>Owner</h5>
                  <div className="nft-detail item_author">
                    <div className="nft-detail author_list_pp">
                      <span>
                        <img
                          className="lazy"
                          src={
                            currentOwnerDetails?.profilePicUrl
                              ? currentOwnerDetails.profilePicUrl
                              : ProfilePic
                          }
                          alt=""
                        />
                        <i className="fa fa-check"></i>
                      </span>
                    </div>
                    <div className="author_list_info">
                      <span>
                        {currentOwnerDetails?.firstName +
                          " " +
                          currentOwnerDetails?.lastName}
                      </span>
                    </div>
                  </div>

                  <br />

                  {/* {stepNo === 6 && (
                    <>
                      <br />
                      <h6>Owner</h6>
                      <div className="item_author">
                        <div className="author_list_pp">
                          <span>
                            <img
                              className="lazy"
                              src={
                                NFTDetails.owner?.profilePicUrl
                                  ? NFTDetails.owner?.profilePicUrl
                                  : ProfilePic
                              }
                              alt=""
                            />
                            <i className="fa fa-check"></i>
                          </span>
                        </div>
                        <div className="author_list_info">
                          <span>
                            {NFTDetails.owner?.firstName +
                              " " +
                              NFTDetails.owner?.lastName}
                          </span>
                        </div>
                      </div>
                      <br />
                    </>
                  )} */}


                  <div className="de_tab">
                    <div className="spacer-10"></div>
                    {inputError && (
                      <p style={{ color: "red" }}>
                        Please fill out all the required selling information
                        fields
                      </p>
                    )}

                    {userInfo?._id === NFTDetails?.userId && (
                      <ul className="de_nav">
                        {NFTDetails?.adminVerification !== "pending" &&
                          NFTDetails.adminVerificationStatus !== "approved" &&
                          NFTDetails.adminVerificationStatus !== "rejected" ? (
                          <>
                            <li id="Mainbtn" className="active">
                              <span
                                onClick={() =>
                                  history.push(`/edit-nft/${nftId}`, "_self")
                                }
                              >
                                Edit
                              </span>
                            </li>

                            <li id="Mainbtn" className="active">
                              <span onClick={() => reviewNFT(nftId)}>
                                Place for Admin Verification
                              </span>
                            </li>
                          </>
                        ) : (
                          NFTDetails.adminVerification !== true &&
                          NFTDetails.adminVerificationStatus !== "approved" &&
                          NFTDetails.adminVerificationStatus !== "rejected" &&
                          NFTDetails.minted !== true && (
                            <div
                              style={{
                                display: "flex",
                                padding: "5px",
                                alignItems: "center",
                              }}
                            >
                              <img
                                src={greenTickMark}
                                alt=""
                                height="15px"
                                width="35px"
                              />
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <p style={{ margin: "2px 2px 0px 25px" }}>
                                  Placed for Admin Approval
                                </p>
                              </div>
                            </div>
                          )
                        )}

                        {NFTDetails.adminVerification === true &&
                          NFTDetails.adminVerificationStatus === "approved" &&
                          NFTDetails.minted !== true &&
                          !isMinted && !auctionExpired && (
                            <li id="Mainbtn" className="active">
                              <span onClick={signSellBuyAgreement}>Mint</span>
                            </li>
                          )}

                        {NFTDetails.adminVerification === true &&
                          NFTDetails.adminVerificationStatus === "approved" &&
                          NFTDetails.minted !== true &&
                          !isMinted && auctionExpired && (
                            <p>The Auction Time has Expired!</p>
                          )}

                        {NFTDetails.adminVerification === true &&
                          NFTDetails.adminVerificationStatus === "rejected" && (
                            <li id="Mainbtn" className="active">
                              <p style={{ margin: "5px 0", color: 'red', fontSize: '18px' }}>
                                Rejected
                              </p>
                            </li>
                          )}

                        {/* {isMinted && (
                                <div style={{ display: "flex", padding: "5px", alignItems: "center" }}>
                                   <img src={greenTickMark} alt="" height="15px" width="35px" />
                                   <div style={{ display: "flex", flexDirection: "column" }}>
                                       <p style={{ margin: "2px 2px 0px 25px" }}>Your NFT has been successfully Minted.</p>
                                   </div>
                                </div>
                            )} */}

                        {readyForSell && !auctionExpired && (
                          <li id="Mainbtn" className="active">
                            <span onClick={sellNFT}>Place For Sell</span>
                          </li>
                        )}
                        <br />
                        {readyForSell && auctionExpired && (
                          <h5 className="nft-detail auction-date">Aution Date & Time already expired. You can not place for sell now!</h5>
                        )}
                      </ul>
                    )}

                    {/* bidding details */}
                    {showBiddingDetails &&
                      NFTDetails.sellingStatus === "selling" &&
                      userInfo?.walletAddress?.toLowerCase() ===
                      selectedWallet?.toLowerCase() &&
                      NFTDetails.method !== "Fixed Price" && (
                        <>
                          <div
                            className="tab-1 onStep fadeIn"
                            style={{ marginLeft: "-35%" }}
                          >
                            <div className="biiding-details">
                              {biddingDetails?.length > 0 ? (
                                <>
                                  {biddingDetails.map((bid) => (
                                    <>
                                      {(NFTDetails.method !== "Auction" ||
                                        NFTDetails.biddingDone === true)  && NFTDetails.userId === userInfo?._id && (
                                          <button
                                            type="button"
                                            className="btn-main download-nft-button"
                                            onClick={() =>
                                              sellNFTToAnyBiddedUser(
                                                bid?.userId
                                              )
                                            }
                                            style={{
                                              backgroundColor: "darkgray",
                                              padding: "4px 10px",
                                              marginTop: "10px",
                                            }}
                                          >
                                            <span
                                              style={{
                                                fontSize: "14px",
                                                fontWeight: "400",
                                              }}
                                            >
                                              Request Sell NFT to{" "}
                                              {bid?.firstName +
                                                " " +
                                                bid?.lastName}
                                            </span>
                                          </button>
                                        )}
                                    </>
                                  ))}
                                </>
                              ) : null
                              }
                            </div>

                            {/* NFT Bidding or Auction */}
                            {NFTDetails.method === "Open for Bids" &&
                              biddingDetails?.length > 0 &&
                              maxBiddedUserId &&
                              maxBiddingAmount && NFTDetails.userId === userInfo?._id && (
                                <>
                                  <button
                                    type="button"
                                    className="btn-main download-nft-button nft-detail resend-notify-btn"
                                    style={{ backgroundColor: "blueviolet", marginTop: '20px' }}
                                    onClick={sellNFTToHighestBiddedUser}
                                  >
                                    <span>
                                      {biddingDone
                                        ? "Resend Notification to Bidding Winning User"
                                        : "Request Sell NFT to Highest Bid Buyer"}
                                    </span>
                                  </button>
                                </>
                              )}

                            {NFTDetails.method === "Auction" &&
                              biddingDetails?.length > 0 &&
                              maxBiddedUserId &&
                              maxBiddingAmount &&
                              biddingDone && NFTDetails.userId === userInfo?._id && (
                                <>
                                  <button
                                    type="button"
                                    className="btn-main download-nft-button"
                                    style={{ backgroundColor: "blueviolet" }}
                                    onClick={sellNFTToHighestBiddedUser}
                                  >
                                    <span>
                                      Resend Notification to Bidding Winning
                                      User
                                    </span>
                                  </button>
                                </>
                              )}

                            <br />
                            {(NFTDetails.method === "Open for Bids" || NFTDetails.method === "Auction") && biddingDone && NFTDetails.bidWonUserId && NFTDetails.sellingStatus === 'selling' && userInfo?._id === NFTDetails.owner && (
                              <h6>Last Place for Sell Request was sent to {getUserFullNameByUserId(NFTDetails.bidWonUserId)}.</h6>
                            )}
                            <br />

                            {NFTDetails.method === "Auction" &&
                              NFTDetails.enableChangeAuctionDate === true &&
                              !biddingDone && NFTDetails.userId === userInfo?._id && (
                                <div className="col-12">
                                  <Form
                                    form={form}
                                    onFinish={updateExpirationDate}
                                    style={{
                                      display: "inline-block",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Form.Item
                                      label="Enter Expiration date"
                                      rules={[
                                        {
                                          required: true,
                                          message:
                                            "Please Enter Expiration date",
                                        },
                                      ]}
                                    >
                                      <input
                                        type="date"
                                        name="bid_expiration_date"
                                        id="bid_expiration_date"
                                        className="form-control"
                                        defaultValue={expirationDate}
                                        value={expirationDate}
                                        onChange={(e) => {
                                          setExpirationDate(e.target.value);
                                        }}
                                        min={moment(new Date())
                                          .add(1, "days")
                                          .format("YYYY-MM-DD")}
                                        style={{
                                          marginBottom: 0,
                                          marginTop: "-5px",
                                        }}
                                      />
                                    </Form.Item>

                                    {expirationDate && (
                                      <div
                                        className="col-md-12"
                                        style={{ marginBottom: "20px" }}
                                      >
                                        <h6>
                                          Auction Expiration Time* in 24 Hours
                                          Format
                                        </h6>
                                        {expirationDate && (
                                          <DateTime
                                            setHours={setHours}
                                            setMinutes={setMinutes}
                                            minutes={minutes}
                                            hours={hours}
                                            expirationDate={expirationDate}
                                          />
                                        )}
                                      </div>
                                    )}

                                    {inputError2 && (
                                      <p style={{ color: "red" }}>
                                        Please give a valid Expiration Date and
                                        It should not be same again as previous
                                        expiration date and should be starting
                                        from tomorrow and Auction Expiration
                                        Time should be in Hours and Minutes!
                                      </p>
                                    )}

                                    <Form.Item
                                      className="btn-itm"
                                      style={{
                                        textAlign: "center",
                                        marginTop: "170px",
                                      }}
                                    >
                                      <Button
                                        type="primary"
                                        htmlType="submit"
                                        loading={loading}
                                        disabled={
                                          !expirationDate || !hours || !minutes
                                        }
                                      >
                                        Update Auction Expiration Date
                                      </Button>
                                    </Form.Item>
                                  </Form>
                                </div>
                              )}
                          </div>
                        </>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}

      {!loading && openEversign && !showResellingScreen && (
        <div hidden={!openEversign} id="embedSignDivision">
          {openEversign &&
            setTimeout(
              () =>
                eversign.open({
                  url: signURL,
                  containerID: "embedSignDivision",
                  width: 750,
                  height: 800,
                  events: {
                    loaded: () => {
                      console.log("loading successful");
                    },
                    signed: signNFTAgreementSigned,
                    error: () => console.log("EVERSIGN ERROR"),
                    declined: () => console.log("declined"),
                  },
                }),
              10
            )}
        </div>
      )}

      {!loading && NFTDetails && !openEversign && showResellingScreen && (
        <>
          <Card style={{ marginLeft: '100px' }} className="horizontal-middle-align">
            <Form labelAlign="left" form={form} onFinish={resellNFT} className="col-16">
              <div className="spacer-10"></div>
              <h5 className="nft-create nft-field-title">Pricing*</h5>
              <p className="nft-create nft-field-sub-title">Select type for NFt marketplace</p>

              <div className="nft-create field-set">
                <div className="nft-create de_tab tab_methods">
                  <ul className="nft-create de_nav text-left mx-0">
                    <li id="btn1" className="nft-create active" onClick={handleShow}>
                      <span>
                        <img src={Price} alt="price" className="nft-create pricing_img" />
                        <p className="nft-create pricing_img_text">Fixed Price</p>
                      </span>
                    </li>
                    <li id="btn2" onClick={handleShow1}>
                      <span>
                        <img src={Time} alt="time" className="nft-create pricing_img" />
                        <p className="nft-create pricing_img_text">Auction</p>
                      </span>
                    </li>
                    <li id="btn3" onClick={handleShow2}>
                      <span>
                        <img src={Bid} alt="bid" className="nft-create pricing_img" />
                        <p className="nft-create pricing_img_text">Bid</p>
                      </span>
                    </li>
                  </ul>

                  <div className="de_tab_content pt-3">
                    <div id="tab_opt_1">
                      <div className="d-flex" style={{ width: '150%' }}>
                        <div className="nft-create price-in-dollar">
                          <h5 className="nft-create nft-field-title">Price (USD)*</h5>
                          <input
                            type="text"
                            name="item_price"
                            min="0"
                            id="item_price"
                            className="nft-create form-control"
                            placeholder="Enter price for one item (USD)"
                            value={!isNaN(usdPrice) ? usdPrice : ''}
                            onChange={(e) => {
                              setUsdPrice(e.target.value);
                              const convertedAmount = (parseFloat(e.target.value) / todayEtheriumPriceInUSD).toFixed(8);
                              setPrice(convertedAmount);
                            }}
                          />
                        </div>
                        {(usdPrice && method === "Fixed Price") ? <div className="nft-create price-in-eth">
                          <h5 className="nft-create nft-field-title">Price (ETH)*</h5>
                          <input
                            type="text"
                            name="item_price"
                            min="0"
                            id="item_price"
                            className="nft-create form-control"
                            disabled
                            placeholder="Enter price for one item (ETH)"
                            value={price}
                          // onChange={(e) => {
                          //   setPrice(e.target.value);
                          // }}
                          />
                        </div> : null}
                      </div>
                    </div>

                    <div id="tab_opt_2" className="hide">
                      <div className="d-flex">
                        <div className="nft-create price-in-dollar">
                          <h5 className="nft-create nft-field-title">Minimum BID (USD)*</h5>
                          <input
                            type="text"
                            name="item_price_bid"
                            id="item_price_bid"
                            className="nft-create form-control"
                            placeholder="Enter minimum bid (USD)"
                            value={!isNaN(usdBid) ? usdBid : ''}
                            onChange={(e) => {
                              setUsdBid(e.target.value);
                              const convertedAmount = (parseFloat(e.target.value) / todayEtheriumPriceInUSD).toFixed(8);
                              setBid(convertedAmount);
                            }}
                          />
                        </div>

                        {(usdBid && method === 'Auction') ? <div className="nft-create price-in-eth">
                          <h5 className="nft-create nft-field-title">Minimum BID (ETH)*</h5>
                          <input
                            type="text"
                            name="item_price_bid"
                            id="item_price_bid"
                            className="nft-create form-control"
                            placeholder="Enter minimum bid (ETH)"
                            disabled
                            value={bid}
                          // onChange={(e) => {
                          //   setBid(e.target.value);
                          // }}
                          />
                        </div> : null
                        }

                      </div>


                      <div className="spacer-20"></div>
                      <div className="row">
                        <div className="col-md-6">
                          <h5 className="nft-create nft-field-title">Start Date*</h5>
                          <input
                            type="date"
                            name="bid_starting_date"
                            id="bid_starting_date"
                            className="nft-create form-control"
                            min={moment(new Date()).format("YYYY-MM-DD")}
                            value={startingDate}
                            onChange={(e) => {
                              setStartingDate(e.target.value);
                            }}
                          />
                        </div>
                        <div className="col-md-6">
                          <h5 className="nft-create nft-field-title">Expiry Date*</h5>
                          <input
                            type="date"
                            name="bid_expiration_date"
                            id="bid_expiration_date"
                            className="nft-create form-control"
                            value={formExpirationDate}
                            onChange={(e) => {
                              setFormExpirationDate(e.target.value);
                            }}
                            min={moment(
                              new Date(startingDate?.replaceAll("-", "/"))
                            )
                              .add(1, "days")
                              .format("YYYY-MM-DD")}
                            disabled={!startingDate}
                          />
                        </div>

                        {method === "Auction" && dateTimeError && (
                          <p className="nft-create error-msg">
                            Please Input Auction Expiration Time in Hours and
                            Minutes!
                          </p>
                        )}

                        {method === "Auction" && (
                          <div
                            className="col-md-12"
                            style={{ marginBottom: "20px" }}
                          >
                            <h6 className="nft-create nft-field-title">Auction Expiration Time* in 24 Hours Format(in
                              your Timezone)</h6>
                            {formExpirationDate && (
                              <DateTime
                                setHours={setHours}
                                setMinutes={setMinutes}
                                minutes={minutes}
                                hours={hours}
                                expirationDate={formExpirationDate}
                              />
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              {(method === "Open for Bids" || method === "Auction") &&
                offerValidityTimeArray?.length > 0 && (
                  <>
                    {/* <h6>
                      Specify the Time in Days by which buyer has to do the
                      final NFT puchase after winning Bids or Auction*
                    </h6> */}
                    <h5 className="nft-create nft-field-title">Pick a time*</h5>
                    <Select
                      placeholder="Pick Time in Days"
                      className="nft-create form-control-dropdown"
                      optionFilterProp="children"
                      onChange={(val) => setOfferValidityTime(val)}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      defaultValue={offerValidityTime}
                      options={[...offerValidityTimeArray]}
                    />
                    <h6 className="nft-create nft-field-sub-title"> Specify the Time in Days by which buyer has to do the
                      final NFT puchase after winning Bids or Auction*</h6>
                  </>
                )}

              <br />

              {minPriceError && (<h6 style={{ color: 'orangered' }}>You can not list your NFT with minimum price less than or equals to {(generalListingCommission + generalSellingCommission)} ether</h6>)}

              <div className="d-flex">
                <div>
                  <Button
                    size="large"
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    style={{ marginTop: '16px' }}
                    className="nft-create submit_btn"
                    disabled={
                      (method === "Fixed Price" && (!price || !usdPrice)) ||
                      (method === "Open for Bids" && !offerValidityTime) ||
                      (method === "Auction" &&
                        (!bid ||
                          !usdBid ||
                          !startingDate ||
                          !formExpirationDate ||
                          !hours ||
                          !minutes ||
                          !offerValidityTime))
                    }
                  >
                    Resell
                  </Button>
                </div>
                <div>
                  <Button
                    size="small"
                    type="secondary"
                    className="nft-create cancel_btn"
                    onClick={() => setShowResellingScreen(false)}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
              <br />
            </Form>
          </Card>
        </>
      )}
    </div>
  );
};
export default ItemDetail;