import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Spin, notification, Input, Button, Popconfirm } from "antd";
import Clock from "../components/Clock";
import { createGlobalStyle } from "styled-components";
import authContext from "../components/Shared/Auth.service";
import { NFTCreatorService } from "../components/services/NFTCreator/NFTCreator.service";
import { VideoPlayer } from "../components/mainSlider/VideoPlayer";
import ProfilePic from "./../assets/images/home/profile-default.png";
import { SharedService } from "../components/Shared/Shared.service";
import "./styles/styles.scss";
import NFTCard from "../components/NFTCardComponent";
import { MetamaskService } from "../components/Shared/Metamask.service";
import './styles/allNFT.scss';
import { SearchOutlined } from "@ant-design/icons";

import { Select, Space } from 'antd';

const nftService = new NFTCreatorService();
const sharedService = new SharedService();

const useSelectedWalletContext = () =>
  new MetamaskService().useSelectedWalletContext();

const AllNFTPage = function () {
  const { userInfo, setUserInfo } = useContext(authContext);

  const [allNFT, setAllNFT] = useState(null);
  const [filteredNFT, setFilteredNFT] = useState(null);
  const [loading, setLoading] = useState(true);
  const [todayEtheriumPriceInUSD, setTodayEtheriumPriceInUSD] = useState();
  const { networkId } = useSelectedWalletContext();
  const [searchTerm, setSearchTerm] = useState("");
  const [typeFilter, setTypeFilter] = useState("Type");
  const [categoryFilter, setCategoryFilter] = useState("Category");
  const [priceFilter, setPriceFilter] = useState("Price");

  const history = useHistory();

  useEffect(() => {
    if (!networkId) return;
    setTimeout(async () => {
      setLoading(true);
      let res = await nftService.getAvailableSellingNFT({ networkId });
      if (res.data) {
        setAllNFT(res.data);
        setFilteredNFT(res.data);
      }

      setLoading(false);
      setTodayEtheriumPriceInUSD(await sharedService.getEtheriumPrice());
    }, 300);
  }, [userInfo, networkId]);

  useEffect(() => {
    if (allNFT?.length) {
      applyFilters();
    }
  }, [typeFilter, categoryFilter, priceFilter, allNFT, searchTerm]);

  const applyFilters = () => {
    let filtered = [...allNFT];

    if (typeFilter !== "Type") {
      filtered = filtered.filter(nft => nft.method === typeFilter);
    }

    if (categoryFilter !== "Category") {
      filtered = filtered.filter(nft => nft.category === categoryFilter);
    }
    // Add additional filtering based on priceFilter if needed

    if (searchTerm) {
      filtered = filtered.filter(nft => {
        const titleMatch = nft.title.toLowerCase().includes(searchTerm.toLowerCase());
        return titleMatch;
      });
    }

    setFilteredNFT(filtered);
  };

  const handleSearchInputChange = (value) => {
    setSearchTerm(value);
  };

  const handleChange = (value, filterType) => {
    switch (filterType) {
      case "Type":
        setTypeFilter(value);
        break;
      case "Category":
        setCategoryFilter(value);
        break;
      case "Price":
        setPriceFilter(value);
        break;
      default:
        break;
    }
  };

  return (
    <div>
      {loading && (
        <div className='loading-spinner' >
          <Spin size="large" />
        </div>
      )}
      {!loading && (
        <div className="row">
          {/* Banner section */}
          <div className="banner-big" style={{
            backgroundImage: "url('/img/banner/all_NFT.png')",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover"
          }}>

          </div>


          {/* Filter section */}
          <div className="row col-12 start-section">
            <div className="m-4 new-nft-title" style={{ marginLeft: "32px !important", fontFamily: "poppins", fontWeight: "700", fontSize: "36px", color: "#050B37" }}>ALL NFTs</div>
            <div className="d-flex justify-content-between new-nft-title">
              <div className="d-flex" style={{ width: '100%' }}>
                <div className="search-container">
                  <div className="search_icon">
                    <SearchOutlined />
                  </div>
                  <Input
                    placeholder="Search here..."
                    value={searchTerm}
                    className="all-nft-search-bar"
                    onChange={(e) => handleSearchInputChange(e.target.value)}
                    prefix={<SearchOutlined />}
                  />
                </div>
                <div className="d-flex" style={{ marginLeft: 'auto', marginRight: '60px' }}>
                  <div className="m-3">
                    <Space wrap />
                    <Select
                      defaultValue="Type"
                      style={{ width: 120 }}
                      className="all-nft-filter-bar"
                      onChange={value => handleChange(value, "Type")}
                      options={[
                        { value: 'Type', label: 'All Method' },
                        { value: 'Fixed Price', label: 'Fixed Price' },
                        { value: 'Auction', label: 'Auction' },
                        { value: 'Bid', label: 'Open for Bids' },
                      ]}
                    />
                  </div>
                  <div className="m-3">
                    <Space wrap />
                    <Select
                      defaultValue="Category"
                      style={{ width: 120 }}
                      className="all-nft-filter-bar"
                      onChange={value => handleChange(value, "Category")}
                      options={[
                        { value: 'Category', label: 'Category' },
                        { value: 'Photo', label: 'Photo' },
                        { value: 'Avatar', label: 'Avatar' },
                        { value: 'Sports', label: 'Sports' },
                        { value: 'Memes', label: 'Memes' },
                        { value: 'Video', label: 'Video' },
                      ]}
                    />

                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* NFT grid section */}
          <div className="d-flex flex-wrap" style={{ marginLeft: '20px' }}>
            {filteredNFT?.map((nft, index) => (
              <div className="itm col-sm-12 col-md-4 col-lg-3 col-xl-3" key={index}>
                <div className={`nft__item nft-item-buy`} style={{ margin: "3px", padding: "3px", border: "none", backgroundColor: "transparent" }}>
                  <NFTCard isCreator={true} nft={nft} todayEtheriumPriceInUSD={todayEtheriumPriceInUSD} buy={true} />
                </div>
              </div>
            ))}
          </div>

          {/* No NFT found message */}
          {!loading &&
            (!filteredNFT || filteredNFT?.length < 1) && (
              <div className="row">
                <h5 style={{ textAlign: "center" }}>
                  No NFT found on this page.
                </h5>
              </div>
            )}
        </div>
      )}
    </div>
  );
};
export default AllNFTPage;
