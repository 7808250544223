import React from 'react';

import { environment } from '../environments/environment';
import { Button, Tooltip } from 'antd';
import './styles/footer.scss';

const FooterNew = () => (
  <div>

    <footer className={`footer-light ${environment.envName}`}>
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-sm-6 col-xs-1">
            <div className="widget">
              <h5 className="home-page about-us-text">About Us</h5>
              <p className='w-75 home-page footer-text'>
                {environment.envName === 'qart' ? (<>
                  Q-Art is a sector of Querubin Global Foundation, Inc. (QGF) which is the philanthropic entity of the Q-Group of companies. QGF is a 501c3, international non-profit, nongovernmental organization which came to live to serve a bridge between sustainable development and civil society working together with the private sector, local government, and the community.
                  </>) : (<>
                  The forefront of digital ownership in our revolutionary NFT Marketplace, the Global Hub for crypto collectibles and non-fungible tokens (NFTs). Clutch the future as you buy, sell, and uncover a world of exclusive digital treasures.</>)}
              </p>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 col-xs-1">
            <div className="widget">
              <h5 className="home-page market-place-text">Marketplace</h5>
              <ul className='home-page li'>
                {/* <li><Link to="/all-nft">All NFTs</Link></li>
                <li><Link to="/new-nft">New NFTs</Link></li> */}
                <li>
                <Tooltip>
                  <a href="/about-us">About Us</a>
                  </Tooltip>
                  </li>
                
                <li>
                  <Tooltip >
                    
                    <a href="/all-nft">All NFT</a>
                  </Tooltip>
                </li>
                <li>
                  <Tooltip >
                  <a href="/new-nft">New NFT</a>
                  </Tooltip>
                </li>

                <li>
                <Tooltip>
                  <a href="/features">Features</a>
                  </Tooltip>
                  </li>
                <li>
                <Tooltip >
                  <a href="/careers">Careers</a>
                  </Tooltip>
                  </li>
              </ul>

            </div>
          </div>
          <div className="col-md-3 col-sm-6 col-xs-1">
            <div className="widget">
              <h5 className='home-page resources-text'>Resources</h5>
              <ul className='home-page li'>
                <li><a href="/blogs">Blogs</a></li>
                <li><a href="/learns">Learn</a></li>
                <li><a href="/help-center">Help Center</a></li>
                <li><a href="/community-standards">Community Standards</a></li>
                <li><a href="/faq">FAQ</a></li>
              </ul>
            </div>
          </div>
          {/* <div className="col-md-3 col-sm-6 col-xs-1">
                        <div className="widget">
                            <h5>Newsletter</h5>
                            <p>Signup for our newsletter to get the latest news in your inbox.</p>
                            <form action="#" className="row form-dark" id="form_subscribe" method="post" name="form_subscribe">
                                <div className="col text-center">
                                    <input className="form-control" id="txt_subscribe" name="txt_subscribe" placeholder="enter your email" type="text" /> 
                                    <Link to="" id="btn-subscribe">
                                      <i className="arrow_right bg-color-secondary"></i>
                                    </Link>
                                    <div className="clearfix"></div>
                                </div>
                            </form>
                            <div className="spacer-10"></div>
                            <small>Your email is safe with us. We don't spam.</small>
                        </div>
                    </div> */}
        </div>
      </div>
      <div className="subfooter">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="de-flex">
                <div className="de-flex-col">
                <div className="social-icons">
    <span className="social-icon" onClick={() => window.open("https://www.facebook.com", "_blank")}><i className="fa fa-facebook fa-lg"></i></span>
    <span className="social-icon" onClick={() => window.open("https://x.com/pixentro", "_blank")}><i className="fa fa-twitter fa-lg"></i></span>
    <span className="social-icon" onClick={() => window.open("https://www.linkedin.com", "_blank")}><i className="fa fa-linkedin fa-lg"></i></span>
    <span className="social-icon" onClick={() => window.open("https://www.pinterest.com", "_blank")}><i className="fa fa-pinterest fa-lg"></i></span>
    <span className="social-icon" onClick={() => window.open("https://www.tumblr.com", "_blank")}><i className="fa fa-tumblr fa-lg"></i></span>
    <span className="social-icon" onClick={() => window.open("https://www.instagram.com/pixentro/?next=%2F", "_blank")}><i className="fa fa-instagram fa-lg"></i></span>
    <span className="social-icon" onClick={() => window.open("https://telegram.org", "_blank")}><i className="fa fa-telegram fa-lg"></i></span>
    <span className="social-icon discord-icon-footer" onClick={() => window.open("https://discord.com/invite/aANKh2GM", "_blank")}>
        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512">
            <path fill="white" d="M524.531,69.836a1.5,1.5,0,0,0-.764-.7A485.065,485.065,0,0,0,404.081,32.03a1.816,1.816,0,0,0-1.923.91,337.461,337.461,0,0,0-14.9,30.6,447.848,447.848,0,0,0-134.426,0,309.541,309.541,0,0,0-15.135-30.6,1.89,1.89,0,0,0-1.924-.91A483.689,483.689,0,0,0,116.085,69.137a1.712,1.712,0,0,0-.788.676C39.068,183.651,18.186,294.69,28.43,404.354a2.016,2.016,0,0,0,.765,1.375A487.666,487.666,0,0,0,176.02,479.918a1.9,1.9,0,0,0,2.063-.676A348.2,348.2,0,0,0,208.12,430.4a1.86,1.86,0,0,0-1.019-2.588,321.173,321.173,0,0,1-45.868-21.853,1.885,1.885,0,0,1-.185-3.126c3.082-2.309,6.166-4.711,9.109-7.137a1.819,1.819,0,0,1,1.9-.256c96.229,43.917,200.41,43.917,295.5,0a1.812,1.812,0,0,1,1.924.233c2.944,2.426,6.027,4.851,9.132,7.16a1.884,1.884,0,0,1-.162,3.126,301.407,301.407,0,0,1-45.89,21.83,1.875,1.875,0,0,0-1,2.611,391.055,391.055,0,0,0,30.014,48.815,1.864,1.864,0,0,0,2.063.7A486.048,486.048,0,0,0,610.7,405.729a1.882,1.882,0,0,0,.765-1.352C623.729,277.594,590.933,167.465,524.531,69.836ZM222.491,337.58c-28.972,0-52.844-26.587-52.844-59.239S193.056,219.1,222.491,219.1c29.665,0,53.306,26.82,52.843,59.239C275.334,310.993,251.924,337.58,222.491,337.58Zm195.38,0c-28.971,0-52.843-26.587-52.843-59.239S388.437,219.1,417.871,219.1c29.667,0,53.307,26.82,52.844,59.239C470.715,310.993,447.538,337.58,417.871,337.58Z"/>
        </svg>
    </span>  
</div>

                </div>
                <div className="de-flex-col">
                  <span>
                    <span className="copy">&copy; Copyright 2024 - <span className='yellow_dark'>{environment.companyName}</span></span>
                  </span>
                </div>
                <div className="de-flex-col">
                  <ul className='d-flex align-items-center privacy'>
                  {environment.envName === 'qart' && (<>
                    <li><a href="/company-content">Querubin Art</a></li> 
                    <span className='me-3 ms-3'>|</span>
                  </>)}
                    <li><a href="/privacy-policy">Privacy Policy</a></li> 
                    <span className='me-3 ms-3'>|</span>
                    {environment.envName === 'qart' && (<li><a href="/terms-of-use">Terms of Use & Disclaimer</a></li>)}

                    {environment.envName !== 'qart' && (<>
                      <li><a href="/terms-of-use">Terms of Use</a></li>
                      <span className='me-3 ms-3'>|</span>
                      <li><a href="/disclaimer">Disclaimer</a></li>
                      </>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>

  </div>
);
export default FooterNew;