import { Button, Divider, Modal, Pagination, Select, notification } from 'antd';
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { AdminService } from '../../../../components/services/AdminService/Admin.service';
import { MasterWhiteListingService } from '../../../../components/Shared/Blockchain/MasterWhitelisting/MasterWhiteListing.service';
import authContext from '../../../../components/Shared/Auth.service';
import { MetamaskService } from '../../../../components/Shared/Metamask.service';
import { useHistory } from 'react-router-dom';

const adminService = new AdminService();
const masterWhiteListingService = new MasterWhiteListingService();
const useSelectedWalletContext = () =>
  new MetamaskService().useSelectedWalletContext();

function WhitelistWallets(props) {
  const { setLoading, smartContractData, loading } = props || {};
  const { userInfo } = useContext(authContext);
  const { networkId } = useSelectedWalletContext();
  const history = useHistory();
  const [goToPage, setGoToPage] = useState(1);
  const [pendingWallets, setPendingWallets] = useState([]);
  const [approvedWallets, setApprovedWallets] = useState([]);
  const [sortByVal, setSortByVal] = useState("Pending");
  const [whitelistRejectionMessage, setWhitelistRejectionMessage] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [walletDetails, setWalletDetails] = useState({});
  const rejectedWallets = useMemo(() => {
    return approvedWallets?.filter((user) => user?.status.walletWhitelisted?.[networkId] !==
      "approved")
  }, [approvedWallets, networkId]) || [];
  const filteredApprovedWallets = useMemo(() => {
    return approvedWallets?.filter((user) => user?.status.walletWhitelisted?.[networkId] ===
      "approved")
  }, [approvedWallets, networkId]) || [];
  const filteredWallets = sortByVal === "Approved" ? filteredApprovedWallets : sortByVal === "Pending" ? pendingWallets : rejectedWallets;
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 12;

  const totalPages = Math.ceil(filteredWallets?.length / pageSize);
  const [isReject, setIsReject] = useState(false);

  const onPageChange = (page) => {
    setCurrentPage(page);
    setGoToPage(page);
  };

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const currentPageData = filteredWallets.slice(startIndex, endIndex);

  useEffect(() => {
    fetchData(userInfo, networkId)
  }, [userInfo, networkId])

  const handleOpenModal = (data) => {
    setOpenModal(true);
    setWalletDetails(data)
  }

  const fetchData = async () => {
    setLoading(true);
    try {
      let res1 = await adminService.getPendingWalletComplianceAdmin({ networkId });
      if (res1.data?.length > 0) {
        setPendingWallets(res1.data.reverse());
      } else {
        setPendingWallets([])
      }

      let res2 = await adminService.getCompletedWalletComplianceAdmin({ networkId });
      if (res2.data?.length > 0) {
        setApprovedWallets(res2.data.reverse());
      } else {
        setApprovedWallets([])
      }
    } catch (error) {
      console.log(error);
      notification.open({
        message: 'Error',
        description: 'something went wrong while fetching WhiteList Wallet Data',
        duration: 0,
      });
    }
    setLoading(false);
  };

  // Approve Wallet - Only for Approving/whitelisting NFT user's wallet address
  const approveWallet = async (userId, walletAddress, status, isWhitelistingFeepaid) => {
    if (!isWhitelistingFeepaid) return notification
      .open({
        message: `NFTCreator has not yet paid his Wallet-WhiteListing Fee`,
        duration: 0,
      });

    // console.log(
    // String(smartContractData?.masterWhiteListingContract))

    if (!smartContractData?.masterWhiteListingContract) return notification.open({
      message: `MasterWhiteListing Contract Address not found`,
      duration: 0,
    });

    try {
      setLoading(true);
      const txnReceipt = await masterWhiteListingService.whitelistNftUserByComplianceAdmin(
        String(walletAddress),
        isWhitelistingFeepaid,
        String(userInfo?.walletAddress),
        String(smartContractData?.masterWhiteListingContract)
      );

      console.log("Blockchain Transaction receipt: ", txnReceipt);
      if (txnReceipt) {
        let form = {
          userId,
          walletAddress,
          status,
          MPComplianceAdminWallet: userInfo?.walletAddress,
          NFTUserWalletAddress: walletAddress,
          transactionDetail: txnReceipt,
          networkId
        }

        let res = await adminService.approveNftUserWalletComplianceAdmin(form);
        if (res.success) {
          setLoading(false);
          notification.open({
            message: `Successfully approved NFTUser: ${walletAddress} by ComplianceAdmin`,
            duration: 0,
          });
          fetchData();
        }
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      notification.open({
        message: 'Error',
        description: error.message,
        duration: 0,
      });
    }
  }

  const handleChange = (value) => {
    setSortByVal(value);
    setCurrentPage(1)
  }

  const handleReject = () => {
    setOpenModal(false);
    approveWallet(
      walletDetails?._id,
      walletDetails?.walletAddress,
      "rejected",
      whitelistRejectionMessage
    )
  }

  const handleApprove = () => {
    setOpenModal(false);
    approveWallet(
      walletDetails?._id,
      walletDetails?.walletAddress,
      "approved",
      walletDetails?.status?.whiteListingFeesPaid?.[networkId]
    )
  }

  const handleGoToPage = () => {
    const pageNumber = parseInt(goToPage);
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  return (
    !loading && <>
      <p className='market-super-admin commission-configuration-text'>Whitelist Wallets</p>
      <div className='market-super-admin admin-invites card-container d-flex justify-content-center flex-column'>
        <div className='market-super-admin card-inner-container' style={{ width: '100%', padding: '20px' }}>
          <div className='d-flex col-12'>
            <div className='d-flex justify-content-center align-items-center'>
              {sortByVal === "Approved" && <img className="market-place-admin status-icon" src={"/img/admin-dashboard/approved.png"} alt="nftArt" />}
              {sortByVal === "Rejected" && <img className="market-place-admin status-icon" src={"/img/admin-dashboard/rejected.png"} alt="nftArt" />}
              {sortByVal === "Pending" && <img className="market-place-admin status-icon" src={"/img/admin-dashboard/pending.png"} alt="nftArt" />}
              <p className='wallet-status-text'>{sortByVal}</p>
            </div>
            <div className='d-flex' style={{ marginLeft: 'auto' }}>
              <div className='sort-by-text'>
              <img
                  className="market-place-admin status-icon"
                  src={"/img/admin-dashboard/sort-icon.png"}
                  alt="nftArt"
                />Sort By:</div>
              <Select
                className='sort-by-select'
                defaultValue={sortByVal}
                onChange={handleChange}
                options={[
                  {
                    value: 'Pending',
                    label: 'Pending',
                  },
                  {
                    value: 'Approved',
                    label: 'Approved',
                  },
                  {
                    value: 'Rejected',
                    label: 'Rejected',
                  },
                ]}
              />
            </div>
          </div>
          <Divider />
          {currentPageData?.length > 0 ?
            currentPageData.map((user) => (
              <div className='market-super-admin wallet-card-component col-lg-3 col-md-3 col-sm-6 col-xs-12'>
                <div className='d-flex flex-column'>
                  {/* <p className='wallet-nft-user-text'>nft-user</p> */}
                  <img
                    className="wallet-kycDetails-selfie"
                    src={user?.kycDetails?.selfie}
                    alt=""
                  />
                  <div className="act_list_text">
                    <h4 className="wallet-fullname-text">
                      {user?.firstName + " " + user?.lastName}
                    </h4>
                    <span className="wallet-email-text">
                      {user?.email}
                    </span>
                    <span className="wallet-contactNumber-text">
                      {user?.kycDetails?.contactNumber}
                    </span>
                    <span className="wallet-walletAddress-text">
                      <b>{user?.walletAddress}</b>
                    </span>

                    {
                      sortByVal !== "Pending" ?
                        user?.status.walletWhitelisted?.[networkId] ===
                          "approved"
                          ? <Button style={{fontWeight:"bolder"}} className="wallet-status-approved-chip"><img className="market-place-admin sidenav-icon" src={"/img/admin-dashboard/approved.png"} alt="nftArt" />
                            <p>Approved</p></Button>
                          : <Button style={{fontWeight:"bolder"}} className="wallet-status-rejected-chip"><img className="market-place-admin sidenav-icon" src={"/img/admin-dashboard/rejected.png"} alt="nftArt" />
                            <p>Rejected</p></Button>
                        : null
                    }

                    {
                      sortByVal === "Pending" && <Button style={{fontWeight:"bolder"}} className="wallet-status-pending-chip" onClick={() => handleOpenModal(user)}><img className="market-place-admin sidenav-icon" src={"/img/admin-dashboard/pending.png"} alt="nftArt" />
                        <p>Pending</p></Button>
                    }

                    {
                      user?.kycDetails?._id &&
                      <p className='kyc-approvals view-more-text' onClick={() => {
                        history.push(
                          `/marketplace-admin/kyc/${user?.kycDetails?._id}`
                        );
                      }}>View More</p>
                    }
                  </div>
                </div>
              </div>
            ))
            : <div className='no-data-found-div'>
              <p className='no-data-found-text'>No Data Found</p>
            </div>
          }

          <Modal
            title=""
            centered
            open={openModal}
            onOk={() => {
              setOpenModal(false);
              setIsReject(false);
            }}
            onCancel={() => {
              setOpenModal(false);
              setIsReject(false);
            }}
            maskClosable={true}
            className='wallet-modal'
            footer={[
              !isReject && (
                <div style={{ marginBottom: '20pxs' }}>
                  <Button className='wallet-modal-approve-button' onClick={handleApprove}>
                    Approve
                  </Button>
                  <Button className='wallet-modal-reject-button' type="primary" onClick={() => {
                    setIsReject(true)
                  }}>
                    Reject
                  </Button>
                </div>),
              isReject && (
                <>
                  <textarea
                    required
                    className='wallet-modal-textarea'
                    value={whitelistRejectionMessage}
                    onChange={(e) =>
                      setWhitelistRejectionMessage(
                        e.target.value
                      )
                    }
                    placeholder="Type here to rejection note ..."
                    style={{ width: "100%", height: "60px" }}
                  />
                  <Button className='wallet-modal-reject-button' type="primary" onClick={handleReject} style={{ marginTop: '20px' }}>
                    Reject
                  </Button>
                </>
              )
            ]}
          >
            <span className='wallet-modal-heading-text'>Are you need to approve this</span>
            <span className='wallet-modal-heading-sub-text'>Whitelist</span>
            <p className='wallet-modal-heading-sub-text'>Wallet ?</p>
          </Modal>

        </div>

        {
          filteredWallets?.length ? <div className='paginationContainer'>
            <Pagination
              current={currentPage}
              total={filteredWallets?.length}
              pageSize={pageSize}
              onChange={onPageChange}
            />
            <input
              type="number"
              value={goToPage}
              onChange={(e) => setGoToPage(e.target.value)}
              min="1"
              max={totalPages}
              className='goInput'
            />
            <button
              onClick={handleGoToPage}
              className='goButton'
            >
              Go
            </button>
          </div>
            : null
        }

      </div>
    </>
  )
}

export default WhitelistWallets
