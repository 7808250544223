import React from 'react';
import {Link} from 'react-router-dom';

const PrivacyPolicyQArt = () => {
    return (
      <div className='container privacy-policy'>
        <h4>PRIVACY POLICY</h4>
        <p>
            This Privacy Statement describes the types of personal information Q-Art NFT collects through our website <a href="https://www.querubinart.com">www.querubinart.com</a> (The “Q-Art’s website), and how we use, share and protect that information. This Privacy Statement does not govern the collection of information through any website, or any other means, other than through the Q-Art NFT website.
        </p>
        <ul class="list-style">
            <li>We do not collect personal information through the Q-Art’s website unless you voluntarily provide such information to us. Personal information such as name, address, phone number and email address are collected through the Q-Art website from visitors who submit a contact or request form, register for events, or request that we provide a link to the website to a third party. We use personal information to respond to your inquiries, register you for events, or any other purposes for which you provide such information.</li>
            <li>Our web server automatically recognizes and records certain information about visitors to the Q-Art website. This includes date and time of access, IP address, web browser type and version, and address of the website from which you entered the Q-Art website. The web server does not record e-mail addresses. We use this information only to optimize and improve the content of the Q-Art’s website.</li>
            <li>We use temporary, session-specific cookies to track Q-Art website traffic. Cookies do not reveal or contain any identifying or personal data, cannot read any information on your computer, and cannot interact with other cookies on your hard drive. Cookies stay on the user’s hard drive to identify site visits and whether the user is a returning visitor.</li>
            <li>We do not sell, rent, or otherwise share personal information about visitors of the Q-Art’s website, except as described herein. We may share non-personal information with third parties who provide us statistical information about the use of the Q-Art’s website.</li>
            <li>We undertake reasonable security measures to protect the information collected through the Q-Art’s website. While we place a priority on the security of personally identifiable information, we cannot guarantee the security of any information you transmit to us through Q-Art’s website.</li>
            <li>The Q-Art’s website may contain links to external websites for your convenience since Q-Art is part of Querubin Global Foundation, Inc. which in turn is part of the Q-Qroup of companies. We do endorse these websites, our mother NGO and the related social responsible companies.</li>
        </ul>
        <br/>
        <p>We may change this Privacy Statement at any time without prior notice to you. We will post a notice on the home page of the Q-Art’s website to notify you of any significant changes to this Privacy Notice Statement.</p>
      </div>
    )
  }
  
  export default PrivacyPolicyQArt