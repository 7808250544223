import React from 'react'
import { environment } from '../environments/environment'
import TermsOfUsePixentro from '../dynamicContent/TermsOfUse.pixentro'
import TermsOfUseQArt from '../dynamicContent/TermsOfUse.qart'

const TermsOfUse = () => {
  return (
    <>
    {environment.envName === 'qart' && <TermsOfUseQArt />}
    {environment.envName === 'pixentro' && <TermsOfUsePixentro />}
  </>
  )
}

export default TermsOfUse