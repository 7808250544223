import React from "react";
import { useHistory } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export default function HomePageCategoriesSection({ nftCategories = [] }) {
  const history = useHistory();

  return <>
    <section className="home-page browse-by-category third_section">
      <div className="home-page browse-by-category container">
        <div className="row">
          <div className="col-lg-12 text-center mt-4">
            <h2 className="home-page browse-by-category-text">Browse by Category</h2>
            <p className="home-page browse-by-category-sub-text">The NFTs provided cover digital art, collectibles, GameFi, the metaverse, and more.</p>

          </div>
          {nftCategories?.length > 0 &&
            nftCategories.map((ctg, ind) => (
              <div key={`cat-${ind}`} className="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-4 d-flex justify-content-center align-items-center">
                <div
                  className="home-page browse-category-card card"
                  onClick={() =>
                    history.push(`/category-detail/${ctg.name}`)
                  }
                  style={{ cursor: "pointer" }}
                >
                  <LazyLoadImage
                    src={`./img/home/${ctg.name}-Category.png`}
                    className="home-page browse-category-card-img card-img-top"
                    alt="category-img"
                    effect="blur"
                    style={{ height: "220px" }}
                  />
                  <div className="home-page browse-category-card-body card-body">
                    <h5 className="home-page browse-category-card-title text-center">{ctg.name}</h5>
                  </div>
                </div>
              </div>
            ))}
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-4 d-flex justify-content-center align-items-center">
            <div
              className="home-page browse-category-card card"
              style={{ cursor: "pointer" }}
            >
              <div className="home-page browse-category-card-body card-body">
                <h5 className="home-page browse-category-more-coming-soon">{"More Coming Soon"}</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
}