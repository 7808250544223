import React, {useState, useEffect, useContext} from 'react'
import {
    Button,
    Card,
    message,
  } from "antd";
import { SharedService } from './../components/Shared/Shared.service';
import { NFTCreatorService } from "./../components/services/NFTCreator/NFTCreator.service";
import authContext from './../components/Shared/Auth.service';
import { useHistory } from 'react-router-dom';
import eversign from '../components/Shared/Eversign/Eversign';

const sharedService = new SharedService();
const nftService = new NFTCreatorService();

const UserAgreement = () => {
    const { userInfo, setUserInfo } = useContext(authContext);
    const [loading, setLoading] = useState(false);
    const [userAgreementSigned, setUserAgreementSigned] = useState(false);
    const [openEversign, setOpenEversign] = useState(false);
    const [signURL, setSignURL] = useState(null);

    useEffect(()=>{
        if(userInfo?.status){
            if(userInfo.status.userAgreementSigned === true) {
                setUserAgreementSigned(true);
                window.location.href = '/KYCSubmitted'
            }

            (async () => {
                setLoading(true);
                try {
                    const response = await nftService.getUserAgreementSignURL(userInfo._id);
                    if(response.data) {
                        setSignURL(response.data);
                    }else {
                        message.error("Something went wrong while fetching user agreement url, please try again.");
                    }
                } catch (error) {
                    console.error(error);
                    message.error("Something went wrong while fetching user agreement url, please try again.");
                } finally {
                    setLoading(false);
                }
            })();
        }
    },[userInfo])

    useEffect(() => {
        setOpenEversign(signURL && !userAgreementSigned)
    }, [signURL, userAgreementSigned])

    const signUserAgreementSigned = async () => {
        setLoading(true);
        try {
            const response = await nftService.setUserAgreementSigned({
                email: userInfo.email,
                userId: userInfo._id
              });
              if (response.success && response.data) {
                userInfo.status.userAgreementSigned = true;
                setUserAgreementSigned(true);
                setOpenEversign(false);
                window.location.href = '/KYCSubmitted';
              }
        } catch (error) {
            console.error(error);
            message.error("Something went wrong while submitting user agreement, please try again.");
        }finally {
            setLoading(false);
        }
    };

    return (
        <Card loading={loading} style={{textAlign: 'center', justifyContent: 'center'}}>
            {!userAgreementSigned && (<h2 style={{ color: 'blue', fontSize: '24px' }}>User Agreement</h2>)}
            <br/>
            <br/>
            {/* NDA View Starts Here */}
            {!userAgreementSigned && (<div hidden={userAgreementSigned} id="embedSignDivision">
                
            {/* settimeout is here beacuse eversign.open is taking some time to open/default load for the first time*/}
            {openEversign &&
                setTimeout(()=> eversign.open({
                url: signURL,
                containerID: "embedSignDivision",
                width: 750,
                height: 800,
                events: {
                    loaded: () => {console.log("loading successful")},
                    signed: signUserAgreementSigned,
                    error: () => console.log("EVERSIGN ERROR"),
                    declined: () => console.log("declined"),
                },
                }), 10)}
            </div>)}
            {/* NDA View ends here */}

            {userAgreementSigned && (<h6 style={{margin: '30px', textAlign: 'center'}}>You have successfully signed User Agreement</h6>)}
        </Card>
    );
}

export default UserAgreement