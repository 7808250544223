import { environment } from '../../../../environments/environment';
import Web3 from 'web3';
import BigNumber from 'bignumber.js';

export class MasterWhiteListingService {
    private ethereum = window['ethereum'];
    private web3 = new Web3(this.ethereum);
    private contract = {
        ABI: require('./MasterWhiteListing.json')
    };
    
    // Whitelisting the NftCreator's wallet by SuperAdmin - MultiSig - 1st Approval
    async whitelistNftUserBySuperAdmin(NFTUserAddress: string, _isWhiteListingFeePaid: boolean, MSAdmin: string, CommissionsContractAddr: string) {    
        const contractInstance = new this.web3.eth.Contract(this.contract.ABI, CommissionsContractAddr);
        
        return contractInstance.methods.addWhiteListedAddress(NFTUserAddress, _isWhiteListingFeePaid).send({ from: MSAdmin });
    }

    // Whitelisting the NftCreator's wallet by ComplianceAdmin - MultiSig - 2nd Approval
    async whitelistNftUserByComplianceAdmin(_addressToWhitelist: string, _isWhiteListingFeePaid: boolean, MCAdmin: string, contractAddress: string) {    
        const contractInstance = new this.web3.eth.Contract(this.contract.ABI, contractAddress);
        return contractInstance.methods.addWhiteListedAddress(_addressToWhitelist, _isWhiteListingFeePaid).send({ from: MCAdmin });
    }

    async ApproveNftForMintingBySuperAdmin(NFTUserAddress: string, IPFSURL: string, MSAdmin: string, CommissionsContractAddr: string) {    
        const contractInstance = new this.web3.eth.Contract(this.contract.ABI, CommissionsContractAddr);
        
        return contractInstance.methods.approveNFTForMinting(NFTUserAddress, IPFSURL).send({ from: MSAdmin });
    }

    async ApproveNftForMintingByTokenAdmin(NFTUserAddress: string, IPFSURL: string, MTAdmin: string, CommissionsContractAddr: string) {    
        const contractInstance = new this.web3.eth.Contract(this.contract.ABI, CommissionsContractAddr);
        
        return contractInstance.methods.approveNFTForMinting(NFTUserAddress, IPFSURL).send({ from: MTAdmin });
    }

    async PromoteGoldenBadgeUser(NFTUserAddress: string, MSAdmin: string, CommissionsContractAddr: string) {    
        const contractInstance = new this.web3.eth.Contract(this.contract.ABI, CommissionsContractAddr);
        
        return contractInstance.methods.PromoteGoldenBadgeUser(NFTUserAddress).send({ from: MSAdmin });
    }

    async collectWhiteListingFee(NFTUserAddress: string, whiteListingFee: string, CommissionsContractAddr: string) {
        console.log(`NFTUserAddress: ${NFTUserAddress}, whiteListingFee: ${whiteListingFee}, CommissionsContractAddr: ${CommissionsContractAddr}`); 
        const contractInstance = new this.web3.eth.Contract(this.contract.ABI, CommissionsContractAddr);
        
        // return contractInstance.methods.collectWhiteListingFee(NFTUserAddress).send({ from: NFTUserAddress, value: this.web3.utils.toWei(whiteListingFee, "ether") });
        
        const transactionParameters = {
            from: NFTUserAddress,
            value: this.web3.utils.toWei(whiteListingFee, "ether")
        };

        const transactionObject = contractInstance.methods.collectWhiteListingFee(
            NFTUserAddress
        );
        let receipts;
        let txn_hash;
 
 
        try{
        const transactionHash = await transactionObject.send(transactionParameters)
        .on('transactionHash', (hash: string) => {
            // Here you get the transaction hash immediately
            console.log('Transaction Hash:', hash);
            txn_hash = hash;
        })
        .on('confirmation', (confirmationNumber: number, receipt: any) => {
            // This is called when the transaction is confirmed
            console.log('Transaction Confirmed:', confirmationNumber);
            receipts = receipt;
        })
        .on('error', (error: any) => {
            // Handle errors
            console.error('Transaction Error:', error);
            receipts = false;
        });
        console.log("after confirmation: ", receipts);
        return receipts;
 
        }catch(e){
            return {hash: txn_hash, pending: true};
        }
 




    }
}