import React from 'react';
import ReactDOM from 'react-dom';
import "./assets/animated.css";
import '../node_modules/font-awesome/css/font-awesome.min.css'; 
import '../node_modules/elegant-icons/style.css';
import '../node_modules/et-line/style.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.js';
import './assets/style.scss';
import App from './App';

//redux store
import { Provider } from 'react-redux'
import store from './store';

import { environment } from './components/environments/environment';

/*AXIOS*/
import axios from 'axios';
axios.interceptors.response.use(response => response.data);
axios.defaults.headers.post['apiKey'] = environment.apiKey;

//time zone set
process.env.TZ = environment.TimeZone;

ReactDOM.render(
	<Provider store={store}>
		<App />
	</Provider>,
	document.getElementById('root'));