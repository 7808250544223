import { environment } from '../../../../../../environments/environment';
import Web3 from 'web3';

export class CommissionsBeaconService {
    private ethereum = window['ethereum'];
    private web3 = new Web3(this.ethereum);
    private contract = {
        ABI: require('./CommissionsBeacon.json')
    };
    
    async upgradeCommissionsContract(newCommissionsContractAddress: string, PSAdmin: string, networkId: string) {    
        const contractInstance = new this.web3.eth.Contract(this.contract.ABI, environment.blockchains[networkId].Commissions_Beacon_Address);
        return contractInstance.methods.upgrade(newCommissionsContractAddress).send({ from: PSAdmin });
    }
}