/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from "react";
import { useHistory, Link } from "react-router-dom";
import { Spin, notification, Button, Form, Card, Input, Select } from "antd";
import { createGlobalStyle } from "styled-components";
import { SharedService } from "../../components/Shared/Shared.service";
import { AdminService } from "../../components/services/AdminService/Admin.service";
import ConnectToMetamask from "../../components/ConnectToMetamask";
import { MetamaskService } from "../../components/Shared/Metamask.service";
import authContext from "../../components/Shared/Auth.service";
import greenTickMark from "../../assets/images/home/greenTickMark.png";
import { PlatformService } from "../../components/Shared/Blockchain/Platform/Platform.service";
import { AdminRolesBeaconService } from "../../components/Shared/Blockchain/AdminRoles/Beacon/AdminRolesBeacon/AdminRolesBeacon";
import { CommissionsBeaconService } from "../../components/Shared/Blockchain/commissions/Beacon/commissionsBeacon/CommissionsBeacon";
import { WhiteListingBeaconService } from "../../components/Shared/Blockchain/MasterWhitelisting/Beacon/WhiteListingBeacon/WhiteListingBeacon";
import { NftFactoryBeaconService } from "../../components/Shared/Blockchain/NftFactory/Beacon/NftFactoryBeacon/NftFactoryBeacon";
import { NftMintingBeaconService } from "../../components/Shared/Blockchain/NftMinting/Beacon/NftMintingBeacon/NftMintingBeacon";
import { NftTraderBeaconService } from "../../components/Shared/Blockchain/NftTrader/Beacon/NftTradingBeacon/NftTradingBeacon";
import { PlatformBeaconService } from "../../components/Shared/Blockchain/Platform/Beacon/PlatformBeacon/PlatformBeacon.service";
import { environment } from "../../environments/environment";

const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.white {
    background: #FAF6F1;
  }
`;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const platformService = new PlatformService();
const adminRolesBeaconService = new AdminRolesBeaconService();
const commissionsBeaconService = new CommissionsBeaconService();
const whiteListingBeaconService = new WhiteListingBeaconService();
const nftFactoryBeaconService = new NftFactoryBeaconService();
const nftMintingBeaconService = new NftMintingBeaconService();
const nftTraderBeaconService = new NftTraderBeaconService();
const platformBeaconService = new PlatformBeaconService();
const sharedService = new SharedService();
const adminService = new AdminService();

const useSelectedWalletContext = () =>
  new MetamaskService().useSelectedWalletContext();

const TokenAdmin = () => {
  const { userInfo, setUserInfo } = useContext(authContext);
  const [loading, setLoading] = useState(false);
  const { selectedWallet, networkId, setNetworkId, isMetamaskAuthenticated } = useSelectedWalletContext();

  const [platfromSuperAdminDetails, setPlatformSuperAdminDetails] = useState();
  const [marketplaceSuperAdminDetails, setMarketplaceSuperAdminDetails] =
    useState();
  const [
    platfromSuperAdminMarketPlaceConfig,
    setPlatformSuperAdminMarketPlaceConfig,
  ] = useState();
  const [detailsError, setDetailsError] = useState(false);
  const [isMPSuperAdminAdded, setIsMPSuperAdminAdded] = useState(false);
  const [isPlatformCommissionAdded, setIsPlatformCommissionAdded] =
    useState(false);
  const [smartContractData, setSmartContractData] = useState({});
  const [showUpdateContract, setShowUpdateContract] = useState(false);
  const contractList = [
    "PlatformContract",
    "AdminRolesContract",
    "CommissionsContract",
    "MasterWhiteListingContract",
    "NftFactoryContract",
    "NftMintingContract",
    "NftTraderContract",
  ];
  const [contractListSelect, setContractListSelect] = useState([]);
  const [contractSelected, setContractSelected] = useState();
  const [contractAddress, setContractAddress] = useState();
  const [contractError, setContractError] = useState(false);
  const [showChangeWallet, setShowChangeWallet] = useState(false);
  const [platformSuperAdminWallet, setPlatformSuperAdminWallet] = useState("");
  const [platformSuperAdminWalletError, setPlatformSuperAdminWalletError] =
    useState(false);
  const [dashboardData, setDashboardData] = useState({});
  const [todayEtheriumPriceInUSD, setTodayEtheriumPriceInUSD] = useState();
  const [form, form2] = Form.useForm();
  const [whitelistingCommission, setWhiteListingCommision] = useState();
  const [collectionCreationCommission, setCollectionCreationCommision] = useState();
  const [mintingCommission, setMintingCommision] = useState();
  const [listingCommission, setListingCommision] = useState();
  const [sellingCommission, setSellingCommision] = useState();
  const [royaltyCommission, setRoyaltyCommision] = useState();

  const isNotConnectedToMetamask = !isMetamaskAuthenticated;

  const history = useHistory();

  useEffect(async () => {
    setLoading(true);
    setDetailsError(false);

    if(!networkId) return;

    setTimeout(async () => {
      if (userInfo?._id && userInfo?.role === "platform-super-admin") {
        try {
          let res =
            await adminService.getPlatformMarketplaceSuperAdminDetails();
          if (
            res.data &&
            res.data.platfromSuperAdminDetails?.email &&
            res.data.marketplaceSuperAdminDetails?.email
          ) {
            setPlatformSuperAdminDetails(res.data.platfromSuperAdminDetails);
            setPlatformSuperAdminMarketPlaceConfig(
              res.data.platformSuperAdminMarketConfiguration
            );
            setWhiteListingCommision(res.data.platformSuperAdminMarketConfiguration?.whitelistingCommission);
            setCollectionCreationCommision(res.data.platformSuperAdminMarketConfiguration?.collectionCreationCommission);
            setMintingCommision(res.data.platformSuperAdminMarketConfiguration?.NFTMintingCommission);
            setListingCommision(res.data.platformSuperAdminMarketConfiguration?.NFTSellingCommission);
            setSellingCommision(res.data.platformSuperAdminMarketConfiguration?.NFTSellingCommission);
            setRoyaltyCommision(res.data.platformSuperAdminMarketConfiguration?.RoyaltyCommission);

            setMarketplaceSuperAdminDetails(
              res.data.marketplaceSuperAdminDetails
            );
            if (
              res.data.marketplaceSuperAdminDetails.status.walletWhitelisted?.[networkId] ===
              "approved"
            ) {
              setIsMPSuperAdminAdded(true);
            }

            let res1 = await adminService.getPlatformSuperAdminWallet();
            if (res1.data?.length > 0) setPlatformSuperAdminWallet(res1.data);

            //setting ether price
            setTodayEtheriumPriceInUSD(await sharedService.getEtheriumPrice());

            //setDashboard Data
            let res2 = await adminService.getPlatformSuperAdminDashboard({networkId});
            if (res2?.data) setDashboardData(res2.data);
          } else {
            setDetailsError(true);
          }

          // Set contractAddress Data
          let res1 = await adminService.getMarketPlaceDeployedSmartContract({networkId});
          if (res1?.data) setSmartContractData(res1.data);
          setLoading(false);
        } catch (error) {
          console.log(error);
          notification.open({
            message: "Error",
            description: "something went wrong while fetching nft data",
            duration: 0,
          });
        }
      } else {
        //return history.push('/account/login');
      }
    }, 400);
  }, [userInfo, networkId]);

  useEffect(() => {
    let tempArray = [];
    for (let i = 0; i < contractList.length; i++) {
      tempArray.push({ label: contractList[i], value: contractList[i] });
    }
    setContractListSelect(tempArray);
  }, []);

  const approveMarketplaceSuperAdmin = async () => {
    console.log(
      "marketplaceSuperAdminDetails?.walletAddress:", String(marketplaceSuperAdminDetails?.walletAddress),
      "platfromSuperAdminDetails?.walletAddress:", String(platfromSuperAdminDetails?.walletAddress),
      "marketplaceSuperAdminDetails?.marketplaceName:", String(marketplaceSuperAdminDetails?.marketplaceName),
    )

    if(!marketplaceSuperAdminDetails?.walletAddress || !platfromSuperAdminDetails?.walletAddress) {
      notification.open({
        message: "Error",
        description: "No Marketplace Super Admin Details Found",
        duration: 0,
      });
      return;
    }

    if(!marketplaceSuperAdminDetails?.marketplaceName) {
      notification.open({
        message: "Error",
        description: "No Marketplace Found",
        duration: 0,
      });
      return;
    }


    setLoading(true);

    try {
      const txnReceipt = await platformService.addSuperAdmin(
        String(marketplaceSuperAdminDetails?.walletAddress),
        String(platfromSuperAdminDetails?.walletAddress),
        String(marketplaceSuperAdminDetails?.marketplaceName),
        environment.blockchains[networkId].Platform_Beacon_Proxy_Address
        // platfromSuperAdminMarketPlaceConfig?.Platform_contract_Address
      );

      console.log("Blockchain Transaction receipt: ", txnReceipt);
      if (txnReceipt) {
        let form = {
          userId: marketplaceSuperAdminDetails?._id,
          MPsuperAdminWallet: marketplaceSuperAdminDetails?.walletAddress,
          PSuperAdmin: platfromSuperAdminDetails?.walletAddress,
          transactionDetail: txnReceipt,
          networkId
        };

        const response = await adminService.approveMPSuperAdminWallet(form);
        if (response.success) {
          setLoading(false);
          setIsMPSuperAdminAdded(true);
          notification.open({
            message: `Successfully whitelisted/Added Marketplace-Super-Admin: ${marketplaceSuperAdminDetails?.walletAddress}`,
            duration: 0,
          });
        } else {
          notification.open({
            message: "Error while adding marketplace super admin",
            description: response.error.message,
            duration: 0,
          });
        }
      }
    } catch (error) {
      setLoading(false);
      notification.open({
        message: error.message,
        duration: 0,
      });
    }
  };

  const updatePlatformCommission = async() => {
    setLoading(true);
    try {
        let form = {
          marketplaceName: platfromSuperAdminMarketPlaceConfig?.marketplaceName,
          whitelistingCommission: whitelistingCommission,
          collectionCreationCommission: collectionCreationCommission,
          NFTMintingCommission: mintingCommission,
          NFTListingCommission: listingCommission,
          NFTSellingCommission: sellingCommission,
          RoyaltyCommission: royaltyCommission
        };

        const response = await adminService.updatePlatformCommission(form);
        if (response.success) {
          setLoading(false);
          notification.open({
            message: `Successfully updated Platform commissions`,
            duration: 0,
          });

          setTimeout(()=> window.location.reload(), 1000);
        } else {
          notification.open({
            message: "Error",
            description: response.error.message,
            duration: 0,
          });
        }
      } catch (error) {
        notification.open({
          message: error.message,
          duration: 0,
        });
      }

    setLoading(false);
  }

  const addPlatformFeesAndCommissions = async () => {
    setLoading(true);
    console.log(platfromSuperAdminDetails, platfromSuperAdminMarketPlaceConfig, environment.blockchains[networkId].Platform_Beacon_Proxy_Address);
    try {
      const txnReceipt = await platformService.setCommission_P(
        String(platfromSuperAdminDetails?.walletAddress),
        platfromSuperAdminMarketPlaceConfig?.whitelistingCommission,
        platfromSuperAdminMarketPlaceConfig?.collectionCreationCommission,
        platfromSuperAdminMarketPlaceConfig?.NFTMintingCommission,
        platfromSuperAdminMarketPlaceConfig?.NFTListingCommission,
        platfromSuperAdminMarketPlaceConfig?.NFTSellingCommission,
        environment.blockchains[networkId].Platform_Beacon_Proxy_Address
        // platfromSuperAdminMarketPlaceConfig?.Platform_contract_Address
      );

      console.log("Blockchain Transaction receipt: ", txnReceipt);
      if (txnReceipt) {
        let form = {
          userId: platfromSuperAdminDetails?._id,
          PSuperAdmin: platfromSuperAdminDetails?.walletAddress,
          transactionDetail: txnReceipt,
          networkId
        };

        const response = await adminService.addPlatformCommissions(form);
        if (response.success) {
          setLoading(false);
          setIsPlatformCommissionAdded(true);
          notification.open({
            message: `Successfully Added Platform commissions`,
            duration: 0,
          });
        } else {
          notification.open({
            message: "Error",
            description: response.error.message,
            duration: 0,
          });
        }
      }
    } catch (error) {
      setLoading(false);
      notification.open({
        message: error.message,
        duration: 0,
      });
    }
  };

  const updateContractAddress = async () => {
    setContractError(false);
    if (!contractSelected || !contractAddress || contractAddress.length < 6) {
      return setContractError(true);
    }
    setLoading(true);
    try {
      let txnDetails;
      if (contractSelected === "AdminRolesContract") {
        const txnReceipt =
          await adminRolesBeaconService.upgradeAdminRolesContract(
            String(contractAddress),
            String(platfromSuperAdminDetails?.walletAddress),
            networkId
          );

        console.log("Blockchain Transaction receipt: ", txnReceipt);
        txnDetails = txnReceipt;
      } else if (contractSelected === "CommissionsContract") {
        const txnReceipt =
          await commissionsBeaconService.upgradeCommissionsContract(
            String(contractAddress),
            String(platfromSuperAdminDetails?.walletAddress),
            networkId
          );

        console.log("Blockchain Transaction receipt: ", txnReceipt);
        txnDetails = txnReceipt;
      } else if (contractSelected === "MasterWhiteListingContract") {
        const txnReceipt =
          await whiteListingBeaconService.upgradeWhiteListingContract(
            String(contractAddress),
            String(platfromSuperAdminDetails?.walletAddress),
            networkId
          );

        console.log("Blockchain Transaction receipt: ", txnReceipt);
        txnDetails = txnReceipt;
      } else if (contractSelected === "NftFactoryContract") {
        const txnReceipt =
          await nftFactoryBeaconService.upgradeNftFactoryContract(
            String(contractAddress),
            String(platfromSuperAdminDetails?.walletAddress),
            networkId
          );

        console.log("Blockchain Transaction receipt: ", txnReceipt);
        txnDetails = txnReceipt;
      } else if (contractSelected === "NftMintingContract") {
        const txnReceipt =
          await nftMintingBeaconService.upgradeNftMintingContract(
            String(contractAddress),
            String(platfromSuperAdminDetails?.walletAddress),
            networkId
          );

        console.log("Blockchain Transaction receipt: ", txnReceipt);
        txnDetails = txnReceipt;
      } else if (contractSelected === "NftTraderContract") {
        const txnReceipt =
          await nftTraderBeaconService.upgradeNftTraderContract(
            String(contractAddress),
            String(platfromSuperAdminDetails?.walletAddress),
            networkId
          );

        console.log("Blockchain Transaction receipt: ", txnReceipt);
        txnDetails = txnReceipt;
      } else if (contractSelected === "PlatformContract") {
        const txnReceipt = await platformBeaconService.upgradePlatformContract(
          String(contractAddress),
          String(platfromSuperAdminDetails?.walletAddress),
          networkId
        );

        console.log("Blockchain Transaction receipt: ", txnReceipt);
        txnDetails = txnReceipt;
      }

      let form = {
        userId: platfromSuperAdminDetails?._id,
        type: contractSelected?.toString(),
        newContractAddress: contractAddress,
        transactionDetail: txnDetails,
        networkId
      };

      const response = await adminService.updateMarketplaceContractAddress(
        form
      );
      if (response.success) {
        setShowUpdateContract(false);
        setContractSelected("");
        setContractAddress("");
        notification.open({
          message: `Successfully updated new Contract Address`,
          duration: 0,
        });
        setTimeout(() => window.location.reload(), 2000);
      } else {
        notification.open({
          message: "Error",
          description: response.error.message,
          duration: 0,
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      notification.open({
        message: error.message,
        duration: 0,
      });
    }
  };

  const updatePlatformSuperAdminWallet = async () => {
    setPlatformSuperAdminWalletError(false);
    if (!platformSuperAdminWallet || platformSuperAdminWallet.length < 6) {
      return setPlatformSuperAdminWalletError(true);
    }
    // Here only we will be having psa credentials, so before update just check the selected metamask wallet and wallet address coming from db is same or not if yes then go ahead and change the wallet.
    try {
      const txnReceipt = await platformService.updatePSAdmin(
        String(userInfo?.walletAddress),
        String(platformSuperAdminWallet),
        environment.blockchains[networkId].Platform_Beacon_Proxy_Address
        // platfromSuperAdminMarketPlaceConfig?.Platform_contract_Address
      );

      console.log("Blockchain Transaction receipt: ", txnReceipt);
      if (txnReceipt) {
        let form = {
          psaWalletAddress: platformSuperAdminWallet,
          transactionDetail: txnReceipt,
          networkId
        };
        let res1 = await adminService.setPlatformSuperAdminWallet(form);
        if (res1.data) {
          notification.open({
            message: "Successfully updated Platform Super Admin Wallet Address",
            duration: 0,
          });
          setLoading(false);
          setTimeout(() => window.location.reload(), 1000);
        }
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      notification.open({
        message: "Error",
        description: "something went wrong while updating wallet address",
        duration: 0,
      });
    }
  };

  return (
    <>
      <div hidden={!isNotConnectedToMetamask}>
        <ConnectToMetamask />
      </div>
      <div hidden={isNotConnectedToMetamask}>
        <GlobalStyles />
        {loading && (
          <div
            className="center"
            style={{ textAlign: "center", margin: "100px 0" }}
          >
            <Spin size="large" />
          </div>
        )}

        {!loading && (
          <div className="platform-admin-view" style={{ minHeight: "1350px" }}>
            <section className="">
              {!isPlatformCommissionAdded && (
                <button
                  title="Update Platform commissions and fees to blockchain"
                  style={{ margin: "30px 10px" }}
                  onClick={addPlatformFeesAndCommissions}
                  type="button"
                  className="btn btn-primary approve-admin-button"
                >
                  Update Platform commissions and fees to blockchain
                </button>
              )}

              {!isMPSuperAdminAdded && (
                <button
                  title="Approve Marketplace Super Admin"
                  style={{ margin: "30px 10px" }}
                  onClick={approveMarketplaceSuperAdmin}
                  type="button"
                  className="btn btn-primary approve-admin-button"
                >
                  Approve Marketplace Super Admin
                </button>
              )}

              {isMPSuperAdminAdded && (
                <div
                  style={{
                    display: "flex",
                    padding: "5px",
                    alignItems: "center",
                  }}
                >
                  <img src={greenTickMark} alt="" height="15px" width="35px" />
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <p style={{ margin: "2px 2px 0px 25px" }}>
                      MPSuperAdmin is successfully Added!
                    </p>
                  </div>
                </div>
              )}
              <br />
              {!showUpdateContract && (
                <button
                  title="Upgrade Contracts"
                  style={{ margin: "30px 10px" }}
                  onClick={() => setShowUpdateContract(true)}
                  type="button"
                  className="btn btn-primary approve-admin-button"
                >
                  Upgrade Contracts
                </button>
              )}

              {showUpdateContract && (
                <Card>
                  <Form
                    {...formItemLayout}
                    form={form}
                    onFinish={updateContractAddress}
                    style={{ margin: "2% 10% 0 10%", width: "80%" }}
                  >
                    <Form.Item
                      label="Select Contract Name To Upgrade"
                      name="contractName"
                      rules={[
                        {
                          required: false,
                          message: "Please Select Contract Name To Upgrade",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Contract Name"
                        onChange={(val) => setContractSelected(val)}
                        value={contractSelected}
                        options={[...contractListSelect]}
                        style={{ marginRight: "20px" }}
                      />
                    </Form.Item>

                    <Form.Item
                      label="New Contract Address"
                      name="newContract"
                      rules={[
                        {
                          required: true,
                          message: "Please input New Contract Address",
                        },
                      ]}
                    >
                      <Input
                        type="text"
                        value={contractAddress}
                        onChange={(e) => setContractAddress(e.target.value)}
                        style={{ padding: "2px 5px 2px 5px" }}
                      />
                    </Form.Item>

                    <Form.Item {...tailFormItemLayout}>
                      <Button
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                      >
                        Update
                      </Button>
                    </Form.Item>
                    {contractError && (
                      <>
                        <br />
                        <h6 style={{ color: "orangered" }}>
                          Please select a contract name from dropdown and also
                          give correct value in the contract address input box
                        </h6>
                      </>
                    )}
                  </Form>
                </Card>
              )}
              <br />

              {!showChangeWallet && (
                <button
                  title="Change Platform Admin Wallet Address"
                  style={{ margin: "30px 10px" }}
                  onClick={() => setShowChangeWallet(true)}
                  type="button"
                  className="btn btn-primary approve-admin-button"
                >
                  Change Platform Admin Wallet Address
                </button>
              )}

              {showChangeWallet && (
                <Card style={{ margin: "20px 10px" }}>
                  <Form
                    {...formItemLayout}
                    form={form2}
                    onFinish={updatePlatformSuperAdminWallet}
                    style={{ margin: "2% 10% 0 10%", width: "80%" }}
                  >
                    <Form.Item
                      label="Platform Super Admin Wallet Address"
                      name="platformSuperAdminWallet"
                      rules={[
                        {
                          required: true,
                          message:
                            "Please input Platform Super Admin Wallet Address",
                        },
                      ]}
                    >
                      <input
                        type="text"
                        defaultValue={platformSuperAdminWallet}
                        value={platformSuperAdminWallet}
                        onChange={(e) =>
                          setPlatformSuperAdminWallet(e.target.value)
                        }
                        style={{ width: "80%", marginLeft: "10px" }}
                      />
                    </Form.Item>

                    <br />
                    <Form.Item {...tailFormItemLayout}>
                      <Button
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                      >
                        Update
                      </Button>
                    </Form.Item>
                  </Form>
                  <br />
                  <br />
                </Card>
              )}
            </section>

            {/* Platform Admin Commission DashBoard View */}
            <div className="col-12 platform-dashboard-table">
              <Card style={{ margin: "20px 10px" }}>
                <h3>Platform DashBoard</h3>
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">No</th>
                      <th scope="col">Label</th>
                      <th scope="col">Total Count / Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dashboardData &&
                      Object.keys(dashboardData).map((key, index) => (
                        <tr>
                          <th scope="row">{index + 1}</th>
                          <td>{key}</td>
                          {key === "RevenueFromWhiteListingFees" ||
                          key === "RevenueFromCollectionFees" ||
                          key === "RevenueFromMintingFees" ||
                          key === "RevenueFromListingFees" ||
                          key === "RevenueFromSellingFees" ||
                          key === "TotalRevenueFromCommission" ? (
                            <td>{`${dashboardData[key].toFixed(6)} ETH -> ${(
                              dashboardData[key] * todayEtheriumPriceInUSD
                            ).toFixed(3)} USD`}</td>
                          ) : (
                            <td>{dashboardData[key]}</td>
                          )}
                        </tr>
                      ))}
                  </tbody>
                </table>
              </Card>
            </div>
            <br />
            <br />

            {/* Platform Admin Configuration Details View */}
            {platfromSuperAdminMarketPlaceConfig && (
              <>
                <div
                  className="platform-admin-table"
                  style={{ marginTop: "380px" }}
                >
                  <Card>
                    <h4>Platform Super Admin Commission Details</h4>
                    {Object.keys(platfromSuperAdminMarketPlaceConfig)?.length >
                      0 && (
                      <>
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th scope="col">Field Name</th>
                              <th scope="col">Value</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th>Platform Super Admin Name</th>
                              <td>
                                {platfromSuperAdminMarketPlaceConfig?.firstName +
                                  " " +
                                  platfromSuperAdminMarketPlaceConfig?.lastName}
                              </td>
                            </tr>
                            <tr>
                              <th>Platform Super Admin Email</th>
                              <td>
                                {platfromSuperAdminMarketPlaceConfig?.email}
                              </td>
                            </tr>
                            <tr>
                              <th>Market Place Name</th>
                              <td>
                                {
                                  platfromSuperAdminMarketPlaceConfig?.marketplaceName
                                }
                              </td>
                            </tr>
                            {/* <tr>
                              <th>White Listing Commission</th>
                              <td>
                                {
                                  platfromSuperAdminMarketPlaceConfig?.whitelistingCommission
                                }
                                %
                              </td>
                            </tr>
                            <tr>
                              <th>Collection Commission</th>
                              <td>
                                {
                                  platfromSuperAdminMarketPlaceConfig?.collectionCreationCommission
                                }
                                %
                              </td>
                            </tr>
                            <tr>
                              <th>Minting Commission</th>
                              <td>
                                {
                                  platfromSuperAdminMarketPlaceConfig?.NFTMintingCommission
                                }
                                %
                              </td>
                            </tr>
                            <tr>
                              <th>Listing Commission</th>
                              <td>
                                {
                                  platfromSuperAdminMarketPlaceConfig?.NFTListingCommission
                                }
                                %
                              </td>
                            </tr>
                            <tr>
                              <th>Royalty Commission</th>
                              <td>
                                {
                                  platfromSuperAdminMarketPlaceConfig?.RoyaltyCommission
                                }
                                %
                              </td>
                            </tr>
                            <tr>
                              <th>Selling Commission</th>
                              <td>
                                {
                                  platfromSuperAdminMarketPlaceConfig?.NFTSellingCommission
                                }
                                %
                              </td>
                            </tr> */}
                          </tbody>
                        </table>
                        <br/>
                        <Form
                          {...formItemLayout}
                          form={form}
                          onFinish={updatePlatformCommission}
                          style={{ margin: "2% 10% 0 10%", width: "80%" }}
                        >
                          <Form.Item
                            label="White Listing Fee in Percentage"
                            name="whitelistingFee"
                            rules={[
                              {
                                required: false,
                                message: "Please input Listing Fees in Percentage!",
                              },
                            ]}
                          >
                            <Input
                              type="number"
                              defaultValue={whitelistingCommission}
                              value={whitelistingCommission}
                              onChange={(e) => setWhiteListingCommision(e.target.value)}
                              style={{ padding: "2px 5px 2px 5px", width: '5rem' }}
                              suffix="%"
                            />
                          </Form.Item>

                          <Form.Item
                            label="Collection Creation Fee in Percentage"
                            name="collectionCreationFee"
                            rules={[
                              {
                                required: false,
                                message: "Please input Collection Creation Fee in Percentage!",
                              },
                            ]}
                          >
                            <Input
                              type="number"
                              defaultValue={collectionCreationCommission}
                              value={collectionCreationCommission}
                              onChange={(e) => setCollectionCreationCommision(e.target.value)}
                              style={{ padding: "2px 5px 2px 5px", width: '5rem' }}
                              suffix="%"
                            />
                          </Form.Item>

                          <Form.Item
                            label="NFT Minting Fee in Percentage"
                            name="mintingFees"
                            rules={[
                              {
                                required: false,
                                message: "Please input NFT Minting Fee in Percentage!",
                              },
                            ]}
                          >
                            <Input
                              type="number"
                              defaultValue={mintingCommission}
                              value={mintingCommission}
                              onChange={(e) => setMintingCommision(e.target.value)}
                              style={{ padding: "2px 5px 2px 5px", width: '5rem' }}
                              suffix="%"
                            />
                          </Form.Item>

                          <Form.Item
                            label="NFT Listing Fee in Percentage"
                            name="listingfees"
                            rules={[
                              {
                                required: false,
                                message: "Please input NFT Listing Fee in Percentage!",
                              },
                            ]}
                          >
                            <Input
                              type="number"
                              defaultValue={listingCommission}
                              value={listingCommission}
                              onChange={(e) => setListingCommision(e.target.value)}
                              style={{ padding: "2px 5px 2px 5px", width: '5rem' }}
                              suffix="%"
                            />
                          </Form.Item>

                          <Form.Item
                            label="Selling Fee in Percentage"
                            name="sellingFee"
                            rules={[
                              {
                                required: false,
                                message: "Please input Selling Fee in Percentage!",
                              },
                            ]}
                          >
                            <Input
                              type="number"
                              defaultValue={sellingCommission}
                              value={sellingCommission}
                              onChange={(e) => setSellingCommision(e.target.value)}
                              style={{ padding: "2px 5px 2px 5px", width: '5rem' }}
                              suffix="%"
                            />
                          </Form.Item>

                          {/* <Form.Item
                            label="Royalty Fee in Percentage"
                            name="royaltyFee"
                            rules={[
                              {
                                required: false,
                                message: "Please input Royalty Fee in Percentage!",
                              },
                            ]}
                          >
                            <Input
                              type="number"
                              defaultValue={royaltyCommission}
                              value={royaltyCommission}
                              onChange={(e) => setRoyaltyCommision(e.target.value)}
                              style={{ padding: "2px 5px 2px 5px", width: '5rem' }}
                              suffix="%"
                            />
                          </Form.Item> */}

                          <Form.Item {...tailFormItemLayout}>
                            <Button
                              type="primary"
                              htmlType="submit"
                              loading={loading}
                            >
                              Update
                            </Button>
                          </Form.Item>
                        </Form>
                      </>
                    )}
                  </Card>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default TokenAdmin;
