import React from 'react'

const DisclaimerQArt = () => {
  return (
    <div className='container disclaimer'>
      <h4>DISCLAIMER</h4>

      <p>
      The limitations and prohibitions of liability set in this Section and elsewhere in this disclaimer: (a) are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer, including liabilities arising in contract, in tort and for breach of statutory duty.
      </p>

      <p>
      As long as the website and the information and services on the website are provided free of charge, we will not be liable for any loss or damage of any nature.
      </p>
    </div>
  )
}

export default DisclaimerQArt