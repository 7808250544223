import { environment } from '../../../../../../environments/environment';
import Web3 from 'web3';

export class PlatformBeaconService {
    private ethereum = window['ethereum'];
    private web3 = new Web3(this.ethereum);
    private contract = {
        ABI: require('./PlatformBeacon.json')
    };

    async upgradePlatformContract(newAdminRolesContractAddress: string, PSAdmin: string, networkId: string) {    
        const contractInstance = new this.web3.eth.Contract(this.contract.ABI, environment.blockchains[networkId].Platform_Beacon_Address);
        return contractInstance.methods.upgrade(newAdminRolesContractAddress).send({ from: PSAdmin });
    }
}