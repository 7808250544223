import { Button, Form, notification } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { AdminService } from '../../../../components/services/AdminService/Admin.service';
import { CommissionsService } from '../../../../components/Shared/Blockchain/commissions/commissions.service';
import authContext from '../../../../components/Shared/Auth.service';
import { MetamaskService } from '../../../../components/Shared/Metamask.service';


const commissionsService = new CommissionsService();
const adminService = new AdminService();
const useSelectedWalletContext = () =>
  new MetamaskService().useSelectedWalletContext();

function CommissionConfiguration(props) {
  const { todayEtheriumPriceInUSD, smartContractData, loading, setLoading, config, loadConfiguration=()=>{} } = props || {}
  const defaultCommissionForMarketplace = 10;
  const [form] = Form.useForm();
  const { userInfo } = useContext(authContext);
  const { networkId } = useSelectedWalletContext();
  const [listingFees, setListingFees] = useState();
  const [MintingFees, setMintingFees] = useState();
  const [walletWhitelistingFee, setWalletWhitelistingFee] = useState();
  const [collectionFee, setCollectionFee] = useState();
  const [sellingFees, setSellingFees] = useState();
  const commission = defaultCommissionForMarketplace;
  const [royalty, setRoyalty] = useState(defaultCommissionForMarketplace);

  useEffect(() => {
    setListingFees(config?.listingFees);
    setMintingFees(config?.MintingFees);
    setSellingFees(config?.sellingFees);
    setRoyalty(config?.royalty ?? defaultCommissionForMarketplace);
    setWalletWhitelistingFee(config?.walletWhitelistingFee);
    setCollectionFee(config?.collectionFee);
  }, [config, defaultCommissionForMarketplace])

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 16,
        offset: 8,
      },
    },
  };

  const updateCommission = async () => {
    if (!smartContractData?.commissionsContract) {
      notification.open({
        message: 'Error',
        description: 'Commissions contract address not found',
        duration: 0,
      });
      return;
    }

    setLoading(true);

    try {
      const txnReceipt = await commissionsService.setCommissionAndFees_NU(
        String(userInfo?.walletAddress),
        String(walletWhitelistingFee),
        String(collectionFee),
        String(MintingFees),
        String(listingFees),
        commission,
        royalty,
        String(sellingFees),
        String(smartContractData?.commissionsContract)
      );

      console.log("Blockchain Transaction receipt: ", txnReceipt);
      if (txnReceipt) {
        let form1 = {
          userId: userInfo._id,
          type: "Updating-commissions-NormalUser",
          commission: parseInt(commission),
          walletWhitelistingFee: walletWhitelistingFee,
          MintingFees: MintingFees,
          collectionFee: collectionFee,
          listingFees: listingFees,
          sellingFees: sellingFees,
          royalty: parseInt(royalty),
          MPsuperAdmin: userInfo?.walletAddress,
          transactionDetail: txnReceipt,
          networkId,
        };
        let res = await adminService.setMarketPlaceConfiguration(form1);
        if (res.data) {
          notification.open({
            message:
              "Successfully updated commission and fees for normal NFTCreator",
            duration: 0,
          });
          setLoading(false);
          loadConfiguration();
        }
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      notification.open({
        message: error.message,
        description: "something went wrong while updating data",
        duration: 0,
      });
    }
  };

  return (
    !loading && <>
      <p className='market-super-admin commission-configuration-text'>Commission Configuration</p>
      <div className='market-super-admin card-container d-flex justify-content-center'>
        <div className='market-super-admin card-inner-container'  style={{width: '100%', padding: '40px 20px'}}>
          <Form
            {...formItemLayout}
            form={form}
            onFinish={updateCommission}
            style={{ padding: "30px", width: "100%" }}
          >

            <Form.Item
              label="NFTListing Fees in Ethereum"
              name="ListingFees"
              rules={[
                {
                  required: false,
                  message: "Please input Listing Fees in Ether!",
                },
              ]}
            >
              <input
                type="text"
                inputMode='decimal'
                pattern="^[0-9]*[.,]?[0-9]*$"
                defaultValue={listingFees}
                value={listingFees}
                onChange={(e) => {
                    const value = e.target.value;
                    if (/^[0-9]*[.,]?[0-9]*$/.test(value)) {
                    setListingFees(value);
                          }
                     }}
                style={{ padding: "2px 5px 2px 5px" }}
              />
              {listingFees && (
                <span style={{alignItems:"center"}} >
                  USD:{" "}
                  {(
                    listingFees * todayEtheriumPriceInUSD
                  ).toFixed(3)}
                </span>
              )}
            </Form.Item>

            <Form.Item
              label="NFTMinting Fees in Ethereum"
              name="MintingFees"
              rules={[
                {
                  required: false,
                  message: "Please input Minting Fees in Ether!",
                },
              ]}
            >
              <input
                type="text"
                inputMode='decimal'
                pattern="^[0-9]*[.,]?[0-9]*$"
                defaultValue={MintingFees}
                value={MintingFees}
                onChange={(e) => {
                    const value = e.target.value;
                    if (/^[0-9]*[.,]?[0-9]*$/.test(value)) {
                      setMintingFees(value);
                          }
                     }}
                style={{ padding: "2px 5px 2px 5px" }}
              />
              {MintingFees && (
                <span style={{alignItems:"center"}} >
                  USD:{" "}
                  {(
                    MintingFees * todayEtheriumPriceInUSD
                  ).toFixed(3)}
                </span>
              )}
            </Form.Item>

            <Form.Item
              label="Wallet Whitelisting Fee in Ethereum"
              name="walletWhitelistingFee"
              rules={[
                {
                  required: false,
                  message:
                    "Please input Wallet Whitelisting Fee in Ethereum!",
                },
              ]}
            >
              <input
                type="text"
                inputMode='decimal'
                pattern="^[0-9]*[.,]?[0-9]*$"
                defaultValue={walletWhitelistingFee}
                value={walletWhitelistingFee}
                onChange={(e) => {
                    const value = e.target.value;
                    if (/^[0-9]*[.,]?[0-9]*$/.test(value)) {
                      setWalletWhitelistingFee(value);
                          }
                     }}
                style={{ padding: "2px 5px 2px 5px" }}
              />
              {walletWhitelistingFee && (
                <span style={{alignItems:"center"}} >
                  USD:{" "}
                  {(
                    walletWhitelistingFee *
                    todayEtheriumPriceInUSD
                  ).toFixed(3)}
                </span>
              )}
            </Form.Item>

            <Form.Item
              label="Collection Fee in Ethereum"
              name="collectionFee"
              rules={[
                {
                  required: false,
                  message:
                    "Please input Collection Fee in Ethereum!",
                },
              ]}
            >
              <input
                type="text"
                inputMode='decimal'
                pattern="^[0-9]*[.,]?[0-9]*$"
                defaultValue={collectionFee}
                value={collectionFee}
                onChange={(e) => {
                    const value = e.target.value;
                    if (/^[0-9]*[.,]?[0-9]*$/.test(value)) {
                      setCollectionFee(value);
                          }
                     }}
                style={{ padding: "2px 5px 2px 5px" }}
              />
              {collectionFee && (
                <span style={{alignItems:"center"}} >
                  USD:{" "}
                  {(
                    collectionFee * todayEtheriumPriceInUSD
                  ).toFixed(3)}
                </span>
              )}
            </Form.Item>

            <Form.Item
              label="Selling Commission in Percentage"
              name="sellingFee"
              rules={[
                {
                  required: false,
                  message:
                    "Selling Commission in Percentage!",
                },
              ]}
            >
              <input
                type="number"
                defaultValue={sellingFees}
                value={sellingFees}
                onChange={(e) => setSellingFees(e.target.value)}
                style={{ padding: "2px 5px 2px 5px" }}
              /> <span style={{alignItems:"center"}}> {"%"}</span>
            </Form.Item>

            {smartContractData.deployed === true && (<Form.Item {...tailFormItemLayout}>
              <Button
                type="primary"
                htmlType="submit"
                className='market-place-admin update-button'
                loading={loading}
              >
                Update
              </Button>
            </Form.Item>)}
          </Form>
        </div>
      </div>
    </>
  )
}

export default CommissionConfiguration
