import React, {useState,Suspense} from 'react';
import { BrowserRouter as Router, Redirect, Route, useSearchParams } from 'react-router-dom';
import { AuthService, UseUserInfo } from "./components/components/Shared/Auth.service";
import { SharedService } from "./components/components/Shared/Shared.service";
import { MetamaskService } from "./components/components/Shared/Metamask.service";
import dotenv from "dotenv";
import authContext from './components/components/Shared/Auth.service';
import { environment } from './components/environments/environment';

import ScrollToTopBtn from './components/menu/ScrollToTop';
import Header from './components/menu/header';
import Footer from './components/components/footer-old';
import "./components/assets/css/animate.css";
import "./components/assets/css/style.scss";
import "./components/assets/css/responsive.css";

import { createGlobalStyle } from 'styled-components';

import Spinner from "./components/components/spinner/Spinner";
import AppRoutes from './App.routes';
import ConnectToMetamask from './components/components/ConnectToMetamask';
import HeaderNew from './components/components/HeaderNew';
import FooterNew from './components/components/FooterNew';

dotenv.config();
const sharedService = new SharedService();
const metamaskService = new MetamaskService();

const SelectedWalletContext = MetamaskService.SelectedWalletContext;
const useSelectedWallet = (userId) =>
  new MetamaskService().useSelectedWallet(userId);

const GlobalStyles = createGlobalStyle`
  :root {
    scroll-behavior: unset;
  }
`;

export const ScrollTop = ({ children, location }) => {
  React.useEffect(() => window.scrollTo(0,0), [location])
  return children
}

const App = () => {
  const [userInfo, setUserInfo] = UseUserInfo();
  const [
    selectedWallet,
    networkId,
    isMetamaskAuthenticated,
    setSelectedWallet,
    setNetworkId,
    setIsMetamaskAuthenticated,
  ] = useSelectedWallet('app');

  const isNotConnectedToMetamask = networkId && !sharedService.allowedNetworks.includes(networkId);

  return (
    <>
    {/* Maintenance mode code */}
    {environment.maintenanceMode === 'true' && (<h5 style={{ textAlign: 'center', margin: '40px 20px', fontSize: '24px', fontWeight: '500'}}>This site is under Maintenance. Sorry for the Inconvenience!! <br/> We will get back very soon..</h5>)}

    {/* Non Maintenance or Normal mode code */}
    {environment.maintenanceMode !== 'true' && (
      <authContext.Provider value={{ userInfo, setUserInfo }}>
        <SelectedWalletContext.Provider value={{ selectedWallet, setSelectedWallet, networkId, setNetworkId, isMetamaskAuthenticated }}>
          <div className="wraper" onContextMenu={(e)=> e.preventDefault()}>
          {/* <div className="wraper"> */}
            <GlobalStyles />
              <Router>
                {/* <Header/> */}
                <HeaderNew />
                <Suspense fallback={Spinner}>
                <div hidden={!isNotConnectedToMetamask}>
                  <ConnectToMetamask
                    isMetamaskInstalled={metamaskService.isMetamaskInstalled}
                    isMetamaskAuthenticated={isMetamaskAuthenticated}
                    setSelectedWallet={setSelectedWallet}
                    setNetworkId={setNetworkId}
                    setIsMetamaskAuthenticated={setIsMetamaskAuthenticated}
                  />
                </div>
                <div hidden={!!isNotConnectedToMetamask} className='main-body'>
                  <AppRoutes userInfo={userInfo} setUserInfo={setUserInfo} networkId={networkId} />
                </div>
                {/* <AppRoutes userInfo={userInfo} /> */}
              </Suspense>
              {/* <Footer /> */}
              <FooterNew />
              </Router>
            <ScrollToTopBtn />    
          </div>
        </SelectedWalletContext.Provider>
      </authContext.Provider>)}
    </>
  );
};
export default App;