import React, { useEffect, useState, useRef, useContext } from "react";
import {
  Table,
  Card,
  Col,
  Row,
  Typography,
  Descriptions,
  Button,
  Tabs,
  Radio,
  message,
  Input,
  Carousel,
  notification,
  Form,
  Spin
} from "antd";
import {
  RightCircleOutlined,
  LeftCircleOutlined
} from '@ant-design/icons'
import moment from 'moment';
import { PlaySquareOutlined, ProjectOutlined } from "@ant-design/icons";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { useHistory, useParams } from 'react-router-dom';
import { SharedService } from './../components/Shared/Shared.service';
import { AdminService } from '../components/services/AdminService/Admin.service';
import authContext from './../components/Shared/Auth.service';
import { environment } from "../environments/environment";

const { Title } = Typography;
const { TabPane } = Tabs;
const { TextArea } = Input;
//const issuerComplianceAdminService = new IssuerComplianceAdminService();

const sharedService = new SharedService();

const displayOptions = {
  AfterRejectDivision: "2",
  MainDivision: "1",
};
const subDisplayOptions = {
  RejectView: "1",
  ResubmitView: "2",
};

const adminService = new AdminService();

export default () => {
    const { userInfo, setUserInfo } = useContext(authContext);
    const [kycDetails, setKycDetails] = useState({});
  const [detailsDivision, setDetailsDivision] = useState(true);
  const [investorDetails, setInvestorDetails] = useState();
  const [submitting, setSubmitting] = useState(false);
  const [detailsDivision2, setDetailsDivision2] = useState(true);
  const [subDisplayOption, setSubDisplayOption] = useState(subDisplayOptions.ResubmitView);
  const [kycRejectReason, setKycRejectReason] = useState();
  const [showKycReason, setShowKycReason] = useState(false);

  const { kycId } = useParams();
  const resubmitRadioRef = useRef(null);
  const rejectRadioRef = useRef(null);
  const textBoxRef = useRef(null);

  const history = useHistory();

  let carousel = useRef(null);

  const card = {
    backgroundColor: "#ebeff5",
    height: "400px",
  };

  const iconCard = {
    height: "400px"
  };

  const image = {
    height: "100%",
    width: "100%",
    borderRadius: "10px"
  };

  const p = {
    fontSize: "25px",

  };

  const icon = {
    fontSize: "25px",
    margin: 0,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",

  };

  const imageDiv = {
    backgroundColor: "#ebeff5",
    width: "50%",
    margin: "auto",
    height: "285px"
  };

  const columns = [
    {
      title: "FIRST NAME",
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: "LAST NAME",
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: "EMAIL",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "ROLE",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "COMPANY",
      dataIndex: "company",
      key: "company",
    },
    {
      title: "CREATED ON",
      dataIndex: "dateOfSubmission",
      key: "creationTS",
      render: (text) => moment(text).format("LLL"),
    },
    {
      title: "ACTION",
      dataIndex: "action",
      key: "action",
      render: (text, investor) => (
        <Button type="primary" onClick={() => showDetails(investor)}>
          DETAILS
        </Button>
      ),
    },
  ];

  const columns2 = [
    {
      title: "FIRST NAME",
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: "LAST NAME",
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: "ROLE",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "EMAIL",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "COMPANY",
      dataIndex: "company",
      key: "company",
    },
    {
      title: "CREATED ON",
      dataIndex: "dateOfSubmission",
      key: "creationTS",
      render: (text) => moment(text).format("LLL"),
    },
  ];

  useEffect(async()=>{
      if(kycId){
        let res = await adminService.getKYCById(kycId);
        setKycDetails(res.data);
      }
  },[kycId])

  const showDetails = (investor) => {
    setDetailsDivision(false);
    setInvestorDetails(investor);
  };

  const showDetailsForResult = (investor) => {
    setDetailsDivision2(false);
    setInvestorDetails(investor);
  };

  //   const resubmitKYC = async () => {
//     // investorDetails.resubmit=true;
//     // const response=await issuerComplianceAdminService.resubmitKYC(investorDetails);
//     // if(response.success)
//     // {
//     //   setDetailsDivision(true);
//     // }
//     // else
//     // {
//     //  message.error(response.error);
//     //  return;
//     // }
//   };

  const acceptKYC = () => {
    (async () => {
      setSubmitting(true);
      kycDetails.approvedByICA = true;
      kycDetails.approved = true;
      kycDetails.rejected = false;
      kycDetails.KYCStatus = "approved";
      const response = await adminService.updateKYCDetails(kycDetails);
      if (response.success) {
        setDetailsDivision(true);
        notification.open({
            message: 'Success',
            description: 'KYC approved successfully'
        });
        setTimeout(() => {
            console.log('User role:', userInfo?.role);
            if (userInfo?.role === "marketplace-super-admin") {
                console.log('Redirecting to super-admin page');
                window.location.href = '/marketplace-admin/super-admin';
            } else {
                console.log('Redirecting to compliance-admin page');
                window.location.href = '/marketplace-admin/compliance-admin';
            }
        }, 1000);
      }
      setSubmitting(false);
    })();
  };

  const rejectKYC = () => {
    (async () => {
        setSubmitting(true);
        kycDetails.approvedByICA = true;
        kycDetails.approved = false;
        kycDetails.rejected = true;
        kycDetails.KYCStatus = "rejected";
        if(!kycRejectReason) return;

        kycDetails.rejectedReason = kycRejectReason;
        console.log(kycRejectReason)
        const response = await adminService.updateKYCDetails(kycDetails);
        if (response.success) {
          setDetailsDivision(true);
          notification.open({
              message: 'Success',
              description: 'KYC rejected successfully'
          });
          setTimeout(() => {
            console.log('User role:', userInfo?.role);
            if (userInfo?.role === "marketplace-super-admin") {
                console.log('Redirecting to super-admin page');
                window.location.href = '/marketplace-admin/super-admin';
            } else {
                console.log('Redirecting to compliance-admin page');
                window.location.href = '/marketplace-admin/compliance-admin';
            }
        }, 1000);      
     }
        setSubmitting(false);
      })();
  };


  return (
    <>
      <br />
      <Row justify="center">
      {submitting && (<div className="center" style={{textAlign: 'center', margin: '100px 0'}}>
                  <Spin size="large"/>
              </div>)}
       {!submitting && (<Col span={22} style={{ textAlign: "center" }}>
            <Row>
                <Col span={1}>
                <Card
                    style={iconCard}
                    onClick={
                    () => carousel.prev()
                    }
                >
                    <LeftCircleOutlined style={icon} />
                </Card>
                </Col>

                <Col span={22}>
                    <Carousel ref={node => (carousel = node)} autoplay>
                        <div>
                            <Card
                                style={card}>
                                <p style={p}>
                                Selfie
                                </p>
                                <div
                                style={{...imageDiv, marginLeft: '37%'}}
                                >
                                <img
                                    style={{...image, width: '285px'}}
                                    src={kycDetails?.selfie}
                                    alt="selfie"
                                />
                                </div>
                            </Card>
                        </div>

                        <div>
                            <Card
                                style={card}>
                                <p style={p}>
                                Document Front Side
                                </p>
                                <div
                                style={imageDiv}
                                >
                                <img
                                    style={image}
                                    src={kycDetails?.IDFront}
                                    alt="ID Front"
                                />
                                </div>
                            </Card>
                        </div>

                        <div>
                            <Card
                                style={card}>
                                <p style={p}>
                                Document Back Side
                                </p>
                                <div
                                style={imageDiv}
                                >
                                <img
                                    style={image}
                                    src={kycDetails?.IDBack}
                                    alt="ID Back"
                                />
                                </div>
                            </Card>
                        </div>
                    </Carousel>
                </Col>

                <Col span={1}>
                    <Card style={iconCard}>
                        <RightCircleOutlined
                        style={icon}
                        onClick={
                            () => carousel.next()
                        }
                        />
                    </Card>
                </Col>

            </Row>

            <br/><br/>
            <Descriptions>
                {kycDetails?.fullResult?.state === "A" && (
                    <Descriptions.Item label="Automated KYC result">
                    <p
                        style={{
                        fontWeight: "bold",
                        color: "green",
                        fontSize: "25px",
                        }}
                    >
                        Approved
                    </p>
                    </Descriptions.Item>
                )}
                {kycDetails?.fullResult?.state == "R" && (
                    <Descriptions.Item label="Automated KYC result">
                    <p
                        style={{
                        fontWeight: "bold",
                        color: "yellowgreen",
                        fontSize: "25px",
                        marginTop: '-8px'
                        }}
                    >
                        Could not decide. Manual intervention needed.
                    </p>
                    </Descriptions.Item>
                )}
                {kycDetails?.fullResult?.state === "D" && (
                    <Descriptions.Item label="Automated KYC resultt">
                    <p
                        style={{
                        fontWeight: "bold",
                        color: "red",
                        fontSize: "25px",
                        }}
                    >
                        Rejected
                    </p>
                    </Descriptions.Item>
                )}
            </Descriptions>
            <Card
            style={{
                margin: "auto",
                width: 900,
                boxShadow:
                "0 4px 12px 0 rgba(0, 0, 0, .1), 0 6px 20px 0 rgba(0, 0, 0, 0.1)",
            }}
            >
                <p
                    style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    textAlign: "left",
                    }}
                >
                    PROFILE
                </p>
                <table style={{ margin: "auto", width: "100%" }}>
                    <tbody>
                    <tr>
                    <td style={{ textAlign: "left", fontWeight: "bold" }}>
                        Name
                    </td>
                    <td style={{ textAlign: "left" }}>
                        {kycDetails?.firstName}{" "}
                        {kycDetails?.lastName}
                    </td>
                    </tr>
                    <tr>
                    <td style={{ textAlign: "left", fontWeight: "bold" }}>
                        Present Address
                    </td>
                    <td style={{ textAlign: "left" }}>
                        {
                        <div>
                            {kycDetails?.pAStreetAddress1}{" "}
                            {kycDetails?.pAStreetAddress2}{" "}
                            {kycDetails?.pACity}{" "}
                            {kycDetails?.pAState}{" "}
                            {kycDetails?.pACountry}{" "}
                            {kycDetails?.pAZipCode}
                        </div>
                        }
                    </td>
                    </tr>
                    <tr>
                    <td style={{ textAlign: "left", fontWeight: "bold" }}>
                        Permanent Address
                    </td>
                    <td style={{ textAlign: "left" }}>
                        {
                        <div>
                            {kycDetails?.pStreetAddress1}{" "}
                            {kycDetails?.pStreetAddress2}{" "}
                            {kycDetails?.pCity}{" "}
                            {kycDetails?.pAState}{" "}
                            {kycDetails?.pACountry}{" "}
                            {kycDetails?.pZipCode}
                        </div>
                        }
                    </td>
                    </tr>
                    <tr>
                    <td style={{ textAlign: "left", fontWeight: "bold" }}>
                        Contact Number
                    </td>
                    {kycDetails?.contactNumber && (
                        <td style={{ textAlign: "left" }}>
                        {formatPhoneNumberIntl(
                            "+" + kycDetails?.contactNumber
                        )}
                        </td>
                    )}
                    </tr>
                    <tr>
                    <td style={{ textAlign: "left", fontWeight: "bold" }}>
                        Email
                    </td>
                    <td style={{ textAlign: "left" }}>
                        {kycDetails?.email}
                    </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: "left", fontWeight: "bold" }}>
                            Country
                        </td>
                        <td style={{ textAlign: "left" }}>
                            {kycDetails?.country}
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: "left", fontWeight: "bold" }}>
                            Date of Birth
                        </td>
                        <td style={{ textAlign: "left" }}>
                            {moment(kycDetails?.dob).format('Do MMMM YYYY')}
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: "left", fontWeight: "bold" }}>
                            Company Name
                        </td>
                        <td style={{ textAlign: "left" }}>
                            {kycDetails?.company}
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: "left", fontWeight: "bold" }}>
                            Wallet Address
                        </td>
                        <td style={{ textAlign: "left" }}>
                            <b>{kycDetails?.walletAddress}</b>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: "left", fontWeight: "bold" }}>
                            Country
                        </td>
                        <td style={{ textAlign: "left" }}>
                            {kycDetails?.country}
                        </td>
                    </tr>
                    <tr>
                    <td style={{ textAlign: "left", fontWeight: "bold" }}>
                        Last Stage run
                    </td>
                    <td style={{ textAlign: "left", fontWeight: "bold" }}>
                        3
                    </td>
                    </tr>
                    <tr>
                    <td style={{ textAlign: "left", fontWeight: "bold" }}>
                        Date of KYC Request
                    </td>
                    <td style={{ textAlign: "left" }}>
                        {moment(kycDetails?.dateOfSubmission).format(
                        "LLL"
                        )}
                    </td>
                    </tr>
                    <tr>
                    <td style={{ textAlign: "left", fontWeight: "bold" }}>
                        Customer since
                    </td>
                    <td style={{ textAlign: "left" }}>
                        {moment(userInfo?.creationTS).format("LLL")}
                    </td>
                    </tr>
                    <tr>
                    <td style={{ textAlign: "left", fontWeight: "bold" }}>
                        Priority
                    </td>
                    <td style={{ textAlign: "left" }}>Normal</td>
                    </tr>
                    <tr>
                    <td style={{ textAlign: "left", fontWeight: "bold" }}>
                        Progress
                    </td>
                    <td style={{ textAlign: "left" }}>New</td>
                    </tr>
                    <tr>
                    <td style={{ textAlign: "left", fontWeight: "bold" }}>
                        Name of fraud rule
                    </td>
                    <td style={{ textAlign: "left" }}>
                        {kycDetails?.fullResult?.frn}
                    </td>
                    </tr>
                    <tr>
                    <td style={{ textAlign: "left", fontWeight: "bold" }}>
                        Result of fraud evaluation
                    </td>
                        <td style={{ textAlign: "left", color: "green" }}>
                        {kycDetails?.fullResult?.frp}
                        </td>
                    </tr>
                    <tr>
                    <td style={{ textAlign: "left", fontWeight: "bold" }}>
                        Result of policy evaluation
                    </td>
                    <td style={{ textAlign: "left", color: "green" }}>
                    {kycDetails?.fullResult?.res}
                    </td>
                    </tr>
                    <tr>
                    <td style={{ textAlign: "left", fontWeight: "bold" }}>
                        User reputation
                    </td>
                    <td style={{ textAlign: "left" }}>
                        {kycDetails?.fullResult?.user}
                    </td>
                    </tr>
                    <tr>
                    <td style={{ textAlign: "left", fontWeight: "bold" }}>
                        User previous reputation
                    </td>
                    <td style={{ textAlign: "left" }}>
                        {kycDetails?.fullResult?.upr}
                    </td>
                    </tr>
                    <tr>
                    <td style={{ textAlign: "left", fontWeight: "bold" }}>
                        Reason for reputation
                    </td>
                    <td style={{ textAlign: "left" }}>
                        {kycDetails?.fullResult?.erd}
                    </td>
                    </tr>
                    </tbody>
                </table>

                <p style={{ textAlign: "right" }}>
                    <Button
                    style={{ color: "green" }}
                    href={
                        environment.acuantUrl
                    }
                    target="__blank"
                    >
                    Detailed Report
                    </Button>
                </p>
            </Card>
            <br /><br/>

            <div hidden={!kycDetails || kycDetails.approved == true || kycDetails.rejected == true}>
                {showKycReason && (<>
                    <Form
                        onFinish={data=>rejectKYC()}
                    >
                        <Form.Item
                        name='rejectDiscription'
                        label='Reason'
                        rules={[{
                            required:true,
                            message:"Reason required!!"
                        }]}
                        >
                            <Input.TextArea rows={4} cols={25} value={kycRejectReason} onChange={(e)=>setKycRejectReason(e.target.value)}></Input.TextArea>
                        </Form.Item>
                        <Form.Item>
                        <Button type="primary" htmlType='submit'  disabled={submitting}>Reject</Button>
                        </Form.Item>
                    </Form>
                    <br />
                </>)}
                <Button
                    onClick={() => acceptKYC()}
                    type="primary"
                    loading={submitting}
                >
                    Accept
                </Button>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <Button
                    onClick={() => setShowKycReason(true)}
                    style={{background: 'lightred'}}
                    disabled={submitting}
                >
                    Reject
                </Button>
                &nbsp;&nbsp;&nbsp;&nbsp;
                {/* <Button
                    onClick={() => setDetailsDivision(true)}
                    disabled={submitting}
                >
                    Cancel
                </Button> */}
            </div>
            <br/><br/>
        </Col>)}
      </Row>
    </>
  );
};
