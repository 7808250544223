import React from 'react'

const PrivacyPolicyPixentro = () => {
    return (
      <div className='container privacy-policy'>
        <h4>PRIVACY POLICY</h4>
        <p>
          Marketplace (“we”, “us”, “our”) is committed to the security and confidentiality of the personal information we hold. Personal information is information about an identifiable individual, either alone or when combined with other information. We have developed this privacy policy to inform our users (collectively “you”, “your”) about our privacy practices, and how we are protecting your personal information. This policy applies with respect to your use of our website and all services we provide.
        </p>
        <p>
          Please read this privacy policy carefully as this policy describes how we collect, use, disclose and safeguard your personal information. Our policy should be read with our Terms of Service. Unless otherwise defined, the terms used in this privacy policy have the same meaning as in our Terms of Service. By using our website or any service we offer, you understand and agree to the collection, use, and disclosure of your personal information by us, each and every time, in the manner described in this policy.
        </p>
        <h4>Disclosure:</h4>
        <p>
          We do not sell, rent or otherwise disclose your personal for any purpose to any external parties, except as required by law, such as:
        </p>
        <ul>
          <li>When we determine, at our sole discretion, that the disclosure of such information is necessary or appropriate to enforce our rights against you or in connection with a breach by you of this policy or the Terms of Use;</li>
          <li>To investigate or respond to suspected illegal or fraudulent activity or to protect the safety, rights, or property of us, our users, or others;</li>
          <li>To prevent prohibited or illegal activities;</li>
          <li>When required by any applicable law, rule, regulations, subpoena, or other legal process.</li>
        </ul>
        <h4 style={{marginTop: '20px'}}>Security Safeguards</h4>
        <p>
          We take your privacy very seriously and we are committed to implementing security safeguards that are appropriate to the sensitivity of the personal information we hold. As part of our commitment to privacy, we take appropriate and reasonable technical, physical and administrative security measures to safeguard the personal information in our custody and control from such risks as unauthorized access, collection, use, disclosure, copying, modification, disposal or destruction.
        </p>
        <p>
          Please note that no data transmissions or encryptions can be guaranteed to be completely secure. As a result, while we strive to protect your personal information, we cannot warrant the security of any information you transmit to us, and you do so at your own risk.
        </p>
        <h4>Cookies and Related Technologies</h4>
        <p>
          A cookie is a small text file that our website may place on your computer as a tool to remember your preferences and display more relevant information next time you visit. You may instruct your browser to refuse all cookies by selecting the appropriate settings.
        </p>
        <p>
          We use login cookies, which are session cookies. These temporary cookies only last for the duration of your visit to our services.
        </p>
        <h4>Retaining Personal Information</h4>
        <p>
          We will retain any and all personal information for the duration of providing our services to you. Once you have ceased to have access to our website or services, your personal information will be deleted. We will also retain your information as necessary to comply with any applicable laws and regulations for the full length of time required under those laws and regulations.
        </p>
        <h4>Children’s Data</h4>
        <p>
          We do not knowingly collect children’s data. Our services are not intended for persons 18 years of age and under and are not directed towards or meant to be used by children.
        </p>
        <h4>Changes to Our Privacy Policy</h4>
        <p>
          We may update this policy from time to time. Updated versions of our privacy policy will continue to be posted on this page. We will let you know via email and/or a prominent notice on our services, prior to the change becoming effective and update “effective date” at the top of this policy. You are advised to review this policy periodically for any changes. Changes to this policy are effective when they are posted on this page.
        </p>
      </div>
    )
  }
  
  export default PrivacyPolicyPixentro