import { environment } from '../../../../environments/environment';
import Web3 from 'web3';

export class NftMintingService {
    private ethereum = window['ethereum'];
    private web3 = new Web3(this.ethereum);
    private contract = {
        ABI: require('./NftMinting.json')
    };
    
    async mintNft(NftAddress: string, IPFS_URL: string, royalty: Number, NftUser: string, NFTMintingFee: string) {  
        console.log(`line no: 12, NftAddress: ${NftAddress}, IPFS_URL: ${IPFS_URL}, royalty: ${royalty}, NftUser: ${NftUser}, NFTMintingFee: ${NFTMintingFee}`);
        const contractInstance = new this.web3.eth.Contract(this.contract.ABI, NftAddress);
        console.log('contractInstance: ' + contractInstance) ;
        // return contractInstance.methods.mintNft(IPFS_URL, royalty).send({ from: NftUser, value: this.web3.utils.toWei(NFTMintingFee, "ether") });
    
        const transactionParameters = {
            from: NftUser,
            value: this.web3.utils.toWei(NFTMintingFee, "ether") 
        };

        const transactionObject = contractInstance.methods.mintNft(
            IPFS_URL, royalty
        );
        let receipts;
        let hashs;

        try{
        const transactionHash = await transactionObject.send(transactionParameters)
        .on('transactionHash', (hash: string) => {
            // Here you get the transaction hash immediately
            console.log('Transaction Hash:', hash);
            hashs = hash;
        })
        .on('confirmation', (confirmationNumber: number, receipt: any) => {
            // This is called when the transaction is confirmed
            console.log('Transaction Confirmed:', confirmationNumber);
            receipts = receipt;
        })
        .on('error', (error: any) => {
            // Handle errors
            console.error('Transaction Error:', error);
            receipts = false;
        });
        console.log("after confirmation: ", receipts);
        return receipts;

        }catch(e){
            return {hash: hashs, pending: true};
        }


    
    
    }
}