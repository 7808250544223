import React from 'react'
import { Tabs, Tab } from "react-tabs-scrollable";
import "react-tabs-scrollable/dist/rts.css";
import "./mui-tabs.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { carouselNew } from "../../components/constants";
import { useHistory } from "react-router-dom";
import NFTCard from '../../components/NFTCardComponent';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

function HomePageCollections({ allCollections = [], todayEtheriumPriceInUSD }) {
  const history = useHistory();
  const [activeTab, setActiveTab] = React.useState(0);

  // define a onClick function to bind the value on tab click
  const onTabClick = (e, index) => {
    setActiveTab(index);
  };

  return (
    <>
      <section className="home-page browse-by-category third_section" style={{ height: '300px' }}>
        <div className="home-page browse-by-category container">
          <div className="row">
            <div className="col-lg-12 home-page explore-collections-div">
              <div className="col-lg-8 d-flex flex-column align-items-end justify-content-center">
                <h2 className="home-page browse-by-category-text explore-collections">Explore Collections</h2>
                <p className="home-page browse-by-category-sub-text">Explore Recent NFTs from creators worldwide</p>
              </div>
              <div className="col-lg-4 d-flex align-items-end justify-content-end">
                <button
                  type="button"
                  style={{marginTop: "40px !important"}}
                  className="btn_view_all float-end home-page view-all-nft-btn collection"
                  onClick={() => history.push('/collections')}
                >
                  VIEW ALL
                </button>
              </div>
            </div>
          </div>



          <div className="col-12">
            <div className="home-page slider-container">
              <Tabs
                activeTab={activeTab}
                onTabClick={onTabClick}
                hideNavBtnsOnMobile={false}
                leftBtnIcon={<img
                  src={`./img/home/rightArrow.png`}
                  className="home-page right-arrow-collection"
                  alt="category-img"
                />}
                rightBtnIcon={<img
                  src={`./img/home/leftArrow.png`}
                  className="home-page left-arrow-collection"
                  alt="category-img"
                />}
              >
                {allCollections?.length > 0 &&
                  allCollections.map((collection, index) => (
                    <Tab key={`collection-${index}`}>
                      <div className='d-flex align-items-center'>
                        <LazyLoadImage
                          src={`${collection?.collectionDocument?.FirebaseURL}`}
                          className="img-fluid float-end home-page collection-polygon-img"
                          alt="collection"
                          effect="blur"
                          onClick={() =>
                            window.open(
                              `/collection/${collection?.collectionName}`,
                              "_self"
                            )
                          }
                        />
                        <p className="card-text text-dark home-page collection-polygon-name">
                          {collection?.collectionName}
                        </p>
                      </div>
                    </Tab>
                  ))}
              </Tabs>
            </div>
          </div>
        </div>
      </section>

      <div style={{ background: "#ECEDEEAB" }}>
        <div className='d-flex align-items-center' style={{ padding: '20px 0 20px 40px' }}>
          <div style={{ width: '100%' }}>
            <p className='collection-nft-name-title'>{allCollections[activeTab]?.collectionName}</p>
          </div>
          <div className="view-all-nft-div display-flex justify-content-end" style={{ width: '100%', margin: 0 }}>
            <button
              type="button"
              style={{ background: "#FFFFFF" }}
              className="btn_view_all float-end home-page view-all-nft-btn"
              onClick={() => history.push('/all-nft')}
            >
              VIEW ALL
            </button>
          </div>
        </div>
      </div>

      <div className="col-12" style={{ background: 'rgb(236 237 238)', height: '590px' }}>
        <div className="nft-101 slider-container">
          <Slider {...carouselNew}>
            {allCollections[activeTab]?.nftDetails?.length > 0 &&
              allCollections[activeTab]?.nftDetails.map((nft, index) => (
                <div className="itm col-xl-3 col-lg-4 col-md-6 col-sm-12" key={index}>
                  <div className="d-item">
                    <div
                      className={`nft__item nft-item-buy`}
                      style={{
                        margin: "3px",
                        padding: "3px",
                        border: "none",
                        backgroundColor: "transparent",
                      }}
                    >
                      <NFTCard
                        nft={nft}
                        todayEtheriumPriceInUSD={
                          todayEtheriumPriceInUSD
                        }
                        isCreator={true}
                       buy={true}
                      />

                    </div>
                  </div>
                </div>
              ))}
          </Slider>
        </div>
      </div>

    </>
  )
}

export default HomePageCollections