import { Input, notification } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { AdminService } from '../../../../components/services/AdminService/Admin.service';
import authContext from '../../../../components/Shared/Auth.service';

const adminService = new AdminService();

export default function AdminInvites(props) {
  const { userInfo } = useContext(authContext);
  const { setLoading, loading } = props || {}
  const [marketplaceComplianceAdminInvite, setMarketplaceComplianceAdminInvite] = useState(false);
  const [marketplaceComplianceAdminInviteEmail, setMarketplaceComplianceAdminInviteEmail] = useState();
  const [marketplaceComplianceAdminApproved, setMarketplaceComplianceAdminApproved] = useState();
  const [marketplaceTokenAdminApproved, setMarketplaceTokenAdminApproved] = useState();
  const [marketplaceTokenAdminInviteEmail, setMarketplaceTokenAdminInviteEmail] = useState();
  const [marketplaceTokenAdminInvite, setMarketplaceTokenAdminInvite] = useState(false);

  useEffect(() => {
    fetchData();
  }, [userInfo])

  const fetchData = async () => {
    setLoading(true);
    try {
      //invites data
      let res2 = await adminService.getAdminInvitesDetails(userInfo?._id);
      if (res2.data) {
        if (res2.data.complianceInvite)
          setMarketplaceComplianceAdminInvite(res2.data.complianceInvite);
        if (res2.data.complianceInviteEmail)
          setMarketplaceComplianceAdminInviteEmail(
            res2.data.complianceInviteEmail
          );
        if (res2.data.complianceAdminApproved)
          setMarketplaceComplianceAdminApproved(
            res2.data.complianceAdminApproved
          );

        if (res2.data.tokenInvite)
          setMarketplaceTokenAdminInvite(res2.data.tokenInvite);
        if (res2.data.tokenInviteEmail)
          setMarketplaceTokenAdminInviteEmail(res2.data.tokenInviteEmail);
        if (res2.data.tokenAdminApproved)
          setMarketplaceTokenAdminApproved(
            res2.data.tokenAdminApproved
          );
      }
    } catch (error) {
      console.log(error);
      notification.open({
        message: "Error",
        description: "something went wrong while fetching admin profile data",
        duration: 0,
      });
    }
    setLoading(false);
  };

  const sendInvite = async (type) => {
    setLoading(true);
    if (
      type === "compliance-admin" &&
      marketplaceComplianceAdminInviteEmail.length > 6
    ) {
      try {
        let res = await adminService.sendComplianceAdminInvite(
          marketplaceComplianceAdminInviteEmail
        );
        if (res.data) {
          notification.open({
            message: "Success",
            duration: 0,
          });
          setMarketplaceComplianceAdminInvite(true);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
        notification.open({
          message: "Error",
          description: "something went wrong while sending email",
          duration: 0,
        });
      }
    }

    if (type === "token-admin" && marketplaceTokenAdminInviteEmail.length > 6) {
      try {
        let res = await adminService.sendTokenAdminInvite(
          marketplaceTokenAdminInviteEmail
        );
        if (res.data) {
          setLoading(false);
          notification.open({
            message: "Success",
            duration: 0,
          });
          setMarketplaceTokenAdminInvite(true);
        }
      } catch (error) {
        console.log(error);
        notification.open({
          message: "Error",
          description: "something went wrong while sending email",
          duration: 0,
        });
      }
    }
    setLoading(false);
  };

  const changeAdminEmail = async (type) => {
    setLoading(true);
    if (
      type === "compliance-admin" &&
      marketplaceComplianceAdminInviteEmail.length > 6
    ) {
      try {
        let res = await adminService.changeComplianceAdminEmail(
          marketplaceComplianceAdminInviteEmail
        );
        if (res.data) {
          notification.open({
            message: "Success",
            duration: 0,
          });
          setMarketplaceComplianceAdminInvite(true);
          setTimeout(() => window.location.reload(), 1000);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
        notification.open({
          message: "Error",
          description: "something went wrong while sending email",
          duration: 0,
        });
      }
    }

    if (type === "token-admin" && marketplaceTokenAdminInviteEmail.length > 6) {
      try {
        let res = await adminService.changeTokenAdminEmail(
          marketplaceTokenAdminInviteEmail
        );
        if (res.data) {
          setLoading(false);
          notification.open({
            message: "Success",
            duration: 0,
          });
          setMarketplaceTokenAdminInvite(true);
          // setTimeout(() => window.location.reload(), 1000);
          fetchData();
        }
      } catch (error) {
        console.log(error);
        notification.open({
          message: "Error",
          description: "something went wrong while sending email",
          duration: 0,
        });
      }
    }
    setLoading(false);
  };

  return (
    !loading && <>
      <p className='market-super-admin commission-configuration-text'>NFT Configuration</p>
      <div className='market-super-admin admin-invites card-container d-flex justify-content-center'>
        <div className='market-super-admin card-inner-container' style={{ width: '100%', padding: '50px', height: '550px' }}>
          <div className="col-12">
            <h3 className='Marketplace-Compliance-Admin-text'>Marketplace Compliance Admin </h3>
            {!marketplaceComplianceAdminInvite && (
              <div className="form">
                <Input
                  type="email"
                  value={marketplaceComplianceAdminInviteEmail}
                  onChange={(e) =>
                    setMarketplaceComplianceAdminInviteEmail(
                      e.target.value
                    )
                  }
                  style={{ width: "50%", marginRight: "20px" }}
                />
                <button
                  type="submit"
                  className="btn btn-info"
                  onClick={() => sendInvite("compliance-admin")}
                >
                  Send Invite
                </button>
              </div>
            )}

            {marketplaceComplianceAdminInvite && (
              <div className="form">
                <Input
                  type="email"
                  defaultValue={
                    marketplaceComplianceAdminInviteEmail
                  }
                  value={marketplaceComplianceAdminInviteEmail}
                  disabled={marketplaceComplianceAdminApproved}
                  style={{ width: "50%", marginRight: "20px" }}
                />
                {!marketplaceComplianceAdminApproved && (<button
                  type="submit"
                  className="btn btn-info"
                  onClick={() => sendInvite("compliance-admin")}
                >
                  Resend Invite
                </button>)}
              </div>
            )}

            {marketplaceComplianceAdminInvite && marketplaceComplianceAdminApproved && (
              <div className="form">
                <Input
                  type="email"
                  defaultValue={
                    marketplaceComplianceAdminInviteEmail
                  }
                  value={marketplaceComplianceAdminInviteEmail}
                  style={{ width: "50%", marginRight: "20px" }}
                  onChange={(e) =>
                    setMarketplaceComplianceAdminInviteEmail(e.target.value)
                  }
                />
                <button
                  type="submit"
                  className="change-email-btn"
                  onClick={() => changeAdminEmail("compliance-admin")}
                >
                  Change Email Address
                </button>
              </div>
            )}
          </div>

          <div className="col-12">
            <h3 className='Marketplace-Token-Admin-text'>Marketplace Token Admin </h3>
            {!marketplaceTokenAdminInvite && (
              <div className="form">
                <Input
                  type="email"
                  value={marketplaceTokenAdminInviteEmail}
                  onChange={(e) =>
                    setMarketplaceTokenAdminInviteEmail(
                      e.target.value
                    )
                  }
                  style={{ width: "50%", marginRight: "20px" }}
                />
                <button
                  type="submit"
                  className="btn btn-info"
                  onClick={() => sendInvite("token-admin")}
                >
                  Send Invite
                </button>
              </div>
            )}
            {marketplaceTokenAdminInvite && (
              <div className="form">
                <Input
                  type="email"
                  defaultValue={marketplaceTokenAdminInviteEmail}
                  value={marketplaceTokenAdminInviteEmail}
                  disabled={marketplaceTokenAdminApproved}
                  style={{ width: "50%", marginRight: "20px" }}
                />
                {!marketplaceTokenAdminApproved && (<button
                  type="submit"
                  onClick={() => sendInvite("token-admin")}
                  className="btn btn-info"
                >
                  Resend Invite
                </button>)}
              </div>
            )}

            {marketplaceTokenAdminInvite && marketplaceTokenAdminApproved && (
              <div className="form">
                <Input
                  type="email"
                  defaultValue={
                    marketplaceTokenAdminInviteEmail
                  }
                  value={marketplaceTokenAdminInviteEmail}
                  style={{ width: "50%", marginRight: "20px" }}
                  onChange={(e) =>
                    setMarketplaceTokenAdminInviteEmail(e.target.value)
                  }
                />
                <button
                  type="submit"
                  className="change-email-btn"
                  onClick={() => changeAdminEmail("token-admin")}
                >
                  Change Email Address
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
