import React, {useEffect, useState, useContext} from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Spin, notification, Card} from 'antd';
import { AdminService } from '../../components/services/AdminService/Admin.service';
import authContext from '../../components/Shared/Auth.service';

const adminService = new AdminService();

const VerifyTokenAdminProfile = () => {
    const { userInfo, setUserInfo } = useContext(authContext);
    const [loading, setLoading] = useState(true);
    const [emailState, setEmailState] = useState('not sent'); //not sent, submitted, verified
    const history = useHistory();

    useEffect(async()=>{
        setLoading(true);
        setTimeout(async()=>{
            if(userInfo?._id && userInfo?.role === 'marketplace-token-admin') {
              try {
                let res = await adminService.sendTokenAdminVerificationEmail(userInfo?._id);
                setEmailState(res.data);
                if(res.data === 'verified') return history.push('/marketplace-admin/token-admin');
              } catch (error) {
                console.log(error);
                notification.open({
                  message: 'Error',
                  description: 'something went wrong while fetching profile verification data',
                  duration: 0,
                });
              }
            }
            setLoading(false);
        }, 400)
      },[userInfo])
  return (
    <>
    {loading && <Spin style={{size:'large', textAlign: 'center'}}/>}
    <Card style={{margin: '20px', textAlign: 'center'}}>
        {emailState == 'submitted' && (
        <h5>Your Marketplace super admin has been notified by an email to approve you. Once he approve you you can login.</h5>)}
    </Card>
    </>
  )
}

export default VerifyTokenAdminProfile