import React, {useState, useEffect} from 'react'
import { environment } from '../environments/environment';
import PrivacyPolicyQArt from '../dynamicContent/PrivacyPolicy.qart';
import PrivacyPolicyPixentro from '../dynamicContent/PrivacyPolicy.pixentro';

const PrivacyPolicy = () => {
  return (
    <>
      {environment.envName === 'qart' && <PrivacyPolicyQArt />}
      {environment.envName === 'pixentro' && <PrivacyPolicyPixentro />}
    </>
  )
}

export default PrivacyPolicy