/* eslint-disable react-hooks/exhaustive-deps */
// Import useState, useEffect, Input, and SearchOutlined
import React, { useState, useEffect } from "react";
import { NFTCreatorService } from "../components/services/NFTCreator/NFTCreator.service";
import { useHistory } from "react-router-dom";
import { environment } from "./../environments/environment";
import axios from "axios";
import { SharedService } from "./../components/Shared/Shared.service";
import { createGlobalStyle } from "styled-components";
import { Spin, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { MetamaskService } from "../components/Shared/Metamask.service";
import "../pages/styles/collections.scss";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

// Define your components and services
const nftService = new NFTCreatorService();
const sharedService = new SharedService();
const APIURL = environment.APIURL;

// Create global styles if needed
const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.white {
    background: #050b37;
    border-bottom: solid 1px #ccc !important;
  }
`;

const useSelectedWalletContext = () =>
  new MetamaskService().useSelectedWalletContext();

// Define your functional component
const UserCollections = () => {
  const history = useHistory();
  const [collections, setCollections] = useState([]);
  const [loggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState(''); // State to hold the search term
  const { networkId } = useSelectedWalletContext();
  const [loadedImages, setLoadedImages] = useState({});

  const getUserbyToken = async () => {
    return axios.post(
      `${APIURL}/users/getUser`,
      {},
      { headers: await sharedService.getAuthHeader() }
    );
  };

  async function getUserCollections(userId = "") {
    //const response = await nftService.getUserCollections(userId);
    const response = await nftService.getAllCollections({ networkId });
    if (response.success) {
      setCollections(response.data);
    } else {
      console.log("An Error has occured!");
    }
  }

  useEffect(async () => {
    if (!networkId) return;
    //making the page available for public
    await getUserCollections();
    setLoggedIn(true);
    setLoading(false);
  }, [networkId]);

  // Function to handle search input change
  const handleSearchInputChange = (value) => {
    setSearchTerm(value);
  };

  // Filtered collections based on the search term
  const filteredCollections = collections.filter(collection => {
    // Convert both the collection name and the search term to lowercase for case-insensitive comparison
    const collectionNameLower = collection.collectionName.toLowerCase();
    const searchTermLower = searchTerm.toLowerCase();
    return collectionNameLower.includes(searchTermLower);
  });

  const handleImageLoad = (index) => {
    setLoadedImages(prevState => ({
      ...prevState,
      [index]: true,
    }));
  };

  return (
    <>
      <GlobalStyles />
      {loading ? (
        <div
          className='loading-spinner'
        >
          <Spin size="large" />
        </div>
      ) : loggedIn ? (
        <div className="row">
          <div className="banner-big">
            <h2 className="collection-banner title text-center">
              Explore Collections
            </h2>
            <p className="collection-banner para text-center">
              Discover unique Collections on the web
            </p>
          </div>

          <div className="collections">
            <h3 className="collection title" style={{ margin: "0 0 0 60px !important" }}>All Collections</h3>
            <div className="search_icon" style={{ margin: "0px 0px 30px 130px" }}>
              <SearchOutlined />
            </div>
            {/* Input component with onChange event and margin */}
            <Input
              placeholder="Search here..."
              value={searchTerm}
              className="all-nft-search-bar"
              onChange={(e) => handleSearchInputChange(e.target.value)}
              prefix={<SearchOutlined />}
              style={{ margin: "0px 0px 20px 50px" }}
            />

            <div className="d-flex flex-wrap" style={{ marginLeft: '20px' }}>
              {/* Mapping through filtered collections */}
              {filteredCollections.map((collection, index) => (
                <div
                  className="itm col-xl-3 col-lg-4 col-md-6 col-sm-12"
                  index={index + 1}
                  key={index}
                >
                  <div className="d-item d-flex justify-content-center">
                    <div className="nft__item collection-nft all-collections">
                      <div className="nft__item_wrap all-collections">
                        <span>

                          {!loadedImages[index] && <div className="shimmer" />}

                          <LazyLoadImage
                            src={collection?.collectionDocument.FirebaseURL}
                            className="lazy nft__item_preview card-img-top all-collections-img"
                            alt="collection"
                            onClick={() =>
                              history.push(
                                `/collection/${collection.collectionName}`,
                                "_self"
                              )
                            }
                            effect="blur"
                            onLoad={() => handleImageLoad(index)}
                          />
                        </span>
                      </div>
                      <div
                        className="nft__item_info all-collections"
                        onClick={() =>
                          history.push(
                            `/collection/${collection?.collectionName}`,
                            "_self"
                          )
                        }
                      >
                        <span>
                          <h4 className="collection collectionName">
                            {collection.collectionName}
                          </h4>
                        </span>
                        <div className="nft__item_desc collection tokenDescription">
                          {collection?.tokenDescription}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <h5 style={{ textAlign: "center", margin: "40px 20px" }}>
          Please login to see the collections!
        </h5>
      )}
    </>
  );
};

export default UserCollections;