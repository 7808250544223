import React, {useState, useEffect} from 'react';
import { Select } from 'antd';
import moment from 'moment';
import 'moment-timezone';
import { environment } from '../environments/environment';
import { NFTCreatorService } from './services/NFTCreator/NFTCreator.service';

const nftService = new NFTCreatorService();

const DateTime = ({ hours, minutes, setHours, setMinutes, expirationDate }) => {
    const sameTimeZone = new Date().getTimezoneOffset() == environment.TimeOffset;

  return (<>
      <Select
        placeholder="Hours"
        optionFilterProp="children"
        onChange={(val)=> setHours(val)}
        filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
        }
        value={hours}
        defaultValue={hours}
        options={[
        { label: '00', value: '00' },
        { label: '01', value: '01' },
        { label: '02', value: '02' },
        { label: '03', value: '03' },
        { label: '04', value: '04' },
        { label: '05', value: '05' },
        { label: '06', value: '06' },
        { label: '07', value: '07' },
        { label: '08', value: '08' },
        { label: '09', value: '09' },
        { label: '10', value: '10' },
        { label: '11', value: '11' },
        { label: '12', value: '12' },
        { label: '13', value: '13' },
        { label: '14', value: '14' },
        { label: '15', value: '15' },
        { label: '16', value: '16' },
        { label: '17', value: '17' },
        { label: '18', value: '18' },
        { label: '19', value: '19' },
        { label: '20', value: '20' },
        { label: '21', value: '21' },
        { label: '22', value: '22' },
        { label: '23', value: '23' },
        { label: '24', value: '24' },
        ]}
        style={{marginRight: '20px'}}
    />

    <Select
        placeholder="Minutes"
        optionFilterProp="children"
        onChange={(val)=> setMinutes(val)}
        filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
        }
        value={minutes}
        defaultValue={minutes}
        options={[
        { label: '00', value: '00' },
        { label: '05', value: '05' },
        { label: '10', value: '10' },
        { label: '15', value: '15' },
        { label: '20', value: '20' },
        { label: '25', value: '25' },
        { label: '30', value: '30' },
        { label: '35', value: '35' },
        { label: '40', value: '40' },
        { label: '45', value: '45' },
        { label: '50', value: '50' },
        { label: '55', value: '55' },
        ]}
    />
    {/* {!sameTimeZone && expirationDate && (<>
        <p style={{margin: '10px 0'}}>Real Auction EndTime in EST(America/New_York) will be: { hours && minutes && (<b>{moment(new Date(nftService.convertToServerTimeZoneAndGetTimestamp(new Date(new Date(expirationDate).setHours(hours, minutes, 0)).getTime()))).format(environment.displayTimeFormat)}</b>)}</p>
    </>)} */}
  </>
  )
}

export default DateTime