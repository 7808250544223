import React, { useState, useEffect, useContext } from "react";
import SliderCarouselRedux from "../components/SliderCarouselRedux";
import CarouselCollectionRedux from "../components/CarouselCollectionRedux";
import CarouselNewRedux from "../components/CarouselNewRedux";
import AuthorListRedux from "../components/AuthorListRedux";
import { useHistory, Link } from "react-router-dom";
import Footer from "../components/footer-old";
import { createGlobalStyle } from "styled-components";
import { HomeService } from "../components/services/home/home.service";
import { NFTCreatorService } from "../components/services/NFTCreator/NFTCreator.service";
import authContext from "../components/Shared/Auth.service";
import { notification } from "antd";

const homeService = new HomeService();
const nftService = new NFTCreatorService();

const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar .search #quick_search{
    background: rgba(255, 255, 255, .1);
  }
  header#myHeader .logo .d-block{
    display: none !important;
  }
  header#myHeader .logo .d-none{
    display: none !important;
  }
  header#myHeader .logo .d-3{
    display: block !important;
  }
  footer.footer-light .subfooter span img.d-1{
    display: none !important;
  }
  footer.footer-light .subfooter span img.d-3{
    display: inline-block !important;
  }
  .de_countdown{
    right: 10px;
  }
  .author_list_pp{
    margin-left:0;
  }
  footer.footer-light .subfooter{
    border-top: 1px solid rgba(255,255,255,.1);
  }
`;

const HomeNew = () => {
  const { userInfo, setUserInfo } = useContext(authContext);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [nftCategories, setNftCategories] = useState([]);

  const history = useHistory();

  useEffect(() => {
    if (userInfo?.role === "marketplace-super-admin") {
      history.push("/marketplace-admin/super-admin");
    }
  }, [userInfo]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      // setCategories((await homeService.getCategories()).data);
      //getting categories
      let res = await nftService.getAllCategories();
      if (res.data?.length > 0) {
        setNftCategories(res.data);
      } else {
        console.error("can not get categories");
        notification.open({
          message: "Error",
          description:
            "Error while tries to get categories, Please try after some time.",
          duration: 0,
        });
      }

      setLoading(false);
    })();
  }, []);

  return (
    <div>
      <section
        className="home_bg_banner-old"
        style={{
          width: "100%",
          backgroundSize: "cover",
          height: "700px",
          backgroundImage: 'url("./img/home/Banner.png")',
        }}
      >
        <div className="container">
          <div className="d-flex align-items-d-flex align-content-between flex-wrap">
            <div className="col-6 position-relative">
              <img className="home_nft_text" src="./img/home/Group3.png" />
              <p className="text_trade">
                Trade, create and explore most wanted digital assets.
              </p>
              <div className="d-flex position-relative">
                <img className="line_img" src="./img/home/line.png" />
                <button type="button" className="full_btn me-3">
                  Get Started
                </button>
                <button type="button" className="btn_outline">
                  Explore
                </button>
              </div>
            </div>
            <div className="col-6">
              <img className="nftArt" src="./img/home/NFTArt.png" />
            </div>
          </div>
        </div>
      </section>

      <div className="container-fluid collection">
        <div className="col-12 text-center mt-3">
          <h2 className="style-2 mb-0">Collections</h2>
          <p>Most popular gaming digital nft market place</p>
          <div className="d-flex justify-content-center">
            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="pills-home-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-home"
                  type="button"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected="true"
                >
                  Trending
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="pills-profile-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-profile"
                  type="button"
                  role="tab"
                  aria-controls="pills-profile"
                  aria-selected="false"
                >
                  Top
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <p>Collections</p>
          </div>
          <div className="col-6">
            <button type="button" className="btn_view_all float-end">
              VIEW ALL
            </button>
          </div>
        </div>
        <hr />

        <div className="tab-content" id="pills-tabContent">
          <div
            className="tab-pane fade show active"
            id="pills-home"
            role="tabpanel"
            aria-labelledby="pills-home-tab"
            tabIndex="0"
          >
            <div className="row">
              <div className="col-3">
                <div className="card mb-3 border-0">
                  <div className="row g-0">
                    <div className="col-md-4 position-relative">
                      <img
                        src="./img/home/Rectangle1172.png"
                        className="img-fluid rounded-start float-end"
                        alt=""
                      />
                      <img
                        src="./img/home/Frame1520.png"
                        className="tick_mark"
                        alt=""
                      />
                    </div>
                    <div className="col-md-8">
                      <div className="card-body">
                        <p className="card-text text-dark">
                          Lin Jingjing - Before the Thunder Rolls
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-9">
                <div className="d-flex mb-4">
                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle67.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>
                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle77.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>
                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle97.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>
                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle07.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>
                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle67.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>

                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle67.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>
                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle97.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>
                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle07.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>
                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle67.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-3">
                <div className="card mb-3 border-0">
                  <div className="row g-0">
                    <div className="col-md-4 position-relative">
                      <img
                        src="./img/home/Rectangle1172.png"
                        className="img-fluid rounded-start float-end"
                        alt=""
                      />
                      <img
                        src="./img/home/Frame1520.png"
                        className="tick_mark"
                        alt=""
                      />
                    </div>
                    <div className="col-md-8">
                      <div className="card-body">
                        <p className="card-text text-dark">
                          Lin Jingjing - Before the Thunder Rolls
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-9">
                <div className="d-flex mb-4">
                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle67.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>
                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle77.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>
                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle97.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>
                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle07.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>
                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle67.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>

                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle67.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>
                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle97.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>
                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle07.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>
                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle67.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-3">
                <div className="card mb-3 border-0">
                  <div className="row g-0">
                    <div className="col-md-4 position-relative">
                      <img
                        src="./img/home/Rectangle1172.png"
                        className="img-fluid rounded-start float-end"
                        alt=""
                      />
                      <img
                        src="./img/home/Frame1520.png"
                        className="tick_mark"
                        alt=""
                      />
                    </div>
                    <div className="col-md-8">
                      <div className="card-body">
                        <p className="card-text text-dark">
                          Lin Jingjing - Before the Thunder Rolls
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-9">
                <div className="d-flex mb-4">
                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle67.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>
                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle77.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>
                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle97.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>
                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle07.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>
                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle67.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>

                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle67.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>
                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle97.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>
                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle07.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>
                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle67.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-3">
                <div className="card mb-3 border-0">
                  <div className="row g-0">
                    <div className="col-md-4 position-relative">
                      <img
                        src="./img/home/Rectangle1172.png"
                        className="img-fluid rounded-start float-end"
                        alt=""
                      />
                      <img
                        src="./img/home/Frame1520.png"
                        className="tick_mark"
                        alt=""
                      />
                    </div>
                    <div className="col-md-8">
                      <div className="card-body">
                        <p className="card-text text-dark">
                          Lin Jingjing - Before the Thunder Rolls
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-9">
                <div className="d-flex mb-4">
                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle67.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>
                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle77.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>
                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle97.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>
                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle07.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>
                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle67.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>

                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle67.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>
                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle97.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>
                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle07.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>
                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle67.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-3">
                <div className="card mb-3 border-0">
                  <div className="row g-0">
                    <div className="col-md-4 position-relative">
                      <img
                        src="./img/home/Rectangle1172.png"
                        className="img-fluid rounded-start float-end"
                        alt=""
                      />
                      <img
                        src="./img/home/Frame1520.png"
                        className="tick_mark"
                        alt=""
                      />
                    </div>
                    <div className="col-md-8">
                      <div className="card-body">
                        <p className="card-text text-dark">
                          Lin Jingjing - Before the Thunder Rolls
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-9">
                <div className="d-flex mb-4">
                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle67.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>
                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle77.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>
                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle97.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>
                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle07.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>
                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle67.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>

                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle67.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>
                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle97.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>
                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle07.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>
                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle67.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="tab-pane fade"
            id="pills-profile"
            role="tabpanel"
            aria-labelledby="pills-profile-tab"
            tabIndex="0"
          >
            <div className="row">
              <div className="col-3">
                <div className="card mb-3 border-0">
                  <div className="row g-0">
                    <div className="col-md-4 position-relative">
                      <img
                        src="./img/home/Rectangle1172.png"
                        className="img-fluid rounded-start float-end"
                        alt=""
                      />
                      <img
                        src="./img/home/Frame1520.png"
                        className="tick_mark"
                        alt=""
                      />
                    </div>
                    <div className="col-md-8">
                      <div className="card-body">
                        <p className="card-text text-dark">
                          Lin Jingjing - Before the Thunder Rolls
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-9">
                <div className="d-flex mb-4">
                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle67.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>
                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle77.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>
                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle97.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>
                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle07.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>
                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle67.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>

                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle67.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>
                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle97.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>
                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle07.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>
                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle67.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-3">
                <div className="card mb-3 border-0">
                  <div className="row g-0">
                    <div className="col-md-4 position-relative">
                      <img
                        src="./img/home/Rectangle1172.png"
                        className="img-fluid rounded-start float-end"
                        alt=""
                      />
                      <img
                        src="./img/home/Frame1520.png"
                        className="tick_mark"
                        alt=""
                      />
                    </div>
                    <div className="col-md-8">
                      <div className="card-body">
                        <p className="card-text text-dark">
                          Lin Jingjing - Before the Thunder Rolls
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-9">
                <div className="d-flex mb-4">
                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle67.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>
                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle77.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>
                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle97.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>
                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle07.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>
                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle67.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>

                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle67.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>
                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle97.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>
                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle07.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>
                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle67.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-3">
                <div className="card mb-3 border-0">
                  <div className="row g-0">
                    <div className="col-md-4 position-relative">
                      <img
                        src="./img/home/Rectangle1172.png"
                        className="img-fluid rounded-start float-end"
                        alt=""
                      />
                      <img
                        src="./img/home/Frame1520.png"
                        className="tick_mark"
                        alt=""
                      />
                    </div>
                    <div className="col-md-8">
                      <div className="card-body">
                        <p className="card-text text-dark">
                          Lin Jingjing - Before the Thunder Rolls
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-9">
                <div className="d-flex mb-4">
                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle67.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>
                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle77.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>
                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle97.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>
                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle07.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>
                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle67.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>

                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle67.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>
                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle97.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>
                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle07.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>
                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle67.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-3">
                <div className="card mb-3 border-0">
                  <div className="row g-0">
                    <div className="col-md-4 position-relative">
                      <img
                        src="./img/home/Rectangle1172.png"
                        className="img-fluid rounded-start float-end"
                        alt=""
                      />
                      <img
                        src="./img/home/Frame1520.png"
                        className="tick_mark"
                        alt=""
                      />
                    </div>
                    <div className="col-md-8">
                      <div className="card-body">
                        <p className="card-text text-dark">
                          Lin Jingjing - Before the Thunder Rolls
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-9">
                <div className="d-flex mb-4">
                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle67.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>
                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle77.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>
                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle97.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>
                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle07.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>
                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle67.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>

                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle67.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>
                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle97.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>
                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle07.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>
                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle67.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-3">
                <div className="card mb-3 border-0">
                  <div className="row g-0">
                    <div className="col-md-4 position-relative">
                      <img
                        src="./img/home/Rectangle1172.png"
                        className="img-fluid rounded-start float-end"
                        alt=""
                      />
                      <img
                        src="./img/home/Frame1520.png"
                        className="tick_mark"
                        alt=""
                      />
                    </div>
                    <div className="col-md-8">
                      <div className="card-body">
                        <p className="card-text text-dark">
                          Lin Jingjing - Before the Thunder Rolls
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-9">
                <div className="d-flex mb-4">
                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle67.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>
                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle77.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>
                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle97.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>
                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle07.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>
                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle67.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>

                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle67.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>
                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle97.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>
                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle07.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>
                  <div className="position-relative me-3">
                    <img src="./img/home/Rectangle67.png" />
                    <p className="crypto_text">1.14 ETH</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section
        className="home_bg_banner-old"
        style={{
          width: "100%",
          backgroundSize: "cover",
          height: "auto",
          backgroundImage: 'url("./img/home/Banner.png")',
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-4 d-flex align-items-center">
              <div>
                <h2 className="style-2 yellow mb-0">New NFT</h2>
                <p className="text-white mb-3">
                  Lorem ipsum dolor sit amet consectetur Lorem ipsum dolor sit
                  amet consectetur
                </p>
                <p>
                  Lorem ipsum dolor sit amet consectetur Lorem ipsum dolor sit
                  amet consectetur
                </p>
                <button type="button" className="full_btn ms-0 mt-3">
                  Get Started
                </button>
              </div>
            </div>
            <div className="col-8">
              <div
                id="carouselExampleControls"
                className="carousel slide"
                data-ride="carousel"
                data-type="multi"
                data-interval="false"
              >
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <div className="row">
                      <div className="col-4">
                        <div className="card">
                          <img
                            src="./img/home/Rectangle14.png"
                            className="card-img-top"
                            alt=""
                          />
                          <div className="card-body nft_card">
                            <h5 className="card-title text-white text-uppercase">
                              horse video auction
                            </h5>
                            <div className="d-flex grey">
                              <div className="p-2 flex-grow-1">
                                <p className="mb-0 text-muted">Method:</p>
                                <h4 className="mb-0 text-white fw-lighter">
                                  Auction
                                </h4>
                              </div>
                              <div className="p-2">
                                <p className="mb-0 text-muted">Min Price:</p>
                                <h4 className="mb-0 text-white fw-lighter">
                                  0.0001 ETH
                                </h4>
                              </div>
                            </div>
                            <div className="d-flex grey">
                              <div className="p-2 flex-grow-1">
                                <p className="mb-0 text-muted">
                                  Min Price in USD:
                                </p>
                                <h4 className="mb-0 text-white fw-lighter">
                                  0.180 $
                                </h4>
                              </div>
                            </div>
                            <div className="col-12 text-center mt-3">
                              <a type="button" className="btn_view_all">
                                place a bid
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="card">
                          <img
                            src="./img/home/Rectangle14.png"
                            className="card-img-top"
                            alt=""
                          />
                          <div className="card-body nft_card">
                            <h5 className="card-title text-white text-uppercase">
                              meme-nft
                            </h5>
                            <div className="d-flex grey">
                              <div className="p-2 flex-grow-1">
                                <p className="mb-0 text-muted">Method:</p>
                                <h4 className="mb-0 text-white fw-lighter">
                                  Open for bid
                                </h4>
                              </div>
                            </div>
                            <div className="col-12 text-center mt-3">
                              <a type="button" className="btn_view_all">
                                Place a bid
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="card">
                          <img
                            src="./img/home/Rectangle14.png"
                            className="card-img-top"
                            alt=""
                          />
                          <div className="card-body nft_card">
                            <h5 className="card-title text-white text-uppercase">
                              cat gif
                            </h5>
                            <div className="d-flex grey">
                              <div className="p-2 flex-grow-1">
                                <p className="mb-0 text-muted">Method:</p>
                                <h4 className="mb-0 text-white fw-lighter">
                                  Fixed Price
                                </h4>
                              </div>
                              <div className="p-2">
                                <p className="mb-0 text-muted">Min Price:</p>
                                <h4 className="mb-0 text-white fw-lighter">
                                  0.005 ETH
                                </h4>
                              </div>
                            </div>
                            <div className="d-flex grey">
                              <div className="p-2 flex-grow-1">
                                <p className="mb-0 text-muted">
                                  Min Price in USD:
                                </p>
                                <h4 className="mb-0 text-white fw-lighter">
                                  8.980 $
                                </h4>
                              </div>
                            </div>
                            <div className="col-12 text-center mt-3">
                              <a type="button" className="btn_view_all">
                                Custom button
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#carouselExampleControls"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#carouselExampleControls"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
        </div>
      </section>
      <section className="third_section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center mt-4">
              <h2 className="style-2">Browse by Categoryy</h2>
              <p>
                Lorem ipsum dolor sit amet consectetur. Congue donec ac varius
                pellentesque velit sem.
              </p>
              <div className="small-border"></div>
            </div>
            <div className="col-3 mb-4">
              <div className="card">
                <img
                  src="./img/home/Rectangle19.png"
                  className="card-img-top"
                  alt=""
                />
                <div className="card-body">
                  <h5 className="card-title text-center">Photo</h5>
                </div>
              </div>
            </div>
            <div className="col-3 mb-4">
              <div className="card">
                <img
                  src="./img/home/Rectangle17.png"
                  className="card-img-top"
                  alt=""
                />
                <div className="card-body">
                  <h5 className="card-title text-center">Avatar</h5>
                </div>
              </div>
            </div>
            <div className="col-3 mb-4">
              <div className="card">
                <img
                  src="./img/home/Rectangle21.png"
                  className="card-img-top"
                  alt=""
                />
                <div className="card-body">
                  <h5 className="card-title text-center">Sports</h5>
                </div>
              </div>
            </div>
            <div className="col-3 mb-4">
              <div className="card">
                <img
                  src="./img/home/Rectangle22.png"
                  className="card-img-top"
                  alt=""
                />
                <div className="card-body">
                  <h5 className="card-title text-center">Video</h5>
                </div>
              </div>
            </div>

            <div className="col-3 mb-4">
              <div className="card">
                <img
                  src="./img/home/Rectangle9.png"
                  className="card-img-top"
                  alt=""
                />
                <div className="card-body">
                  <h5 className="card-title text-center">Memes</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="second_section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center mt-4">
              <h2 className="style-2">NFT 101</h2>
              <p className="card-text">
                Some quick example text to build on the card title and make up
                the bulk of the card's content.
              </p>
              <div className="small-border"></div>
            </div>
            <div className="col-4">
              <div className="card">
                <img
                  src="./img/home/Rectangle1.png"
                  className="card-img-top"
                  alt=""
                />
                <div className="card-body">
                  <h5 className="card-title">What is an NFT?</h5>
                  <p className="card-text">
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="card">
                <img
                  src="./img/home/Rectangle18.png"
                  className="card-img-top"
                  alt=""
                />
                <div className="card-body">
                  <h5 className="card-title">What is a crypto wallet?</h5>
                  <p className="card-text">
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="card">
                <img
                  src="./img/home/Rectangle9.png"
                  className="card-img-top"
                  alt=""
                />
                <div className="card-body">
                  <h5 className="card-title">What are blockchain gas fees?</h5>
                  <p className="card-text">
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 text-center">
              <button type="button" className="btn_view_all">
                VIEW ALL
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HomeNew;
