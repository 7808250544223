import React from "react";
import './learn.scss';

const Learn3 = () => {
  return (
    <div className="container learn-container">
      <h3 class="blog-heading">How to create an NFT</h3>
      <img className="blog-image horizontal-middle-align" src={`/img/learns/learnPageIcon3.png`} alt="learn-img"
      /*  style={{ width: "600px", height: "550px" }} */ />
      <p>
        After completing the registration and KYC process, and Whitelisting
        process the Creator can start the NFT Creation
      </p>
      <p>
        When a user wants to create an NFT, they can select the "Create" option
        and input all the necessary details such as category and preferred
        selling method - whether it be Fixed Price, Auction, or Open for Bids.
      </p>
      <p>
        <strong>For Fixed Price:</strong>
      </p>
      <ul>
        <li>NFT Creator enters the price in ETH.</li>
      </ul>
      <p>
        <strong>For Auction:</strong>
      </p>
      <ul>
        <li>
          NFT Creator specifies the minimum bid, starting date, and expiration
          date.
        </li>
      </ul>
      <p>
        <strong>For Open for Bids:</strong>
      </p>
      <ul>
        <li>
          NFT Creator specifies the time in days within which the buyer must
          complete the final NFT purchase after winning a bid or auction.
        </li>
      </ul>
      <p>
        When it comes to creating NFTs, it's important to consider various
        factors such as royalties&rsquo; percentage, properties, and
        collections. These details are crucial for the NFT Creator to specify
        their selling method and add extra attributes to their creation. Moving
        forward into the next section, more information on these aspects can be
        found.
      </p>
      <p>
        To create a new NFT collection, follow these simple steps: First, click
        on the "Create (ERC 721)" button. This will open a pop-up titled "Add
        Collection (ERC 721)". Here, you can browse and upload a thumbnail image
        for the collection. Then, provide the collection name, token name and
        description to complete the creation process. Once completed
        successfully, you will receive a confirmation message displaying your
        new collection's name.
      </p>
      <h4>To Create a New Collection: </h4>
      <p><strong>To create a new NFT collection, follow these simple steps:</strong></p>
      <ul>
        <li>
          First, click on the "<strong>Create (ERC 721)"</strong> button. This
          will open a pop-up titled <strong>"Add Collection</strong> (ERC 721)".
          Here, you can browse and upload a thumbnail image for the collection.
          Then, provide the collection name, token name and description to
          complete the creation process. Once completed successfully, you will
          receive a confirmation message displaying your new collection's name.
        </li>
        <li>
          As an NFT Creator, you have the option of adding a copyright notice and
          watermark to your file. Just click on the <strong>"Save" button</strong>{" "}
          to save these changes.
        </li>
        <li>
          Afterwards, make sure that you check the captcha checkbox before
          proceeding to pay for collection fees by{" "}
          <strong>clicking on "Confirm".</strong>
        </li>
        <li>
          Your newly created NFT collection will be saved under{" "}
          <strong>"Drafted NFT".</strong> To view it, simply navigate to the{" "}
          <strong>"User"</strong> option and select <strong>"Profile".</strong>{" "}
          Click on the "Drafted NFT" section for further operations.
        </li>
        <li>
          Nextis placing your submission for admin verification by clicking on{" "}
          <strong>&ldquo;Place for Admin Verification</strong>&rdquo;. An email
          will then be sent to confirm receipt of your submission. The Marketplace
          Token Admin will carefully review all details provided.
        </li>
        <li>
          You can keep track of your NFT status in your profile under &ldquo;NFT
          Pending Approval from Marketplace Admin&rdquo;. Once approved by
          Marketplace Token Admin upon reviewing all details provided in-depth,
          you'll receive an email confirming successful verification of your NFT!
        </li>
      </ul>
    </div>
  );
};

export default Learn3;
