import React from "react";
import './learn.scss';

const Learn5 = () => {
  return (
    <div className="container learn-container">
      <h3 class="blog-heading">How does a Buyer purchase an NFT using the Fixed Price method Purchase of NFT by Buyer using Fixed Price</h3>
      <img className="blog-image horizontal-middle-align" src={`/img/learns/learnPageIcon5.png`} alt="learn-img"
     /*   style={{ width: "600px", height: "550px" }} */ />
      <h4>To procure an NFT through the fixed price method, the buyer must
          adhere to specific steps.{" "}</h4>
      <ul>
        <li>
          The first step involves registering on the platform and completing the
          KYC process, including wallet address whitelisting.
        </li>
        <li>
          Once that's done, the buyer can browse through all available NFTs by
          clicking on either the "Market" or "Explore" section and selecting a
          particular NFT before clicking on "Buy Now."
        </li>
        <li>
          A new page will open up with detailed information about the NFT, such
          as its name, collection name, method of sale and price. After
          reviewing this information, a "Buy Now" button is displayed for making
          payments.
        </li>
        <li>
          After pressing this button, buyers are required to sign an agreement
          by providing their name, address and contact number before receiving
          an email confirmation.
        </li>
        <li>
          The creator of the NFT also receives an email notifying them of the
          successful signing of this agreement along with details like buyer's
          name and purchased NFT's name.
        </li>
        <li>
          Once payment is made successfully for buying an NFT using fixed price
          method, a confirmation email titled &ldquo;Successful Purchase of
          NFT&rdquo; will be sent to your mail. Additionally, upon successfully
          selling that particular NFT in future, the creator is notified via
          email about successful transaction.
        </li>
        <li>
          If you wish to view your purchased asset then you can go to profile
          page where under &ldquo;NFT Bought&rdquo; option you can view your
          purchase history. By selecting any one from it you can explore all
          relevant information about that asset including its owner&rsquo;s
          details and creator's credentials.
        </li>
        <li>
          By following these guidelines meticulously one can easily own their
          desired Non-Fungible Token (NFT) without any hassle.
        </li>
      </ul>
    </div>
  );
};

export default Learn5;
