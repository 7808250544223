import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./../pages/homePage.scss";
import { carouselNew } from "../components/constants";
import NFTCard from "../components/NFTCardComponent";
import "../pages/Profile/index.css";
import { Avatar } from "antd";
import NoDataImg from "./../assets/images/profile/Mask group.png";

export const NftDrafted = (props) => {
  const { nftDraftArr = [], sliderPoints=carouselNew, todayEtheriumPriceInUSD, noNftText = "", fromTab = false, nftCardBtnText="", draftedNft=false} = props || {};

  return <div className="col-12 profile-section render-tabs" style={{ background: fromTab ? "#FFFFFF" : "transparent" }}>
    <div className="home-page slider-container nft-dashboard" style={{ background: fromTab ? "#FFFFFF" : "#f2f2f2" }}>

      {
        nftDraftArr.length > 0 ?
        <Slider {...sliderPoints} >
            {nftDraftArr.length > 0 &&
              nftDraftArr.map((nft, index) => (
                <div className="itm col-xl-3 col-lg-4 col-md-6 col-sm-12" key={index}>
                  <div className="d-item">
                    <div
                      className={`nft__item nft-item-buy`}
                      style={{
                        margin: "3px",
                        padding: "3px",
                        border: "none",
                        backgroundColor: "transparent",
                      }}
                    >
                      <NFTCard
                        nft={nft}
                        nftCardBtnText={!draftedNft ? nftCardBtnText : nft?.adminVerificationStatus==="approved" ? 'Approved': nftCardBtnText}
                        todayEtheriumPriceInUSD={
                          todayEtheriumPriceInUSD
                        }
                      />
                    </div>
                  </div>
                </div>
              ))}
          </Slider>
          : <div className="itm col-xl-12 col-lg-12 col-md-12 col-sm-12 profile-div no-nft-found-div">
            <div className="d-item">
              <div
                className={`d-flex nft__item nft-item-buy justify-content-center align-items-center`}
                style={{
                  margin: "3px",
                  padding: "3px",
                  border: "none",
                  backgroundColor: "transparent",
                }}
              >
                <div className="profile-div avatar col-lg-3 col-md-6 col-sm-12">
                  <Avatar className="profile-div avatar-image" src={NoDataImg} style={{ backgroundColor: '#D9D9D980' }}></Avatar>
                  <p className="profile-div noNftText">{noNftText}</p>
                </div>
              </div>
            </div>
          </div>
      }

    </div>
  </div>
}