import React, { useContext, useState, useEffect } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import styled from "styled-components";
import { Spin, notification, Input, Button, Popconfirm } from "antd";
import Clock from "../components/Clock";
import { createGlobalStyle } from "styled-components";
import authContext from "../components/Shared/Auth.service";
import { NFTCreatorService } from "../components/services/NFTCreator/NFTCreator.service";
import { VideoPlayer } from "./../components/mainSlider/VideoPlayer";
import ProfilePic from "./../assets/images/home/profile-default.png";
import { SharedService } from "./../components/Shared/Shared.service";
import "./styles/styles.scss";
import NFTCard from "../components/NFTCardComponent";
import { MetamaskService } from "../components/Shared/Metamask.service";

const nftService = new NFTCreatorService();
const sharedService = new SharedService();

const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.white {
    background: #FAF6F1;
    border-bottom: solid 1px #ccc !important;
  }
`;

const Outer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: 260px;
  overflow: hidden;
  border-radius: 8px;
`;

const useSelectedWalletContext = () =>
  new MetamaskService().useSelectedWalletContext();

const CategoryDetail = function () {
  const { userInfo, setUserInfo } = useContext(authContext);

  const [CategoryDetails, setCategoryDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [todayEtheriumPriceInUSD, setTodayEtheriumPriceInUSD] = useState();
  const { networkId } = useSelectedWalletContext();

  const { categoryName } = useParams();

  const history = useHistory();

  useEffect(() => {
    if(!networkId) return;
    setTimeout(async () => {
      if (categoryName) {
        setLoading(true);
        let res = await nftService.getCategorisedNFT(categoryName, {networkId});
        if (res.data) setCategoryDetails(res.data);

        setLoading(false);
      }
      //setting ether price
      setTodayEtheriumPriceInUSD(await sharedService.getEtheriumPrice());
    }, 300);
  }, [userInfo, networkId]);

  return (
    <>
      {loading && (
        <div
          className="center"
          style={{ textAlign: "center", margin: "100px 0" }}
        >
          <Spin size="large" />
        </div>
      )}
      {!loading && CategoryDetails?.length > 0 && categoryName && (
        <div className="row">
          <h3
            className="title test"
            style={{ "margin-top": "100px" }}
          >{`${categoryName} NFTs`}</h3>
          <div className="row col-12" style={{ padding: "0 20px" }}>
            {CategoryDetails.map((nft, index) => (
              <div
                className="itm col-xl-3 col-lg-4 col-md-6 col-sm-12"
                index={index + 1}
                key={index}
              >
                <div className="d-item">
                  <div className="nft__item">
                    <NFTCard
                      nft={nft}
                      buy={true}
                      todayEtheriumPriceInUSD={todayEtheriumPriceInUSD}
                    />
                  </div>
                </div>
              </div>
            ))}
            {/* for testing purpose */}
            {/* {indents} */}
          </div>
        </div>
      )}
      {!loading &&
        (!CategoryDetails || CategoryDetails?.length < 1) &&
        categoryName && (
          <div className="row">
            <h5 style={{ textAlign: "center" }}>
              No NFT found under this Category
            </h5>
          </div>
        )}
    </>
  );
};
export default CategoryDetail;
