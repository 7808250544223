import {  Card, Col, Form, Input, Row, notification } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { AdminRolesService } from "../../../../components/Shared/Blockchain/AdminRoles/AdminRoles.service";
import authContext from "../../../../components/Shared/Auth.service";
import { MetamaskService } from "../../../../components/Shared/Metamask.service";
import { AdminService } from "../../../../components/services/AdminService/Admin.service";

const adminWalletUpdateStyleing = {
  container: {
    backgroundColor: "#FFFFFF",
    borderRadius: "14px",
    boxShadow: "0px 4px 4px 0px #00000040",
    padding: "10% 10% 20% 10%",
    margin: "20px",
  },
  card: {
    backgroundColor: "#FFF4D2",
    borderRadius: "14px",
  },
  input: {
    width: "100%",
    height: "74px",
  },
  buttonCol: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
  },
  button: {
    width: "148px",
    height: "74px",
    borderRadius: "7px",
    background: "#1BB9CE",
    fontFamily: "Poppins",
    fontSize: "24px",
    fontWeight: "700",
    marginRight: "8px",
    color: "#FFFFFF",
  },
};

const adminRolesService = new AdminRolesService();
const useSelectedWalletContext = () =>
  new MetamaskService().useSelectedWalletContext();
const adminService = new AdminService();

const AdminWalletUpdate = ({
  loading,
  setLoading,
  smartContractData,
}) => {
  const [form1] = Form.useForm();
  const [form2] = Form.useForm();
  const { networkId } = useSelectedWalletContext();
  const { userInfo } = useContext(authContext);

  const [oldMarketplaceTokenAdminWallet, setOldMarketplaceTokenAdminWallet] =
    useState("");
  const [marketplaceTokenAdminWallet, setMarketplaceTokenAdminWallet] =
    useState("");
  const [
    oldMarketplaceComplianceAdminWallet,
    setOldMarketplaceComplianceAdminWallet,
  ] = useState("");
  const [
    marketplaceComplianceAdminWallet,
    setMarketplaceComplianceAdminWallet,
  ] = useState("");

  const handleBtnClickAdminWallet = async () => {
    //get golden badge data
    setLoading(true);
    try {
      let res1 = await adminService.getMarketplaceTokenAdminWallet();
      if (res1.data?.length > 0) {
        // setMarketplaceTokenAdminWallet(res1.data);
        setOldMarketplaceTokenAdminWallet(res1.data);
      }

      let res2 = await adminService.getMarketplaceComplianceAdminWallet();
      if (res2.data?.length > 0) {
        // setMarketplaceComplianceAdminWallet(res2.data);
        setOldMarketplaceComplianceAdminWallet(res2.data);
      }
    } catch (error) {
      console.log(error);
      notification.open({
        message: "Error",
        description: "something went wrong while fetching golden badge data",
        duration: 0,
      });
    }
    setLoading(false);
  };

  

  const updateMarketplaceTokenAdminWallet = async () => {
    const tokenAdminWallet = form1.getFieldsValue()
    setMarketplaceTokenAdminWallet(tokenAdminWallet.marketplaceTokenAdminWallet)
    setLoading(true);
    try {
      const txnReceipt = await adminRolesService.changeMTAdmin(
        String(oldMarketplaceTokenAdminWallet),
        String(tokenAdminWallet.marketplaceTokenAdminWallet),
        String(userInfo?.walletAddress),
        String(smartContractData?.adminRolesContract)
      );

      console.log("Blockchain Transaction receipt: ", txnReceipt);
      if (txnReceipt) {
        let form = {
          mtaWalletAddress: tokenAdminWallet.marketplaceTokenAdminWallet,
          MSAWalletAddress: String(userInfo?.walletAddress),
          transactionDetail: txnReceipt,
          networkId,
        };
        let res2 = await adminService.setMarketplaceTokenAdminWallet(form);
        if (res2.data) {
          notification.open({
            message:
              "Successfully updated Marketplace Token Admin Wallet Address",
            duration: 0,
          });
          setLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
      notification.open({
        message: "Error",
        description: "something went wrong while updating wallet address",
        duration: 0,
      });
    }
  };

  const updateMarketplaceComplianceAdminWallet = async () => {
    const ComplianceAdminWallet = form2.getFieldsValue();
    setMarketplaceComplianceAdminWallet(ComplianceAdminWallet.marketplaceComplianceAdminWallet)
    setLoading(true);
    try {
      const txnReceipt = await adminRolesService.changeMCAdmin(
        String(oldMarketplaceComplianceAdminWallet),
        String(ComplianceAdminWallet.marketplaceComplianceAdminWallet),
        String(userInfo?.walletAddress),
        String(smartContractData?.adminRolesContract)
      );

      console.log("Blockchain Transaction receipt: ", txnReceipt);
      if (txnReceipt) {
        let form = {
          mcaWalletAddress: ComplianceAdminWallet.marketplaceComplianceAdminWallet,
          MSAWalletAddress: String(userInfo?.walletAddress),
          transactionDetail: txnReceipt,
          networkId,
        };
        let res2 = await adminService.setMarketplaceComplianceAdminWallet(form);
        if (res2.data) {
          notification.open({
            message:
              "Successfully updated Marketplace Compliance Admin Wallet Address",
            duration: 0,
          });
          setLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
      notification.open({
        message: "Error",
        description: "something went wrong while updating wallet address",
        duration: 0,
      });
    }
  };

useEffect(() => {
    form1.setFieldsValue({
      marketplaceTokenAdminWallet: oldMarketplaceTokenAdminWallet,
    });
    form2.setFieldsValue({
      marketplaceComplianceAdminWallet: oldMarketplaceComplianceAdminWallet,
    });
    handleBtnClickAdminWallet();
  }, [oldMarketplaceTokenAdminWallet, oldMarketplaceComplianceAdminWallet]);

  return (
    !loading && <>
      <p className="market-super-admin commission-configuration-text">
        Admin Wallets Updates
      </p>
      <div style={adminWalletUpdateStyleing.container}>
        <Card style={adminWalletUpdateStyleing.card}>
          <Form
            form={form1}
            layout="vertical"
            onFinish={updateMarketplaceTokenAdminWallet}
          >
            <Row>
              <Col sm={24} md={24} lg={24} xl={18} xxl={18}>
                <Form.Item
                  label="Marketplace Token Admin Wallet Address"
                  name="marketplaceTokenAdminWallet"
                  rules={[
                    {
                      required: true,
                      message:
                        "Please input Marketplace Token Admin Wallet Address",
                    },
                  ]}
                  style={{ width: "100%" }}
                >
                  <Input
                    placeholder="Enter Marketplace Token Admin Wallet Address "
                    style={adminWalletUpdateStyleing.input}
                  />
                </Form.Item>
              </Col>
              <Col
                sm={24}
                md={24}
                lg={24}
                xl={{ span: 4, offset: 2 }}
                xxl={{ span: 4, offset: 2 }}
                style={adminWalletUpdateStyleing.buttonCol}
              >
                <Form.Item>
                  <button
                    type="submit"
                    // loading={loading}
                    style={adminWalletUpdateStyleing.button}
                  >
                    Update
                  </button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Form
            form={form2}
            layout="vertical"
            onFinish={updateMarketplaceComplianceAdminWallet}
          >
            <Row>
              <Col sm={24} md={24} lg={24} xl={18} xxl={18}>
                <Form.Item
                  label="Marketplace Compliance Admin Wallet Address"
                  name="marketplaceComplianceAdminWallet"
                  rules={[
                    {
                      required: true,
                      message:
                        "Please input Marketplace Compliance Admin Wallet Address",
                    },
                  ]}
                  style={{ width: "100%" }}
                >
                  <Input
                    placeholder="Enter Marketplace Compliance Admin Wallet Address"
                    style={adminWalletUpdateStyleing.input}
                  />
                </Form.Item>
              </Col>
              <Col
                sm={24}
                md={24}
                lg={24}
                xl={{ span: 4, offset: 2 }}
                xxl={{ span: 4, offset: 2 }}
                style={adminWalletUpdateStyleing.buttonCol}
              >
                <Form.Item>
                  <button
                    type="submit"
                    // loading={loading}
                    style={adminWalletUpdateStyleing.button}
                  >
                    Update
                  </button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    </>
  );
};

export default AdminWalletUpdate;
