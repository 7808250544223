import { Button, Form, Input, notification } from 'antd';
import React, { useContext, useEffect, useState } from 'react'
import authContext from '../../../../components/Shared/Auth.service';
import { MetamaskService } from '../../../../components/Shared/Metamask.service';
import { AccountService } from '../../../../components/Account/Account.service';
const useSelectedWalletContext = () =>
  new MetamaskService().useSelectedWalletContext();
const accountService = new AccountService();

function ChangePassword(props) {
  const { setLoading, loading } = props || {};
  const { userInfo } = useContext(authContext);
  const { selectedWallet: walletAddress } = useSelectedWalletContext();

  const [form] = Form.useForm();
  const [oldPassword, setOldPassword] = useState([]);
  const [newPassword, setNewPassword] = useState(false);
  const [newConfirmPassword, setNewConfirmPassword] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [correctWalletSelected, setCorrectWalletSelected] = useState(false);
  const [samePasswordError, setSamePasswordError] = useState(false);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 8 },
      lg: { span: 8 },
      xl: { span: 8 },
      xxl: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 16 },
      lg: { span: 16 },
      xl: { span: 16 },
      xxl: { span: 16 },
    },
  };
  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 16,
        offset: 8,
      },
    },
  };

  useEffect(() => {
    setLoading(true);
    setTimeout(async () => {
      if (!userInfo) window.location.href = "/account/login";
      else setLoading(false);
    }, 500);
  }, [userInfo]);

  useEffect(() => {
    if (userInfo)
      setCorrectWalletSelected(
        userInfo.walletAddress?.toLowerCase() === walletAddress?.toLowerCase()
      );
  }, [userInfo, walletAddress]);

  const changePassword = async (req) => {
    setConfirmPasswordError(false);
    setSamePasswordError(false);
    if (newPassword !== newConfirmPassword) {
      return setConfirmPasswordError(true);
    }
    if (oldPassword === newPassword) {
      return setSamePasswordError(true);
    }

    setLoading(true);
    let data = {
      email: userInfo?.email,
      oldPassword: oldPassword,
      newPassword: newPassword,
    };
    const response = await accountService.changePassword(data);

    if (response.success) {
      notification.open({
        message:
          "You have successfully updated new password for NFT Marketplace",
        duration: 0,
      });

      if (userInfo?.role === 'marketplace-super-admin') {
        setTimeout(() => (window.location.href = "/marketplace-admin/super-admin"), 2000);
      } else if (userInfo?.role === 'marketplace-compliance-admin') {
        setTimeout(() => (window.location.href = "/marketplace-admin/compliance-admin"), 2000);
      } else if (userInfo?.role === 'marketplace-token-admin') {
        setTimeout(() => (window.location.href = "/marketplace-admin/token-admin"), 2000);
      } else {
        setTimeout(() => (window.location.href = "/profile"), 2000);
      }

    } else {
      notification.open({
        message: response.error.message,
        duration: 0,
      });
    }

    setLoading(false);
  };

  return (
    !loading && <>
      <p className='market-super-admin commission-configuration-text'>Change Password</p>
      <div className='market-super-admin admin-invites card-container d-flex justify-content-center flex-column'>
        <div className='market-super-admin card-inner-container justify-content-center align-items-center' style={{ width: '100%', padding: '40px' }}>

          {correctWalletSelected ? (
            <Form
              {...formItemLayout}
              form={form}
              style={{width: '100%'}}
              onFinish={changePassword}
              className='change-password-form'
            >
              <Form.Item
                label="Old Password :"
                name="current Password"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please enter the Old password",
                  },
                  {
                    validator: (rule, value) => {
                      const strongRegex =
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$\-_[\](){}<>|°¬+=~".,;:'#!%*?&])[A-Za-z\d@$\-_[\](){}<>|°¬+=~".,;:'#!%*?&]{8,}$/;
                      if (strongRegex.test(value)) return Promise.resolve();
                      return Promise.reject(
                        "Please enter the valid password"
                      );
                    },
                  },
                ]}
              >
                <Input.Password
                  value={oldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                />
              </Form.Item>

              <Form.Item
                label="New Password :"
                name="newPassword"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please input your new password!",
                  },
                  {
                    validator: (rule, value) => {
                      const strongRegex =
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$\-_[\](){}<>|°¬+=~".,;:'#!%*?&])[A-Za-z\d@$\-_[\](){}<>|°¬+=~".,;:'#!%*?&]{8,}$/;
                      if (strongRegex.test(value)) return Promise.resolve();
                      return Promise.reject(
                        "The new password must contains at least 1 number, 1 lowercase, 1 uppercase and 1 special character and 8 characters long"
                      );
                    },
                  },
                ]}
              >
                <Input.Password
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </Form.Item>

              <Form.Item
                label="Confirm Password :"
                name="confirmNewPassword"
                dependencies={["newPassword"]}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please input your confirm password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue("newPassword") === value)
                        return Promise.resolve();
                      return Promise.reject(
                        "The two passwords that you entered do not match!"
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  value={newConfirmPassword}
                  onChange={(e) => setNewConfirmPassword(e.target.value)}
                />
              </Form.Item>

              <br />
              {confirmPasswordError && (
                <p style={{ color: "orangered", fontSize: "16px", fontFamily:"Poppins"}}>
                  Your new password and confirm password does not match
                </p>
              )}

              {samePasswordError && (
                <p style={{ color: "orangered", fontSize: "16px", fontFamily:"Poppins" }}>
                  Your current password and new password can not be same!
                </p>
              )}

              <Form.Item {...tailFormItemLayout}>
                <Button
                  type="primary"
                  htmlType="submit"
                  className='market-place-admin update-button'
                  loading={loading}
                  disabled={
                    !oldPassword || !newPassword || !newConfirmPassword
                  }
                >
                  Update Password
                </Button>
              </Form.Item>
            </Form>
          ) : (
            <h4 style={{ textAlign: "center", margin: "30px 10px" }} className='deploy-smart-contract'>
              {" "}
              Please select correct User Wallet
            </h4>
          )
          }

        </div>
      </div>
    </>
  )
}

export default ChangePassword
