import { Button, Form, notification } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import authContext from '../../../../components/Shared/Auth.service';
import { MetamaskService } from '../../../../components/Shared/Metamask.service';
import { AdminService } from '../../../../components/services/AdminService/Admin.service';

const useSelectedWalletContext = () =>
  new MetamaskService().useSelectedWalletContext();
const adminService = new AdminService();

function NftConfiguration(props) {
  const { loading, setLoading, config } = props || {}
  const [form] = Form.useForm();
  const { userInfo } = useContext(authContext);
  const { networkId } = useSelectedWalletContext();
  const [photoSize, setPhotoSize] = useState(15);
  const [videoSize, setVideoSize] = useState(50);
  const [linkedinGoldenBadge, setLinkedinGoldenBadge] = useState(20);
  const [twitterGoldenBadge, setTwitterGoldenBadge] = useState(20);

  useEffect(() => {
    setPhotoSize(config?.photoSize);
    setVideoSize(config?.videoSize);
    setLinkedinGoldenBadge(config?.linkedinGoldenBadge);
  }, [config])

  const updateNFTConfiguration = async () => {
    setLoading(true);
    try {
      let form1 = {
        userId: userInfo._id,
        photoSize: parseInt(photoSize),
        videoSize: parseInt(videoSize),
        linkedinGoldenBadge: parseInt(linkedinGoldenBadge),
        twitterGoldenBadge: parseInt(twitterGoldenBadge),
        networkId,
      };
      let res = await adminService.setMarketPlaceConfiguration(form1);
      if (res.data) {
        notification.open({
          message: "Success",
          duration: 0,
        });
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      notification.open({
        message: "Error",
        description: "something went wrong while updating data",
        duration: 0,
      });
    }
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 16,
        offset: 8,
      },
    },
  };

  return (
    !loading && <>
      <p className='market-super-admin commission-configuration-text'>NFT Configuration</p>
      <div className='market-super-admin card-container d-flex justify-content-center'>
        <div className='market-super-admin card-inner-container' style={{ width: '100%', padding: '40px 20px' }}>
          <Form
            {...formItemLayout}
            form={form}
            onFinish={updateNFTConfiguration}
          >
            <Form.Item
              label="Photo max size in MB"
              name="photoSize"
              rules={[
                {
                  required: false,
                  message: "Please input Photo max size in MB",
                },
              ]}
            >
              <input
                type="number"
                defaultValue={photoSize}
                value={photoSize}
                onChange={(e) => setPhotoSize(e.target.value)}
                style={{ padding: "2px 5px 2px 5px" }}
                max="15"
              />
              <div style={{marginLeft:"8px", textAlign:"left"}}>
                It should not be more than 10MB as watermark on
                this will fail
              </div>
            </Form.Item>

            <Form.Item
              label="Video max size in MB"
              name="videoSize"
              rules={[
                {
                  required: false,
                  message: "Please input Photo max size in MB",
                },
              ]}
            >
              <input
                type="number"
                defaultValue={videoSize}
                value={videoSize}
                onChange={(e) => setVideoSize(e.target.value)}
                style={{ padding: "2px 5px 2px 5px" }}
                max="50"
              />
              <div style={{marginLeft:"8px", textAlign:"left"}}>
                It should not be more than 50MB as watermark on
                this may fail
              </div>
            </Form.Item>

            <Form.Item
              label="Minimum No of Linkedin Followers"
              name="linkedinGoldenBadge"
              rules={[
                {
                  required: false,
                  message:
                    "Please input Minimum Linkedin Followers for Golden Badge",
                },
              ]}
            >
              <input
                type="number"
                defaultValue={linkedinGoldenBadge}
                value={linkedinGoldenBadge}
                onChange={(e) =>
                  setLinkedinGoldenBadge(e.target.value)
                }
                style={{ padding: "2px 5px 2px 5px" }}
              />
              <div style={{marginLeft:"8px", textAlign:"left", width:"40%"}}>For Golden Badge</div>
            </Form.Item>

            <Form.Item
              label="Minimum No of Twitter Followers"
              name="twitterGoldenBadge"
              rules={[
                {
                  required: false,
                  message:
                    "Please input Minimum Linkedin Followers for Golden Badge",
                },
              ]}
            >
              <input
                type="number"
                defaultValue={twitterGoldenBadge}
                value={twitterGoldenBadge}
                onChange={(e) =>
                  setTwitterGoldenBadge(e.target.value)
                }
                style={{ padding: "2px 5px 2px 5px" }}
              />
              <div style={{marginLeft:"8px", textAlign:"left", width:"40%"}}>For Golden Badge</div>
            </Form.Item>

            <Form.Item {...tailFormItemLayout}>
              <Button
                type="primary"
                htmlType="submit"
                className='market-place-admin update-button'
                loading={loading}
                style={{display:"flex",
                justifyContent:"center"
                }}
              >
                Update
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  )
}

export default NftConfiguration
