import React, { useContext } from 'react';
import Nav from './Nav';
import authContext from './Shared/Auth.service';
import { SharedService } from './Shared/Shared.service';
//import { MetamaskService } from './Shared/Metamask.service';
//const metamaskService = new MetamaskService();
//const sharedService = new SharedService();

//const useUserInfo = () => new AuthService().useUserInfo();

//const SelectedWalletContext = MetamaskService.SelectedWalletContext;
//const useSelectedWallet = (userId) => new MetamaskService().useSelectedWallet(userId);

const NavHoc = ({ component }) => {
    const { userInfo, setUserInfo } = useContext(authContext);
    // const [selectedWallet, networkId, isMetamaskAuthenticated, setSelectedWallet, setNetworkId, setIsMetamaskAuthenticated] = useSelectedWallet(userInfo);
    // const isNotConnectedToMetamask = !!userInfo && (!metamaskService.isMetamaskInstalled || isMetamaskAuthenticated === false || (networkId && !sharedService.allowedNetworks.includes(networkId)));

    return (
        <div>
            {/* <Nav isNotConnectedToMetamask={isNotConnectedToMetamask}>
                {component}
            </Nav> */}
            {component}
        </div>
    )
}

export default NavHoc;
