import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { VideoPlayer } from "./mainSlider/VideoPlayer";
import authContext from "./Shared/Auth.service";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const Outer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: 260px;
  overflow: hidden;
  border-radius: 8px;
`;

const NFTCard = ({ nft, buy, todayEtheriumPriceInUSD, checkDetail, fromNft101=false }) => {
  const { userInfo, setUserInfo } = useContext(authContext);
  const [buyScreen, setBuyScreen] = useState(buy || false);
  const history = useHistory();
  const [loaded, setLoaded] = useState(true);

  const bid = (nft) => {
    // making then buy now available for public
    history.push(`/item-buy/${nft._id}`, "_self");

    // if(userInfo?._id) {
    //   history.push(`/item-buy/${nft._id}`, "_self")
    // }else {
    //   history.push(`/account/login`, "_self")
    // }
  };

  if (!nft) return <></>;

  return (
    <>

      <div className="home-page card nft-card">
        <span>
          {nft.file?.type === "mp4" || nft.file?.type === "MP4" ? (
            <VideoPlayer
              linkSrc={
                nft.file?.watermarkedUrl
                  ? nft.file?.watermarkedUrl
                  : nft.file?.IPFSURL
              }
              //coverImageLink={nft.file?.videoWatermarkImageURL}
              coverImageLink={nft.file?.videoThumbnailUrl}
              playerId="player-1"
              coverId="nft-cover"
              playButtonId="play-1"
              subsButtonId="subs-1"
              autoplay="off"
            />
          ) : (
             <>
            {loaded && <div className="shimmer" />}

              <LazyLoadImage
                src={
                  nft.file?.watermarkedUrl
                    ? nft.file?.watermarkedUrl
                    : nft.file?.IPFSURL
                }
                className="lazy nft__item_preview card-img-top"
                effect="blur"
                onClick={() => !fromNft101 ? history.push(`/item-detail/${nft._id}`, "_self") : history.push(`${nft.cardUrl}`)}
                style={{  height: loaded && "0px", width: loaded && "0px" }}
                onLoad={() => setLoaded(false)}
              />
            </>
          )}
        </span>
        <div
          className={`home-page card-body nft_card ${buyScreen ? "nft-card-buy" : ""}`}
        >
         {!fromNft101 && <h5
            className="card-title text-white text-uppercase"
            onClick={() => history.push(`/item-detail/${nft._id}`, "_self")}
          >
            {nft.title}
          </h5>}
          <div
            className="d-flex grey"
            onClick={() => !fromNft101 ? history.push(`/item-detail/${nft._id}`, "_self") : history.push(`${nft.cardUrl}`)}
          >
            <div className="home-page method-div">
              { !fromNft101 && <p className="home-page method-text">Method:</p>}
              <h4 className="home-page nft-method">{nft.method}</h4>
            </div>
            {nft.method === "Fixed Price" && (
              <>
                <div
                  className="p-2"
                  onClick={() =>
                    history.push(`/item-detail/${nft._id}`, "_self")
                  }
                >
                  <p className="home-page price-text">Price:</p>
                  <h4 className="home-page price-in-eth">
                    {nft.price} ETH
                  </h4>
                </div>
              </>
            )}
            {nft.method === "Auction" && (
              <>
                <div
                  className="p-2"
                  onClick={() =>
                    history.push(`/item-detail/${nft._id}`, "_self")
                  }
                >
                  <p className="home-page price-text">Min Price:</p>
                  <h4 className="home-page price-in-eth">{nft.bid} ETH</h4>
                </div>
              </>
            )}
          </div>

          {nft.method === "Fixed Price" && (
            <>
              <div
                className="d-flex grey"
                onClick={() => history.push(`/item-detail/${nft._id}`, "_self")}
              >
                <div className="p-2 flex-grow-1">
                  <p className="home-page price-text">Price in USD:</p>
                  <h4 className="home-page price-in-eth">
                    {(nft.price * todayEtheriumPriceInUSD).toFixed(3) + " $"}
                  </h4>
                </div>
              </div>
            </>
          )}
          {nft.method === "Auction" && (
            <>
              <div
                className="d-flex grey"
                onClick={() => history.push(`/item-detail/${nft._id}`, "_self")}
              >
                <div className="p-2 flex-grow-1">
                  <p className="home-page price-text">Min Price in USD:</p>
                  <h4 className="home-page price-in-eth">
                    {(nft.bid * todayEtheriumPriceInUSD).toFixed(3) + " $"}
                  </h4>
                </div>
              </div>
            </>
          )}

          {nft.userId !== userInfo?._id && nft.createdBy !== userInfo?._id && 
            buyScreen &&
            nft.method !== "Fixed Price" && (
              <div className="col-12 text-center mt-3 bid-button">
                <a
                  type="button"
                  className="btn_view_all"
                  onClick={() => bid(nft)}
                >
                  Place a Bid
                </a>
              </div>
            )}
          {nft.userId !== userInfo?._id &&
            buyScreen &&
            nft.method === "Fixed Price" && (
              <div className="col-12 text-center mt-3 bid-button">
                <a
                  type="button"
                  className="btn_view_all"
                  onClick={() => bid(nft)}
                >
                  Buy Now
                </a>
              </div>
            )}
          {nft.userId !== userInfo?._id && checkDetail && (
            <div className="col-12 text-center mt-3 bid-button">
              <a
                type="button"
                className="btn_view_all "
                onClick={() => bid(nft)}
              >
                Check Details
              </a>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default NFTCard;