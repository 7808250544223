import React, { useEffect, useState, useContext } from "react";
import { useParams, useHistory, Link, useLocation } from "react-router-dom";
import { createGlobalStyle } from "styled-components";
import { Form, Input, Button, Card, notification, message, Spin } from "antd";
import { NFTCreatorService } from "../components/services/NFTCreator/NFTCreator.service";
import { AccountService } from "../components/Account/Account.service";
import authContext from "../components/Shared/Auth.service";

import "./styles/ForgotPasswordPage.scss";

const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.white {
    background: #fff;
  }
  .mainside{
    .connect-wal{
      display: none;
    }
    .logout{
      display: flex;
      align-items: center;
    }
  }
  @media only screen and (max-width: 1199px) {
    .navbar{
      background: #403f83;
    }
    .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
      background: #fff;
    }
    .item-dropdown .dropdown a{
      color: #111 !important;
    }
  }
`;

const nftService = new NFTCreatorService();
const accountService = new AccountService();

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const ForgotPasswordPage = ({ authorId = null }) => {
  const { userInfo, setUserInfo } = useContext(authContext);
  const [email, setEmail] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const { search } = useLocation();
  const queryParams = Object.fromEntries(new URLSearchParams(search));
  const forgotEmail = queryParams['forgotEmail'];


  useEffect(()=> {
    if(forgotEmail?.length > 0) {
      let forgotEmailNew = forgotEmail;
      if(forgotEmailNew?.indexOf(" ") > -1) {
        forgotEmailNew = forgotEmailNew.replaceAll(" ", "+");
      }
      setEmail(forgotEmailNew);
    }
  }, [forgotEmail]);

  const sendPasswordVerificationCode = async (req) => {
    setLoading(true);
    const response = await accountService.setEmailVerificationCodeForPassword(
      email
    );

    if (response.success) {
      notification.open({
        message:
          "A Password Reset Email has been successfully sent to your emailId. Please check that!",
        duration: 0,
      });
      setTimeout(() => (window.location.href = "/"), 3000);
    } else {
      notification.open({
        message: response.error.message,
        duration: 2,
      });
    }
    setLoading(false);
  };

  return (
    <div>
      <GlobalStyles />
      {loading ? (
        <div
          className="center"
          style={{ textAlign: "center", margin: "100px 0" }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <>
          <div class="forgot-password-container">
            <div class="forgot-password-row">
              {/* <!-- Left column --> */}
              <div className="forgot-password-form-column">
                <div class="forgot-password-form">
                  <Form
                    // {...formItemLayout}
                    className="forgot-password-form"
                    form={form}
                    onFinish={sendPasswordVerificationCode}
                  >
                    <h2 className="forgot-password-custom-heading">
                      Forgot Password
                    </h2>

                    <Form.Item
                      label="*  Email"
                      name="email"
                      className="forgot-password-email-item"
                      rules={[
                        {
                          type: "email",
                          message: "The input is not valid E-mail!",
                        },
                        // {
                        //   required: true,
                        //   message: "Please input your E-mail!",
                        // },
                      ]}
                    >
                      {console.log(`email: ${email}`)}
                      <Input
                        className="forgot-password-email-input"
                        placeholder="Enter your email"
                        value={email}
                        defaultValue={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Form.Item>

                    <Form.Item>
                      {/* <Form.Item {...tailFormItemLayout}> */}
                      <Button
                        className="forgot-password-form-button"
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                        disabled={!email}
                      >
                        Send verification code
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
              </div>
              {/* <!-- Right column --> */}
              <div className="d-md-block d-none forgot-password-image-column">
                <div className="forgot-password-image"></div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ForgotPasswordPage;
