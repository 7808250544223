import React from "react";
import "./learn.scss";

const Learn2 = () => {
  return (
    <div className="container learn-container">
      <h3 class="blog-heading">How to do Whitelisting of the Wallet</h3>
      <img
        className="blog-image horizontal-middle-align"
        src={`/img/learns/learnPageIcon2.png`}
        alt="learn-img"
        /* style={{ width: "600px", height: "550px" }} */
      />
      <p>
        After completing the registration and KYC process, and KYC approval, the
        creator is required to proceed with wallet whitelisting. This involves
        submitting a request to have their wallet whitelisted on the platform.
      </p>
      <ul>
        <li>
          Once the KYC has been approved, the creator can log into the
          marketplace using their login credentials.
        </li>
        <li>
          The next step is to pay the Wallet Whitelisting Fee, which can be done
          by clicking on the "Pay Whitelisting Fees" button. The payment should
          be made at the respective gas price, and once completed, the creator
          confirms the transaction by clicking on "Confirm" on MetaMask.
        </li>
        <li>
          After this, a message will appear in their account stating that their
          wallet whitelisting request is submitted and pending with marketplace
          admin's approval.
        </li>
        <li>
          Once approved by the Marketplace Super Admin (MSA), a confirmation
          email will be sent to the NFT creator stating that their wallet has
          been successfully whitelisted.
        </li>
        <li>
          By following these simple steps, creators can easily complete the
          process of wallet whitelisting within minutes and gain access to
          specific features or privileges within the platform without any
          hassle.
        </li>
      </ul>
      <p>
        The process of wallet whitelisting can be accomplished within minutes by
        following a few straightforward steps. This enables creators to
        effortlessly obtain access to certain platform features or privileges,
        without experiencing any inconvenience.
      </p>
    </div>
  );
};

export default Learn2;
