export const environment = {
  production: false,
  apiKey:'5f3cd49bf3bc85f2558e6421',
  captchaKey:'6LfV5ccZAAAAACClqoeS8Vv8B_HyXJfd57AaFvKI',
  APIURL: 'https://prod-nft-backend-master-qukmd.ondigitalocean.app',
  headerTitle: 'NFT-UI',
  securityTokenRegistryAddress: '0x289A86045b934b9fE706031D9AD0C0CAaA9F558c',
  allowedNetworks: ['137'], // polygon mainnet
  twoFactorAuth: false,
  ipfsApiKey: '2STQ2b0Ja18Ly5MCMVN4P00GTCp',
  ipfsProjectSecret: 'd424d6a433fb20006aea6e5f375b5de2',
  ipfsDomain: 'https://pixentro.infura-ipfs.io',
  loginCaptha: 'true',
  maintenanceMode: 'false',
  acuantUrl: 'https://edna.identitymind.com/edna/applications',
  etheriumPriceAPI: 'https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=matic-network',
  maxImageDimensionSize: 5500, // this is used for maximum uploaded image dimension size for peocessing watermark on that
  TimeZone: "America/New_York",
  TimeOffset: 300, //Timezone offset for EST in minutes.
  displayTimeFormat: "dddd, MMMM Do YYYY, h:mm:ss a z",
  sessionExpiry: 2 * 60 * 60 * 1000, // 2 hours in millis
  websiteName: "https://www.pixentro.com",
  companyName: "Pixentro",
  beforeGoLive: false,
  goLiveDate: "March 31, 2023",
  registerWithInvitation: true,
  invitationCode: 625134,
  claimTimeInEscrow: 30 * 24 * 60 * 60 * 1000, // 30 days in millis, should be set to 30 days
  bidPercentage: 20,
  resell: true,
  companyLogoUrl: '/img/logo/pixentro-logo.png',
  homePageBannerImageUrl: '/img/home/NFTArt.png',
  envName: 'pixentro',
  // Sepolia Testnet - chainID - "11155111"
  blockchains: {
    '137': { // polygon mainnet
      Platform_Beacon_Proxy_Address:"0xa779D5650a4c0681B1df1bA8cBb1c662632f1f64",
      Platform_Beacon_Address: "0x4251D641a3fA6651ed59efbF67233adAd0488fd3",
      AdminRoles_Beacon_Address: "0x106853C6c93AeB05742540a847d535e4cBEed98f",
      Commissions_Beacon_Address: "0xf62d3dF06733327eeC001b6d9E39F6F8f7C5193a",
      WhiteListing_Beacon_Address: "0x41FEf70Ac6BA085739c442F56b233D4da75fc15c",
      NftFactory_Beacon_Address: "0x8A20E748D42Fd0d88CcC11dAE0d1347681190Af4",
      NftMinting_Beacon_Address: "0xdd4d92196f02AA5f8330ef4b4131ce42d19d5E14",
      NftTrader_Beacon_Address: "0xbA1f8874ff49889B702Cf70bBa5eB8e4039B28a6"
    }
  }
}
