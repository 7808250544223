import React, { useEffect, useState, useContext } from "react";
import Breakpoint, { BreakpointProvider, setDefaultBreakpoints } from "react-socks";
// import { header } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import useOnclickOutside from "react-cool-onclickoutside";
import authContext from './../../components/components/Shared/Auth.service';
import { ConsoleSqlOutlined } from "@ant-design/icons";


import { AccountService } from '../components/Account/Account.service';

const accountService = new AccountService();


setDefaultBreakpoints([
  { xs: 0 },
  { l: 1199 },
  { xl: 1200 }
]);

const NavLink = props => (
  <Link 
    {...props}
    getProps={({ isCurrent }) => {
      // the object returned here is passed to the
      // anchor element's props
      return {
        className: isCurrent ? 'active' : 'non-active',
      };
    }}
  />
);



const Header= function() {

    const [openMenu, setOpenMenu] = React.useState(false);
    const [openMenu1, setOpenMenu1] = React.useState(false);
    const [openMenu2, setOpenMenu2] = React.useState(false);
    const [openMenu3, setOpenMenu3] = React.useState(false);
    const { userInfo, setUserInfo } = useContext(authContext);

    const handleBtnClick = () => {
      setOpenMenu(!openMenu);
    };
    const handleBtnClick1 = () => {
      setOpenMenu1(!openMenu1);
    };
    const handleBtnClick2 = () => {
      setOpenMenu2(!openMenu2);
    };
    const handleBtnClick3 = () => {
      setOpenMenu3(!openMenu3);
    };
    const closeMenu = () => {
      setOpenMenu(false);
    };
    const closeMenu1 = () => {
      setOpenMenu1(false);
    };
    const closeMenu2 = () => {
      setOpenMenu2(false);
    };
    const closeMenu3 = () => {
      setOpenMenu3(false);
    };
    const ref = useOnclickOutside(() => {
      closeMenu();
    });
    const ref1 = useOnclickOutside(() => {
      closeMenu1();
    });
    const ref2 = useOnclickOutside(() => {
      closeMenu2();
    });
    const ref3 = useOnclickOutside(() => {
      closeMenu3();
    });

    const [showmenu, btn_icon] = useState(false);
    const [showpop, btn_icon_pop] = useState(false);
    const [shownot, btn_icon_not] = useState(false);
    const closePop = () => {
      btn_icon_pop(false);
    };
    const closeNot = () => {
      btn_icon_not(false);
    };
    const refpop = useOnclickOutside(() => {
      closePop();
    });
    const refpopnot = useOnclickOutside(() => {
      closeNot();
    });
    
    useEffect(() => {
    const headers = document.getElementById("myHeader");
    const totop = document.getElementById("scroll-to-top");
    const sticky = headers.offsetTop;
    // commented out below code to disable navbar header sticky on scroll for all pages using position absolute
    // const scrollCallBack = window.addEventListener("scroll", () => {
    //     btn_icon(false);
    //     if (window.pageYOffset > sticky) {
    //       headers.classList.add("sticky");
    //       totop.classList.add("show");
          
    //     } else {
    //       headers.classList.remove("sticky");
    //       totop.classList.remove("show");
    //     } if (window.pageYOffset > sticky) {
    //       closeMenu();
    //     }
    //   });
      return () => {
        // commented out below code to disable navbar header sticky on scroll for all pages using position absolute
        //window.removeEventListener("scroll", scrollCallBack);
      };
    }, []);

    const logout = async () =>{
      await accountService.logout(userInfo);
      setUserInfo(null);
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = '/';
    }

    return (
    <header id="myHeader" className='navbar white'>
     <div className='container'>
       <div className='row w-100-nav'>
          <div className='logo px-0'>
              <div className='navbar-title navbar-item'>
                <Link to="/">
                {/* <img
                    src="/img/logo-retro.png"
                    className="img-fluid d-block"
                    alt="#"
                  />
                  <img
                    src="/img/logo-retro.png"
                    className="img-fluid d-3"
                    alt="#"
                  />
                  <img
                    src="/img/logo-retro.png"
                    className="img-fluid d-none"
                    alt="#"
                  /> */}
                  <h3 style={{marginBottom: "0px"}}>NFT Marketplace</h3>
                </Link>
              
              </div>
          </div>

          {/* <div className='search'>
            <input id="quick_search" className="xs-hide" name="quick_search" placeholder="search item here..." type="text" />
          </div> */}
              {/* This menu is for mobile view */}
              <BreakpointProvider>
                <Breakpoint l down>
                  {showmenu && 
                  <div className='menu'>
                    <div className='navbar-item'>
                      <div ref={ref}>
                        <NavLink to="/" onClick={() => btn_icon(!showmenu)}>Market</NavLink>
                        {/* <div className="dropdown-custom dropdown-toggle btn" 
                          onClick={handleBtnClick}
                          >
                          Home
                        </div>
                        {openMenu && (
                          // <div className='item-dropdown'>
                          //   <div className="dropdown" onClick={closeMenu}>
                          //     <NavLink to="/" onClick={() => btn_icon(!showmenu)}>Homepage</NavLink>
                          //     {/* <NavLink to="/home1" onClick={() => btn_icon(!showmenu)}>Homepage 1</NavLink>
                          //     {/* <NavLink to="/home2" onClick={() => btn_icon(!showmenu)}>Homepage 1</NavLink> 
                          //   </div>
                          // </div>
                        )}*/}
                      </div>
                    </div>
                    <div className='navbar-item'>
                      <div ref={ref1}>
                        <div className="dropdown-custom dropdown-toggle btn" 
                          onClick={handleBtnClick1}
                          >
                          Explore
                        </div>
                        {openMenu1 && (
                          <div className='item-dropdown'>
                            <div className="dropdown" onClick={closeMenu1}>
                              <NavLink to="/collections" onClick={() => btn_icon(!showmenu)}>All Collections</NavLink>
                              <NavLink to="/nfts" onClick={() => btn_icon(!showmenu)}>All NFTs</NavLink>
                              {/* <NavLink to="/rangking" onClick={() => btn_icon(!showmenu)}>Rangking</NavLink>
                              <NavLink to="/colection/1" onClick={() => btn_icon(!showmenu)}>Collection</NavLink>
                              <NavLink to="/ItemDetail/1" onClick={() => btn_icon(!showmenu)}>Items Details</NavLink> 
                              <NavLink to="/Auction" onClick={() => btn_icon(!showmenu)}>Live Auction</NavLink>
                              <NavLink to="/helpcenter" onClick={() => btn_icon(!showmenu)}>Help Center</NavLink> */}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    {userInfo?._id && (<div className='navbar-item'>
                      <div ref={ref2}>
                        <div className="dropdown-custom dropdown-toggle btn" 
                          onClick={handleBtnClick2}
                          >
                          User
                        </div>
                        {openMenu2 && (
                          <div className='item-dropdown'>
                            <div className="dropdown" onClick={closeMenu2}>
                              <NavLink to="/profile" onClick={() => btn_icon(!showmenu)}>Profile</NavLink>
                              {/* <NavLink to="/AuthorOpensea" onClick={() => btn_icon(!showmenu)}>Author OpenSea</NavLink> */}
                              {/* <NavLink to="/wallet" onClick={() => btn_icon(!showmenu)}>Wallet</NavLink> */}
                              <Link to="/signed-agreements" onClick={() => btn_icon(!showmenu)}>User  Agreements</Link>
                              <Link to="/change-password" onClick={() => btn_icon(!showmenu)}>Change Password</Link>
                              {/* <NavLink to="/createOption" onClick={() => btn_icon(!showmenu)}>Create Options</NavLink>Co */}
                              {/* <NavLink to="/mint" onClick={() => btn_icon(!showmenu)}>Nft Minting</NavLink> */}
                              {/* <NavLink to="/news" onClick={() => btn_icon(!showmenu)}>News</NavLink> */}
                              {/* <NavLink to="/works" onClick={() => btn_icon(!showmenu)}>Gallery</NavLink> */}
                              {/* {userInfo == null && (
                                <NavLink to="/account/login" onClick={() => btn_icon(!showmenu)}>login</NavLink>
                              )}                            
                              {/* <NavLink to="/loginTwo" onClick={() => btn_icon(!showmenu)}>login 2</NavLink> 
                              {userInfo == null && (
                                <NavLink to="/account/register/nft-user" onClick={() => btn_icon(!showmenu)}>Register</NavLink>
                              )} */}
                              {/* {userInfo == null && (
                                <NavLink to="/account/register/nft-marketplace-admin" onClick={() => btn_icon(!showmenu)}>Register as Marketplace Admin</NavLink>
                              )} */}
                             
                              {/* <NavLink to="/contact" onClick={() => btn_icon(!showmenu)}>Contact Us</NavLink> */}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>)}
                    
                    {userInfo?._id && (<div className='navbar-item'>
                      <NavLink to="/create" onClick={() => btn_icon(!showmenu)}>Create</NavLink>
                    </div>)}
                    
                    {/* <div className='navbar-item'>
                      <NavLink to="/marketplace-admin/super-admin" onClick={() => btn_icon(!showmenu)}>
                        Marketplace super Admin
                      </NavLink>
                    </div> */}
                    {userInfo == null && (<div className='navbar-item'>
                      <NavLink to="/account/register/nft-user" onClick={() => btn_icon(!showmenu)}>
                        Register
                      </NavLink>
                    </div>)}
                  </div>
                  }
                </Breakpoint>

                <Breakpoint xl>
                  <div className='menu'>
                    <div className='navbar-item'>
                        <div ref={ref}>
                          <NavLink to="/">Market</NavLink>
                          {/* <div className="dropdown-custom dropdown-toggle btn" 
                             onMouseEnter={handleBtnClick} onMouseLeave={closeMenu}>
                            Home
                            <span className='lines'></span>
                            {openMenu && (
                            // <div className='item-dropdown'>
                            //   {/* <div className="dropdown" onClick={closeMenu}>
                            //     <NavLink to="/">Homepage</NavLink>
                            //     <NavLink to="/home1">Market</NavLink>
                            //   </div>
                            // </div>
                          )}
                          </div> */}
                          
                        </div>
                    </div>
                    <div className='navbar-item'>
                      <div ref={ref1}>
                          <div className="dropdown-custom dropdown-toggle btn" 
                             onMouseEnter={handleBtnClick1} onMouseLeave={closeMenu1}>
                            Explore
                            <span className='lines'></span>
                            {openMenu1 && (
                            <div className='item-dropdown'>
                              <div className="dropdown" onClick={closeMenu1}>
                              {/* <NavLink to="/explore">Explore</NavLink> */}
                              {/* <NavLink to="/categories">Categories</NavLink> */}
                              <NavLink to="/collections">All Collections</NavLink>
                              <NavLink to="/nfts">All NFTs</NavLink>
                              {/* <NavLink to="/rangking">Rangking</NavLink>
                              <NavLink to="/colection/1">Collection</NavLink>
                              <NavLink to="/ItemDetail/1">Items Details</NavLink>
                              <NavLink to="/Auction">Live Auction</NavLink>
                              <NavLink to="/helpcenter">Help Center</NavLink> */}
                              </div>
                            </div>
                          )}
                          </div>
                          
                        </div>
                    </div>
                    {userInfo?._id && (<div className='navbar-item'>
                      <div ref={ref2}>
                          <div className="dropdown-custom dropdown-toggle btn" 
                             onMouseEnter={handleBtnClick2} onMouseLeave={closeMenu2}>
                            User
                            <span className='lines'></span>
                            {openMenu2 && (
                            <div className='item-dropdown'>
                              <div className="dropdown" onClick={closeMenu2}>
                              <NavLink to="/profile">Profile</NavLink>
                              {/* <NavLink to="/AuthorOpensea">Author OpenSea</NavLink> */}
                              {/* <NavLink to="/wallet">Wallet</NavLink> */}
                              <Link to="/signed-agreements" onClick={() => btn_icon(!showmenu)}>User Agreements</Link>
                              <Link to="/change-password" onClick={() => btn_icon(!showmenu)}>Change Password</Link>
                              {/* <NavLink to="/createOption">Create Option</NavLink> */}
                              {/* <NavLink to="/mint">Nft Minting</NavLink> */}
                              {/* <NavLink to="/news">News</NavLink> */}
                              {/* <NavLink to="/works">Gallery</NavLink> */}
                              {/* {userInfo == null && (
                                <NavLink to="/account/login" onClick={() => btn_icon(!showmenu)}>login</NavLink>
                              )}
                              {userInfo == null && (
                                <NavLink to="/account/register/nft-user">Register</NavLink>
                              )} */}
                              {/* {userInfo == null && (
                                <NavLink to="/account/register/nft-marketplace-admin">Register as Marketplace Admin</NavLink>
                              )} */}
                              {/* <NavLink to="/contact">Contact Us</NavLink> */}
                              </div>
                            </div>
                          )}
                          </div>
                        </div>
                    </div>)}
                    
                    <div className='navbar-item'>
                      {/* <NavLink to="/marketplace-admin/super-admin">
                        Marketplace Super Admin
                      <span className='lines'></span>
                      </NavLink> */}
                    </div>
                    
                    {userInfo?._id && (<div className='navbar-item'>
                      <NavLink to="/create">Create</NavLink>
                    </div>)}

                    {userInfo == null && (<div className='navbar-item'>
                      <NavLink to="/account/register/nft-user">
                        Register
                      <span className='lines'></span>
                      </NavLink>
                    </div>)}

                    {/* <div className='navbar-item'>
                      <div ref={ref3}>
                          <div className="dropdown-custom dropdown-toggle btn" 
                             onMouseEnter={handleBtnClick3} onMouseLeave={closeMenu3}>
                            Elements
                            <span className='lines'></span>
                            {openMenu3 && (
                            <div className='item-dropdown'>
                              <div className="dropdown" onClick={closeMenu3}>
                              <NavLink to="/elegantIcons">Elegant Icon</NavLink>
                              <NavLink to="/etlineIcons">Etline Icon</NavLink>
                              <NavLink to="/fontAwesomeIcons">Font Awesome Icon</NavLink>
                              <NavLink to="/accordion">Accordion</NavLink>
                              <NavLink to="/alerts">Alerts</NavLink>
                              <NavLink to="/price">Pricing Table</NavLink>
                              <NavLink to="/progressbar">Progess Bar</NavLink>
                              <NavLink to="/tabs">Tabs</NavLink>
                              </div>
                            </div>
                          )}
                          </div>
                        </div>
                    </div> */}
                  </div>
                </Breakpoint>
              </BreakpointProvider>

              <div className='mainside'>
                {/* <div className='connect-wal'>
                  <NavLink to="/wallet">Connect Wallet</NavLink>
                </div> */}
                {userInfo?.roles?.length > 0 ? (
                  <div id="logout" className="login-btn">
                    <Link to="/" onClick={logout}>Logout</Link>
                  </div>
                ) : (
                  <div id="login" className="login-btn">
                    <Link to="/account/login">Login</Link>
                  </div>
                )}
                <div className="logout">
                  {/* <Link to="/createOptions">Create</Link> */}
                  {/* commented out notification under profile as dont have any notification panel for now */}
                  {/* <div id="de-click-menu-notification" className="de-menu-notification" onClick={() => btn_icon_not(!shownot)} ref={refpopnot}>
                      <div className="d-count">0</div>
                      <i className="fa fa-bell"></i>
                      {shownot && 
                        <div className="popshow">
                          <div className="de-flex">
                              <h4>Notifications</h4>
                              <span className="viewaall">Show all</span>
                          </div>
                          <ul>
                            {/* <li>
                                <div className="mainnot">
                                    <img className="lazy" src="../../img/author/author-2.jpg" alt=""/>
                                    <div className="d-desc">
                                        <span className="d-name"><b>Mamie Barnett</b> started following you</span>
                                        <span className="d-time">1 hour ago</span>
                                    </div>
                                </div>  
                            </li>
                            <li>
                                <div className="mainnot">
                                    <img className="lazy" src="../../img/author/author-3.jpg" alt=""/>
                                    <div className="d-desc">
                                        <span className="d-name"><b>Nicholas Daniels</b> liked your item</span>
                                        <span className="d-time">2 hours ago</span>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="mainnot">
                                    <img className="lazy" src="../../img/author/author-4.jpg" alt=""/>
                                    <div className="d-desc">
                                        <span className="d-name"><b>Lori Hart</b> started following you</span>
                                        <span className="d-time">18 hours ago</span>
                                    </div>
                                </div>    
                            </li>
                            <li>
                                <div className="mainnot">
                                    <img className="lazy" src="../../img/author/author-5.jpg" alt=""/>
                                    <div className="d-desc">
                                        <span className="d-name"><b>Jimmy Wright</b> liked your item</span>
                                        <span className="d-time">1 day ago</span>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="mainnot">
                                    <img className="lazy" src="../../img/author/author-6.jpg" alt=""/>
                                    <div className="d-desc">
                                        <span className="d-name"><b>Karla Sharp</b> started following you</span>
                                        <span className="d-time">3 days ago</span>
                                    </div>
                                </div>    
                            </li> 
                        </ul>
                        </div>
                        }
                  </div> */}
                  {/* <div id="de-click-menu-profile" className="de-menu-profile" onClick={() => btn_icon_pop(!showpop)} ref={refpop}>                           
                      <img src="../../img/author_single/author_thumbnail.jpg" alt=""/>
                      {showpop && 
                        <div className="popshow">
                          <div className="d-name">
                              <h4>Monica Lucas</h4>
                              <span className="name" onClick={()=> window.open("", "_self")}>Set display name</span>
                          </div>
                          <div className="d-balance">
                              <h4>Balance</h4>
                              12.858 ETH
                          </div>
                          <div className="d-wallet">
                              <h4>My Wallet</h4>
                              <span id="wallet" className="d-wallet-address">DdzFFzCqrhshMSxb9oW3mRo4MJrQkusV3fGFSTwaiu4wPBqMryA9DYVJCkW9n7twCffG5f5wX2sSkoDXGiZB1HPa7K7f865Kk4LqnrME</span>
                              <button id="btn_copy" title="Copy Text">Copy</button>
                          </div>
                          <div className="d-line"></div>
                          <ul className="de-submenu-profile">
                            <li>
                              <span>
                                <i className="fa fa-user"></i> My profile
                              </span>
                            </li>
                            <li>
                              <span>
                                <i className="fa fa-pencil"></i> Edit profile
                              </span>
                            </li>
                            <li>
                              <span>
                                <i className="fa fa-sign-out"></i> Sign out
                              </span>
                            </li>
                          </ul>
                        </div>
                      }
                  </div> */}
                </div>
              </div>
                  
      </div>

        <button className="nav-icon" onClick={() => btn_icon(!showmenu)}>
          <div className="menu-line white"></div>
          <div className="menu-line1 white"></div>
          <div className="menu-line2 white"></div>
        </button>

      </div>     
    </header>
    );
}
export default Header;