/* eslint-disable import/no-anonymous-default-export */
import React, { useState, useEffect, useContext } from 'react';
import { Form, Input, Button, Card, Row, Col } from 'antd';
import { UserOutlined, LockOutlined, ConsoleSqlOutlined } from '@ant-design/icons';
import ReCAPTCHA from 'react-google-recaptcha';
import { NavLink, Link, useHistory } from 'react-router-dom';
// import * as classNames from 'classnames';
import { SharedService } from '../../Shared/Shared.service';
import { AccountService } from '../Account.service';
import authContext from '../../Shared/Auth.service';
import { environment } from '../../../environments/environment';
import emailIcon from "../../../assets/images/profile/emailIcon.png";


import '../../../components/../pages/styles/LoginPage.scss'
import { start } from 'repl';

const accountService = new AccountService();
const sharedService = new SharedService();

//const useUserContext = () => new AuthService().useUserContext();
export default () => {
  const history = useHistory();

  const [reqErr, setReqErr] = useState<string>();
  const [submitting, setSubmitting] = useState(false);
  const [expired, setExpired] = useState(true);

  const { userInfo, setUserInfo } = useContext(authContext);

  useEffect(() => {
    if (!userInfo) return;
    if (userInfo.role === 'platform issuer admin') {
      history.push('/platform-issuer-admin/issuer-reg-reqs');
    } else if (userInfo.role === 'nft-user') {
      history.push("/nft");
    } else if (userInfo.role === 'issuer super admin') {
      history.push('/issuer-super-admin/basic-info-verif');
    } else if (userInfo.role === 'employee' || userInfo.role === 'contractor_consultant_Advisor') {
      history.push(`/team-member/vesting-schedule`);
    } else if (userInfo.role === 'issuer token admin') {
      history.push('/token-dashboard');
    }
    else if (userInfo.role === 'marketplace-super-admin') {
      history.push('/marketplace-admin/super-admin');
    } else if (userInfo.role === 'marketplace-compliance-admin') {
      history.push('/marketplace-admin/compliance-admin');
    } else if (userInfo.role === 'marketplace-token-admin') {
      history.push('/marketplace-admin/token-admin');
    } else if (userInfo.role === 'issuer counsel') {
      history.push('/token-configurations');
    }
    else if (userInfo.role === 'issuer company officer') {
      history.push('/token-configurations');
    }
    else if (userInfo.role === 'investor') {
      history.push('/investor/home');
    }
    else if (userInfo.role === 'platform compliance admin') {
      history.push('/platform-compliance-admin/kyc-verification-request')
    }
    else if (userInfo.role === 'issuer compliance admin') {
      history.push('/issuer-compliance-admin/kyc-verification-request')
    }
    // const subscription = props.source.subscribe();
    // return () => {
    //   subscription.unsubscribe();
    // };
  },
    [userInfo, history],
  );

  useEffect(() => {
    setTimeout(() => {
      if (environment.loginCaptha === 'false') setExpired(false)
    }, 1000);
  }, [])


  const handleCaptchaChange = (value: any) => {
    setExpired(false);
    // if value is null recaptcha expired
    if (value === null) setExpired(true);
  };

  const prefix = <img src={emailIcon} alt="Email" style={{ height: '16px', marginRight: '8px' }} />;

  const login = async ({ email, password }: { [name: string]: string }) => {
    setSubmitting(true);
    //console.log('login')

    // CHECK IF EMAIL IS VERIFIED
    try {
      const response = await accountService.login(email, password);

      if (response.success) {
        sharedService.setToken(response.data.token);
        sharedService.setSessionExpiry();
        setUserInfo({ ...response.data.user });

      } else {
        console.error(response.error.message);
        setReqErr(response.error.message);
      }

    } catch (err) {
      console.error(err);
      setReqErr('An error ocurred. Try again later');
    }

    setSubmitting(false);

  }


  return (
    <div className="login-container" >
      <div className="login-row">
        {/* Left side image Start */}

        <div className="d-md-block d-none login-nft-object " style={{ flex: '1' }}>
          <div className='login-nft-object '>

          </div>
        </div>

        {/* Left side image End */}

        {/* Right side form Start   */}

        <div className="login-form-column element" >

          <div className="login-form elementone" >

            <h2 className='login-form-custom-heading elementtwo' >Login</h2>

            <Form
              // form = {form}
              // size='middle'
              onFinish={login}
              className='login-form'

            >


              <Form.Item
                label="Email"
                name="email"
                rules={[{ required: true, message: 'Please input your email!' }]}
                validateStatus={reqErr && 'error'}
                className='login-form-item'


              >
                <Input suffix={prefix} className='login-email elementhree' />
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                rules={[{ required: true, message: 'Please input your password!' }]}
                validateStatus={reqErr && 'error'}
                help={reqErr}
                className='login-form-item password'>
                <Input.Password className='login-password elementhree' />
              </Form.Item>

              {environment.loginCaptha === 'true' && (<div style={{ marginTop: '30px', display: 'inline-block', marginLeft: '90px' }} className='login-captcha'>
                <ReCAPTCHA
                  theme='light'
                  sitekey={environment.captchaKey}
                  onChange={handleCaptchaChange} />
              </div>)}


              <Form.Item className='forgot-password-link'>
                <a className='forgot-password-link-a' href="/account/forgot-password">
                  Forgot password?
                </a>
              </Form.Item>

              <Form.Item className='new-user'>
                <p className='d-block d-sm-none new-user-a'>Do you have an account? <Link className='new-user-link' to='/account/register/nft-user'>Register</Link></p>
              </Form.Item>


              <Button
                type='primary'
                htmlType='submit'
                className='login-form-button'
                loading={submitting}
                disabled={expired}
              >
                Login
              </Button>

            </Form>


            {/* for Dekstop view */}
            <p className='d-md-block d-none login-form-register '>Do you have an account? <Link className='login-form-register-link' to='/account/register/nft-user'>Register</Link></p>

          </div>

          {/* </div> */}


        </div>
      </div>

      {/* Right side form End   */}



    </div >
  )
}