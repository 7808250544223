import React, { useEffect } from "react";
import { Result, Button } from "antd";
import { useHistory, useParams } from "react-router-dom";
import { PaymentService } from "./../components/services/Payment/Payment.service";
const paymentService = new PaymentService();

const PaymentDeclined = () => {
  const history = useHistory();
  const { paymentId } = useParams();

  useEffect(() => {
    (async () => {
      const response = await paymentService.getCoinbasePaymentDetails({
        paymentId,
      });
      if (response.success && response.data) {
        console.log(response.data);
      }
    })();
  }, []);
  return (
    <>
      <Result
        status="error"
        title="Payment Declined"
        extra={[
          <Button
            type="primary"
            key="console"
            onClick={() => history.push("/")}
          >
            Home
          </Button>,
        ]}
      ></Result>
    </>
  );
};

export default PaymentDeclined
