import React, {useState, useEffect, useContext} from 'react';
import { Typography, Row, Col, Card, Spin, Input, Button, notification } from 'antd';
import QRCode from 'qrcode';
import authContext from './../../Shared/Auth.service';
import { useHistory } from 'react-router-dom';
import { AccountService } from '../Account.service';
import { SharedService } from '../../Shared/Shared.service';



const {Title} = Typography;

const accountService = new AccountService();
const sharedService = new SharedService();

//const useUserContext = () => new AuthService().useUserContext();


export default function TOTP() {
  const { userInfo, setUserInfo } = useContext(authContext);
  const history = useHistory();

  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState('');


  useEffect(() => {
    if(userInfo && !userInfo.status.firstTimeLoggedIn && userInfo.device) 
    { //history.push("/profile");
  }

  },[userInfo, history]);


  useEffect(() => {
    (async () => {
      if(userInfo && !userInfo.status.firstTimeLoggedIn && userInfo.device) return;

      const response = await accountService.generateTOTPSecret();

      if(!response.success) return;

      await QRCode.toCanvas(document.getElementById('qr-canvas'), response.data);

      setLoading(false);

    })();
  }, [userInfo]);


  const verifyTOTP = async() => {
    setSubmitting(true);

    try {
      const response = await accountService.verifyTOTP({token});

      if (response.success) {
        notification.success({
          message: 'Success',
          description: 'Authentication enabled successfully'
        });

        setUserInfo(prev => {
          if(!prev) return;
          const current = sharedService.clone(prev);
          current.status.firstTimeLoggedIn = false;
          current.ip = response.data;
          current['differentIP'] = false;
          current['differentDevice'] = false;
          current['deviceExpired'] = false;
          return current; 
        });
        
        window.location.href = "/profile";

      } else {
        notification.error({
          message: 'Error',
          description: response.error.message
        });
      }
      
    } catch (err) {
      notification.error({
        message: 'Error',
        description: 'An error has ocurred. Try again later'
      });
    }

    setSubmitting(false);
  }


  return (
    <>
      <br/><br/>
      <Row justify="center">
        <Col span={20}>
          {loading && 
            <div style={{textAlign:'center'}}>
              <Spin size='large'/>
            </div>
          }
          {/* {!loading &&  */}
            <>
              <Card hidden={loading}>
                <Title level={1} style={{textAlign:'center'}}>Enable Authenticator Support</Title>
                <Row justify="center">
                  <Col span={20}>
                    <Card bordered={false} >                      
                    <div style={{ textAlign: 'center' }} className='card-shadow'>
                        <canvas id="qr-canvas"></canvas>
                      </div>
                      <p style={{ marginTop: '220px'}}>1) Open Google Authenticator App on your mobile device. if you don't have download it.</p>
                      <p>2) Select Setup Account and choose Scan Barcode. Scan QR Code with the Authenticator App.</p>
                      <p>3) Enter the 6-digit code you see in the App.</p>

                      <br/>
                      <div>
                        <p style={{textAlign:'center'}}>Enter the 2-step verification code provided by your Authenticator App</p>
                        <Row>
                          <Col offset={8} span={6}>
                            <Input onChange={(e) => setToken(e.target.value)}/>
                          </Col>
                        </Row>
                        
                        <br/>
                        <div style={{textAlign:'right'}}>
                          <Button type='primary' disabled={!token} loading={submitting} onClick={verifyTOTP}>VERIFY</Button>
                        </div>
                      </div>
                    </Card>
                  </Col>
                </Row>
              </Card>
            </>
        </Col>
      </Row>
    </>
  );

}