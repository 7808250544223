import React, { memo, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const HomepageLearns = () => {
  const history = useHistory();
  useEffect(() => {
    // 👇️ Scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const NFTArray = [
    {
      file: {
        type: "image",
        watermarkedUrl: `/img/learns/learnIcon1.png`,
      },
      method: 'Step to step guide to registering on our NFT marketplace',
      cardUrl: `/img/learns/learnIcon1.png`
    },
    {
      file: {
        type: "image",
        watermarkedUrl: `/img/learns/learnIcon2.png`,
      },
      method: 'How to do Whitelisting of the Wallet?',
      cardUrl: `/img/learns/learnIcon2.png`
    },
    {
      file: {
        type: "image",
        watermarkedUrl: `/img/learns/learnIcon3.png`,
      },
      method: 'How to create an NFT?',
      cardUrl: `/img/learns/learnIcon3.png`
    },
    {
      file: {
        type: "image",
        watermarkedUrl: `/img/learns/learnIcon4.png`,
      },
      method: 'How to Mint your NFT?',
      cardUrl: `/img/learns/learnIcon4.png`
    },
    {
      file: {
        type: "image",
        watermarkedUrl: `/img/learns/learnIcon5.png`,
      },
      method: 'How does a Buyer purchase an NFT using the Fixed Price method Purchase of NFT by Buyer using Fixed Price?',
      cardUrl: `/img/learns/learnIcon5.png`
    },
    {
      file: {
        type: "image",
        watermarkedUrl: `/img/learns/learnIcon6.png`,
      },
      method: 'How to purchase an NFT through Place a Bids?',
      cardUrl: `/img/learns/learnIcon6.png`
    },
    // {
    //   file: {
    //     type: "image",
    //     watermarkedUrl: `/img/learns/learn7.png`,
    //   },
    //   method: 'How to take part in timed auction for NFT’s?',
    //   cardUrl: `/img/learns/learn7.png`
    // },
  ]

  return (
    <>
      <section className="home-page browse-by-category third_section learns-section">
        <div className="home-page browse-by-category container">
          <div className="row">
            <div className="d-flex col-lg-12 align-items-center">
              <h2 className="home-page text-left browse-by-category-text explore-nft-text">Explore on NFT Marketplace</h2>
              <button
                type="button"
                className="btn_view_all float-end home-page view-all-nft-btn learns-btn"
                onClick={() => history.push('/learns')}
              >
                LEARN MORE
              </button>
            </div>

            <div className="d-flex justify-content-center align-items-center home-page learns-parent-div flex-wrap">
              {NFTArray.length > 0 &&
                NFTArray.map((nft, index) => (
                  <div key={`nft-${index}`} className="d-flex col-lg-4 col-md-6 col-sm-12 align-items-center learns-inner-div" onClick={() => {
                    history.push(`/learns/learn${index + 1}`);
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                  }} style={{ cursor: 'pointer' }}>
                    <div className="home-page-learns-section-div">
                      <div className="home-page learns-img-div">
                        <LazyLoadImage
                          className="home-page learns-img-icon" src={nft.cardUrl} alt="nftArt" effect="blur"
                        />
                      </div>
                      <div className="home-page learns-img-text-div d-flex">
                        <p className="home-page learns-img-text">{nft.method}</p>
                      </div>
                    </div>
                  </div>
                ))}
            </div>

          </div >
        </div>
      </section >
    </>
  )
};

export default memo(HomepageLearns);
