import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Card,
  DatePicker,
  Select,
  Result,
  Col,
  Row,
  message,
} from "antd";
import { Link, useHistory } from "react-router-dom";
import { SharedService } from "../../../Shared/Shared.service";
import { Country } from "../../../Shared/interfaces";
import { AccountService } from "../../Account.service";
import BigNumber from "bignumber.js";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import ConnectToMetamask from "../../../ConnectToMetamask";
import { MetamaskService } from "./../../../Shared/Metamask.service";
import { environment } from "../../../../environments/environment";
import RegisterBanner from "../../../../assets/images/banner/registerBanner.png";
import "../../../../pages/styles/Register.scss";

// import PhoneInput from 'react-phone-number-input'
// import { isValidPhoneNumber } from 'react-phone-number-input'
const { Option } = Select;

const sharedService = new SharedService();
const accountService = new AccountService();

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const useSelectedWalletContext = () =>
  new MetamaskService().useSelectedWalletContext();

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const [submitting, setSubmitting] = useState(false);
  const { selectedWallet, networkId, isMetamaskAuthenticated } =
    useSelectedWalletContext();
  const [countries, setCountries] = useState<Country[]>();

  const [issuerEmail, setIssuerEmail] = useState<string>();
  const [form] = Form.useForm();
  const history = useHistory();
  const isNotConnectedToMetamask = !isMetamaskAuthenticated;

  useEffect(() => {
    (async () => {
      const response = await sharedService.getCountries();
      setCountries(response.data);
    })();
  }, []);

  const register = async (req) => {
    setSubmitting(true);
    req.companyName = environment.companyName;
    const response = await accountService.registerNFTCreator(req);

    if (response.success) {
      setIssuerEmail(req.email);
    } else {
      message.error(response.error.message);
    }

    setSubmitting(false);
  };

  useEffect(() => {
    form.setFieldsValue({ walletAddress: selectedWallet });
  }, [selectedWallet]);
  /* const fetchCountry=(_value)=>{
    let cunt=[]
    for(let i=0;i<tempCountries?.length;i++)
    {
      if(tempCountriesp[i].toString().startsWith(_value))cunt.push(tempCountries[i])
    }
  };*/
  return (
    <>
      <div hidden={!isNotConnectedToMetamask}>
        <ConnectToMetamask />
      </div>

      {/* REGISTER BANNER SECTION */}
      <div className="register-page">
        <div className="register-page registerBanner">
          <img src={RegisterBanner} />
        </div>

        {/*  REGISTER PAGE BODY SECTION  */}
        <div hidden={!!isNotConnectedToMetamask} className="reg-page-p-div">
          <Row
            className="register-row"
            justify="center"
            style={{ margin: " 0px" }}
          >
            <Col
              className="register-column"
              span={16}
              style={{ textAlign: "center" }}
            >
              {!issuerEmail && (
                <Card className="register-card">
                  <h2
                    className="register-heading"
                    style={{ textAlign: "left" }}
                  >
                    Welcome !
                  </h2>
                  <p className="register-page register-para">
                    Register as NFT Creator
                  </p>

                  <Form
                    {...formItemLayout}
                    form={form}
                    onFinish={register}
                    className="register_page_form"
                  >
                    <Form.Item
                      label="First Name"
                      name="firstName"
                      rules={[
                        {
                          required: true,
                          message: "Please input your first name!",
                          whitespace: true,
                        },
                        {
                          pattern: new RegExp(/^[a-zA-Z]+$/i),
                          message: "Please Use Alphabets Only",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <br />

                    <Form.Item
                      label="Last Name"
                      name="lastName"
                      rules={[
                        {
                          required: true,
                          message: "Please input your last name!",
                          whitespace: true,
                        },
                        {
                          pattern: new RegExp(/^[a-zA-Z]+$/i),
                          message: "Please Use Alphabets Only",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <br />

                    <Form.Item
                      label="E-mail"
                      name="email"
                      rules={[
                        {
                          type: "email",
                          message: "The input is not valid E-mail!",
                        },
                        {
                          required: true,
                          message: "Please input your E-mail!",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <br />

                    <Form.Item
                      label="Password"
                      name="password"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: "Please input your password!",
                        },
                        {
                          validator: (rule, value) => {
                            const strongRegex =
                              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$\-_[\](){}<>|°¬+=~".,;:'#!%*?&])[A-Za-z\d@$\-_[\](){}<>|°¬+=~".,;:'#!%*?&]{8,}$/;
                            if (strongRegex.test(value))
                              return Promise.resolve();
                            return Promise.reject(
                              "The password must contains at least 1 number, 1 lowercase, 1 uppercase and 1 special character and 8 characters long"
                            );
                          },
                        },
                      ]}
                    >
                      <Input.Password />
                    </Form.Item>

                    <Form.Item
                      label="Confirm Password"
                      name="confirm"
                      dependencies={["password"]}
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: "Please confirm your password!",
                        },
                        ({ getFieldValue }) => ({
                          validator(rule, value) {
                            if (!value || getFieldValue("password") === value)
                              return Promise.resolve();
                            return Promise.reject(
                              "The two passwords that you entered do not match!"
                            );
                          },
                        }),
                      ]}
                    >
                      <Input.Password />
                    </Form.Item>
                    <br />

                
                      <Form.Item
                        label="Wallet Address"
                        name="walletAddress"
                        rules={[
                          {
                            required: true,
                            message: "Please connect to metamask",
                            whitespace: true,
                          },
                        ]}
                        style={{ marginBottom: "10px" }}
                        help={<p style={{ color: "rgba(255, 219, 109, 1)", fontSize: "12px", marginTop: "10px" }}>*Please verify the wallet address</p>}
                      >
                        <Input
                          style={{
                            color: "rgba(255, 219, 109, 1)!important",
                            height: "50px",
                            fontSize: "13px",
                          }}
                          disabled
                        />

                      </Form.Item>
                  

                    {/* <Form.Item 
                  label='Name of Company'
                  name="companyName"
                  rules={[
                    {
                      required: true,
                      message: 'Enter Company name here',
                      whitespace: true
                    }
                  ]}>
                  <Input />
                </Form.Item> */}

                    <Form.Item
                      label="Country"
                      name="companyCountry"
                      validateStatus={countries ? "" : "validating"}
                      hasFeedback
                      help={countries ? "" : "Loading Countries..."}
                      rules={[
                        {
                          required: true,
                          message: "Select Country",
                        },
                      ]}
                    >
                      <Select showSearch placeholder="Select Country">
                        {countries
                          ?.sort(function (a, b) {
                            return b.name["en"].localeCompare(a.name["en"]);
                          })
                          .map((country) => (
                            <Option
                              key={country._id}
                              value={country.name["en"]}
                            >
                              {country.name["en"]}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>

                    <Form.Item
                      shouldUpdate={(prevValues, currentValues) =>
                        prevValues.companyCountry !==
                        currentValues.companyCountry
                      }
                      noStyle
                    >
                      {({ getFieldValue }) => {
                        return (
                          <Form.Item
                            label="Phone number"
                            name="phone"
                            rules={[
                              {
                                required: true,
                                message: "Enter Phone number here",
                                whitespace: true,
                              },
                              {
                                validator: (rule, value) => {
                                  const strongRegex =
                                    /^[+]{0,1}[0-9\s-]{0,4}[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]{3,10}$/;
                                  if (strongRegex.test(value))
                                    return Promise.resolve();
                                  return Promise.reject("Invalid phone number");
                                },
                              },
                            ]}
                          >
                            <PhoneInput country={"us"} />
                          </Form.Item>
                        );
                      }}
                    </Form.Item>

                    <Form.Item
                      label="Gender"
                      name="gender"
                      rules={[
                        {
                          required: true,
                          message: "Please select your gender!",
                        },
                      ]}
                    >
                      <Select defaultValue="">
                        <Option value="" disabled>
                          Select
                        </Option>
                        <Option value="male">Male</Option>
                        <Option value="female">Female</Option>
                        <Option value="unknown">Unknown</Option>
                      </Select>
                    </Form.Item>

                    {environment.registerWithInvitation === true && (
                      <Form.Item
                        label="Invitation Code"
                        name="invitationCode"
                        rules={[
                          {
                            required: true,
                            message:
                              "Please enter the correct invitation code sent to you!",
                          },
                          ({ getFieldValue }) => ({
                            validator(rule, value) {
                              if (
                                !value ||
                                environment.invitationCode === parseInt(value)
                              )
                                return Promise.resolve();
                              return Promise.reject(
                                "Please enter the correct invitation code sent to you!"
                              );
                            },
                          }),
                        ]}
                      >
                        <Input type="number" />
                      </Form.Item>
                    )}

                    {/* <Form.Item 
                  label='Amount to raise'
                  name="amountToRaise"
                  normalize = {(value: string, prevValue: any, allValues) => {
                    const _value = value.replace(/,/g,'');
                    
                    if(new BigNumber(_value).isGreaterThan(0)) return new BigNumber(_value).toFormat(0);
                    if(!_value) return '';
                    return prevValue || '';
                  }}
                  rules={[
                    {
                      required: true,
                      message: 'Please input amount to raise!'
                    },
                  ]}>
                  <Input
                    addonAfter='USD'
                  />
                </Form.Item> */}

                    <br />
                    <br />
                    <Form.Item
                      {...tailFormItemLayout}
                      className="register-btn-wrapper d-flex"
                    >
                      <Link to="/account/login" className="register-btn link">
                        <span
                          className="register text-para"
                          style={{
                            color: "rgba(255, 219, 109, 1)", marginRight: '10px',
                          }}
                        >
                          Already a member?
                        </span>
                        Login Now!
                      </Link>
                      <Button
                        className="register-btn btn"
                        type="primary"
                        htmlType="submit"
                        loading={submitting}
                      >
                        Register
                      </Button>
                      {/* <br /> */}
                      {/* <>Or </> */}
                    </Form.Item>
                  </Form>
                </Card>
              )}

              {issuerEmail && (
                <Card>
                  <h2 style={{ textAlign: "center" }}>Check your Email</h2>
                  <Result
                    title={`We sent a verification email to ${issuerEmail}.`}
                    subTitle="Click on the link to get started!"
                    extra={
                      <Button
                        key="console"
                        onClick={() =>
                          history.push(
                            "/account/resend-email-verification-code"
                          )
                        }
                      >
                        Email didn't arrive?
                      </Button>
                    }
                  />
                </Card>
              )}
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
