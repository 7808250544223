import { environment } from '../../../../environments/environment';
import Web3 from 'web3';

export class AdminRolesService {
    private ethereum = window['ethereum'];
    private web3 = new Web3(this.ethereum);
    private contract = {
        ABI: require('./AdminRoles.json')
    };

    // We must add MSAdmin as well as MCAdmin - MultiSig Approval needed
    async addingComplianceAdmin(MCAdmin: string, MSAdmin: string, AdminRolesContractAddr: string) {    
        const contractInstance = new this.web3.eth.Contract(this.contract.ABI, AdminRolesContractAddr);
        
        return contractInstance.methods.addMPCompilenceAdmin([MSAdmin, MCAdmin],2).send({ from: MSAdmin });
    }

    // We must add MSAdmin as well as MTAdmin - Approval from EitherOne is needed
    async addingTokenAdmin(MTAdmin: string, MSAdmin: string, AdminRolesContractAddr: string) {    
        const contractInstance = new this.web3.eth.Contract(this.contract.ABI, AdminRolesContractAddr);
        
        return contractInstance.methods.addMPTokenAdmin([MSAdmin, MTAdmin]).send({ from: MSAdmin });
    }

    async changeMTAdmin(OldMTAdmin: string, NewMTAdmin: string, MSAdmin: string, AdminRolesContractAddr: string) {  
        //console.log('OldMTAdmin:', OldMTAdmin, '. NewMTAdmin:', NewMTAdmin, ',MSAdmin:' , MSAdmin, ',AdminRolesContractAddr:', AdminRolesContractAddr); 
        const contractInstance = new this.web3.eth.Contract(this.contract.ABI, AdminRolesContractAddr);
        
        return contractInstance.methods.changeMTAdmin(OldMTAdmin, NewMTAdmin).send({ from: MSAdmin });
    }
    async changeMCAdmin(OldMTAdmin: string, NewMTAdmin: string, MSAdmin: string, AdminRolesContractAddr: string) {   
        const contractInstance = new this.web3.eth.Contract(this.contract.ABI, AdminRolesContractAddr);
        
        return contractInstance.methods.changeMPCompilenceAdmin(OldMTAdmin, NewMTAdmin).send({ from: MSAdmin });
    }
}