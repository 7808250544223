/* eslint-disable import/no-anonymous-default-export */
import React from "react";
// import { lazy } from '@loadable/component';
import { Switch, Route, Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { deviceDetect } from "react-device-detect";
import Home from './components/pages/home';
import Home1 from './components/pages/home1';
import Account from "./components/components/Account";
import { User } from "./components/components/Shared/interfaces";
import PlatformSuperAdmin from './components/components/PlatformSuperAdmin/PlatformSuperAdmin.routes';
import { AccountService } from "./components/components/Account/Account.service";
import Login from "./components/components/Account/Login";
import NavHoc from "./components/components/NavHOC";
import Createpage from "./components/pages/create";
import UserCollections from "./components/pages/UserCollections.js";
import AllCategorisedNFTs from "./components/pages/AllCategorisedNFTs.js";
import Collections from "./components/pages/Collections.js";
import AllNfts from "./components/pages/AllNfts.js";
import { environment } from './components/environments/environment';
import ItemDetail from "./components/pages/ItemDetail.js";
import CategoryDetail from "./components/pages/CategoryDetails.js";
import EditNft from "./components/pages/EditNft.js";
import KYCUpload from "./components/pages/KYCUpload";
import NFTSell from "./components/pages/NFTSell";
import ItemBuy from "./components/pages/ItemBuy";
import PaymentDeclined from "./components/pages/PaymentDeclined";
import PaymentSuccess from "./components/pages/PaymentSuccess";
import KYCSubmit from "./components/pages/KYCSubmit";
import WhitelistWallet from "./components/pages/WhitelistWallet";
import { notification } from "antd";
import UserAgreement from "./components/pages/UserAgreement";
import SignedAgreement from "./components/pages/SignedAgreement";
import ChangePassword from "./components/pages/ChangePassword";
import ForgotPasswordPage from "./components/pages/ForgotPasswordPage";
import HomeNew from "./components/pages/HomeOld";
import { SharedService } from "./components/components/Shared/Shared.service";
import { logout } from "./components/components/HeaderNew";
import PrivacyPolicy from "./components/pages/PrivacyPolicy";
import TermsOfUse from "./components/pages/TermsOfUse";
import Disclaimer from "./components/pages/Disclaimer";
import BlogsRoutes from "./components/pages/blogs/Blogs.routes";
import LearnRoutes from "./components/pages/learn/Learn.routes";
import AllNFTPage from "./components/pages/AllNFTPage";
import SoldNFTPage from "./components/pages/SoldNFTPage";
import NewNFTPage from "./components/pages/NewNFTPage";
import CompanyContent from "./components/pages/CompanyContent";
import Features from "./components/pages/Features";
import AboutUs from "./components/pages/AboutUs";
import Careers from "./components/pages/Careers";
import Faq from "./components/pages/Faq";
import HelpCenter from "./components/pages/helpPage";
import CommunityStandards from "./components/pages/CommunityStandards";
import { ProfileDetails } from "./components/pages/profileDetails";
import { NftProfile } from "./components/pages/nftProfile";

const jQuery = require("jquery");

const accountService = new AccountService();
const currentDeviceId = deviceDetect();
const sharedService = new SharedService();

declare global {
  interface Window {
    $: any;
    jQuery: any;
  }
}
window.$ = jQuery;
window.jQuery = jQuery;

const CustomRoute = ({
  children,
  userInfo,
  setUserInfo,
  networkId,
  ...rest
}: {
  children: any;
  userInfo: User;
  setUserInfo: any;
  networkId: string;
  path: string;
  exact?: boolean;
}) => {
  const history = useHistory();
  return (
    <Route
      {...rest}
      render={({ location }) => {
        let twoFactorAuth = environment.twoFactorAuth;

        if (userInfo) {
          // checking for if session expired then logout
          let sessionExpiry = sharedService.getSessionExpiry();
          if(sessionExpiry && !isNaN(parseInt(sessionExpiry)) && new Date().getTime() > new Date(parseInt(sessionExpiry)).getTime())
            return logout(userInfo, setUserInfo);


          //checking for platform-super-admin login. this is completely seperated login flow and will be removed in future. and do not touch this for any changes.
          if(userInfo.role === "platform-super-admin" && location.pathname !== "/marketplace-admin/platform-super-admin-view") {
            return (<Redirect to={{ pathname: '/marketplace-admin/platform-super-admin-view', state: { from: location } }} />);
          }

          // other roles login
          if (userInfo.role === "marketplace-super-admin" || userInfo.role === "marketplace-compliance-admin" || userInfo.role === "marketplace-token-admin" || userInfo.role === "nft-user") {
            //verify email
            if(userInfo.status.emailVerified === false && location.pathname !== "/account/resend-email-verification-code") {
              return (<Redirect to={{ pathname: '/account/resend-email-verification-code', state: { from: location } }} />);
            }

            // two factor auth flow and first time login
            if (twoFactorAuth === true && (userInfo.status.firstTimeLoggedIn || !userInfo.device) &&
              location.pathname !== "/account/TOTP"
            ) {
              return (<Redirect to={{ pathname: '/account/TOTP', state: { from: location } }} />);
            }

            // two factor auth flow and new device
            if (twoFactorAuth === true && (userInfo["differentIP"] || userInfo["differentDevice"] || userInfo["deviceExpired"]) &&
              location.pathname !== "/account/2FA" &&
              location.pathname !== "/account/TOTP"
            ) {
              return (<Redirect to={{ pathname: '/account/2FA', state: { from: location } }} />);
            }
          }

          //verify super admin
          if (userInfo.role === "marketplace-super-admin" && userInfo.status.emailVerified !== false){
            if((userInfo.status.profileVerified === false || userInfo.status.profileVerified === 'submitted') && location.pathname !== "/marketplace-admin/verify-super-admin-profile") {
              return (<Redirect to={{ pathname: '/marketplace-admin/verify-super-admin-profile', state: { from: location } }} />);
            }
          }

          //verify compliance admin
          if (userInfo.role === "marketplace-compliance-admin" && userInfo.status.emailVerified !== false){
            if((userInfo.status.profileVerified === false || userInfo.status.profileVerified === 'submitted') && location.pathname !== "/marketplace-admin/verify-compliance-admin-profile") {
              return (<Redirect to={{ pathname: '/marketplace-admin/verify-compliance-admin-profile', state: { from: location } }} />);
            }
          }

          //verify token admin
          if (userInfo.role === "marketplace-token-admin" && userInfo.status.emailVerified !== false){
            if((userInfo.status.profileVerified === false || userInfo.status.profileVerified === 'submitted') && location.pathname !== "/marketplace-admin/verify-token-admin-profile") {
              return (<Redirect to={{ pathname: '/marketplace-admin/verify-token-admin-profile', state: { from: location } }} />);
            }
          }

          // admins normal page after login
          if(userInfo.role === 'marketplace-super-admin') {
            <Redirect
                to={{
                  pathname: "/marketplace-admin/super-admin",
                  state: { from: location },
                }}
            />

            // if(location.pathname !== '/marketplace-admin/super-admin' && location.pathname !== '/')
            //   window.location.href = '/marketplace-admin/super-admin';
          }else if(userInfo.role === 'marketplace-compliance-admin') {
            <Redirect
                to={{
                  pathname: "/marketplace-admin/compliance-admin",
                  state: { from: location },
                }}
            />

            // if(location.pathname !== '/marketplace-admin/compliance-admin' && location.pathname !== '/')
            //   window.location.href = '/marketplace-admin/compliance-admin';
          }else if(userInfo.role === 'marketplace-token-admin') {
            <Redirect
                to={{
                  pathname: "/marketplace-admin/token-admin",
                  state: { from: location },
                }}
            />
            // if(location.pathname !== '/marketplace-admin/token-admin' && location.pathname !== '/')
            //   window.location.href = '/marketplace-admin/token-admin';
          }

          // nft-user flow
          if(userInfo.role === "nft-user" && userInfo.status.emailVerified !== false) {
            //in case of banned or blocked profile
            if(userInfo.status.banned === true){
              alert('Sorry! Your profile is blocked by admin, you can not proceed ahead!');
              localStorage.removeItem('token');
              return window.location.reload();
            }else if(userInfo.status.KYC === "rejected"){
              alert('Sorry! Your kyc is rejected by admin, you can not proceed ahead!');
              localStorage.removeItem('token');
              return window.location.reload();
              // return (<Redirect
              //   to={{
              //     pathname: "/account/login",
              //     state: { from: location },
              //   }}
              // />)
            }else if (userInfo.status.KYC !== "approved" || userInfo.status.userAgreementSigned !== true) {
              if(userInfo.status.KYC && userInfo.status.KYC === 'pending' && location.pathname !== "/KYCUpload"){
                //return window.location.href = '/KYCUpload';

                return (<Redirect
                  to={{
                    pathname: "/KYCUpload",
                    state: { from: location },
                  }}
                />);
              }else if(userInfo.status.KYC && userInfo.status.KYC === 'submitted' && (!userInfo.status.userAgreementSigned || userInfo.status.userAgreementSigned !== true) && location.pathname !== "/userAgreement") {
                return( 
                <Redirect
                    to={{
                      pathname: "/userAgreement",
                      state: { from: location },
                    }}
                  />
                );
              }else if(userInfo.status.KYC && userInfo.status.KYC === 'submitted' && userInfo.status.userAgreementSigned === true && location.pathname !== "/KYCSubmitted"){
                return( 
                <Redirect
                    to={{
                      pathname: "/KYCSubmitted",
                      state: { from: location },
                    }}
                  />
                );
              }
            }

            // whitelisting approval flow
            //console.log(userInfo.status.walletWhitelisted)
            if(
              networkId &&
              ( userInfo.status.walletWhitelisted?.[networkId] !== "approved" || !userInfo.status.whiteListingFeesPaid?.[networkId] ) && 
              userInfo.status.KYC === "approved" &&
              userInfo.status.userAgreementSigned === true
            ){
              if(location.pathname !== "/whitelistWallet"){
                  return(
                  <Redirect
                    to={{
                      pathname: "/whitelistWallet",
                      state: { from: location },
                    }}
                  />
                  );
              }
            }
          }

          //if(userInfo.role === "nft-user")
          return children;
        } else {
          //without login pages
          if (
            location.pathname.startsWith("/account") ||
            location.pathname === "/company-content" ||
            location.pathname === "/privacy-policy" ||
            location.pathname === "/terms-of-use" ||
            location.pathname === "/disclaimer" ||
            location.pathname === "/subscriptionagreement" || 
            location.pathname === "/nfts" ||
            location.pathname === "/collections" || 
            location.pathname.startsWith("/collection") || 
            location.pathname.startsWith("/category-detail") || 
            location.pathname.startsWith("/item-detail/") || 
            location.pathname.startsWith("/item-buy/") ||
            location.pathname.startsWith("/blogs") || 
            location.pathname.startsWith("/learns") || 
            location.pathname.startsWith("/all-nft") || 
            location.pathname.startsWith("/sold-nft") || 
            location.pathname.startsWith("/new-nft") || 
            location.pathname.startsWith("/about-us") ||
            location.pathname === "/features" ||  
            location.pathname === "/careers" ||  
            location.pathname === "/help-center" ||  
            location.pathname === "/community-standards" ||  
            location.pathname === "/faq" ||  
            location.pathname === "/"
          ){
            console.log(location.pathname)
            return children;
          }

        //below code is commented out so that we dont want to go to login page for every refresh
          //else
            // return (
            //   <Redirect to={{ pathname: "/account/login", state: { from: location } }} />
            // );
        }
      }}
    />
  );
};

export default ({ userInfo, setUserInfo, networkId }) => {
  const history = useHistory();
  return (
    <div>
      <Switch>
        <CustomRoute path="/account" userInfo={userInfo} setUserInfo={setUserInfo} networkId={networkId}>
          <NavHoc component={<Account />} />
        </CustomRoute>
        <CustomRoute path="/marketplace-admin" userInfo={userInfo} setUserInfo={setUserInfo} networkId={networkId}>
          <NavHoc component={<PlatformSuperAdmin />} />
        </CustomRoute>
        <CustomRoute path="/home" userInfo={userInfo} setUserInfo={setUserInfo} networkId={networkId}>
          <Route path="/home">
            <Home />
          </Route>
        </CustomRoute>
        <CustomRoute path="/homeNew" userInfo={userInfo} setUserInfo={setUserInfo} networkId={networkId}>
          <Route path="/homeNew">
            <HomeNew />
          </Route>
        </CustomRoute>
        <CustomRoute path="/home1" userInfo={userInfo} setUserInfo={setUserInfo} networkId={networkId}>
          <Route path="/home1">
              <Home1 />
          </Route>
        </CustomRoute>
        <CustomRoute path="/collections" userInfo={userInfo} setUserInfo={setUserInfo} networkId={networkId}>
          <Route path="/collections">
              <UserCollections />
          </Route>
        </CustomRoute>
        <CustomRoute path="/profile" userInfo={userInfo} setUserInfo={setUserInfo} networkId={networkId}>
          <Route path="/profile">
              <ProfileDetails />
          </Route>
        </CustomRoute>
        <CustomRoute path="/nft" userInfo={userInfo} setUserInfo={setUserInfo} networkId={networkId}>
          <Route path="/nft">
              <NftProfile />
          </Route>
        </CustomRoute>
        <CustomRoute path="/categories" userInfo={userInfo} setUserInfo={setUserInfo} networkId={networkId}>
          <Route path="/categories">
              <AllCategorisedNFTs />
          </Route>
        </CustomRoute>
        <CustomRoute path="/collection/:collectionName" userInfo={userInfo} setUserInfo={setUserInfo} networkId={networkId}>
          <Route path="/collection/:collectionName">
              <Collections />
          </Route>
        </CustomRoute>
        <CustomRoute path="/nfts" userInfo={userInfo} setUserInfo={setUserInfo} networkId={networkId}>
          <Route path="/nfts">
              {/* <AllNfts /> */}
              <AllNFTPage />
          </Route>
        </CustomRoute>
        <CustomRoute path="/create" userInfo={userInfo} setUserInfo={setUserInfo} networkId={networkId}>
        <Route path="/create">
            <Createpage />
        </Route>
        </CustomRoute>
        <CustomRoute path="/item-detail/:nftId" userInfo={userInfo} setUserInfo={setUserInfo} networkId={networkId}>
          <Route path="/item-detail/:nftId">
              <ItemDetail />
          </Route>
        </CustomRoute>
        <CustomRoute path="/category-detail/:categoryName" userInfo={userInfo} setUserInfo={setUserInfo} networkId={networkId}>
          <Route path="/category-detail/:categoryName">
              <CategoryDetail />
          </Route>
        </CustomRoute>
        <CustomRoute path="/edit-nft/:nftId" userInfo={userInfo} setUserInfo={setUserInfo} networkId={networkId}>
        <Route path="/edit-nft/:nftId">
            <EditNft />
        </Route>
        </CustomRoute>
        <CustomRoute path="/item-buy/:nftId" userInfo={userInfo} setUserInfo={setUserInfo} networkId={networkId}>
          <Route path="/item-buy/:nftId">
              <ItemBuy />
          </Route>
        </CustomRoute>
        <CustomRoute path="/item-sell/:nftId" userInfo={userInfo} setUserInfo={setUserInfo} networkId={networkId}>
          <Route path="/item-sell/:nftId">
              <NFTSell />
          </Route>
        </CustomRoute>
        <CustomRoute path="/KYCUpload" userInfo={userInfo} setUserInfo={setUserInfo} networkId={networkId}>
          <Route path="/KYCUpload">
              <KYCUpload />
          </Route>
        </CustomRoute>
        <CustomRoute path="/userAgreement" userInfo={userInfo} setUserInfo={setUserInfo} networkId={networkId}>
          <Route path="/userAgreement">
              <UserAgreement />
          </Route>
        </CustomRoute>
        <CustomRoute path="/KYCSubmitted" userInfo={userInfo} setUserInfo={setUserInfo} networkId={networkId}>
          <Route path="/KYCSubmitted">
              <KYCSubmit />
          </Route>
        </CustomRoute>
        <CustomRoute path="/whitelistWallet" userInfo={userInfo} setUserInfo={setUserInfo} networkId={networkId}>
          <Route path="/whitelistWallet">
              <WhitelistWallet />
          </Route>
        </CustomRoute>
        <CustomRoute path="/payment-complete/:paymentId" userInfo={userInfo} setUserInfo={setUserInfo} networkId={networkId}>
          <Route path="/payment-complete/:paymentId">
              <PaymentSuccess />
          </Route>
        </CustomRoute>
        <CustomRoute path="/payment-declined/:paymentId" userInfo={userInfo} setUserInfo={setUserInfo} networkId={networkId}>
          <Route path="/payment-declined/:paymentId">
              <PaymentDeclined />
          </Route>
        </CustomRoute>
        <CustomRoute path="/signed-agreements" userInfo={userInfo} setUserInfo={setUserInfo} networkId={networkId}>
          <Route path="/signed-agreements">
              <SignedAgreement />
          </Route>
        </CustomRoute>
        <CustomRoute path="/change-password" userInfo={userInfo} setUserInfo={setUserInfo} networkId={networkId}>
          <Route path="/change-password">
              <ChangePassword />
          </Route>
        </CustomRoute>
        <CustomRoute path="/company-content" userInfo={userInfo} setUserInfo={setUserInfo} networkId={networkId}>
          <Route path="/company-content">
              <CompanyContent />
          </Route>
        </CustomRoute>
        <CustomRoute path="/privacy-policy" userInfo={userInfo} setUserInfo={setUserInfo} networkId={networkId}>
          <Route path="/privacy-policy">
              <PrivacyPolicy />
          </Route>
        </CustomRoute>
        <CustomRoute path="/terms-of-use" userInfo={userInfo} setUserInfo={setUserInfo} networkId={networkId}>
          <Route path="/terms-of-use">
              <TermsOfUse />
          </Route>
        </CustomRoute>
        <CustomRoute path="/disclaimer" userInfo={userInfo} setUserInfo={setUserInfo} networkId={networkId}>
          <Route path="/disclaimer">
              <Disclaimer />
          </Route>
        </CustomRoute>
        <CustomRoute path="/learns" userInfo={userInfo} setUserInfo={setUserInfo} networkId={networkId}>
          <NavHoc component={<LearnRoutes />} />
        </CustomRoute>
        <CustomRoute path="/blogs" userInfo={userInfo} setUserInfo={setUserInfo} networkId={networkId}>
          <NavHoc component={<BlogsRoutes />} />
        </CustomRoute>
        <CustomRoute path="/all-nft" userInfo={userInfo} setUserInfo={setUserInfo} networkId={networkId}>
          <Route path="/all-nft">
            <AllNFTPage />
          </Route>
        </CustomRoute>
        <CustomRoute path="/sold-nft" userInfo={userInfo} setUserInfo={setUserInfo} networkId={networkId}>
          <Route path="/sold-nft">
            <SoldNFTPage />
          </Route>
        </CustomRoute>
        <CustomRoute path="/new-nft" userInfo={userInfo} setUserInfo={setUserInfo} networkId={networkId}>
        <Route path="/new-nft">
            <NewNFTPage />
          </Route>
        </CustomRoute>

        <CustomRoute path="/about-us" userInfo={userInfo} setUserInfo={setUserInfo} networkId={networkId}>
          <Route path="/about-us">
            <AboutUs/>
          </Route>
        </CustomRoute>
        <CustomRoute path="/features" userInfo={userInfo} setUserInfo={setUserInfo} networkId={networkId}>
          <Route path="/features">
            <Features />
          </Route>
        </CustomRoute>
        <CustomRoute path="/careers" userInfo={userInfo} setUserInfo={setUserInfo} networkId={networkId}>
          <Route path="/careers">
            <Careers />
          </Route>
        </CustomRoute>
        <CustomRoute path="/help-center" userInfo={userInfo} setUserInfo={setUserInfo} networkId={networkId}>
          <Route path="/help-center">
            <HelpCenter />
          </Route>
        </CustomRoute>
        <CustomRoute path="/community-standards" userInfo={userInfo} setUserInfo={setUserInfo} networkId={networkId}>
          <Route path="/community-standards">
            <CommunityStandards />
          </Route>
        </CustomRoute>
        <CustomRoute path="/faq" userInfo={userInfo} setUserInfo={setUserInfo} networkId={networkId}>
          <Route path="/faq">
            <Faq />
          </Route>
        </CustomRoute>

        <CustomRoute path="/" userInfo={userInfo} setUserInfo={setUserInfo} networkId={networkId}>
          <Route exact path="/">
            <Home />
          </Route>
        </CustomRoute>
      </Switch>
    </div>
  );
};
