import React, { useEffect, useState, useContext } from "react";
import { useHistory, Link } from "react-router-dom";
import { createGlobalStyle } from "styled-components";
import { Spin, notification, Form, Button, Input } from "antd";
import { NFTCreatorService } from "../components/services/NFTCreator/NFTCreator.service";
import { MetamaskService } from "./../components/Shared/Metamask.service";
import authContext from "./../components/Shared/Auth.service";
import eversign from "../components/Shared/Eversign/Eversign";

const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.white {
    background: #fff;
  }
  .mainside{
    .connect-wal{
      display: none;
    }
    .logout{
      display: flex;
      align-items: center;
    }
  }
  @media only screen and (max-width: 1199px) {
    .navbar{
      background: #403f83;
    }
    .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
      background: #fff;
    }
    .item-dropdown .dropdown a{
      color: #111 !important;
    }
  }
`;

const nftService = new NFTCreatorService();
const useSelectedWalletContext = () =>
  new MetamaskService().useSelectedWalletContext();

const SignedAgreement = ({ authorId = null }) => {
  const { userInfo, setUserInfo } = useContext(authContext);
  const { selectedWallet } = useSelectedWalletContext();

  const [signedAgreements, setSignedAgreements] = useState([]);
  const [openEversign, setOpenEversign] = useState(false);
  const [loading, setLoading] = useState(false);
  const [singnedUserAgreementUrl, setSignedUserAgreementUrl] = useState();
  const [signedSellUrl, setSignedSellUrl] = useState([]);
  const [signedbuyUrl, setSignedBuyUrl] = useState([]);
  const [correctWalletSelected, setCorrectWalletSelected] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(async () => {
      if (!userInfo) {
        window.location.href = "/account/login";
      } else {
        try {
          let res = await nftService.getUserAllAgreements(userInfo?._id);
          if (res?.data?.length > 0) {
            setSignedAgreements(res.data);
            let buyUrlArray = [],
              sellUrlArray = [];
            res.data.forEach((obj) => {
              if (obj.hasOwnProperty("signedUserAgreementURL"))
                setSignedUserAgreementUrl(obj.signedUserAgreementURL);
              else if (obj.agreementType === "sell")
                sellUrlArray.push(obj.newSignedAgreementURL);
              else if (obj.agreementType === "buy")
                buyUrlArray.push(obj.newSignedAgreementURL);
            });

            setSignedSellUrl(sellUrlArray);
            setSignedBuyUrl(buyUrlArray);

            setOpenEversign(true);
          }
        } catch (error) {
          console.error(error);
          notification.open({
            message: "Error",
            description:
              "something went wrong while fetching getUserAllAgreements api!",
            duration: 0,
          });
        } finally {
          setLoading(false);
        }
      }
    }, 800);
  }, [userInfo]);

  useEffect(() => {
    if (userInfo)
      setCorrectWalletSelected(
        userInfo.walletAddress?.toLowerCase() === selectedWallet?.toLowerCase()
      );
  }, [userInfo, selectedWallet]);

  return (
    <div>
      <GlobalStyles />
      {loading ? (
        <div
        className='loading-spinner' 
        >
          <Spin size="large" />
        </div>
      ) : (
        <>
          {correctWalletSelected ? (
            <div
              className="agreements-wrapper"
              style={{
                margin: "30px 10px",
                justifyContent: "center",
                width: "100%",
              }}
            >
              {singnedUserAgreementUrl?.length > 0 ||
              signedSellUrl?.length > 0 ||
              signedbuyUrl?.length > 0 ? (
                <>
                  {singnedUserAgreementUrl?.length > 0 && (
                    <>
                      <h6 style={{ textAlign: "center" }}>User Agreement</h6>
                      <br />
                      <a href={`${singnedUserAgreementUrl}`} target="_blank"> User Agreement Link</a>
                      {/* <div hidden={!openEversign} id="embedSignDivision">
                        {openEversign &&
                          setTimeout(
                            () =>
                              eversign.open({
                                url: singnedUserAgreementUrl,
                                containerID: "embedSignDivision",
                                width: 750,
                                height: 800,
                                events: {
                                  loaded: () => {
                                    console.log("loading successful");
                                  },
                                  error: () => console.log("EVERSIGN ERROR"),
                                  declined: () => console.log("declined"),
                                },
                              }),
                            10
                          )}
                      </div> */}
                    </>
                  )}

                  {signedSellUrl?.length > 0 && (
                    <>
                      <br />
                      <h6 style={{ textAlign: "center" }}>
                        Agreement Type: Sell
                      </h6>
                      <br />
                      {signedSellUrl.map((url, index) => (
                        <>
                        <span>{index + 1}) </span>
                        <a href={`${url}`} target="_blank">Agreement Link</a>
                        <br />
                        </>
                        // <div
                        //   hidden={!openEversign}
                        //   id={`embedSignDivision-sell-${index}`}
                        //   style={{ textAlign: "center" }}
                        //   key={index}
                        // >
                        //   {openEversign &&
                        //     setTimeout(
                        //       () =>
                        //         eversign.open({
                        //           url: url,
                        //           containerID: `embedSignDivision-sell-${index}`,
                        //           width: 750,
                        //           height: 800,
                        //           events: {
                        //             loaded: () => {
                        //               console.log("loading successful");
                        //             },
                        //             error: () => console.log("EVERSIGN ERROR"),
                        //             declined: () => console.log("declined"),
                        //           },
                        //         }),
                        //       10
                        //     )}
                        // </div>
                      ))}
                    </>
                  )}

                  {signedbuyUrl?.length > 0 && (
                    <>
                      <br />
                      <h6 style={{ textAlign: "center" }}>
                        Agreement Type: Buy
                      </h6>
                      <br />
                      {signedbuyUrl.map((url, index) => (
                        <>
                        <span>{index + 1}) </span>
                        <a href={`${url}`} target="_blank">Agreement Link</a>
                        <br />
                        </>
                        // <div
                        //   hidden={!openEversign}
                        //   id={`embedSignDivision-buy-${index}`}
                        //   style={{ textAlign: "center", marginBottom: "30px" }}
                        //   key={index}
                        // >
                        //   {openEversign &&
                        //     setTimeout(
                        //       () =>
                        //         eversign.open({
                        //           url: url,
                        //           containerID: `embedSignDivision-buy-${index}`,
                        //           width: 750,
                        //           height: 800,
                        //           events: {
                        //             loaded: () => {
                        //               console.log("loading successful");
                        //             },
                        //             error: () => console.log("EVERSIGN ERROR"),
                        //             declined: () => console.log("declined"),
                        //           },
                        //         }),
                        //       10
                        //     )}
                        // </div>
                      ))}
                    </>
                  )}
                </>
              ) : (
                <h6 style={{ textAlign: "center" }}>
                  No Signed Agreement found for your user!
                </h6>
              )}
            </div>
          ) : (
            <h6 style={{ textAlign: "center", padding: "120px 10px" }}>
              {" "}
              Please select correct User Wallet
            </h6>
          )}
        </>
      )}
    </div>
  );
};

export default SignedAgreement;
