import React, { useEffect, useState, useContext } from "react";
import { Result, Button, Descriptions, message } from "antd";
import { useHistory, useParams } from "react-router-dom";
import { PaymentService } from "./../components/services/Payment/Payment.service";
import authContext from './../components/Shared/Auth.service';

const paymentService = new PaymentService();

const PaymentSuccess = () => {
  const [paymentData, setPaymentData] = useState(null);
  const history = useHistory();
  const { userInfo, setUserInfo } = useContext(authContext);
  const { paymentId } = useParams();
  const [loading, setLoading] = useState(false);

  const completePayment = async (data) => {
    const dataToSend = {
      agent: "coinbase",
      firstName: userInfo?.firstName,
      lastName: userInfo?.lastName,
      email: userInfo?.email,
      internalTransactionId: paymentId,
      coinbaseTransactionId: data?.id,
      coinbaseCode: data?.code,
      amount: data?.pricing?.local?.amount + data?.pricing?.local?.currency,
      amountToInvest: Number(data?.pricing?.local?.amount),
      modeOfFundTransfer: "cryptocurrency",
      investorId: userInfo?._id,
      metadata: data?.metadata
    };

    console.log("Complete Payment", dataToSend);
    const response = await paymentService.completePayment(dataToSend);
    if (!response.success) {
      setPaymentData(-1);
    }
  };

  useEffect(() => {
      setLoading(true);
      if(userInfo?._id) {
        (async () => {
            const response = await paymentService.getCoinbasePaymentDetails({ 
              paymentId,
            });
            if (response.success && response.data) {
              console.log("Data", response.data);
              setPaymentData(response.data);
              await completePayment(response.data);
              setLoading(false);
            } else {
              message.error("Something went wrong. Please try again");
              setPaymentData(-1);
            }
          })();
      }
  }, [userInfo]);
  return (
    <>
      {paymentData && paymentData !== -1 && (
        <Result
          loading={loading}
          status="success"
          title="Payment Completed"
          extra={[
            <Button
              type="primary"
              key="console"
              onClick={() => history.push("/")}
            >
              Home
            </Button>,
          ]}
        >
          <div>
            <Descriptions
              title="Payment Details"
              extra={[<Button>Download Reciept</Button>]}
              bordered
            >
              <Descriptions.Item label="First Name">
                {userInfo?.firstName}
              </Descriptions.Item>
              <Descriptions.Item label="Last Name">
                {userInfo?.lastName}
              </Descriptions.Item>
              <Descriptions.Item label="Mode of payment">
                Crypto Currency
              </Descriptions.Item>
              <Descriptions.Item label="Agent">Coinbase</Descriptions.Item>
              <Descriptions.Item label="Transaction ID">
                {paymentId}
              </Descriptions.Item>
              <Descriptions.Item label="Amount">{`${paymentData?.pricing?.local?.amount} ${paymentData?.pricing?.local?.currency}`}</Descriptions.Item>
            </Descriptions>
          </div>
        </Result>
      )}
      {paymentData === -1 && (
        <Result
          loading={!paymentData}
          status="error"
          title="Invalid Payment"
          subTitle="you are reached here because your payment is invalid."
          extra={[
            <Button
              type="primary"
              key="console"
              onClick={() => history.push("/")}
            >
              Home
            </Button>,
          ]}
        ></Result>
      )}
    </>
  );
};

export default PaymentSuccess;
